import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Tab } from '../../components/Tab';
import { GuideDetailsPage } from './GuideDetailsPage';
import { GuidesPage } from './GuidesPage';
import { LibraryPage } from './LibraryPage';
import { MaterialDetailsPage } from './MaterialDetailsPage';
import { MaterialsPage } from './MaterialsPage';
import { NoticeDetailsPage } from './NoticeDetailsPage';
import { NoticesPage } from './NoticesPage';

export const PreparePage = () => {
  return (
    <>
      <div className="border-b border-gray-100 bg-white">
        <div className="mx-auto pt-4 px-4 w-full max-w-5xl flex space-x-4">
          <Tab title="학습 컨텐츠" path="/teacher/prepare/library" />
          <Tab title="수업 자료실" path="/teacher/prepare/materials" />
          <Tab title="교사 교육실" path="/teacher/prepare/guides" />
          <Tab title="공지사항" path="/teacher/prepare/notices" />
        </div>
      </div>
      <Switch>
        <Route path="/teacher/prepare/library" component={LibraryPage} />
        <Route
          path="/teacher/prepare/notices/:id"
          component={NoticeDetailsPage}
        />
        <Route path="/teacher/prepare/notices" component={NoticesPage} />
        <Route
          path="/teacher/prepare/materials/:id"
          component={MaterialDetailsPage}
        />
        <Route path="/teacher/prepare/materials" component={MaterialsPage} />
        <Route
          path="/teacher/prepare/guides/:id"
          component={GuideDetailsPage}
        />
        <Route path="/teacher/prepare/guides" component={GuidesPage} />
        <Redirect from="/" to="/teacher/prepare/library" />
      </Switch>
    </>
  );
};
