import moment from 'moment';
import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
// @ts-ignore
import ReactStars from 'react-rating-stars-component';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { LessonRow } from '../../components/LessonRow';
import { Table } from '../../components/Table';
import { TextArea } from '../../components/TextArea';
import { useQueryString } from '../../hooks';
import { MobileHelper } from '../../pages/Teacher/MobileHelper';
import { apiUrl } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import {
  Course,
  CourseItem,
  Live,
  LiveState,
  LiveFeedback,
  Paginated,
  LiveReview,
  LessonVoice,
  classTypeToText,
  TestResult,
  Entry,
} from '../../types';
import { AdminLiveStatePopUp } from '../components/AdminLiveStatePopUp';

export const UserStudentLiveDetails = () => {
  const { search } = useLocation();
  const [live, setLive] = useState<Live | undefined>(undefined);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { studentId, courseId, courseItemId } = useParams<{
    studentId: string;
    courseId: string;
    courseItemId: string;
  }>();
  const { round, liveId } = parse(search, { ignoreQueryPrefix: true });
  const queryStringLive = useQueryString({
    filter: {
      id: liveId,
      studentId: studentId,
      courseId: courseId,
    },
    limit: 1000,
    join: ['student', 'teacher'],
  });

  const studentIdQueryString = useQueryString({
    filter: { studentId: studentId },
    join: ['series'],
    sort: { createdAt: 'DESC' },
  });

  const { data: course } = useQuery<Course>(
    `/admin/courses/query?studentId=${studentId}&courseId=${courseId}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: courseItem } = useQuery<CourseItem>(
    `/admin/course-items/${courseItemId}?join[0]=liveClass&join[1]=liveClass.book&join[2]=liveClass.book.lessons&join[3]=liveClass.series`,
    fetcher,
    { enabled: !!courseItemId, keepPreviousData: true }
  );

  const { data: lessonVoices } = useQuery<Paginated<LessonVoice>>(
    `/admin/lesson-voices?filter[studentId]=${studentId}&${courseItem?.liveClass.book.lessons
      .map((l, i) => `filter[lessonId][${i}]=${l.id}`)
      .join('&')}&join[0]=lesson&limit=1000`,
    fetcher,
    { enabled: !!studentId && !!courseItem?.liveClass.book.lessons }
  );

  const { data: lives, refetch } = useQuery<Paginated<Live>>(
    `/admin/lives${queryStringLive}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: feedback } = useQuery<LiveFeedback>(
    `/admin/live-feedbacks/lives/${live?.id}`,
    fetcher,
    { enabled: !!live, keepPreviousData: true }
  );

  const { data: review } = useQuery<LiveReview>(
    `/admin/live-reviews/lives/${live?.id}`,
    fetcher,
    { enabled: !!live, keepPreviousData: true }
  );

  const { data: testResults } = useQuery<Paginated<TestResult>>(
    `/admin/test-results/${studentIdQueryString}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  const { data: entries } = useQuery<Entry>(
    `/admin/entries/admin-entry/${testResults?.items[0]?.id}`,
    fetcher,
    { enabled: !!testResults?.items[0]?.id }
  );

  const entrySeries =
    entries?.levelCode === '1-A' || entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C' || entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A' || entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A' || entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  useEffect(() => {
    if (!lives) return;
    setLive(lives?.items?.filter((live) => live)?.[0]);
  }, [lives]);

  const sameCanceled =
    (live?.state === LiveState.CANCELED &&
      moment(live?.start).isSame(live?.canceledAt, 'd')) ||
    live?.state === LiveState.CANCELLATION_THE_DAY;

  console.log(courseItem?.liveClass?.book);

  return (
    <>
      <div className="space-y-5">
        <div className="w-full rounded-lg bg-white shadow-lg overflow-hidden p-10">
          <div className="flex justify-end text-white">
            <Button
              text="수업진행 상태 변경"
              disabled={!live}
              className="bg-primary-1 h-9"
              onClick={() => setIsOpen(true)}
            />
          </div>
          <div className="flex space-x-10">
            <div className="mx-auto text-center flex-shrink-0">
              <button className="border-2 border-primary-7 rounded-full wh-14">
                <Avatar
                  src={apiUrl(course?.student?.avatar)}
                  className="wh-11 md:wh-17"
                />
              </button>
              <div className="mt-2 font-semibold text-lg">
                {course?.student?.name}
              </div>
            </div>
            <div className="flex-1 grid grid-cols-3 gap-5 text-sm">
              <div className="col-span-3 text-lg font-semibold">
                {live?.attendRound
                  ? live.attendRound
                  : live?.cancelRound
                  ? live.cancelRound
                  : round}
                회차 학습
              </div>

              <div className="space-y-2">
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    예약시간
                  </label>
                  <div className="flex-1 text-gray-400">
                    {live?.reservedAt
                      ? utcToLocalFormat(
                          live?.reservedAt,
                          MomentFormat.YYYYMMDDHmm
                        )
                      : '-'}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    수업시간
                  </label>
                  <div className="flex-1 text-gray-400">
                    {live?.start
                      ? utcToLocalFormat(live?.start, MomentFormat.YYYYMMDDHmm)
                      : '-'}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    취소시간
                  </label>
                  <div className="flex-1 text-gray-400">
                    {live?.canceledAt
                      ? utcToLocalFormat(
                          live?.canceledAt,
                          MomentFormat.YYYYMMDDHmm
                        )
                      : '-'}
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    선생님
                  </label>
                  <div className="flex-1 text-gray-400">
                    {live?.teacher.englishName
                      ? live?.teacher.englishName
                      : '-'}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    진행상태
                  </label>
                  <div className="flex-1 text-gray-400">
                    {sameCanceled
                      ? '당일취소'
                      : live?.state === LiveState.AVAILABLE
                      ? '예약가능'
                      : live?.state === LiveState.RESERVED
                      ? '예약완료'
                      : live?.state === LiveState.CANCELED
                      ? '취소'
                      : live?.state === LiveState.ATTENDED
                      ? '출석'
                      : live?.state === LiveState.ABSENT
                      ? '결석'
                      : live?.state === LiveState.NO_SHOW
                      ? '선생님 노쇼'
                      : live?.state === LiveState.ABSENT_AND_NO_SHOW
                      ? '학생 결석 / 선생님 노쇼'
                      : '-'}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    유형
                  </label>
                  <div className="flex-1 text-gray-400">
                    {/* {courseItem?.liveClass?.book?.title
                      ? courseItem?.liveClass?.book?.title
                      : '체험'} */}
                    {courseItem?.liveClass?.level?.classType
                      ? classTypeToText(courseItem?.liveClass?.level?.classType)
                      : '체험'}
                  </div>
                </div>
              </div>

              <div className="space-y-2">
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    교재
                  </label>
                  <div className="flex-1 text-gray-400">
                    {/* {courseItem?.liveClass?.series?.name
                      ? courseItem?.liveClass?.series?.name
                      : '체험'} */}
                    {courseItem?.liveClass?.book?.title ?? entrySeries}
                  </div>
                </div>
                <div className="flex">
                  <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                    학생피드백
                  </label>
                  <div className="flex-1 text-gray-400">
                    {review ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={review.rate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </div>

              <div className="col-span-3 space-y-1">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  관리자 메모
                </label>
                <TextArea disabled value={live?.student.memo} />
              </div>

              <div className="col-span-3 space-y-1">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  수업교사 메모
                </label>
                <TextArea
                  placeholder="다른 선생님에게 해당 학생에 대해 공유해주실게 있다면, 작성해주세요!"
                  defaultValue={live?.text}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full grid grid-cols-3 gap-5 pt-5">
          <div className="col-span-3 flex justify-between items-center">
            <H5>피드백 작성하기</H5>
          </div>
          <div className="col-span-2 space-y-5">
            <div className="rounded-lg bg-white shadow-lg overflow-hidden">
              <div className="px-5 py-4 border-b border-gray-100 font-semibold">
                자가학습
              </div>
              <Table>
                <Table.Head className="bg-white">
                  <Table.Row>
                    <Table.Th>Lesson</Table.Th>
                    <Table.Th>수업날짜</Table.Th>
                    <Table.Th>Story Quiz</Table.Th>
                    <Table.Th>Learn More Quiz</Table.Th>
                    <Table.Th>Word Quiz</Table.Th>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {courseItem?.liveClass.book.lessons
                    ?.sort((a, b) => a.index - b.index)
                    .map((lesson) => (
                      <LessonRow
                        key={lesson.id}
                        studentId={studentId}
                        lesson={lesson}
                      />
                    ))}
                </Table.Body>
              </Table>
            </div>

            <div className="rounded-lg bg-white shadow-lg overflow-hidden">
              <div className="px-5 py-4 border-b border-gray-100 font-semibold">
                학생 녹음파일
              </div>
              <Table>
                <Table.Head className="bg-white">
                  <Table.Row>
                    <Table.Th>녹음파일</Table.Th>
                    <Table.Th>레슨</Table.Th>
                    <Table.Th>제출시간</Table.Th>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {lessonVoices?.items.map((voice) => (
                    <Table.Row key={voice.id}>
                      <Table.Td>
                        <audio src={apiUrl(voice.file)} controls></audio>
                      </Table.Td>
                      <Table.Td>{voice.lesson.title}</Table.Td>
                      <Table.Td>
                        {moment(voice.createdAt).format('YYYY.MM.DD h:mm')}
                      </Table.Td>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div className="place-self-stretch">
            <div className="rounded-lg bg-white shadow-lg overflow-hidden ">
              <div className="px-5 py-4 border-b border-gray-100 font-semibold">
                피드백하기
              </div>
              <div className="p-5">
                <div className="font-semibold">이번주 화상수업 평가</div>
                <div className="space-y-1 mt-2">
                  <div className="flex justify-between items-center text-gray-600">
                    <p>총 평가</p>
                    {feedback ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={feedback.totalRate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <p>수업 참여도</p>
                    {feedback ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={feedback.participationRate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <p>어휘 / 문장력</p>
                    {feedback ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={feedback.vocabularyRate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <p>이해력</p>
                    {feedback ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={feedback.understandingRate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="flex justify-between items-center text-gray-600">
                    <p>발음</p>
                    {feedback ? (
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={feedback.pronunciationRate + 1}
                      />
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="pt-5 space-y-2">
                    <div className="font-semibold">이번주 화상수업 평가</div>
                    <TextArea
                      className="h-96"
                      placeholder="아이의 학습태도, 수업성과 등을 오늘 학습한 내용과 함께 OO자 이상 작성해주세요."
                      defaultValue={feedback?.text}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden w-full">
        <MobileHelper />
      </div>
      {isOpen && live && (
        <AdminLiveStatePopUp
          live={live}
          onClose={() => setIsOpen(false)}
          courseItem={courseItem}
          cancelRound={courseItem?.number || 0}
          refetch={() => refetch()}
        />
      )}
    </>
  );
};
