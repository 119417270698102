import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString, useSearch } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Grade, LiveReview, Paginated, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminSearch } from '../components/AdminSearch';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';

export const Teachers = () => {
  const { q, setQ, setSearchText } = useSearch();
  const queryString = useQueryString();
  const { data } = useQuery<Paginated<User>>(
    `/admin/users/search/teacher/list${queryString}&q=${q}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const { data: teachers } = useQuery<User[]>(
    `/admin/users/teacher/list/export`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const exporter = () => {
    const teacherData = teachers?.map((teacher: any) => {
      const liveReviewRates = teacher.liveReviews?.map(
        (liveReview: LiveReview) => liveReview.rate
      );

      const result = liveReviewRates?.reduce(function add(
        sum: any,
        currValue: any
      ) {
        return sum + currValue;
      },
      0);

      const rate = result / teacher.liveReviews?.length;

      return {
        영어이름: teacher.englishName,
        선생님이름: teacher.name,
        이메일: teacher.email,
        주민번호: teacher.residentRegistrationNumber,
        계좌번호: `${teacher.accountHolder} / ${teacher.bank} / ${teacher.bankAccountNumber}`,
        주소: teacher.address,
        그룹: teacher.grade,
        숨김여부: teacher.isDisabled ? '탈퇴' : '-',
        '활동교사(Y/N)': teacher.isInActive ? 'N' : 'Y',
        우선노출: teacher.exposureRank ? teacher.exposureRank : '-',
        전화번호: teacher?.phoneNumber,
        별점평균: rate ? rate : 0,
      };
    });

    jsonExport(teacherData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(`${BOM} ${csv}`, '선생님 리스트(전체)');
    });
  };

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>선생님 관리</AdminH1>
        <div className="flex items-center space-x-4">
          <Button
            className="bg-white text-blue-500 ml-auto text-14"
            onClick={exporter}
          >
            파일 내보내기
          </Button>
          <Button
            text="선생님 추가"
            to="/admin/teachers/add/info"
            className=" font-normal bg-white text-indigo-600 hover:text-indigo-900"
          />
        </div>
      </div>

      <AdminSearch
        placeholder="검색"
        value={q}
        onChange={(e) => setQ(e.target.value)}
      />
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>이름</Table.Th>
              <Table.Th>영어이름</Table.Th>
              <Table.Th>그룹</Table.Th>
              <Table.Th>이메일</Table.Th>
              <Table.Th>전화번호</Table.Th>
              <Table.Th>탈퇴처리</Table.Th>
              <Table.Th>활동교사(Y/N)</Table.Th>
              <Table.Th>우선순위</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((user) => (
              <Table.Row key={user.id}>
                <Table.Td>{user.name}</Table.Td>
                <Table.Td>{user.englishName}</Table.Td>
                <Table.Td>
                  {user.grade === Grade.T1
                    ? 'T1'
                    : user?.grade === Grade.T2
                    ? 'T2'
                    : 'TF'}
                </Table.Td>
                <Table.Td>{user.email}</Table.Td>
                <Table.Td>
                  {user.phoneNumber.replace(
                    /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
                    '$1-$2-$3'
                  )}
                </Table.Td>
                <Table.Td>{user.isDisabled ? '탈퇴' : '-'}</Table.Td>
                <Table.Td>{user.isInActive ? 'N' : 'Y'}</Table.Td>
                <Table.Td>
                  {user.exposureRank ? user.exposureRank : '-'}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/teachers/${user.id}`}
                    onClick={setSearchText}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/teachers" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
