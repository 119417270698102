import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import { AdminHeader } from './components/AdminHeader';
import { AdminMain } from './components/AdminMain';
import { BannerDetails } from './pages/BannerDetails';
import { Banners } from './pages/Banners';
import { BookDetails } from './pages/BookDetails';
import { Books } from './pages/Books';
import { CouponDetails } from './pages/CouponDetails';
import { Coupons } from './pages/Coupons';
import { Dashboard } from './pages/Dashboard';
import { LiveClassDetails } from './pages/LiveClassDetails';
import { LiveClasses } from './pages/LiveClasses';
import { NoticeDetails } from './pages/NoticeDetails';
import { Notices } from './pages/Notices';
import { PayItems } from './pages/PayItems';
import { PaymentDetails } from './pages/PaymentDetails';
import { Payments } from './pages/Payments';
import { Pays } from './pages/Pays';
import { ProductDetails } from './pages/ProductDetails';
import { Products } from './pages/Products';
import { ReinforceDetails } from './pages/ReinforceDetails';
import { Reinforces } from './pages/Reinforces';
import { Students } from './pages/Students';
import { TeacherDetails } from './pages/TeacherDetails';
import { TeacherGuideDetails } from './pages/TeacherGuideDetails';
import { TeacherGuides } from './pages/TeacherGuides';
import { TeacherMaterialDetails } from './pages/TeacherMaterialDetails';
import { TeacherMaterials } from './pages/TeacherMaterials';
import { TeacherNoticeDetails } from './pages/TeacherNoticeDetails';
import { TeacherNotices } from './pages/TeacherNotices';
import { Teachers } from './pages/Teachers';
import { UserDetails } from './pages/UserDetails';
import { Users } from './pages/Users';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { EventBoardDetails } from './pages/EventBoardDetails';
import { EventBoards } from './pages/EventBoards';
import { CategoryDetails } from './pages/CategoryDetails';
import { Categories } from './pages/Categories';
import { searchTextState } from '../plugins/ridge';

export const Admin = () => {
  const [searchText, setSearchText] = searchTextState.use();

  return (
    <div className="h-screen flex">
      <Sidebar>
        <Sidebar.Title>
          <Link to="/admin">
            <Logo />
          </Link>
        </Sidebar.Title>
        <Sidebar.Menu>
          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="회원" />
            <Sidebar.Menu.Group.Item
              text="자녀 관리"
              to="/admin/students"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
            <Sidebar.Menu.Group.Item
              text="회원 관리"
              to="/admin/users"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
          </Sidebar.Menu.Group>

          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="교육/선생님" />
            <Sidebar.Menu.Group.Item
              text="선생님 관리"
              to="/admin/teachers"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
            <Sidebar.Menu.Group.Item
              text="수업자료실"
              to="/admin/teacher-materials"
            />
            <Sidebar.Menu.Group.Item
              text="교사교육실"
              to="/admin/teacher-guides"
            />
            <Sidebar.Menu.Group.Item
              text="공지사항"
              to="/admin/teacher-notices"
            />
          </Sidebar.Menu.Group>

          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="상품/주문" />
            <Sidebar.Menu.Group.Item
              text="주문 관리"
              to="/admin/payments/all"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
            <Sidebar.Menu.Group.Item text="상품 관리" to="/admin/products" />
            <Sidebar.Menu.Group.Item text="쿠폰 관리" to="/admin/coupons" />
            <Sidebar.Menu.Group.Item text="보강 관리" to="/admin/reinforces" />
          </Sidebar.Menu.Group>

          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="컨텐츠 관리" />
            <Sidebar.Menu.Group.Item
              text="수업 컨텐츠 관리"
              to="/admin/books"
            />
            <Sidebar.Menu.Group.Item
              text="회차 관리"
              to="/admin/live-classes"
            />
          </Sidebar.Menu.Group>

          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="배너/이벤트" />
            <Sidebar.Menu.Group.Item text="배너 관리" to="/admin/banners" />
            <Sidebar.Menu.Group.Item text="이벤트 관리" to="/admin/events" />
            <Sidebar.Menu.Group.Item
              text="카테고리 관리"
              to="/admin/categories"
            />
            <Sidebar.Menu.Group.Item text="공지사항 관리" to="/admin/notices" />
          </Sidebar.Menu.Group>

          <Sidebar.Menu.Group>
            <Sidebar.Menu.Group.Title text="정산" />
            <Sidebar.Menu.Group.Item
              text="정산 관리"
              to="/admin/pays"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
            <Sidebar.Menu.Group.Item
              text="패널티 관리"
              to="/admin/pay-items"
              onClick={() => {
                setSearchText({ ...searchText, q: '' });
              }}
            />
          </Sidebar.Menu.Group>
        </Sidebar.Menu>
      </Sidebar>

      <div className="flex-1 flex flex-col min-w-0 overflow-hidden bg-secondary-9">
        <AdminHeader />
        <AdminMain>
          <Switch>
            <Route path="/admin/users/:id" component={UserDetails} />
            <Route path="/admin/users" component={Users} />

            <Route path="/admin/students" component={Students} />

            <Route path="/admin/teachers/:id" component={TeacherDetails} />
            <Route path="/admin/teachers" component={Teachers} />
            <Route
              path="/admin/teacher-materials/:id"
              component={TeacherMaterialDetails}
            />
            <Route
              path="/admin/teacher-materials"
              component={TeacherMaterials}
            />
            <Route
              path="/admin/teacher-guides/:id"
              component={TeacherGuideDetails}
            />
            <Route path="/admin/teacher-guides" component={TeacherGuides} />
            <Route
              path="/admin/teacher-notices/:id"
              component={TeacherNoticeDetails}
            />
            <Route path="/admin/teacher-notices" component={TeacherNotices} />

            <Route
              path="/admin/live-classes/:id"
              component={LiveClassDetails}
            />
            <Route path="/admin/live-classes" component={LiveClasses} />

            <Route path="/admin/reinforces/:id" component={ReinforceDetails} />
            <Route path="/admin/reinforces" component={Reinforces} />

            <Route path="/admin/products/:id" component={ProductDetails} />
            <Route path="/admin/products" component={Products} />

            <Route path="/admin/payments/:id/edit" component={PaymentDetails} />
            <Route path="/admin/payments" component={Payments} />

            <Route path="/admin/pays" component={Pays} />
            <Route path="/admin/pay-items" component={PayItems} />

            <Route path="/admin/books/:id" component={BookDetails} />
            <Route path="/admin/books" component={Books} />

            <Route path="/admin/notices/:id" component={NoticeDetails} />
            <Route path="/admin/notices" component={Notices} />

            <Route path="/admin/events/:id" component={EventBoardDetails} />
            <Route path="/admin/events" component={EventBoards} />

            <Route path="/admin/categories/:id" component={CategoryDetails} />
            <Route path="/admin/categories" component={Categories} />

            <Route path="/admin/coupons/:id" component={CouponDetails} />
            <Route path="/admin/coupons" component={Coupons} />

            <Route path="/admin/banners/:id" component={BannerDetails} />
            <Route path="/admin/banners" component={Banners} />

            <Route path="/admin" component={Dashboard} exact />
            <Redirect from="/" to="/admin" />
          </Switch>
        </AdminMain>
      </div>
    </div>
  );
};
