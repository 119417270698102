import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { PayItem, PayItemType } from '../../types';

export const TransactionsMonthPenaltyPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: payItems } = useQuery<PayItem[]>(
    `/pay-items/pay/${id}`,
    fetcher,
    { keepPreviousData: true, enabled: !!id }
  );

  const penalties = payItems?.filter((pi) => pi.type === PayItemType.PENALTY);

  return (
    <div className="border rounded-md overflow-hidden border-gray-100">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>일자</Table.Th>
            <Table.Th>패널티 비용</Table.Th>
            <Table.Th>세부내용</Table.Th>
            {/* <Table.Th>담당자명</Table.Th> */}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {penalties
            ?.sort((a: any, b: any) => b.id - a.id)
            ?.map((penalty) => (
              <Table.Row key={penalty?.id}>
                <Table.Td>
                  {utcToLocalFormat(
                    penalty?.createdAt || '',
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td>
                  {`-${penalty?.value
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </Table.Td>
                <Table.Td>{penalty?.memo}</Table.Td>
                {/* <Table.Td>{penalty?.manager}</Table.Td> */}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Pagination>
        <Pagination.Label
          count={penalties?.length || 0}
          total={penalties?.length || 0}
        />
        <Pagination.Nav
          basePath={`/teacher/mypage/transactions/${id}/penalty`}
          total={penalties?.length || 0}
        />
      </Pagination>
    </div>
  );
};
