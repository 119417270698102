import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Coupon, CouponTarget, CouponType, Paginated } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const Coupons = () => {
  const queryString = useQueryString({
    join: ['user', 'student'],
  });
  const { data } = useQuery<Paginated<Coupon>>(
    `/admin/coupons${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>쿠폰 관리</AdminH1>
        <Button
          text="추가"
          to={'/admin/coupons/add'}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>대상</Table.Th>
              <Table.Th>유저</Table.Th>
              <Table.Th>자녀명</Table.Th>
              <Table.Th>쿠폰명</Table.Th>
              <Table.Th>쿠폰타입</Table.Th>
              <Table.Th>할인 금액</Table.Th>
              <Table.Th>다운로드 시작일</Table.Th>
              <Table.Th>다운로드 종료일</Table.Th>
              <Table.Th>사용 시작일</Table.Th>
              <Table.Th>사용 종료일</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((coupon) => (
              <Table.Row key={coupon.id}>
                <Table.Td>
                  {coupon.target === CouponTarget.ALL ? '전체' : '개인'}
                </Table.Td>
                <Table.Td>{coupon.user ? coupon.user?.name : '-'}</Table.Td>
                <Table.Td>{coupon.student?.name}</Table.Td>
                <Table.Td>{coupon.name}</Table.Td>
                <Table.Td>
                  {coupon.type === CouponType.NORMAL ? '일반' : '중복'}
                </Table.Td>
                <Table.Td>{` ${String(coupon.discount).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    coupon.downloadStart,
                    MomentFormat.YYYYMMDD
                  )}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(coupon.downloadEnd, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(coupon.useStart, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td>
                  {utcToLocalFormat(coupon.useEnd, MomentFormat.YYYYMMDD)}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/coupons/${coupon.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/coupons" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
