import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { EditorView } from '../../plugins/froala';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { EventBoard } from '../../types';

export const EventsDetail = () => {
  const { goBack } = useHistory();
  const { id } = useParams<{ id: string }>();
  const now = moment().format('YYYY-MM-DD');

  const { data: event } = useQuery<EventBoard>(`/event-boards/${id}`, fetcher, {
    enabled: !!id,
  });

  return (
    <div className="max-w-container">
      <button
        className="bg-brand-yellow text-white px-4 py-1.5 rounded-lg cursor-pointer"
        onClick={() => goBack()}
      >
        ← 목록으로
      </button>

      <div className="space-y-1 py-5">
        <p className="text-gray-400">{event?.category?.title}</p>
        <div>
          <h4 className="text-lg font-semibold">{event?.title}</h4>
          <p className="text-gray-400 text-sm font-light">
            {event && event?.startedAt <= now && event?.endedAt >= now ? (
              <>
                {utcToLocalFormat(
                  event?.startedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}{' '}
                ~{' '}
                {utcToLocalFormat(
                  event?.endedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}
              </>
            ) : event && event?.endedAt <= now ? (
              '종료'
            ) : (
              <>
                {utcToLocalFormat(
                  event?.startedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}{' '}
                ~{' '}
                {utcToLocalFormat(
                  event?.endedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}
              </>
            )}
          </p>
        </div>
      </div>

      <EditorView model={event?.description} />
    </div>
  );
};
