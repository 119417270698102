import React from 'react';
import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TreeItem from '@mui/lab/TreeItem';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';

export const PageCallContentPage = () => {
  const { data } = useQuery<any>(`/pagecall/contents`, fetcher);

  const sendMessage = (c: any) => {
    window.parent.postMessage(
      {
        type: 'tuntunContents',
        body: Object.values(c)[0],
      },
      '*'
    );
  };

  if (!data) return <></>;
  return (
    <div className="max-w-xs">
      <div className="flex items-center h-14 sticky top-0 bg-white z-10 pl-4 justify-between">
        <div>수업 콘텐츠</div>
      </div>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        <>
          <TreeItem nodeId="100" label="체험 콘텐츠">
            {data
              .filter(
                (d: any) => d.level === 5 || d.level === 6 || d.level === 7
              )
              .map((d: any) => {
                return (
                  <TreeItem
                    key={d.level}
                    nodeId={d.level}
                    label={
                      d.level === 5
                        ? 'Level.1'
                        : d.level === 6
                        ? 'Level.2'
                        : 'Level.3'
                    }
                  >
                    {d.series.map((s: any) => {
                      return (
                        <TreeItem
                          key={s.id}
                          nodeId={s.seriesName}
                          label={s.seriesName}
                        >
                          {s.products.map((p: any) => {
                            return (
                              <TreeItem
                                key={p.id}
                                nodeId={p.productName}
                                label={p.productName}
                              >
                                {p.contents?.map((c: any, i: any) => {
                                  return (
                                    <TreeItem
                                      key={i}
                                      nodeId={c}
                                      label={
                                        c[Object.keys(c)[0]][0].class ===
                                        'C1' ? (
                                          <>1 - {i + 1}</>
                                        ) : c[Object.keys(c)[0]][0].class ===
                                          'C2' ? (
                                          <>2 - {i - 2}</>
                                        ) : (
                                          <>1 - {i + 1}</>
                                        )
                                      }
                                      onClick={() => sendMessage(c)}
                                    ></TreeItem>
                                  );
                                })}
                              </TreeItem>
                            );
                          })}
                        </TreeItem>
                      );
                    })}
                  </TreeItem>
                );
              })}
          </TreeItem>

          {data
            .filter(
              (d: any) =>
                d.level === 1 || d.level === 2 || d.level === 3 || d.level === 4
            )
            .map((d: any) => {
              return (
                <TreeItem
                  key={d.level}
                  nodeId={d.level}
                  label={` Level.${d.level}`}
                >
                  {d.series.map((s: any) => {
                    return (
                      <TreeItem
                        key={s.id}
                        nodeId={s.seriesName}
                        label={s.seriesName}
                      >
                        {s.products.map((p: any) => {
                          return (
                            <TreeItem
                              key={p.id}
                              nodeId={p.productName}
                              label={p.productName}
                            >
                              {p.contents?.map((c: any, i: any) => {
                                return (
                                  <TreeItem
                                    key={i}
                                    nodeId={c}
                                    label={
                                      c[Object.keys(c)[0]][0].class === 'C1' ? (
                                        <>1 - {i + 1}</>
                                      ) : c[Object.keys(c)[0]][0].class ===
                                        'C2' ? (
                                        <>2 - {i - 2}</>
                                      ) : (
                                        <>1 - {i + 1}</>
                                      )
                                    }
                                    onClick={() => sendMessage(c)}
                                  ></TreeItem>
                                );
                              })}
                            </TreeItem>
                          );
                        })}
                      </TreeItem>
                    );
                  })}
                </TreeItem>
              );
            })}

          <TreeItem nodeId="10" label="이벤트">
            {data
              .filter((d: any) => d.level === 0)
              .map((d: any) => {
                return (
                  <>
                    {d.series.map((s: any) => {
                      return (
                        <>
                          {s.products.map((p: any) => {
                            return (
                              <TreeItem
                                key={p.id}
                                nodeId={p.productName}
                                label={p.productName}
                              >
                                {p.contents?.map((c: any, i: any) => {
                                  return (
                                    <TreeItem
                                      key={i}
                                      nodeId={c}
                                      label={
                                        c[Object.keys(c)[0]][0].class ===
                                        'C1' ? (
                                          <>1 - {i + 1}</>
                                        ) : c[Object.keys(c)[0]][0].class ===
                                          'C2' ? (
                                          <>2 - {i - 2}</>
                                        ) : (
                                          <>1 - {i + 1}</>
                                        )
                                      }
                                      onClick={() => sendMessage(c)}
                                    ></TreeItem>
                                  );
                                })}
                              </TreeItem>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                );
              })}
          </TreeItem>
        </>
      </TreeView>
    </div>
  );
};
