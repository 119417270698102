import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api, apiUrl } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Grade, Live, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminPhoneNumber } from '../components/AdminPhoneNumber';
import { PlusIcon } from '../../components/icons';
import { resizeImage } from '../../plugins/compress';
import { UploadFile } from '../../components/UploadFile';
import { downloadPopupState } from '../../plugins/ridge';

interface FormValues {
  avatar: File;
  category: string;
  englishName: string;
  introduction: string;
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  emergencyContact: string;
  address: string;
  residentRegistrationNumber: string;
  bankAccountNumber: string;
  passBook: File;
  idCard: File;
  resume: File;
  privacyPolicy: boolean;
  teacherTerms: boolean;
  firstDayOfWork: string;
  resignationDay: string;
  isDisabled: boolean;
  isInActive?: boolean;
  disabledTypes: string;
  disabledAt: string;
  grade: Grade;
  memo: string;
  videoUrl: string;
  exposureRank: number;
  bank: string;
  accountHolder: string;
  isHide: boolean;
  regularYn: boolean;
  specialYn: boolean;
}

export const TeacherInfo = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);
  const [phoneNumber1, setPhoneNumber1] = useState<string>('010');
  const [phoneNumber2, setPhoneNumber2] = useState<string>('');
  const [phoneNumber3, setPhoneNumber3] = useState<string>('');
  const [emergencyNumber1, setEmergencyNumber1] = useState<string>('010');
  const [emergencyNumber2, setEmergencyNumber2] = useState<string>('');
  const [emergencyNumber3, setEmergencyNumber3] = useState<string>('');

  const { data: teacher, refetch } = useQuery<User>(
    `/admin/users/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const { data: lives } = useQuery<Live[]>(
    `/admin/lives/teacher/reserved/${id}`,
    fetcher,
    {
      enabled: !addMode,
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!teacher) return;
    clearErrors();
    setValue('englishName', teacher.englishName);
    setValue('category', teacher.category);
    setValue('introduction', teacher.introduction);
    setValue('name', teacher.name);
    setValue('email', teacher.email);
    setValue('phoneNumber', teacher.phoneNumber);
    setValue('emergencyContact', teacher.emergencyContact);
    setValue('address', teacher.address);
    setValue('residentRegistrationNumber', teacher.residentRegistrationNumber);
    setValue('bankAccountNumber', teacher.bankAccountNumber);
    setValue('privacyPolicy', teacher.privacyPolicy);
    setValue('teacherTerms', teacher.teacherTerms);
    setValue('grade', teacher.grade);
    setValue('memo', teacher.memo);
    setValue('firstDayOfWork', teacher.firstDayOfWork);
    setValue('resignationDay', teacher.resignationDay);
    setValue('isDisabled', teacher.isDisabled);
    setValue('isInActive', teacher.isInActive);
    setValue('isHide', teacher.isHide);
    setValue('disabledTypes', teacher.disabledTypes);
    setValue('disabledAt', teacher.disabledAt);
    setValue('videoUrl', teacher.videoUrl);
    setValue('exposureRank', teacher.exposureRank);
    setValue('bank', teacher.bank);
    setValue('accountHolder', teacher.accountHolder);
    setValue('regularYn', teacher.regularYn);
    setValue('specialYn', teacher.specialYn);
    setPhoneNumber1(teacher.phoneNumber.substring(0, 3));
    setPhoneNumber2(teacher.phoneNumber.substring(3, 7));
    setPhoneNumber3(teacher.phoneNumber.substring(7));
    setEmergencyNumber1(teacher.emergencyContact.substring(0, 3));
    setEmergencyNumber2(teacher.emergencyContact.substring(3, 7));
    setEmergencyNumber3(teacher.emergencyContact.substring(7));
  }, [clearErrors, setValue, teacher, editMode]);

  return (
    <>
      <AdminH1>선생님 정보</AdminH1>
      <AdminCard>
        <div className="space-y-12 px-4 py-6 sm:px-6 md:px-8">
          <div className="space-y-4">
            <AdminH1>공개 정보</AdminH1>
            <label htmlFor="avatar" className="relative w-max cursor-pointer">
              <Avatar
                src={
                  watch('avatar')
                    ? URL.createObjectURL(watch('avatar'))
                    : apiUrl(teacher?.avatar)
                }
                className="wh-28 mt-4"
              />
              <div className="absolute right-0 bottom-0 top-10 bg-primary-1 rounded-full wh-7 grid place-content-center">
                <PlusIcon className="text-white fill-current wh-3" />
              </div>
              <input
                id="avatar"
                type="file"
                accept="image/*"
                className="sr-only"
                disabled={!addMode && !editMode}
                onChange={async (e) => {
                  const file = e.target.files?.item(0);
                  if (!file || !e.target.validity.valid) return;
                  setValue('avatar', await resizeImage(file));
                }}
              />
            </label>
            <TextField
              label="영어 이름*"
              disabled={!addMode && !editMode}
              placeholder="영어 이름을 입력해주세요"
              helper={errors.name?.message}
              {...register('englishName', {
                required: '영어 이름을 입력해주세요',
              })}
            />
            <TextField
              label="카테고리*"
              disabled={!addMode && !editMode}
              placeholder="카테고리를 입력해주세요"
              helper={errors.category?.message}
              {...register('category', {
                required: '카테고리를 입력해주세요',
              })}
            />
            <TextField
              label="자기소개 영상 링크 *"
              disabled={!addMode && !editMode}
              placeholder="자기소개 영상 링크를 입력해주세요"
              helper={errors.videoUrl?.message}
              {...register('videoUrl', {
                required: '자기소개 영상 링크를 입력해주세요',
              })}
            />
            <TextArea
              label="자기소개*"
              disabled={!addMode && !editMode}
              placeholder="자기소개를 입력해주세요"
              helper={errors.introduction?.message}
              {...register('introduction', {
                required: '자기소개를 입력해주세요',
              })}
            />
          </div>

          <div>
            <AdminH1>비공개 정보</AdminH1>
            <div className="grid grid-cols-1 gap-6 py-6 md:grid-cols-2">
              <TextField
                label="이름*"
                disabled={!addMode && !editMode}
                placeholder="이름을 입력해주세요"
                helper={errors.name?.message}
                {...register('name', {
                  required: '이름을 입력해주세요',
                })}
              />
              <TextField
                label="이메일*"
                type="email"
                disabled={!addMode && !editMode}
                placeholder="이메일을 입력해주세요"
                helper={errors.email?.message}
                {...register('email', {
                  required: '이메일을 입력해주세요.',
                })}
              />
              {addMode && (
                <>
                  <TextField
                    label="비밀번호*"
                    type="password"
                    disabled={!addMode}
                    placeholder="비밀번호를 입력해주세요."
                    helper={errors.password?.message}
                    {...register('password', {
                      required: '비밀번호를 입력해주세요.',
                      minLength: {
                        value: 6,
                        message: '최소 6자 이상 입력해주세요.',
                      },
                    })}
                  />
                  <TextField
                    label="비밀번호 확인*"
                    type="password"
                    disabled={!addMode}
                    placeholder="비밀번호를 한 번 더 입력해주세요."
                    helper={errors.confirmPassword?.message}
                    {...register('confirmPassword', {
                      required: '비밀번호를 한 번 더 입력해주세요.',
                    })}
                  />
                </>
              )}

              <AdminPhoneNumber
                label="핸드폰 번호*"
                disabled={!addMode && !editMode}
                number1={phoneNumber1}
                number2={phoneNumber2}
                number3={phoneNumber3}
                setNumber1={setPhoneNumber1}
                setNumber2={setPhoneNumber2}
                setNumber3={setPhoneNumber3}
              />
              <AdminPhoneNumber
                label="비상연락망*"
                disabled={!addMode && !editMode}
                number1={emergencyNumber1}
                number2={emergencyNumber2}
                number3={emergencyNumber3}
                setNumber1={setEmergencyNumber1}
                setNumber2={setEmergencyNumber2}
                setNumber3={setEmergencyNumber3}
              />
              <TextField
                label="주소*"
                disabled={!addMode && !editMode}
                placeholder="주소를 입력해주세요"
                helper={errors.address?.message}
                {...register('address', {
                  required: '주소를 입력해주세요',
                })}
              />
              <TextField
                label="주민등록번호*"
                disabled={!addMode && !editMode}
                placeholder="주민등록번호를 입력해주세요"
                helper={errors.residentRegistrationNumber?.message}
                {...register('residentRegistrationNumber', {
                  required: '주민등록번호를 입력해주세요',
                })}
              />
              <Select
                label="등급*"
                disabled={!addMode && !editMode}
                {...register('grade', {
                  required: '등급을 선택해주세요.',
                })}
              >
                <option label="TF" value={Grade.TF} />
                <option label="T1" value={Grade.T1} />
                <option label="T2" value={Grade.T2} />
              </Select>
              <TextField
                label="예금주*"
                disabled={!addMode && !editMode}
                placeholder="예금주를 입력해주세요"
                helper={errors.accountHolder?.message}
                {...register('accountHolder', {
                  required: '예금주를 입력해주세요',
                })}
              />
              <TextField
                label="지급 은행*"
                disabled={!addMode && !editMode}
                placeholder="지급 은행을 입력해주세요"
                helper={errors.bank?.message}
                {...register('bank', {
                  required: '지급 은행을 입력해주세요',
                })}
              />
              <TextField
                label="지급 계좌번호*"
                disabled={!addMode && !editMode}
                placeholder="지급 계좌번호를 입력해주세요"
                helper={errors.bankAccountNumber?.message}
                {...register('bankAccountNumber', {
                  required: '지급 계좌번호를 입력해주세요',
                })}
              />
              <UploadFile
                label="통장사본"
                disabled={!addMode && !editMode}
                fileName={
                  watch('passBook')
                    ? watch('passBook').name
                    : teacher?.passBook.split('_')[1]
                }
                onChange={async (file) =>
                  setValue('passBook', await resizeImage(file))
                }
              />
              <UploadFile
                label="신분증사본"
                disabled={!addMode && !editMode}
                fileName={
                  watch('idCard')
                    ? watch('idCard').name
                    : teacher?.idCard.split('_')[1]
                }
                onChange={async (file) =>
                  setValue('idCard', await resizeImage(file))
                }
              />
            </div>
            <Checkbox
              label="개인정보 동의"
              disabled={!addMode && !editMode}
              {...register('privacyPolicy')}
            />
            <Checkbox
              label="교사약관 전자서명"
              disabled={!addMode && !editMode}
              {...register('teacherTerms')}
            />
          </div>

          <div className="space-y-6">
            <AdminH1>관리자 권한</AdminH1>
            <TextArea
              label="관리자 메모"
              disabled={!addMode && !editMode}
              {...register('memo')}
            />
            <UploadFile
              label="이력서"
              disabled={!addMode && !editMode}
              fileName={
                watch('resume')
                  ? watch('resume').name
                  : teacher?.resume.split('_')[1]
              }
              onChange={async (file) =>
                setValue('resume', await resizeImage(file))
              }
            />
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <TextField
                label="입사일*"
                disabled={!addMode && !editMode}
                helper={errors.firstDayOfWork?.message}
                {...register('firstDayOfWork', {
                  required: '입사일을 입력해주세요',
                })}
              />
              <TextField
                label="계약일*"
                disabled={!addMode && !editMode}
                helper={errors.resignationDay?.message}
                {...register('resignationDay', {
                  required: '계약일을 입력해주세요',
                })}
              />
              <TextField
                label="숨김유형"
                disabled={!addMode && !editMode}
                {...register('disabledTypes')}
              />
              <TextField
                label="숨김일자"
                disabled={!addMode && !editMode}
                {...register('disabledAt')}
              />
              <div className="space-y-2">
                <Checkbox
                  label="숨김처리"
                  disabled={!addMode && !editMode}
                  {...register('isDisabled')}
                />
                <Checkbox
                  label="예약 숨김 처리"
                  disabled={!addMode && !editMode}
                  {...register('isHide')}
                />
                <Checkbox
                  label="비활동 상태"
                  disabled={!addMode && !editMode}
                  {...register('isInActive')}
                />
              </div>
              <TextField
                label="우선 순위"
                type="number"
                disabled={!addMode && !editMode}
                {...register('exposureRank')}
              />
              <div className="space-y-2">
                <Checkbox
                  label="정기수업가능여부"
                  disabled={!addMode && !editMode}
                  {...register('regularYn')}
                />
                <Checkbox
                  label="특강수업가능여부"
                  disabled={!addMode && !editMode}
                  {...register('specialYn')}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            {!addMode && (
              <Button
                text="회원 탈퇴 처리"
                className="filled-primary-1 h-10"
                onClick={() => {
                  if (lives && lives.length > 0) {
                    alert(
                      '현재 예약된 수업이 있습니다.예약 취소 후 탈퇴가 가능합니다.'
                    );
                  } else {
                    window.confirm('정말 탈퇴 처리 하시겠습니까?') &&
                      api
                        .patch(`/admin/users/teacher/isDisabled/${id}`)
                        .then(() => alert('탈퇴 처리되었습니다.'));
                  }
                }}
              />
            )}

            {editMode && (
              <Button
                text="취소"
                className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                onClick={() => setEditMode(false)}
              />
            )}
            {editMode && (
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                disabled={
                  !watch('englishName') ||
                  !watch('category') ||
                  !watch('introduction') ||
                  !watch('name') ||
                  !watch('email') ||
                  !watch('address') ||
                  !watch('residentRegistrationNumber') ||
                  !watch('grade') ||
                  !watch('firstDayOfWork') ||
                  !watch('resignationDay') ||
                  !watch('bank') ||
                  !watch('accountHolder') ||
                  !phoneNumber1 ||
                  !phoneNumber2 ||
                  !phoneNumber3 ||
                  !emergencyNumber1 ||
                  !emergencyNumber2 ||
                  !emergencyNumber3
                }
                onClick={handleSubmit(async (data) => {
                  data.phoneNumber = phoneNumber1 + phoneNumber2 + phoneNumber3;
                  data.emergencyContact =
                    emergencyNumber1 + emergencyNumber2 + emergencyNumber3;
                  const formData = new FormData();
                  Object.entries(data).forEach(([name, value]) =>
                    formData.append(name, value)
                  );
                  await api.patch(`/admin/users/teacher/${id}`, formData);
                  await refetch();
                  setEditMode(false);
                })}
              />
            )}
            {addMode && (
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                disabled={
                  !watch('englishName') ||
                  !watch('introduction') ||
                  !watch('name') ||
                  !watch('email') ||
                  !watch('password') ||
                  !watch('confirmPassword') ||
                  !watch('address') ||
                  !watch('residentRegistrationNumber') ||
                  !watch('grade') ||
                  !watch('firstDayOfWork') ||
                  !watch('resignationDay') ||
                  !watch('videoUrl') ||
                  !phoneNumber1 ||
                  !phoneNumber2 ||
                  !phoneNumber3 ||
                  !emergencyNumber1 ||
                  !emergencyNumber2 ||
                  !emergencyNumber3
                }
                onClick={handleSubmit(async (data) => {
                  if (data.password !== data.confirmPassword) {
                    alert('비밀번호가 다릅니다. 다시 확인해주세요.');
                  } else {
                    data.phoneNumber =
                      phoneNumber1 + phoneNumber2 + phoneNumber3;
                    data.emergencyContact =
                      emergencyNumber1 + emergencyNumber2 + emergencyNumber3;
                    const formData = new FormData();
                    Object.entries(data).forEach(([name, value]) =>
                      formData.append(name, value)
                    );
                    await api
                      .post<User>('/admin/users', formData)
                      .then((res) =>
                        push(`/admin/teachers/${res.data.id}/info`)
                      )
                      .catch((err) => {
                        if (err.response.data.statusCode === 409) {
                          alert(err.response.data.message);
                        } else {
                          alert('회원가입에 실패했습니다. 다시 시도해주세요.');
                        }
                      });
                  }
                })}
              />
            )}
            {!addMode && !editMode && (
              <div className="flex space-x-2">
                <Button
                  className="filled-primary-1 h-10"
                  text="다운로드"
                  onClick={() => {
                    downloadPopupState.set({
                      title: '다운로드 선택',
                      body: '필요한 서류를 선택해주세요.',
                      secondBody: '클릭시 다운로드 가능합니다.',
                      primaryText: '통장 사본',
                      primaryLink: teacher?.passBook,
                      secondaryText: '신분증 사본',
                      secondaryLink: teacher?.idCard,
                      teacherName: teacher?.englishName,
                      thirdText: '이력서',
                      thirdLink: teacher?.resume,
                    });
                  }}
                />
                <Button
                  text="수정"
                  className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                  onClick={() => setEditMode(true)}
                />
              </div>
            )}
          </div>
        </div>
      </AdminCard>
    </>
  );
};
