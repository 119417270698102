import React from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as SuccessSVG } from '../../assets/svg/success.svg';
import { Button } from '../../components/Button';
import { H4 } from '../../components/H4';

export const MobileHelper = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center pt-14 px-4 min-h-screen-10 justify-center">
      <SuccessSVG />

      <H4>
        해당페이지는
        <br />
        PC에서 확인해주세요!
      </H4>
      <p className="text-gray-600 text-center pt-4 pb-8">
        해당페이지는 PC이용을 권장합니다 :)
        <br />
        지금, PC로 접속하여 로그인해주세요.
      </p>

      <Button
        text="이전 페이지로 돌아가기 →"
        className="filled-primary-1 w-full md:w-1/3"
        onClick={() => history.goBack()}
      />
    </div>
  );
};
