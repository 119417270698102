import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';

export const ContractsPage = () => {
  const { push } = useHistory();
  const { data: contracts } = useQuery<any>(`/contracts`, fetcher);
  return (
    <div className="w-full max-w-5xl mx-auto my-5 grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1  px-5 xl:px-0">
      {contracts
        ?.slice()
        ?.sort(
          (a: any, b: any) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        ?.map((contract: any) => (
          <div className="rounded-lg bg-white shadow-lg overflow-hidden p-5 m-2 cursor-pointer">
            <div className="py-14 text-center space-y-1">
              <div className="text-lg font-semibold">튼튼영어 계약서</div>
              <div>
                {utcToLocalFormat(contract.date, MomentFormat.YYYYMMDD)}
              </div>
            </div>
            <Button
              className={`w-full ${
                contract?.isSigned ? 'outlined-primary-1' : 'filled-primary-1'
              }`}
              onClick={() => push(`/teacher/mypage/contract/${contract.id}`)}
              text={`${contract?.isSigned ? '계약완료' : '✍🏻  사인하기'}`}
            />
          </div>
        ))}
    </div>
  );
};
