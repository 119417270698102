import React, { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { useId } from 'react-id-generator';
import { api } from '../plugins/axios';
import { Phone } from '../types';
import { Button } from './Button';
import { Label } from './Label';
import { TextField } from './TextField';

export interface PhoneNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  phoneNum: string;
  setPhoneNum: (v: string) => void;
  isVerified: boolean;
  setIsVerified: (v: boolean) => void;
  setPhoneValue?: (v: string) => void;
}

export const PhoneNumber: FC<PhoneNumberProps> = ({
  label,
  onChange,
  phoneNum,
  setPhoneNum,
  isVerified,
  setIsVerified,
  setPhoneValue,
  ...props
}) => {
  const [id] = useId(1, 'phonenumber');
  const [number1, setNumber1] = useState<string>('010');
  const [number2, setNumber2] = useState<string>('');
  const [number3, setNumber3] = useState<string>('');
  const [verifyNum, setVerifyNum] = useState<string>('');
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [phoneId, setPhoneId] = useState<number>();

  const createPhone = async (e: any) => {
    e.preventDefault();
    const phoneNumber = number1 + number2 + number3;

    await api
      .post<Phone>(`/phones`, { phoneNumber: phoneNumber })
      .then((res) => {
        setPhoneId(res.data.id);
        setIsVerified(false);
        setCodeSent(true);
        alert('인증번호를 발송헸습니다.');
      })
      .catch((err) => alert('인증번호 발송에 실패했습니다.다시 시도해주세요.'));
  };

  const verifyPhone = async (e: any) => {
    e.preventDefault();
    const phoneNumber = number1 + number2 + number3;

    await api
      .post<Phone>(`/phones/verify`, {
        id: phoneId,
        number: phoneNumber,
        code: verifyNum,
      })
      .then((res) => {
        setIsVerified(res.data.verified);
        setPhoneNum(phoneNumber);
        alert('휴대폰 번호 인증에 성공했습니다.');
      })
      .catch((err) => {
        console.error(err);
        alert('휴대폰 번호 인증에 실패했습니다.다시 인증해주세요.');
      });
  };

  return (
    <div className="label-col">
      {label && <Label htmlFor={id} text={label} />}
      <div className="grid grid-cols-3 gap-4 pb-4">
        <TextField
          id={id}
          type="tel"
          pattern="[0-9]{3}"
          maxLength={3}
          placeholder={phoneNum.substring(0, 3) || '010'}
          value={number1}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber1(e.target.value);
            e.target.value = `${e.target.value}${number2}${number3}`;
            setPhoneValue &&
              number1.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={phoneNum.substring(3, 7) || '0000'}
          value={number2}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber2(e.target.value);
            e.target.value = `${number1}${e.target.value}${number3}`;
            setPhoneValue &&
              number2.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={phoneNum.substring(7, 11) || '0000'}
          value={number3}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber3(e.target.value);
            e.target.value = `${number1}${number2}${e.target.value}`;
            setPhoneValue &&
              number3.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
      </div>

      <div className="pb-8">
        {codeSent && (
          <>
            <div className="flex space-x-4">
              <TextField
                placeholder="인증번호를 입력해주세요"
                className="h-14"
                value={verifyNum}
                disabled={isVerified}
                onChange={(e) => setVerifyNum(e.target.value)}
              />
              <Button
                type="button"
                text="확인"
                disabled={isVerified}
                className={`${
                  verifyNum && 'bg-gray-500 text-white'
                } outlined-gray-500 rounded-full mb-5`}
                onClick={(e) => verifyPhone(e)}
              />
            </div>
          </>
        )}

        {!isVerified && (
          <div className="justify-end flex">
            <Button
              onClick={(e) => createPhone(e)}
              text={`${codeSent ? '인증번호 재전송' : '인증번호 전송'}`}
              className={`${
                codeSent ? 'outlined-primary-1' : 'filled-primary-1'
              }  px-8`}
              disabled={!number1 || !number2 || !number3}
            />
          </div>
        )}
      </div>
    </div>
  );
};
