import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, TeacherNotice } from '../../types';

export const NoticesPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');
  const limit = Number(params.get('limit') ?? '10');
  const floor = page - 1;

  const queryString = useQueryString({ sort: { createdAt: 'DESC' } });
  const { data: teacherNotices } = useQuery<Paginated<TeacherNotice>>(
    `/teacher-notices${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <div className="max-w-container space-y-5 mt-5">
      <H5>공지사항</H5>
      <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden">
        <Table>
          <Table.Head className="bg-white">
            <Table.Row>
              <Table.Th>번호</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>등록일자</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {teacherNotices?.items.map((notice, index) => (
              <Table.Row key={notice.id}>
                <Table.Td>
                  {page > 1
                    ? floor && teacherNotices.total - floor * limit - index
                    : teacherNotices.total - index}
                </Table.Td>
                <Table.Td>{notice.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    notice.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td className="text-right">
                  <Button
                    className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                    to={`/teacher/prepare/notices/${notice.id}`}
                  >
                    상세보기
                  </Button>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination className="border-t">
          <Pagination.Label
            count={teacherNotices?.items.length || 0}
            total={teacherNotices?.total || 0}
          />
          <Pagination.Nav
            basePath="/teacher/prepare/notices"
            total={teacherNotices?.total || 0}
          />
        </Pagination>
      </div>
    </div>
  );
};
