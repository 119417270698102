import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { api } from '../../plugins/axios';
import { Series } from '../../types';

interface FormValues {
  name: string;
  thumbnail: string;
  index: number;
  open: boolean;
  levelId: number;
}

interface AdminSeriesPopUpProps {
  series?: Series;
  onClose: () => void;
}

export const AdminSeriesPopUp: FC<AdminSeriesPopUpProps> = ({
  series,
  onClose,
}) => {
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!series) return;
    clearErrors();
    setValue('name', series.name);
    setValue('thumbnail', series.thumbnail);
    setValue('index', series.index);
    setValue('open', series.open);
    setValue('levelId', series.levelId);
  }, [series, clearErrors, setValue]);

  const add = handleSubmit((data) =>
    api
      .post('/admin/series', data)
      .then(() => onClose())
      .catch((err) => console.error(err))
  );

  const edit = handleSubmit(async (data) =>
    api
      .patch(`/admin/series/${series?.id}`, data)
      .then(() => onClose())
      .catch((err) => console.error(err))
  );

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">시리즈 관리</H3>

        <TextField
          label="시리즈명"
          placeholder="시리즈명을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', {
            required: '시리즈명을 입력해주세요.',
          })}
        />

        <div className="flex space-x-2">
          <span>공개 여부</span>
          <Toggle
            checked={series?.open}
            onChange={(checked) => setValue('open', checked)}
          />
        </div>

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          disabled={!watch('name')}
          onClick={series?.id ? edit : add}
        />
      </div>
    </div>
  );
};
