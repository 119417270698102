import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { H5 } from '../../components/H5';
import { Tab } from '../../components/Tab';
import { fetcher } from '../../plugins/react-query';
import { PayItem, PayItemType } from '../../types';
import { MobileHelper } from './MobileHelper';
import { TransactionsMonthIncentivePage } from './TransactionsMonthIncentivePage';
import { TransactionsMonthPayItemPage } from './TransactionsMonthPayItemPage';
import { TransactionsMonthPenaltyPage } from './TransactionsMonthPenaltyPage';

export const TransactionsMonthPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: pay } = useQuery<PayItem>(`/pays/${id}`, fetcher, {
    keepPreviousData: true,
  });

  const { data: payItems } = useQuery<PayItem[]>(
    `/pay-items/pay/${id}`,
    fetcher,
    { keepPreviousData: true, enabled: !!id }
  );

  const incentives = payItems?.filter(
    (pi) => pi.type === PayItemType.INCENTIVE
  );
  const penalties = payItems?.filter((pi) => pi.type === PayItemType.PENALTY);
  const liveSettlementData = payItems?.filter(
    (payItem) => payItem.type === PayItemType.LIVE_SETTLEMENT
  );

  const liveSettlementTotal =
    liveSettlementData &&
    liveSettlementData?.length > 0 &&
    liveSettlementData
      ?.map((payItem) => payItem.value)
      ?.reduce((a, b) => a + b);
  const incentiveTotal =
    incentives &&
    incentives?.length > 0 &&
    incentives?.map((payItem) => payItem.value).reduce((a, b) => a + b);
  const penaltyTotal =
    penalties &&
    penalties?.length > 0 &&
    penalties?.map((payItem) => payItem.value).reduce((a, b) => a + b);

  const total =
    (liveSettlementTotal ? liveSettlementTotal : 0) +
    (incentiveTotal ? incentiveTotal : 0) -
    (penaltyTotal ? penaltyTotal : 0);

  if (!pay) return <></>;
  return (
    <>
      <div>
        <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden p-5 my-5 space-y-5 hidden md:block">
          <div className="flex justify-between">
            <div className="flex items-center space-x-3">
              <H5 className="flex">
                {pay.teacher.name}님 정산 내역 {'>'}{' '}
                <> {moment(pay.time).format('YYYY년 MM월')}</>
              </H5>
            </div>
            <div className="h-12 filled-gray-100 text-black flex items-center justify-center px-5 rounded-md">
              총 정산 비용{' '}
              {`${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}원
            </div>
          </div>

          <div className="border-b border-gray-100 bg-white">
            <div className="mx-auto pt-4 px-4 w-full max-w-5xl flex space-x-4">
              <Tab
                title="정산내역"
                path={`/teacher/mypage/transactions/${id}/payItem`}
              />
              <Tab
                title="장려금 내역"
                path={`/teacher/mypage/transactions/${id}/incentive`}
              />
              <Tab
                title="패널티 내역"
                path={`/teacher/mypage/transactions/${id}/penalty`}
              />
            </div>
          </div>
          <Switch>
            <Route
              path="/teacher/mypage/transactions/:id/payItem"
              component={TransactionsMonthPayItemPage}
            />
            <Route
              path="/teacher/mypage/transactions/:id/incentive"
              component={TransactionsMonthIncentivePage}
            />
            <Route
              path="/teacher/mypage/transactions/:id/penalty"
              component={TransactionsMonthPenaltyPage}
            />
            <Redirect
              from="/teacher/mypage/transactions/:id"
              to="/teacher/mypage/transactions/:id/payItem"
            />
          </Switch>
        </div>

        <div className="block md:hidden w-full">
          <MobileHelper />
        </div>
      </div>
    </>
  );
};
