import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { CumulativePoint } from '../../types';

interface FormValues {
  initializationTime: string;
}

interface AdminCumulativeResetTimerProps {
  cumulativePoint?: CumulativePoint;
  onClose: () => void;
  refetch: () => void;
}

export const AdminCumulativeResetTimer: FC<AdminCumulativeResetTimerProps> = ({
  cumulativePoint,
  onClose,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const update = handleSubmit(async (data) => {
    try {
      await api.patch(`/admin/cumulative-points/reset/timer`, data);
      await refetch();
      await onClose();
      alert('리셋 예약이 설정되었습니다.');
    } catch (e) {
      console.error('수강권 유효기간 변경 오류', e);
    }
  });

  useEffect(() => {
    if (!cumulativePoint?.initializationTime) return;

    const resetTime = new Date(cumulativePoint?.initializationTime || '');
    resetTime.setHours(resetTime.getHours() + 9);

    setValue(
      'initializationTime',
      resetTime.toISOString().replace('T', ' ').substring(0, 19)
    );
  }, [cumulativePoint, setValue]);

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">리셋 예약시간 설정</H3>

        <TextField
          label="예약시간"
          placeholder="예약시간을 입력해주세요."
          type="datetime-local"
          max={'9999-12-31'}
          helper={errors.initializationTime?.message}
          {...register('initializationTime', {
            required: '예약시간을 입력해주세요.',
          })}
        />

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          onClick={update}
        />
      </div>
    </div>
  );
};
