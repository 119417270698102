import React from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import { H5 } from '../../components/H5';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Notice, Paginated } from '../../types';

export const NoticesPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');
  const limit = Number(params.get('limit') ?? '10');
  const floor = page - 1;
  const queryString = useQueryString();

  const { data: notices } = useQuery<Paginated<Notice>>(
    `/notices${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <>
      <H5 className="pb-5">공지사항</H5>
      <div className="bg-white rounded-md shadow-3xl p-5">
        <Table className="divide-gray-100 ">
          <Table.Head className="bg-transparent">
            <Table.Row>
              <Table.Th>번호</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>등록일자</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body className="divide-gray-50">
            {notices?.items.map((notice, index) => (
              <Table.Row key={notice.id}>
                <Table.Td>
                  <p>
                    {page > 1
                      ? floor && notices.total - floor * limit - index
                      : notices.total - index}
                  </p>
                </Table.Td>
                <Table.Td className="font-normal">
                  <Link to={`/parent/notices/${notice.id}`}>
                    {notice.title}
                  </Link>
                </Table.Td>
                <Table.Td className="font-normal">
                  {utcToLocalFormat(notice.createdAt, MomentFormat.YYYYMMDD)}
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={notices?.items.length || 0}
            total={notices?.total || 0}
          />
          <Pagination.Nav
            basePath="/parent/notices"
            total={notices?.total || 0}
          />
        </Pagination>
      </div>
    </>
  );
};
