import React, { FC } from 'react';
import { ThirdSection } from './lesson-introduction/ThirdSection';
import { FourthSection } from './lesson-introduction/FourthSection';
import { FifthSection } from './lesson-introduction/FifthSection';

interface LessonIntroductionProps {}

export const LessonIntroduction: FC<LessonIntroductionProps> = () => {
  return (
    <>
      <div className="relative">
        {/* 자연스러운 예습을 돕는 튼튼영어라이브 회원 전용 디지털학습 */}
        <ThirdSection />

        {/* 베테랑 라이브쌤과 함께하는 25분의 짜임새 있는 수업! */}
        <FourthSection />
      </div>

      {/* 칭찬 뿐인 피드백은 NO!체계적이고 솔직한 피드백은 YES! */}
      <FifthSection />
    </>
  );
};
