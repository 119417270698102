import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { usePopup } from '../../components/PopUp';
import { api, apiUrl } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { popupState } from '../../plugins/ridge';
import { TeacherContractCreate } from './TeacherContractCreate';

export const TeacherContracts = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { addContractPopup } = usePopup();
  const fileInputRef = useRef<any>(null);

  const [file, setFile] = useState<any>();
  const [fileData, setFileData] = useState<any>();
  const [createMode, setCreateMode] = useState(false);

  const { data: contracts, refetch } = useQuery<any>(
    `/admin/contracts/query?teacherId=${id}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (createMode) {
    return (
      <TeacherContractCreate
        id={Number(id)}
        _file={file}
        _fileData={fileData}
        goBack={() => {
          refetch();
          setCreateMode(false);
        }}
      />
    );
  }

  return (
    <>
      <div className="w-full h-full">
        <div className="font-bold text-lg">계약관리</div>
        <div className="my-5 w-full mx-auto grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 px-5 xl:px-0">
          {contracts
            ?.slice()
            ?.sort(
              (a: any, b: any) =>
                new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            ?.map((contract: any) => (
              <div className="rounded-lg bg-white shadow-lg overflow-hidden p-5 m-2 relative">
                {contract?.isSigned && (
                  <div
                    className="absolute right-16 z-50 cursor-pointer text-primary-1"
                    onClick={() =>
                      window.confirm('계약서를 무효 처리 하시겠습니까?') &&
                      api
                        .patch(`admin/contracts/${contract.id}`, {
                          isSigned: false,
                        })
                        .then(() =>
                          alert('해당 계약서가 무효 처리 되었습니다.')
                        )
                        .catch((err) => console.log('계약서 삭제 오류', err))
                    }
                  >
                    <span>무효 처리</span>
                  </div>
                )}

                <div
                  className="absolute right-5 z-50 cursor-pointer text-red-500"
                  onClick={() =>
                    window.confirm('계약서를 정말 삭제 하시겠습니까?') &&
                    api
                      .delete(`admin/contracts/${contract.id}`)
                      .then(() => alert('해당 계약서가 삭제 되었습니다.'))
                      .catch((err) => console.log('계약서 삭제 오류', err))
                  }
                >
                  <span>삭제</span>
                </div>
                <div className="py-14 text-center space-y-1">
                  <div className="text-lg font-semibold">튼튼영어 계약서</div>
                  <div>
                    {utcToLocalFormat(contract.date, MomentFormat.YYYYMMDD)}
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <Button
                    className={`w-full ${
                      contract?.isSigned
                        ? 'outlined-primary-1'
                        : 'filled-primary-1'
                    }`}
                    text={`${
                      contract?.isSigned ? '계약서 확인 완료' : '계약서 대기중'
                    }`}
                    onClick={() =>
                      history.push(
                        `/admin/teachers/${id}/contracts/${contract.id}`
                      )
                    }
                  />
                  {contract?.isSigned && (
                    <div className="w-full grid text-white place-items-center bg-primary-1 h-12 rounded-xl">
                      <a
                        href={apiUrl(contract?.signedPdf)}
                        download
                        target="_blank"
                        rel="noreferrer"
                      >
                        계약서 다운로드
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div
            className="rounded-lg bg-white shadow-lg overflow-hidden px-5 m-2 cursor-pointer py-24"
            onClick={() =>
              addContractPopup(
                () =>
                  fileInputRef?.current?.click && fileInputRef.current.click(),
                () => popupState.reset()
              )
            }
          >
            <div className="w-full h-full flex items-center justify-center">
              <div className="text-lg font-semibold">✍🏻 계약서 추가 +</div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        className="hidden"
        accept="application/pdf"
        ref={fileInputRef}
        onChange={(e) => {
          if (e.target.validity.valid) {
            setFile(e.target.files?.item(0));
            let reader = new FileReader();
            reader.readAsArrayBuffer(e.target.files?.item(0) || new Blob());
            reader.onload = function () {
              setFileData(reader.result);
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
            setCreateMode(true);
          }
        }}
      />
      {/* <form className="uploader" encType="multipart/form-data">
        <input
          type="file"
          className="hidden"
          multiple
          accept="application/pdf"
          ref={fileInputRef}
          onChange={(e) => {
            if (e.target.files && e.target.files?.length !== 0) {
              if (e.target.validity.valid) {
                setFile(e.target.files);
                const fileList = Array.from(e.target.files);
                fileList.map((f) => {
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(f || new Blob());
                  reader.onload = function () {
                    setFileData(fileList);
                  };
                  reader.onerror = function (error) {
                    console.log('Error: ', error);
                  };
                  return f;
                });
                setCreateMode(true);
              }
            }
          }}
        />
      </form> */}
    </>
  );
};
