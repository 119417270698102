import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { api, apiUrl } from '../../plugins/axios';
import { LevelTest } from '../../plugins/leveltest';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import {
  Book,
  Course,
  Entry,
  Gender,
  Level,
  Paginated,
  Reinforce,
  Series,
  Student,
  TestResult,
  classTypeToText,
} from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';
import { PlusIcon } from '../../components/icons';
import { resizeImage } from '../../plugins/compress';
import { AdminCoursePopUp } from '../components/AdminCoursePopUp';
import { parse } from 'qs';

interface FormValues {
  avatar: File;
  name: string;
  dob: string;
  memo: string;
  gender: Gender;
  nextLevelId: number;
  nextSeriesId: number;
  nextBookId: number;
}

export const UserStudents = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { itemId } = parse(search, { ignoreQueryPrefix: true });
  const [editMode, setEditMode] = useState(false);
  const [studentId, setStudentId] = useState<number>();
  const [isOpenCount, setIsOpenCount] = useState<boolean>(false);

  const courseStudentIdQueryString = useQueryString({
    filter: { studentId: studentId },
    join: ['startSeries', 'startBook', 'startLevel'],
  });
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: students, refetch: refetchStudents } = useQuery<Student[]>(
    `/admin/students/user/${id}`,
    fetcher,
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  const { data: testResults } = useQuery<TestResult[]>(
    `/admin/test-results/student/${studentId}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  const { data: courses, refetch } = useQuery<Paginated<Course>>(
    `/admin/courses/${courseStudentIdQueryString}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  useEffect(() => {
    if (studentId) return;
    if (!students || students.length === 0) return;
    if (itemId) {
      setStudentId(
        students?.find((student) => student.id === Number(itemId))?.id
      );
    } else {
      setStudentId(students[0].id);
    }
  }, [itemId, studentId, students]);

  const student = students?.find((student) => student.id === Number(studentId));

  const { data: entries } = useQuery<Entry>(
    `/admin/entries/admin-entry/${testResults && testResults[0]?.id}`,
    fetcher,
    { enabled: testResults && !!testResults[0]?.id }
  );

  useEffect(() => {
    if (!student) return;
    setValue('name', student.name);
    setValue('dob', student.dob);
    setValue('memo', student.memo);
    setValue('gender', student.gender);
    setValue('nextLevelId', student.nextLevelId);
    setValue('nextSeriesId', student.nextSeriesId);
    setValue('nextBookId', student.nextBookId);
  }, [student, setValue, editMode]);

  // levels
  const levelQuery = useQueryString({
    sort: { index: 'ASC' },
  });
  const { data: levels } = useQuery<Paginated<Level>>(
    `/admin/levels${levelQuery}`,
    fetcher
  );

  //series
  const seriesQuery = useQueryString({
    sort: { index: 'ASC' },
    filter: { levelId: watch('nextLevelId') },
  });
  const { data: serieses } = useQuery<Paginated<Series>>(
    `/admin/series${seriesQuery}`,
    fetcher,
    { enabled: !!watch('nextLevelId') }
  );

  // books
  const bookQuery = useQueryString({
    filter: { seriesId: watch('nextSeriesId') },
    sort: { index: 'ASC' },
  });
  const { data: books } = useQuery<Paginated<Book>>(
    `/admin/books${bookQuery}`,
    fetcher,
    { enabled: !!watch('nextSeriesId'), keepPreviousData: true }
  );

  const { data: reinforces } = useQuery<Reinforce[]>(
    `/admin/reinforces/student/${studentId}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  const testResult = testResults?.sort((a, b) => b.id - a.id)[0];

  if (!students || !student) return <>아직 자녀 정보가 없습니다.</>;
  return (
    <>
      <div className="flex space-x-5">
        <AdminTabs>
          {students?.map((student) => (
            <AdminTab
              key={student.id}
              text={student.name}
              selected={student.id === studentId}
              onClick={() => setStudentId(student.id)}
            />
          ))}
        </AdminTabs>
      </div>

      <div className="space-y-20">
        <div className="space-y-4">
          <div className="flex justify-between mb-3">
            <AdminH1>자녀 정보</AdminH1>

            <Button
              text="보강권 생성하기"
              className="px-3 py-2 text-md outlined-primary-1 hover:bg-primary-1 hover:text-white"
              to={`/admin/reinforces/add?userId=${id}&studentId=${studentId}`}
            />
          </div>

          <AdminCard>
            <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
              <label htmlFor="avatar" className="relative w-max cursor-pointer">
                <Avatar
                  src={
                    watch('avatar')
                      ? URL.createObjectURL(watch('avatar'))
                      : apiUrl(student?.avatar)
                  }
                  className="wh-28"
                />
                <div className="absolute right-0 bottom-0 bg-primary-1 rounded-full wh-7 grid place-content-center">
                  <PlusIcon className="text-white fill-current wh-3" />
                </div>
                <input
                  id="avatar"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  disabled={!editMode}
                  onChange={async (e) => {
                    const file = e.target.files?.item(0);
                    if (!file || !e.target.validity.valid) return;
                    setValue('avatar', await resizeImage(file));
                  }}
                />
              </label>
              <TextField
                label="자녀 이름*"
                helper={errors.name?.message}
                disabled={!editMode}
                {...register('name', {
                  required: '자녀 이름을 입력해주세요.',
                })}
              />
              <TextField
                label="생년월일*"
                disabled={!editMode}
                helper={errors.dob?.message}
                {...register('dob', {
                  required: '자녀 생년월일을 입력해주세요.',
                })}
              />
              <TextField
                label="나이"
                disabled
                value={`${
                  new Date().getFullYear() -
                  new Date(student?.dob?.substr(0, 4) || 0).getFullYear() +
                  1
                }세`}
              />
              <Select
                label="성별*"
                disabled={!editMode}
                helper={errors.gender?.message}
                {...register('gender', {
                  required: '자녀 성별을 입력해주세요.',
                })}
              >
                <option value={Gender.MALE}>남자</option>
                <option value={Gender.FEMALE}>여자</option>
              </Select>

              <TextField
                label="학습수준"
                value={
                  testResult?.isSkip
                    ? '레벨테스트를 응시하지 않았습니다.'
                    : entries
                    ? entries.levelCode === '1-A'
                      ? '알파벳부터 시작해야하는 단계입니다.'
                      : entries.levelCode === '1-B'
                      ? '알파벳부터 시작해야하는 단계입니다.'
                      : entries.levelCode === '1-C'
                      ? '알파벳을 알고 있으며, 기초단어를 듣고, 따라 말할 수 있습니다.'
                      : entries.levelCode === '1-D'
                      ? '알파벳을 알고 있으며, 기초단어를 듣고, 따라 말할 수 있습니다.'
                      : entries.levelCode === '2-A'
                      ? '알고 있는 여러 영어 단어들을 활용해, 자신의 의사를 표현할 수 있습니다.'
                      : entries.levelCode === '2-B'
                      ? '알고 있는 여러 영어 단어들을 활용해, 자신의 의사를 표현할 수 있습니다.'
                      : entries.levelCode === '2-C'
                      ? '일상 주제에 대해 하나의 완벽한 문장을 말할 수 있습니다.'
                      : entries.levelCode === '3-A'
                      ? '일상 주제에 대해 하나의 완벽한 문장을 말할 수 있습니다.'
                      : entries.levelCode === '3-B'
                      ? '다양한 주제에 대해 여러 문장을 이어 말할 수 있습니다.'
                      : entries.levelCode === '3-C'
                      ? '전문 주제에 대한 중~장편의 글을 읽고 내용을 이해할 수 있습니다.'
                      : ''
                    : '아직 레벨테스트 결과가 없습니다.'
                }
                disabled
              />

              <Select
                label="다음 레벨"
                value={watch('nextLevelId')}
                disabled={!editMode}
                onChange={(e) => {
                  if (Number(e.target.value) === 0) {
                    setValue('nextLevelId', Number(e.target.value));
                    setValue('nextSeriesId', 0);
                    setValue('nextBookId', 0);
                  } else {
                    setValue('nextLevelId', Number(e.target.value));
                  }
                }}
              >
                <option label="레벨을 선택해주세요." value="" selected>
                  레벨을 선택해주세요.
                </option>
                {levels?.items
                  ?.sort((a, b) => a.id - b.id)
                  .map((level) => (
                    <option key={level.id} label={level.name} value={level.id}>
                      {level.name}
                    </option>
                  ))}
              </Select>

              <Select
                label="다음 시리즈"
                value={watch('nextSeriesId')}
                disabled={!editMode}
                onChange={(e) =>
                  setValue('nextSeriesId', Number(e.target.value))
                }
              >
                <option label="교재를 선택해주세요." value="" selected>
                  교재를 선택해주세요.
                </option>
                {serieses?.items?.map((series) => (
                  <option key={series.id} label={series.name} value={series.id}>
                    {series.name}
                  </option>
                ))}
              </Select>

              <Select
                label="다음 진도"
                value={watch('nextBookId')}
                disabled={!editMode}
                onChange={(e) => setValue('nextBookId', Number(e.target.value))}
              >
                <option label="진도를 선택해주세요." value="" selected>
                  진도를 선택해주세요.
                </option>
                {books?.items?.map((book) => (
                  <option key={book.id} label={book.title} value={book.id}>
                    {book.title}
                  </option>
                ))}
              </Select>

              <TextField
                label="유입 경로"
                disabled
                value={student.inflowPath}
              />
            </div>
            <div className="px-4 sm:px-6 md:px-8">
              <TextArea
                label="관리자 메모"
                {...register('memo')}
                disabled={!editMode}
              />
            </div>
            <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
              {editMode && (
                <Button
                  text="취소"
                  className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                  onClick={() => setEditMode(false)}
                />
              )}
              {editMode && (
                <Button
                  text="저장"
                  className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                  onClick={handleSubmit(async (data) => {
                    if (watch('nextLevelId') && !watch('nextSeriesId')) {
                      alert('시리즈를 선택해주세요.');
                      return;
                    }
                    if (
                      watch('nextLevelId') &&
                      watch('nextSeriesId') &&
                      !watch('nextBookId')
                    ) {
                      alert('교재를 선택해주세요.');
                      return;
                    }

                    const formData = new FormData();
                    Object.entries(data).forEach(([name, value]) =>
                      formData.append(name, value)
                    );
                    await api.patch(`/admin/students/${student?.id}`, formData);
                    await refetchStudents();
                    setEditMode(false);
                  })}
                />
              )}
              {!editMode && (
                <Button
                  text="수정"
                  className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                  onClick={() => setEditMode(true)}
                />
              )}
            </div>
          </AdminCard>
        </div>

        <hr />

        <div className="space-y-4">
          <div className="flex justify-between">
            <AdminH1>레벨테스트 정보</AdminH1>
            <div className="flex space-x-2">
              <Button
                text="레벨테스트 생성"
                className="bg-white text-primary-1"
                onClick={async () => {
                  window.confirm('정말 레벨테스트를 생성 하시겠습니까?') &&
                    (await api.post(`/admin/test-results`, {
                      type: 'A',
                      score: 0,
                      score100: 0,
                      studentId: studentId,
                      userId: Number(id),
                      activeStudentId: studentId,
                      levelId: 1,
                      seriesId: 1,
                      bookId: 2,
                    })) &&
                    (await api.patch(`/admin/students/${student?.id}`, {
                      isTested: true,
                    }));
                }}
              />
              <Button
                text="레벨테스트 리셋"
                className="bg-primary-1 text-white"
                onClick={async () => {
                  window.confirm('정말 레벨테스트를 초기화 하시겠습니까?') &&
                    (await api.patch(`/admin/students/${student?.id}`, {
                      isTested: false,
                    }));
                }}
              />
            </div>
          </div>

          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>날짜</Table.Th>
                  <Table.Th>레벨</Table.Th>
                  <Table.Th>교재</Table.Th>
                  <Table.Th>진도</Table.Th>
                  <Table.Th></Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {testResults?.length === 0
                  ? '아직 레벨테스트 결과가 없습니다.'
                  : testResults?.map((testResult) => (
                      <Table.Row key={testResult.id}>
                        <Table.Td>
                          {utcToLocalFormat(
                            testResult.createdAt,
                            MomentFormat.YYYYMMDDHmm
                          )}
                        </Table.Td>
                        <Table.Td>{testResult.levelId}</Table.Td>
                        <Table.Td>{testResult.series?.name}</Table.Td>
                        <Table.Td>{testResult.bookId}권</Table.Td>
                        {testResult.json ? (
                          <>
                            <Table.Td className="text-right">
                              <div
                                className="font-normal text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem(
                                    'testResult',
                                    testResult.json
                                  );
                                  localStorage.setItem(
                                    'isTested',
                                    student.isTested ? 'true' : 'false'
                                  );
                                  localStorage.setItem(
                                    'studentName',
                                    student.name
                                  );
                                  LevelTest.openTest(testResult.type);
                                }}
                              >
                                결과보기
                              </div>
                            </Table.Td>
                            <Table.Td className="text-right">
                              <div
                                className="font-normal text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                onClick={() => {
                                  localStorage.setItem(
                                    'testResult',
                                    testResult.json
                                  );
                                  LevelTest.openResult();
                                }}
                              >
                                성적표 보기
                              </div>
                            </Table.Td>
                          </>
                        ) : testResult.isSkip ? (
                          <Table.Td className="text-right">
                            레벨테스트 미응시
                          </Table.Td>
                        ) : (
                          <Table.Td className="text-right">
                            관리자 임의 생성 레벨테스트
                          </Table.Td>
                        )}
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          </AdminCard>
        </div>

        <hr />

        <div className="space-y-4">
          <div className="flex justify-between">
            <AdminH1>수업 구매 정보</AdminH1>
            <div className="flex space-x-2">
              <Button
                text="수강권 발급"
                className="bg-primary-1 text-white"
                onClick={() => setIsOpenCount(true)}
              />
            </div>
          </div>

          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>구분</Table.Th>
                  <Table.Th>구매일자</Table.Th>
                  <Table.Th>시작일자</Table.Th>
                  <Table.Th>종료일자</Table.Th>
                  <Table.Th>코스</Table.Th>
                  <Table.Th>시작레벨</Table.Th>
                  <Table.Th>시작교재</Table.Th>
                  <Table.Th>시작넘버링</Table.Th>
                  <Table.Th>비활성화</Table.Th>
                  <Table.Th>메모</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {courses?.items.length === 0
                  ? '아직 구매한 수강권이 없습니다.'
                  : courses?.items.map((course) => {
                      const entryLevel =
                        entries?.levelCode === '1-A' ||
                        entries?.levelCode === '1-B' ||
                        entries?.levelCode === '1-C' ||
                        entries?.levelCode === '1-D'
                          ? 'Level 1'
                          : entries?.levelCode === '2-A' ||
                            entries?.levelCode === '2-B' ||
                            entries?.levelCode === '2-C'
                          ? 'Level 2'
                          : entries?.levelCode === '3-A' ||
                            entries?.levelCode === '3-B' ||
                            entries?.levelCode === '3-C'
                          ? 'Level 3'
                          : '';

                      const entrySeries =
                        entries?.levelCode === '1-A' ||
                        entries?.levelCode === '1-B'
                          ? 'I Love Stories 1권'
                          : entries?.levelCode === '1-C' ||
                            entries?.levelCode === '1-D'
                          ? 'Reading Rookie 1-1권'
                          : entries?.levelCode === '2-A' ||
                            entries?.levelCode === '2-B'
                          ? `What's Up 1권`
                          : entries?.levelCode === '2-C'
                          ? 'Reading Rookie 2-7권'
                          : entries?.levelCode === '3-A' ||
                            entries?.levelCode === '3-B'
                          ? 'Joy the Tomboy 1권'
                          : entries?.levelCode === '3-C'
                          ? 'My Reading Rainbow 1권'
                          : '';
                      return (
                        <Table.Row key={course.id}>
                          <Table.Td>
                            {course.isTrial && course.initialAmount === 1
                              ? '체험'
                              : course.isTrial && course.initialAmount > 1
                              ? '체험팩'
                              : classTypeToText(course?.startLevel?.classType)}
                          </Table.Td>
                          <Table.Td>
                            {utcToLocalFormat(
                              course.createdAt,
                              MomentFormat.YYYYMMDD
                            )}
                          </Table.Td>
                          <Table.Td>
                            {course.startedAt ? (
                              <>
                                {utcToLocalFormat(
                                  course.startedAt,
                                  MomentFormat.YYYYMMDD
                                )}
                              </>
                            ) : (
                              '-'
                            )}
                          </Table.Td>
                          <Table.Td>
                            {course.endedAt ? (
                              <>
                                {utcToLocalFormat(
                                  course.endedAt,
                                  MomentFormat.YYYYMMDD
                                )}
                              </>
                            ) : (
                              '-'
                            )}
                          </Table.Td>
                          <Table.Td>{course.initialAmount}회</Table.Td>
                          <Table.Td>
                            {course.startLevelId
                              ? course.startLevel.name //`${course.startLevelId}단계`
                              : entryLevel}
                          </Table.Td>
                          <Table.Td>
                            {course.startSeries
                              ? course.startSeries?.name
                              : entrySeries}
                          </Table.Td>
                          <Table.Td>
                            {course.startBookId
                              ? `${course.startBook?.title}`
                              : '-'}
                          </Table.Td>
                          <Table.Td>{course.isHide ? 'O' : 'X'}</Table.Td>
                          <Table.Td>{course.memo}</Table.Td>
                          <Table.Td className="text-right">
                            <Link
                              className="font-normal text-indigo-600 hover:text-indigo-900"
                              to={`/admin/users/${id}/students/${student.id}/courses/${course.id}`}
                            >
                              상세보기
                            </Link>
                          </Table.Td>
                        </Table.Row>
                      );
                    })}
              </Table.Body>
            </Table>

            <Pagination>
              <Pagination.Label
                count={courses?.items.length || 0}
                total={courses?.total || 0}
              />
              <Pagination.Nav
                basePath={`/admin/users/${id}/students`}
                total={courses?.total || 0}
              />
            </Pagination>
          </AdminCard>
        </div>

        <hr />

        <div className="space-y-4">
          <AdminH1>보강권 구매 정보</AdminH1>

          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>학생</Table.Th>
                  <Table.Th>횟수</Table.Th>
                  <Table.Th>금액</Table.Th>
                  <Table.Th>결제 여부</Table.Th>
                  <Table.Th>메모</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {reinforces?.length === 0
                  ? '아직 보강권 구매이력이 없습니다.'
                  : reinforces?.map((reinforce) => (
                      <Table.Row key={reinforce.id}>
                        <Table.Td>{reinforce.student.name}</Table.Td>
                        <Table.Td>{reinforce.amount}회</Table.Td>
                        <Table.Td>
                          {` ${String(reinforce.price).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}원`}
                        </Table.Td>
                        <Table.Td>
                          {reinforce.isPaid ? '결제완료' : '미결제'}
                        </Table.Td>
                        <Table.Td>{reinforce.memo}</Table.Td>
                        <Table.Td className="text-right">
                          <Link
                            className="font-normal text-indigo-600 hover:text-indigo-900"
                            to={`/admin/reinforces/${reinforce.id}`}
                          >
                            상세보기
                          </Link>
                        </Table.Td>
                      </Table.Row>
                    ))}
              </Table.Body>
            </Table>
          </AdminCard>
        </div>
      </div>
      {isOpenCount && (
        <AdminCoursePopUp
          courseCreate={true}
          student={student}
          refetch={() => {
            refetch();
            refetchStudents();
          }}
          onClose={() => setIsOpenCount(false)}
        />
      )}
    </>
  );
};
