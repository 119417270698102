import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Product, classTypeToText } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const Products = () => {
  const queryString = useQueryString({
    sort: { classType: 'ASC', liveType: 'ASC', index: 'ASC' },
  });
  const { data } = useQuery<Paginated<Product>>(
    `/admin/products${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>상품 관리</AdminH1>
        <Button
          text="상품 추가"
          to="/admin/products/add"
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>수업종류</Table.Th>
              <Table.Th>상품명</Table.Th>
              <Table.Th>수량</Table.Th>
              <Table.Th>단계</Table.Th>
              <Table.Th>정가</Table.Th>
              <Table.Th>할인율</Table.Th>
              <Table.Th>최종 금액</Table.Th>
              <Table.Th>노출여부</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((product) => (
              <Table.Row key={product.id}>
                <Table.Td>{classTypeToText(product.classType)}</Table.Td>
                <Table.Td>{product.name}</Table.Td>
                <Table.Td>{product.amount}</Table.Td>
                <Table.Td>
                  {product.liveType === 'TRIAL'
                    ? '체험'
                    : product.liveType === 'FIRST'
                    ? '1,2 단계'
                    : product.liveType === 'SECOND'
                    ? '3,4 단계'
                    : ''}
                </Table.Td>
                <Table.Td>{product.originalPrice}</Table.Td>
                <Table.Td>{product.discountRate}</Table.Td>
                <Table.Td>{product.finalPrice}</Table.Td>
                <Table.Td>{product.open ? 'O' : 'X'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/products/${product.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/products" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
