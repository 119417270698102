import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { H5 } from '../../components/H5';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Live, User } from '../../types';

interface FormValues {
  name: string;
  email: string;
  phoneNumber: string;
  marketing: boolean;
  marketingDate: string;
}

export const MyPage = () => {
  const { logout } = useAuth();
  const [edit, setEdit] = useState<boolean>(false);
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [marketingPolicy, setMarketingPolicy] = useState<boolean>(false);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: user, refetch } = useQuery<User>(`/users/me`, fetcher, {
    keepPreviousData: true,
  });

  const { data: lives } = useQuery<Live[]>(`/lives/myStudent/lives`, fetcher, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!user) return;
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('marketingDate', user.marketingDate);
    setPhoneNum(user.phoneNumber);
    setMarketingPolicy(user.marketing);
  }, [setValue, user]);

  return (
    <>
      <H5 className="pb-5">내 정보수정</H5>

      <div className="bg-white shadow-3xl rounded-md p-5">
        <form className="max-w-md space-y-4">
          <TextField
            className="py-8"
            placeholder="학부모(본인) 이름을 입력해주세요"
            disabled={!edit}
            helper={errors.name?.message}
            {...register('name', {
              required: '학부모(본인) 이름을 입력해주세요',
            })}
          />
          <TextField
            type="email"
            className="py-8"
            placeholder="이메일을 입력해주세요."
            disabled={!edit}
            helper={errors.email?.message}
            {...register('email', {
              required: '이메일을 입력해주세요.',
            })}
          />
          <PhoneNumber
            label="휴대폰 인증 *"
            phoneNum={phoneNum}
            setPhoneNum={setPhoneNum}
            disabled={!edit}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            setPhoneValue={setPhoneValue}
          />

          <Checkbox
            className="mb-8"
            disabled={!edit}
            label="마케팅 및 이벤트 소식을 수신하고 싶습니다. (선택)"
            onChange={() => setMarketingPolicy(!marketingPolicy)}
            checked={marketingPolicy}
          />
          {edit && (
            <Button
              text="저장하기"
              className="filled-primary-1 w-full"
              disabled={
                !watch('name') ||
                !watch('email') ||
                (phoneValue.length > 0 && !isVerified)
              }
              onClick={handleSubmit(async (data) => {
                if (user?.phoneNumber !== phoneNum) {
                  data.phoneNumber = phoneNum;
                }
                data.marketing = marketingPolicy;
                await api.patch(`/users/me`, data);
                setEdit(false);
                refetch();
                alert('저장되었습니다.');
              })}
            />
          )}
          {!edit && (
            <Button
              text="수정하기"
              className="filled-primary-1 w-full"
              onClick={() => {
                setEdit(true);
                refetch();
              }}
            />
          )}
        </form>
        <div className="max-w-md mt-4">
          <Button
            text="회원 탈퇴하기"
            className="filled-primary-1 w-full"
            onClick={() => {
              if (lives && lives.length > 0) {
                alert(
                  '현재 예약된 수업이 있습니다.예약 취소 후 탈퇴가 가능합니다.'
                );
              } else {
                window.confirm('정말 탈퇴하시겠습니까?') &&
                  api.patch(`/users/isDisabled`).then(() => logout());
              }
            }}
          />
        </div>
      </div>
    </>
  );
};
