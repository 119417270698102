import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Tab } from '../../components/Tab';
import { ContractDetailPage } from './ContractDetailPage';
import { ContractsPage } from './ContractsPage';
import { ProfileEditPage } from './ProfileEditPage';
import { TransactionsMonthPage } from './TransactionsMonthPage';
import { TransactionsPage } from './TransactionsPage';

export const MyPage = () => {
  return (
    <>
      <div className="border-b border-gray-100 bg-white">
        <div className="mx-auto pt-4 px-4 w-full max-w-5xl flex space-x-4">
          <Tab title="내 정보 수정" path="/teacher/mypage/edit" />
          <Tab title="정산내역" path="/teacher/mypage/transactions" />
          <Tab title="계약관리" path="/teacher/mypage/contracts" />
        </div>
      </div>
      <Switch>
        <Route
          path="/teacher/mypage/contract/:id"
          component={ContractDetailPage}
        />
        <Route path="/teacher/mypage/contracts" component={ContractsPage} />
        <Route path="/teacher/mypage/edit" component={ProfileEditPage} />
        <Route
          path="/teacher/mypage/transactions/:id"
          component={TransactionsMonthPage}
        />
        <Route
          path="/teacher/mypage/transactions"
          component={TransactionsPage}
        />
        <Redirect from="/" to="/teacher/mypage/edit" />
      </Switch>
    </>
  );
};
