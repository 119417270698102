import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Live, LiveState, Paginated, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';

export const TeacherLives = () => {
  const { id } = useParams<{ id: string }>();
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const queryString = useQueryString();

  const { data } = useQuery<Paginated<Live>>(
    `/admin/lives/teacher/all/${id}${queryString}&from=${from}&to=${to}`,
    fetcher,
    { keepPreviousData: true, enabled: !!id }
  );

  const { data: teacher } = useQuery<User>(`/admin/users/${id}`, fetcher, {
    keepPreviousData: true,
    enabled: !!id,
  });

  const { data: liveExport } = useQuery<Live[]>(
    `/admin/lives/teacher/export/${id}?from=${from}&to=${to}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );

  const exporter = () => {
    const teacherLiveData = liveExport?.map((live: any) => {
      const sameCanceled =
        (live.state === LiveState.CANCELED &&
          moment(live.start).isSame(live.canceledAt, 'd')) ||
        live.state === LiveState.CANCELLATION_THE_DAY;

      return {
        수업구분: `${
          !live.studentId || live.state === LiveState.AVAILABLE
            ? '-'
            : !live.course?.isTrial
            ? '정규'
            : '체험'
        }`,
        수업일정: moment(live.start).format('YYYY.MM.DD'),
        학생명: live.student?.name,
        진행상태: `${
          sameCanceled
            ? '당일 취소'
            : live.state === LiveState.AVAILABLE
            ? '예약가능'
            : live.state === LiveState.RESERVED
            ? '예약완료'
            : live.state === LiveState.CANCELED
            ? '취소'
            : live.state === LiveState.ATTENDED
            ? '출석'
            : live.state === LiveState.ABSENT
            ? '결석'
            : live.state === LiveState.ABSENT_AND_NO_SHOW
            ? '결석/선생님 노쇼'
            : live.state === LiveState.CANCELLATION_THE_DAY
            ? '당일취소'
            : '선생님 노쇼'
        }`,
      };
    });

    jsonExport(teacherLiveData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(`${BOM} ${csv}`, '선생님 수업 관리(개인)');
    });
  };

  const { data: lives } = useQuery<Live[]>(
    `/admin/lives/teacher/${id}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: !!id,
    }
  );

  const now = new Date();
  const thisMonthData = lives?.filter((live) => {
    const liveStartedAt = new Date(live.start);
    return moment(now).isSame(liveStartedAt, 'month');
  });
  const thisMonthOpenLives = thisMonthData?.filter(
    (live) =>
      live.state === LiveState.AVAILABLE ||
      live.state === LiveState.RESERVED ||
      live.state === LiveState.ATTENDED
  );
  const thisMonthStudentCount = thisMonthData?.filter((live) => live.studentId);
  const thisMonthStudentIds = thisMonthStudentCount?.map((i) => i.studentId);
  const thisStudentIds = thisMonthStudentIds?.filter(
    (v, i) => thisMonthStudentIds.indexOf(v) === i
  );

  const thisMonthDoneLiveCount = thisMonthData?.filter((live) => {
    return (
      live.state === LiveState.RESERVED || live.state === LiveState.ATTENDED
    );
  });

  let thisMonthCount = 0;
  if (thisMonthDoneLiveCount && thisMonthOpenLives) {
    const count =
      (thisMonthDoneLiveCount?.length === 0
        ? 0
        : thisMonthDoneLiveCount?.length / thisMonthOpenLives?.length) * 100;

    thisMonthCount = count;
  }

  const nextMonthData = lives?.filter((live) => {
    const liveStartedAt = new Date(live.start);
    return moment(now).add(1, 'month').isSame(liveStartedAt, 'month');
  });
  const nextMonthOpenLives = nextMonthData?.filter(
    (live) =>
      live.state === LiveState.AVAILABLE ||
      live.state === LiveState.RESERVED ||
      live.state === LiveState.ATTENDED
  );
  const nextMonthStudentCount = nextMonthData?.filter((live) => live.studentId);
  const nextMonthStudentIds = nextMonthStudentCount?.map((i) => i.studentId);
  const nextStudentIds = nextMonthStudentIds?.filter(
    (v, i) => nextMonthStudentIds.indexOf(v) === i
  );
  const nextMonthDoneLiveCount = nextMonthData?.filter((live) => {
    return (
      live.state === LiveState.RESERVED || live.state === LiveState.ATTENDED
    );
  });
  let nextMonthCount = 0;

  if (nextMonthDoneLiveCount && nextMonthOpenLives) {
    const nextCount =
      (nextMonthDoneLiveCount?.length === 0
        ? 0
        : nextMonthDoneLiveCount.length / nextMonthOpenLives?.length) * 100;

    nextMonthCount = nextCount;
  }

  if (!data || !teacher) return <></>;
  return (
    <div className="space-y-4">
      <div className="text-24 bg-white w-44 text-center rounded-lg py-1">
        {teacher?.name} 선생님
      </div>
      <div className="flex space-x-10">
        <div className="space-y-4">
          <AdminH1>이번달 예약률 </AdminH1>
          <AdminCard className="bg-blue-100">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>이번달 예약률</Table.Th>
                  <Table.Th>수업 오픈수</Table.Th>
                  <Table.Th>예약한 학생수</Table.Th>
                  <Table.Th>예약완료수</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row key={''}>
                  <Table.Td>{thisMonthCount.toLocaleString()}%</Table.Td>
                  <Table.Td>{thisMonthOpenLives?.length}</Table.Td>
                  <Table.Td>{thisStudentIds?.length}</Table.Td>
                  <Table.Td>{thisMonthDoneLiveCount?.length}</Table.Td>
                </Table.Row>
              </Table.Body>
            </Table>
          </AdminCard>
        </div>

        <div className="space-y-4">
          <AdminH1>다음달 예약률</AdminH1>
          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>다음달 예약률</Table.Th>
                  <Table.Th>수업 오픈수</Table.Th>
                  <Table.Th>예약한 학생수</Table.Th>
                  <Table.Th>예약완료수</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                <Table.Row key={''}>
                  <Table.Td>{nextMonthCount.toLocaleString()}%</Table.Td>
                  <Table.Td>{nextMonthOpenLives?.length}</Table.Td>
                  <Table.Td>{nextStudentIds?.length}</Table.Td>
                  <Table.Td>{nextMonthDoneLiveCount?.length}</Table.Td>
                </Table.Row>
              </Table.Body>
            </Table>
          </AdminCard>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <AdminH1>수업 내역</AdminH1>
        <div className="flex ml-auto space-x-4 bg-white border-1 p-1.5 rounded-lg">
          <div className="flex space-x-2 items-center">
            <TextField
              type="date"
              value={from}
              className="h-12"
              max="9999-12-31"
              onChange={(e) => setFrom(e.target.value)}
            />
            <div>~</div>
            <TextField
              type="date"
              value={to}
              className="h-12"
              max="9999-12-31"
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
          <Button
            className="bg-primary-1 h-12 text-white text-14"
            onClick={exporter}
          >
            파일 내보내기
          </Button>
        </div>
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>수업구분</Table.Th>
              <Table.Th>수업일정</Table.Th>
              <Table.Th>학생명</Table.Th>
              <Table.Th>진행상태</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((live) => {
              const sameCanceled =
                (live.state === LiveState.CANCELED &&
                  moment(live.start).isSame(live.canceledAt, 'd')) ||
                live.state === LiveState.CANCELLATION_THE_DAY;

              return (
                <Table.Row key={live.id}>
                  <Table.Td>
                    {!live.studentId || live.state === LiveState.AVAILABLE
                      ? '-'
                      : !live.course?.isTrial
                      ? '정규'
                      : '체험'}
                  </Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(live.start, MomentFormat.YYYYMMDDHmm)} ~
                    {utcToLocalFormat(live.end, MomentFormat.HHmm)}
                  </Table.Td>
                  <Table.Td>{live.student?.name}</Table.Td>
                  <Table.Td>
                    {sameCanceled
                      ? '당일 취소'
                      : live.state === LiveState.AVAILABLE
                      ? '예약가능'
                      : live.state === LiveState.RESERVED
                      ? '예약완료'
                      : live.state === LiveState.CANCELED
                      ? '취소'
                      : live.state === LiveState.ATTENDED
                      ? '출석'
                      : live.state === LiveState.ABSENT
                      ? '결석'
                      : live.state === LiveState.ABSENT_AND_NO_SHOW
                      ? '결석/선생님 노쇼'
                      : live.state === LiveState.CANCELLATION_THE_DAY
                      ? '당일취소'
                      : '선생님 노쇼'}
                  </Table.Td>
                  {live.state === LiveState.ATTENDED && live.courseItemId && (
                    <Table.Td className="text-right">
                      <Link
                        className="font-normal text-indigo-600 hover:text-indigo-900"
                        to={`/admin/users/${live.student?.userId}/students/${live.studentId}/courses/${live.courseId}/courseItems/${live.courseItemId}?liveId=${live.id}`}
                      >
                        상세보기
                      </Link>
                    </Table.Td>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data.items.length}
            total={data.items.length}
          />
          <Pagination.Nav
            basePath={`/admin/teachers/${id}/lives`}
            total={data.total}
          />
        </Pagination>
      </AdminCard>
    </div>
  );
};
