import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button';
import { ScheduleManager } from '../../components/ScheduleManager';
import { api } from '../../plugins/axios';
import { useWeek } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { popupState } from '../../plugins/ridge';
import { Live, LiveState, User } from '../../types';

export const ScheduleEditPage = () => {
  const { push } = useHistory();
  const { week, setWeek, from, to } = useWeek();
  const [isStart, setIsStart] = useState(false);

  const [selectedTimes, setSelectedTimes] = useState<Map<string, any>>(
    new Map()
  );

  const { data: me } = useQuery<User>('/users/me', fetcher);
  const { data: contracts } = useQuery('/contracts', fetcher);
  const { data: lives, refetch } = useQuery<Live[]>(
    `/lives?from=${from?.toISOString()}&to=${to?.toISOString()}`,
    fetcher
  );

  useEffect(() => {
    if (!lives) return;
    setSelectedTimes(
      new Map(
        lives
          .filter(
            (l) =>
              l.state === LiveState.ATTENDED ||
              l.state === LiveState.RESERVED ||
              l.state === LiveState.AVAILABLE ||
              l.state === LiveState.ABSENT_AND_NO_SHOW ||
              l.state === LiveState.NO_SHOW ||
              l.state === LiveState.ABSENT
          )
          .map((l) => [l.start, l])
      )
    );
  }, [lives]);

  const findLiveTimes = Array.from(selectedTimes.values()).filter(
    (live) => live.studentId
  );

  useEffect(() => {
    if (contracts && !contracts) {
      push('/teacher/schedule');
    }
    if (contracts && !contracts?.at(-1)?.isSigned) {
      push('/teacher/schedule');
    }
  }, [contracts, push]);

  return (
    <>
      <div className="max-w-5xl mx-auto flex justify-between items-center my-4">
        <div className="font-bold text-lg">🗒 스케줄</div>
        <Button
          className="h-10 rounded-lg filled-gray-800"
          onClick={() => {
            if (!isStart) {
              setIsStart(true);
              if (!me) {
                return;
              }
              if (me.isInActive) {
                popupState.set({
                  title: '스케줄 수정 안내',
                  body: `스케줄 설정이 불가합니다. 관리자에게 문의해주세요.`,
                  primaryText: '확인',
                  primaryClick: () =>
                    setSelectedTimes(new Map(lives?.map((l) => [l.start, l]))),
                });
              } else {
                api
                  .post('/lives/schedule', {
                    from,
                    to,
                    lives: Array.from(selectedTimes.values()),
                  })
                  .then(
                    async () => {
                      await refetch();
                      alert('스케줄이 저장 되었습니다.');
                      setIsStart(false);
                    }
                    //  push('/teacher/schedule')
                  );
              }
            }
          }}
        >
          저장하기
        </Button>
      </div>

      <div className="max-w-5xl mx-auto mb-10 p-10 rounded-lg overflow-hidden bg-white">
        <ScheduleManager
          from={from}
          to={to}
          onClickPrev={() => setWeek(week - 1)}
          onClickNext={() => setWeek(week + 1)}
          //당일 25일 기준 설정
          disabledTime={(time) => {
            const now = new Date();
            // const form = new Date();
            // form.setMonth(form.getMonth());
            // form.setDate(25);
            // const to = new Date();
            // to.setMonth(form.getMonth() + 1);
            // to.setDate(25);
            // if (moment(now) >= moment(form)) {
            //   return moment(to) >= time;
            // } else {
            //   return moment(form) >= time;
            // }
            return moment(now) >= moment(time);
          }}
          findLiveTimes={new Map(findLiveTimes.map((l) => [l.start, l]))}
          selectedTime={(time) => selectedTimes.has(time.toISOString())}
          onClickTime={(time) => {
            const start = time.toISOString();
            const end = time.clone().add(30, 'minutes');
            const newValue = new Map(selectedTimes);
            selectedTimes.has(start) ? (
              <>
                {findLiveTimes.find((live) => live.start === start)
                  ? ''
                  : newValue.delete(start)}
              </>
            ) : (
              newValue.set(start, { start, end })
            );
            return setSelectedTimes(newValue);
          }}
        />
      </div>
    </>
  );
};
