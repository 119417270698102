import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { PasswordFindPage } from './PasswordFindPage';
import { PasswordFindEmailPage } from './PasswordFindEmailPage';
import { PasswordFindPhonePage } from './PasswordFindPhonePage';
import { PasswordFindSentPage } from './PasswordFindSentPage';
import { PasswordResetPage } from './PasswordResetPage';
import { PasswordResetSuccessPage } from './PasswordResetSuccessPage';

export const PasswordPage = () => {
  return (
    <Switch>
      <Route
        path="/password/reset/success"
        component={PasswordResetSuccessPage}
      />
      <Route path="/password/reset/:token" component={PasswordResetPage} />
      <Route path="/password/find/email" component={PasswordFindEmailPage} />
      <Route path="/password/find/phone" component={PasswordFindPhonePage} />
      <Route path="/password/find/sent" component={PasswordFindSentPage} />
      <Route path="/password/find" component={PasswordFindPage} />
      <Redirect from="/" to="/password/find" />
    </Switch>
  );
};
