import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Img from '../../assets/img/login-img.png';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { Section } from '../../components/Section';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';

interface FormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="grid md:grid-cols-2">
      <Section>
        <div className="hidden md:fixed left-0 right-0 top-8">
          <Link to="/" className="block max-w-5xl mx-auto">
            <Logo />
          </Link>
        </div>
        <img
          src={Img}
          alt=""
          className="hidden md:block h-screen w-full object-cover"
        />
      </Section>

      <Section className="flex flex-col justify-center md:px-16 px-4 pt-20 md:pt-0">
        <H3>로그인</H3>
        <p className="text-15 text-gray-400 pt-3 py-8">
          환영합니다. 로그인해주세요.
        </p>
        <form
          onSubmit={handleSubmit((data) =>
            login(data).catch((err) => {
              if (err.response?.data?.statusCode === 404) {
                alert(err.response?.data?.message);
              } else {
                alert(err.response?.data?.message);
              }
            })
          )}
        >
          <div className="flex flex-col space-y-4">
            <TextField
              autoFocus={true}
              type="email"
              placeholder="이메일을 입력해주세요."
              helper={errors.email?.message}
              {...register('email', {
                required: '이메일을 입력해주세요.',
              })}
            />
            <TextField
              type="password"
              placeholder="비밀번호를 입력해주세요."
              helper={errors.password?.message}
              {...register('password', {
                required: '비밀번호를 입력해주세요.',
              })}
            />
            <Link to="/password" className="self-end text-14 text-gray-300">
              비밀번호를 잊으셨나요?
            </Link>
          </div>
          <Button text="로그인" className="filled-primary-1 mt-14 w-full" />
        </form>
        {/* <Link to="/signup" className="text-15 text-gray-300 w-max mt-3">
          아직 계정이 없으신가요?{' '}
          <span className="text-primary-1">회원가입하기</span>
        </Link> */}
      </Section>
    </div>
  );
};
