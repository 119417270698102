import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { H4 } from '../../components/H4';
import { MobileBackButton } from '../../components/MobileBackButton';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    event: 'signup',
  },
};

interface FormValues {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  marketing: boolean;
  inviteCode: string;
  privacyPolicy: boolean;
}

export const SignupPage = () => {
  const { signup } = useAuth();
  const { push } = useHistory();
  const [phoneNum, setPhoneNum] = useState('');
  const [agePolicy, setAgePolicy] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [marketingAgreement, setMarketingAgreement] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="flex flex-col max-w-md mx-auto md:pt-14 pb-16 px-4">
      <MobileBackButton />

      <H4 className="pb-8">간편 회원가입</H4>

      <p style={{ position: 'relative', top: '-20px' }}>
        ※회원가입 후 무료 체험수업 예약이 가능합니다.
      </p>

      <form
        className="space-y-6"
        onSubmit={handleSubmit((data) => {
          const {
            phoneNumber,
            privacyPolicy,
            confirmPassword,
            marketing,
            ...user
          } = data;
          if (data.password !== confirmPassword) {
            alert('비밀번호가 다릅니다. 다시 확인해주세요.');
          } else {
            signup({
              phoneNumber: phoneNum,
              marketing: marketingAgreement,
              privacyPolicy: privacy,
              ...user,
            })
              .then(() => {
                TagManager.dataLayer(tagManagerArgs);
                push('/signup/success');
              })
              .catch((err) => {
                if (err.response.data.statusCode === 409) {
                  alert(err.response.data.message);
                } else {
                  alert('회원가입에 실패했습니다. 다시 시도해주세요.');
                }
              });
          }
        })}
      >
        <TextField
          className="py-8"
          placeholder="학부모(본인) 이름을 입력해주세요"
          helper={errors.name?.message}
          {...register('name', {
            required: '학부모(본인) 이름을 입력해주세요',
          })}
        />
        <TextField
          type="email"
          className="py-8"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email', {
            required: '이메일을 입력해주세요.',
          })}
        />
        <TextField
          type="password"
          className="py-8"
          placeholder="비밀번호를 입력해주세요."
          helper={errors.password?.message}
          {...register('password', {
            required: '비밀번호를 입력해주세요.',
            minLength: {
              value: 6,
              message: '최소 6자 이상 입력해주세요.',
            },
          })}
        />
        <TextField
          type="password"
          className="py-8"
          placeholder="비밀번호를 한 번 더 입력해주세요."
          helper={errors.confirmPassword?.message}
          {...register('confirmPassword', {
            required: '비밀번호를 한 번 더 입력해주세요.',
            minLength: {
              value: 6,
              message: '최소 6자 이상 입력해주세요.',
            },
          })}
        />
        <PhoneNumber
          label="휴대폰 인증 *"
          phoneNum={phoneNum}
          setPhoneNum={setPhoneNum}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
        />

        <TextField
          label="추천인 코드 "
          placeholder="추천인코드를 입력해주세요."
          className="h-14"
          {...register('inviteCode')}
        />
        <div className="pt-4 pb-16 space-y-4">
          <Checkbox
            label="전체 동의"
            onChange={() => {
              const allChecked = agePolicy && privacy && marketingAgreement;
              setAgePolicy(!allChecked);
              setPrivacy(!allChecked);
              setMarketingAgreement(!allChecked);
            }}
            checked={agePolicy && privacy && marketingAgreement}
          />
          <Checkbox
            label="만 14세 이상입니다. (필수)"
            onChange={() => setAgePolicy(!agePolicy)}
            checked={agePolicy}
          />
          <Checkbox
            label="개인정보 취급 방침에 동의합니다. (필수)"
            onChange={() => setPrivacy(!privacy)}
            checked={privacy}
          />
          <Checkbox
            label="마케팅 및 이벤트 소식을 수신하고 싶습니다. (선택)"
            onChange={() => setMarketingAgreement(!marketingAgreement)}
            checked={marketingAgreement}
          />
          <div className="task_box">
            선택 시 바로 사용 가능한 적립금 3천원 지급
          </div>
        </div>
        <Button
          text="회원가입"
          className="filled-primary-1 w-full"
          disabled={
            !watch('name') ||
            !watch('email') ||
            !watch('password') ||
            !watch('confirmPassword') ||
            !isVerified ||
            !agePolicy ||
            !privacy
          }
        />
      </form>
    </div>
  );
};
