import React, { FC, useState } from 'react';
import VideoImg1 from '../../../assets/img/video-img-1.png';
import { useHistory } from 'react-router-dom';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Navigation } from 'swiper/core';
import { ReactComponent as Play } from '../../../assets/svg/play_button.svg';
import { VideoPopup } from '../../../components/VideoPopup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import $ from 'jquery';

SwiperCore.use([Navigation]);

interface FirstSectionProps {}

const videoData = [
  { id: 1, path: 'VvjknydTej0' },
  { id: 2, path: 'SxbdJLb9krw' },
  { id: 3, path: 'FUdy0_UWlDo' },
  { id: 4, path: 'iReQjrGz1ZA' },
  { id: 5, path: 'qXkPs5tKFB8' },
];

export const FirstSection: FC<FirstSectionProps> = () => {
  const { push } = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [videoPath, setVideoPath] = useState<string>('');

  return (
    <>
      <VideoPopup
        open={isOpen}
        onClose={() => setIsOpen(false)}
        videoPath={videoPath}
      />
      <div className="bg-light-blue con-pa">
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="mb-6"
        >
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
            어떤 라이브 쌤을 만나더라도
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
            수업은 튼튼하게!
          </h2>
          <p className="text-15 md:text-xl text-black text-center sub-txt01 font-medium">
            실력을 겸비한 라이브쌤들의 <br />
            개성 넘치는 수업을 지금 만나보세요!
          </p>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <Swiper
            slidesPerView="auto"
            spaceBetween={20}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            freeMode={true}
            navigation={{
              nextEl: '.swiper-button-next-1',
              prevEl: '.swiper-button-prev-1',
            }}
            className="mySwiper max-w-container"
          >
            <SwiperSlide className="rounded-lg overflow-hidden w-auto">
              <img
                src={VideoImg1}
                alt=""
                className="h-80 w-72 object-cover cursor-p"
                onClick={() => push('/live-teacher')}
              />
            </SwiperSlide>

            {videoData.map((item) => (
              <SwiperSlide
                className="rounded-lg overflow-hidden grid place-content-center w-max relative cursor-p"
                onClick={() => {
                  setIsOpen(true);
                  setVideoPath(item.path);
                }}
              >
                <img
                  src={`http://img.youtube.com/vi/${item.path}/0.jpg`}
                  alt=""
                  className="h-80 w-72 object-cover  "
                />
                <div className="bg-black absolute w-full h-full opacity-20" />
                <Play className="wh-20 absolute top-5 right-5" />
              </SwiperSlide>
            ))}
          </Swiper>
        </ScrollAnimation>
      </div>
    </>
  );
};
