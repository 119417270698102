import { FC } from 'react';
import { useQuery } from 'react-query';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { CM_OP_ERROR, fn_openCornerMap } from '../plugins/open_cm';
import { fetcher } from '../plugins/react-query';
import { tokenState } from '../plugins/ridge';
import { Lesson, LessonResult } from '../types';
import { Table } from './Table';

interface LessonRowProps {
  studentId: string;
  lesson: Lesson;
}

export const LessonRow: FC<LessonRowProps> = ({ studentId, lesson }) => {
  const { data: result } = useQuery<LessonResult>(
    `/lesson-results?studentId=${studentId}&lessonId=${lesson.id}`,
    fetcher
  );

  const openBluegaLesson = async (result: LessonResult, type: string) => {
    if (lesson.id === result.lessonId) {
      fn_openCornerMap(
        '/cornermap/index.html',
        {
          authToken: tokenState.get(),
          lessonId: result.lessonId,
          studentId: result.studentId,
          directMode: 'ANSWER',
          corners: [
            {
              ActiveID: type === 'story' ? 3 : type === 'learn' ? 5 : 7,
              url:
                type === 'story'
                  ? lesson.storyQuiz
                  : type === 'learn'
                  ? lesson.learnQuiz
                  : lesson.wordQuiz,
              QuizResult:
                type === 'story'
                  ? result.storyQuizResult
                  : type === 'learn'
                  ? result.learnQuizResult
                  : result.wordQuizResult,
              modes: [{ name: 'ANSWER', tip: '풀이 결과', needData: true }],
            },
          ],
        },
        ({ op, data }: any) => {
          if (!data) {
            window.location.reload();
          }
          if (op === CM_OP_ERROR) {
            alert('새로고침 후 다시 시도해주세요!');
          }
        }
      );
    }
  };

  if (result?.testDone) {
    return (
      <Table.Row className="bg-secondary-1">
        <Table.Td>
          <div className="text-primary-1 font-semibold text-lg">
            {lesson.title}
          </div>
          <p className="text-sm text-gray-500">
            {utcToLocalFormat(
              result.createdAt.toLocaleString(),
              MomentFormat.YYYYMMDDHmm
            )}
          </p>
        </Table.Td>
        <Table.Td colSpan={4}>
          <div className="flex flex-col items-center font-semibold text-gray-700 text-sm">
            <div>
              {result.testCorrect}/{result.testQuestion}
            </div>
            <div>{result.testScore}/100</div>
          </div>
        </Table.Td>
        <Table.Td
          onClick={() => {
            const encodedString = Buffer.from(
              result.testId.toString()
            ).toString('base64');
            window.open(
              `${process.env.REACT_APP_WEEKLY_TEST_URL}${encodedString}`
            );
          }}
        >
          결과 보기
        </Table.Td>
      </Table.Row>
    );
  }

  return (
    <Table.Row>
      <Table.Td>{lesson.title}</Table.Td>
      <Table.Td>
        {result ? (
          <div>
            {utcToLocalFormat(result?.createdAt, MomentFormat.YYYYMMDDHmm)}
          </div>
        ) : (
          '-'
        )}
      </Table.Td>
      {result && (
        <>
          <Table.Td
            className="cursor-pointer"
            onClick={() => openBluegaLesson(result, 'story')}
          >
            <span
              className={
                result.storyQuizCorrect < result.storyQuizQuestion
                  ? 'text-primary-8 underline'
                  : ''
              }
            >
              {result.storyQuizCorrect}
            </span>
            /{result.storyQuizQuestion}
          </Table.Td>
          <Table.Td
            className="cursor-pointer"
            onClick={() => openBluegaLesson(result, 'learn')}
          >
            <span
              className={
                result.learnQuizCorrect < result.learnQuizQuestion
                  ? 'text-primary-8 underline'
                  : ''
              }
            >
              {result.learnQuizCorrect}
            </span>
            /{result.learnQuizQuestion}
          </Table.Td>
          <Table.Td
            className="cursor-pointer"
            onClick={() => openBluegaLesson(result, 'word')}
          >
            <span
              className={
                result.wordQuizCorrect < result.wordQuizQuestion
                  ? 'text-primary-8 underline'
                  : ''
              }
            >
              {result.wordQuizCorrect}
            </span>
            /{result.wordQuizQuestion}
          </Table.Td>
        </>
      )}
    </Table.Row>
  );
};
