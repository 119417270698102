import React, { FC } from 'react';
import './donut.css';
import character from '../../assets/img/character2.png';

interface DonutProps {
  donutClassName: string;
  strokeDashArray: string;
  className?: string;
  number: number;
  title: string;
  subTitle?: string;
  hasCharacter?: boolean;
}

export const Donut: FC<DonutProps> = ({
  donutClassName,
  strokeDashArray,
  number,
  title,
  className,
  subTitle,
  hasCharacter = false,
}) => {
  return (
    <>
      <div className={`svg-item ${className} relative`}>
        <svg width="100%" height="100%" viewBox="0 0 40 40" className="donut">
          <circle
            className="donut-hole"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="#fff"
          ></circle>
          <circle
            className="donut-ring"
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="2"
          ></circle>
          <circle
            className={`donut-segment-all ${donutClassName} `}
            cx="20"
            cy="20"
            r="15.91549430918954"
            fill="transparent"
            stroke-width="2"
            // 길이 수정하려면 여기 바꾸면 됩니다!
            stroke-dasharray={strokeDashArray}
            stroke-dashoffset="25"
          ></circle>
          <g className="font-nanum donut-text donut-text-1">
            <text y="45%" transform="translate(0, 0)">
              <tspan x="50%" text-anchor="middle" className="donut-percent">
                {number}
              </tspan>
            </text>
            <text y="55%" transform="translate(0, 0)">
              <tspan x="50%" text-anchor="middle" className="donut-data">
                {title}
              </tspan>
            </text>
            <text y="65%" transform="translate(0, 0)">
              <tspan x="50%" text-anchor="middle" className="donut-data">
                {subTitle}
              </tspan>
            </text>
          </g>
        </svg>
        {hasCharacter && (
          <img
            src={character}
            alt=""
            className="md:w-48 sm:w-48 w-32 absolute md:bottom-6 sm:bottom-7 bottom-4 transform translate-x-1/2 right-1/2 z-0"
          />
        )}
      </div>
    </>
  );
};
