import moment from 'moment';
import { parse } from 'qs';
import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Avatar } from '../components/Avatar';
import { Button } from '../components/Button';
import { Dropdown } from '../components/Dropdown';
import { H5 } from '../components/H5';
import { ChevronDownIcon, PlusIcon } from '../components/icons';
import { Menu } from '../components/Menu';
import { ScheduleCalendar } from '../components/ScheduleCalendar';
import { Section } from '../components/Section';
import { api, apiUrl } from '../plugins/axios';
import { fetcher } from '../plugins/react-query';
import {
  courseItemState,
  courseState,
  liveState,
  popupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import {
  CancelType,
  ClassType,
  Course,
  Live,
  LiveState,
  liveStateToText,
  LiveType,
} from '../types';

export const LivesPage = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { view } = parse(search, { ignoreQueryPrefix: true });
  const student = studentState.useValue();
  const course = courseState.useValue();
  const testResult = testResultState.useValue();
  const [isUpcoming, setIsUpcoming] = useState(true);

  if (
    course?.startLevel?.classType !== ClassType.SPECIAL &&
    !student?.isTested
  ) {
    push('/learn/classroom');
  }

  const { data: allLives, refetch } = useQuery<Live[]>(
    `/lives?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  const lives = isUpcoming
    ? allLives?.filter(
        (l) => l.state === LiveState.RESERVED && l.courseId === course?.id
      ) ?? []
    : allLives?.filter(
        (l) => l.state !== LiveState.RESERVED && l.courseId === course?.id
      ) ?? [];

  //회차 정의
  const trialLives = lives.filter(
    (l) => l.liveType === LiveType.TRIAL && course?.initialAmount === 1
  );
  const reservedLives = allLives?.filter(
    (l) =>
      (l.liveType === LiveType.NORMAL ||
        (l.liveType === LiveType.TRIAL &&
          course &&
          course?.initialAmount > 1)) &&
      l.state === LiveState.ATTENDED &&
      l.courseId === course?.id
  );

  const normalLives = lives
    .filter(
      (l) =>
        l.liveType === LiveType.NORMAL ||
        (l.liveType === LiveType.TRIAL && course && course?.initialAmount > 1)
    )
    .sort((a, b) => +new Date(a.start) - +new Date(b.start))
    .map((l, index) => ({
      ...l,
      index:
        !reservedLives || reservedLives?.length === 0
          ? index + 1
          : index + 1 + reservedLives?.length,
    }));

  const concatLives = [...trialLives, ...normalLives].sort(
    (a, b) => +new Date(b.start) - +new Date(a.start)
  );

  if (
    (course?.startLevel?.classType !== ClassType.SPECIAL && !testResult) ||
    !course
  ) {
    return <></>;
  }
  return (
    <div className="flex flex-col space-y-5 flex-1">
      <div className="flex md:flex-row flex-col md:justify-between md:items-center space-y-4 md:space-y-0">
        <div className="flex space-x-4">
          <H5>나의 수업</H5>
          <div className="bg-gray-100 w-0.5 h-auto rounded-full" />
          <Dropdown>
            <button className="text-20 font-bold text-gray-600 items-center flex space-x-2">
              <span>{isUpcoming ? '예정된 수업' : '완료된 수업'}</span>
              <ChevronDownIcon className="wh-4 text-gray-200" />
            </button>
            <Dropdown.View className="max-h-60 overflow-y-scroll">
              <Menu>
                <Menu.Item
                  text="예정된 수업"
                  onClick={() => setIsUpcoming(true)}
                />
                <Menu.Item
                  text="완료된 수업"
                  onClick={() => setIsUpcoming(false)}
                />
              </Menu>
            </Dropdown.View>
          </Dropdown>
        </div>
        {view === 'calendar' ? (
          <Button
            to="/learn/lives"
            text="카드 보기"
            className="bg-white shadow-sm rounded-md text-primary-1 font-bold px-6 h-10"
          />
        ) : (
          <Button
            to="/learn/lives?view=calendar"
            text="캘린더 보기"
            className="bg-white shadow-sm rounded-md text-primary-1 font-bold px-6 h-10"
          />
        )}
      </div>

      {view === 'calendar' ? (
        <div>
          <ScheduleCalendar lives={lives} selected />
        </div>
      ) : (
        <div className="grid md:grid-cols-3 gap-6">
          {isUpcoming && <AddLiveCard />}
          {concatLives.map((live) => (
            <LiveCard
              key={live.id}
              live={live}
              refetch={refetch}
              index={live.index as number}
              isUpcoming={isUpcoming}
              course={course}
              concatLives={concatLives}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface AddLiveCardProps {}

export const AddLiveCard: FC<AddLiveCardProps> = () => {
  return (
    <Link
      to="/learn/reservation"
      className="group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm rounded-lg flex flex-col items-center justify-center h-64 md:h-96"
    >
      <div className="group-hover:bg-primary-1 bg-gray-50 rounded-full wh-14 grid place-items-center">
        <PlusIcon className="group-hover:text-white text-gray-400 fill-current" />
      </div>
      <H5 className="mt-4 mb-2">수업 예약하기</H5>
    </Link>
  );
};

interface LiveCardProps {
  live: Live;
  course?: Course;
  index: number;
  isUpcoming: boolean;
  concatLives?: Live[];
  refetch: () => void;
}

export const LiveCard: FC<LiveCardProps> = ({
  live,
  course,
  index,
  isUpcoming,
  concatLives,
  refetch,
}) => {
  const { push } = useHistory();

  // const lastLiveId = concatLives?.sort(
  //   (a, b) => +new Date(a.start) - +new Date(b.start)
  // )[concatLives?.length - 1].id;

  // const startPlusOne = new Date(
  //   new Date(live.start).setSeconds(new Date(live.start).getSeconds() + 1)
  // );

  const startDate = new Date(live.start);
  startDate.setHours(0, 0, 0, 0);
  const canceledAt = new Date(live.canceledAt);
  canceledAt.setHours(0, 0, 0, 0);
  const checkTime = new Date(live.start);
  checkTime.setHours(checkTime.getHours() - 1);

  return (
    <div
      className={`bg-white rounded-md shadow-sm p-5 min-h-96 ${
        [
          LiveState.CANCELED,
          LiveState.ABSENT,
          LiveState.NO_SHOW,
          LiveState.ABSENT_AND_NO_SHOW,
          LiveState.AVAILABLE,
        ].includes(live.state)
          ? 'opacity-50'
          : ''
      } `}
    >
      <Section>
        <div className="flex justify-between items-center w-full">
          <div />
          <h1 className="text-17 text-primary-1 font-bold mr-auto">
            {live.liveType === LiveType.TRIAL && course?.initialAmount === 1
              ? '체험'
              : live.liveType === LiveType.TRIAL &&
                course &&
                course?.initialAmount > 1
              ? `${
                  !isUpcoming && live.cancelRound
                    ? live.cancelRound
                    : live.attendRound
                    ? live.attendRound
                    : index
                }회차`
              : `${
                  !isUpcoming && live.cancelRound
                    ? live.cancelRound
                    : live.attendRound
                    ? live.attendRound
                    : index
                }회차`}
          </h1>
          <div
            className={`text-12 py-2 px-3 font-bold rounded-md ${
              live.state === LiveState.RESERVED
                ? 'bg-secondary-2 text-primary-2'
                : live.state === LiveState.CANCELED ||
                  live.state === LiveState.CANCELLATION_THE_DAY
                ? 'bg-red-50 text-red-500'
                : live.state === LiveState.ATTENDED
                ? 'bg-secondary-2 text-primary-2'
                : live.state === LiveState.ABSENT
                ? 'bg-red-50 text-red-500'
                : live.state === LiveState.NO_SHOW
                ? 'bg-red-50 text-red-500'
                : live.state === LiveState.ABSENT_AND_NO_SHOW
                ? 'bg-red-50 text-red-500'
                : ''
            }`}
          >
            {(live.state === LiveState.CANCELED &&
              moment(startDate).isSame(canceledAt)) ||
            live.state === LiveState.CANCELLATION_THE_DAY
              ? `당일 취소(${
                  live.cancelType === CancelType.ADMIN ? '관리자' : '학생'
                })`
              : live.state === LiveState.CANCELED
              ? `${liveStateToText(live.state)}(${
                  live.cancelType === CancelType.ADMIN ? '관리자' : '학생'
                })`
              : liveStateToText(live.state)}
          </div>
        </div>
        {/* <h1 className="text-17 font-bold">live.title</h1> */}
      </Section>

      <div className="border border-gray-50 -mx-5 my-7 mb-6" />

      <Section>
        <p className="pb-1 text-13 text-gray-500">선생님</p>
        <div className="space-x-2">
          <Avatar src={apiUrl(live.teacher.avatar)} className="wh-10" />
          <span>{live.teacher.englishName} 선생님</span>
        </div>

        <div className="pt-8">
          <p className="pb-1 text-13 text-gray-500">수업 일시</p>
          <p>{moment(live.start).format('LLL')}</p>
        </div>
      </Section>

      {live.state === LiveState.RESERVED &&
        moment(checkTime).isSameOrAfter(moment.now()) &&
        !live.pageCallRoomId && (
          <Section className="flex flex-col space-y-3 mt-4">
            <Button
              text="예약 취소"
              className="rounded-md h-11 bg-red-50 text-red-600"
              onClick={() => {
                if (moment(live.start).isSame(moment(new Date()), 'day')) {
                  popupState.set({
                    title: '예약취소',
                    body: '예약취소는 수업 전날 자정까지만 가능하며 \n당일취소 시 보강없이 횟수가 차감됩니다.\n그래도 취소하시겠습니까?.',
                    dangerText: '수업 예약 취소하기',
                    dangerClick: () =>
                      api
                        .post(`/lives/${live.id}/cancel`, {
                          cancelRound: index,
                          cancelType: CancelType.STUDENT,
                        })
                        .then(() => {
                          liveState.reset();
                          refetch();
                          popupState.set({
                            title: '예약 취소 완료',
                            body: '선생님께 결석여부를 전달하였습니다. \n다음에는 꼭 함께 수업하도록해요~!',
                            primaryText: '확인',
                            primaryClick: () => refetch,
                          });
                        }),
                  });
                } else {
                  popupState.set({
                    title: '예약 취소',
                    body: '수업 예약을 정말 취소하시겠습니까? 취소하면 다음 예약된 시간에 해당 수업을 진행하게 됩니다.',
                    dangerText: '수업 예약 취소하기',
                    dangerClick: () =>
                      api
                        .post(`/lives/${live.id}/cancel`, {
                          cancelRound: index,
                          cancelType: CancelType.STUDENT,
                        })
                        .then(() => {
                          liveState.reset();
                          refetch();
                          popupState.set({
                            title: '예약 취소 완료',
                            body: '취소가 완료되었습니다!',
                            primaryText: '확인',
                            primaryClick: () => refetch,
                          });
                        }),
                  });
                }
              }}
            />
          </Section>
        )}

      {live.state !== LiveState.RESERVED && (
        <Section className="flex flex-col space-y-3 mt-4">
          <Button
            text="레포트 보기"
            to={`/learn/lives/${live.id}/report`}
            className="rounded-md h-11 bg-secondary-1 text-primary-1"
            disabled={
              [
                LiveState.CANCELED,
                LiveState.ABSENT,
                LiveState.NO_SHOW,
                LiveState.ABSENT_AND_NO_SHOW,
                LiveState.AVAILABLE,
              ].includes(live.state) || live?.liveFeedbacks?.length === 0
            }
          />
          <Button
            text="학습하기"
            onClick={async () => {
              const courseItem = await course?.courseItems.find(
                (c) => c.id === live?.courseItemId
              );
              if (courseItem) {
                await courseItemState.set(courseItem);
                push('/learn/classroom');
              }
            }}
            className="rounded-md h-11 bg-secondary-1 text-primary-1"
            disabled={[
              LiveState.CANCELED,
              LiveState.ABSENT,
              LiveState.NO_SHOW,
              LiveState.ABSENT_AND_NO_SHOW,
              LiveState.AVAILABLE,
            ].includes(live.state)}
          />
        </Section>
      )}
    </div>
  );
};
