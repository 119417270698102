import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString, useSearch } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { LiveState, Paginated, Pay, PayItemType } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { Button } from '../../components/Button';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { AdminSearch } from '../components/AdminSearch';
import { searchTextState } from '../../plugins/ridge';
import { typeToName } from '../../utils';

export const Pays = () => {
  const { q, setQ, setSearchText } = useSearch();
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  const queryString = useQueryString({ filter: getMoment.toISOString() });
  const { data } = useQuery<Paginated<Pay>>(
    `/admin/pays/monthly${queryString}&q=${q}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: pays } = useQuery<Pay[]>(
    `/admin/pays/export${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  const exporter = () => {
    const payPayItems = pays?.map((pay) => pay.payItems);
    const payItems = Array.prototype.concat.apply([], payPayItems || []);
    const payData = payItems?.map((payItem: any) => {
      const pay = pays?.find((pay) => pay.id === payItem.payId);

      return {
        강사명: pay?.teacher?.name,
        영어이름: pay?.teacher?.englishName,
        교사등급: pay?.teacher?.grade,
        수업일자: payItem?.createdAt
          ? utcToLocalFormat(
              payItem?.createdAt.toLocaleString(),
              MomentFormat.YYYYMMDD
            )
          : '',
        회원명: payItem?.live?.student?.name,
        생년월일: payItem?.live?.student?.dob,
        수업유형:
          payItem?.type === PayItemType.LIVE_SETTLEMENT
            ? typeToName(
                payItem?.live?.course?.productLiveType,
                payItem?.live?.course?.classType
              )
            : '',
        장려금:
          payItem?.type === PayItemType.INCENTIVE
            ? payItem?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0,
        '건별 정산금':
          payItem?.type === PayItemType.LIVE_SETTLEMENT
            ? payItem?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0,
        '본사지원 조건': !payItem?.auto ? payItem?.memo : '',
        '패널티 조건':
          payItem?.live?.state === LiveState.ABSENT
            ? '결석'
            : payItem?.live?.state === LiveState.ABSENT_AND_NO_SHOW
            ? '학생결석 및 선생님노쇼'
            : payItem?.live?.state === LiveState.NO_SHOW
            ? '선생님 노쇼'
            : payItem?.live?.state === LiveState.CANCELED
            ? '수업 취소'
            : '',
        '패널티 수수료':
          payItem?.type === PayItemType.PENALTY
            ? payItem?.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0,
      };
    });
    jsonExport(payData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(
        `${BOM} ${csv}`,
        `정산 관리(${today.format('YYYY 년 MM 월')})`
      );
    });
  };

  if (!data) return <></>;
  return (
    <>
      <AdminH1>정산 목록</AdminH1>

      <div className="w-full text-center my-7 text-2xl font-bold">
        <div>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {'<'}
          </button>
          <span className="mx-7">{today.format('YYYY 년 MM 월')}</span>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {'>'}
          </button>
        </div>
      </div>

      <Button
        className="bg-white text-blue-500 ml-auto text-14"
        onClick={exporter}
      >
        파일 내보내기
      </Button>

      <AdminSearch
        placeholder="검색"
        value={q}
        onChange={(e) => {
          e.preventDefault();
          setQ(e.target.value);
        }}
      />

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>일자</Table.Th>
              <Table.Th>강사명</Table.Th>
              <Table.Th>영어이름</Table.Th>
              <Table.Th>계좌정보</Table.Th>
              <Table.Th>총 정산 비용</Table.Th>
              <Table.Th>총 수업 횟수</Table.Th>
              <Table.Th>등급</Table.Th>
              <Table.Th>기본 정산 (횟수)</Table.Th>
              <Table.Th>장려금</Table.Th>
              <Table.Th>패널티</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items.map((pay) => {
              return (
                <Table.Row key={pay.id}>
                  <Table.Td>
                    {utcToLocalFormat(
                      pay.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDD
                    )}
                  </Table.Td>
                  <Table.Td>{pay.teacher.name}</Table.Td>
                  <Table.Td>{pay.teacher.englishName}</Table.Td>
                  <Table.Td>
                    {pay.teacher.bank} / {pay.teacher.bankAccountNumber}
                  </Table.Td>
                  <Table.Td>
                    {pay.total && pay.liveSettlement < pay.penalty
                      ? `${pay.total
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                      : pay.total
                      ? pay.total
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : 0}
                  </Table.Td>
                  <Table.Td>{pay.liveSettlementCount}회</Table.Td>
                  <Table.Td>{pay.teacher.grade}</Table.Td>
                  <Table.Td>
                    {pay.liveSettlement
                      ? pay.liveSettlement
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : '-'}{' '}
                    ({pay.liveSettlementCount}회)
                  </Table.Td>
                  <Table.Td>
                    {pay.incentive
                      ? pay.incentive
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      : 0}
                  </Table.Td>
                  <Table.Td>
                    {pay.penalty
                      ? `-${pay.penalty
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                      : 0}
                  </Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/teachers/${pay.teacherId}/transactions`}
                      onClick={setSearchText}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/pays" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
