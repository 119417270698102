import React, { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { range } from '../utils';
import { Label } from './Label';
import { Select } from './Select';

export interface DateSelectProps {
  label: string;
  value?: string;
  onChange: (value: Date) => void;
}

export const DateSelect: FC<DateSelectProps> = ({ label, value, onChange }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(1);

  useEffect(() => {
    if (!value) return;
    const chunks = value.split('-');
    setYear(Number(chunks[0]));
    setMonth(Number(chunks[1]) - 1);
    setDay(Number(chunks[2]));
  }, [value]);

  useEffect(() => {
    onChange(new Date(year, month, day));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month, day]);

  return (
    <div>
      <Label text={label} />
      <div className="flex space-x-4">
        <Select
          value={year}
          onChange={(e) => {
            setYear(Number(e.target.value));
            setDay(1);
          }}
        >
          {range(100).map((i) => {
            const year = new Date().getFullYear() - i;
            return (
              <option key={i} value={year}>
                {year.toString()}
              </option>
            );
          })}
        </Select>

        <Select
          value={month}
          onChange={(e) => {
            setMonth(Number(e.target.value));
            setDay(1);
          }}
        >
          {range(12).map((m) => (
            <option key={m} value={m}>
              {(m + 1).toString()}
            </option>
          ))}
        </Select>

        <Select value={day} onChange={(e) => setDay(Number(e.target.value))}>
          {range(new Date(year ?? 0, (month ?? 0) + 1, 0).getDate(), 1).map(
            (d) => (
              <option key={d} value={d}>
                {d.toString()}
              </option>
            )
          )}
        </Select>
      </div>
    </div>
  );
};
