import React, { useEffect, useState } from 'react';
import { Avatar } from '../../components/Avatar';
import { H5 } from '../../components/H5';
import { ReactComponent as Plus } from '../../assets/svg/plus-circle.svg';
import { TextField } from '../../components/TextField';
import { TextArea } from '../../components/TextArea';
import { PhoneNumber } from '../../components/PhoneNumber';
import { Checkbox } from '../../components/Checkbox';
import { Button } from '../../components/Button';
import { useQuery } from 'react-query';
import { Grade, User } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { useForm } from 'react-hook-form';
import { api, apiUrl } from '../../plugins/axios';
import { resizeImage } from '../../plugins/compress';
import { useAuth } from '../../hooks';
import { UploadFile } from '../../components/UploadFile';
import { downloadPopupState } from '../../plugins/ridge';

interface FormValues {
  avatar: File;
  category: string;
  passBook: File;
  idCard: File;
  englishName: string;
  introduction: string;
  name: string;
  email: string;
  phoneNumber: string;
  emergencyContact: string;
  address: string;
  residentRegistrationNumber: string;
  bankAccountNumber: string;
  teacherTerms: boolean;
  privacyPolicy: boolean;
  videoUrl: string;
  bank: string;
  accountHolder: string;
}

export const ProfileEditPage = () => {
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [passBook, setPassBook] = useState<string>('');
  const [idCard, setIdCard] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');

  const { logout } = useAuth();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: teacher, refetch } = useQuery<User>(`/users/me`, fetcher);

  useEffect(() => {
    if (!teacher) return;
    setValue('englishName', teacher.englishName);
    setValue('category', teacher.category);
    setValue('introduction', teacher.introduction);
    setValue('name', teacher.name);
    setValue('email', teacher.email);
    setValue('emergencyContact', teacher.emergencyContact);
    setValue('address', teacher.address);
    setValue('residentRegistrationNumber', teacher.residentRegistrationNumber);
    setValue('bankAccountNumber', teacher.bankAccountNumber);
    setValue('privacyPolicy', teacher.privacyPolicy);
    setValue('teacherTerms', teacher.teacherTerms);
    setValue('videoUrl', teacher.videoUrl);
    setValue('bank', teacher.bank);
    setValue('accountHolder', teacher.accountHolder);
    setPassBook(teacher.passBook);
    setIdCard(teacher.idCard);
    setPhoneNum(teacher.phoneNumber);
  }, [setValue, teacher]);

  const save = handleSubmit(async (data) => {
    if (teacher?.phoneNumber !== phoneNum) {
      data.phoneNumber = phoneNum;
    }
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) =>
      formData.append(name, value)
    );

    await api.patch('/users/edit/teacher', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    refetch();
    alert('저장되었습니다.');
  });

  return (
    <>
      <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden p-5 my-5">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5">
          <H5 className="col-start-1">공개 정보</H5>
          <label
            htmlFor="avatar"
            className="relative rounded-full wh-14 col-start-1 cursor-pointer"
          >
            <Avatar
              src={
                watch('avatar')
                  ? URL.createObjectURL(watch('avatar'))
                  : apiUrl(teacher?.avatar)
              }
              className="wh-14"
            />
            <Plus className="wh-6 absolute bottom-0 right-0" />
            <input
              id="avatar"
              type="file"
              accept="image/*"
              className="sr-only"
              onChange={async (e) => {
                const file = e.target.files?.item(0);
                if (!file || !e.target.validity.valid) return;
                setValue('avatar', await resizeImage(file));
              }}
            />
          </label>
          <div className="col-start-1">
            <TextField
              label="영어이름*"
              placeholder="영어 이름을 입력해주세요."
              helper={errors.englishName?.message}
              {...register('englishName', {
                required: '영어 이름을 입력해주세요.',
              })}
            />
          </div>
          <div className="col-start-1">
            <TextField
              label="카테고리*"
              placeholder="카테고리를 입력해주세요"
              helper={errors.category?.message}
              {...register('category', {
                required: '카테고리를 입력해주세요',
              })}
            />
          </div>
          <div className="col-start-1">
            <TextField
              label="자기소개 영상 링크 *"
              placeholder="자기소개 영상 링크를 입력해주세요."
              helper={errors.englishName?.message}
              {...register('videoUrl', {
                required: '자기소개 영상 링크를 입력해주세요.',
              })}
            />
          </div>
          <div className="col-start-1">
            <TextArea
              label="자기소개*"
              helper={errors.introduction?.message}
              {...register('introduction', {
                required: '영어 이름을 입력해주세요.',
              })}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-14">
          <H5 className="md:col-span-2">비공개 정보</H5>
          <TextField
            label="이름*"
            placeholder="이름을 입력해주세요."
            helper={errors.name?.message}
            {...register('name', {
              required: '이름을 입력해주세요.',
            })}
          />
          <TextField
            label="이메일*"
            type="email"
            placeholder="이메일을 입력해주세요."
            helper={errors.email?.message}
            {...register('email', {
              required: '이메일을 입력해주세요.',
            })}
          />
          <PhoneNumber
            label="휴대폰 인증 *"
            phoneNum={phoneNum}
            setPhoneNum={setPhoneNum}
            isVerified={isVerified}
            setIsVerified={setIsVerified}
            setPhoneValue={setPhoneValue}
          />

          <div className="col-start-1">
            <TextField
              label="비상연락망*"
              type="tel"
              placeholder="01012341234 형태로 입력해주세요."
              helper={errors.emergencyContact?.message}
              {...register('emergencyContact', {
                required: '비상연락망을 입력해주세요.',
              })}
            />
          </div>
          <div className="col-start-1">
            <TextField
              label="주소*"
              placeholder="주소를 입력해주세요."
              helper={errors.address?.message}
              {...register('address', {
                required: '주소를 입력해주세요.',
              })}
            />
          </div>
          <TextField
            label="주민등록번호*"
            placeholder="주민등록번호를 입력해주세요."
            helper={errors.residentRegistrationNumber?.message}
            {...register('residentRegistrationNumber', {
              required: '주민등록번호를 입력해주세요.',
            })}
          />
          <TextField
            label="등급*"
            disabled
            value={
              teacher?.grade === Grade.T1
                ? 'T1'
                : teacher?.grade === Grade.T2
                ? 'T2'
                : 'TF'
            }
          />
          <TextField
            label="예금주*"
            placeholder="예금주를 입력해주세요."
            helper={errors.accountHolder?.message}
            {...register('accountHolder', {
              required: '예금주를 입력해주세요.',
            })}
          />
          <TextField
            label="지급 은행*"
            placeholder="지급 은행을 입력해주세요."
            helper={errors.bank?.message}
            {...register('bank', {
              required: '지급 은행을 입력해주세요.',
            })}
          />
          <TextField
            label="지급 계좌번호*"
            placeholder="지급 계좌번호를 입력해주세요."
            helper={errors.bankAccountNumber?.message}
            {...register('bankAccountNumber', {
              required: '지급 계좌번호를 입력해주세요.',
            })}
          />
          <UploadFile
            label="통장사본*"
            fileName={
              watch('passBook')
                ? watch('passBook').name
                : teacher?.passBook.split('_')[1]
            }
            onChange={async (file) => {
              setPassBook(file.name);
              setValue('passBook', await resizeImage(file));
            }}
          />
          <UploadFile
            label="신분증사본*"
            fileName={
              watch('idCard')
                ? watch('idCard').name
                : teacher?.idCard.split('_')[1]
            }
            onChange={async (file) => {
              setIdCard(file.name);
              setValue('idCard', await resizeImage(file));
            }}
          />

          <div className="space-y-2">
            <div className="flex space-x-2 items-center">
              <div>
                <Checkbox {...register('privacyPolicy')} />
              </div>
              <p
                className="underline cursor-pointer"
                onClick={() => window.open('https://www.naver.com')}
              >
                개인정보 동의
              </p>
            </div>
            <div className="flex space-x-2 items-center">
              <div>
                <Checkbox {...register('teacherTerms')} />
              </div>
              <p
                className="underline cursor-pointer"
                onClick={() => window.open('https://www.naver.com')}
              >
                교사약관 전자서명
              </p>
            </div>
          </div>
          <div className="md:col-span-2 flex justify-end space-x-3">
            <Button
              className="filled-primary-1 h-10"
              text="다운로드"
              onClick={() => {
                downloadPopupState.set({
                  title: '다운로드 선택',
                  body: '필요한 서류를 선택해주세요.',
                  secondBody: '한번에 하나씩만 다운로드 가능합니다.',
                  primaryText: '통장 사본',
                  primaryLink: passBook,
                  secondaryText: '신분증 사본',
                  secondaryLink: idCard,
                  teacherName: watch('englishName'),
                });
              }}
            />
            <Button
              className="outlined-black h-10 font-semibold"
              text="로그아웃"
              onClick={() => {
                localStorage.clear();
                logout();
              }}
            />
            <Button
              className="filled-black h-10 font-semibold"
              text="저장하기"
              disabled={
                !watch('englishName') ||
                !watch('category') ||
                !watch('introduction') ||
                !watch('name') ||
                !watch('email') ||
                !watch('emergencyContact') ||
                !watch('address') ||
                !watch('residentRegistrationNumber') ||
                !watch('videoUrl') ||
                !watch('bankAccountNumber') ||
                !watch('accountHolder') ||
                !watch('bank') ||
                !watch('teacherTerms') ||
                !watch('privacyPolicy') ||
                (phoneValue.length > 0 && !isVerified) ||
                passBook === '' ||
                idCard === ''
              }
              onClick={save}
            />
          </div>
        </div>
      </div>
    </>
  );
};
