import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { EditIcon } from '../../components/icons';
import { ScheduleCalendar } from '../../components/ScheduleCalendar';
import { fetcher } from '../../plugins/react-query';
import { Live, User } from '../../types';
import moment from 'moment';

export const SchedulePage = () => {
  const { push } = useHistory();
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [weekFrom, setWeekFrom] = useState<any>();
  const [weekTo, setWeekTo] = useState<any>();
  const [type, setType] = useState<string>('month');

  const timeTo = new Date(weekTo?.toISOString());
  timeTo.setHours(timeTo.getHours() + 9);

  const formatFrom = from && moment(from).startOf('M').add(9, 'h');
  const formatTo = to && moment(to).endOf('M').add(9, 'h');

  const { data: contracts } = useQuery('/contracts', fetcher);
  const { data: teacher } = useQuery<User>('/users/me', fetcher);
  const { data: lives } = useQuery<Live[]>(
    `/lives?from=${
      type === 'month' ? formatFrom?.toISOString() : weekFrom?.toISOString()
    }&to=${type === 'month' ? formatTo?.toISOString() : timeTo?.toISOString()}`,
    fetcher,
    {
      keepPreviousData: true,
      enabled: type === 'month' ? !!from && !!to : !!weekFrom && !!weekTo,
    }
  );

  const isSigned = contracts?.at(-1)?.isSigned;

  const enter = async (live: Live) => {
    window.open(
      `https://app.pagecall.net/${live.pageCallRoomId}/?access_token=${teacher?.pageCallAccessToken}`
    );
  };

  return (
    <>
      <div className="max-w-5xl mx-auto flex justify-between items-center my-4">
        <div className="font-bold text-lg">🗒 스케줄</div>
        <button
          onClick={() => {
            isSigned
              ? push('/teacher/schedule/edit')
              : alert('계약서 전자서명이 확인되어야 스케줄 관리가 가능합니다.');
          }}
          className="h-10 px-4 rounded-lg border border-gray-100 flex items-center space-x-2"
        >
          <EditIcon />
          <span className="font-bold text-sm text-grey-7">스케줄 관리</span>
        </button>
      </div>

      <ScheduleCalendar
        lives={lives}
        onChangeMonth={(f, t) => {
          setFrom(f);
          setTo(t);
        }}
        onChangeWeek={(f, t) => {
          setWeekFrom(f);
          setWeekTo(t);
        }}
        onClickEnter={enter}
        selected
        setType={(type) => setType(type)}
      />
    </>
  );
};
