import Compress from 'compress.js';

const compress = new Compress();

export async function resizeImage(file: File) {
  const [result] = await compress.compress([file], {
    size: 0.1, // MB
    maxWidth: 2000, // px
    maxHeight: 2000, // px
  });
  return Compress.convertBase64ToFile(result.data, result.ext);
}
