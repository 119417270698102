import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Course, Level, Paginated, Student } from '../../types';

interface FormValues {
  name?: string;
  initialAmount?: number;
  remainingAmount: number;
  isTrial?: boolean;
  bookIncluded?: boolean;
  penIncluded?: boolean;
  startedAt: string;
  endedAt: string;
  memo: string;
  startLevelId?: number;
  startSeriesId?: number;
  startBookId?: number;
  userId?: number;
  studentId?: number;
}

interface AdminPayItemPopUpProps {
  course?: Course;
  student?: Student;
  courseCreate: boolean;
  onClose: () => void;
  refetch: () => void;
}

export const AdminCoursePopUp: FC<AdminPayItemPopUpProps> = ({
  course,
  student,
  courseCreate,
  onClose,
  refetch,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const levelQueryString = useQueryString({
    join: ['serieses', 'serieses.books'],
  });

  const { data: levels } = useQuery<Paginated<Level>>(
    `/admin/levels${levelQueryString}`,
    fetcher,
    {
      enabled: courseCreate,
    }
  );

  const create = handleSubmit((data) => {
    const now = new Date();
    const endedAt = new Date(watch('endedAt'));
    endedAt.setHours(23, 59, 59, 0);

    if (
      watch('endedAt') <= watch('startedAt') ||
      now.toISOString() > endedAt.toISOString()
    ) {
      alert('수강권 종료일자를 다시 확인해주세요!');
      return;
    }

    data.initialAmount = Number(data.remainingAmount);
    data.remainingAmount = Number(data.remainingAmount);

    return api
      .post(`/admin/courses/new`, data)
      .then(async () => {
        await refetch();
        onClose();
      })
      .catch((err) => console.error('수강권 발급 오류', err));
  });

  const update = handleSubmit((data) => {
    data.remainingAmount = Number(data.remainingAmount);
    return api
      .patch(`/admin/courses/${course?.id}/student`, data)
      .then(async () => {
        await refetch();
        onClose();
      })
      .catch((err) => console.error('수강권 횟수 변경 오류', err));
  });

  useEffect(() => {
    if (!student) return;
    setValue('userId', student.userId);
    setValue('studentId', student.id);
  }, [student, setValue]);

  useEffect(() => {
    if (!course) return;
    setValue('remainingAmount', course.remainingAmount);
    setValue('memo', course.memo);
  }, [course, setValue]);

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full max-h-screen-10 overflow-y-auto md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">
          {courseCreate ? '수강권 발급' : '수강권 횟수 변경'}
        </H3>

        <TextField
          label="학생명"
          value={!courseCreate ? course?.student.name : student?.name}
          disabled
        />
        {courseCreate ? (
          <>
            <Select
              label="레벨"
              value={watch('startLevelId')}
              onChange={(e) => setValue('startLevelId', Number(e.target.value))}
            >
              <option label="레벨을 선택해주세요." value={0} selected>
                레벨을 선택해주세요.
              </option>
              {levels?.items
                .sort((a, b) => a.id - b.id)
                .map((level) => (
                  <option key={level.id} label={level.name} value={level.id}>
                    {level.name}
                  </option>
                ))}
            </Select>
            <Select
              label="시리즈"
              value={watch('startSeriesId')}
              onChange={(e) =>
                setValue('startSeriesId', Number(e.target.value))
              }
            >
              <option label="교재를 선택해주세요." value={0} selected>
                교재를 선택해주세요.
              </option>
              {levels?.items
                .find((level) => level.id === watch('startLevelId'))

                ?.serieses.map((series) => (
                  <option key={series.id} label={series.name} value={series.id}>
                    {series.name}
                  </option>
                ))}
            </Select>
            <Select
              label="책"
              value={watch('startBookId')}
              onChange={(e) => setValue('startBookId', Number(e.target.value))}
            >
              <option label="진도를 선택해주세요." value={0} selected>
                진도를 선택해주세요.
              </option>
              {levels?.items
                .find((level) => level.id === watch('startLevelId'))
                ?.serieses.find(
                  (series) => series.id === watch('startSeriesId')
                )
                ?.books.map((book) => (
                  <option key={book.id} label={book.title} value={book.id}>
                    {book.title}
                  </option>
                ))}
            </Select>
            <TextField
              label="수강 시작일"
              placeholder="수강 시작일을 입력해주세요."
              type="date"
              max={'9999-12-31'}
              helper={errors.startedAt?.message}
              {...register('startedAt', {
                required: '수강 시작일을 입력해주세요.',
              })}
            />
            <TextField
              label="수강 종료일"
              placeholder="수강 종료일을 입력해주세요."
              helper={errors.endedAt?.message}
              type="date"
              max={'9999-12-31'}
              {...register('endedAt', {
                required: '수강 종료일을 입력해주세요.',
              })}
            />
            <Checkbox label="스토리북 포함" {...register('bookIncluded')} />
            <Checkbox label="토킹펜 포함" {...register('penIncluded')} />
          </>
        ) : (
          <>
            <TextField
              label="레벨"
              value={`${course?.startLevelId}단계`}
              disabled
            />
            <TextField
              label="시리즈"
              value={course?.startSeries?.name}
              disabled
            />
            <TextField
              label="진도"
              value={`${course?.startBookId}권`}
              disabled
            />
            <TextField
              label="전체 횟수"
              value={`${course?.initialAmount}회`}
              disabled
            />
          </>
        )}

        <TextField
          label="잔여 횟수"
          placeholder="잔여 횟수를 입력해주세요."
          type="number"
          helper={errors.remainingAmount?.message}
          {...register('remainingAmount', {
            required: '잔여 횟수를 입력해주세요.',
          })}
        />
        <TextArea
          className="h-28"
          label="내용"
          placeholder="내용을 입력해주세요."
          {...register('memo')}
        />

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1 py-4"
          disabled={
            courseCreate
              ? !watch('startLevelId') ||
                !watch('startSeriesId') ||
                !watch('startBookId') ||
                !watch('remainingAmount') ||
                !watch('startedAt') ||
                !watch('endedAt')
              : false
          }
          onClick={() => (courseCreate ? create() : update())}
        />
      </div>
    </div>
  );
};
