import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { TextField } from '../../components/TextField';
import { api, apiUrl } from '../../plugins/axios';
import { resizeImage } from '../../plugins/compress';
import { fetcher } from '../../plugins/react-query';
import { Banner } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  image: File;
  mobileImage: File;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  rank: number;
  isHide: boolean;
}

export const BannerDetails = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);
  const { data: banner, refetch } = useQuery<Banner>(
    `/admin/banners/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!banner) return;
    clearErrors();
    setValue('title', banner.title);
    setValue('description', banner.description);
    setValue('buttonText', banner.buttonText);
    setValue('buttonLink', banner.buttonLink);
    setValue('rank', banner.rank);
    setValue('isHide', banner.isHide);
  }, [clearErrors, setValue, banner, editMode]);

  return (
    <>
      <AdminH1>배너</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <div className="col-span-2 grid grid-cols-4">
            <label htmlFor="cover" className="cursor-pointer">
              <span>PC이미지</span>
              {banner?.image || watch('image') ? (
                <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden">
                  <img
                    src={
                      watch('image')
                        ? URL.createObjectURL(watch('image'))
                        : apiUrl(banner?.image)
                    }
                    alt={banner?.title}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
              ) : (
                <div className="h-40 w-96 grid place-items-center border-2 border-dashed rounded">
                  Upload cover image
                </div>
              )}
              <input
                id="cover"
                type="file"
                accept="image/*"
                className="sr-only"
                disabled={!addMode && !editMode}
                onChange={async (e) => {
                  const file = e.target.files?.item(0);
                  if (!file || !e.target.validity.valid) return;
                  setValue('image', await resizeImage(file));
                }}
              />
            </label>
          </div>

          <div className="col-span-2 grid grid-cols-4">
            <label htmlFor="mobile-cover" className="cursor-pointer">
              <span>Mobile 이미지</span>
              {banner?.mobileImage || watch('mobileImage') ? (
                <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden">
                  <img
                    src={
                      watch('mobileImage')
                        ? URL.createObjectURL(watch('mobileImage'))
                        : apiUrl(banner?.mobileImage)
                    }
                    alt={banner?.title}
                    className="w-full h-full object-center object-cover"
                  />
                </div>
              ) : (
                <div className="h-40 w-96 grid place-items-center border-2 border-dashed rounded">
                  Upload cover Mobile Image
                </div>
              )}
              <input
                id="mobile-cover"
                type="file"
                accept="image/*"
                className="sr-only"
                disabled={!addMode && !editMode}
                onChange={async (e) => {
                  const file = e.target.files?.item(0);
                  if (!file || !e.target.validity.valid) return;
                  setValue('mobileImage', await resizeImage(file));
                }}
              />
            </label>
          </div>

          <TextField
            label="타이틀"
            disabled={!addMode && !editMode}
            helper={errors.title?.message}
            {...register('title', { required: '타이틀을 입력해주세요.' })}
          />

          <TextField
            label="설명"
            disabled={!addMode && !editMode}
            {...register('description')}
          />

          <TextField
            label="버튼 타이틀"
            disabled={!addMode && !editMode}
            {...register('buttonText')}
          />

          <TextField
            label="버튼 링크"
            disabled={!addMode && !editMode}
            {...register('buttonLink')}
          />

          <TextField
            type="number"
            label="Rank"
            disabled={!addMode && !editMode}
            helper={errors.rank?.message}
            {...register('rank', { required: '순서를 입력해주세요.' })}
          />

          <Checkbox
            label="숨김처리"
            disabled={!addMode && !editMode}
            {...register('isHide')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <>
              <Button
                text="취소"
                className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                onClick={() => setEditMode(false)}
              />
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                onClick={handleSubmit(async (data) => {
                  const formData = new FormData();
                  Object.entries(data).forEach(([name, value]) =>
                    formData.append(name, value)
                  );
                  await api.patch(`/admin/banners/${id}`, formData);
                  await refetch();
                  setEditMode(false);
                })}
              />
            </>
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('title') || !watch('rank')}
              onClick={handleSubmit(async (data) => {
                const formData = new FormData();
                Object.entries(data).forEach(([name, value]) =>
                  formData.append(name, value)
                );
                await api
                  .post<Banner>('/admin/banners', formData)
                  .then((res) => push(`/admin/banners/${res.data.id}`))
                  .catch((err) => console.log(err));
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
