import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString, useSearch } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Gender, Paginated, Student } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminSearch } from '../components/AdminSearch';
import moment from 'moment';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import { Button } from '../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { api } from '../../plugins/axios';
import { AdminCumulativeResetTimer } from '../components/AdminCumulativeResetTimer';

export const Students = () => {
  const today = moment();
  const { q, setQ, setSearchText } = useSearch();
  const queryString = useQueryString({ q: q });
  const [isResetTimer, setIsResetTimer] = useState<boolean>(false);

  const { data, refetch } = useQuery<Paginated<Student>>(
    `/admin/students/search/list${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const { data: students } = useQuery<Student[]>(
    `/admin/students/all`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const exporter = () => {
    const studentsData = students?.map((student: any) => {
      const regPhoneNumber = (phoneNumber: any) => {
        phoneNumber = student.user?.phoneNumber?.replace(/[^0-9]/g, '');
        phoneNumber = student.user?.phoneNumber?.replace(
          /(\d{3})(\d{4})(\d)/,
          '$1-$2-$3'
        );
        return phoneNumber.slice(0, 13);
      };
      return {
        자녀이름: student.name,
        생년월일: student.dob,
        성별: student.gender === Gender.MALE ? '남' : '여',
        '부모님 성함': student.user?.name,
        이메일: student.user?.email,
        전화번호: regPhoneNumber(student.user?.phoneNumber),
        '레벨테스트 유무': student.isTested ? 'O' : 'X',
        '마케팅 수신여부': student.user?.marketing ? '동의' : '미동의',
        가입일: student.user?.createdAt
          ? moment(student.user?.createdAt).format('YYYY.MM.DD')
          : '',
        '레벨테스트 응시일': student.studentAggregation?.testResultedAt
          ? utcToLocalFormat(
              student.studentAggregation?.testResultedAt,
              MomentFormat.YYYYMMDDHmm
            )
          : '',
        체험수업일: student.studentAggregation?.liveTrialedAt
          ? utcToLocalFormat(
              student.studentAggregation?.liveTrialedAt,
              MomentFormat.YYYYMMDDHmm
            )
          : '',
        '체험수업 교사': student.studentAggregation?.liveTrialTeacherName,
        최초구매일: student.studentAggregation?.courseCreatedAt
          ? utcToLocalFormat(
              student.studentAggregation?.courseCreatedAt,
              MomentFormat.YYYYMMDD
            )
          : '',
        최근구매일: student.studentAggregation?.lastCourseCreatedAt
          ? utcToLocalFormat(
              student.studentAggregation?.lastCourseCreatedAt,
              MomentFormat.YYYYMMDD
            )
          : '',
        '총 구매 횟수': student.studentAggregation?.coursePaymentCount,
        현재학습레벨: student.studentAggregation?.studentNowLevel,
        최근접속일자: student.studentAggregation?.accessedAt
          ? utcToLocalFormat(
              student.studentAggregation?.accessedAt,
              MomentFormat.YYYYMMDDHmm
            )
          : '',
        잔여수업횟수: student.studentAggregation?.courseRemainingCount,
        '유효기간 종료일자': student.studentAggregation?.courseExpiredAt
          ? utcToLocalFormat(
              student.studentAggregation?.courseExpiredAt,
              MomentFormat.YYYYMMDD
            )
          : '',
        누적구매금액: student.studentAggregation?.paymentTotalPrice,
        '블랙리스트 여부': student.user?.blackList ? 'O' : 'X',
        '회원 탈퇴여부': student.user.isDisabled ? 'O' : 'X',
        메모: student.memo,
        유입경로: student.inflowPath,
        '현재 포인트 합계': student.cumulativePoint.currentTotalPoint,
      };
    });

    jsonExport(studentsData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(
        `${BOM} ${csv}`,
        `자녀 관리(${today.format('YYYY 년 MM 월')})`
      );
    });
  };

  return (
    <>
      <AdminH1>자녀 관리</AdminH1>

      <div className="flex justify-end space-x-6">
        <Button
          text="리셋 예약시간 설정"
          onClick={() => setIsResetTimer(true)}
          className="bg-white border-primary-1 text-primary-1"
        />
        <Button
          className="bg-blue-500 text-white text-14"
          onClick={async () => {
            const onReset = window.confirm('정말 초기화 하시겠습니까?');
            if (onReset) {
              await api.patch(`/admin/cumulative-points/reset/point`);
              await refetch();
              alert('초기화가 완료되었습니다.');
            }
          }}
        >
          전체 포인트 리셋
        </Button>
        <Button className="bg-white text-blue-500 text-14" onClick={exporter}>
          파일 내보내기
        </Button>
      </div>

      <AdminSearch
        placeholder="검색"
        value={q}
        onChange={(e) => setQ(e.target.value)}
      />
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>자녀명</Table.Th>
              <Table.Th>학부모명</Table.Th>
              <Table.Th>생년월일</Table.Th>
              <Table.Th>성별</Table.Th>
              <Table.Th>레벨테스트 유무</Table.Th>
              <Table.Th>현재 포인트 합계</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items.map((student) => (
              <Table.Row key={student.id}>
                <Table.Td>{student.name}</Table.Td>
                <Table.Td>{student.user.name}</Table.Td>
                <Table.Td>{student.dob}</Table.Td>
                <Table.Td>
                  {student.gender === Gender.MALE ? '남' : '여'}
                </Table.Td>
                <Table.Td>{student.isTested ? ' O' : 'X'}</Table.Td>
                <Table.Td>
                  {student.cumulativePoint?.currentTotalPoint}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/users/${student.userId}/students?itemId=${student.id}`}
                    onClick={setSearchText}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data?.items.length || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav basePath="/admin/students" total={data?.total || 0} />
        </Pagination>
      </AdminCard>

      {isResetTimer && (
        <AdminCumulativeResetTimer
          cumulativePoint={students && students[0]?.cumulativePoint}
          onClose={() => setIsResetTimer(false)}
          refetch={() => refetch()}
        />
      )}
    </>
  );
};
