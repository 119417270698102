import mainStep1 from '../../assets/img/main-step-1.png';
import mainStep2 from '../../assets/img/main-step-2.png';
import mainStep3 from '../../assets/img/main-step-3.png';
import mainStep4 from '../../assets/img/main-step-4.png';

export const steps = [
  {
    id: 1,
    title: '1단계',
    graphImg: mainStep1,
  },
  {
    id: 2,
    title: '2단계',
    graphImg: mainStep2,
  },
  {
    id: 3,
    title: '3단계',
    graphImg: mainStep3,
  },
  {
    id: 4,
    title: '4단계',
    graphImg: mainStep4,
  },
];

export const speakingsteps01 = [
  {
    id: 1,
    title: 'Jobs',
    graphImg: mainStep1,
  },
  {
    id: 2,
    title: 'How many~?',
    graphImg: mainStep2,
  },
  {
    id: 3,
    title: 'Shapes',
    graphImg: mainStep3,
  },
  {
    id: 4,
    title: 'My home',
    graphImg: mainStep4,
  },
];
