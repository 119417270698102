import { chain } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import {
  CancelType,
  Course,
  CourseItem,
  Entry,
  Live,
  LiveState,
  LiveType,
  Paginated,
  TestResult,
  classTypeToText,
} from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminCourseIsOpenPopUp } from '../components/AdminCourseIsOpenPopUp';
import { AdminCoursePopUp } from '../components/AdminCoursePopUp';
import { AdminCourseValidityPopUp } from '../components/AdminCourseValidityPopUp';
import { AdminH1 } from '../components/AdminH1';

export const UserStudentLives = () => {
  const [isOpenCount, setIsOpenCount] = useState<boolean>(false);
  const [isOpenValidity, setIsOpenValidity] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { userId, studentId, courseId } = useParams<{
    userId: string;
    studentId: string;
    courseId: string;
  }>();
  const studentIdQueryString = useQueryString({
    filter: { studentId: studentId },
    join: ['series'],
    sort: { createdAt: 'DESC' },
  });
  const QueryString = useQueryString({
    filter: { courseId },
    join: [
      'liveClass',
      'liveClass.book',
      'liveClass.book.lessons',
      'liveClass.book.points',
    ],
    sort: { id: 'ASC' },
  });

  const { data: course, refetch } = useQuery<Course>(
    `/admin/courses/query?studentId=${studentId}&courseId=${courseId}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: lives } = useQuery<Live[]>(
    `/admin/lives/student/lives?studentId=${studentId}&courseId=${courseId}`,
    fetcher,
    { keepPreviousData: true, enabled: !!courseId && !!studentId }
  );

  const { data: allLives } = useQuery<Live[]>(
    `/admin/lives/byStudentIdAndCourseId?studentId=${studentId}&courseId=${courseId}`,
    fetcher,
    { keepPreviousData: true, enabled: !!courseId && !!studentId }
  );

  const { data: courseItems } = useQuery<Paginated<CourseItem>>(
    `/admin/course-items${QueryString}`,
    fetcher,
    { enabled: !!courseId, keepPreviousData: true }
  );

  const { data: testResults } = useQuery<Paginated<TestResult>>(
    `/admin/test-results/${studentIdQueryString}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  const { data: entries } = useQuery<Entry>(
    `/admin/entries/admin-entry/${testResults?.items[0]?.id}`,
    fetcher,
    { enabled: !!testResults?.items[0]?.id }
  );

  const attendedLives = lives?.filter(
    (live) => live.state === LiveState.ATTENDED
  );

  const cancelLives = lives?.filter((live) => {
    const canceledAt = new Date(live.canceledAt);
    canceledAt.setHours(0, 0, 0, 0);
    const startedAt = new Date(live.start);
    startedAt.setHours(0, 0, 0, 0);

    return (
      (live.state === LiveState.CANCELED &&
        moment(startedAt).isSame(canceledAt)) ||
      live.state === LiveState.CANCELLATION_THE_DAY
    );
  });

  const absentLives = lives?.filter(
    (live) =>
      live.state === LiveState.ABSENT_AND_NO_SHOW ||
      live.state === LiveState.ABSENT
  );

  const entryLevel =
    entries?.levelCode === '1-A' ||
    entries?.levelCode === '1-B' ||
    entries?.levelCode === '1-C' ||
    entries?.levelCode === '1-D'
      ? 'Level 1'
      : entries?.levelCode === '2-A' ||
        entries?.levelCode === '2-B' ||
        entries?.levelCode === '2-C'
      ? 'Level 2'
      : entries?.levelCode === '3-A' ||
        entries?.levelCode === '3-B' ||
        entries?.levelCode === '3-C'
      ? 'Level 3'
      : '';

  const entrySeries =
    entries?.levelCode === '1-A' || entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C' || entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A' || entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A' || entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  if (!course) return <></>;

  const startedAt = new Date(course.startedAt);
  startedAt.setHours(0, 0, 0, 0);
  const endedAt = new Date(course.endedAt);
  endedAt.setHours(23, 59, 59, 59);

  return (
    <>
      <div className="space-y-4">
        <div className="flex justify-between">
          <AdminH1>수업정보</AdminH1>
          <div className="flex space-x-4">
            <Button
              text="수강권 비활성화 처리"
              onClick={() => setIsDisabled(true)}
              className="bg-primary-1 text-white"
            />
            <Button
              text="수강권 유효기간 변경"
              onClick={() => setIsOpenValidity(true)}
              className="bg-white border-primary-1 text-primary-1"
            />
            <Button
              text="수강권 횟수 변경"
              onClick={() => setIsOpenCount(true)}
              className="bg-primary-1 text-white"
            />
          </div>
        </div>
      </div>
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>구분</Table.Th>
              <Table.Th>시작일자</Table.Th>
              <Table.Th>종료일자</Table.Th>
              <Table.Th>전체 횟수</Table.Th>
              <Table.Th>잔여 횟수</Table.Th>
              <Table.Th>시작 레벨</Table.Th>
              <Table.Th>시작 교재</Table.Th>
              <Table.Th>시작 진도</Table.Th>
              <Table.Th>출석 횟수</Table.Th>
              <Table.Th>
                결석 및 당일 취소
                <br />
                횟수
              </Table.Th>
              <Table.Th>비활성화</Table.Th>
              <Table.Th>관리자 메모</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              <Table.Td>
                {course.isTrial && course.initialAmount === 1
                  ? '체험'
                  : course.isTrial && course.initialAmount > 1
                  ? '체험팩'
                  : classTypeToText(course?.startLevel?.classType)}
              </Table.Td>
              <Table.Td>
                {course.startedAt ? (
                  <>
                    {utcToLocalFormat(
                      startedAt.toISOString(),
                      MomentFormat.YYYYMMDDHHmmss
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Table.Td>
              <Table.Td>
                {course.endedAt ? (
                  <>
                    {utcToLocalFormat(
                      endedAt.toISOString(),
                      MomentFormat.YYYYMMDDHHmmss
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Table.Td>
              <Table.Td>{course.initialAmount}회</Table.Td>
              <Table.Td>{course.remainingAmount}회</Table.Td>
              <Table.Td>{course.startLevel?.name ?? entryLevel}</Table.Td>
              <Table.Td>
                {course.startSeries ? course.startSeries?.name : entrySeries}
              </Table.Td>
              <Table.Td>
                {course.startBook ? `${course.startBook?.title}` : entrySeries}
              </Table.Td>
              <Table.Td>{attendedLives?.length}</Table.Td>
              <Table.Td>
                {absentLives &&
                  cancelLives &&
                  absentLives?.length + cancelLives?.length}
              </Table.Td>
              <Table.Td>{course.isHide ? 'O' : 'X'}</Table.Td>
              <Table.Td>{course.memo}</Table.Td>
            </Table.Row>
          </Table.Body>
        </Table>
      </AdminCard>
      <AdminH1>학습정보</AdminH1>
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>회차</Table.Th>
              <Table.Th>수업 구분</Table.Th>
              <Table.Th>예약 정보</Table.Th>
              <Table.Th className="whitespace-pre">진도</Table.Th>
              <Table.Th className="whitespace-pre">{`선생님\n피드백`}</Table.Th>
              <Table.Th className="whitespace-pre">{`Lesson\n수강`}</Table.Th>
              <Table.Th className="whitespace-pre">포인트지급</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {courseItems?.items.length === 0
              ? '학습정보가 없습니다.'
              : chain(allLives)
                  .map((live) => Object.values(live))
                  .flatten()
                  .map((lives: Live[]) => {
                    const doneLive = lives.filter(
                      (l) => l.state === LiveState.RESERVED
                    );

                    const otherLive = lives.filter(
                      (l) => l.state !== LiveState.RESERVED
                    );
                    return lives.map((live: any, index) => {
                      const reservedCount =
                        live.state === LiveState.RESERVED &&
                        doneLive.map((l, i) => {
                          if (l.id === live.id) {
                            return live?.firstCourseItem?.number;
                          } else {
                            return '';
                          }
                        });

                      const start = new Date(live.start);
                      start.setHours(0, 0, 0, 0);
                      const canceledAt = new Date(live.canceledAt);
                      canceledAt.setHours(0, 0, 0, 0);

                      return (
                        <Table.Row key={live.id}>
                          <Table.Td>
                            {live.liveType === LiveType.TRIAL &&
                            course.initialAmount === 1
                              ? 1
                              : live.attendRound && live.courseItem
                              ? live.attendRound
                              : live.cancelRound
                              ? live.cancelRound
                              : live.state === LiveState.RESERVED &&
                                live?.firstCourseItem
                              ? live?.firstCourseItem?.number
                              : index + 1 - otherLive.length}
                          </Table.Td>
                          <Table.Td
                            className={
                              live.state === LiveState.CANCELED ||
                              live.state === LiveState.ABSENT ||
                              live.state === LiveState.NO_SHOW ||
                              live.state === LiveState.ABSENT_AND_NO_SHOW ||
                              live.state === LiveState.CANCELLATION_THE_DAY
                                ? 'text-primary-8'
                                : ''
                            }
                          >
                            {(live.state === LiveState.CANCELED &&
                              moment(start).isSame(canceledAt)) ||
                            live.state === LiveState.CANCELLATION_THE_DAY
                              ? `${
                                  live.cancelType === CancelType.ADMIN
                                    ? '관리자'
                                    : '학생'
                                } 당일 취소`
                              : live.state === LiveState.AVAILABLE
                              ? '예약가능'
                              : live.state === LiveState.RESERVED
                              ? '예약완료'
                              : live.state === LiveState.CANCELED
                              ? `${
                                  live.cancelType === CancelType.ADMIN
                                    ? '관리자'
                                    : '학생'
                                } 취소`
                              : live.state === LiveState.ATTENDED
                              ? '출석'
                              : live.state === LiveState.ABSENT
                              ? '결석'
                              : live.state === LiveState.NO_SHOW
                              ? '선생님 노쇼'
                              : '학생 결석 / 선생님 노쇼'}
                          </Table.Td>
                          <Table.Td>
                            <div>
                              {utcToLocalFormat(
                                live.start.toLocaleString(),
                                MomentFormat.YYYYMMDDHmm
                              )}
                            </div>
                            <div>{live?.teacher}</div>
                          </Table.Td>
                          <Table.Td>
                            {live?.courseItem?.liveClass &&
                            live?.courseItem?.liveClass?.book?.title === '체험'
                              ? entrySeries
                              : live?.courseItem?.liveClass
                              ? live?.courseItem?.liveClass?.book?.title
                              : live?.firstCourseItem &&
                                live?.firstCourseItem?.liveClass?.book
                                  ?.title === '체험'
                              ? entrySeries
                              : live?.firstCourseItem?.liveClass?.book?.title}
                          </Table.Td>
                          <Table.Td>
                            {!live?.liveFeedbacks ? 'X' : 'O'}
                          </Table.Td>
                          <Table.Td>
                            {live?.courseItem?.liveClass?.book?.lessonResults
                              ? live?.courseItem?.liveClass?.book?.lessonResults
                                  ?.length
                              : live?.firstCourseItem?.liveClass?.book
                                  ?.lessonResults
                              ? live?.firstCourseItem?.liveClass?.book
                                  ?.lessonResults?.length
                              : 0}{' '}
                            /{' '}
                            {live?.courseItem?.liveClass?.book?.lessons
                              ? live?.courseItem?.liveClass?.book?.lessons
                                  .length
                              : live?.firstCourseItem?.liveClass?.book?.lessons
                              ? live?.firstCourseItem?.liveClass?.book?.lessons
                                  .length
                              : 0}
                          </Table.Td>
                          <Table.Td>
                            {live.points ? `+${live?.points?.value}` : 0}
                          </Table.Td>

                          {(live?.courseItem || live?.firstCourseItem) && (
                            <Table.Td className="text-right flex space-x-3">
                              <Button
                                className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                                to={`/admin/users/${userId}/students/${studentId}/courses/${courseId}/courseItems/${
                                  live?.courseItem
                                    ? live?.courseItem?.id
                                    : live?.firstCourseItem.id
                                }?liveId=${live.id}&round=${
                                  reservedCount &&
                                  reservedCount.toString().replace(/,/g, '')
                                }`}
                              >
                                상세보기
                              </Button>
                            </Table.Td>
                          )}
                        </Table.Row>
                      );
                    });
                  })
                  .value()}
          </Table.Body>
        </Table>
      </AdminCard>

      {isOpenCount && (
        <AdminCoursePopUp
          courseCreate={false}
          onClose={() => setIsOpenCount(false)}
          course={course}
          refetch={() => refetch()}
        />
      )}
      {isOpenValidity && (
        <AdminCourseValidityPopUp
          onClose={() => setIsOpenValidity(false)}
          course={course}
          refetch={() => refetch()}
        />
      )}
      {isDisabled && (
        <AdminCourseIsOpenPopUp
          onClose={() => setIsDisabled(false)}
          course={course}
          refetch={() => refetch()}
        />
      )}
    </>
  );
};
