import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { api } from '../../plugins/axios';
import { Course } from '../../types';

interface FormValues {
  isHide: boolean;
}

interface AdminCourseIsOpenPopUpProps {
  course: Course;
  onClose: () => void;
  refetch: () => void;
}

export const AdminCourseIsOpenPopUp: FC<AdminCourseIsOpenPopUpProps> = ({
  course,
  onClose,
  refetch,
}) => {
  const { register, handleSubmit, setValue } = useForm<FormValues>();

  const update = handleSubmit((data) => {
    return api
      .patch(`/admin/courses/${course.id}/disabled`, data)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((err) => console.error('수강권 비활성화 처리 오류', err));
  });

  useEffect(() => {
    if (!course) return;
    setValue('isHide', course.isHide);
  }, [course, setValue]);

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>
        <H3 className="pb-4">수강권 비활성화 처리</H3>
        <Checkbox label="비활성화" {...register('isHide')} />
        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          onClick={update}
        />
      </div>
    </div>
  );
};
