import React from 'react';
import { Button } from '../components/Button';
import { ReactComponent as SuccessSVG } from '../assets/svg/success.svg';
import { H5 } from '../components/H5';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

export const PurchaseSuccessPage = () => {
  const { search } = useLocation();
  const { orderNumber } = parse(search, { ignoreQueryPrefix: true });
  const isPaymentSuccess = !!orderNumber;

  return (
    <div className="flex flex-col items-center pt-14 px-4 min-h-screen-10 justify-center">
      {isPaymentSuccess ? (
        <>
          <SuccessSVG />
          <H5 className="text-center">
            수업 구매가 완료되었습니다.
            <br />
            체험, 정규 수업 모두 직접 스케줄 예약을 해주세요!
          </H5>
          <p className="text-gray-600 text-center pt-4 pb-8">
            수업 예약 방법: 나의 학습 → 예약 → 시간/선생님 선택
          </p>
          <Button
            to="/learn"
            text="수업 예약하기"
            className="filled-primary-1 w-full md:w-1/3"
          />
        </>
      ) : (
        <div className="space-y-10 w-full text-center">
          <div>결제가 취소되었습니다.</div>
          <Button
            to="/"
            text="메인으로 돌아가기"
            className="filled-primary-1 w-full md:w-1/3"
          />
        </div>
      )}
    </div>
  );
};
