import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { ReactComponent as Search } from '../../assets/icons/icon-search.svg';
import { ReactComponent as X } from '../../assets/icons/icon-x.svg';

interface AdminSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
}

export const AdminSearch: FC<AdminSearchProps> = ({
  value,
  onSearch = () => {},
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  return (
    <form
      className="flex items-center px-4 h-10 rounded-md border border-gray-200 focus-within:border-brand-1"
      onSubmit={(e) => {
        e.preventDefault();
        onSearch();
      }}
    >
      <div className="flex justify-center items-center -ml-2 w-10 h-10">
        <Search />
      </div>
      <input
        ref={input}
        className="flex-1 text-sm text-black bg-secondary-9"
        value={value}
        {...props}
      />
      {value && (
        <div className="flex justify-center items-center -mr-2 wh-10">
          <X
            className="wh-4"
            onClick={() => {
              Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
              )?.set?.call(input.current, '');
              input.current?.dispatchEvent(
                new Event('change', { bubbles: true })
              );
            }}
          />
        </div>
      )}
    </form>
  );
};
