import { liveFeedbackPopupState } from '../plugins/ridge';
import { Button } from './Button';
import { XIcon } from './icons';
// @ts-ignore
import ReactStars from 'react-rating-stars-component';
import { useState } from 'react';
import { api } from '../plugins/axios';
import { useHistory } from 'react-router-dom';

export const LiveFeedbackPopup = () => {
  const { push } = useHistory();
  const { open, teacherName, liveId, teacherId, studentId } =
    liveFeedbackPopupState.useValue();
  const [rate, setRate] = useState(0);

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="card">
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-32 whitespace-pre">
            오늘 {teacherName}선생님과의 <br />
            수업은 어땠나요?
          </h1>
          <button className="mt-1" onClick={liveFeedbackPopupState.reset}>
            <XIcon />
          </button>
        </div>
        <div className="mt-10 grid justify-center">
          <ReactStars
            count={5}
            size={40}
            edit={true}
            value={0}
            onChange={(rating: number) => setRate(rating)}
          />
        </div>

        <div className="mt-14 flex flex-col space-y-3">
          <Button
            text={`평가완료`}
            className="filled-primary-1"
            onClick={() => {
              api
                .post(`/live-reviews`, {
                  rate,
                  liveId,
                  studentId,
                  teacherId,
                })
                .then(() => {
                  liveFeedbackPopupState.reset();
                  push('/learn/classroom');
                })
                .catch((err) => alert(err));
            }}
          />
        </div>
      </div>
    </div>
  );
};
