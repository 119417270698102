import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Payment, PaymentState, classTypeToText } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';

export const PaymentInclude = () => {
  const [select, setSelect] = useState<PaymentState | string>();
  const queryString = useQueryString({
    filter: {
      paymentState: select,
    },
    join: ['user', 'student'],
  });
  const { data } = useQuery<Paginated<Payment>>(
    `/admin/payments/include/all/${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  const includedPaymentData = data?.items.filter(
    (payment) => payment.bookIncluded || payment.penIncluded
  );
  const includedPaymentTotal = includedPaymentData?.length;
  if (!includedPaymentData) return <></>;
  return (
    <>
      <AdminTabs>
        {[
          {
            id: 1,
            text: '전체',
          },
          {
            id: 2,
            text: '상담대기',
            state: PaymentState.WAITING_FOR_CONSULTATION,
          },
          {
            id: 2,
            text: '배송 준비',
            state: PaymentState.WAITING_FOR_DELIVERY,
          },
          {
            id: 3,
            text: '출고완료',
            state: PaymentState.LEAVING,
          },
        ].map((payment) => (
          <AdminTab
            key={payment.id}
            text={payment.text}
            selected={payment.state === select}
            onClick={() => setSelect(payment.state)}
          />
        ))}
      </AdminTabs>
      {/* <Button
        className="bg-white text-blue-500 ml-auto text-14"
        onClick={exporter}
      >
        파일 내보내기
      </Button> */}
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>주문일자</Table.Th>
              <Table.Th>주문자</Table.Th>
              <Table.Th>상품유형</Table.Th>
              <Table.Th>주문상품</Table.Th>
              <Table.Th>결제금액</Table.Th>
              <Table.Th>할인금액</Table.Th>
              <Table.Th>교재</Table.Th>
              <Table.Th>TLD</Table.Th>
              <Table.Th>주문상태</Table.Th>
              <Table.Th>관리자메모</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {includedPaymentData.map((payment) => (
              <Table.Row key={payment.id + payment.userId}>
                <Table.Td>
                  {moment(payment.createdAt).format('YYYY.MM.DD')}
                </Table.Td>
                <Table.Td>{payment.student.name}</Table.Td>
                <Table.Td>
                  {payment?.paymentProducts?.length > 0
                    ? classTypeToText(
                        payment?.paymentProducts[0]?.product?.classType
                      )
                    : ''}
                </Table.Td>
                <Table.Td>{payment.productAmount || 0}회 수업</Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.paidAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.discountedAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>{payment?.bookIncluded ? '포함' : '-'}</Table.Td>
                <Table.Td>{payment?.penIncluded ? '포함' : '-'}</Table.Td>
                <Table.Td>
                  {payment.paymentState ===
                  PaymentState.WAITING_FOR_CONSULTATION
                    ? '상담대기'
                    : payment.paymentState === PaymentState.WAITING_FOR_DELIVERY
                    ? '배송준비'
                    : payment.paymentState === PaymentState.LEAVING
                    ? '출고완료'
                    : ''}
                </Table.Td>
                <Table.Td>{payment.memo}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/payments/${payment.id}/edit`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={includedPaymentTotal || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav
            basePath="/admin/payments/true"
            total={data?.total || 0}
          />
        </Pagination>
      </AdminCard>
    </>
  );
};
