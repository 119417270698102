import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { Select } from '../../components/Select';
import { api } from '../../plugins/axios';
import { CancelType, CourseItem, Live, LiveState } from '../../types';
import { useHistory, useLocation } from 'react-router-dom';

interface FormValues {
  state: any;
  courseItemId?: number | null;
  cancelType?: CancelType | null;
  attendRound?: number | null;
  cancelRound?: number | null;
  canceledAt?: Date | null;
  isManualChange: boolean;
}

interface AdminLiveStatePopUpProps {
  live: Live;
  courseItem?: CourseItem;
  cancelRound: number;
  onClose: () => void;
  refetch: () => void;
}

export const AdminLiveStatePopUp: FC<AdminLiveStatePopUpProps> = ({
  live,
  courseItem,
  cancelRound,
  refetch,
  onClose,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const chunks = pathname.split('/');

  const { watch, setValue, handleSubmit, clearErrors } = useForm<FormValues>();
  useEffect(() => {
    if (!live) return;
    clearErrors();
    setValue('state', live.state);
  }, [clearErrors, setValue, live]);

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">수업진행 상태 변경</H3>
        <Select
          label="수업진행 상태"
          value={watch('state')}
          onChange={(e) => setValue('state', e.target.value)}
        >
          {[
            {
              id: 1,
              label: '취소',
              state: LiveState.CANCELED,
            },
            { id: 2, label: '출석', state: LiveState.ATTENDED },
            { id: 3, label: '결석', state: LiveState.ABSENT },
            { id: 4, label: '선생님 노쇼', state: LiveState.NO_SHOW },
            {
              id: 5,
              label: '결석/선생님 노쇼',
              state: LiveState.ABSENT_AND_NO_SHOW,
            },
            {
              id: 6,
              label: '예약완료',
              state: LiveState.RESERVED,
            },
            {
              id: 7,
              label: '당일취소',
              state: LiveState.CANCELLATION_THE_DAY,
            },
            {
              id: 8,
              label: '예약가능',
              state: LiveState.AVAILABLE,
            },
          ].map((s) => (
            <option key={s.id} label={s.label} value={s.state}></option>
          ))}
        </Select>

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          onClick={handleSubmit((data) => {
            data.cancelType =
              data.state === LiveState.ABSENT ||
              data.state === LiveState.ABSENT_AND_NO_SHOW ||
              data.state === LiveState.CANCELED ||
              data.state === LiveState.NO_SHOW ||
              data.state === LiveState.CANCELLATION_THE_DAY
                ? CancelType.ADMIN
                : null;

            data.canceledAt =
              data.state === LiveState.ABSENT ||
              data.state === LiveState.ABSENT_AND_NO_SHOW ||
              data.state === LiveState.CANCELED ||
              data.state === LiveState.NO_SHOW ||
              data.state === LiveState.CANCELLATION_THE_DAY
                ? new Date()
                : null;

            data.cancelRound =
              data.state === LiveState.ABSENT ||
              data.state === LiveState.ABSENT_AND_NO_SHOW ||
              data.state === LiveState.CANCELED ||
              data.state === LiveState.NO_SHOW ||
              data.state === LiveState.CANCELLATION_THE_DAY
                ? cancelRound
                : null;

            data.attendRound =
              data.state === LiveState.ATTENDED ? courseItem?.number : null;

            data.courseItemId =
              data.state === LiveState.ATTENDED ? courseItem?.id : null;

            data.isManualChange = true;

            api
              .patch(`/admin/lives/${live?.id}`, data)
              .then(() => {
                alert('수업 진행 상태가 변경되었습니다.');
                refetch();
                onClose();
                push(
                  `/admin/users/${chunks[3]}/students/${chunks[5]}/courses/${chunks[7]}`
                );
              })
              .catch((err) => {
                alert(`${err.response.data.message}`);
                onClose();
              });
          })}
        />
      </div>
    </div>
  );
};
