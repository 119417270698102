import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import GreenAvatar from '../assets/img/avatar-green.png';
import { ReactComponent as ClipboardIcon } from '../assets/svg/icon-clipboard.svg';
import { Dropdown } from '../components/Dropdown';
import { H6 } from '../components/H6';
import { ChevronDownIcon } from '../components/icons';
import { Menu } from '../components/Menu';
import { Section } from '../components/Section';
import { Tab } from '../components/Tab';
import { api, apiUrl } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import {
  courseItemState,
  courseState,
  latestCourseItemState,
  latestCourseState,
  latestLiveState,
  popupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import {
  Banner,
  ClassType,
  Course,
  CumulativePoint,
  Live,
  LiveState,
  classTypeToText,
} from '../types';
import { ClassroomPage } from './ClassroomPage';
import { LiveReportPage } from './LiveReportPage';
import { LivesPage } from './LivesPage';
import { ReservationPage } from './ReservationPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import moment from 'moment';
import { Button } from '../components/Button';
// import { io, Socket } from 'socket.io-client';
import { isMobile } from 'react-device-detect';
import { LearnCoursePage } from './LearnCoursePage';

const tabs = [
  // { title: '나의 수강권', path: '/learn/course' },
  { title: '나의 강의실', path: '/learn/classroom' },
  { title: '나의 수업', path: '/learn/lives' },
  { title: '예약', path: '/learn/reservation' },
];

export const LearnPage = () => {
  // const [socket, setSocket] = useState<Socket | null>(null);
  const student = studentState.useValue();
  const course = courseState.useValue();
  const testResult = testResultState.useValue();
  const courseItem = courseItemState.useValue();
  const latestCourse = latestCourseState.useValue();
  const latestCourseItem = latestCourseItemState.useValue();
  const latestLive = latestLiveState.useValue();
  const [courseType, setCourseType] = useState<ClassType>(ClassType.TRIAL);
  const [classTypeList, setClassTypeList] = useState<
    { name: string; type: ClassType; isInclude: boolean }[]
  >([]);

  // coursetype 세팅
  useEffect(() => {
    if (!course) return;

    const type =
      course && !course.isTrial ? course.startLevel.classType : ClassType.TRIAL;

    if (ClassType.TRIAL !== type) {
      setCourseType(
        course && !course.isTrial
          ? course.startLevel.classType
          : ClassType.TRIAL
      );
    }
  }, [course]);

  const { data: courses } = useQuery<Course[]>(
    `/courses?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  const { data: cumulativePoint } = useQuery<CumulativePoint>(
    `/cumulative-points/student/${student?.id}`,
    fetcher,
    {
      enabled: !!student,
    }
  );

  const { data: lives } = useQuery<Live[]>(
    `/lives?studentId=${student?.id}&courseId=${course?.id}`,
    fetcher,
    {
      enabled: !!student && !!course,
    }
  );

  const { data: banners } = useQuery<Banner[]>(`/banners`, fetcher);

  const doneLives = lives?.filter((l) => l.state === LiveState.ATTENDED);

  useEffect(() => {
    if (!courses) return;

    var defaultClassTypeList = [
      { name: '체험', type: ClassType.TRIAL, isInclude: false },
      { name: '정규', type: ClassType.REGULAR, isInclude: false },
      { name: '특강', type: ClassType.SPECIAL, isInclude: false },
    ];

    courses.forEach((course) => {
      // 체험 세팅
      if (course.isTrial && !defaultClassTypeList[0].isInclude) {
        defaultClassTypeList[0].isInclude = true;
      }

      // 정규 세팅
      if (
        !course.isTrial &&
        course.startLevel?.classType === ClassType.REGULAR &&
        !defaultClassTypeList[1].isInclude
      )
        defaultClassTypeList[1].isInclude = true;

      // 특강 세팅
      if (
        !course.isTrial &&
        course.startLevel?.classType === ClassType.SPECIAL &&
        !defaultClassTypeList[2].isInclude
      )
        defaultClassTypeList[2].isInclude = true;
    });

    setClassTypeList(defaultClassTypeList);
  }, [courses]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <></>,
    prevArrow: <></>,
  };

  const now = new Date();
  const checkTime = new Date(latestLive?.start || '');
  checkTime.setMinutes(checkTime.getMinutes() - 5);
  const end = new Date(latestLive?.end || '');
  end.setMinutes(end.getMinutes() - 5);

  const enterLive = async () => {
    if (!student || !latestLive) return;
    if (!student.pageCallAccessToken) {
      alert('강의실 입장 권한이 없습니다.');
      return;
    }
    if (!latestLive.pageCallRoomId) {
      alert('강의실 입장이 불가합니다.');
      return;
    }
    if (latestLive.state === LiveState.NO_SHOW) {
      popupState.set({
        title: '수업 종료 안내',
        body: `선생님이 수업시작 10분내로 입장하지않아 노쇼처리 되었습니다.\n 문의사항은 고객센터로 연락주세요`,
      });
    }
    if (latestLive.state === LiveState.ABSENT) {
      popupState.set({
        title: '수업 종료 안내',
        body: `수업시작 10분내로 입장하지않아 결석 처리 되었습니다.\n 다음 회차 수업을 예약해주세요.`,
      });
    }
    if (latestLive.state === LiveState.ABSENT_AND_NO_SHOW) {
      popupState.set({
        title: '수업 종료 안내',
        body: `수업시작 10분내로 입장하지않아 결석 처리 되었습니다.\n 다음 회차 수업을 예약해주세요.`,
      });
    }
    if (
      latestLive.state === LiveState.CANCELED ||
      latestLive.state === LiveState.CANCELLATION_THE_DAY
    ) {
      popupState.set({
        title: '수업 종료 안내',
        body: `취소 처리된 수업입니다..\n 다음 회차 수업을 예약해주세요.`,
      });
    }

    if (latestLive.state === LiveState.RESERVED) {
      const attendFindCourse = latestCourse?.lives
        .filter((l) => l.attendRound)
        .sort((a, b) => a.attendRound - b.attendRound);

      const attendFindCourseItem =
        attendFindCourse &&
        attendFindCourse.length > 0 &&
        attendFindCourse[attendFindCourse.length - 1];

      const attendCourseItem = latestCourse?.courseItems.find(
        (ci) =>
          attendFindCourseItem &&
          ci.number === attendFindCourseItem?.attendRound
      );

      const nextCourseItem = latestCourse?.courseItems.find(
        (ci) => attendCourseItem && ci.number === attendCourseItem?.number + 1
      );

      await api
        .patch(`/lives/courseItem/${latestLive.id}`, {
          courseItemId: nextCourseItem
            ? nextCourseItem?.id
            : latestCourse?.courseItems[0].id,
        })
        .then(async () => {
          // socket?.emit('join', { liveId: latestLive?.id });
          const pageCallAccessToken = student.pageCallAccessToken
            ? student.pageCallAccessToken
            : '';
          window.open(
            `https://app.pagecall.net/${latestLive.pageCallRoomId}/?access_token=${pageCallAccessToken}`
          );
        })
        .catch((e) => console.log('라이브 수정 오류', e));
    }
  };

  // useEffect(() => {
  //   const socket = io(`https://www.tuntunlive.com/liveSocket`);
  //   socket.on('close', () => {
  //     push('/lives/feedback');
  //   });
  //   setSocket(socket);
  //   return () => {
  //     socket.close();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  if (!student) {
    return <></>;
  }
  return (
    <div>
      <div className="mx-auto px-4 pt-4 w-full max-w-5xl">
        <div className="flex md:pl-24 space-x-4">
          {tabs.map((t) => (
            <Tab
              key={t.title}
              title={t.title}
              path={
                !student
                  ? 'student'
                  : t.path === '/learn/classroom'
                  ? t.path
                  : course?.startLevel?.classType === ClassType.SPECIAL
                  ? t.path
                  : !testResult || !student.isTested
                  ? 'testResult'
                  : !course
                  ? 'course'
                  : t.path
              }
            />
          ))}
        </div>
      </div>

      <div style={{ backgroundColor: '#F9FAFB' }} className=" min-h-screen">
        <div className="max-w-5xl mx-auto px-4 pt-8">
          {/* 배너 */}
          <Slider {...settings}>
            {!course && (
              <Section className="w-full bg-center bg-cover bg-no-repeat rounded-xl h-28  px-8 py-6 bg-primary-10">
                <div className="flex justify-between items-center">
                  <div className="flex space-x-4 items-center">
                    <div className="bg-white rounded-full wh-16 grid place-content-center">
                      <ClipboardIcon />
                    </div>
                    <div>
                      <H6>무료 체험수업 신청하기</H6>
                      <p className="text-gray-400">
                        우리 아이에게 딱 맞는 선생님과 1:1로 튼튼하게! <br />
                        지금 튼튼영어라이브를 직접 체험해 보세요.
                      </p>
                    </div>
                  </div>
                  <Link
                    to="/pricing/trial"
                    className="bg-white text-primary-1 font-bold px-8 py-3 rounded-xl"
                  >
                    자세히 보기
                  </Link>
                </div>
              </Section>
            )}
            {banners
              ?.sort((a, b) => a.rank - b.rank)
              .map((banner) => (
                <>
                  <div
                    className={`w-full bg-center bg-cover bg-no-repeat rounded-xl h-22 md:h-28 px-4 py-1.5 md:py-5 md:px-8 ${
                      !banner.image && 'bg-primary-10'
                    }`}
                    key={banner.id}
                    style={{
                      backgroundImage: isMobile
                        ? banner.mobileImage &&
                          `url(${apiUrl(banner.mobileImage)})`
                        : banner.image && `url(${apiUrl(banner.image)})`,
                    }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex space-x-4 items-center h-18">
                        <div>
                          <H6>{banner.title}</H6>
                          <p className="text-gray-400">{banner.description}</p>
                        </div>
                      </div>
                      {(banner.buttonText || banner.buttonLink) && (
                        <a
                          href={banner.buttonLink}
                          className="bg-white text-primary-1 font-bold px-8 py-3 rounded-xl"
                        >
                          {banner.buttonText}
                        </a>
                      )}
                    </div>
                  </div>
                </>
              ))}
          </Slider>

          <Section className="flex md:flex-row flex-col mt-6 space-y-6 md:space-y-0 md:space-x-6 pb-20">
            {/* 프로필 섹션 */}
            <div className="bg-white shadow-sm md:w-56 rounded-xl p-4 space-y-3 h-full">
              <div className="flex md:flex-col flex-row space-x-4 md:space-x-0 md:space-y-3">
                <div className="wh-16 rounded-xl border-2 border-gray-50 overflow-hidden grid place-content-center">
                  <img
                    src={apiUrl(student?.avatar) || GreenAvatar}
                    alt=""
                    className={`object-cover ${
                      student?.avatar ? 'wh-16 ' : 'h-14 '
                    }`}
                  />
                </div>

                <div className="space-y-2">
                  <div className="outlined-primary-1 font-bold rounded-full px-3 py-1 w-max text-13">
                    {cumulativePoint?.currentTotalPoint
                      ?.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    P
                  </div>
                  <p className="font-medium">{`안녕하세요 ${student?.name}님!`}</p>
                </div>
              </div>

              <div className="py-1">
                <div className="border-t -mx-4 border-gray-50" />
              </div>

              <div>
                <span
                  className={`${
                    student?.nextLevel?.name === 'Level 1'
                      ? 'text-primary-3 bg-secondary-10'
                      : student?.nextLevel?.name === 'Level 2'
                      ? 'text-primary-5 bg-secondary-8'
                      : student?.nextLevel?.name === 'Level 3'
                      ? 'text-primary-6 bg-secondary-1'
                      : student?.nextLevel?.name === 'Level 4'
                      ? 'text-primary-8 bg-secondary-4'
                      : ''
                  } px-3 py-1.5 rounded-full font-bold text-13 text-primary-2 bg-secondary-2`}
                >
                  {course?.isTrial
                    ? '체험'
                    : courseItem?.liveClass?.level?.name}
                </span>
              </div>
              {course && (
                <>
                  <Dropdown>
                    <button className="font-bold flex items-center space-x-2">
                      <span>{classTypeToText(courseType)}</span>
                      <ChevronDownIcon className="wh-4 text-gray-200" />
                    </button>
                    <Dropdown.View className="overflow-y-scroll">
                      <Menu>
                        {classTypeList?.map((courseType: any) => {
                          if (!courseType.isInclude) return;

                          return (
                            <Menu.Item
                              key={courseType.type}
                              text={courseType.name}
                              onClick={() => {
                                setCourseType(courseType.type);
                                var firstCourse = courses?.filter((f) => {
                                  if (courseType.type === ClassType.TRIAL) {
                                    return f.isTrial;
                                  }

                                  return (
                                    !f.isTrial &&
                                    f.startLevel?.classType === courseType.type
                                  );
                                })[0];

                                if (firstCourse) {
                                  courseState.set(firstCourse);
                                  localStorage.setItem(
                                    'courseId',
                                    firstCourse.id.toString()
                                  );
                                }
                              }}
                            />
                          );
                        })}
                      </Menu>
                    </Dropdown.View>
                  </Dropdown>
                  <Dropdown>
                    <button className="font-bold flex items-center space-x-2">
                      <span>{course?.name}</span>
                      <ChevronDownIcon className="wh-4 text-gray-200" />
                    </button>
                    <Dropdown.View className="overflow-y-scroll">
                      <Menu>
                        {courses
                          ?.filter((course) => {
                            // 체험수업일 경우 startLevel이 세팅되어 있지 않아 따로 분류
                            if (courseType === ClassType.TRIAL) {
                              return course.isTrial;
                            }

                            return (
                              !course.isTrial &&
                              course.startLevel?.classType === courseType
                            );
                          })
                          // ?.filter((course) => {
                          //   const now = new Date();
                          //   now.setHours(0, 0, 0, 0);

                          //   return (
                          //     course.endedAt >= now.toISOString() ||
                          //     !course.endedAt
                          //   );
                          // })
                          ?.map((course) => {
                            return (
                              <Menu.Item
                                key={course.id}
                                text={
                                  course.isTrial && course.initialAmount === 1
                                    ? course.name
                                    : `${course.name}  ( ${
                                        course.endedAt
                                          ? `${utcToLocalFormat(
                                              course.endedAt,
                                              MomentFormat.YYYYMMDD
                                            )} 까지`
                                          : '미학습'
                                      } )`
                                }
                                onClick={() => {
                                  courseState.set(course);
                                  localStorage.setItem(
                                    'courseId',
                                    course.id.toString()
                                  );
                                }}
                              />
                            );
                          })}
                      </Menu>
                    </Dropdown.View>
                  </Dropdown>

                  <div className="flex justify-between">
                    <span>
                      {doneLives && doneLives?.length > 0 ? (
                        <>
                          {Math.floor(
                            ((course.initialAmount -
                              (course.initialAmount - doneLives.length)) /
                              course.initialAmount) *
                              100
                          )}
                        </>
                      ) : (
                        '0'
                      )}
                      % 수강중
                    </span>
                    <span className="text-gray-400">
                      {doneLives && doneLives?.length > 0 ? (
                        <>
                          {course.initialAmount -
                            (course.initialAmount - doneLives.length)}
                          /{course.initialAmount}
                        </>
                      ) : (
                        <>
                          0/
                          {course.initialAmount}
                        </>
                      )}
                    </span>
                  </div>

                  <div className="relative">
                    <div
                      className="rounded-full bg-primary-3 absolute h-2"
                      style={{
                        width: ` ${
                          doneLives &&
                          doneLives?.length > 0 &&
                          ((course.initialAmount -
                            (course.initialAmount - doneLives.length)) /
                            course.initialAmount) *
                            100
                        }%`,
                      }}
                    />
                    <div className="rounded-full w-full bg-gray-50 h-2" />
                  </div>

                  {latestCourse && latestLive && (
                    <>
                      <div className="py-1">
                        <div className="border-t -mx-4 border-gray-50" />
                      </div>
                      <div className="space-y-3">
                        <span className="font-semibold text-16">
                          {latestCourse.isTrial &&
                          latestCourse.initialAmount === 1
                            ? '체험 수업'
                            : `${latestCourseItem?.number}회차 수업`}
                        </span>
                        <div className="space-y-1">
                          <p className="text-13 text-gray-500">수업 일시</p>
                          <p className="text-18 font-semibold">
                            {moment(latestLive?.start || '').format(
                              'MMM Do (dd) LT'
                            )}
                          </p>
                        </div>
                        <Button
                          disabled={
                            checkTime > now ||
                            now > end ||
                            !latestLive?.pageCallRoomId
                          }
                          text="강의실 입장"
                          className="filled-primary-1 w-full"
                          onClick={() =>
                            popupState.set({
                              title: '강의실 입장하기',
                              body: `${latestLive?.teacher.englishName} 선생님과의 수업을 시작하시겠습니까?\n입장하기 버튼을 누르면 화상수업 페이지가 열립니다.`,
                              primaryText: '수업 입장하기',
                              primaryClick: () => enterLive(),
                            })
                          }
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>

            <Switch>
              <Route path="/learn/classroom" component={ClassroomPage} />
              <Route path="/learn/course" component={LearnCoursePage} />
              <Route
                path="/learn/lives/:id/report"
                component={LiveReportPage}
              />
              <Route path="/learn/lives" component={LivesPage} />
              <Route path="/learn/reservation" component={ReservationPage} />
              <Redirect from="/" to="/learn/classroom" />
            </Switch>
          </Section>
        </div>
      </div>
    </div>
  );
};
