import React, { useEffect, useState } from 'react';
import { Button } from '../components/Button';
import { H5 } from '../components/H5';
import {
  liveFeedbackPopupState,
  liveState,
  studentState,
} from '../plugins/ridge';
import { range } from '../utils';
import Stars from '../components/Starts';
import { useForm } from 'react-hook-form';
import { api } from '../plugins/axios';
import { useQuery } from 'react-query';
import { Live, LiveReview } from '../types';
import { fetcher } from '../plugins/react-query';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

interface FormValues {
  rate: number;
  liveId: number;
  teacherId: number;
  studentId: number;
}

export const LiveFeedbackPage = () => {
  const live = liveState.useValue();
  const student = studentState.useValue();
  const { search } = useLocation();
  const { liveId } = parse(search, { ignoreQueryPrefix: true });
  const [hoverTotalRating, setHoverTotalRating] = useState(0);

  const { handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      liveId: live?.id,
      teacherId: live?.teacherId,
      studentId: live?.studentId,
    },
  });

  const onMouseEnterRate = (index: number) => setHoverTotalRating(index);
  const onMouseLeaveRate = () => setHoverTotalRating(0);
  const onSaveRating = (index: number) => setValue('rate', index);

  const { data: liveReview, refetch } = useQuery<LiveReview>(
    `/live-reviews/lives/${live?.id}`,
    fetcher,
    {
      enabled: !!live,
    }
  );

  const { data: teacherLive } = useQuery<Live>(`/lives/${liveId}`, fetcher, {
    enabled: !!liveId,
  });

  useEffect(() => {
    if (!liveReview) return;
    setValue('rate', liveReview.rate);
  }, [liveReview, setValue]);

  useEffect(() => {
    if (!live) return;
    liveFeedbackPopupState.set({
      open: true,
      teacherName: live?.teacher.englishName,
      liveId: live?.id,
      teacherId: live?.teacherId,
      studentId: live?.studentId,
    });
  }, [live]);

  return (
    <div className="flex flex-col items-center pt-14 px-4 min-h-screen-10 justify-center">
      <div className="text-center">
        <H5>
          수업시간이 종료되었습니다.
          <br />
          수업 피드백을 남겨주세요.
        </H5>
      </div>

      {student ? (
        <p className="text-gray-600 text-center pt-4 pb-8">
          오늘 {live?.teacher.englishName} 선생님과의 수업은 어땠나요?
        </p>
      ) : (
        <p className="text-gray-600 text-center pt-4 pb-8">
          오늘 내에 반드시 오늘 수업의 피드백을 작성해주세요.
          <br />
          미작성시 패널티가 부과됩니다
        </p>
      )}

      {!liveReview && student ? (
        <>
          <div className="flex mt-8 mb-20">
            {range(5).map((idx) => {
              return (
                <Stars
                  key={idx}
                  width={'w-16'}
                  index={idx}
                  rating={watch('rate')}
                  hoverRating={hoverTotalRating}
                  onMouseEnter={onMouseEnterRate}
                  onMouseLeave={onMouseLeaveRate}
                  onSaveRating={onSaveRating}
                />
              );
            })}
          </div>

          <Button
            text="평가 완료"
            className="filled-primary-1 w-full md:w-1/4"
            disabled={!watch('rate')}
            onClick={handleSubmit((data) => {
              data.teacherId = Number(live?.teacherId);
              data.liveId = Number(live?.id);

              api
                .post('/live-reviews', data)
                .then(() => {
                  alert('피드백 작성이 완료되었습니다.');
                  refetch();
                })
                .catch((err) => console.error(err, '선생님 피드백 생성 오류'));
            })}
          />
        </>
      ) : student ? (
        <div className="flex flex-col space-y-3 mt-10 w-full md:w-1/4">
          <Button
            text="다음 교재 학습하기"
            to="/learn/classroom"
            className="filled-primary-1 font-bold"
          />
          <Button
            text="현재 회차 학습하기"
            to="/learn/classroom"
            className="bg-secondary-1 text-primary-1 font-bold"
          />
        </div>
      ) : (
        <div className="flex flex-col space-y-3 mt-10 w-full md:w-1/4">
          <Button
            text="지금 피드백 작성하기"
            to={`/teacher/students/${teacherLive?.studentId}/lives/${teacherLive?.id}`}
            className="filled-primary-1 font-bold"
          />
          <Button
            text="나중에 작성하기"
            to="/teacher/schedule"
            className="bg-secondary-1 text-primary-1 font-bold"
          />
        </div>
      )}
    </div>
  );
};
