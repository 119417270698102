import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, PayItem, PayItemType } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { Button } from '../../components/Button';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import { AdminSearch } from '../components/AdminSearch';

export const PayItems = () => {
  const [q, setQ] = useState('');
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  const queryString = useQueryString({ filter: getMoment.toISOString() });
  const { data } = useQuery<Paginated<PayItem>>(
    `/admin/pay-items/monthly/penalty${queryString}&q=${q}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: payItems } = useQuery<PayItem[]>(
    `/admin/pay-items/penalty/export${queryString}&q=${q}`,
    fetcher,
    { keepPreviousData: true }
  );

  const exporter = () => {
    const payData = payItems?.map((pay: any) => {
      return {
        강사명: pay.teacher.name,
        계좌정보: pay.teacher.bankAccountNumber,
      };
    });

    jsonExport(payData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(
        `${BOM} ${csv}`,
        `패널티 관리(${today.format('YYYY 년 MM 월')})`
      );
    });
  };

  if (!data) return <></>;
  return (
    <>
      <AdminH1>패널티 관리</AdminH1>

      <div className="w-full text-center my-7 text-2xl font-bold">
        <div>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {'<'}
          </button>
          <span className="mx-7">{today.format('YYYY 년 MM 월')}</span>
          <button
            className="w-7"
            onClick={() => {
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {'>'}
          </button>
        </div>
      </div>

      <Button
        className="bg-white text-blue-500 ml-auto text-14"
        onClick={exporter}
      >
        파일 내보내기
      </Button>

      <AdminSearch
        placeholder="검색"
        value={q}
        onChange={(e) => {
          e.preventDefault();
          setQ(e.target.value);
        }}
      />

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>수업일정</Table.Th>
              <Table.Th>강사명</Table.Th>
              <Table.Th>수업한 학생</Table.Th>
              <Table.Th>패널티 타입</Table.Th>
              <Table.Th>패널티 금액</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items
              .filter((payItem) => payItem.type === PayItemType.PENALTY)
              .map((payItem) => (
                <Table.Row key={payItem.id}>
                  <Table.Td>
                    {utcToLocalFormat(
                      payItem.live.start,
                      MomentFormat.YYYYMMDDHmm
                    )}{' '}
                    ~ {utcToLocalFormat(payItem.live.end, MomentFormat.HHmm)}
                  </Table.Td>
                  <Table.Td>{payItem.teacher.name}</Table.Td>
                  <Table.Td>{payItem.live.student.name}</Table.Td>
                  <Table.Td>{payItem.memo}</Table.Td>
                  <Table.Td>
                    -
                    {payItem.value
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/pay-items" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
