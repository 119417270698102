import React, { FC } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../plugins/axios';
import { LevelTest } from '../plugins/leveltest';
import { selectLevelPopupState, studentState } from '../plugins/ridge';
import { Button } from './Button';
import { XIcon } from './icons';
import { Radio } from './Radio';
import { TextField } from './TextField';

interface FormValues {
  inflowPath: string;
}

export const SelectLevelPopUp = () => {
  const student = studentState.useValue();
  const { open } = selectLevelPopupState.useValue();
  const [level, setLevel] = useState(0);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack px-4 overflow-scroll">
      <div className="flex w-full justify-center items-center min-h-screen">
        <div className="card">
          <div className="flex justify-between items-start">
            <h1 className="font-medium text-20">
              딱 맞는 레벨테스트를 제공해드릴게요.
              <br />
              대략적인 학습 수준을 알려주세요!
            </h1>
            <button
              className="mt-1"
              onClick={() => selectLevelPopupState.reset()}
            >
              <XIcon />
            </button>
          </div>
          <form
            className="space-y-6"
            onSubmit={handleSubmit((data) => {
              api
                .patch(`/students/${student?.id}`, data)
                .then(() => {
                  selectLevelPopupState.reset();
                  const type = level <= 1 ? 'A' : level <= 3 ? 'B' : 'C';
                  LevelTest.openTest(type);
                })
                .catch((e) => console.log(e, '레벨테스트 접근 오류'));
            })}
          >
            <div className="mt-5 mb-10 flex flex-col max-h-72 overflow-y-auto overflow-x-hidden">
              <SelectLevelItem
                title="알파벳부터 시작해야하는 단계입니다."
                description=""
                selected={level === 0}
                onClick={() => setLevel(0)}
              />
              <SelectLevelItem
                title="알파벳을 알고, 단어를 듣고 따라 말할 수 있습니다."
                description=""
                selected={level === 1}
                onClick={() => setLevel(1)}
              />
              <SelectLevelItem
                title="알고 있는 여러 영어 단어들을 활용해, 자신의 의사를 표현할 수 있습니다."
                description=""
                selected={level === 2}
                onClick={() => setLevel(2)}
              />
              <SelectLevelItem
                title="일상 주제에 대해 하나의 완벽한 문장을 말할 수 있습니다."
                description=""
                selected={level === 3}
                onClick={() => setLevel(3)}
              />
              <SelectLevelItem
                title="다양한 주제에 대해 여러 문장을 이어 말할 수 있습니다."
                description=""
                selected={level === 4}
                onClick={() => setLevel(4)}
              />
              <SelectLevelItem
                title="전문 주제에 대한 중&#183;장편의 글을 읽고 내용을 이해할 수 있습니다."
                description=""
                selected={level === 5}
                onClick={() => setLevel(5)}
              />
            </div>

            <TextField
              label="유입경로를 입력해주세요!"
              className="py-5"
              placeholder="ex) 소개, 블로그, SNS 등"
              helper={errors.inflowPath?.message}
              {...register('inflowPath', {
                required: '유입경로를 입력해주세요!',
              })}
            />

            <Button
              text="레벨테스트 응시하기"
              className="filled-primary-1 mt-10 w-full"
              disabled={!watch('inflowPath')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export interface SelectLevelItemProps {
  title: string;
  description: string;
  selected: boolean;
  onClick: () => void;
}

export const SelectLevelItem: FC<SelectLevelItemProps> = ({
  title,
  description,
  selected,
  onClick,
}) => {
  return (
    <div
      className={`-mx-8 px-8 py-2 flex items-start space-x-2 ${
        selected ? 'bg-brand-2' : ''
      }`}
      onClick={onClick}
    >
      <Radio checked={selected} className="mt-1" />
      <div>
        <p className={`text-17 ${selected ? 'font-bold' : ''}`}>{title}</p>
        <p className="text-14 text-gray-600">{description}</p>
      </div>
    </div>
  );
};
