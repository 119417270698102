import { Course, Lesson, LessonResult } from '../types';

export function lessonToCorners(
  lesson: Lesson,
  course?: Course,
  lessonResult?: LessonResult,
  bluegaVoices?: any
) {
  const corners = [];
  if (lesson.story)
    corners.push({
      ActiveID: '2',
      url: lesson.story,
      done: lessonResult?.storyDone,
    });
  if (lesson.storyQuiz)
    corners.push({
      ActiveID: '3',
      url: lesson.storyQuiz,
      done: lessonResult?.storyQuizDone,
      QuizResult: lessonResult?.storyQuizResult,
      QuizScore: lessonResult?.storyQuizScore,
    });
  if (lesson.learn)
    corners.push({
      ActiveID: '4',
      url: lesson.learn,
      done: lessonResult?.learnDone,
    });
  if (lesson.learnQuiz)
    corners.push({
      ActiveID: '5',
      url: lesson.learnQuiz,
      done: lessonResult?.learnQuizDone,
      QuizResult: lessonResult?.learnQuizResult,
      QuizScore: lessonResult?.learnQuizScore,
    });
  if (lesson.words)
    corners.push({
      ActiveID: '6',
      url: lesson.words,
      done: lessonResult?.wordsDone,
    });
  if (lesson.wordQuiz)
    corners.push({
      ActiveID: '7',
      url: lesson.wordQuiz,
      done: lessonResult?.wordQuizDone,
      QuizResult: lessonResult?.wordQuizResult,
      QuizScore: lessonResult?.wordQuizScore,
    });
  if (lesson.workbook)
    if (course?.bookIncluded) {
      corners.push({
        ActiveID: '8',
        url: lesson.workbook,
        done: lessonResult?.workbookDone,
      });
    }
  if (lesson.talk)
    corners.push({
      ActiveID: '9',
      url: lesson.talk,
      done: lessonResult?.talkDone,
      TalkPlusResult: bluegaVoices,
    });
  return corners;
}

export function convertLessonResult(data: any) {
  const result: any = {
    studentId: data.studentId,
    lessonId: data.lessonId,
    createdAt: new Date(),
  };
  if (data.ActiveID === '2') {
    result.storyDone = data.done;
  }
  if (data.ActiveID === '3') {
    result.storyQuizDone = data.done;
    result.storyQuizQuestion = data.nQuestion;
    result.storyQuizCorrect = data.nCorrect;
    result.storyQuizResult = data.QuizResult;
    result.storyQuizScore = data.QuizScore;
  }
  if (data.ActiveID === '4') {
    result.learnDone = data.done;
  }
  if (data.ActiveID === '5') {
    result.learnQuizDone = data.done;
    result.learnQuizQuestion = data.nQuestion;
    result.learnQuizCorrect = data.nCorrect;
    result.learnQuizResult = data.QuizResult;
    result.learnQuizScore = data.QuizScore;
  }
  if (data.ActiveID === '6') {
    result.wordsDone = data.done;
  }
  if (data.ActiveID === '7') {
    result.wordQuizDone = data.done;
    result.wordQuizQuestion = data.nQuestion;
    result.wordQuizCorrect = data.nCorrect;
    result.wordQuizResult = data.QuizResult;
    result.wordQuizScore = data.QuizScore;
  }
  if (data.ActiveID === '8') {
    result.workbookDone = data.done;
  }
  if (data.ActiveID === '9') {
    result.talkDone = data.done;
  }
  return result;
}

export function convertLessonDoneResult(data: any, isCompleted: boolean) {
  const result: any = {
    studentId: data.studentId,
    lessonId: data.lessonId,
    createdAt: new Date(),
    isCompleted: isCompleted,
  };
  data.corners.map((data: any) => {
    if (data.ActiveID === '2') {
      result.storyDone = data.done;
    }
    if (data.ActiveID === '3') {
      result.storyQuizDone = data.done;
      result.storyQuizQuestion = data.nQuestion;
      result.storyQuizCorrect = data.nCorrect;
      result.storyQuizResult = data.QuizResult;
      result.storyQuizScore = data.QuizScore;
    }
    if (data.ActiveID === '4') {
      result.learnDone = data.done;
    }
    if (data.ActiveID === '5') {
      result.learnQuizDone = data.done;
      result.learnQuizQuestion = data.nQuestion;
      result.learnQuizCorrect = data.nCorrect;
      result.learnQuizResult = data.QuizResult;
      result.learnQuizScore = data.QuizScore;
    }
    if (data.ActiveID === '6') {
      result.wordsDone = data.done;
    }
    if (data.ActiveID === '7') {
      result.wordQuizDone = data.done;
      result.wordQuizQuestion = data.nQuestion;
      result.wordQuizCorrect = data.nCorrect;
      result.wordQuizResult = data.QuizResult;
      result.wordQuizScore = data.QuizScore;
    }
    if (data.ActiveID === '8') {
      result.workbookDone = data.done;
    }
    if (data.ActiveID === '9') {
      result.talkDone = data.done;
    }
    return result;
  });
  return result;
}
