import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { EventBoard, Paginated } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const EventBoards = () => {
  const queryString = useQueryString({ join: ['category'] });
  const { data } = useQuery<Paginated<EventBoard>>(
    `/admin/event-boards${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>이벤트 관리</AdminH1>
        <Button
          text="이벤트 추가"
          to="/admin/events/add"
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>타이틀</Table.Th>
              <Table.Th>시작일자</Table.Th>
              <Table.Th>종료일자</Table.Th>
              <Table.Th>카테고리명</Table.Th>
              <Table.Th>숨김여부</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items.map((event) => (
              <Table.Row key={event.id}>
                <Table.Td>{event.title}</Table.Td>
                <Table.Td>{event.startedAt}</Table.Td>
                <Table.Td>{event.endedAt}</Table.Td>
                <Table.Td>{event.category?.title}</Table.Td>
                <Table.Td>{event.isHide ? 'O' : 'X'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/events/${event.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data?.items.length || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav basePath="/admin/events" total={data?.total || 0} />
        </Pagination>
      </AdminCard>
    </>
  );
};
