import { useEffect } from 'react';
export const NewLocationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="new-terms-wrap">
        <p className="hv-txt">위치정보 이용약관</p>
        <p className="nl-txt">제 1 조 (목적)</p>
        이 약관은 튼튼영어 주식회사 (이하 “회사”)가 제공하는 위치정보사업 또는
        위치기반서비스사업과 관련하여 회사와 개인위치정보주체와의 권리, 의무 및
        책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        <br />
        <p className="nl-txt">제 2 조 (약관 외 준칙)</p>
        이 약관에 명시되지 않은 사항은 위치정보의 보호 및 이용 등에 관한 법률,
        정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전기통신기본법,
        전기통신사업법 등 관계법령과 회사의 이용약관 및 개인정보취급방침, 회사가
        별도로 정한 지침 등에 의합니다.
        <br />
        <p className="nl-txt">제 3 조 (서비스 내용 및 요금)</p>
        회사가 제공하는 위치기반 서비스는 아래와 같습니다.
        <br />
        * 서비스 명칭: 가까운 튼튼찾기
        <br />
        * 서비스 내용: 현재의 위치를 기반으로 하여 주변 튼튼영어 지사 위치 등의
        정보를 제공하는 서비스는
        <br />
        * 요금:
        <br />
        - 서비스의 이용 : 무료
        <br />
        - 서비스의 이용을 위한 데이터 통신 비용 : 이동통신사 및 회원의 가입
        요금제에 따름
        <br />
        <p className="nl-txt">제 4 조 (개인위치정보주체의 권리)</p>
        1. 개인위치정보주체는 개인위치정보 수집 범위 및 이용약관의 내용 중 일부
        또는 개인위치정보의 이용ㆍ제공 목적, 제공받는 자의 범위 및
        위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
        <br />
        <br />
        2. 개인위치정보주체는 회사에 대하여 언제든지 개인위치정보의 수집에 대한
        동의 또는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의
        제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우
        회사는 수집한 개인위치정보 및 위치정보 수집,이용, 제공사실
        확인자료(동의의 일부를 철회한 경우에는 철회한 부분의 개인위치정보 및
        위치정보 이용,제공사실 확인자료에 한함)를 파기합니다.
        <br />
        <br />
        3. 개인위치정보주체는 회사에 대하여 언제든지 개인위치정보의 수집, 이용
        또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고
        이를 위한 기술적 수단을 갖추고 있습니다.
        <br />
        가. 개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실 확인자료
        <br />
        나. 개인위치정보주체의 개인위치정보가 위치정보의 보호 및 이용 등에 관한
        법률 또는 다른 법령의 규정에 의하여 제3자에게 제공된 이유 및 내용
        <br />
        <br />
        4. 개인위치정보주체는 회사에 대하여 아래 자료의 열람 또는 고지를 요구할
        수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다.
        이 경우 회사는 정당한 이유 없이 요구를 거절하지 아니합니다.
        <br />
        <br />
        5. 개인위치정보주체는 제1항 내지 제4항의 권리행사를 고객센터(전화
        02-3015-1100 또는 “웹사이트 → 고객 서비스 → 고객의 소리 → 문의하기”)를
        통해 회사에 요구할 수 있습니다.
        <br />
        <p className="nl-txt">제 5 조 (위치정보의 보유기간)</p>
        회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여
        개인위치정보주체에 대한 위치정보 수집ㆍ이용ㆍ제공사실
        확인자료(위치정보를 제공받는 자, 취득경로, 이용, 제공일시 및 방법에 관한
        자료)를 위치정보시스템에 자동으로 기록하며, 1년 이상 보관합니다.
        <br />
        <p className="nl-txt">제 6 조 (적용제외)</p>
        만 14세 미만인자는 회원 가입대상이 아니므로, 회사는 회원가입이 불가능한
        만14세 미만 자의 위치정보를 수집하지 않습니다. 따라서 만14세 미만의
        이용자에 대해서는 위치기반서비스와 관련한 민원 발생 등에 대해서는 책임을
        지지 않습니다.
        <br />
        <p className="nl-txt">제 7 조 (서비스의 변경 및 중지)</p>
        1. 회사는 위치정보사업자의 정책변경 등과 같이 회사의 제반 사정 또는
        법률상의 장애 등으로 서비스를 유지할 수 없는 경우, 서비스의 전부 또는
        일부를 제한, 변경하거나 중지할 수 있습니다.
        <br />
        <br />
        2. 제1항에 의한 서비스 중단의 경우에는 회사는 사전에 인터넷 등에
        공지하거나 개인위치정보주체에게 통지합니다.
        <br />
        <p className="nl-txt">제 8 조 (개인위치정보 제3자 제공 및 제한)</p>
        1. 회사는 회원의 동의 없이 회원의 개인위치정보를 제3자에게 제공하지
        아니하며, 제3자 제공 서비스를 제공하는 경우에는 제공 받는 자 및
        제공목적을 사전에 회원에게 고지합니다.
        <br />
        <br />
        2. 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는
        개인위치정보를 수집한 당해 통신단말장치로 매회 회원에게 제공받는 자,
        제공일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호에 해당하는
        경우에는 회원이 미리 특정하여 지정한 통신단말장치 또는 전자우편주소로
        통보합니다.
        <br />
        가. 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의
        수신기능을 갖추지 아니한 경우 나. 회원이 개인위치정보를 수집한 당해
        통신단말장치 외의 통신단말장치 또는 전자우편주소로 통보할 것을 미리
        요청한 경우
        <br />
        <br />
        3. 개인위치정보 등의 이용제공의 제한 회사는 회원의 동의가 있거나 아래 각
        호에 해당하는 경우를 제외하고는 개인위치정보 또는 위치정보 수집,이용,
        제공사실 확인자료를 본 약관에 명시하거나 별도 고지한 범위를 넘어
        이용하거나 제3자에게 제공하지 않습니다.
        <br />
        가. 위치기반서비스 제공에 따른 요금정산 위하여 위치정보 수집, 이용,
        제공사실 확인자료가 필요한 경우
        <br />
        나. 통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는
        형태로 가공하여 제공하는 경우
        <br />
        <p className="nl-txt">제 9 조 (손해배상)</p>
        개인위치정보주체는 회사의 위치정보의 보호 및 이용 등에 관한 법률 제15조
        내지 26조의 규정을 위반한 행위로 손해를 입은 경우에 회사에 대하여
        손해배상을 청구할 수 있습니다. 이 경우 회사는 고의 또는 과실이 없음을
        입증하지 아니하면 책임을 면할 수 없습니다.
        <br />
        <p className="nl-txt">제 10 조 (분쟁의 조정)</p>
        1. 회사는 위치정보와 관련된 분쟁에 대하여 개인위치정보주체와 협의가
        이루어지지 아니하거나 협의를 할 수 없는 경우에는 방송통신위원회에 재정을
        신청할 수 있습니다.
        <br />
        <br />
        2. 회사 또는 개인위치정보주체는 위치정보와 관련된 분쟁에 대해 당사자간
        협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법에
        따라 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.
        <br />
        <p className="nl-txt">제 11 조 (사업자 정보)</p>
        회사의 상호, 주소, 전화번호 그 밖의 연락처는 다음과 같습니다.
        <br />
        상호 : ㈜튼튼영어
        <br />
        주소 : 서울특별시 강남구 논현로 164 튼튼영어빌딩
        <br />
        전화번호 : 02-1577-0582
        <br />
        사업자정보 : 241-88-01632
        <br />
        <br />
        제 1 조 시행일
        <br />
        본 약관은 2022년 4월 11일부터 적용됩니다.
        <br />
        <br />
        제 2 조 위치정보관리 책임자 정보
        <br />
        회사는 다음과 같이 위치정보 관리책임자를 지정하여 이용자들이 서비스
        이용과정에서 발생한 민원사항 처리를 비롯하여 개인위치정보주체의 권리
        보호를 위해 힘쓰고 있습니다.
        <br />
        이름 : 임재현
        <br />
        소속/직책 : 경영지원본부 IT실/이사
        <br />
        전화 : 1577-0582
        <br />
        E-mail : jhlim@tuntun.co.kr
        <br />
      </div>
    </>
  );
};
