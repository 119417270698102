import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Pay, User } from '../../types';
import { MobileHelper } from './MobileHelper';

export const TransactionsPage = () => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const queryString = useQueryString({ filter: year });
  const { data } = useQuery<Paginated<Pay>>(
    `/pays/monthly${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: me } = useQuery<User>(`/users/me`, fetcher);

  const maxOffset = 2;
  const thisYear = new Date().getFullYear();
  const allYears = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((y) => {
    return (
      <>
        <option key={y} value={y}>
          {y}년
        </option>
      </>
    );
  });

  const yearTotal =
    data?.items &&
    data?.items.length > 0 &&
    data?.items.map((pay) => pay.total).reduce((a, b) => a + b);

  if (!data || !me) return <></>;
  return (
    <div>
      <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden p-5 my-5 hidden md:block">
        <div className="flex justify-between">
          <H5>{me.name}님 정산 내역</H5>
          <div className="text-primary-8 text-sm">
            <p>* 정산값은 상황에 따라 변동될 수 있습니다.</p>
            <p>*패널티와 장려금은 다음달에 반영될 수 있습니다.</p>
          </div>
        </div>

        <div className="flex justify-between my-5">
          <Select
            className="w-28 h-12"
            onChange={(e) => setYear(Number(e.target.value))}
          >
            {yearList}
          </Select>
          <div className="h-12 filled-gray-100 text-black flex items-center justify-center px-5 rounded-md">
            총 정산 비용{' '}
            {yearTotal
              ? `${yearTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              : 0}
            원
          </div>
        </div>

        <div className="border rounded-md overflow-hidden border-gray-100">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>월</Table.Th>
                <Table.Th>강사명</Table.Th>
                <Table.Th>영어이름</Table.Th>
                <Table.Th>계좌정보</Table.Th>
                <Table.Th>총 정산비용</Table.Th>
                <Table.Th>기본 정산(횟수)</Table.Th>
                <Table.Th>장려금</Table.Th>
                <Table.Th>패널티</Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {data.items
                .sort((a, b) => b.id - a.id)
                .map((pay) => (
                  <Table.Row key={pay.id}>
                    <Table.Td>
                      {utcToLocalFormat(pay.createdAt, MomentFormat.MM)}월
                    </Table.Td>
                    <Table.Td>{pay.teacher.name}</Table.Td>
                    <Table.Td>{pay.teacher.englishName}</Table.Td>
                    <Table.Td>
                      <span>{pay.teacher.bankAccountNumber}</span>
                    </Table.Td>
                    <Table.Td>
                      {pay.total && pay.liveSettlement < pay.penalty
                        ? `${pay.total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                        : pay.total
                        ? pay.total
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0}
                    </Table.Td>
                    <Table.Td>
                      {pay.liveSettlement
                        ? pay.liveSettlement
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : '-'}{' '}
                      ({pay.liveSettlementCount}회)
                    </Table.Td>
                    <Table.Td>
                      {pay.incentive
                        ? pay.incentive
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : 0}
                    </Table.Td>
                    <Table.Td>
                      {pay.penalty
                        ? `-${pay.penalty
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                        : 0}
                    </Table.Td>

                    <Table.Td className="text-right">
                      <Button
                        className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                        to={`/teacher/mypage/transactions/${pay.id}`}
                      >
                        상세보기
                      </Button>
                    </Table.Td>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          <Pagination>
            <Pagination.Label count={data.items.length} total={data.total} />
            <Pagination.Nav
              basePath={`/teacher/mypage/transactions`}
              total={data.total}
            />
          </Pagination>
        </div>
      </div>
      <div className="block md:hidden w-full">
        <MobileHelper />
      </div>
    </div>
  );
};
