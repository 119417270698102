import React from 'react';
import { ReactComponent as SuccessSVG } from '../../assets/svg/success.svg';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';

export const PasswordResetSuccessPage = () => {
  return (
    <div className="flex flex-col items-center pt-14 px-4 min-h-screen-10 justify-center">
      <SuccessSVG />

      <H5>비밀번호 변경이 완료되었습니다!</H5>
      <p className="text-gray-600 text-center pt-4 pb-8">
        새로운 비밀번호로 로그인해주세요.
      </p>

      <Button
        to="/login"
        text="로그인하기"
        className="filled-primary-1 w-full md:w-1/3"
      />
    </div>
  );
};
