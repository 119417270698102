import React, { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Document, Page, pdfjs } from 'react-pdf';
import { useHistory, useParams } from 'react-router-dom';
import { fetcher } from '../../plugins/react-query';
import { api, apiUrl } from '../../plugins/axios';
import SignaturePad from 'signature_pad';
import { Checkbox } from '../../components/Checkbox';
import { TextField } from '../../components/TextField';
import { Button } from '../../components/Button';
import { Section } from '../../components/Section';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';
import { PDFDocument } from 'pdf-lib';
import { popupState } from '../../plugins/ridge';
import { range } from '../../utils';

pdfjs.GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js';

let sigPad: any = null;

export const ContractDetailPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { data: contract, refetch } = useQuery<any>(
    `/contracts/${id}`,
    fetcher
  );

  const [pageNumber, setNumPages] = useState(0);
  const [sigPadData, setSigPadData] = useState<any>();
  const [modalOpen, setModalOpen] = useState(false);
  const [agree, setAgree] = useState(false);
  const [fileData, setFileData] = useState<any>();
  // const [, setFileData] = useState<any>();
  const [canvasEventAdded, setCanvasEventAdded] = useState(false);
  // const [signedPdfs, setSignedPdfs] = useState<any>([]);
  // const [pdfs, setPdfs] = useState<any>([]);

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  const pdfFile = useMemo(() => ({ data: fileData }), [fileData]);

  const addCanvasEventListener = () => {
    const canvas = document.querySelector('canvas');
    if (!canvas) return () => {};
    sigPad = new SignaturePad(canvas);
    sigPad.addEventListener('endStroke', () =>
      setSigPadData(sigPad.toDataURL('image/png'))
    );
    setCanvasEventAdded(true);
  };

  useEffect(() => {
    const pdfUrl = contract
      ? contract?.isSigned
        ? apiUrl(contract?.signedPdf)
        : apiUrl(contract?.pdf)
      : '';
    if (pdfUrl) {
      fetch(pdfUrl)
        .then((res) => res.arrayBuffer())
        .then((fileData) => setFileData(fileData));
    }
  }, [contract]);

  useEffect(() => addCanvasEventListener(), []);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const clearSignature = () => {
    sigPad?.clear();
    setSigPadData('');
  };

  const createNewPdf = async () => {
    const pdfDoc = await PDFDocument.load(fileData);

    const pages = pdfDoc.getPages();
    const formData = new FormData();

    const promises = pages.map(async (p) => {
      const firstPage = p;
      const { width } = firstPage.getSize();
      const sigPadImage = await pdfDoc.embedPng(sigPadData);

      firstPage.drawImage(sigPadImage, {
        x: width - 300,
        y: 50,
        width: 75,
        height: 50,
      });
      // formData.append('teacherSignature', sigPadData);
    });

    const pdfBytes = await pdfDoc.save();
    setFileData(pdfBytes);
    const fileBlobData = new Blob([pdfBytes], { type: 'application/pdf' });
    formData.append('file', fileBlobData);

    await Promise.all(promises);

    // const pdfUrls = JSON.parse(contract?.pdf);
    // const promises = pdfUrls.map(async (pdfUrl: any) => {
    //   const ios = await fetch(contract && apiUrl(pdfUrl)).then((res) =>
    //     res.arrayBuffer()
    //   );
    //   const pdfDoc = await PDFDocument.load(ios);
    //   const pages = pdfDoc.getPages();

    //   const promises = pages.map(async (p) => {
    //     const firstPage = p;
    //     const { width } = firstPage.getSize();
    //     const sigPadImage = await pdfDoc.embedPng(sigPadData);

    //     firstPage.drawImage(sigPadImage, {
    //       x: width - 300,
    //       y: 50,
    //       width: 75,
    //       height: 50,
    //     });
    //   });
    //   await Promise.all(promises);

    //   const pdfBytes = await pdfDoc.save();
    //   setFileData(pdfBytes);

    //   formData.append(
    //     'signedPdf',
    //     new Blob([pdfBytes], { type: 'application/pdf' })
    //   );
    // });

    // await Promise.all(promises);

    await api.post<any>(`/contracts/signature?id=${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    popupState.set({
      title: '서명 완료',
      body: '서명이 완료되었습니다.',
      primaryText: '계약서 보기',
    });
  };

  // useEffect(() => {
  //   if (!contract) {
  //     return;
  //   }

  //   setPdfs(JSON.parse(contract.pdf || '[]'));
  //   setSignedPdfs(JSON.parse(contract.signedPdf || '[]'));
  // }, [contract]);

  // const isContractSigned = contract?.isSigned;

  return (
    <>
      <div className="w-full max-w-5xl mx-auto my-5 px-6 space-y-6">
        <div
          className="font-bold text-lg mb-5 cursor-pointer"
          onClick={() => push(`/teacher/mypage/contracts`)}
        >
          &#60; 목록보기
        </div>

        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {range(pageNumber).map((_, i) => (
            <Page pageNumber={i + 1} key={i} className="mb-2" />
          ))}
        </Document>
        {/* {isContractSigned
          ? signedPdfs.map((f: any) => (
              <Document
                file={f}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {range(pageNumber).map((_, i) => (
                  <Page pageNumber={i + 1} key={i} className="mb-2" />
                ))}
              </Document>
            ))
          : pdfs.map((f: any) => (
              <Document
                file={f}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {range(pageNumber).map((_, i) => (
                  <Page pageNumber={i + 1} key={i} className="mb-2" />
                ))}
              </Document>
            ))} */}

        {!contract?.isSigned && (
          <div className="fixed bottom-4 left-0 right-0">
            <div className="w-min mx-auto flex space-x-4 items-center justify-between p-1">
              <div
                className="rounded-full bg-blue-700 text-white py-3 px-5 min-w-max cursor-pointer"
                onClick={() => {
                  setModalOpen(true);
                  if (!canvasEventAdded) {
                    addCanvasEventListener();
                  }
                }}
              >
                ✍🏻 서명하기
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`fixed inset-0 w-full h-screen z-60 bg-littleblack flex items-center justify-center ${
          !modalOpen && 'hidden'
        }`}
      >
        <div
          className={`w-80 bg-white opacity-100 rounded-lg relative pt-7 px-5 pb-3`}
        >
          <div
            className="absolute top-3 right-3"
            onClick={() => {
              clearSignature();
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </div>
          <Section className="flex flex-col space-y-4">
            <div>
              <div className="text-gray-700 text-xl font-bold">서명란</div>
              <div className="text-gray-500">
                아래 네모칸에 서명을 해주세요.
              </div>
            </div>
            <div className="relative">
              <canvas
                width={300}
                height={200}
                style={{
                  borderRadius: '30px',
                  background: '#F2F2F2',
                  width: 'calc(100% - 20px)',
                  maxWidth: '400px',
                  margin: 'auto',
                }}
              />
            </div>
            <Checkbox
              label="[필수] 아래 내용에 동의하십니까?"
              onChange={() => setAgree(!agree)}
            />
            <TextField value="* 계약서에 동의합니다." disabled />

            <div className="flex items-center justify-center space-x-2">
              <Button
                className="bg-blue-700 text-white w-full"
                onClick={async () => {
                  if (!agree) {
                    alert('약관에 동의해 주세요.');
                  } else if (!sigPadData) {
                    alert('사인 후 승인해 주세요.');
                  } else {
                    await createNewPdf();
                    await setModalOpen(false);
                    await refetch();
                  }
                }}
              >
                서명 제출하기
              </Button>
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};
