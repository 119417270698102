import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks';
import PcImg from '../assets/img/free_e_pc.png';
import mobileImg from '../assets/img/free_e_m.png';
import { isMobile } from 'react-device-detect';
import { courseState, studentState } from '../plugins/ridge';
import { useQuery } from 'react-query';
import { Course, Role, User } from '../types';
import { fetcher } from '../plugins/react-query';

export const QuickMenu = () => {
  const { authenticated } = useAuth();
  const { pathname } = useLocation();
  const student = studentState.useValue();

  const { data: myCourses } = useQuery<Course[]>(`/courses/my`, fetcher, {
    enabled: !!student,
  });
  const { data: me } = useQuery<User>('/users/me', fetcher);

  const trialCourse = myCourses?.find(
    (course) =>
      course.isTrial &&
      course.initialAmount === 1 &&
      course.studentId === student?.id
  );
  return (
    <>
      {/* {pathname === '/pagecall-content' ? (
        ''
      ) : (
        <div id="q-menu">
          <Link
            to={authenticated ? '/pricing/trial' : '/signup'}
            target="_blank"
            // style={{
            //   backgroundImage: `url('${isMobile ? mobileImg : PcImg}')`,
            // }}
            className="q-img"
          ></Link>
        </div>
      )} */}
      {/* {!trialCourse &&
      me?.role !== Role.TEACHER &&
      pathname !== '/pagecall-content' ? (
        <div id="b_q-menu">
          <Link
            to="/signup"
            style={{
              backgroundImage: `url('${isMobile ? mobileImg : PcImg}')`,
            }}
            className="b_q-img"
          ></Link>
        </div>
      ) : (
        ''
      )} */}
    </>
  );
};
