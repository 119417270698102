import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { ScheduleCalendar } from '../../components/ScheduleCalendar';
import { Table } from '../../components/Table';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Live, LiveState, Paginated, Payment, PaymentState } from '../../types';
import { AdminCard } from '../components/AdminCard';

export const Dashboard = () => {
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [getMoment] = useState(moment());
  const queryString = useQueryString({
    filter: { createdAt: getMoment.toISOString() },
    limit: '10000',
  });

  const formatFrom = from && moment(from).startOf('M').add(9, 'h');
  const formatTo = to && moment(to).endOf('M').add(9, 'h');
  const nextFrom = from && moment(from).add(1, 'M').startOf('M').add(9, 'h');
  const nextTo = to && moment(to).add(1, 'M').endOf('M').add(9, 'h');

  // const { data: monthLives } = useQuery<Paginated<Live>>(
  //   `/admin/lives?filter[state][0]=${LiveState.ABSENT}&filter[state][1]=${
  //     LiveState.ABSENT_AND_NO_SHOW
  //   }&filter[state][2]=${LiveState.ATTENDED}&filter[state][3]=${
  //     LiveState.CANCELED
  //   }&filter[state][4]=${LiveState.NO_SHOW}&filter[state][5]=${
  //     LiveState.RESERVED
  //   }&filter[state][6]=${LiveState.AVAILABLE}&filter[state][7]=${
  //     LiveState.CANCELLATION_THE_DAY
  //   }&filter[start][from]=${formatFrom?.toISOString()}&filter[start][to]=${formatTo?.toISOString()}&limit=100000&join[0]=student&join[1]=course&join[2]=liveFeedbacks&join[3]=teacher`,
  //   fetcher,
  //   { keepPreviousData: true, enabled: !!formatFrom && !!formatTo }
  // );
  const { data: monthLives } = useQuery<Live[]>(
    `/admin/lives/dashboard?from=${formatFrom?.toISOString()}&to=${formatTo?.toISOString()}`,
    fetcher,
    { keepPreviousData: true, enabled: !!formatFrom && !!formatTo }
  );

  // const { data: nextLives } = useQuery<Paginated<Live>>(
  //   `/admin/lives?${LiveState.ABSENT}&filter[state][1]=${
  //     LiveState.ABSENT_AND_NO_SHOW
  //   }&filter[state][2]=${LiveState.ATTENDED}&filter[state][3]=${
  //     LiveState.CANCELED
  //   }&filter[state][4]=${LiveState.NO_SHOW}&filter[state][5]=${
  //     LiveState.RESERVED
  //   }&filter[state][6]=${LiveState.AVAILABLE}&filter[state][7]=${
  //     LiveState.CANCELLATION_THE_DAY
  //   }&filter[start][from]=${nextFrom?.toISOString()}&filter[start][to]=${nextTo?.toISOString()}&limit=100000&join[0]=student&join[1]=course&join[2]=liveFeedbacks`,
  //   fetcher,
  //   { keepPreviousData: true, enabled: !!nextFrom && !!nextTo }
  // );
  const { data: nextLives } = useQuery<Live[]>(
    `/admin/lives/dashboard?from=${nextFrom?.toISOString()}&to=${nextTo?.toISOString()}`,
    fetcher,
    { keepPreviousData: true, enabled: !!nextFrom && !!nextTo }
  );

  const { data: monthPayments } = useQuery<Paginated<Payment>>(
    `/admin/payments?filter[createdAt][from]=${formatFrom?.toISOString()}&filter[createdAt][to]=${formatTo?.toISOString()}&limit=100000`,
    fetcher,
    { keepPreviousData: true, enabled: !!formatFrom && !!formatTo }
  );

  const mPayments = monthPayments?.items
    .filter(
      (p) =>
        p.paymentState === PaymentState.PAID ||
        p.paymentState === PaymentState.WAITING_FOR_DELIVERY ||
        p.paymentState === PaymentState.WAITING_FOR_CONSULTATION ||
        p.paymentState === PaymentState.LEAVING ||
        p.paymentState === PaymentState.IN_TRANSIT ||
        p.paymentState === PaymentState.DELIVERED
    )
    .map((p) => Number(p.paidAmount));

  let sum = 0;
  if (mPayments && mPayments?.length > 0) {
    sum = mPayments?.reduce((a, b) => a + b);
  }

  const { data: todayPayments } = useQuery<Paginated<Payment>>(
    `/admin/payments/new/lists${queryString}`,
    fetcher
  );

  const { data: monthlyPayments } = useQuery<Paginated<Payment>>(
    `/admin/payments/monthly/lists${queryString}`,
    fetcher
  );

  const totalPaymentList = todayPayments?.items?.filter(
    (payment) => !payment.reinforceId
  );
  const trialAmount = todayPayments?.items.filter(
    (p) =>
      (!p.productAmount || p.productAmount === 1) &&
      !p.reinforceId &&
      !p.isTrialPackage
  );

  const trialPackageAmount = todayPayments?.items.filter(
    (p) => !p.reinforceId && p.isTrialPackage
  );

  const sixAmount = todayPayments?.items.filter(
    (p) => p.productAmount === 6 && !p.reinforceId && !p.isTrialPackage
  );
  const twelveAmount = todayPayments?.items.filter(
    (p) => p.productAmount === 12 && !p.reinforceId
  );
  const twentyFourAmount = todayPayments?.items.filter(
    (p) => p.productAmount === 24 && !p.reinforceId
  );
  const fortyEightAmount = todayPayments?.items.filter(
    (p) => p.productAmount === 48 && !p.reinforceId
  );
  const ninetySixAmount = todayPayments?.items.filter(
    (p) => p.productAmount === 96 && !p.reinforceId
  );

  const monthlyPaymentList = monthlyPayments?.items?.filter(
    (payment) => !payment.reinforceId
  );
  const monthlyTrialAmount = monthlyPayments?.items.filter(
    (p) =>
      (!p.productAmount || p.productAmount === 1) &&
      !p.reinforceId &&
      !p.isTrialPackage
  );
  const monthlyTrialPackageAmount = monthlyPayments?.items.filter(
    (p) => !p.reinforceId && p.isTrialPackage
  );
  const monthlySixAmount = monthlyPayments?.items.filter(
    (p) => p.productAmount === 6 && !p.reinforceId && !p.isTrialPackage
  );
  const monthlyTwelveAmount = monthlyPayments?.items.filter(
    (p) => p.productAmount === 12 && !p.reinforceId
  );
  const monthlyTwentyFourAmount = monthlyPayments?.items.filter(
    (p) => p.productAmount === 24 && !p.reinforceId
  );
  const monthlyFortyEightAmount = monthlyPayments?.items.filter(
    (p) => p.productAmount === 48 && !p.reinforceId
  );
  const monthlyNinetySixAmount = monthlyPayments?.items.filter(
    (p) => p.productAmount === 96 && !p.reinforceId
  );

  const tPayments = todayPayments?.items.map((p) => Number(p.paidAmount));
  let tSum = 0;
  if (tPayments && tPayments?.length > 0) {
    tSum = tPayments?.reduce((a, b) => a + b);
  }

  const thisLives = monthLives?.filter(
    (live) =>
      live.state === LiveState.RESERVED || live.state === LiveState.ATTENDED
  );

  let thisLiveCount = 0;
  if (thisLives && monthLives) {
    const count =
      (thisLives?.length /
        monthLives?.filter(
          (live) =>
            live.state === LiveState.AVAILABLE ||
            live.state === LiveState.RESERVED ||
            live.state === LiveState.ATTENDED ||
            live.state === LiveState.ABSENT ||
            live.state === LiveState.NO_SHOW ||
            live.state === LiveState.ABSENT_AND_NO_SHOW
        ).length) *
      100;
    thisLiveCount = count;
  }

  const nextMonthDoneLiveCount = nextLives?.filter(
    (live) =>
      live.state === LiveState.ATTENDED || live.state === LiveState.RESERVED
  );

  let nextMonthCount = 0;
  if (nextMonthDoneLiveCount && nextLives) {
    const nextCount =
      (nextMonthDoneLiveCount.length /
        nextLives?.filter(
          (live) =>
            live.state === LiveState.AVAILABLE ||
            live.state === LiveState.RESERVED ||
            live.state === LiveState.ATTENDED ||
            live.state === LiveState.ABSENT ||
            live.state === LiveState.NO_SHOW ||
            live.state === LiveState.ABSENT_AND_NO_SHOW
        ).length) *
      100;
    nextMonthCount = nextCount;
  }

  return (
    <div className="w-full mx-0 p-0 mb-10">
      <ScheduleCalendar
        lives={monthLives?.filter((l) => l.studentId)}
        onChangeMonth={(f, t) => {
          setFrom(f);
          setTo(t);
        }}
        selected={false}
      />
      <div className="flex flex-col space-y-10  max-w-5xl mx-auto">
        <div className="flex space-x-20">
          <div className="space-y-4 w-full">
            <div className="space-x-3">
              <span className="font-bold text-18">오늘의 누적매출</span>
              <span className="text-12">*대략적인 누적 금액 수치입니다.</span>
            </div>
            <TextField
              value={`₩ ${String(tSum || 0).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}`}
              className="text-right"
            />
          </div>
          <div className="space-y-4 w-full">
            <div className="space-x-3">
              <span className="font-bold text-18">
                {utcToLocalFormat(
                  from?.toISOString() || '',
                  MomentFormat.YYYYMMM
                )}{' '}
                매출정보
              </span>
              <span className="text-12">*대략적인 누적 금액 수치입니다.</span>
            </div>
            <TextField
              value={`₩ ${String(sum || 0).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}`}
              className="text-right"
            />
          </div>
        </div>

        <div className="flex space-x-20">
          <div className="space-y-4 w-full">
            <div className="font-bold text-18">당일 신규 주문</div>
            <AdminCard className="flex-1">
              <Table>
                <Table.Body>
                  <Table.Td className="bg-gray-50 text-primary-1">
                    전체
                  </Table.Td>
                  <Table.Th className="bg-primary-9 text-primary-1 text-14 text-center border-none">
                    {totalPaymentList?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50 border-none">
                    체험 수업
                  </Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center border-none">
                    {trialAmount?.length}
                  </Table.Th>
                </Table.Body>
                <Table.Body>
                  <Table.Td className="bg-gray-50">6회 체험팩</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {trialPackageAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">6회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {sixAmount?.length}
                  </Table.Th>
                </Table.Body>
                <Table.Body>
                  <Table.Td className="bg-gray-50">12회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {twelveAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">24회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {twentyFourAmount?.length}
                  </Table.Th>
                </Table.Body>
                <Table.Body>
                  <Table.Td className="bg-gray-50">48회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {fortyEightAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">96회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {ninetySixAmount?.length}
                  </Table.Th>
                </Table.Body>
              </Table>
            </AdminCard>
          </div>

          <div className="space-y-4  w-full">
            <div className="font-bold text-18">당월 신규 주문</div>
            <AdminCard className="flex-1">
              <Table>
                <Table.Body>
                  <Table.Td className="bg-gray-50 text-primary-1">
                    전체
                  </Table.Td>
                  <Table.Th className="bg-primary-9 text-primary-1 text-14 text-center border-none">
                    {monthlyPaymentList?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50 border-none">
                    체험 수업
                  </Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center border-none">
                    {monthlyTrialAmount?.length}
                  </Table.Th>
                </Table.Body>
                <Table.Body>
                  <Table.Td className="bg-gray-50">6회 체험팩</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlyTrialPackageAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">6회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlySixAmount?.length}
                  </Table.Th>
                </Table.Body>
                <Table.Body>
                  <Table.Td className="bg-gray-50">12회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlyTwelveAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">24회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlyTwentyFourAmount?.length}
                  </Table.Th>
                </Table.Body>

                <Table.Body>
                  <Table.Td className="bg-gray-50">48회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlyFortyEightAmount?.length}
                  </Table.Th>
                  <Table.Td className="bg-gray-50">96회</Table.Td>
                  <Table.Th className="bg-primary-9 text-14 text-center">
                    {monthlyNinetySixAmount?.length}
                  </Table.Th>
                </Table.Body>
              </Table>
            </AdminCard>
          </div>
        </div>

        <div className="space-y-4 w-full">
          <div className="font-bold text-18">예약률</div>
          <AdminCard className="flex-1">
            <Table>
              <Table.Body>
                <Table.Td className="bg-gray-50 text-primary-1">
                  이번달 예약률
                </Table.Td>
                <Table.Th className="bg-primary-9 text-primary-1 text-14 text-center border-none">
                  {thisLiveCount.toLocaleString()}%
                </Table.Th>
                <Table.Td className="bg-gray-50 text-primary-1 border-none">
                  다음달 예약률
                </Table.Td>
                <Table.Th className="bg-primary-9 text-primary-1 text-14 text-center border-none">
                  {nextMonthCount.toLocaleString()}%
                </Table.Th>
              </Table.Body>
              <Table.Body>
                <Table.Td className="bg-gray-50">총 수업 오픈수</Table.Td>
                <Table.Th className="bg-primary-9 text-14 text-center">
                  {
                    monthLives?.filter(
                      (live) => live.state !== LiveState.CANCELED
                    ).length
                  }
                </Table.Th>
                <Table.Td className="bg-gray-50">총 수업 오픈수</Table.Td>
                <Table.Th className="bg-primary-9 text-14 text-center">
                  {
                    nextLives?.filter(
                      (live) => live.state !== LiveState.CANCELED
                    ).length
                  }
                </Table.Th>
              </Table.Body>
              <Table.Body>
                <Table.Td className="bg-gray-50">총 예약 완료수</Table.Td>
                <Table.Th className="bg-primary-9 text-14 text-center">
                  {thisLives?.length}
                </Table.Th>
                <Table.Td className="bg-gray-50">총 예약 완료수</Table.Td>
                <Table.Th className="bg-primary-9 text-14 text-center">
                  {nextMonthDoneLiveCount?.length}
                </Table.Th>
              </Table.Body>
            </Table>
          </AdminCard>
        </div>
      </div>
    </div>
  );
};
