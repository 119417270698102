import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { PointType, Student } from '../../types';

interface FormValues {
  pointType: PointType;
  title: string;
  memo: string;
  value: number;
  userId: number;
  studentId: number;
}

interface AdminPointPopUpProps {
  userId: number;
  students?: Student[];
  onClose: () => void;
  refetch: () => void;
}

export const AdminPointPopUp: FC<AdminPointPopUpProps> = ({
  userId,
  students,
  onClose,
  refetch,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const add = handleSubmit((data) => {
    data.userId = userId;
    api
      .post('/admin/points', data)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((err) => console.error('포인트 생성 오류', err));
  });

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">포인트 지급/차감하기</H3>

        <Select
          label="지급/차감"
          className="pr-8 h-12 text-sm"
          {...register('pointType')}
        >
          <option value={PointType.PROVISION}>지급</option>
          <option value={PointType.DEDUCTIBLE}>차감</option>
        </Select>
        <Select
          label="자녀 선택"
          className="pr-8 h-12 text-sm"
          {...register('studentId')}
        >
          {students?.map((student) => (
            <option value={student.id}>{student.name}</option>
          ))}
        </Select>
        <TextField
          label="포인트"
          placeholder="포인트를 입력해주세요."
          helper={errors.value?.message}
          {...register('value', {
            required: '포인트를 입력해주세요.',
          })}
        />
        <TextArea label="관리자 메모" {...register('memo')} />
        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          disabled={!watch('value')}
          onClick={add}
        />
      </div>
    </div>
  );
};
