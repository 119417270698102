import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H4 } from '../../components/H4';
import { MobileBackButton } from '../../components/MobileBackButton';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export const PasswordResetPage = () => {
  const { push } = useHistory();
  const { token } = useParams<{ token: string }>();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4 ">
      <MobileBackButton />
      <H4 className="pb-8">새로운 비밀번호 입력</H4>

      <form
        onSubmit={handleSubmit(async (data) => {
          if (watch('password') !== watch('confirmPassword')) {
            alert('비밀번호가 다릅니다. 다시 확인해주세요.');
          } else {
            await api.post('users/reset-password', {
              token: token,
              password: data.password,
            });
            alert('비밀번호가 변경되었습니다. 재로그인 해주세요:)');
            push('/login');
          }
        })}
      >
        <div className="flex flex-col space-y-4">
          <TextField
            type="password"
            placeholder="새 비밀번호를 입력해주세요."
            helper={errors.password?.message}
            {...register('password', {
              required: '비밀번호를 입력해주세요',
              minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
            })}
          />
          <TextField
            type="password"
            placeholder="새 비밀번호를 한 번 더 입력해주세요."
            helper={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              required: '새 비밀번호를 한 번 더 입력해주세요.',
              minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
            })}
          />
        </div>

        <Button
          className="filled-primary-1 font-bold w-full mt-10"
          text="비밀번호 재설정"
        />
      </form>
    </div>
  );
};
