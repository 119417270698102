import React, { useEffect, useState } from 'react';
import { getSiteCode, PaymentContainer } from '../../plugins/payment/payment';
import { PaymentStartDto } from '../../plugins/payment/payment.api.types';
import { studentState } from '../../plugins/ridge';
import { PaymentMethod } from '../../types';

const PaymentProcessingMobile = () => {
  const student = studentState.useValue();
  const [isFail, setIsFail] = useState(false);
  const { restorePaymentStatus, rememberPgParams, removePaymentStatus } =
    PaymentContainer.useContext();
  const [paymentStatus, setPaymentStatus] = useState<PaymentStartDto>();

  useEffect(() => {
    const data = restorePaymentStatus();
    if (!data) {
      setIsFail(true);
      return;
    }
    removePaymentStatus();

    setPaymentStatus(data);
  }, [removePaymentStatus, restorePaymentStatus]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const jsf__chk_type = () => {
    // @ts-ignore
    const frm = document.order_info;
    if (paymentStatus?.paymentMethod === PaymentMethod.CARD) {
      frm.pay_method.value = 'CARD';
      frm.pay_mod.value = 'N';
    } else if (paymentStatus?.paymentMethod === PaymentMethod.BANK) {
      frm.pay_method.value = 'BANK';
      // frm.pay_mod.value = 'O';
    }
  };

  useEffect(() => {
    if (paymentStatus) {
      jsf__chk_type();
      // @ts-ignore
      const form = document.order_info;
      form.action = paymentStatus.mobilePayUrl;
      const data = Object.fromEntries(new FormData(form).entries());
      rememberPgParams(data);
      form.submit();
    }
  }, [jsf__chk_type, paymentStatus, rememberPgParams]);

  if (isFail || !paymentStatus) {
    return <div>결제 정보가 없거나 잘못된 접근입니다.</div>;
  }

  return (
    <div className="h-full">
      <form name="order_info" method="post" acceptCharset="euc-kr">
        {/* 주문 필수 정보 */}
        <input type="hidden" name="good_name" value="튼튼영어_수강권구매" />
        <input
          type="hidden"
          name="ordr_idxx"
          value={paymentStatus?.pgOrderNumber}
        />
        <input
          type="hidden"
          name="good_mny"
          value={paymentStatus?.totalAmount}
        />
        <input type="hidden" name="buyr_name" value={student?.name} />
        <input type="hidden" name="tno" value={paymentStatus.pgTraceNo} />
        <input
          type="hidden"
          name="approval_key"
          value={paymentStatus?.pgApprovalKey}
        />
        <input
          type="hidden"
          name="buyr_tel1"
          value={student?.user.phoneNumber}
        />
        <input type="hidden" name="buyr_tel2" value="010-0000-0000" />
        <input type="hidden" name="buyr_mail" value={student?.user.email} />
        <input
          type="hidden"
          name="ActionResult"
          value={
            paymentStatus?.paymentMethod === PaymentMethod.CARD ||
            paymentStatus?.paymentMethod === PaymentMethod.NPAY ||
            paymentStatus?.paymentMethod === PaymentMethod.KPAY
              ? 'card'
              : 'acnt'
          }
        />
        ActionResult
        <input
          type="hidden"
          name="pay_method"
          value={
            paymentStatus?.paymentMethod === PaymentMethod.CARD ||
            paymentStatus?.paymentMethod === PaymentMethod.NPAY ||
            paymentStatus?.paymentMethod === PaymentMethod.KPAY
              ? 'CARD'
              : 'BANK'
          }
        />
        <input type="hidden" name="pay_mod" value="N" />
        {/*요청 구분 */}
        <input type="hidden" name="req_tx" value="pay" />
        {/*사이트 이름 */}
        <input
          type="hidden"
          name="shop_name"
          value={
            paymentStatus?.paymentMethod === PaymentMethod.CARD ||
            paymentStatus?.paymentMethod === PaymentMethod.BANK
              ? '튼튼영어라이브'
              : paymentStatus?.paymentMethod === PaymentMethod.NPAY
              ? '튼튼영어라이브_Npay'
              : paymentStatus?.paymentMethod === PaymentMethod.KPAY
              ? '튼튼영어라이브_kakaopay'
              : ''
          }
        />
        {/* 사이트 키 - 테스트용/실서버용 */}
        <input
          type="hidden"
          name="site_cd"
          value={getSiteCode(paymentStatus?.paymentMethod)}
        />
        {/* 통화 코드 */}
        <input type="hidden" name="currency" value="410" />
        {/* 인증시 필요한 파라미터(변경불가)*/}
        <input type="hidden" name="van_code" value="" />
        {/* 신용카드 설정 */}
        {/* 최대 할부개월수 */}
        <input type="hidden" name="quotaopt" value="12" />
        {/*가상계좌 설정 */}
        <input type="hidden" name="ipgm_date" value="" />
        {/* 리턴 URL (kcp와 통신후 결제를 요청할 수 있는 암호화 데이터를 전송 받을 가맹점의 주문페이지 URL) */}
        <input
          type="hidden"
          name="Ret_URL"
          value={`${process.env.REACT_APP_API_URL}/payments/confirm/mobile`}
        />
        {/* 화면 크기조정 */}
        <input type="hidden" name="tablet_size" value="1.0" />
        {/*추가 파라미터 ( 가맹점에서 별도의 값전달시 param_opt 를 사용하여 값 전달 ) */}
        <input type="hidden" name="param_opt_1" value="" />
        <input type="hidden" name="param_opt_2" value="" />
        <input type="hidden" name="param_opt_3" value="" />
        {/*결제 정보 등록시 응답 타입 ( 필드가 없거나 값이 '' 일경우 TEXT, 값이 XML 또는 JSON 지원 */}
        <input type="hidden" name="response_type" value="JSON" />
        <input type="hidden" name="PayUrl" id="PayUrl" value="" />
        <input type="hidden" name="traceNo" value={paymentStatus.pgTraceNo} />
        <input type="hidden" name="encoding_trans" value="UTF-8" />
        {/* 에스크로 사용유무 에스크로 사용 업체(가상계좌, 계좌이체 해당)는 escw_used 를 Y로 세팅 해주시기 바랍니다. */}
        <input type="hidden" name="escw_used" value="N" />
        <input type="hidden" name="shop_user_id" value={student?.id} />
        <input
          type="hidden"
          name="kakaopay_direct"
          value={
            paymentStatus?.paymentMethod === PaymentMethod.KPAY ? 'Y' : 'N'
          }
        />
        <input
          type="hidden"
          name="naverpay_direct"
          value={
            paymentStatus?.paymentMethod === PaymentMethod.NPAY ? 'Y' : 'N'
          }
        />
      </form>
    </div>
  );
};

export default PaymentProcessingMobile;
