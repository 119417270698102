import { useEffect } from 'react';
export const NewPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="new-terms-wrap">
        <p className="hv-txt">개인정보 처리방침</p>
        (주)튼튼영어(이하 ‘회사’라 한다.)은 회원의 개인정보보호를 매우
        중요시하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및
        “개인정보 보호법” 등 개인정보와 관련된 법령 상의 개인정보보호 규정과
        행정안전부 및 방송통신위원회 등 유관기관이 제정한 가이드라인을 준수하고
        있습니다.
        <br />
        또한 회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에
        이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을
        경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다.
        <br />
        단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수
        있습니다.
        <br />
        회사는 개인정보취급방침을 통해 이용자가 제공하는 개인정보가 어떠한
        용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고
        있음을 다음과 같이 알려드리고자 합니다.
        <br />본 방침은 2022년 4월 11일부터 시행됩니다.
        <br />
        <br />
        1. 수집하는 개인정보 항목 및 수집방법
        <br />
        2. 개인정보의 수집 및 이용목적
        <br />
        3. 개인정보의 보유 및 이용기간
        <br />
        4. 개인정보의 파기절차 및 방법
        <br />
        5. 개인정보제공
        <br />
        6. 수집한 개인정보의 위탁
        <br />
        7. 이용자 및 법정대리인의 권리와 그 행사 방법
        <br />
        8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
        <br />
        9. 개인정보의 기술적, 관리적 보호 대책
        <br />
        10. 개인정보에 관한 상담 및 신고
        <br />
        11. 부칙
        <br />
        <br />
        <p className="nl-txt">1. 수집하는 개인정보 항목 및 수집방법</p>
        이용자들이 회원서비스를 이용하기 위해 회사의 웹사이트에 가입하거나
        고객센터를 통해 문의할 때, 체험 수업 신청을 작성할 때, 무료상담을 요청할
        때, 구매한 제품을 배송할 때 등 각종 서비스 제공을 위한 필수적인 정보들을
        온라인상에서 입력받고 있습니다.
        <br />
        <br />
        1) 수집하는 개인정보의 항목
        <br />
        ① 회사는 각종 서비스의 제공에 필요한 회원님의 최소한의 개인정보를
        수집하며, 수집∙이용하는 목적은 다음과 같습니다.
        <br />
        <br />
        [필수 수집∙이용 목적 및 항목]
        <br />
        <table className="new-terms-table">
          <tr>
            <td className="first-td">수집∙이용 목적</td>
            <td className="first-td">개인정보 항목</td>
          </tr>
          <tr>
            <td>
              회원가입/탈퇴, 서비스가입/변경/해지, 교재배송, 본인확인, 사용자
              식별, 고지사항전달, 이용관련 문의 및 불만처리, 자녀 학습분석, 수업
              추천, 이용후기, 이용요금 청구, 할인, 결제 및 추심, 명의도용 방지를
              위한 등록된 이동전화
            </td>
            <td>
              휴대전화번호, 이메일주소, 이름, 배송지 주소, 학생정보(생년월일,
              성별, 학년, 학습수준)
            </td>
          </tr>
          <tr>
            <td>학습 제공 및 수업품질 유지를 위한 화상수업 녹화</td>
            <td>강사의 수업 강의내용 및 학생 모습 녹화</td>
          </tr>
        </table>
        ※위 정보는 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를
        포함합니다. <br />
        ② 서비스 이용 과정이나 사업 처리 과정에서 아래와 같은 정보들이 생성되어
        수집될 수 있습니다. <br />
        - 회원의 브라우저 종류 및 OS, IP Address, 쿠키 <br />
        - 방문 일시, 서비스 이용 기록 <br />
        ③ 부가 서비스 이용, 설문조사, 이벤트 등의 응모 과정에서 회원가입 시
        수집하지 않았던 개인정보를 추가로 수집할 때에는 해당 서비스의 이용자에
        한해서만 개인정보 추가 수집에 대해 사전에 고지하고 이에 대해
        명시적·개별적 동의를 받습니다. <br />
        ④ 회사는 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종,
        사상 및 신조, 정치적 성향이나 범죄기록, 의료정보 등)은 기본적으로
        수집하지 않습니다. <br />
        <br />
        2) 개인정보 수집 방법 <br />
        홈페이지 회원가입, 서비스 이용, 체험신청, 이벤트 응모, 회원정보 수정,
        팩스, 전화, 고객센터 문의하기 - 서비스 이용과정 중에서 아래와 같은
        정보들이 자동으로 생성되어 수집됩니다.(IP Address, 쿠키, 방문 일시,
        서비스 이용 기록, 불량 이용 기록)
        <br />
        <br />
        <p className="nl-txt">2. 개인정보의 수집 및 이용목적</p>
        회사는 이용자들에게 맞춤학습, 교육정보, 고객상담 등을 서비스하기 위하여
        최소한의 개인 정보를 수집하고 있습니다. 회사가 수집한 개인 정보는 회사의
        서비스 제공 이외의 다른 어떤 용도로도 사용되지 않습니다. 또한 회사는
        이용자들의 개인 정보를 보다 나은 서비스 개발을 위해 활용하여 이용자들이
        원하는 서비스를 받으실 수 있도록 합니다.
        <br />
        <br />
        <p className="nl-txt">3. 개인정보의 보유 및 이용기간</p>
        이용자들이 회사에서 제공하는 서비스를 이용하는 동안은 이용자들의
        개인정보를 계속적으로 보유하며 서비스 제공 등을 위해 이용합니다. 다만,
        개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이
        달성되거나 보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기
        합니다. 하지만, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는
        아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
        <br />
        <br />
        1) 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
        <br />
        보존 근거: 통신비밀보호법
        <br />
        보존 기간: 3개월
        <br />
        2) 표시/광고에 관한 기록
        <br />
        보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        보존 기간 : 6개월
        <br />
        3) 계약 또는 청약철회 등에 관한 기록
        <br />
        보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        보존 기간 : 5년
        <br />
        4) 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        보존 기간 : 5년
        <br />
        5) 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        보존 기간 : 3년
        <br />
        6) 본인확인에 관한 기록 : 6개월
        <br />
        보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
        <br />
        보존 기간 : 6개월
        <br />
        <br />
        <p className="nl-txt">4. 개인정보의 파기절차 및 방법</p>
        회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를
        지체 없이 파기합니다. 파기 절차 및 방법은 다음과 같습니다.
        <br />
        <br />
        1) 파기절차
        <br />
        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로
        옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
        정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후
        파기됩니다.
        <br />
        2) 파기 방법
        <br /> 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제합합니다. 그리고 종이에 출력된 개인정보는 분쇄기로
        분쇄하거나 소각을 통하여 파기합니다.
        <br />
        <br />
        <p className="nl-txt">5. 개인정보제공</p>
        회사는 이용자들의 개인정보를 “2. 개인정보의 수집목적 및 이용목적”에서
        고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를
        초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 제공하지
        않습니다. 단, 다음의 경우에는 개인정보를 처리할 수 있습니다.
        <br />
        <br />
        1) 이용자들이 사전에 동의한 경우
        <br />
        2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
        <br />
        <br />
        <p className="nl-txt">6. 수집한 개인정보의 위탁</p>
        회사는 원칙적으로 이용자의 동의 없이 해당 개인정보의 처리를 타인에게
        위탁하지 않습니다. 다만, 이용자들의 동의를 받아 회사는 현재
        개인정보처리를 위탁하는 사항은 다음과 같습니다.
        <br />
        <br />
        [필수 수집∙이용 목적 및 항목]
        <br />
        <table className="new-terms-table">
          <tr>
            <td className="first-td">수탁 업체</td>
            <td className="first-td">위탁 업무 내용</td>
          </tr>
          <tr>
            <td>TCK(트랜스코스모스코리아)</td>
            <td>고객 상담, 관리</td>
          </tr>
          <tr>
            <td>시노텍코리아</td>
            <td>학습 기기 AS</td>
          </tr>
          <tr>
            <td>채널톡</td>
            <td>고객 채팅 상담, 관리</td>
          </tr>
          <tr>
            <td>비즈엠알림톡</td>
            <td>고객 알림 공지, 본인 인증</td>
          </tr>
          <tr>
            <td>NHN KCP</td>
            <td>상품 및 서비스에 대한 결제 대행 및 현금영수증 발행</td>
          </tr>
          <tr>
            <td>(주)케이티알파</td>
            <td>모바일 경품(기프티콘) 발송</td>
          </tr>
          <tr>
            <td>CJ 대한통운</td>
            <td>배송 서비스</td>
          </tr>
        </table>
        ※보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지
        <br />
        <br />
        <p className="nl-txt">7. 이용자 및 법정대리인의 권리와 그 행사 방법</p>
        이용자 및 법정대리인(본인의 위임을 받지 않고도 법률의 규정에 의하여
        대리권의 효력이 발생하는 자로 미성년자에 대한 친권자나 후견인 등을
        말함)은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의
        개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.
        이용자 혹은 만 14세 미만 아동의 개인정보 조회/수정을 위해서는
        '개인정보변경'(또는 '회원정보관리' 등)을 통하여 가능하며,
        가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 본인 확인 절차를
        거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
        <br />
        또는 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체
        없이 조치하겠습니다. 이용자가 개인정보의 오류에 대한 정정을 요청한
        경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
        않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
        처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
        회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는
        "3. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의
        용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <br />
        <br />
        <p className="nl-txt">
          8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
        </p>
        회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를
        수시로 저장하고 불러오는 '쿠키(cookie)'를 운용합니다. 쿠키란 웹사이트를
        운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트
        파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다. 회사는 다음과 같은
        목적을 위해 쿠키를 사용합니다.
        <br />
        <br />
        1)쿠키의 사용 목적
        <br />
        회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공에 사용됩니다. 이용자는
        쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
        웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될
        때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        <br />
        <br />
        2)쿠키 설정 거부 방법
        <br />
        쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을
        선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
        <br />
        설정방법 예(인터넷 익스플로어의 경우): 웹 브라우저 상단의 도구 ＞ 인터넷
        옵션 ＞ 개인정보
        <br />
        단, 쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에
        어려움이 있을 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">9. 개인정보의 기술적, 관리적 보호 대책</p>
        회사는 이용자의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출,
        변조 또는 훼손되지 않도록 안정성 확보를 위하여 다음과 같은 기술적,
        관리적 대책을 강구하고 있습니다.
        <br />
        <br />
        1) 개인정보 암호화
        <br />
        이용자의 개인정보는 비밀번호에 의해 보호되며, 중요한 데이터는 파일 및
        전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도
        보안기능을 통해 보호하고 있습니다.
        <br />
        <br />
        2) 해킹 등에 대비한 기술적 대책
        <br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을
        설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여 24시간
        감시하고 있습니다. 그리고 백신 프로그램을 주기적으로 업데이트하며
        갑작스러운 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써
        개인정보가 침해되는 것을 방지하고 있습니다.
        <br />
        <br />
        3) 개인정보처리시스템 접근 제한
        <br />
        회사는 개인정보를 처리할 수 있도록 체계적으로 구성한
        데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을
        수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타
        개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
        <br />
        <br />
        4)개인정보 취급 직원의 교육
        <br />
        개인정보관련 취급 직원은 담당자에 한정시켜 최소화하고 새로운 보안기술의
        습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의
        비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고
        있습니다.
        <br />
        <br />
        5) 개인 아이디와 비밀번호 관리
        <br />
        이용자가 사용하는 ID와 비밀번호는 원칙적으로 이용자만이 사용하도록 되어
        있습니다. 회사는 이용자의 개인적인 부주의로 아이디, 비밀번호등
        개인정보가 유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에
        일어나는 일들에 대해 책임을 지지 않습니다. 비밀번호에 대한 보안 의식을
        가지고 비밀번호를 자주 변경하며 공용PC에서의 로그인시 개인정보가
        유출되지 않도록 각별한 주의를 기울여 주시기 바랍니다.
        <br />
        <br />
        <p className="nl-txt">10. 개인정보에 관한 상담 및 신고</p>
        회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 개인정보관리책임자를 지정하고 있습니다. 이용자는
        회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
        개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
        이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
        <br />
        <br />
        [개인정보 관리 담당자]
        <br />
        이름 임재현
        <br />
        소속/직책 IT실/이사
        <br />
        전화 1577-0582
        <br />
        E-mail jhlim@tuntun.co.kr
        <br />
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        <br />
        개인정보침해신고센터 : (국번없이)118 privacy.kisa.or.kr
        <br />
        정보보호마크인증위원회 : 02-580-0533~4 www.eprivacy.or.kr
        <br />
        대검찰청 사이버범죄수사단 : 02-580-0533~4 www.spo.go.kr
        <br />
        경찰청 사이버테러대응센터 : 02-393-9112 www.ctrc.go.kr
        <br />
        <br />
        <p className="nl-txt">11. 부칙</p>
        법령 및 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을
        시에는 변경사항의 시행일의 최소 7일 전부터 회사 사이트의 공지사항을
        통하여 고지할 것입니다. 다만, 회원의 권리 또는 의무에 중요한 내용의
        변경은 최소 30일전에 고지하겠습니다.
      </div>
    </>
  );
};
