import React, { FC, useEffect, useState } from 'react';
import { Button } from '../components/Button';
import { Checkbox } from '../components/Checkbox';
import { H5 } from '../components/H5';
import { H6 } from '../components/H6';
import { Section } from '../components/Section';
import { Select } from '../components/Select';
import { TextField } from '../components/TextField';
import MobileDetect from 'mobile-detect';
import PaymentProcessingWeb from './Pg/PaymentProcessingWeb';
import PaymentProcessingMobile from './Pg/PaymentProcessingMobile';
import { PaymentContainer } from '../plugins/payment/payment';
import { useHistory, useLocation } from 'react-router-dom';
import { AdminPhoneNumber } from '../admin/components/AdminPhoneNumber';
import { useForm } from 'react-hook-form';
import { Coupon, CouponType, PaymentMethod, User } from '../types';
import { popupState, studentState } from '../plugins/ridge';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { useAuth } from '../hooks';
import { DateTime } from 'luxon';
import { api } from '../plugins/axios';

interface FormValues {
  paymentMethod: PaymentMethod;
  email: string;
  paymentPhoneNumber: string;
  studentId: number;
  productIds: number[];
  productAmount?: number;
  couponItem?: string;
  couponItemIds?: number[];
  mobileReturnUrl?: string;
  pgOrderNumber?: string;
}

export const TrialPurchasePage = () => {
  const { authenticated } = useAuth();
  const { push } = useHistory();
  const { state } = useLocation<any>();
  const { trialPaymentStart } = PaymentContainer.useContext();
  const student = studentState.useValue();
  const [paidStart, setPaidStart] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [paymentTel1, setPaymentTel1] = useState<string>('');
  const [paymentTel2, setPaymentTel2] = useState<string>('');
  const [paymentTel3, setPaymentTel3] = useState<string>('');
  const [selectedRepeatCoupons, setSelectedRepeatCoupons] = useState(new Set());

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  const { watch, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      email: me?.email,
      paymentPhoneNumber: me?.phoneNumber,
      studentId: state?.studentId,
      productIds: state?.productIds,
      productAmount: state?.productAmount,
    },
  });

  const { data: coupons } = useQuery<Coupon[]>(
    `/coupons/not-used/user`,
    fetcher,
    {
      enabled: !!student,
    }
  );

  useEffect(() => {
    if (!me) return;
    setValue('email', me.email);
    setValue('paymentPhoneNumber', me.phoneNumber);
    setPaymentTel1(me.phoneNumber.substring(0, 3));
    setPaymentTel2(me.phoneNumber.substring(3, 7));
    setPaymentTel3(me.phoneNumber.substring(7));
  }, [setValue, me]);

  const md = new MobileDetect(window.navigator.userAgent);

  const parseCouponItem: { id: number; price: number; studentId?: number } =
    watch('couponItem') && JSON.parse(watch('couponItem') || '');

  const repeatCoupons = Array.from(selectedRepeatCoupons)?.map((c: any) =>
    JSON.parse(c)
  );
  const repeatPrices = repeatCoupons?.map((p) => p.price);
  const repeatDiscountPrice = repeatPrices?.reduce(function add(
    sum,
    currValue
  ) {
    return sum + currValue;
  },
  0);

  const discountTotalPrice =
    state?.totalAmount <= repeatDiscountPrice + (parseCouponItem?.price || 0)
      ? state?.totalAmount
      : repeatDiscountPrice + (parseCouponItem?.price || 0);
  const totalPrice =
    state?.totalAmount - (discountTotalPrice || 0) <= 0
      ? 0
      : state?.totalAmount - (discountTotalPrice || 0);

  const getNormalCoupons = coupons?.filter(
    (coupon) => coupon.type === CouponType.NORMAL
  );
  const getRepeatCoupons = coupons?.filter((coupon) =>
    coupon.student?.id
      ? (coupon.student?.id === student?.id || !coupon.student) &&
        coupon.type === CouponType.REPEAT
      : !coupon.student && coupon.type === CouponType.REPEAT
  );

  // 수강권 유효기간 설정
  const startedDate = DateTime.now().toISODate();
  const endedDate = DateTime.local()
    .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
    .plus({ weeks: 7 })
    .toISODate();

  useEffect(() => {
    if (parseCouponItem && parseCouponItem.studentId !== student?.id) {
      setValue('couponItem', '');
    }
  }, [parseCouponItem, setValue, student?.id]);

  return (
    <div className="flex md:flex-row flex-col max-w-4xl mx-auto md:pt-24 gap-6 px-4 py-8">
      {isOpen && md.mobile() ? (
        <PaymentProcessingMobile />
      ) : isOpen ? (
        <PaymentProcessingWeb />
      ) : (
        ''
      )}
      <Section className="space-y-6 flex-1">
        <H6>결제자 정보</H6>
        <TextField
          label="이메일 *"
          type="email"
          placeholder="이메일 주소"
          value={me?.email}
          disabled
        />
        <AdminPhoneNumber
          label="핸드폰 번호*"
          number1={paymentTel1}
          number2={paymentTel2}
          number3={paymentTel3}
          setNumber1={setPaymentTel1}
          setNumber2={setPaymentTel2}
          setNumber3={setPaymentTel3}
          disabled
        />
        <div className="border border-gray-50" />

        <H6>결제 방법</H6>
        <div className="grid gap-4 grid-cols-3">
          {[
            { title: '신용카드', value: PaymentMethod.CARD },
            { title: '실시간 계좌이체', value: PaymentMethod.BANK },
          ].map((p) => (
            <button
              className={`px-3 py-2 border border-gray-50 rounded-md whitespace-pre ${
                watch('paymentMethod') === p.value && 'bg-blue-500 text-white'
              }`}
              onClick={() =>
                setValue(
                  'paymentMethod',
                  p.value === PaymentMethod.CARD
                    ? PaymentMethod.CARD
                    : PaymentMethod.BANK
                )
              }
            >
              {p.title}
            </button>
          ))}
        </div>

        <div className="border border-gray-50" />

        <p className="font-nanum text-17">수강권 유의사항</p>
        <div className="bg-gray-50 p-5 rounded-lg">
          {[
            { description: '튼튼영어라이브 화상수업 1회 25분입니다.' },
            {
              description:
                '구매하신 패키지에 포함된 디지털 콘텐츠는 수강 기간 내내 제한없이 사용하실 수 있습니다.',
            },
            { description: '이 수강권은 이용 중 정지(홀딩)가 불가능합니다.' },
            {
              description:
                '모든 증빙서류(수강확인, 출석증 등)는 고객센터에 요청하시면 1-2 영업일 이내에 발급해드립니다.',
            },
          ].map((d) => (
            <InfoItem description={d.description} />
          ))}
        </div>

        <div className="border border-gray-50" />

        <p className="font-nanum text-17">환불 정책</p>
        <div className="bg-gray-50 p-5 rounded-lg">
          {[
            {
              description:
                '수강권 환불 가능 기간은 결제 일자로부터 계산됩니다.',
            },
            {
              description:
                '페이퍼북 학습패키지 구매의 경우, 배송된 학습교재의 포장 훼손이 있을 경우, 환불이 불가능합니다.',
            },
            {
              description:
                '디지털학습패키지 구매의 경우, 수강권 구매 후 7일 이내 수강기록이 없을 경우 전액 환불이 가능합니다.',
            },
            {
              description:
                '수강권 사용 후 환불 시, 실결제 금액에서 [수업한 수강횟수의 정가 금액+콘텐츠 결합 상품의 정가+사은품 정가]금액을 차감하고 환불됩니다. (1회 수업당 정가 기준 차감)',
            },
            {
              description:
                '환불은 고객센터를 통해 진행 가능합니다. (하단 ‘질문있어요!’ 채팅 기능 활용)',
            },
            {
              description:
                '환불 정책 및 정산 방법 등은 홈페이지 내 이용약관을 참고해주시기 바랍니다.',
            },
          ].map((d) => (
            <InfoItem description={d.description} />
          ))}
        </div>
      </Section>

      <Section className="bg-secondary-1 rounded-lg p-5 space-y-5 h-full flex-1">
        <p className="text-17 font-nanum">주문 정보</p>
        <div className="justify-between flex">
          <p className="text-gray-600">상품명</p>
          <p className="font-bold">체험 {state?.productAmount}회권</p>
        </div>
        <div className="justify-between flex">
          <p className="text-gray-600">수강 기간</p>
          <p className="font-bold">
            {startedDate} ~ {endedDate}
          </p>
        </div>

        <div className="space-y-1">
          <p className="text-17 font-nanum pt-4">기본 쿠폰</p>
          <Select
            className="border-gray-100"
            value={watch('couponItem')}
            onChange={(e) => {
              const isAlreadyOverDiscountPrice =
                (state?.totalAmount || 0) - discountTotalPrice <= 0 &&
                e.target.value !== '';

              if (
                isAlreadyOverDiscountPrice &&
                watch('couponItem') !== e.target.value &&
                watch('couponItem') !== ''
              ) {
                setValue('couponItem', e.target.value);
                return;
              }

              if (isAlreadyOverDiscountPrice) {
                alert('총 금액 이미 0원이므로 추가 쿠폰 사용은 불가합니다.');
                setValue('couponItem', '');
                return;
              }

              setValue('couponItem', e.target.value);
            }}
          >
            <option value="" disabled selected hidden>
              쿠폰을 선택해주세요
            </option>
            <option value="">선택안함</option>
            {getNormalCoupons
              ?.filter((coupon) => coupon.student?.id === student?.id)
              ?.map((c) => {
                return (
                  <>
                    {c.couponItems.length > 0 && (
                      <option
                        value={JSON.stringify({
                          id: c.couponItems[0]?.id || '',
                          price: c.discount,
                          studentId: c.couponItems[0]?.studentId,
                        })}
                      >
                        {c.name +
                          ` ${String(c.discount || 0).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}원` +
                          ' (' +
                          c.useEnd +
                          ' 까지)'}
                      </option>
                    )}
                  </>
                );
              })}
          </Select>
          <p className="text-gray-400 text-14">
            *해당 자녀에게 발급된 쿠폰만 보여집니다.
          </p>
        </div>

        <div className="space-y-1.5">
          <p className="text-17 font-nanum pt-4">
            친구 추천 쿠폰(중복/다중 적용가능)
          </p>
          {getRepeatCoupons && getRepeatCoupons.length > 0 ? (
            <>
              {getRepeatCoupons?.map((c) => {
                return (
                  <>
                    {c.couponItems.length > 0 && (
                      <Checkbox
                        label={
                          c.name +
                          ` ${String(c.discount || 0).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )}원` +
                          ' (' +
                          c.useEnd +
                          ' 까지)'
                        }
                        onChange={(e) => {
                          const newValue = new Set(selectedRepeatCoupons);
                          if (
                            selectedRepeatCoupons.has(
                              JSON.stringify({
                                id: c.couponItems[0]?.id || '',
                                price: c.discount,
                              })
                            )
                          ) {
                            newValue.delete(
                              JSON.stringify({
                                id: c.couponItems[0]?.id || '',
                                price: c.discount,
                              })
                            );
                          } else {
                            const isAlreadyOverDiscountPrice =
                              (state?.totalAmount || 0) - discountTotalPrice <=
                              0;

                            if (isAlreadyOverDiscountPrice) {
                              alert(
                                '총 금액 이미 0원이므로 추가 쿠폰 사용은 불가합니다.'
                              );
                              return;
                            }

                            newValue.add(
                              JSON.stringify({
                                id: c.couponItems[0]?.id || '',
                                price: c.discount,
                              })
                            );
                          }
                          setSelectedRepeatCoupons(newValue);
                        }}
                        value={JSON.stringify({
                          id: c.couponItems[0]?.id || '',
                          price: c.discount,
                          studentId: c.couponItems[0]?.studentId,
                        })}
                        checked={selectedRepeatCoupons.has(
                          JSON.stringify({
                            id: c.couponItems[0]?.id || '',
                            price: c.discount,
                          })
                        )}
                      />
                    )}
                  </>
                );
              })}
            </>
          ) : (
            <p className=" text-gray-400 text-14">
              - 사용 가능한 추천 쿠폰이 없습니다.
            </p>
          )}
        </div>

        <p className="text-17 font-nanum pt-4">결제 금액</p>
        <div className="bg-white rounded-md p-5">
          <div className="justify-between flex">
            <p className="text-gray-600">할인 금액</p>
            <p className="text-red-500">
              {String(discountTotalPrice || 0).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ','
              )}
              원
            </p>
          </div>

          <div className=" bg-secondary-4 text-red-500 rounded p-2 mt-2 text-13">
            쿠폰 할인금액이 결제액보다 클경우 차액 환불은 불가합니다.
          </div>

          <div className="border border-gray-50 -mx-5 my-5" />

          <div className="justify-between flex items-center">
            <p className="text-gray-600">총 금액</p>
            <H5>
              {String(totalPrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
            </H5>
          </div>
        </div>

        <p className="text-17 font-nanum pt-4">
          구매 조건 확인 및 결제대행 서비스 약관
        </p>
        <div className="justify-between flex">
          <p className="text-gray-600">전자금융거래 이용약관</p>
          <button
            className="text-primary-1"
            onClick={() =>
              window.open(
                'https://testspay.kcp.co.kr/html/terms/eCommerceTransKO.html'
              )
            }
          >
            보기
          </button>
        </div>
        <div className="justify-between flex">
          <p className="text-gray-600">개인정보 수집 및 이용약관</p>
          <button
            className="text-primary-1"
            onClick={() =>
              window.open(
                'https://testspay.kcp.co.kr/html/terms/personalInfoKO.html'
              )
            }
          >
            보기
          </button>
        </div>
        <div className="justify-between flex">
          <p className="text-gray-600">개인정보 제3자 제공 및 위탁 약관</p>
          <button
            className="text-primary-1"
            onClick={() =>
              window.open(
                'https://testspay.kcp.co.kr/html/terms/personSupportKO.html'
              )
            }
          >
            보기
          </button>
        </div>

        <div className="py-4">
          <Checkbox
            label="위 주문 내용을 확인하였으며, 결제를 진행함으로써 약관 및 유의사항에 동의합니다."
            onChange={() => setIsAgree(!isAgree)}
          />
        </div>

        <Button
          text="결제하기"
          className="filled-primary-1 rounded-md font-bold w-full h-14"
          disabled={!watch('paymentMethod') || !isAgree}
          onClick={
            !paidStart
              ? handleSubmit((data) => {
                  setPaidStart(true);
                  const { mobileReturnUrl, couponItem, ...payment } = data;
                  const repeatCouponIds = repeatCoupons?.map((c) => c.id);

                  payment.couponItemIds =
                    repeatCouponIds.length > 0 && parseCouponItem?.id
                      ? repeatCouponIds.concat(parseCouponItem?.id)
                      : repeatCouponIds.length > 0
                      ? repeatCouponIds
                      : [parseCouponItem?.id];

                  if (totalPrice <= 0) {
                    api
                      .post('payments/trial/regular/omitted', {
                        ...payment,
                      })
                      .then((res) =>
                        push(
                          `/purchase/success?orderNumber=${res.data.pgOrderNumber}`
                        )
                      )
                      .catch((e) => {
                        setPaidStart(false);
                        popupState.set({
                          title: '결제 오류',
                          body: e.response.data.message,
                        });
                      });
                  } else {
                    trialPaymentStart({
                      mobileReturnUrl: `${window.location.protocol}//${window.location.host}/purchase/success`,
                      ...payment,
                    })
                      .then(
                        (result: { totalAmount: any; mobilePayUrl: any }) => {
                          if (!result.totalAmount) {
                            // 0원 결제 경우 처리?
                          }
                          if (result.mobilePayUrl) {
                            setIsOpen(true);
                          } else {
                            alert(
                              '결제 주소가 없습니다. 관리자에게 문의해주세요.'
                            );
                          }
                        }
                      )
                      .catch((e) => {
                        setPaidStart(false);
                        popupState.set({
                          title: '결제 오류',
                          body: e.response.data.message,
                        });
                      });
                  }
                })
              : () => ''
          }
        />
      </Section>
    </div>
  );
};

interface InfoItemProps {
  description: string;
}

export const InfoItem: FC<InfoItemProps> = ({ description }) => {
  return (
    <>
      <div className="flex">
        •<p className="pl-2 pt-1 text-12 text-gray-600">{description}</p>
      </div>
    </>
  );
};
