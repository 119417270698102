import React from 'react';
import { useHistory } from 'react-router';
import { api } from '../plugins/axios';
import {
  courseState,
  popupState,
  selectLevelNoTestPopupState,
  selectLevelPopupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import { Live } from '../types';
import { Button } from './Button';
import { XIcon } from './icons';

export interface PopUpProps {
  title?: string;
  body?: any;
  secondBody?: any;
  primaryText?: string;
  primaryClick?: () => void;
  secondaryText?: string;
  secondaryClick?: () => void;
  dangerText?: string;
  dangerClick?: () => void;
  footer?: any;
}

export const PopUp = () => {
  const popup = popupState.useValue();

  if (!popup.title) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="card max-h-screen-15">
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-24 whitespace-pre">{popup.title}</h1>
          <button className="mt-1" onClick={popupState.reset}>
            <XIcon />
          </button>
        </div>

        {popup.body && (
          <div className="mt-4 text-17 text-gray-700 whitespace-pre-line overflow-y-auto">
            {popup.body}
          </div>
        )}

        {popup.secondBody && (
          <div className="mt-6 text-14 text-gray-400 whitespace-pre-line">
            {popup.secondBody}
          </div>
        )}

        <div className="mt-14 flex flex-col space-y-3">
          {popup.primaryText && (
            <Button
              text={popup.primaryText}
              className="filled-primary-1"
              onClick={() => {
                popupState.reset();
                popup.primaryClick?.();
              }}
            />
          )}
          {popup.secondaryText && (
            <Button
              text={popup.secondaryText}
              className="bg-secondary-1 text-primary-1"
              onClick={() => {
                popupState.reset();
                popup.secondaryClick?.();
              }}
            />
          )}
          {popup.dangerText && (
            <Button
              text={popup.dangerText}
              className="bg-red-50 text-red-600"
              onClick={() => {
                popupState.reset();
                popup.dangerClick?.();
              }}
            />
          )}
        </div>

        {popup.footer && (
          <div className="mt-4 text-17 text-gray-700">{popup.footer}</div>
        )}
      </div>
    </div>
  );
};

export function usePopup() {
  const { push } = useHistory();

  const requireLoginPopup = (returnUrl?: string) =>
    popupState.set({
      title: '로그인이 필요한 서비스입니다.',
      body: '로그인이 필요한 서비스입니다. 로그인 페이지로 이동하시겠습니까?',
      primaryText: '로그인 페이지로 이동하기',
      primaryClick: () =>
        push('/login' + (returnUrl ? '?ru=' + returnUrl : '')),
    });

  const addStudentPopup = () =>
    popupState.set({
      title: '등록된 자녀가 없습니다.',
      body: '등록된 자녀가 없습니다. 자녀 등록 페이지로 이동하시겠습니까?',
      primaryText: '자녀 등록 페이지로 이동하기',
      primaryClick: () => push('/parent/manage'),
    });

  const requireLevelTestPopup = (studentId?: number) =>
    popupState.set({
      title: '레벨테스트',
      body: '레벨테스트를 응시하시면\n 우리 아이에게 딱 맞는 수업이 가능합니다.',
      primaryText: '레벨테스트 응시하기',
      primaryClick: selectLevelPopup,
      secondaryText: `레벨테스트 없이\n바로 체험 수업 예약하기`,
      // secondaryClick: selectLevelNoTestPopup
      // 20230322 안정환 레벨테스트 집입 팝업과 동일하지만 레벨테스트는 스킵함
      secondaryClick: () => {
        const isTesting = localStorage.getItem('leveltest_ready');

        if (isTesting) {
          const isSkip = window.confirm(
            '진행 중인 레벨테스트를 완료하지 않고 레벨테스트 미진행으로 체험 수업 예약을 진행하시겠습니까?'
          );
          if (isSkip) {
            localStorage.removeItem('leveltest_ready');
            // skipLevelTestPopup(studentId);
            selectLevelNoTestPopup();
          } else {
            return;
          }
        } else {
          // skipLevelTestPopup(studentId);
          selectLevelNoTestPopup();
        }
      },
    });

  const skipLevelTestPopup = (studentId?: number) =>
    popupState.set({
      title: '레벨테스트 미응시',
      body: '레벨 테스트 없이 바로 체험수업을 받는 경우 1단계 첫번째 교재로 학습단계가 배정되며 체험수업은 [I Love Stories]로 진행됩니다.',
      secondBody:
        '* 영어 학습이 처음이 아니라면,\n정확한 레벨 진단을 위해 레벨테스트를 응시해주세요.',
      primaryText: '바로 체험수업 예약하기',
      primaryClick: async () => {
        studentId &&
          (await api
            .post(`/test-results/skip`, { studentId: studentId })
            .then((res) => {
              testResultState.set(res.data);
              studentState.set(res.data.student);

              // 체험수업 구매유무 확인
              if (
                res.data.student.courses &&
                res.data.student.courses.length > 0
              ) {
                window.location.replace('/learn/reservation');
                courseState.set(res.data.student.courses[0]);
                localStorage.setItem(
                  'courseId',
                  res.data.student.courses[0]?.id.toString()
                );
              } else {
                window.location.replace('/pricing/trial');
              }
            })
            .catch((err) => {
              alert(`${err.response.data.message}`);
              return;
            }));
      },
    });

  const requireCoursePopup = () =>
    popupState.set({
      title: '구매한 수강권이 없습니다.',
      body: '구매한 수강권이 없습니다. 수강권 구매 페이지로 이동하시겠습니까?',
      primaryText: '수강권 구매하기',
      primaryClick: () => push('/pricing/regular'),
    });

  const noShowTeacherPopup = (live: Live) =>
    popupState.set({
      title: '선생님이 수업에 \n참여하지 않았나요?',
      body: '수업시간 기준 10분 후 까지 선생님이 들어오지 않았다면, 아래 버튼을 눌러주세요!',
      secondBody:
        '*관리자 확인 후 해당 선생님에게 안내메세지가 전달되며, 학생의 수업 횟수권은 복원됩니다.',
      primaryText: '선생님이 참여하지 않았어요',
      primaryClick: async () =>
        await api.post(`/lives/teacher/noShow/notice`, { id: live.id }),
    });

  const noShowStudentPopup = (live: Live) =>
    popupState.set({
      title: '학생이 수업에 \n참가하지 않았나요?',
      body: '학생이 오늘 수업에 참여하지 않았다면 노쇼처리를 하고 피드백에 해당 내용을 작성해주세요. ',
      secondBody:
        '*관리자 확인 후 해당 선생님에게 안내메세지가 전달되며, 학생의 수업 횟수권은 복원됩니다.',
      primaryText: '결석 처리하기',
      primaryClick: async () =>
        await api.post(`/lives/student/noShow/notice`, { id: live.id }),
    });

  const selectLevelPopup = () => selectLevelPopupState.set({ open: true });

  const selectLevelNoTestPopup = () =>
    selectLevelNoTestPopupState.set({ open: true });

  const requirePurchasePopup = () =>
    popupState.set({
      title: '무료 체험 신청하기',
      body: '‘수강 신청’ 탭에서 체험을 0원에 구매한 후 수업을 직접 예약해주세요.',
      primaryText: '체험 수업 0원에 구매하기',
      primaryClick: () => push('/pricing/trial'),
      secondaryText: '체험 없이 정규 수강권 바로 구매하기',
      secondaryClick: () => push('/pricing/regular'),
    });

  const addContractPopup = (
    primaryClick: () => void,
    secondaryClick: () => void
  ) =>
    popupState.set({
      title: '계약서 등록',
      body: 'PDF파일을 업로드해주세요',
      primaryText: '계약서 파일 업로드',
      primaryClick,
      secondaryText: '취소',
      secondaryClick,
    });

  return {
    requireLoginPopup,
    requireLevelTestPopup,
    skipLevelTestPopup,
    selectLevelPopup,
    selectLevelNoTestPopup,
    requireCoursePopup,
    requirePurchasePopup,
    addContractPopup,
    addStudentPopup,
    noShowTeacherPopup,
    noShowStudentPopup,
  };
}
