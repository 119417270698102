import React, { FC } from 'react';
import BgImg from '../../assets/img/main-bg-1.png';
import samImg1 from '../../assets/img/sam_img01.png';
import { ReactComponent as HashiCircle } from '../../assets/icons/icon-hash-circle.svg';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { FirstSection } from './lesson-introduction/FirstSection';
import { SecondSection } from './lesson-introduction/SecondSection';
import { isMobile } from 'react-device-detect';

interface TeacherIntroductionProps {}

export const TeacherIntroduction: FC<TeacherIntroductionProps> = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url('${BgImg}')` }}
        className="bg-center bg-cover bg-no-repeat con-pa relative"
      >
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center ">
            이중언어 선생님과 1:1 맞춤수업
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
            튼튼영어라이브쌤!
          </h2>
          <p className="text-15 md:text-xl text-black text-center sub-txt01 font-medium">
            처음 배우는 영어를 <br />더 따뜻하게, 1:1로 꼼꼼하게!
          </p>
        </ScrollAnimation>

        {/* <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="md:w-1/2 md:mx-auto mx-4 py-10"
        >
        <img src={mainImg1} className="" alt="" />
        </ScrollAnimation> */}

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className=""
          // duration={10}
        >
          <ul className="infocard_wrap">
            <InfoCard
              title01={'이중언어'}
              title02={'라이브쌤'}
              description={
                isMobile
                  ? '잘 이해가 안되는 부분은\n모국어로 쉽게!'
                  : '잘 이해가 안되는 부분은\n모국어로 쉽게!'
              }
            />
            <InfoCard
              title01={'아이를 잘 아는'}
              title02={'라이브쌤'}
              description={
                isMobile
                  ? '아이 성향에\n딱 맞는 1:1 맞춤수업'
                  : '아이 성향에\n딱 맞는 1:1 맞춤수업'
              }
            />
            <InfoCard
              title01={'영어 전문가'}
              title02={'라이브쌤'}
              description={
                isMobile
                  ? '검증된 초등영어교육 경력자,\n원어민 수준의 영어실력 보유'
                  : '검증된 초등영어교육 경력자,\n원어민 수준의 영어실력 보유'
              }
            />
            <InfoCard
              title01={'관리가 확실한'}
              title02={'라이브쌤'}
              description={
                isMobile
                  ? '수업 피드백은\n솔직하고 꼼꼼하게'
                  : '수업 피드백은\n솔직하고 꼼꼼하게'
              }
            />
            <img src={samImg1} className="sam_img01" alt="" />
          </ul>
        </ScrollAnimation>
      </div>

      {/* 어떤 라이브 쌤을 만나더라도 수업은 튼튼하게! */}
      <FirstSection />

      {/* '수업만' 하는 화상영어들과 튼튼영어라이브가 다른 이유! */}
      <SecondSection />
    </>
  );
};

interface InfoCardProps {
  title01?: string;
  title02?: string;
  description?: string;
}

export const InfoCard: FC<InfoCardProps> = ({
  title01,
  title02,
  description,
}) => {
  return (
    <li className="relative infocard_box whitespace-pre-line">
      <div className="absolute-center top-0">
        <HashiCircle />
      </div>
      <h2 className="lg:text-32 text-24">
        <p>{title01}</p>
        {title02}
      </h2>
      <span className="desc_wrap lg:text-md md:text-18 text-16 font-medium p-auto">
        {description}
      </span>
    </li>
  );
};

// interface InfoCardProps {
//   title: string;
//   description: string;
// }

// export const InfoCard: FC<InfoCardProps> = ({ title, description }) => {
//   return (
//     <div className="relative infocard_wrap">
//       <div className="absolute-center top-0">
//         <HashiCircle />
//       </div>

//       <div className="infobox text-center bg-white py-12 rounded-2xl md:h-52 h-auto">
//         <h2 className="lg:text-32 whitespace-pre-line text-24 py-2 ">{title}</h2>
//         <p className="lg:text-md md:text-18 text-16 whitespace-pre-line font-medium p-auto ">
//           {description}
//         </p>
//       </div>
//     </div>
//   );
// };
