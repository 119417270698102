import { useRef, useState } from 'react';

const tabs = [
  {
    id: 1,
    title: '체험수업',
  },
  {
    id: 2,
    title: '학습일반',
  },
  {
    id: 3,
    title: '서비스이용',
  },
  {
    id: 4,
    title: '기술지원',
  },
];

const faqs01 = [
  {
    id: 1,
    sub: '체험수업',
    tit: '체험수업 신청은 어떻게 하나요?',
    text: `튼튼영어라이브 사이트 회원가입 및 로그인 후, 수강신청 페이지에서 0원 결제해주세요.<br>
        이후 나의강의실에서 체험수업을 신청하실 수 있습니다.<br><br> 
        <span class=faq-bold><a href="https://youtu.be/JVZvV570d3s" target="_blank">[체험수업 신청 방법 영상으로 확인하기]</a></span>`,
  },
];

const faqs02 = [
  {
    id: 1,
    sub: '학습일반',
    tit: '교재(스토리북/워크북) 및 스마트토킹펜 구매는 필수인가요?',
    text: `튼튼영어라이브는 화상수업에 걸맞은 디지털 콘텐츠에 최적화되어있는 수업으로, 디지털 콘텐츠에는 e-book과 학습 액티비티가 모두 포함되어 있습니다.<br>
        교재(스토리북/워크북) 구매는 필수 옵션이 아니지만, 학습효과를 더욱 극대화하고 싶다면 구매를 추천합니다.<br>
        (특히 문법, 쓰기 교재가 포함된 3-4단계 수업의 경우 교재 구매를 더욱 추천드립니다.)<br>
        스마트토킹펜은 교재를 콕콕 찍으며 스토리, 챈트, 녹음 등 디지털 기기가 없어도 교재를 즐길 수 있는 보조 기구입니다.<br>
        교재(페이퍼북) 및 토킹펜 구매를 희망하시는 경우 수강권 구매 시 옵션을 선택하시면 됩니다.<br>
        * 라이브 전용 교재는 스토리북+워크북(연상력 버디)으로 구성되어 있으며, 구매하시는 횟수 상품에 맞게 교재가 배송됩니다.`,
  },
  {
    id: 2,
    sub: '학습일반',
    tit: '레벨테스트는 꼭 진행해야 하나요?',
    text: `레벨테스트 클릭 후 '레벨테스트 없이 바로 체험 수업 예약하기'를 선택하면 간단한 문진 선택으도 체험수업 및 정규수업 진행이 가능합니다.<br>
        다만 보다 정확한 실력진단 및 효과적인 수업 진행을 위해 레벨테스트를 권해드립니다.`,
  },
  {
    id: 3,
    sub: '학습일반',
    tit: '정규 커리큘럼 수강 시 교재는 자동으로 배정 되나요?',
    text: `레벨테스트 결과와 체험수업 진행 결과를 바탕으로 시작 단계와 교재가 배정됩니다.`,
  },
  {
    id: 4,
    sub: '학습일반',
    tit: '정규 커리큘럼 수강 중간에 교재 변경이 가능한가요?',
    text: `시작한 교재의 난이도가 맞지 않을 경우 상담을 통해 변경이 가능합니다. 변경을 원하실 하실 경우 채팅상담을 통해 문의주세요.`,
  },
  {
    id: 5,
    sub: '학습일반',
    tit: '정규 커리큘럼 단계별 학습 기간은 어느 정도인가요?',
    text: `<table class="faq_table faq_table_02">
              <tr class="color01">
                  <td>구분</td>
                  <td>프로그램 수</td>
                  <td>수업 수</td>
                  <td>권당 수업 횟수</td>
              </tr>
              <tr>
                  <td class="color01">1단계</td>
                  <td class="color02">7</td>
                  <td class="color02">54회</td>
                  <td rowspan="2" class="color02">1권 1회 수업</td>
              </tr>
              <tr>
                  <td class="color01">2단계</td>
                  <td class="color02">6</td>
                  <td class="color02">48회</td>
              </tr>
              <tr>
                  <td class="color01">3단계</td>
                  <td class="color02">6</td>
                  <td class="color02">84회</td>
                  <td rowspan="2" class="color02">1권 2회 수업</td>
              </tr>
              <tr>
                  <td class="color01">4단계</td>
                  <td class="color02">4</td>
                  <td class="color02">72회</td>
              </tr>
          </table>
          <table class="faq_table faq_table_03">
              <tr class="color01">
                  <td>1~4단계 수업 횟수</td>
                  <td>주 1회 수업 시</td>
                  <td>주 2회 수업 시</td>
              </tr>
              <tr class="color02">
                  <td>258회</td>
                  <td>5년 3개월</td>
                  <td>2년 6개월</td>
              </tr>
          </table>`,
  },
  {
    id: 6,
    sub: '학습일반',
    tit: '디지털콘텐츠 이용방법(예습/복습)',
    text: `튼튼영어라이브 디지털 학습 콘텐츠 이용방법은 아래 영상을 통해 자세하게 확인하실 수 있습니다.<br>
    <a href="https://youtu.be/W3wtrCOgZUk" target="_blank" class=faq-bold>[영상 확인하기]</a>`,
  },
  {
    id: 7,
    sub: '학습일반',
    tit: '미취학 아동(5세)도 수업 가능한가요?',
    text: `영어 학습의 특성상 수업 진행에 정해진 연령은 없습니다.<br> 
    화상수업에 잘 적응한다면 미취학 아동도 충분히 수업 진행이 가능합니다.`,
  },
  {
    id: 8,
    sub: '학습일반',
    tit: '영어를 처음 시작하는 아이도 할 수 있나요? ',
    text: `튼튼영어라이브는 억지로 단어를 외우거나 파닉스를 공부하도록 하기 보다는 스토리를 통해서 자연스럽게 단어의 음가를 익히고 알아가는 방식의 연상력 학습법을 사용해 영어를 처음 시작하는 아이도 충분히 학습이 가능합니다.<br><br>
          디지털 학습 콘텐츠로 예습 한 후, 주 1~2회 라이브 선생님과 만나 학습하는 과정을 기본으로 하며 교재 구매 시(별도판매) 더욱 완성도 있는 학습이 가능합니다.`,
  },
];

const faqs03 = [
  {
    id: 1,
    sub: '서비스이용',
    tit: '결제 취소 및 환불은 어떻게 하나요?',
    text: `1. 수강권의 구매 취소 및 환불은 결제일로부터 7일 이내 미사용 시에만 전액 환불이 가능합니다.<br><br>
        2. 수강권은 디지털 콘텐츠와 화상 수업이 결합된 상품으로써 둘 중 하나라도 이용을 했다면 둘 다 일부 사용으로 간주되어 환불이 불가합니다.<br><br>
        3. 수강권 별 별도로 표시된 환불 가능 유효기간 내 미사용 분에 대한 환불을 원하는 경우 아래와 같이 적용합니다.<br>
        1) 수강권만 구매 시<br>
        ● 환불금액 = 최종 결제금액 – 수강권 정가*수업횟수 – 사은품 또는 할인 금액<br>
        2) 수강권+옵션상품 구매 시<br>
        ● 환불금액 = 최종 결제금액 – 옵션상품 정가 – 수업권 정가*수업횟수 – 사은품 또는 할인 금액<br>
        ① 대상 상품의 유료 결제금액: 결제 시 사용한 포인트 및 할인쿠폰 금액을 제외한 순수 결제 금액<br>
        ② 옵션 상품 정가: 상품 제공사의 공식 소비자 가격을 기준으로 책정됩니다.<br>
        <span class=faq-bold>③ 정규 수강권 1개당 정가 : 30,000원</span><br>
        ④ 정규 커리큘럼 외 특강 수강권 1개의 정가는 판매 페이지에 고지된 금액을 기준으로 합니다.<br>
        ⑤ 각 회차 별 디지털 콘텐츠를 이미 학습한 경우 수업한 것으로 간주합니다.<br>
        ⑥ 회원이 수강료 반환 의사를 표시하지 아니하고 지난 기간은 수강 기간으로 계산되며, 환불 신청이 접수된 시점을 기준으로 환불을 진행합니다.<br><br>
        4. 옵션 상품의 환불<br>
        1) 구매자의 단순 변심에 의한 계약 철회의 경우 상품이 개봉되지 않은 상태여야 하고, 사은품이 포함된 경우 구매 제품과 함께 원상태로 반환해야 합니다. 사은품을 개봉한 경우 사은품의 소비자 가격을 공제한 후 환불합니다.<br>
        2) 구매자가 판매자의 책임 없는 사유로 제품을 교환 또는 반품하는 경우, 왕복 배송비를 포함한 물류비용은 구매자가 부담합니다.<br>
        [1박스당 가격 기준]<br>
        ① 최초 배송비(실비 등 포함): 교환/반품 배송비 5,000원<br>
        ② 회수 배송비(실비 등 포함): 교환/반품 배송비 5,000원<br>
        ③ 고객부담 왕복 배송비: 교환/반품 배송비 10,000원 (①+②)<br>
        *비고: 교환/반품 접수 시에는 판매자가 지정한 택배사를 이용해야 합니다.<br>
        제주 및 도서산간 지역의 경우 추가비용이 발생할 수 있습니다.<br>
        3) 계약의 철회는 웹사이트나 앱 내에서 구매취소나 환불을 요청했을 때 그 효력이 발생합니다.<br>`,
  },
  {
    id: 2,
    sub: '서비스이용',
    tit: '정해진 선생님과 일정기간 수업을 한 번에 예약하고 싶어요.',
    text: `튼튼영어라이브는 수업은 고객 직접 예약제입니다.<br>
        같은 선생님 또는 같은 시간으로 수업을 일정하게 듣고 싶으신 경우 예약 페이지에서 매달 15일 오후에 열리는 선생님들의 약 두 달치 스케줄을 보시고 한 번에 직접 예약해주시면 됩니다.<br>
        선생님에 따라 스케줄 확정 시간대가 일부 상이할 수 있습니다.<br>
        또한 15일이 주말 또는 공휴일인 경우 전 영업일에 스케줄을 오픈합니다.<br>
        * 스케줄 오픈 예시: 5월 15일 오후에 6월, 7월 두 달 간의 수업 스케줄 확정 및 오픈 (만약 15일이 토요일이라면 14일에 스케줄 오픈)`,
  },
  {
    id: 3,
    sub: '서비스이용',
    tit: '수강권을 구매했는데 수업 예약이 불가합니다.',
    text: `수강권 변경을 아래 방법을 통해 진행해 주세요.<br>
        ① 로그인 → 나의 학습 → 나의 강의실에서 좌측 레벨 표시 부분 아래 수강권 쪽 화살표 클릭/탭<br> 
        ② 화살표 클릭 시 현재 보유 및 활성화가 된 수강권 확인할 수 있습니다.<br>
        ③ 원하는 수강권을 선택하고 바뀌는 것을 확인한 후 수업 예약을 진행해 주세요.<br>`,
  },
  {
    id: 4,
    sub: '서비스이용',
    tit: '수강권 유효기간은 어떻게 되나요?',
    text: `각 상품별 유효기간을 안내드립니다.<br>
        <table class="faq_table faq_table_01">
            <tr class="color01">
                <td>구분</td>
                <td>횟수<br />(단위/개)</td>
                <td>유효기간<br />(단위/주)</td>
            </tr>
            <tr>
                <td rowspan="3" class="color01">정규</td>
                <td class="color02">12</td>
                <td class="color02">20</td>
            </tr>
            <tr class="color02">
                <td>24</td>
                <td>40</td>	
            </tr>
            <tr class="color02">
                <td>48</td>
                <td>70</td>	
            </tr>
            <tr>
                <td rowspan="3" class="color01">특강<br/>스피킹</td>
                <td class="color02">4</td>
                <td class="color02">6</td>
            </tr>
            <tr class="color02">
                <td>8</td>
                <td>12</td>	
            </tr>
            <tr class="color02">
                <td>12</td>
                <td>18</td>	
            </tr>
        </table>
        유효기간은 첫 수업 예약일 또는 <span class=faq-bold>디지털 학습콘텐츠 첫 열람</span> 시부터 적용됩니다.<br>`,
  },
  {
    id: 5,
    sub: '서비스이용',
    tit: '예약된 수업을 취소하고 싶어요.',
    text: `예약된 수업을 취소하는 것은 수업이 있는 전일까지 가능하며 이 경우 취소한 수강권은 복구됩니다. 다만 수업 당일취소 시 수강권이 복구되지 않습니다.<br> 
        수업을 예약한 후 회원은 수업 예약 전날 23시 59분 59초 이전에 예약을 취소할 수 있으며, 수업 당일 0시가 넘은 경우 예약은 취소할 수 있으나 수강권은 복구되지 않습니다.<br>
        *다만, 회원의 입원, 가족상(喪) 및 이에 준하는 상황으로 수업이 불가능하여 당일 취소하는 경우 증빙자료제출 및 확인을 통해 수강권을 복구할 수 있습니다.`,
  },
  {
    id: 6,
    sub: '서비스이용',
    tit: '스토리북/워크북 혹은 스마트토킹펜 A/S신청은 어떻게 하나요?',
    text: `-스토리북/워크북의 불량의 경우 수령 후 1:1 채팅 상담을 통해 접수해 주시면 빠르게 교환해 드리겠습니다.<br>
        -스마트토킹펜은 하단 연락처를 통해 A/S를 신청해 주세요.<br>
        업체명 : 시노텍 A/S<br>
        전화 : 031-745-1472<br>
        스마트토킹펜의 경우 증상에 따라서 A/S 접수 시 유선으로 해결되는 건도 있고, 끝내 해결되지 않는 경우 택배수거 후 수리하는 방식으로 진행됩니다.<br>
        학습에 여유 있는 일정에 위 번호로 전화상담 후 안내에 따라 A/S 진행하시면 됩니다.<br>`,
  },
  {
    id: 7,
    sub: '서비스이용',
    tit: '당일 취소(결석) 시 해당 날짜의 교재는 수업이 불가한가요?',
    text: `결석일에 학습예정이었던 교재는 홀딩되며 이후 예약 시 해당 수업 교재로 재배정됩니다.`,
  },
  {
    id: 8,
    sub: '서비스이용',
    tit: '보강권 구매가 가능한가요?',
    text: `결석으로 수업을 진행하지 못한 경우에만 보강권 사용이 가능합니다.<br>
    보강권 구매를 원하실 하실 경우 채팅상담을 통해 문의주세요.`,
  },
  {
    id: 9,
    sub: '서비스이용',
    tit: '레벨테스트를 다시 볼 수 있나요?',
    text: `네, 가능합니다. 레벨테스트 다시 보기를 원하실 경우 채팅상담을 통해 문의주세요.`,
  },
  {
    id: 10,
    sub: '서비스이용',
    tit: '정규 커리큘럼 수강 시 별도로 구매한 교재(스토리북, 워크북)가 토킹펜 지원이 되지 않아요.',
    text: `튼튼영어라이브 정규 커리큘럼 일부 프로그램의 교재(스토리북/워크북)는 스마트토킹펜 적용이 불가합니다.<br>
          해당 교재들은 교재의 QR코드와 예습콘텐츠, 나의 도서관을 통해 학습해주세요.<br>
          <br>
          1단계<br>
          Sounds at Work : 기초 파닉스 익히기<br>
          Reading Sight Words : 초등 저학년이 꼭 알아야 할 Sight Words 115개 익히기<br>
          <br>
          2단계<br>
          Sounds Factory : 스토리 속에서 각 음가의 소리를 익히며 초등 파닉스 완성하기<br>
          <br>
          3단계<br>
          What to Say : 연상력 읽고 쓰기 집중 훈련을 통해 어순 감각 구체화. 창작 쓰기 시작, 중학 기본 문법 완성<br>
          Grammar at Work : 대화문 속에서 품사와 문장 구조 중심으로 기본 문법 습득`,
  },
];

const faqs04 = [
  {
    id: 1,
    sub: '기술지원',
    tit: '수업 진행시 어떤 기기와 브라우저를 이용해야 하나요?',
    text: `튼튼영어라이브의 디지털학습과 수업은 PC 및 안드로이드 기반 기기, 크롬 브라우저에 최적화되어 있습니다.<br><br>

        PC/노트북을 사용하여 크롬 브라우저로 서비스를 이용하시는 게 가장 좋은 방법이며,<br>
        안드로이드 기반 모바일/태블릿을 이용하는 경우 크롬 어플을 최신 버전으로 업데이트하여 이용해 주세요.<br><br>
        
        * 카메라와 마이크가 탑재되지 않은 기기로는 수업이 불가합니다.<br>
        * 디지털학습을 제외한 화상수업의 경우 아이패드에서도 진행이 가능하나 안정적인 사용을 위해서는 권장 기기를 이용해 주세요.<br>
        * 네이버앱, 카카오앱/삼성인터넷/사파리 등 크롬 어플이 아닌 다른 인터넷 브라우저를 이용하는 경우 학습이 제한될 수 있습니다.<br>
        ※ 기기 준비가 완료되었다면, 수업 전 '수업환경 테스트'를 진행해 카메라, 마이크가 잘 작동하는지 확인해 주세요.`,
  },
  {
    id: 2,
    sub: '기술지원',
    tit: '수업 중 화면이나 음성이 끊기는 경우엔 어떻게 하나요?',
    text: `1. 적합한 기기와 브라우저로 수업을 진행 중인지 확인해 주세요.<br>
        <span class=faq-bold>- 수업에 적합한 기기: 카메라와 마이크를 탑재한 기기</span><br>
        - 수업 가능 브라우저: 크롬<br><br>
        2. 크롬 업데이트를 진행해 주세요.<br>
        <span class=faq-bold>- 크롬 업데이트하기 <a href="https://www.google.co.kr/chrome/" target="_blank">[바로가기]</a></span><br><br>
        3. 캐시 삭제를 진행해 주세요.<br>
        크롬 창 오른쪽 상단 점 3개 부분 클릭 > 설정 > 돋보기 있는 부분에 '캐시' 검색 > 인터넷 사용 기록 삭제 클릭 > 캐시된 이미지 및 파일 체크 후 삭제 이 순으로 진행해 주시면 됩니다.<br><br>
        <span class=faq-bold>4. 마지막으로 컴퓨터 재부팅 후 '나의강의실' 재입장을 통해 프로그램을 재실행해 보세요.</span><br><br>
        위 방법들로 문제가 개선되지 않을 경우 1:1 채팅 상담으로 문의주시면 도움을 드리도록 하겠습니다.<br>`,
  },
];

const AccordionItem = (props: any) => {
  const contentEl: any = useRef();
  const { handleToggle, active, faq } = props;
  const { sub, id, text, tit } = faq;

  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div
          className={`rc-accordion-toggle ${active === id ? 'active' : ''}`}
          onClick={() => handleToggle(id)}
        >
          <a className="rc-accordion-sub">[{sub}]</a>
          <a className="rc-accordion-tit">{tit}</a>
          <a className="fa xi-angle-down rc-accordion-icon"></a>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`rc-collapse ${active === id ? 'show' : ''}`}
        style={
          active === id
            ? { height: contentEl.current?.scrollHeight }
            : { height: '0px' }
        }
      >
        <div className="rc-accordion-body">
          <p className="mb-0" dangerouslySetInnerHTML={{ __html: text }}></p>
        </div>
      </div>
    </div>
  );
};

export const FaqPage = () => {
  const [active, setActive] = useState(null);
  const [tab, setTab] = useState(tabs[0]);

  const handleToggle = (index: any) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <>
      <div className="faq-wrap">
        <div className="faq-tabs-wrap">
          {tabs.map((s) => (
            <button
              className={`${
                s === tab && s.id === 1
                  ? 'faq-tabs-active'
                  : s === tab && s.id === 2
                  ? 'faq-tabs-active'
                  : s === tab && s.id === 3
                  ? 'faq-tabs-active'
                  : s === tab && s.id === 4
                  ? 'faq-tabs-active'
                  : 'faq-tabs-text'
              }`}
              onClick={() => {
                setTab(s);
                setActive(null);
              }}
            >
              {s.title}
            </button>
          ))}
        </div>
        {tab === tabs[0] && (
          <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-md-8 mt-2">
                <div className="accordion-wrap">
                  <div className="card-body">
                    {faqs01.map((faq, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          active={active}
                          handleToggle={handleToggle}
                          faq={faq}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === tabs[1] && (
          <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-md-8 mt-2">
                <div className="accordion-wrap">
                  <div className="card-body">
                    {faqs02.map((faq, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          active={active}
                          handleToggle={handleToggle}
                          faq={faq}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === tabs[2] && (
          <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-md-8 mt-2">
                <div className="accordion-wrap">
                  <div className="card-body">
                    {faqs03.map((faq, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          active={active}
                          handleToggle={handleToggle}
                          faq={faq}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {tab === tabs[3] && (
          <div className="container-fluid mt-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-md-8 mt-2">
                <div className="accordion-wrap">
                  <div className="card-body">
                    {faqs04.map((faq, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          active={active}
                          handleToggle={handleToggle}
                          faq={faq}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
