import {
  PaymentParamDto,
  PaymentReinforceParamDto,
  PaymentSpecialParamDto,
  PaymentTrialParamDto,
} from '../../types';
import { api } from '../axios';
import { OrderCompleteDto, PaymentStartDto } from './payment.api.types';

export const postPaymentStart = (data: PaymentParamDto) =>
  api.post<PaymentStartDto>(`payments/start`, data);

export const postReinforcePaymentStart = (data: PaymentReinforceParamDto) =>
  api.post<PaymentStartDto>(`payments/reinforce/start`, data);

export const postTrialPaymentStart = (data: PaymentTrialParamDto) =>
  api.post<PaymentStartDto>(`payments/trial/start`, data);

export const postSpecialPaymentStart = (data: PaymentSpecialParamDto) =>
  api.post<PaymentStartDto>(`payments/special/start`, data);

export const postConfirmPayments = (data: any) =>
  api.post<OrderCompleteDto>(`payments/confirm`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
