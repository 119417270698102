import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../src/plugins/bridge';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';

const client = new QueryClient();

Sentry.init({
  dsn: 'https://1b399a4056e246d483cf892f88a72ccf@o1200930.ingest.sentry.io/6325125',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.3,
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
