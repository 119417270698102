import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Book, Level, Paginated, Series } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminSeriesPopUp } from '../components/AdminSeriesPopUp';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';

export const Books = () => {
  const { push } = useHistory();
  const [getLevels, setGetLevels] = useState<any>();
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [selectedSeries, setSelectedSeries] = useState<Series>();
  const [seriesToEdit, setSeriesToEdit] = useState<Series>();

  // levels
  const levelQuery = useQueryString({
    sort: { index: 'ASC' },
    join: ['serieses'],
  });
  const { data: levels, refetch: refetchLevels } = useQuery<Paginated<Level>>(
    `/admin/levels${levelQuery}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    const bookLevels = localStorage.getItem('bookLevels');
    if (levels?.items.length === 0 && bookLevels) {
      const parseBookLevels = JSON.parse(bookLevels);
      setGetLevels(parseBookLevels);
    }
    const bookSelectLevel = localStorage.getItem('bookSelectedLevel');
    if (bookSelectLevel) {
      setSelectedLevel(JSON.parse(bookSelectLevel));
    } else {
      localStorage.setItem('bookLevels', JSON.stringify(levels));
      setSelectedLevel(levels?.items[0]);
    }
  }, [levels]);

  useEffect(() => {
    const bookSelectSeries = localStorage.getItem('bookSelectedSeries');
    if (bookSelectSeries) {
      setSelectedSeries(JSON.parse(bookSelectSeries));
    } else {
      setSelectedSeries(selectedLevel?.serieses[0]);
    }
  }, [selectedLevel]);

  // books
  const bookQuery = useQueryString({
    filter: { seriesId: selectedSeries?.id },
    sort: { index: 'ASC' },
  });
  const { data: books } = useQuery<Paginated<Book>>(
    `/admin/books${bookQuery}`,
    fetcher,
    { enabled: !!selectedSeries, keepPreviousData: true }
  );

  return (
    <>
      <AdminH1>수업 컨텐츠 관리</AdminH1>

      <div className="flex justify-between items-center">
        <AdminTabs>
          {getLevels
            ? getLevels?.items?.map((level: any) => (
                <AdminTab
                  key={level.id}
                  text={level.name}
                  selected={level.id === selectedLevel?.id}
                  onClick={() => {
                    refetchLevels();
                    localStorage.setItem(
                      'bookSelectedLevel',
                      JSON.stringify(level)
                    );
                    setSelectedLevel(level);
                  }}
                />
              ))
            : levels?.items.map((level) => (
                <AdminTab
                  key={level.id}
                  text={level.name}
                  selected={level.id === selectedLevel?.id}
                  onClick={() => {
                    refetchLevels();
                    localStorage.setItem('bookLevels', JSON.stringify(levels));
                    localStorage.setItem(
                      'bookSelectedLevel',
                      JSON.stringify(level)
                    );
                    setSelectedLevel(level);
                  }}
                />
              ))}
        </AdminTabs>

        {!process.env.REACT_APP_DISABLED && (
          <Button
            text="추가"
            to={'/admin/books/add'}
            className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
          />
        )}
      </div>

      <div className="flex items-start space-x-4">
        <AdminCard className="px-6 py-4">
          <div className="mb-4 flex justify-between items-center space-x-8">
            <h3 className="font-bold text-18">시리즈 관리</h3>
            {!process.env.REACT_APP_DISABLED && (
              <button
                className="px-4 py-2 rounded bg-gray-50"
                onClick={() =>
                  setSeriesToEdit({ levelId: selectedLevel?.id } as Series)
                }
              >
                추가
              </button>
            )}
          </div>

          {selectedLevel?.serieses.map((series) => (
            <div
              key={series.id}
              className="flex justify-between items-center my-1 cursor-pointer"
              onClick={() => {
                localStorage.setItem(
                  'bookSelectedSeries',
                  JSON.stringify(series)
                );
                setSelectedSeries(series);
                push('/admin/books?page=1&limit=10');
              }}
            >
              <span
                className={`text-15 ${
                  series.id === selectedSeries?.id
                    ? 'font-bold'
                    : 'text-gray-500'
                }`}
              >
                {series.name}
              </span>
              {!process.env.REACT_APP_DISABLED && (
                <button
                  className="text-12 text-gray-500 hover:text-gray-800"
                  onClick={() => setSeriesToEdit(series)}
                >
                  수정
                </button>
              )}
            </div>
          ))}
        </AdminCard>

        <AdminCard className="flex-1">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>타이틀</Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {books?.items.map((book) => (
                <Table.Row key={book.id}>
                  <Table.Td>{book.title}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/books/${book.id}`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Pagination>
            <Pagination.Label
              count={books?.items.length ?? 0}
              total={books?.total ?? 0}
            />
            <Pagination.Nav basePath="/admin/books" total={books?.total ?? 0} />
          </Pagination>
        </AdminCard>
      </div>

      {seriesToEdit && (
        <AdminSeriesPopUp
          key={seriesToEdit.id}
          series={seriesToEdit}
          onClose={() => {
            setSeriesToEdit(undefined);
            refetchLevels();
          }}
        />
      )}
    </>
  );
};
