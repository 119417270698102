import React, { FC } from 'react';
import { ReactComponent as LockIcon } from '../assets/svg/library-lock.svg';
import { apiUrl } from '../plugins/axios';
import { CM_OP_ERROR, fn_openCornerMap } from '../plugins/open_cm';
import { studentState, tokenState } from '../plugins/ridge';
import { Book } from '../types';
import { Button } from './Button';
import { H6 } from './H6';
import { min } from 'lodash';
import { VideoLibraryPopup } from './LibraryVideoPopup';

interface LibraryCardProps {
  book: Book;
  locked?: boolean;
}

export const LibraryCard: FC<LibraryCardProps> = ({ book, locked }) => {
  const student = studentState.useValue();

  const isNotIncludeBook =
    book.id === 64 ||
    book.id === 65 ||
    book.id === 69 ||
    book.id === 73 ||
    book.id === 146 ||
    book.id === 147 ||
    book.id === 148 ||
    book.id === 149 ||
    book.id === 150 ||
    book.id === 151 ||
    book.id === 164 ||
    book.id === 165 ||
    book.id === 166 ||
    book.id === 167 ||
    book.id === 168 ||
    book.id === 169;

  const openBluegaLesson = async (name: string, bookUrl: string) => {
    if (name === 'Worksheet') {
      window.location.href = bookUrl;
      return;
    }

    // // 스피킹 교재만 스토리 팝업 형식으로 나오게
    // if (book.seriesId === 25 && name === 'Story') {
    //   if (setVideo)
    //     setVideo({ open: true, title: book.title, videoPath: bookUrl });
    //   return;
    // }

    fn_openCornerMap(
      '/cornermap/index.html',
      {
        authToken: tokenState.get(),
        studentId: student?.id,
        uploadUrl: bookUrl,
        directMode: book.talk !== bookUrl ? 'REVIEW' : 'ANSWER',
        corners: [
          {
            ActiveID:
              book.talk === bookUrl ? '9' : book.story === bookUrl ? '2' : '1',
            url: bookUrl,
          },
        ],
      },
      ({ op, data }: any) => {
        if (!data) {
          window.location.reload();
        }
        if (op === CM_OP_ERROR) {
          alert('새로고침 후 다시 시도해주세요!');
        }
      }
    );
  };

  return (
    <div className="flex flex-col w-full space-y-2">
      <div className="w-full relative ">
        <div className="w-fit h-fit rounded-lg overflow-hidden">
          {locked && (
            <div className="bg-littleblack z-10 absolute inset-0 grid place-content-center rounded-lg">
              <LockIcon />
            </div>
          )}
          <img
            src={apiUrl(book?.cover)}
            alt={book.title}
            className="w-fit h-fit object-center object-cover"
          />
        </div>
      </div>
      <H6 className="py-2">
        {book.index}. {book.title}
      </H6>
      {book.bookItems
        .sort((x, y) => x.index - y.index)
        .map((item) => {
          return item.name ? (
            <Button
              text={item.name}
              className="bg-secondary-1 text-primary-1 font-bold"
              onClick={() => openBluegaLesson(item.name, item.url)}
              disabled={locked}
            />
          ) : null;
        })}

      {/* <Button
        text="Story"
        className="bg-secondary-1 text-primary-1 font-bold"
        onClick={() => openBluegaLesson(book.story)}
        disabled={locked}
      />

      {book.seriesId === 14 || book.seriesId === 18 ? (
        ''
      ) : book.seriesId === 15 || book.seriesId === 17 ? (
        <Button
          text="Lecture"
          className="bg-secondary-1 text-primary-1 font-bold"
          onClick={() => openBluegaLesson(book.lecture)}
          disabled={locked}
        />
      ) : book.song ? (
        <Button
          text="Song"
          className="bg-secondary-1 text-primary-1 font-bold"
          onClick={() => openBluegaLesson(book.song)}
          disabled={locked}
        />
      ) : isNotIncludeBook ? (
        ''
      ) : (
        <Button
          text="Song"
          className="bg-secondary-1 text-primary-1 font-bold"
          disabled={locked}
        />
      )}

      <Button
        text="Talk +"
        className={`${
          !locked && 'border-primary-1 border'
        }  text-primary-1 font-bold`}
        onClick={() => openBluegaLesson(book.talk)}
        disabled={locked}
      /> */}
    </div>
  );
};
