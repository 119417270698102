import React, { FC, useState } from 'react';
import { Section } from '../../../components/Section';
import { steps } from '../images';
import { useHistory } from 'react-router-dom';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../components/icons';
import character from '../../../assets/img/character3.png';
import { useAuth } from '../../../hooks';
import { usePopup } from '../../../components/PopUp';
import { studentState, testResultState } from '../../../plugins/ridge';
interface SecondSectionProps {
  firstTitle?: string;
  secondTitle?: string;
  firstDescription?: string;
  secondDescription?: string;
}

export const SecondSection: FC<SecondSectionProps> = ({
  firstTitle = "'수업만' 하는 화상영어들과",
  secondTitle = '튼튼영어라이브가 다른 이유!',
  firstDescription = '확실한 성과를 만들어내는 5년의 탄탄한 커리큘럼',
  secondDescription,
}) => {
  const [step, setStep] = useState(steps[0]);
  // const [showContent, setShowContent] = useState(false);
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLoginPopup, requireLevelTestPopup } = usePopup();
  const testResult = testResultState.useValue();

  const handleLevelTestClick = () => {
    const student = studentState.get();
    if (!authenticated) return requireLoginPopup();
    // if (students?.length === 0) return addStudentPopup();
    if (!testResult || !student?.isTested)
      return requireLevelTestPopup(student?.id);
    push('/level-test/result');
  };

  const handlelibraryClick = () => {
    if (!authenticated) return requireLoginPopup('/library');
    // if (!testResult || !student?.isTested) return requireLevelTestPopup();
    push('/library');
  };

  return (
    <div className="md:max-w-container max-w-full con-pa">
      <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
          {firstTitle}
        </h2>
        <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
          {secondTitle}
        </h2>
        <p className=" md:text-xl text-black text-center font-medium sub-txt01">
          {firstDescription}
          {secondDescription && (
            <>
              <br />
              {secondDescription}
            </>
          )}
        </p>
      </ScrollAnimation>

      {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        {steps.map((s) => (
          <div
            className={`${
              s !== step && 'hidden'
            } max-w-container w-full h-full`}
          >
            <img
              src={`${s === step && s.graphImg}`}
              alt=""
              className="mx-auto"
            />tep
          </div>
        ))}{' '}
      </ScrollAnimation> */}

      <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        {/* PC용 */}
        <div className="md:block hidden w-full md:max-w-7xl md:mx-auto F-tab-tab">
          <div
            className="flex gap-5 justify-center rounded-full xl:mx-48 lg:mx-32 md:mx-24 items-center my-10 "
            style={{ backgroundColor: '#F2F4F6' }}
          >
            {steps.map((s) => (
              <button
                className={`${
                  s === step && s.id === 1
                    ? 'filled-primary-3'
                    : s === step && s.id === 2
                    ? 'filled-primary-5'
                    : s === step && s.id === 3
                    ? 'filled-primary-6'
                    : s === step && s.id === 4
                    ? 'filled-primary-4'
                    : 'text-gray-200'
                } text-2xl text-center py-2 w-full rounded-full`}
                onClick={() => setStep(s)}
              >
                {s.title}
              </button>
            ))}
          </div>
        </div>

        {/* 모바일용 */}
        <div className="block md:hidden w-full F-tab-tab">
          <div className="flex justify-between md:space-x-5 space-x-12 mx-6 py-8 ">
            <button
              className="p-2.5 border border-gray-100 rounded-full "
              onClick={() => setStep(steps[step.id < 2 ? 3 : step.id - 2])}
            >
              <ChevronLeftIcon className="text-gray-300 md:wh-7 wh-6" />
            </button>
            <button
              className={`${
                step.id === 1
                  ? 'filled-primary-3'
                  : step.id === 2
                  ? 'filled-primary-5'
                  : step.id === 3
                  ? 'filled-primary-6'
                  : step.id === 4
                  ? 'filled-primary-8'
                  : 'text-gray-200'
              } md:text-2xl text-17 text-center py-2 w-full rounded-full`}
            >
              {steps[step.id - 1].title}
            </button>
            <button
              className="p-2.5 border border-gray-100 rounded-full"
              onClick={() => setStep(steps[step.id > 3 ? 0 : step.id])}
            >
              <ChevronRightIcon className="text-primary-1 md:wh-7 wh-6" />
            </button>
          </div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        {step === steps[0] && (
          <Section
            style={{ backgroundColor: '#FFF9D9' }}
            className="relative lg:rounded-2xl mx-0 rounded-none p-12 grid place-content-center w-full md:max-w-5xl md:mx-auto F-tab-wrap"
          >
            <h1
              className="md:text-24 text-14 text-center"
              // style={{ color: ' #FFDC0F' }}
            >
              영어 소리에 친숙해지고, 기초 파닉스,{' '}
              <br className="md:hidden block" />
              사이트 워드를 익히는 단계입니다.
            </h1>
            <div
              className="hidden md:grid grid-cols-4 w-full justify-items-center absolute px-20"
              style={{ top: '-18px' }}
            >
              <div
                style={{ borderBottomColor: ' #fff9d9' }}
                className="bubble-arrow "
              />
            </div>
            <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium w_box_txt">
              <li>
                영어의 소리와 리듬에 집중하여 듣고 영어 소리에 친숙해집니다.
              </li>
              <li>일상생활의 회화 표현을 익힙니다.</li>
              <li>기초 파닉스를 익혀 문자와 소리의 규칙을 알게 됩니다.</li>
              <li>파닉스 규칙을 활용하여 문장을 읽을 수 있습니다.</li>
            </ul>

            {/* <button
              className="mx-auto my-6"
              onClick={() => setShowContent(!showContent)}
            >
              <ChevronUpIcon
                className={`${
                  showContent ? '' : 'rotate-180'
                } transform transition-transform`}
              />
            </button>

            {showContent && (
              <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium">
                <li>
                  영어의 소리와 리듬에 집중하여 듣고 영어 소리에 친숙해집니다.
                </li>
                <li>일상생활의 회화 표현을 익힙니다.</li>
                <li>기초 파닉스를 익혀 문자와 소리의 규칙을 알게 됩니다.</li>
                <li>파닉스 규칙을 활용하여 문장을 읽을 수 있습니다.</li>
              </ul>
            )} */}
            <div className="flex -space-x-3 md:space-x-10 mx-auto">
              {[
                { smallText: '1주 권장 수업 횟수', bigText: '1회' },
                { smallText: '총 수업', bigText: '54회' },
                { smallText: '총 교재', bigText: '54권' },
              ].map((item) => (
                <div className="text-white wh-32 md:w-48 md:h-48 rounded-full bg-primary-3 flex items-center justify-center">
                  <div className="text-center mx-auto">
                    <p className="font-medium text-sm md:text-lg">
                      {item.smallText}
                    </p>
                    <p className="md:text-4xl text-2xl ">{item.bigText}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-10 flex justify-center">
              <button
                className=" content-center font-black F-tab-btn01"
                onClick={handlelibraryClick}
                style={{ backgroundColor: ' #FFDC0F' }}
              >
                대표교재 보기
                <span
                // style={{ color: ' #FFDC0F' }}
                >
                  Click
                </span>
              </button>
            </div>
          </Section>
        )}

        {step === steps[1] && (
          <Section
            style={{ backgroundColor: '#FFEDF2' }}
            className="relative lg:rounded-2xl mx-0 rounded-none p-12 grid place-content-center w-full md:max-w-5xl md:mx-auto F-tab-wrap"
          >
            <h1
              className="md:text-24 text-14 text-center"
              // style={{ color: ' #FFA5BE' }}
            >
              초등 기본 문형을 익히고 파닉스를 완성하여{' '}
              <br className="md:hidden block" />
              짧은 글을 혼자 읽을 수 있습니다.
            </h1>
            <div
              className="hidden md:grid grid-cols-4 w-full justify-items-center absolute px-20"
              style={{ top: '-18px' }}
            >
              <div
                style={{ borderBottomColor: ' #FFEDF2' }}
                className="bubble-arrow col-start-2"
              />
            </div>

            <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2  list-disc font-medium w_box_txt">
              <li>실생활 표현들을 익히고, 연상하여 말할 수 있습니다.</li>
              <li>
                자음, 모음, 이중모음, 이중자음의 음가를 익혀서 파닉스를
                완성합니다.
              </li>
              <li>200~300단어의 글을 읽을 수 있는 실력이 길러집니다.</li>
            </ul>
            {/* <button
              className="mx-auto my-6"
              onClick={() => setShowContent(!showContent)}
            >
              <ChevronUpIcon
                className={`${
                  showContent ? '' : 'rotate-180'
                } transform transition-transform`}
              />
            </button>

            {showContent && (
              <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2  list-disc font-medium">
                <li>실생활 표현들을 익히고, 연상하여 말할 수 있습니다.</li>
                <li>
                  자음, 모음, 이중모음, 이중자음의 음가를 익혀서 파닉스를
                  완성합니다.
                </li>
                <li>200~300단어의 글을 읽을 수 있는 실력이 길러집니다.</li>
              </ul>
            )} */}

            <div className="flex -space-x-3 md:space-x-10 mx-auto">
              {[
                { smallText: '1주 권장 수업 횟수', bigText: '1회' },
                { smallText: '총 수업', bigText: '48회' },
                { smallText: '총 교재', bigText: '48권' },
              ].map((item) => (
                <div className="text-white wh-32 md:w-48 md:h-48 rounded-full bg-primary-5 flex items-center justify-center">
                  <div className="text-center mx-auto">
                    <p className="font-medium text-sm md:text-lg">
                      {item.smallText}
                    </p>
                    <p className="md:text-4xl text-2xl ">{item.bigText}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-10 flex justify-center">
              <button
                className=" content-center font-black F-tab-btn01"
                onClick={handlelibraryClick}
                style={{ backgroundColor: ' #FFA5BE' }}
              >
                대표교재 보기
                <span
                // style={{ color: ' #FFA5BE' }}
                >
                  Click
                </span>
              </button>
            </div>
          </Section>
        )}

        {step === steps[2] && (
          <Section
            style={{ backgroundColor: '#E8F7FF' }}
            className="relative mx-0 lg:rounded-2xl rounded-none p-12 grid place-content-center w-full md:max-w-5xl md:mx-auto F-tab-wrap"
          >
            <h1
              className="md:text-24 text-14 text-center"
              // style={{ color: ' #8CD7FF' }}
            >
              기초문법과 문장의 구조를 익히고, 짧은 글쓰기가 가능하고,{' '}
              <br className="md:hidden block" />
              다양한 소재의 글을 독해할 수 있습니다.
            </h1>
            <div
              className="hidden md:grid grid-cols-4 w-full justify-items-center absolute px-20"
              style={{ top: '-18px' }}
            >
              <div
                style={{ borderBottomColor: ' #E8F7FF' }}
                className="bubble-arrow col-start-3"
              />
            </div>

            <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium w_box_txt">
              <li>일상생활의 표현들을 자연스럽게 말할 수 있습니다.</li>
              <li>
                8품사의 개념을 익히고 영어 문장의 구조를 파악할 수 있습니다.
              </li>
              <li>
                기본 문형을 활용하여 문장을 쓰고 300~400단어의 글을 읽고 이해할
                수 있습니다.
              </li>
            </ul>
            {/* <button
              className="mx-auto my-6"
              onClick={() => setShowContent(!showContent)}
            >
              <ChevronUpIcon
                className={`${
                  showContent ? '' : 'rotate-180'
                } transform transition-transform`}
              />
            </button>

            {showContent && (
              <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium">
                <li>일상생활의 표현들을 자연스럽게 말할 수 있습니다.</li>
                <li>
                  8품사의 개념을 익히고 영어 문장의 구조를 파악할 수 있습니다.
                </li>
                <li>
                  기본 문형을 활용하여 문장을 쓰고 300~400단어의 글을 읽고
                  이해할 수 있습니다.
                </li>
              </ul>
            )} */}
            <div className="flex -space-x-3 md:space-x-10 mx-auto">
              {[
                { smallText: '1주 권장 수업 횟수', bigText: '2회' },
                { smallText: '총 수업', bigText: '84회' },
                { smallText: '총 교재', bigText: '42권' },
              ].map((item) => (
                <div className="text-white wh-32 md:w-48 md:h-48 rounded-full bg-primary-6 flex items-center justify-center">
                  <div className="text-center mx-auto">
                    <p className="font-medium text-sm md:text-lg">
                      {item.smallText}
                    </p>
                    <p className="md:text-4xl text-2xl ">{item.bigText}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-10 flex justify-center">
              <button
                className=" content-center font-black F-tab-btn01"
                onClick={handlelibraryClick}
                style={{ backgroundColor: '#8CD7FF' }}
              >
                대표교재 보기
                <span
                // style={{ color: ' #8CD7FF' }}
                >
                  Click
                </span>
              </button>
            </div>
          </Section>
        )}

        {step === steps[3] && (
          <Section
            style={{ backgroundColor: '#FFEDE5' }}
            className="relative mx-0 lg:rounded-2xl rounded-none p-12 grid place-content-center w-full md:max-w-5xl md:mx-auto F-tab-wrap"
          >
            <h1
              className="md:text-24 text-14 text-center"
              // style={{ color: ' #FF8C58' }}
            >
              중학 문법을 학습하여 심화된 독해를 하고,{' '}
              <br className="md:hidden block" />
              자신의 생활과 관련된 글쓰기를 합니다.
            </h1>
            <div
              className="hidden md:grid grid-cols-4 w-full justify-items-center absolute px-20"
              style={{ top: '-18px' }}
            >
              <div
                style={{ borderBottomColor: ' #FFEDE5' }}
                className="bubble-arrow col-start-4"
              />
            </div>

            <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium w_box_txt">
              <li>문장의 구조를 파악하며 문법에 맞게 말할 수 있습니다.</li>
              <li>
                문법개념과 문제 풀이를 통해 중학 문법의 기본을 완성할 수
                있습니다.
              </li>
              <li>
                400~550단어의 픽션, 논픽션 글을 읽으며 독해 실력을 기를 수
                있습니다.
              </li>
              <li>
                자신의 생활과 관련된 글 쓰기(편지, 일기, 이메일 등)를 할 수
                있습니다.
              </li>
            </ul>
            {/* <button
              className="mx-auto my-6"
              onClick={() => setShowContent(!showContent)}
            >
              <ChevronUpIcon
                className={`${
                  showContent ? '' : 'rotate-180'
                } transform transition-transform`}
              />
            </button>

            {showContent && (
              <ul className="bg-white rounded-2xl md:p-12 p-10 mx-3 md:mx-auto mb-12 md:space-y-2 list-disc font-medium">
                <li>문장의 구조를 파악하며 문법에 맞게 말할 수 있습니다.</li>
                <li>
                  문법개념과 문제 풀이를 통해 중학 문법의 기본을 완성할 수
                  있습니다.
                </li>
                <li>
                  400~550단어의 픽션, 논픽션 글을 읽으며 독해 실력을 기를 수
                  있습니다.
                </li>
                <li>
                  자신의 생활과 관련된 글 쓰기(편지, 일기, 이메일 등)를 할 수
                  있습니다.
                </li>
              </ul>
            )} */}
            <div className="flex -space-x-3 md:space-x-10 mx-auto">
              {[
                { smallText: '1주 권장 수업 횟수', bigText: '2회' },
                { smallText: '총 수업', bigText: '72회' },
                { smallText: '총 교재', bigText: '36권' },
              ].map((item) => (
                <div className="text-white wh-32 md:w-48 md:h-48 rounded-full bg-primary-4 flex items-center justify-center">
                  <div className="text-center mx-auto">
                    <p className="font-medium text-sm md:text-lg">
                      {item.smallText}
                    </p>
                    <p className="md:text-4xl text-2xl">{item.bigText}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="pt-10 flex justify-center">
              <button
                className=" content-center font-black F-tab-btn01"
                onClick={handlelibraryClick}
                style={{ backgroundColor: '#FF8C58' }}
              >
                대표교재 보기
                <span
                // style={{ color: ' #FF8C58' }}
                >
                  Click
                </span>
              </button>
            </div>
          </Section>
        )}
      </ScrollAnimation>

      {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
        <div className="flex justify-center">
          <button className="relative pt-9 group">
            <img
              src={character}
              alt=""
              className="h-16 object-cover absolute bottom-0.5 left-3"
            />
            <div
              onClick={handleLevelTestClick}
              className="border-2 border-primary-1 text-primary-1 lg:text-2xl text-lg pl-24 group-hover:bg-primary-1 group-hover:text-white pr-6 py-2.5 rounded-full "
            >
              레벨 테스트 하러 가기 {`>`}
            </div>
          </button>
        </div>
      </ScrollAnimation> */}
    </div>
  );
};
