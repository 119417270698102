import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { LibraryCard } from '../../components/LibraryCard';
import { LibraryTab } from '../../components/LibraryTab';
import { fetcher } from '../../plugins/react-query';
import { Level, Series } from '../../types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { ReactComponent as Arrow } from '../../assets/svg/swiper_arrow_right.svg';
import { Section } from '../../components/Section';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';

export const LibraryPage = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { levelId, seriesId } = parse(search, { ignoreQueryPrefix: true });
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [selectedSeries, setSelectedSeries] = useState<Series>();

  const { data: levels } = useQuery<Level[]>('/levels', fetcher);

  // useEffect(() => {
  //   if (selectedLevel) return;
  //   if (!levels || levels.length === 0) return;
  //   setSelectedLevel(levels[0]);
  // }, [selectedLevel, levels]);

  // useEffect(() => {
  //   if (!selectedLevel || selectedLevel.serieses.length === 0) return;
  //   setSelectedSeries(selectedLevel.serieses[0]);
  // }, [selectedLevel]);

  useEffect(() => {
    if (!levels || levels.length === 0) return;
    if (!levelId) {
      setSelectedLevel(levels[0]);
    } else {
      const levelIds = levels.map((level) => level.id);
      if (!levelIds.includes(Number(levelId.toString()))) {
        push('/teacher/prepare/library?levelId=1');
        return;
      }
      setSelectedLevel(levels.find((level) => level.id === Number(levelId)));
    }
  }, [selectedLevel, levels, levelId, push]);

  useEffect(() => {
    if (!selectedLevel || selectedLevel.serieses.length === 0) return;
    if (!seriesId) {
      setSelectedSeries(selectedLevel.serieses[0]);
    } else {
      const seriesIds = selectedLevel.serieses.map((series) => series.id);
      if (!seriesIds.includes(Number(seriesId.toString()))) {
        push('/teacher/prepare/library?levelId=1');
        return;
      }
      setSelectedSeries(
        selectedLevel.serieses.find((series) => series.id === Number(seriesId))
      );
    }
  }, [push, selectedLevel, seriesId]);

  return (
    <div className="flex flex-col space-y-5 items-center mx-auto px-4 pt-8 w-full max-w-5xl overflow-x-hidden">
      <div className="flex flex-col">
        <Swiper
          className="mySwiper"
          slidesPerView="auto"
          spaceBetween={6}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          freeMode={true}
        >
          {(levels ?? []).map((level) => (
            <SwiperSlide className="w-auto">
              <LibraryTab
                key={level.id}
                item={level}
                selected={level.id === selectedLevel?.id}
                onClick={() =>
                  push(`/teacher/prepare/library?levelId=${level?.id}`)
                }
                className={`${
                  level.index === 1
                    ? 'border-primary-3'
                    : level.index === 2
                    ? 'border-primary-5'
                    : level.index === 3
                    ? 'border-primary-6'
                    : 'border-primary-4'
                }`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="md:block hidden">
        <Section className="px-10 flex content-center ">
          <Arrow className="swiper-button-prev-1 transform rotate-180 cursor-pointer wh-12 pl-2" />
          <Swiper
            pagination={false}
            slidesPerView="auto"
            spaceBetween={6}
            slidesOffsetBefore={0}
            slidesOffsetAfter={0}
            freeMode={true}
            navigation={{
              nextEl: '.swiper-button-next-1',
              prevEl: '.swiper-button-prev-1',
            }}
            className="mySwiper max-w-3xl flex"
          >
            {(selectedLevel?.serieses ?? []).map((series) => (
              <SwiperSlide className="w-auto ">
                <LibraryTab
                  key={series.id}
                  item={series}
                  selected={series.id === selectedSeries?.id}
                  onClick={() => {
                    push(
                      `/teacher/prepare/library?levelId=${selectedLevel?.id}&seriesId=${series.id}`
                    );
                  }}
                  className={`${
                    selectedLevel?.index === 1
                      ? 'border-primary-3'
                      : selectedLevel?.index === 2
                      ? 'border-primary-5'
                      : selectedLevel?.index === 3
                      ? 'border-primary-6'
                      : 'border-primary-4'
                  }`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Arrow className="swiper-button-next-1 cursor-pointer wh-12 transform pl-1" />
        </Section>
      </div>
      <Swiper
        className="mySwiper w-screen max-w-5xl md:hidden block"
        slidesPerView="auto"
        spaceBetween={6}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        freeMode={true}
      >
        {(selectedLevel?.serieses ?? []).map((series) => (
          <SwiperSlide className="w-auto">
            <LibraryTab
              key={series.id}
              item={series}
              selected={series.id === selectedSeries?.id}
              onClick={() =>
                push(
                  `/teacher/prepare/library?levelId=${selectedLevel?.id}&seriesId=${series.id}`
                )
              }
              className={`${
                selectedLevel?.index === 1
                  ? 'border-primary-3'
                  : selectedLevel?.index === 2
                  ? 'border-primary-5'
                  : selectedLevel?.index === 3
                  ? 'border-primary-6'
                  : 'border-primary-4'
              }`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-6 pt-10">
        {(selectedSeries?.books ?? []).map((book) => (
          <LibraryCard
            key={book.id}
            book={book}
            // locked={!myBookIds.has(book.id)}
          />
        ))}
      </div>
    </div>
  );
};
