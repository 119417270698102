import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Reinforce, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  memo: string;
  amount: number;
  price: number;
  isPaid: boolean;
  studentId: number;
  userId: number;
}

export const ReinforceDetails = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const queryParams = new URLSearchParams(search);

  const { data: reinforce, refetch } = useQuery<Reinforce>(
    `/admin/reinforces/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const userId = queryParams.get('userId') || reinforce?.userId;
  const studentId = queryParams.get('studentId') || reinforce?.studentId;

  const { data: user } = useQuery<User>(`/admin/users/${userId}`, fetcher, {
    refetchOnWindowFocus: false,
  });

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      userId: Number(userId),
      studentId: Number(studentId),
      isPaid: false,
    },
  });

  useEffect(() => {
    if (reinforce) {
      setValue('memo', reinforce.memo);
      setValue('amount', reinforce.amount);
      setValue('price', reinforce.price);
      setValue('isPaid', reinforce.isPaid);
      setValue('studentId', reinforce.studentId);
      setValue('userId', reinforce.userId);
    }
  }, [setValue, editMode, reinforce]);

  return (
    <>
      <AdminH1>보강권</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField label="대상 유저" disabled={true} value={user?.name} />

          <TextField
            type="number"
            label="횟수"
            disabled={!addMode && !editMode}
            helper={errors.amount?.message}
            {...register('amount', {
              required: '횟수를 입력해주세요',
            })}
          />

          <TextField
            type="number"
            label="금액"
            disabled={!addMode && !editMode}
            helper={errors.price?.message}
            {...register('price', {
              required: '금액를 입력해주세요',
            })}
          />
          <TextField
            label="메모"
            disabled={!addMode && !editMode}
            helper={errors.memo?.message}
            {...register('memo')}
          />

          {!addMode && (
            <Checkbox
              label="결제 여부"
              disabled={!addMode && !editMode}
              {...register('isPaid')}
            />
          )}
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('amount') || !watch('price')}
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/reinforces/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
            />
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('amount') || !watch('price')}
              onClick={handleSubmit(async (data) => {
                const res = await api.post<Reinforce>(
                  '/admin/reinforces',
                  data
                );
                push(`/admin/reinforces/${res.data.id}`);
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
