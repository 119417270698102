import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Pay, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import moment from 'moment';
import { Button } from '../../components/Button';
import { api } from '../../plugins/axios';

export const TeacherTransactions = () => {
  const [getMoment] = useState(moment());
  const today = getMoment;
  const { id } = useParams<{ id: string }>();
  const queryString = useQueryString({ filter: { teacherId: id } });
  const { data, refetch } = useQuery<Paginated<Pay>>(
    `/admin/pays${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );
  const { data: pays } = useQuery<Pay[]>(
    `/admin/pays/teacher/export?teacherId=${id}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const { data: teacher } = useQuery<User>(`/admin/users/${id}`, fetcher);

  const exporter = () => {
    const payData = pays?.map((pay: any) => {
      return {
        강사명: teacher?.name,
        영어이름: teacher?.englishName,
        계좌정보: teacher?.bankAccountNumber,
        주민등록번호: teacher?.residentRegistrationNumber,
        주소: teacher?.address,
        등급: teacher?.grade,
        총정산비용: pay.total
          ? pay.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '-',
        총수업횟수: `${pay.liveSettlementCount}회`,
        '기본정산(횟수)': `${pay.total
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}(${
          pay.liveSettlementCount
        }회)`,
        장려금: pay.incentive
          ? pay.incentive.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '-',
        패널티: pay.penalty
          ? pay.penalty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '-',
      };
    });

    jsonExport(payData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(
        `${BOM} ${csv}`,
        `${teacher?.name} 정산 관리(${today.format('YYYY 년 MM 월')})`
      );
    });
  };

  if (!data || !teacher) return <></>;
  return (
    <>
      <AdminH1>{teacher.name} 정산 내역</AdminH1>

      <div className="flex space-x-2">
        <Button
          className=" bg-blue-500 text-white ml-auto text-14"
          onClick={() => {
            window.confirm('이번달 정산 내역을 생성하시겠습니까?') &&
              api
                .post(`admin/pays/teacher/${teacher.id}`)
                .then(() => refetch())
                .catch((e) => alert(e.response.data.message));
          }}
        >
          이번 달 정산내역 생성
        </Button>
        <Button
          className="bg-white text-blue-500 ml-auto text-14"
          onClick={exporter}
        >
          파일 내보내기
        </Button>
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>년/월</Table.Th>
              <Table.Th>강사명</Table.Th>
              <Table.Th>영어이름</Table.Th>
              <Table.Th>계좌정보</Table.Th>
              <Table.Th>총 정산비용</Table.Th>
              <Table.Th>기본 정산(횟수)</Table.Th>
              <Table.Th>장려금</Table.Th>
              <Table.Th>패널티</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((pay) => (
              <Table.Row key={pay.id}>
                <Table.Td>
                  {utcToLocalFormat(pay.time, MomentFormat.YYYYMM)}
                </Table.Td>
                <Table.Td>{teacher.name}</Table.Td>
                <Table.Td>{teacher.englishName}</Table.Td>
                <Table.Td>
                  {teacher.bank} / {teacher.bankAccountNumber}
                </Table.Td>
                <Table.Td>
                  {pay.total && pay.liveSettlement < pay.penalty
                    ? `${pay.total
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : pay.total
                    ? pay.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0}
                </Table.Td>
                <Table.Td>
                  {pay.liveSettlement
                    ? pay.liveSettlement
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : '-'}{' '}
                  ({pay.liveSettlementCount}회)
                </Table.Td>
                <Table.Td>
                  {pay.incentive
                    ? pay.incentive
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    : 0}
                </Table.Td>
                <Table.Td>
                  {pay.penalty
                    ? `-${pay.penalty
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : 0}
                </Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/teachers/${id}/transactions/${pay.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav
            basePath={`/admin/teachers/${id}/transactions`}
            total={data.total}
          />
        </Pagination>
      </AdminCard>
    </>
  );
};
