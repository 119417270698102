import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';
import { TeacherContractDetails } from './TeacherContractDetails';
import { TeacherContracts } from './TeacherContracts';
import { TeacherInfo } from './TeacherInfo';
import { TeacherLives } from './TeacherLives';
import { TeacherSchedule } from './TeacherSchedule';
import { TeacherTransactionDetails } from './TeacherTransactionDetails';
import { TeacherTransactions } from './TeacherTransactions';

export const TeacherDetails = () => {
  const { pathname } = useLocation();
  const chunks = pathname.split('/');
  const basePath = `/${chunks[1]}/${chunks[2]}/${chunks[3]}`;
  const subPath = chunks[4];

  return (
    <>
      <AdminTabs>
        <AdminTab
          text="수업현황"
          to={
            pathname === '/admin/teachers/add/info' ? '' : `${basePath}/lives`
          }
          selected={subPath === 'lives'}
        />
        <AdminTab
          text="스케줄관리"
          to={
            pathname === '/admin/teachers/add/info'
              ? ''
              : `${basePath}/schedule`
          }
          selected={subPath === 'schedule'}
        />
        <AdminTab
          text="선생님정보"
          to={pathname === '/admin/teachers/add/info' ? '' : `${basePath}/info`}
          selected={subPath === 'info'}
        />
        <AdminTab
          text="정산내역"
          to={
            pathname === '/admin/teachers/add/info'
              ? ''
              : `${basePath}/transactions`
          }
          selected={subPath === 'transactions'}
        />
        <AdminTab
          text="계약관리"
          to={
            pathname === '/admin/teachers/add/info'
              ? ''
              : `${basePath}/contracts`
          }
          selected={subPath === 'contracts'}
        />
      </AdminTabs>

      <Switch>
        <Route path="/admin/teachers/:id/lives" component={TeacherLives} />
        <Route
          path="/admin/teachers/:id/schedule"
          component={TeacherSchedule}
        />
        <Route path="/admin/teachers/:id/info" component={TeacherInfo} />
        <Route
          path="/admin/teachers/:id/transactions/:payId"
          component={TeacherTransactionDetails}
        />
        <Route
          path="/admin/teachers/:id/transactions"
          component={TeacherTransactions}
          exact
        />
        <Route
          path="/admin/teachers/:id/contracts/:contractId"
          component={TeacherContractDetails}
        />
        <Route
          path="/admin/teachers/:id/contracts"
          component={TeacherContracts}
        />
        <Redirect from="/admin/teachers/:id" to="/admin/teachers/:id/lives" />
      </Switch>
    </>
  );
};
