import React, { ButtonHTMLAttributes, FC } from 'react';
import { Level, Series } from '../types';
import hme from '../assets/img/HME.png';
import eup from '../assets/img/EUP.png';
import ils from '../assets/img/ILS.png';
import saw from '../assets/img/SAW.png';
import mdy from '../assets/img/MDY.png';
import rsw from '../assets/img/RSW.png';
import rr1 from '../assets/img/RR1.png';
import scl from '../assets/img/SCL.png';
import sfy from '../assets/img/SFY.png';
import wup from '../assets/img/WUP.png';
import rr2 from '../assets/img/RR2.png';
import ttt from '../assets/img/TTT.png';
import wtr from '../assets/img/WTR.png';
import wts from '../assets/img/WTS.png';
import egr from '../assets/img/EGR.png';
import jtt from '../assets/img/JTT.png';
import gaw from '../assets/img/GAW.png';
import mrr from '../assets/img/MRR.png';
import rm3 from '../assets/img/RM3.png';
import tmb from '../assets/img/TMB.png';
import rm4 from '../assets/img/RM4.png';
import wtg from '../assets/img/WTG.png';
import rm5 from '../assets/img/RM5.png';
import spk from '../assets/img/SPK.png';

const seriesLogoList = [
  { id: 1, imgPath: hme, seriesId: 1 },
  { id: 2, imgPath: eup, seriesId: 2 },
  { id: 3, imgPath: ils, seriesId: 3 },
  { id: 4, imgPath: saw, seriesId: 4 },
  { id: 5, imgPath: mdy, seriesId: 5 },
  { id: 6, imgPath: rsw, seriesId: 6 },
  { id: 7, imgPath: rr1, seriesId: 7 },
  { id: 8, imgPath: scl, seriesId: 8 },
  { id: 9, imgPath: sfy, seriesId: 9 },
  { id: 10, imgPath: wup, seriesId: 10 },
  { id: 11, imgPath: rr2, seriesId: 11 },
  { id: 12, imgPath: ttt, seriesId: 12 },
  { id: 13, imgPath: wtr, seriesId: 13 },
  { id: 14, imgPath: wts, seriesId: 14 },
  { id: 15, imgPath: egr, seriesId: 15 },
  { id: 16, imgPath: jtt, seriesId: 16 },
  { id: 17, imgPath: gaw, seriesId: 17 },
  { id: 18, imgPath: mrr, seriesId: 18 },
  { id: 19, imgPath: rm3, seriesId: 19 },
  { id: 20, imgPath: tmb, seriesId: 20 },
  { id: 21, imgPath: rm4, seriesId: 21 },
  { id: 22, imgPath: wtg, seriesId: 22 },
  { id: 23, imgPath: rm5, seriesId: 23 },
  // TODO : 스피킹 id 로 수정!!!!!!!!!!!!!!!!!
  { id: 25, imgPath: spk, seriesId: 25 },
];

interface LibraryTabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  item: Level | Series;
  selected: boolean;
}

export const LibraryTab: FC<LibraryTabProps> = ({
  item,
  selected,
  className = '',
  ...props
}) => {
  return (
    <button
      className={`items-center px-6 bg-white rounded-full border-2 shadow-3xl ${
        selected ? className : 'border-transparent'
      } ${isSeries(item) ? 'flex space-x-2 py-2' : 'py-3'}`}
      {...props}
    >
      {isSeries(item) && (
        <div className="relative wh-7 flex items-center">
          {seriesLogoList.map(
            (si) =>
              item.id === si.seriesId && (
                <img src={si.imgPath} className="absolute" alt="" />
              )
          )}
        </div>
      )}
      <p className={`text-gray-900 font-semibold whitespace-pre`}>
        {item.name}
      </p>
    </button>
  );
};

function isSeries(item: Level | Series): item is Series {
  return (item as Series).thumbnail !== undefined;
}
