import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Lesson } from '../../types';
import { AdminCard } from './AdminCard';

interface FormValues {
  title: string;
  index: number;
  story: string;
  storyQuiz: string;
  learn: string;
  learnQuiz: string;
  words: string;
  wordQuiz: string;
  workbook: string;
  talk: string;
  test: string;
}

interface AdminLessonCardProps {
  lesson?: Lesson;
  refetchLessons: () => void;
}

export const AdminLessonCard: FC<AdminLessonCardProps> = ({
  lesson,
  refetchLessons,
}) => {
  const [lessonEditMode, setLessonEditMode] = useState(false);

  const {
    watch,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!lesson) return;
    clearErrors();
    setValue('title', lesson.title);
    setValue('index', lesson.index);
    setValue('story', lesson.story);
    setValue('storyQuiz', lesson.storyQuiz);
    setValue('learn', lesson.learn);
    setValue('learnQuiz', lesson.learnQuiz);
    setValue('words', lesson.words);
    setValue('wordQuiz', lesson.wordQuiz);
    setValue('workbook', lesson.workbook);
    setValue('talk', lesson.talk);
    setValue('test', lesson.test);
  }, [clearErrors, setValue, lesson, lessonEditMode]);

  return (
    <AdminCard>
      <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
        <TextField
          label="타이틀"
          disabled={!lessonEditMode}
          helper={errors.title?.message}
          {...register('title', { required: '타이틀명을 입력해주세요.' })}
        />
        <TextField
          type="number"
          label="인덱스"
          disabled={!lessonEditMode}
          {...register('index')}
        />
        <TextField
          label="Story"
          disabled={!lessonEditMode}
          {...register('story')}
        />
        <TextField
          label="Story Quiz"
          disabled={!lessonEditMode}
          {...register('storyQuiz')}
        />
        <TextField
          label="Learn More"
          disabled={!lessonEditMode}
          {...register('learn')}
        />
        <TextField
          label="Learn More Quiz"
          disabled={!lessonEditMode}
          {...register('learnQuiz')}
        />
        <TextField
          label="Words"
          disabled={!lessonEditMode}
          {...register('words')}
        />
        <TextField
          label="Word Quiz"
          disabled={!lessonEditMode}
          {...register('wordQuiz')}
        />
        <TextField
          label="Workbook"
          disabled={!lessonEditMode}
          {...register('workbook')}
        />
        <TextField
          label="Talk+"
          disabled={!lessonEditMode}
          {...register('talk')}
        />
        <TextField
          label="Weekly Test"
          disabled={!lessonEditMode}
          {...register('test')}
        />
      </div>
      {!process.env.REACT_APP_DISABLED && (
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {lessonEditMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setLessonEditMode(false)}
            />
          )}
          {lessonEditMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('title')}
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/lessons/${lesson?.id}`, data);
                await refetchLessons();
                setLessonEditMode(false);
              })}
            />
          )}
          {!lessonEditMode && (
            <Button
              text="삭제"
              className="h-10 text-sm filled-red-500 hover:bg-red-600"
              onClick={() =>
                window.confirm('삭제하시겠습니까?') &&
                api
                  .delete(`/admin/lessons/${lesson?.id}`)
                  .then(refetchLessons)
                  .then(() => window.alert('삭제되었습니다.'))
              }
            />
          )}
          {!lessonEditMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setLessonEditMode(true)}
            />
          )}
        </div>
      )}
    </AdminCard>
  );
};
