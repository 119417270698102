import { PlusIcon } from '../components/icons';
import { H5 } from '../components/H5';
import { Button } from '../components/Button';
import DUMMY from '../assets/img/DUMMY-course.png';
import {
  courseItemState,
  courseState,
  latestLiveState,
  liveState,
  studentState,
} from '../plugins/ridge';
import { useQuery } from 'react-query';
import {
  ClassType,
  Course,
  LiveState,
  ProductLiveType,
  classTypeToText,
  liveStateToTextMin,
} from '../types';
import { fetcher } from '../plugins/react-query';
import { apiUrl } from '../plugins/axios';
import { Avatar } from '../components/Avatar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

export const LearnCoursePage = () => {
  const student = studentState.useValue();
  const { push } = useHistory();

  const { data: courses } = useQuery<Course[]>(
    `/courses?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  const getNumber = (num: number) => {
    return num ? num : 0;
  };

  return (
    <div className="course-wrap">
      {/*첫 수강권 구매 className='first'*/}
      <div
        className="card first group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm items-center justify-center"
        onClick={() => {
          push('/pricing/regular');
        }}
      >
        <div className="group-hover:bg-primary-1 bg-gray-50 rounded-full wh-14 grid place-items-center">
          <PlusIcon className="group-hover:text-white text-gray-400 fill-current" />
        </div>
        <H5 className="mt-4 mb-2">수강권 구매하기</H5>
      </div>
      {/*첫 수강권 구매 className='first'*/}
      {courses?.map((c) => {
        var isActive =
          (c.remainingAmount === 0 && // 기본적으로 잔여 0
            c.lives.filter((f) => f.state === LiveState.RESERVED).length ===
              0) ||
          moment(c.endedAt).add(1, 'day') < moment()
            ? 'end'
            : 'active';

        var sortedLive = c.lives
          ?.filter((live) => live.state !== LiveState.CANCELED)
          .sort(
            (x, y) => moment(x.start).valueOf() - moment(y.start).valueOf()
          );

        var recentLive = sortedLive.filter(
          (live) => moment(live.start).valueOf() - moment().valueOf() > 0
        )[0];

        if (!recentLive) recentLive = sortedLive[sortedLive.length - 1];

        var classTypeName = c.isTrial
          ? `체험 ${
              c?.courseItems[0].liveClass.productLiveType ===
              ProductLiveType.TRIAL
                ? '정규'
                : c?.courseItems[0].liveClass.productLiveType ===
                  ProductLiveType.TRIAL_SPEAKING
                ? '스피킹'
                : ''
            }`
          : classTypeToText(c?.startLevel?.classType);

        var title = c.isTrial
          ? classTypeName // 체험
          : c?.startLevel?.classType === ClassType.REGULAR
          ? `${classTypeName} ${c.courseItems.length}회` // 정규
          : `${classTypeName} ${c.startSeries.name} ${c.courseItems.length}회`; // 특강

        return (
          <div
            className={`card group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm items-center ${isActive}`}
          >
            <div className="info">
              <p>{title}</p>
              <p style={{ height: '22px' }}>
                {c.endedAt ? '~' + moment(c.endedAt).format('YYYY.M.D') : ' '}
              </p>
              <div className="img">
                <Avatar
                  src={apiUrl(recentLive ? recentLive.teacher.avatar : '')}
                />
              </div>
              <p style={{ minHeight: '22px' }}>
                {recentLive ? recentLive.teacher.englishName + ' 선생님' : ''}
                {recentLive ? (
                  <span>{liveStateToTextMin(recentLive.state)}</span>
                ) : (
                  ''
                )}
              </p>
              <p style={{ height: '22px' }}>
                {recentLive
                  ? moment(recentLive.start).format('M.D (ddd) a hh:mm')
                  : ''}
              </p>
            </div>
            <Button
              className="btn filled-primary-1"
              text={
                isActive === 'active' &&
                recentLive?.state !== LiveState.RESERVED &&
                c.isOpen
                  ? '수업 예약하기'
                  : '나의 강의실'
              }
              onClick={
                isActive === 'active'
                  ? () => {
                      var courseItemIndex = 0;
                      c.lives.forEach((f) => {
                        if (getNumber(f.attendRound) >= courseItemIndex) {
                          courseItemIndex = f.attendRound;
                        }
                      });

                      courseState.set(c);
                      courseItemState.set(c.courseItems[courseItemIndex]);
                      localStorage.setItem('courseId', c.id.toString());
                      push(
                        `/learn/${
                          recentLive && recentLive.state !== LiveState.RESERVED
                            ? 'reservation'
                            : 'classroom'
                        }`
                      );
                    }
                  : () => {}
              }
            />
          </div>
        );
      })}
      {/*예약 className='active'*/}

      {/*예약 className='active'*/}

      {/*완료 className='end'*/}
      {/* <div className="card end group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm items-center">
        <div className="info">
          <p>정규 12회</p>
          <p>(~2023.04.19)</p>
          <div className="img">
            <img src={DUMMY} />
          </div>
          <p>
            Sunghee선생님<span>완료</span>
          </p>
          <p>4. 12 (화) 오후 4:30</p>
        </div>
        <Button className="btn filled-primary-1" text={'수강권 구매'} />
      </div> */}
      {/*완료 className='end'*/}
    </div>
  );
};
