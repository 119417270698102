import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { ArrowLeftIcon } from './icons';

interface MobileBackButtonProps {
  className?: string;
}

export const MobileBackButton: FC<MobileBackButtonProps> = ({ className }) => {
  const { goBack } = useHistory();
  return (
    <button className={`${className} md:hidden py-5`} onClick={goBack}>
      <ArrowLeftIcon />
    </button>
  );
};
