import React, { FC } from 'react';
import { Table } from '../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { Point, PointType } from '../../types';

interface RewardsPointPageProps {
  points?: Point[];
}

export const RewardsPointPage: FC<RewardsPointPageProps> = ({ points }) => {
  return (
    <div className="bg-white shadow-3xl rounded-md space-y-6 p-5">
      <Table className="divide-gray-50">
        <Table.Head className="bg-transparent">
          <Table.Row>
            <Table.Th>일자</Table.Th>
            <Table.Th>자녀명</Table.Th>
            <Table.Th>포인트 설명</Table.Th>
            <Table.Th>지급 • 차감</Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body className="divide-gray-50">
          {points?.map((point) => (
            <Table.Row key={point.id}>
              <Table.Td className="font-normal">
                {utcToLocalFormat(
                  point.createdAt.toLocaleString(),
                  MomentFormat.YYYYMMDDHmm
                )}
              </Table.Td>
              <Table.Td className="font-normal">{point.student.name}</Table.Td>
              <Table.Td className="font-normal">{point.title}</Table.Td>
              <Table.Td>
                <span
                  className={` font-normal ${
                    point.pointType === PointType.PROVISION
                      ? 'text-blue-500'
                      : point.pointType === PointType.DEDUCTIBLE
                      ? 'text-red-500'
                      : ''
                  }`}
                >
                  {point.pointType === PointType.PROVISION
                    ? `+${point.value}`
                    : `-${point.value}`}
                </span>
              </Table.Td>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
