import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { MobileBackButton } from '../../components/MobileBackButton';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';

interface FormValues {
  email: string;
}

export const PasswordFindEmailPage = () => {
  const { push } = useHistory();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4">
      <MobileBackButton />
      <H5 className="pb-8">이메일로 비밀번호 찾기</H5>
      <form
        onSubmit={handleSubmit((data) => {
          api
            .post('users/find-password', data)
            .then(() => push('/password/find/sent', { email: data.email }))
            .catch(() => alert('등록된 유저가 아닙니다. 다시 시도해주세요.'));
        })}
      >
        <TextField
          label="이메일"
          autoFocus={true}
          type="email"
          placeholder="you@example.com"
          helper={errors.email?.message}
          {...register('email', { required: '이메일을 입력해주세요.' })}
        />
        <p className="pt-2">
          *이메일이 수신 되지 않거나, 이메일주소가 기억나지 않을 경우 고객센터로
          문의 주시기 바랍니다.
        </p>
        <Button
          className="filled-primary-1 mt-10 w-full"
          text="이메일 발송"
          disabled={!watch('email')}
        />
      </form>
    </div>
  );
};
