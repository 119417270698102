import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Button } from '../../components/Button';
import { EditorView } from '../../plugins/froala';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Notice } from '../../types';

export const NoticeDetailsPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: notice } = useQuery<Notice>(`/notices/${id}`, fetcher, {
    enabled: !!id,
  });

  if (!notice) return <>404 오류. 정보가 없습니다.</>;
  return (
    <div className="max-w-container space-y-5 mt-5">
      <Button
        to="/parent/notices"
        className="filled-primary-7 text-secondary-7 h-10 font-semibold"
      >
        ← 목록으로
      </Button>
      <div className="w-full max-w-3xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden p-5">
        <div className="border-b pb-5 border-gray-100">
          <h2 className="text-2xl font-medium">{notice.title}</h2>
          <span className="text-sm text-gray-500">
            {utcToLocalFormat(notice.createdAt, MomentFormat.YYYYMMDDHmm)}
          </span>
        </div>
        <div className={`whitespace-pre-line text-gray-600 mt-5`}>
          <EditorView model={notice.content} />
        </div>
      </div>
    </div>
  );
};
