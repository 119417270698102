import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { api, apiUrl } from '../../plugins/axios';
import { resizeImage } from '../../plugins/compress';
import { fetcher } from '../../plugins/react-query';
import { studentState } from '../../plugins/ridge';
import { Gender, Student } from '../../types';
import { Avatar } from '../Avatar';
import { Button } from '../Button';
import { DateSelect } from '../DateSelect';
import { H3 } from '../H3';
import { ArrowLeftIcon, PlusIcon, XIcon } from '../icons';
import { Label } from '../Label';
import { Radio } from '../Radio';
import { RadioGroup } from '../RadioGroup';
import { TextField } from '../TextField';

interface FormValues {
  avatar: File;
  name: string;
  dob: string;
  gender: Gender;
}

interface ChildPopUpProps {
  open: boolean;
  studentId: number;
  onClose: () => void;
}

export const ChildPopUp: FC<ChildPopUpProps> = ({
  studentId,
  open,
  onClose,
}) => {
  const { data: student } = useQuery<Student>(
    `/students/${studentId}`,
    fetcher,
    { enabled: studentId !== 0 }
  );

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { gender: Gender.MALE } });

  useEffect(() => {
    if (!student) return;
    setValue('name', student.name);
    setValue('dob', student.dob);
    setValue('gender', student.gender);
  }, [studentId, student, setValue]);

  const add = handleSubmit((data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) =>
      formData.append(name, value)
    );
    api
      .post('/students', formData)
      .then((res) => {
        studentState.set(res.data);
        localStorage.setItem('studentId', res?.data?.id.toString());
        onClose();
        window.location.reload();
      })
      .catch(console.error);
  });

  const edit = handleSubmit((data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) =>
      formData.append(name, value)
    );
    api
      .patch(`/students/${studentId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .catch(console.error)
      .finally(() => {
        onClose();
        window.location.reload();
        studentState.set(student);
        if (student) {
          localStorage.setItem('studentId', student?.id.toString());
        }
      });
  });

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack px-4 overflow-scroll">
      <div className="flex w-full justify-center items-center min-h-screen">
        <div className="w-full md:h-auto flex flex-col bg-white rounded-xl md:overflow-hidden p-10 space-y-6 md:max-w-xl">
          <button onClick={onClose} className="md:self-end">
            <ArrowLeftIcon className="md:hidden" />
            <XIcon className="hidden md:block" />
          </button>
          <H3 className="pb-4">{studentId ? '자녀 정보 수정' : '자녀 추가'}</H3>

          <label htmlFor="avatar" className="relative w-max cursor-pointer">
            <Avatar
              src={
                watch('avatar')
                  ? URL.createObjectURL(watch('avatar'))
                  : apiUrl(student?.avatar)
              }
              className="wh-20 object-cover"
            />
            <div className="absolute right-0 bottom-0 bg-primary-1 rounded-full wh-7 grid place-content-center">
              <PlusIcon className="text-white fill-current wh-3" />
            </div>
            <input
              id="avatar"
              type="file"
              accept="image/*"
              className="sr-only"
              onChange={async (e) => {
                const file = e.target.files?.item(0);
                if (!file || !e.target.validity.valid) return;
                setValue('avatar', await resizeImage(file));
              }}
            />
          </label>

          <TextField
            label="자녀 이름*"
            placeholder="자녀 이름을 입력해주세요."
            helper={errors.name?.message}
            {...register('name', {
              required: '자녀 이름을 입력해주세요.',
            })}
          />

          <div className="flex flex-col space-y-2">
            <DateSelect
              label="자녀 생년월일*"
              value={student?.dob}
              onChange={(value) => setValue('dob', value.toLocaleDateString())}
            />
          </div>

          <div className="space-y-2 pb-12">
            <Label text="자녀 성별*" />
            <RadioGroup
              onChange={(e) =>
                setValue(
                  'gender',
                  e.target.value === Gender.MALE ? Gender.MALE : Gender.FEMALE
                )
              }
            >
              <Radio
                label="남성"
                value={Gender.MALE}
                checked={watch('gender') === Gender.MALE}
              />
              <Radio
                label="여성"
                value={Gender.FEMALE}
                checked={watch('gender') === Gender.FEMALE}
              />
            </RadioGroup>
          </div>

          <Button
            onClick={studentId ? edit : add}
            text={studentId ? '수정 완료' : '등록하기'}
            className="text-15 font-normal filled-primary-1"
          />
        </div>
      </div>
    </div>
  );
};
