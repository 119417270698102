import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { api, apiUrl } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { range } from '../../utils';
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js';

export const TeacherContractDetails = () => {
  const history = useHistory();
  const [pageNumber, setNumPages] = useState(0);
  // const [signedPdfs, setSignedPdfs] = useState<any>([]);
  // const [pdfs, setPdfs] = useState<any>([]);
  const { contractId } = useParams<{ contractId: string }>();

  const { data: contract, refetch } = useQuery<any>(
    `/admin/contracts/${contractId}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  // useEffect(() => {
  //   if (!contract) {
  //     return;
  //   }
  //   setPdfs(JSON.parse(contract.pdf || '[]'));
  //   setSignedPdfs(JSON.parse(contract.signedPdf || '[]'));
  // }, [contract]);

  const pdfUrl = useMemo(
    () =>
      contract
        ? contract?.isSigned
          ? apiUrl(contract?.signedPdf)
          : apiUrl(contract?.pdf)
        : '',
    [contract]
  );

  // const isContractSigned = contract?.isSigned;

  return (
    <>
      <div className="w-full h-full relative space-y-6">
        <div
          className="font-bold text-lg mb-5 cursor-pointer"
          onClick={() => history.goBack()}
        >
          &#60; 목록보기
        </div>

        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {range(pageNumber).map((_, i) => (
            <Page pageNumber={i + 1} key={i} className="mb-2" />
          ))}
        </Document>

        {/* {isContractSigned
          ? signedPdfs.map((f: any) => (
              <Document
                file={f}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {range(pageNumber).map((_, i) => (
                  <Page pageNumber={i + 1} key={i} className="mb-2" />
                ))}
              </Document>
            ))
          : pdfs.map((f: any) => (
              <Document
                file={f}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {range(pageNumber).map((_, i) => (
                  <Page pageNumber={i + 1} key={i} className="mb-2" />
                ))}
              </Document>
            ))} */}

        <div className="py-10" />
        {contract && !contract?.isSigned && (
          <div className="absolute bottom-4 left-0 right-0">
            <div className="w-min mx-auto flex space-x-4 items-center justify-between p-1">
              <label
                className="rounded-full bg-blue-700 text-blue-100 py-3 px-5 min-w-max cursor-pointer"
                htmlFor="file_upload"
              >
                파일 수정하기
              </label>

              {/* <form className="uploader" encType="multipart/form-data">
                <input
                  type="file"
                  id="file_upload"
                  className="hidden"
                  multiple
                  accept="application/pdf"
                  onChange={async (e) => {
                    if (e.target.files && e.target.files?.length !== 0) {
                      const formData = new FormData();
                      const files = Array.from(e.target.files);
                      for (let i = 0; i < files.length; i++) {
                        formData.append('pdf', files[i]);
                      }
                      await api.post<any>(
                        `/admin/contracts/${contractId}`,
                        formData,
                        {
                          headers: { 'Content-Type': 'multipart/form-data' },
                        }
                      );
                      await refetch();
                    }
                  }}
                />
              </form> */}
              <input
                type="file"
                id="file_upload"
                className="hidden"
                accept="application/pdf"
                onChange={async (e) => {
                  if (e.target.validity.valid && e.target.files?.item(0)) {
                    const formData = new FormData();
                    formData.append(
                      'file',
                      e.target.files.item(0) || new Blob()
                    );
                    await api.post<any>(
                      `/admin/contracts/${contractId}`,
                      formData,
                      {
                        headers: { 'Content-Type': 'multipart/form-data' },
                      }
                    );
                    await refetch();
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
