import React, { ButtonHTMLAttributes, FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import { studentState } from '../plugins/ridge';
import { usePopup } from './PopUp';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  path: string;
}

export const Tab: FC<TabProps> = ({ title, path, ...props }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const selected = pathname.startsWith(path);
  const student = studentState.useValue();

  const { requireLevelTestPopup, requireCoursePopup, addStudentPopup } =
    usePopup();
  return (
    <button
      onClick={() =>
        path === 'student'
          ? addStudentPopup()
          : path === 'testResult'
          ? requireLevelTestPopup(student?.id)
          : path === 'course'
          ? requireCoursePopup()
          : push(path)
      }
      className={`pt-2 flex flex-col items-center space-y-1 ${
        selected ? '' : ''
      }`}
      {...props}
    >
      <p
        className={`pb-4 px-2 ${
          selected ? 'text-gray-900 font-semibold' : 'text-gray-400'
        }`}
      >
        {title}
      </p>
      {selected ? (
        <div className="w-full border-2 border-primary-1" />
      ) : (
        <div />
      )}
    </button>
  );
};
