import { useRef, useState } from 'react';
export const NewPolicyUpdatePage = () => {
  return (
    <>
      <div className="new-terms-wrap">
        <p className="hv-txt">개인정보 처리방침</p>
        튼튼영어라이브 서비스 이용약관 일부가 다음과 같이 변경될 예정입니다.
        <br />
        주요 개정 내용 및 일정에 대하여 안내 드리오니, 참고해 주시기 바랍니다.
        <br />
        <br />
        2023년 8월 3일
        <br />
        1. 주요 개정 내용
        <br />
        - 1조 1) 수집하는 개인정보의 항목
        <br />
        - 6조 수집한 개인정보 위탁, 개인정보 처리업무 수탁자 및 위탁 업무 내용
        <br />
        <table className="new-terms-table update">
          <tr>
            <td className="first-td">구분</td>
            <td className="first-td">현행</td>
            <td className="first-td">개정안</td>
          </tr>
          <tr>
            <td>수집하는 개인정보의 항목</td>
            <td>
              고객센터 문의 시: 이름, 휴대폰 번호, 주거지역, 이메일
              <br />
              <br />
              체험 신청 시: 자녀이름, 자녀생년월일, 학부모 휴대폰 번호, 주거지역
              그외 설문조사나 이벤트 행사시 경품제공등을 위해 선별적으로
              개인정보 입력을 요청 할 수 있습니다.
            </td>
            <td>
              수집∙이용 목적
              <br />
              회원가입/탈퇴, 서비스가입/변경/해지, 교재배송, 본인확인, 사용자
              식별, 고지사항전달, 이용관련 문의 및 불만처리, 자녀 학습분석, 수업
              추천, 이용후기, 이용요금 청구, 할인, 결제 및 추심, 명의도용 방지를
              위한 등록된 이동전화로 가입 사실 통보, 상품 가입/해지 정보 고지
              <br />
              <br />
              개인정보 항목
              <br />
              휴대전화번호, 이메일주소, 이름, 배송지 주소, 학생정보(생년월일,
              성별, 학년, 학습수준)
              <br />
              <br />
              수집∙이용 목적
              <br />
              학습 제공 및 수업품질 유지를 위한 화상수업 녹화
              <br />
              <br />
              개인정보 항목
              <br />
              강사의 수업 강의내용 및 학생 모습 녹화
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td>
              수집한 개인정보 위탁, 개인정보 처리업무 수탁자 및 위탁 업무 내용
            </td>
            <td>
              - 수탁업체: TCK(트랜스코스모스코리아), 시노텍코리아
              <br />
              - 위탁업무 내용: 불만처리 등 민원 및 고객 상담 서비스
              <br />- 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지
            </td>
            <td>
              수탁업체 및 위탁 업무 내용
              <br />
              - TCK(트랜스코스모스코리아) :고객 상담, 관리
              <br />
              - 시노텍코리아 : 학습 기기 AS
              <br />
              - 채널톡 : 고객 채팅 상담, 관리
              <br />
              - 비즈엠알림톡 : 고객 알림 공지, 본인 인증
              <br />
              - NHN KCP : 상품 및 서비스에 대한 결제 대행 및 현금영수증 발행
              <br />
              - (주)케이티알파 : 모바일 경품(기프티콘) 발송
              <br />
              - CJ 대한통운 : 배송 서비스
              <br />
              - 보유 및 이용기간: 회원탈퇴시 혹은 위탁계약 종료시까지
              <br />
            </td>
          </tr>
        </table>
        <br />
        2. 적용 시점
        <br />
        2023년 8월 3일
        <br />
        <br />
        3. 이의제기 관련사항
        <br />
        약관 변경 시행일까지 별도의 거부 의사를 표시(회원 탈퇴)하지 않은 경우,
        개정 약관에 동의한 것으로 간주합니다.
        <br />
        2023년 8월 3일자로 적용될 개정 약관에 문의사항이 있을 경우 고객센터로
        연락주시기 바랍니다.
        <br />
      </div>
    </>
  );
};
