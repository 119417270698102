import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { ReactComponent as PencilIcon } from '../../assets/svg/icon-pencil.svg';
import { Avatar } from '../../components/Avatar';
import { H5 } from '../../components/H5';
import { PlusIcon } from '../../components/icons';
import { ChildPopUp } from '../../components/Parent/ChildPopup';
import { usePopup } from '../../components/PopUp';
import { apiUrl } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { studentState } from '../../plugins/ridge';
import { Student, TestResult } from '../../types';
import { range } from '../../utils';

export const ManagePage = () => {
  const [popup, setPopup] = useState(false);
  const [studentId, setStudentId] = useState<number>(0);
  const { data: students, refetch } = useQuery<Student[]>(`/students`, fetcher);
  const studentMaxLength = 3;

  if (!students) return <>아직 자녀가 없습니다.</>;
  return (
    <>
      <ChildPopUp
        key={studentId}
        studentId={studentId}
        open={popup}
        onClose={() => {
          refetch();
          setPopup(false);
          setStudentId(0);
        }}
      />

      <H5 className="pb-5">자녀관리</H5>

      <div className="grid md:grid-cols-3 gap-6">
        {students?.map((student) => (
          <ChildCard
            key={student.id}
            onClick={() => {
              setPopup(true);
              setStudentId(student.id);
            }}
            student={student}
          />
        ))}
        {range(studentMaxLength - students.length).length !== 0 && (
          <div
            className="relative bg-white rounded-md shadow-3xl flex md:flex-col items-center md:justify-center px-5 py-5 md:py-16 space-y-1"
            onClick={() => {
              setPopup(true);
              setStudentId(0);
            }}
          >
            <button className=" bg-gray-50 rounded-full wh-14 grid place-content-center">
              <PlusIcon className="text-gray-300 stroke-current" />
            </button>
            <span className="pl-2 md:pl-0 pt-1 text-17">추가하기</span>
          </div>
        )}
      </div>
    </>
  );
};

interface ChildCardProps {
  student: Student;
  onClick: () => void;
}

const ChildCard: FC<ChildCardProps> = ({ student, onClick }) => {
  const { requireLevelTestPopup } = usePopup();

  const { data: testResult } = useQuery<TestResult>(
    `/test-results/active?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  return (
    <>
      <div className="relative bg-white rounded-md shadow-3xl flex md:flex-col items-center md:justify-center px-5 py-5 md:py-16 space-y-1">
        {student && (
          <button
            onClick={onClick}
            className="absolute top-10 md:top-5 right-5"
          >
            <PencilIcon />
          </button>
        )}

        {student && (
          <>
            <div
              className={`border-2 wh-14 md:wh-20 rounded-full p-1 ${
                true ? 'border-primary-2 ' : 'border-primary-3'
              }`}
            >
              <Avatar
                src={apiUrl(student.avatar)}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="flex flex-col md:items-center md:space-y-1 pl-2 md:pl-0">
              <p className="text-17">{student.name}</p>

              <div
                className={`text-12 rounded-md px-2 py-1
                ${
                  student.nextLevel
                    ? 'bg-secondary-1 text-primary-1'
                    : 'bg-primary-1 text-white cursor-pointer'
                }`}
                onClick={() => {
                  if (!student.nextLevel) {
                    localStorage.setItem('studentId', student?.id.toString());
                    studentState.set(student);
                    requireLevelTestPopup(student?.id);
                  }
                }}
              >
                {testResult ? testResult?.level?.name : '레벨테스트 하러 가기'}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
