import React, { FC } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { api } from '../plugins/axios';
import { LevelTest } from '../plugins/leveltest';
import {
  courseState,
  selectLevelNoTestPopupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import { Button } from './Button';
import { XIcon } from './icons';
import { Radio } from './Radio';
import { TextField } from './TextField';

interface FormValues {
  inflowPath: string;
}

export const SelectLevelNoTestPopUp = () => {
  const student = studentState.useValue();
  const { open } = selectLevelNoTestPopupState.useValue();
  const [level, setLevel] = useState('1-A');

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack px-4 overflow-scroll">
      <div className="flex w-full justify-center items-center min-h-screen">
        <div className="card">
          <div className="flex justify-between items-start">
            <h1 className="font-medium text-20">
              대략적인 학습 수준을 선택해주시면
              <br />
              체험수업 예습 컨텐츠를 제공해 드려요!
            </h1>
            <button
              className="mt-1"
              onClick={() => selectLevelNoTestPopupState.reset()}
            >
              <XIcon />
            </button>
          </div>
          <form
            className="space-y-6"
            onSubmit={handleSubmit((data) => {
              // 유입경로 저장할 필요가 있다면 사용
              api
                .patch(`/students/${student?.id}`, data)
                .then(() => {
                  selectLevelNoTestPopupState.reset();

                  // skip api start
                  api
                    .post(`/test-results/skip`, {
                      studentId: student?.id,
                      levelCode: level,
                    })
                    .then((res) => {
                      testResultState.set(res.data);
                      studentState.set(res.data.student);

                      // 체험수업 구매유무 확인
                      if (
                        res.data.student.courses &&
                        res.data.student.courses.length > 0
                      ) {
                        window.location.replace('/learn/reservation');
                        courseState.set(res.data.student.courses[0]);
                        localStorage.setItem(
                          'courseId',
                          res.data.student.courses[0]?.id.toString()
                        );
                      } else {
                        window.location.replace('/pricing/trial');
                      }
                    })
                    .catch((err) => {
                      alert(`${err.response.data.message}`);
                      return;
                    });
                  // skip api end
                })
                .catch((e) => console.log(e, '레벨테스트 접근 오류'));
            })}
          >
            <div className="mt-5 mb-10 flex flex-col max-h-72 overflow-y-auto overflow-x-hidden">
              <SelectLevelItem
                title="알파벳부터 시작해야하는 단계입니다. 영어가 처음이에요."
                description=""
                selected={level === '1-A'}
                onClick={() => setLevel('1-A')}
              />
              <SelectLevelItem
                title="알파벳을 알고 있으며, 단어를 듣고 따라 말할 수 있습니다."
                description=""
                selected={level === '1-B'}
                onClick={() => setLevel('1-B')}
              />
              <SelectLevelItem
                title="자신의 이름과 나이 등 간단하게 자기소개를 할 수 있습니다."
                description=""
                selected={level === '1-C'}
                onClick={() => setLevel('1-C')}
              />
              <SelectLevelItem
                title="알고 있는 영어 단어와 어구를 활용해 간단한 대화를 할 수 있습니다."
                description=""
                selected={level === '1-D'}
                onClick={() => setLevel('1-D')}
              />
              <SelectLevelItem
                title="간단한 문장을 듣고 이해할 수 있으며, 일상 생활에 대해 짧은 문장을 만들어 표현할 수 있습니다."
                description=""
                selected={level === '2-A'}
                onClick={() => setLevel('2-A')}
              />
              <SelectLevelItem
                title="익숙한 주제에 대한 글을 읽고 세부적인 내용을 파악할 수 있으며, 학습한 것을 바탕으로 문장으로 완성하여 말할 수 있습니다."
                description=""
                selected={level === '2-B'}
                onClick={() => setLevel('2-B')}
              />
              <SelectLevelItem
                title="자신의 경험과 생각에 대해 형식에 맞추어 쓰는 글쓰기가 가능합니다."
                description=""
                selected={level === '2-C'}
                onClick={() => setLevel('2-C')}
              />
              <SelectLevelItem
                title="대화 내용을 충분히 이해하고 자신있게 대화에 참여할 수 있으며, 기초 문법을 적용하여 창의적인 글쓰기가 가능합니다."
                description=""
                selected={level === '3-A'}
                onClick={() => setLevel('3-A')}
              />
              <SelectLevelItem
                title="올바른 문법과 어휘를 사용하여 자신의 생각을 문장으로 자유롭게 표현할 수 있으며, 설득력 있는 말하기가 가능합니다."
                description=""
                selected={level === '3-B'}
                onClick={() => setLevel('3-B')}
              />
              <SelectLevelItem
                title="전문 주제에 대한 중&#183;장편의 글을 읽고, 다소 복잡한 문장을 사용하여 자신의 의견을 논리적으로 표현할 수 있습니다."
                description=""
                selected={level === '3-C'}
                onClick={() => setLevel('3-C')}
              />
            </div>

            <TextField
              label="유입경로를 입력해주세요!"
              className="py-5"
              placeholder="ex) 소개, 블로그, SNS 등"
              helper={errors.inflowPath?.message}
              {...register('inflowPath', {
                required: '유입경로를 입력해주세요!',
              })}
            />

            <Button
              text="바로 체험수업 예약하기"
              className="filled-primary-1 mt-10 w-full"
              disabled={!watch('inflowPath')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export interface SelectLevelItemProps {
  title: string;
  description: string;
  selected: boolean;
  onClick: () => void;
}

export const SelectLevelItem: FC<SelectLevelItemProps> = ({
  title,
  description,
  selected,
  onClick,
}) => {
  return (
    <div
      className={`-mx-8 px-8 py-2 flex items-start space-x-2 ${
        selected ? 'bg-brand-2' : ''
      }`}
      onClick={onClick}
    >
      <Radio checked={selected} className="mt-1" />
      <div>
        <p className={`text-17 ${selected ? 'font-bold' : ''}`}>{title}</p>
        <p className="text-14 text-gray-600">{description}</p>
      </div>
    </div>
  );
};
