import React, { FC, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from '../../components/Label';
import { TextField } from '../../components/TextField';

export interface PhoneNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  number1: string;
  number2: string;
  number3: string;
  setNumber1: (v: string) => void;
  setNumber2: (v: string) => void;
  setNumber3: (v: string) => void;
  disabled: boolean;
}

export const AdminPhoneNumber: FC<PhoneNumberProps> = ({
  label,
  disabled,
  number1,
  number2,
  number3,
  setNumber1,
  setNumber2,
  setNumber3,
}) => {
  const [id] = useId(1, 'phonenumber');

  return (
    <div className="label-col">
      {label && <Label htmlFor={id} text={label} />}
      <div className="grid grid-cols-3 gap-4 pb-4">
        <TextField
          id={id}
          type="tel"
          pattern="[0-9]{3}"
          maxLength={3}
          placeholder="010"
          value={number1}
          disabled={disabled}
          onChange={(e) => {
            setNumber1(e.target.value);
            e.target.value = `${e.target.value}${number2}${number3}`;
          }}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder="0000"
          value={number2}
          disabled={disabled}
          onChange={(e) => {
            setNumber2(e.target.value);
            e.target.value = `${number1}${e.target.value}${number3}`;
          }}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder="0000"
          value={number3}
          disabled={disabled}
          onChange={(e) => {
            setNumber3(e.target.value);
            e.target.value = `${number1}${number2}${e.target.value}`;
          }}
        />
      </div>
    </div>
  );
};
