import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { LibraryCard } from '../components/LibraryCard';
import { LibraryTab } from '../components/LibraryTab';
import { Section } from '../components/Section';
import { fetcher } from '../plugins/react-query';
import { studentState, testResultState } from '../plugins/ridge';
import { ClassType, Course, Level, Series } from '../types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { ReactComponent as Arrow } from '../assets/svg/swiper_arrow_right.svg';
import { VideoLibraryPopup } from '../components/LibraryVideoPopup';

export const LibraryPage = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const student = studentState.useValue();
  const result = testResultState.useValue();
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [selectedSeries, setSelectedSeries] = useState<Series>();
  const { levelId, seriesId } = parse(search, { ignoreQueryPrefix: true });
  const [video, setVideo] = useState<{
    open: boolean;
    title?: string;
    videoPath?: string;
  }>({ open: false });

  const { data: levels } = useQuery<Level[]>(
    `/levels?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );
  const { data: courses } = useQuery<Course[]>(
    `/courses?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  // const validCourses = courses?.filter((course) => {
  //   const now = new Date();
  //   now.setHours(0, 0, 0, 0);
  //   const endedAt = new Date(course.endedAt);
  //   endedAt.setHours(0, 0, 0, 0);

  //   return endedAt.toISOString() >= now.toISOString() || !course.endedAt;
  // });

  const openedBookIds = new Set<number>();
  (courses ?? []).forEach((course) => {
    course.courseItems.forEach((ci) => {
      openedBookIds.add(ci.liveClass.bookId);
      // if (selectedLevel) {
      //   const serieses = selectedLevel?.serieses.filter(
      //     (series) => series.id === ci.liveClass.seriesId
      //   );
      //   if (serieses) {
      //     return serieses?.map((series) =>
      //       series.books?.map((book) => openedBookIds.add(book.id))
      //     );
      //   } else {
      //     return;
      //   }
      // } else {
      //   return;
      // }
    });
  });

  if (result?.bookId) {
    openedBookIds
      .add(2)
      .add(14)
      .add(32)
      .add(44)
      .add(56)
      .add(74)
      .add(86)
      .add(104)
      .add(116)
      .add(128);
  }

  // 김채리 특정 학생만 JTT교재 나의 도서관 접근 가능하게 처리
  if (student?.id === 184) {
    openedBookIds.add(116).add(117).add(118).add(119).add(120).add(121);
  }

  useEffect(() => {
    if (!levels || levels.length === 0) return;
    if (!levelId) {
      setSelectedLevel(levels[0]);
    } else {
      const levelIds = levels.map((level) => level.id);
      if (!levelIds.includes(Number(levelId.toString()))) {
        push('/library?levelId=1');
        return;
      }
      setSelectedLevel(levels.find((level) => level.id === Number(levelId)));
    }
  }, [selectedLevel, levels, levelId, push]);

  useEffect(() => {
    if (!selectedLevel || selectedLevel.serieses.length === 0) return;
    if (!seriesId) {
      setSelectedSeries(selectedLevel.serieses[0]);
    } else {
      const seriesIds = selectedLevel.serieses.map((series) => series.id);
      if (!seriesIds.includes(Number(seriesId.toString()))) {
        push('/library?levelId=1');
        return;
      }
      setSelectedSeries(
        selectedLevel.serieses.find((series) => series.id === Number(seriesId))
      );
    }
  }, [push, selectedLevel, seriesId]);

  if (!student) {
    return <></>;
  }
  return (
    <Section style={{ backgroundColor: '#F9FAFB' }} className="min-h-screen">
      {/* <VideoLibraryPopup
        open={video.open}
        onClose={() => {
          setVideo({ open: false });
        }}
        videoPath={!video.videoPath ? '' : video.videoPath}
        title={!video.title ? '' : video.title}
      ></VideoLibraryPopup> */}
      <div className="flex flex-col space-y-5 items-center mx-auto px-4 pt-8 pb-20 w-full max-w-5xl relative overflow-x-hidden">
        <Swiper
          className="mySwiper sm:max-w-none max-w-xs flex"
          slidesPerView="auto"
          spaceBetween={6}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          freeMode={true}
        >
          {(levels ?? []).map((level) => (
            <SwiperSlide className="w-auto">
              <LibraryTab
                key={level.id}
                item={level}
                selected={level.id === selectedLevel?.id}
                onClick={() => push(`/library?levelId=${level?.id}`)}
                className={`${
                  level.index === 1
                    ? 'border-primary-3'
                    : level.index === 2
                    ? 'border-primary-5'
                    : level.index === 3
                    ? 'border-primary-6'
                    : 'border-primary-4'
                }`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="md:block hidden">
          <Section className="px-10 flex content-center ">
            <Arrow className="swiper-button-prev-1 transform rotate-180 cursor-pointer wh-12 pl-2" />
            <Swiper
              pagination={false}
              slidesPerView="auto"
              spaceBetween={6}
              slidesOffsetBefore={0}
              slidesOffsetAfter={0}
              freeMode={true}
              navigation={{
                nextEl: '.swiper-button-next-1',
                prevEl: '.swiper-button-prev-1',
              }}
              className="mySwiper max-w-3xl flex"
            >
              {(selectedLevel?.serieses ?? []).map((series) => (
                <SwiperSlide className="w-auto ">
                  <LibraryTab
                    key={series.id}
                    item={series}
                    selected={series.id === selectedSeries?.id}
                    onClick={() =>
                      push(
                        `/library?levelId=${selectedLevel?.id}&seriesId=${series.id}`
                      )
                    }
                    className={`${
                      selectedLevel?.index === 1
                        ? 'border-primary-3'
                        : selectedLevel?.index === 2
                        ? 'border-primary-5'
                        : selectedLevel?.index === 3
                        ? 'border-primary-6'
                        : 'border-primary-4'
                    }`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <Arrow className="swiper-button-next-1 cursor-pointer wh-12 transform pl-1" />
          </Section>
        </div>

        <Swiper
          className="mySwiper w-screen max-w-5xl md:hidden block"
          slidesPerView="auto"
          spaceBetween={6}
          slidesOffsetBefore={0}
          slidesOffsetAfter={0}
          freeMode={true}
        >
          {(selectedLevel?.serieses ?? []).map((series) => (
            <SwiperSlide className="w-auto">
              <LibraryTab
                key={series.id}
                item={series}
                selected={series.id === selectedSeries?.id}
                onClick={() =>
                  push(
                    `/library?levelId=${selectedLevel?.id}&seriesId=${series.id}`
                  )
                }
                className={`${
                  selectedLevel?.index === 1
                    ? 'border-primary-3'
                    : selectedLevel?.index === 2
                    ? 'border-primary-5'
                    : selectedLevel?.index === 3
                    ? 'border-primary-6'
                    : 'border-primary-4'
                }`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-6 pt-10">
          {(selectedSeries?.books ?? []).map((book: any) => {
            var locked =
              !openedBookIds.has(book.id) ||
              (selectedLevel?.classType !== ClassType.SPECIAL &&
                !student?.isTested);

            return <LibraryCard key={book.id} book={book} locked={locked} />;
          })}
        </div>
      </div>
    </Section>
  );
};
