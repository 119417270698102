import React, { ButtonHTMLAttributes, FC } from 'react';

interface HomeTabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  title: string;
}

export const HomeTab: FC<HomeTabProps> = ({ selected, title, ...props }) => (
  <button
    type="button"
    className={`z-10 pt-6 pb-4 ${
      selected
        ? 'md:text-15 text-14 border-b-2 border-primary-1 text-gray-900 '
        : 'md:text-15 text-14 text-gray-500'
    }`}
    {...props}
  >
    {title}
  </button>
);
