import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { apiUrl } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { studentState } from '../../plugins/ridge';
import { Course, Student, TestResult } from '../../types';
import { Avatar } from '../Avatar';
import { usePopup } from '../PopUp';

interface StudentCardProps {
  student: Student;
}

export const StudentCard: FC<StudentCardProps> = ({ student }) => {
  const { push } = useHistory();
  const { requireLevelTestPopup } = usePopup();
  const { data: courses } = useQuery<Course[]>(
    `/courses?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  const { data: testResult } = useQuery<TestResult>(
    `/test-results/active?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  return (
    <div className="bg-white rounded-md shadow-3xl p-5">
      <div className="flex justify-between items-center">
        <div className="flex space-x-3 items-center">
          <div
            style={{ borderColor: '#8CDA29' }}
            className="border-2 wh-14 rounded-full p-1"
          >
            <Avatar
              src={apiUrl(student.avatar)}
              className="w-full h-full object-cover"
            />
          </div>

          <div>
            <p className="font-nanum text-17">{student.name}</p>
            <p className="bg-secondary-1 text-primary-1 w-max text-12 px-2 py-1 font-bold">
              {testResult ? `${testResult?.level?.name}` : '미응시'}
            </p>
          </div>
        </div>

        <button
          className={`${
            testResult ? 'bg-gray-50' : 'bg-primary-1 text-white'
          } px-2 text-13 rounded-md h-10`}
          onClick={() => {
            localStorage.setItem('studentId', student?.id.toString());
            if (testResult) {
              if (testResult.studentId === student.id) {
                studentState.set(student);
                push('/level-test/result');
              }
            } else {
              studentState.set(student);
              requireLevelTestPopup(student?.id);
            }
          }}
        >
          {testResult ? '레벨테스트 결과' : '레벨테스트 하러 가기'}
        </button>
      </div>
      {courses?.map((course) => (
        <>
          <div className="border border-gray-50 -mx-5 my-5" />
          <div className="text-20 font-bold mb-3">
            {course.isTrial && course.initialAmount === 1
              ? '체험'
              : course.isTrial && course.initialAmount > 1
              ? `${course?.initialAmount}회 체험팩`
              : `${course?.initialAmount}회`}
          </div>
          <div className="grid md:grid-cols-3 gap-y-3">
            <InfoItem
              title="시작 레벨"
              description={
                course.startLevelId ? `${course.startLevelId}단계` : '체험'
              }
            />
            <InfoItem
              title="전체 횟수"
              description={`${course?.initialAmount}회`}
            />
            <InfoItem
              title="잔여 횟수"
              description={`${course?.remainingAmount}회`}
            />
            <InfoItem
              title="결제일"
              description={utcToLocalFormat(
                course?.createdAt || '',
                MomentFormat.YYYYMMDD
              )}
            />
            <InfoItem
              title="시작일"
              description={
                course.startedAt
                  ? `${utcToLocalFormat(
                      course.startedAt,
                      MomentFormat.YYYYMMDD
                    )}`
                  : '-'
              }
            />
            <InfoItem
              title="유효기간"
              description={
                course.endedAt
                  ? `${utcToLocalFormat(
                      course.endedAt,
                      MomentFormat.YYYYMMDD
                    )} 까지`
                  : '-'
              }
            />
          </div>
        </>
      ))}
    </div>
  );
};

interface InfoItemProps {
  title?: string;
  description?: string;
}

const InfoItem: FC<InfoItemProps> = ({ title, description }) => {
  return (
    <div className="flex text-12">
      <p className="text-gray-400 w-1/3">{title}</p>
      <p className="flex-1">{description}</p>
    </div>
  );
};
