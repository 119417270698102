import React, { useEffect, useState } from 'react';
import { EventsTab } from '../../components/EventsTab';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { EventsCard } from '../../components/EventsCard';
import { fetcher } from '../../plugins/react-query';
import { useQuery } from 'react-query';
import { Category, EventBoard, Paginated } from '../../types';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { useQueryString } from '../../hooks';
import { Pagination } from '../../components/Pagination';

export const EventsList = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { categoryId } = parse(search, { ignoreQueryPrefix: true });
  const [tab, setTab] = useState<number>();
  const queryString = useQueryString({ limit: '9' });

  const { data: categories } = useQuery<Category[]>(
    `/categories/isHide`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const { data: events } = useQuery<Paginated<EventBoard>>(
    `/event-boards/category${queryString}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    if (categoryId) {
      setTab(Number(categoryId));
    }
  }, [categoryId]);

  return (
    <div className="max-w-container ">
      <div className="text-center space-y-3 py-16">
        <h1 className="text-3xl">TUNTUNLIVE EVENT</h1>
        <p>튼튼영어 라이브만의 특별한 혜택을 놓치지 마세요!</p>
      </div>

      <Swiper
        className="mySwiper max-w-5xl w-fit mx-auto justify-center"
        slidesPerView="auto"
        spaceBetween={6}
        slidesOffsetBefore={0}
        centeredSlidesBounds={true}
        centeredSlides={true}
        slidesOffsetAfter={0}
        freeMode={true}
      >
        <SwiperSlide className="w-auto">
          <EventsTab
            text="All"
            selected={!tab || !categoryId}
            onClick={() => push(`/events`)}
          />
        </SwiperSlide>
        {categories?.map((category) => (
          <SwiperSlide className="w-auto ">
            <EventsTab
              text={category.title}
              selected={category.id === Number(categoryId)}
              onClick={() => push(`/events?categoryId=${category.id}`)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-5 pt-5">
        {!events || events?.items?.length === 0 ? (
          <div className="mt-5">현재 진행 중인 이벤트가 없습니다.</div>
        ) : (
          events?.items?.map((event) => <EventsCard event={event} />)
        )}
      </div>

      <Pagination className="flex justify-center mt-10">
        <Pagination.Nav basePath="/events" total={events?.total || 0} />
      </Pagination>
    </div>
  );
};
