import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as PhoneIcon } from '../../assets/svg/icon-phone.svg';
import { H4 } from '../../components/H4';
import { MailIcon } from '../../components/icons';
import { MobileBackButton } from '../../components/MobileBackButton';

export const PasswordFindPage = () => {
  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4">
      <MobileBackButton />

      <H4 className="pb-8">비밀번호 찾기</H4>

      <div className="flex flex-col space-y-5">
        <Link
          to="/password/find/email"
          className="flex items-center space-x-4 p-5 rounded-lg bg-secondary-1 bg-opacity-50"
        >
          <div className="bg-secondary-1 wh-11 rounded-lg grid place-content-center">
            <MailIcon className="text-primary-1 stroke-current" />
          </div>
          <span>이메일로 찾기</span>
        </Link>

        <Link
          to="/password/find/phone"
          className="flex items-center space-x-4 p-5 rounded-lg bg-secondary-2 bg-opacity-50"
        >
          <div className="bg-secondary-2 wh-11 rounded-lg grid place-content-center">
            <PhoneIcon />
          </div>
          <span>휴대폰 번호로 찾기</span>
        </Link>
      </div>
    </div>
  );
};
