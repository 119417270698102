import React, { FC } from 'react';
import BgImg from '../../assets/img/main-bg-1.png';
import { StarIcon } from '../../components/icons';
import { range } from '../../utils';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { isMobile } from 'react-device-detect';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const reviews = [
  {
    id: 1,
    description: `라이브쌤 덕분에, 영어가 싫다던
    아이가 영어를 재밌어하고
    자신감도 가지게 되었어요!
    칭찬 듬뿍해주셔서 아이가
    정말 행복해해요!`,
    name: '초O맘',
  },
  {
    id: 2,
    description: `아이가 생활 속에서 영어로
    말하기 시작했어요.
    라이브쌤 덕분이에요!`,
  },
  {
    id: 3,
    description: `화상이지만, 선생님의 교육에
    대한 열정과 사랑이 옆에서
    지켜보는 제게도 느껴질 
    정도입니다! 아이가 엉뚱하게
    말해도 환하게 웃어주시고,
    칭찬 많이 해주셔서 감사합니다.`,
  },
  {
    id: 1,
    description: `라이브쌤 덕분에, 영어가 싫다던
    아이가 영어를 재밌어하고
    자신감도 가지게 되었어요!
    칭찬 듬뿍해주셔서 아이가
    정말 행복해해요!`,
    name: '초O맘',
  },
  {
    id: 2,
    description: `아이가 생활 속에서 영어로
    말하기 시작했어요.
    라이브쌤 덕분이에요!`,
  },
  {
    id: 3,
    description: `화상이지만, 선생님의 교육에
    대한 열정과 사랑이 옆에서
    지켜보는 제게도 느껴질 
    정도입니다! 아이가 엉뚱하게
    말해도 환하게 웃어주시고,
    칭찬 많이 해주셔서 감사합니다.`,
  },
];

interface ReviewSectionProps {}

export const ReviewSection: FC<ReviewSectionProps> = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url('${BgImg}')` }}
        className="bg-center bg-cover bg-no-repeat con-pa relative"
      >
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className=""
        >
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center ">
            화상으로도 넘치게 가능한
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
            영어수업의 즐거움
          </h2>
          <p className="text-15 md:text-xl text-black font-medium sub-txt01 text-center">
            라이브 회원이 전하는 생생한 수업후기
          </p>

          {/* <div className="md:w-782 w-96 mx-auto px-8 mt-10 mb-20">
            <div className="relative aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
              <iframe
                // width="560"
                // height="315"
                src="https://www.youtube.com/embed/jMnffwO2drI"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div> */}
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="relative "
        >
          <Swiper
            // pagination={true}
            centeredSlides={isMobile ? false : true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={{
              nextEl: '.swiper-button-next-1',
              prevEl: '.swiper-button-prev-1',
            }}
            loop={true}
            className="mySwiper "
            breakpoints={{
              320: { slidesPerView: 1.5 },
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1536: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
          >
            {reviews.map((review) => (
              <SwiperSlide key={String(review.id)}>
                <div
                  // style={{ width: '22vw' }}
                  className="ml-5 md:mx-0 flex flex-col font-nanum lg:text-20 md:text-base text-13 leading-relaxed bg-white shadow-blue rounded-xl p-10 my-10 lg:h-96 md:h-80 h-72 overflow-hidden"
                >
                  <p className="flex-1 ">{review.description}</p>

                  <div className="flex space-x-2 my-2">
                    {range(5).map((star) => (
                      <StarIcon className="fill-current text-yellow-200 blue-star" />
                    ))}
                  </div>
                  {/* <span className="text-gray-300 font-semibold">
                    {review.name}
                  </span> */}
                </div>
              </SwiperSlide>
            ))}
            {/* <div className=" absolute left-1/2 top-0">
              <Arrow className="swiper-button-prev-1 transform rotate-180 cursor-pointer absolute z-10 md:top-1/3 left-7 wh-24 translate-y-4" />
              <Arrow className="swiper-button-next-1 cursor-pointer absolute z-10 wh-24 right-7 md:top-1/3 transform translate-y-4" />
            </div> */}
          </Swiper>
        </ScrollAnimation>
      </div>
    </>
  );
};
