import React, { useState } from 'react';
import speakingTopImg from '../../assets/img/speaking-top-img.jpg';
import mobilespeakingTopImg from '../../assets/img/speaking-top-img_m.jpg';
import speakingImg01 from '../../assets/img/speaking-01.jpg';
import speakingImg02 from '../../assets/img/speaking-img01.png';
import speakingImg03 from '../../assets/img/speaking-03.png';
import speakingImg04 from '../../assets/img/speaking-04.png';
import speakingImg05 from '../../assets/img/speaking-05.png';
import speakingImg06 from '../../assets/img/speaking-06.png';
import speakingBookImg01 from '../../assets/img/speaking-book01.png';
import speakingBookImg02 from '../../assets/img/speaking-book02.png';
import speakingBookImg03 from '../../assets/img/speaking-book03.png';
import speakingBookImg04 from '../../assets/img/speaking-book04.png';
import reviewImg01 from '../../assets/img/speaking-review-01.png';
import reviewImg02 from '../../assets/img/speaking-review-02.png';
import reviewImg03 from '../../assets/img/speaking-review-03.png';
import { isMobile } from 'react-device-detect';
import ScrollAnimation from 'react-animate-on-scroll';
import { useHistory } from 'react-router-dom';
import { speakingsteps01 } from '../home/images';
import { ChevronLeftIcon, ChevronRightIcon } from '../../components/icons';
// import { ChevronRightIcon } from '@heroicons/react/outline';

export const SpeakingPage = () => {
  const { push } = useHistory();
  const [step, setStep] = useState(speakingsteps01[0]);

  return (
    <>
      <div className="font-nanum">
        <div className="speaking-main">
          <img src={isMobile ? mobilespeakingTopImg : speakingTopImg}></img>
        </div>
        <div className="con-pa">
          <div className="md:block w-full md:max-w-7xl md:mx-auto">
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
                예습 없이 수업은 가볍게
              </h2>
              <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
                실력은 확실하게!
              </h2>
              <p className=" md:text-xl text-black text-center font-medium sub-txt01">
                평면적인 수업 콘텐츠를 넘어 화상 수업에 최적화된
                <br />
                활동 중심 위주의 영어 말하기 수업
              </p>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <div className="bj-if-wrap">
                <div className="bj-if">
                  <iframe
                    className="w-full h-full object-cover absolute"
                    src={`https://www.youtube.com/embed/QQXBIkN5fdg`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="bg-light-blue md:max-w-container max-w-full con-pa con-pa-b-n">
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
              일상 생활을 주제로
            </h2>
            <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
              살아있는 영어 말하기
            </h2>
            <p className=" md:text-xl text-black text-center font-medium sub-txt01">
              실용성과 흥미를 모두 고려한
              <br />
              탄탄한 커리큘럼
            </p>
          </ScrollAnimation>
          <div className="md:block speaking-w md:max-w-7xl md:mx-auto">
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <div className="speaking-con01 speaking-ma">
                <img src={speakingImg02} />
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="con-pa">
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
              일상 생활을 주제로
            </h2>
            <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
              살아있는 영어 말하기
            </h2>
            <p className=" md:text-xl text-black text-center font-medium sub-txt01">
              실용성과 흥미를 모두 고려한 탄탄한 커리큘럼
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="speaking-subtit">대표 주제 미리보기</div>
            {/* PC용 */}
            <div className="md:block hidden w-full md:max-w-7xl md:mx-auto s-tab-tab">
              <div
                className="flex gap-5 justify-center rounded-full xl:mx-48 lg:mx-32 md:mx-24 items-center my-10 "
                // style={{ backgroundColor: '#F2F4F6' }}
              >
                {speakingsteps01.map((s) => (
                  <button
                    className={`${
                      s === step && s.id === 1
                        ? 'filled-primary-3 on on01'
                        : s === step && s.id === 2
                        ? 'filled-primary-5 on on02'
                        : s === step && s.id === 3
                        ? 'filled-primary-6 on on03'
                        : s === step && s.id === 4
                        ? 'filled-primary-4 on on04'
                        : 'text-gray-200'
                    } text-center w-full rounded-full speakingsteps-btn01 steps-btn`}
                    onClick={() => setStep(s)}
                  >
                    {s.title}
                  </button>
                ))}
              </div>
            </div>

            {/* 모바일용 */}
            <div className="block md:hidden w-full s-tab-tab">
              <div className="flex justify-between md:space-x-5 space-x-12 mx-6 py-8 ">
                <button
                  className="p-2.5 border border-gray-100 rounded-full "
                  onClick={() =>
                    setStep(speakingsteps01[step.id < 2 ? 3 : step.id - 2])
                  }
                >
                  <ChevronLeftIcon className="text-gray-300 md:wh-7 wh-6" />
                </button>
                <button
                  className={`${
                    step.id === 1
                      ? 'filled-primary-3 on on01'
                      : step.id === 2
                      ? 'filled-primary-5 on on02'
                      : step.id === 3
                      ? 'filled-primary-6 on on03'
                      : step.id === 4
                      ? 'filled-primary-8 on on04'
                      : 'text-gray-200'
                  } md:text-2xl text-17 text-center py-2 w-full rounded-full`}
                >
                  {speakingsteps01[step.id - 1].title}
                </button>
                <button
                  className="p-2.5 border border-gray-100 rounded-full"
                  onClick={() =>
                    setStep(speakingsteps01[step.id > 3 ? 0 : step.id])
                  }
                >
                  <ChevronRightIcon className="text-primary-1 md:wh-7 wh-6" />
                </button>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="speaking-book-area">
              {step === speakingsteps01[0] && <img src={speakingBookImg01} />}
              {step === speakingsteps01[1] && <img src={speakingBookImg02} />}
              {step === speakingsteps01[2] && <img src={speakingBookImg03} />}
              {step === speakingsteps01[3] && <img src={speakingBookImg04} />}
            </div>
          </ScrollAnimation>
        </div>
        <div className="bg-light-blue con-pa">
          <div className="md:block  w-full md:max-w-7xl md:mx-auto">
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
                이런 친구들에게
              </h2>
              <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
                추천해요!
              </h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <div className="speaking-con02 speaking-w speaking-shadow speaking-ma">
                <div className="txt">
                  <p>
                    #영어로 자신 있게 말하기가 부끄러운 친구
                    <br />
                    #단순 암기 위주의 학습식 영어가 버거운 친구
                    <br />
                    #글을 읽고 이해하는 문해력은 갖춰졌지만 발화가 되지 않는
                    친구
                    <br />
                    #신나게 놀면서 영어랑 친해지고 싶은 친구
                    <br />
                    #영어 말하기에 이제 막 재미를 붙인 친구
                    <br />
                  </p>
                </div>
                <img src={speakingImg01} style={{ margin: '0 auto' }} />
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="md:max-w-container max-w-full con-pa con-pa-b-n">
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
              테스트 수업 평점
            </h2>
            <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
              4.9/5
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <ul className="speaking-con03 speaking-w speaking-c speaking-ma">
              <li>
                <a
                  style={{
                    backgroundImage: `url('${reviewImg01}')`,
                  }}
                  className="bg-no-repeat review01"
                >
                  선생님이 아이의 수준에 맞게 수업을 잘 이끌어주세요.
                </a>
              </li>
              <li>
                <a
                  style={{
                    backgroundImage: `url('${reviewImg02}')`,
                  }}
                  className="bg-no-repeat review02"
                >
                  스피킹 수업에 걸맞게 영어를 말하는 양이 엄청 많네요.
                  <br />
                  만족해요!
                </a>
              </li>
              <li>
                <a
                  style={{
                    backgroundImage: `url('${reviewImg01}')`,
                  }}
                  className="bg-no-repeat review01"
                >
                  말하기랑 게임이 너무 재미있다고 또 하고 싶다네요~~
                </a>
              </li>
              <li>
                <a
                  style={{
                    backgroundImage: `url('${reviewImg03}')`,
                  }}
                  className="bg-no-repeat review02"
                >
                  예습이랑 과제가 없어서 부담 없어요!
                  <br />
                  놀기 좋아하는 우리 아이한테 딱이에요.
                </a>
              </li>
            </ul>
          </ScrollAnimation>
        </div>
        {/* <button
          className="speaking-btn"
          onClick={() => {
            push('/pricing/special');
          }}
        >
          수강신청
        </button> */}
      </div>
    </>
  );
};
