import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AuthRoute } from '../components/AuthRoute';
import { GlobalState } from '../components/GlobalState';
import { TopNavBar } from '../components/TopNavBar';
import { CurriculumPage } from './curriculum/CurriculumPage';
import { SpeakingPage } from './curriculum/SpeakingPage';
import { LoginPage } from './GuestOnly/LoginPage';
import { PasswordPage } from './GuestOnly/PasswordPage';
import { SignupPage } from './GuestOnly/SignupPage';
import { TestSignupPage } from './GuestOnly/TestSignupPage';
import { HomePage } from './home/HomePage';
import { LearnPage } from './LearnPage';
import { LevelTestResultPage } from './LevelTestResultPage';
import { LibraryPage } from './LibraryPage';
import { LiveFeedbackPage } from './LiveFeedbackPage';
import { LiveTeacherPage } from './LiveTeacher/LiveTeacherPage';
import { PageCallContentPage } from './PageCallContentPage';
import { ParentPage } from './Parent/ParentPage';
import { PricingPage } from './PricingPage';
import { PricingReinforcePage } from './PricingReinforcePage';
import { PurchasePage } from './PurchasePage';
import { PurchaseSuccessPage } from './PurchaseSuccessPage';
import { ReinforcePurchasePage } from './ReinforcePurchasePage';
import { ReservationTeacherFirstPage } from './ReservationTeacherFirstPage';
import { ReservationTimeFirstPage } from './ReservationTimeFirstPage';
import { SignupSuccessPage } from './SignupSuccessPage';
import { StudentsAddPage } from './StudentsAddPage';
import { StudentsPage } from './StudentsPage';
import { TeacherPage } from './Teacher/TeacherPage';
import { Footer } from '../components/Footer';
import { QuickMenu } from '../components/QuickMenu';
import ReactGA from 'react-ga4';
import { TrialPurchasePage } from './TrialPurchasePage';
import { EventsList } from './events/EventsList';
import { EventsDetail } from './events/EventsDetail';
import { FaqPage } from './FaqPage';
import { NewPolicyPage } from './NewPolicy';
import { NewPolicyUpdatePage } from './NewPolicyUpdate';
import { NewTermsPage } from './NewTerms';
import { NewLocationPage } from './NewLocation';
import { SpecialPurchasePage } from './SpecialPurchasePage';

export const Router = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      page_title: pathname,
      page_location: pathname,
    });
    // 광고전환추적
    ReactGA.gtag('event', 'conversion', {
      send_to: 'AW-10905165221/6jB4CLHX-MMDEKW7_s8o',
    });
    // window.kakaoPixel('7492043441117771610').pageView();
  }, [pathname]);

  return (
    <div className="min-h-screen flex flex-col">
      <GlobalState />
      <Switch>
        <Route
          path={[
            '/reservation',
            '/pagecall-content',
            '/signup/success',
            '/students',
            '/students/add',
          ]}
        />
        <Route path="/" component={TopNavBar} />
      </Switch>
      <main
        className={`flex-1 ${pathname.startsWith('/teacher') && 'bg-gray-50'}`}
      >
        <Switch>
          {/* Teacher Router */}
          <AuthRoute path="/teacher" component={TeacherPage} />
          {/* Parent Router */}
          <AuthRoute path="/parent" component={ParentPage} />
          {/* Student Router */}
          <AuthRoute path="/lives/feedback" component={LiveFeedbackPage} />
          <AuthRoute
            path="/level-test/result"
            component={LevelTestResultPage}
          />
          <AuthRoute
            path="/reservation/teacher-first"
            component={ReservationTeacherFirstPage}
          />
          <AuthRoute
            path="/reservation/time-first"
            component={ReservationTimeFirstPage}
          />
          <AuthRoute path="/purchase/success" component={PurchaseSuccessPage} />
          <AuthRoute path="/trial/purchase" component={TrialPurchasePage} />
          <AuthRoute path="/special/purchase" component={SpecialPurchasePage} />
          <AuthRoute
            path="/reinforce/purchase"
            component={ReinforcePurchasePage}
          />
          <AuthRoute path="/purchase" component={PurchasePage} />
          <AuthRoute path="/library" component={LibraryPage} />
          <AuthRoute path="/learn" component={LearnPage} />
          <AuthRoute path="/students/add" component={StudentsAddPage} />
          <AuthRoute path="/students" component={StudentsPage} />
          <Route path="/pagecall-content" component={PageCallContentPage} />
          <Route path="/events/:id" component={EventsDetail} />
          <Route path="/events" component={EventsList} />
          <Route path="/curriculum" component={CurriculumPage} />
          <Route path="/speaking" component={SpeakingPage} />
          <Route path="/live-teacher" component={LiveTeacherPage} />
          <AuthRoute
            path="/pricing/reinforce"
            component={PricingReinforcePage}
          />
          {/* <Route path="/pricing" component={PricingPage} /> */}
          <Route path="/signup/success" component={SignupSuccessPage} />
          <AuthRoute path="/signup/test" component={TestSignupPage} guestOnly />
          <AuthRoute path="/signup" component={SignupPage} guestOnly />
          <AuthRoute path="/login" component={LoginPage} guestOnly />
          <AuthRoute path="/password" component={PasswordPage} guestOnly />
          <Route path="/" component={HomePage} exact />
          <Route path="/Faq" component={FaqPage} />
          <Route path="/NewPolicy" component={NewPolicyPage} />
          <Route path="/NewPolicyUpdate" component={NewPolicyUpdatePage} />
          <Route path="/NewLocation" component={NewLocationPage} />
          <Route path="/NewTerms" component={NewTermsPage} />
          <Redirect from="/" to="/" />
        </Switch>
        {/* 퀵메뉴 */}
        <Route path="/" component={QuickMenu} />
      </main>
      <Switch>
        <Route path="/" component={Footer} />
      </Switch>
    </div>
  );
};
