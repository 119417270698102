import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ScheduleCalendar } from '../../components/ScheduleCalendar';
import { fetcher } from '../../plugins/react-query';
import { Live, Paginated } from '../../types';
import moment from 'moment';

export const TeacherSchedule = () => {
  const { id } = useParams<{ id: string }>();
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [weekFrom, setWeekFrom] = useState<any>();
  const [weekTo, setWeekTo] = useState<any>();
  const [type, setType] = useState<string>('month');

  const timeTo = new Date(weekTo?.toISOString());
  timeTo.setHours(timeTo.getHours() + 9);

  const formatFrom = from && moment(from).startOf('M').add(9, 'h');
  const formatTo = to && moment(to).endOf('M').add(9, 'h');

  const { data, refetch } = useQuery<Paginated<Live>>(
    `/admin/lives?filter[teacherId]=${id}&filter[start][from]=${
      type === 'month' ? formatFrom?.toISOString() : weekFrom?.toISOString()
    }&filter[start][to]=${
      type === 'month' ? formatTo?.toISOString() : timeTo?.toISOString()
    }&limit=1000&join[0]=student&join[1]=liveFeedbacks&join[2]=course&join[3]=teacher`,
    fetcher,
    {
      keepPreviousData: true,
      enabled:
        !!id && type === 'month' ? !!from && !!to : !!weekFrom && !!timeTo,
    }
  );

  return (
    <div>
      <ScheduleCalendar
        lives={data?.items.filter((l) => l)}
        onChangeMonth={(f, t) => {
          setFrom(f);
          setTo(t);
        }}
        onChangeWeek={(f, t) => {
          setWeekFrom(f);
          setWeekTo(t);
        }}
        selected
        refetch={() => refetch()}
        setType={(type) => setType(type)}
      />
    </div>
  );
};
