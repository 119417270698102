import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AdminTabs } from '../components/AdminTabs';
import { AdminTab } from '../components/AdminTab';
import { TeacherTransactionPayItemDetails } from './TeacherTransactionPayItemDetails';
import { TeacherTransactionIncentiveDetails } from './TeacherTransactionIncentiveDetails';
import { TeacherTransactionPenaltyDetails } from './TeacherTransactionPenaltyDetails';

export const TeacherTransactionDetails = () => {
  const { pathname } = useLocation();
  const chunks = pathname.split('/');
  const basePath = `/${chunks[1]}/${chunks[2]}/${chunks[3]}/${chunks[4]}/${chunks[5]}`;
  const subPath = chunks[6];

  return (
    <>
      <AdminTabs>
        <AdminTab
          text="정산 내역"
          to={
            pathname === '/admin/teachers/:id/transactions/:payId/payItems'
              ? ''
              : `${basePath}/payItems`
          }
          selected={subPath === 'payItems'}
        />
        <AdminTab
          text="장려금 내역"
          to={
            pathname === '/admin/teachers/:id/transactions/:payId/incentive'
              ? ''
              : `${basePath}/incentive`
          }
          selected={subPath === 'incentive'}
        />
        <AdminTab
          text="페널티 내역"
          to={
            pathname === '/admin/teachers/:id/transactions/:payId/penalty'
              ? ''
              : `${basePath}/penalty`
          }
          selected={subPath === 'penalty'}
        />
      </AdminTabs>

      <Switch>
        <Route
          path="/admin/teachers/:id/transactions/:payId/payItems"
          component={TeacherTransactionPayItemDetails}
        />
        <Route
          path="/admin/teachers/:id/transactions/:payId/incentive"
          component={TeacherTransactionIncentiveDetails}
        />
        <Route
          path="/admin/teachers/:id/transactions/:payId/penalty"
          component={TeacherTransactionPenaltyDetails}
        />
        <Redirect
          from="/admin/teachers/:id/transactions/:payId"
          to="/admin/teachers/:id/transactions/:payId/payItems"
        />
      </Switch>
    </>
  );
};
