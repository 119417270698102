import { parse } from 'qs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { useAuth } from '../../hooks';
import { apiUrl } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { popupState } from '../../plugins/ridge';
import {
  CancelType,
  Entry,
  Gender,
  Live,
  LiveState,
  LiveType,
  Paginated,
  Student,
  TestResult,
  User,
} from '../../types';
import { MobileHelper } from './MobileHelper';
// import { io, Socket } from 'socket.io-client';
import { chain } from 'lodash';
// import ChannelService from '../../plugins/ChannelService';
import moment from 'moment';
import { TextArea } from '../../components/TextArea';

export const StudentsDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { authenticated } = useAuth();
  const { courseId } = parse(search, { ignoreQueryPrefix: true });
  // const [socket, setSocket] = useState<Socket | null>(null);
  const [liveId, setLiveId] = useState<number>();

  const { data: me } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  const { data: student } = useQuery<Student>(
    `/students/byTeacher/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  const { data: testResults } = useQuery<Paginated<TestResult>>(
    `/test-results/?sort[createdAt]=DESC&courseId=${courseId}&filter[studentId]=${id}&join[0]=series&join[1]=book&join[2]=level`,
    fetcher,
    { enabled: !!id, keepPreviousData: true }
  );

  const testResult = testResults?.items?.sort((a, b) => b.id - a.id)[0];

  const { data: entries } = useQuery<Entry>(
    `/entries?testResultId=${testResults?.items[0]?.id}`,
    fetcher,
    { enabled: testResults?.total !== 0 }
  );

  // const { data: course } = useQuery<Course>(
  //   `/courses/byTeacher/${courseId}`,
  //   fetcher,
  //   { enabled: !!courseId }
  // );

  const { data: lives } = useQuery<Live[]>(
    `/lives/byTeacher/${courseId}`,
    fetcher,
    { enabled: !!courseId }
  );

  // useEffect(() => {
  //   const socket = io(`https://www.tuntunlive.com/liveSocket`);
  //   socket.on('close', () => push(`/lives/feedback?liveId=${liveId}`));
  //   setSocket(socket);
  //   return () => {
  //     socket.close();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const studentInfoEntrySeries =
    entries?.levelCode === '1-A'
      ? 'Hear Me 1권'
      : entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C'
      ? 'My Day 1권'
      : entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A'
      ? `School's Cool 1권`
      : entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A'
      ? 'What to Say 1권'
      : entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  const entrySeries =
    entries?.levelCode === '1-A' || entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C' || entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A' || entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A' || entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  // ChannelService.boot({
  //   pluginKey: process.env.REACT_APP_CHANNEL_IO_KEY,
  //   mobileMessengerMode: 'iframe',
  //   zIndex: 100,
  //   customLauncherSelector: '#cannelIo-button4',
  //   profile: {
  //     name: me ? me.name : '비회원',
  //     mobileNumber: me ? me.phoneNumber : '-',
  //     email: me ? me.email : '-',
  //     avatarUrl: me?.avatar ? apiUrl(me.avatar) : '-',
  //   },
  // });

  if (!me) {
    return <></>;
  }
  return (
    <>
      <div className="max-w-container space-y-5 hidden md:block">
        <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden p-10 flex space-x-10">
          <div className="mx-auto text-center flex-shrink-0">
            <button className="border-2 border-primary-7 rounded-full wh-14">
              <Avatar className="wh-12" src={apiUrl(student?.avatar)} />
            </button>
            <div className="mt-2 font-semibold text-lg">{student?.name}</div>
          </div>
          <div className="flex-1 grid grid-cols-3 gap-5 text-sm">
            <div className="flex space-x-3">
              <div className="col-span-3 text-lg font-semibold">학생정보</div>
            </div>
            {/** 여기까지*/}
            <div />
            <div />
            {/** grid 밀어내는 용*/}
            <div className="space-y-2">
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  생년월일
                </label>
                <div className="flex-1 text-gray-400">
                  {utcToLocalFormat(
                    student?.dob?.toLocaleString() || '',
                    MomentFormat.YYYYMMDD
                  )}
                </div>
              </div>
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  성별
                </label>
                <div className="flex-1 text-gray-400">
                  {student?.gender === Gender.FEMALE ? '여자' : '남자'}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  나이
                </label>
                <div className="flex-1 text-gray-400">
                  {new Date().getFullYear() -
                    new Date(
                      student?.dob?.substring(0, 4) || ''
                    ).getFullYear() +
                    1}
                  세
                </div>
              </div>
              {/* <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  부모님 성함
                </label>
                <div className="flex-1 text-gray-400">
                  {student?.user?.name}
                </div>
              </div> */}
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  가입시 레벨
                </label>
                <div className="flex-1 text-gray-400">
                  {testResults?.items[0] ? testResults.items[0].level.name : ''}
                </div>
              </div>
            </div>

            <div className="space-y-2">
              {/* <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  가입일
                </label>
                <div className="flex-1 text-gray-400">
                  {utcToLocalFormat(
                    student?.createdAt.toLocaleString() || '',
                    MomentFormat.YYYYMMDD
                  )}
                </div>
              </div> */}
              {/* <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  유입경로
                </label>
                <div className="flex-1 text-gray-400">
                  {student?.inflowPath}
                </div>
              </div> */}
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  가입시 배정 교재
                </label>
                <div className="flex-1 text-gray-400">
                  {studentInfoEntrySeries}
                </div>
              </div>
              <div className="flex">
                <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                  가입시 학습 수준
                </label>
                <div className="flex-1 text-gray-400">
                  {testResult?.isSkip
                    ? '레벨테스트를 응시하지 않았습니다.'
                    : entries
                    ? entries.levelCode === '1-A'
                      ? '알파벳부터 시작해야하는 단계입니다.'
                      : entries.levelCode === '1-B'
                      ? '알파벳부터 시작해야하는 단계입니다.'
                      : entries.levelCode === '1-C'
                      ? '알파벳을 알고 있으며, 기초단어를 듣고, 따라 말할 수 있습니다.'
                      : entries.levelCode === '1-D'
                      ? '알파벳을 알고 있으며, 기초단어를 듣고, 따라 말할 수 있습니다.'
                      : entries.levelCode === '2-A'
                      ? '알고 있는 여러 영어 단어들을 활용해, 자신의 의사를 표현할 수 있습니다.'
                      : entries.levelCode === '2-B'
                      ? '알고 있는 여러 영어 단어들을 활용해, 자신의 의사를 표현할 수 있습니다.'
                      : entries.levelCode === '2-C'
                      ? '일상 주제에 대해 하나의 완벽한 문장을 말할 수 있습니다.'
                      : entries.levelCode === '3-A'
                      ? '일상 주제에 대해 하나의 완벽한 문장을 말할 수 있습니다.'
                      : entries.levelCode === '3-B'
                      ? '다양한 주제에 대해 여러 문장을 이어 말할 수 있습니다.'
                      : entries.levelCode === '3-C'
                      ? '전문 주제에 대한 중~장편의 글을 읽고 내용을 이해할 수 있습니다.'
                      : ''
                    : '아직 레벨테스트 결과가 없습니다.'}
                </div>
              </div>
            </div>
            <div className="col-span-3 space-y-1">
              <label className="w-20 flex-shrink-0 font-semibold text-gray-600">
                관리자 메모
              </label>
              <TextArea disabled value={student?.memo} />
            </div>
          </div>
        </div>
        <div className="w-full max-w-5xl mx-auto">
          <p className="flex justify-end mb-2 text-sm text-gray-500">
            학생 레벨 변경 요청시
            <span
              id="cannelIo-button4"
              className="underline ml-1 cursor-pointer font-semibold"
            >
              문의하기
            </span>
            를 클릭해주세요.
          </p>
          <div className="rounded-lg bg-white shadow-lg overflow-hidden">
            <Table>
              <Table.Head className="bg-white">
                <Table.Row>
                  <Table.Th>회차</Table.Th>
                  <Table.Th>수업 구분</Table.Th>
                  <Table.Th>예약 정보</Table.Th>
                  <Table.Th className="whitespace-pre">진도</Table.Th>
                  <Table.Th className="whitespace-pre">{`선생님\n피드백`}</Table.Th>
                  <Table.Th className="whitespace-pre">
                    {`Lesson\n수강`}
                  </Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {chain(lives)
                  .map((live) => Object.values(live))
                  .flatten()
                  .map((lives: Live[]) => {
                    return lives.map((live: any, index) => {
                      const start = new Date(live.start);
                      start.setHours(0, 0, 0, 0);
                      const canceledAt = new Date(live.canceledAt);
                      canceledAt.setHours(0, 0, 0, 0);
                      const courseItemId = live?.courseItem?.id;
                      const firstCourseItemId = live?.firstCourseItem?.id;

                      return (
                        <Table.Row key={live.id}>
                          <Table.Td>
                            {live.liveType === LiveType.TRIAL &&
                            live.course?.initialAmount === 1
                              ? 1
                              : live.courseItem
                              ? live.attendRound
                              : live.cancelRound
                              ? live?.firstCourseItem?.number
                              : live.state === LiveState.RESERVED &&
                                live?.firstCourseItem
                              ? live?.firstCourseItem?.number
                              : index + 1}
                          </Table.Td>
                          <Table.Td
                            className={
                              live.state === LiveState.CANCELED ||
                              live.state === LiveState.ABSENT ||
                              live.state === LiveState.NO_SHOW ||
                              live.state === LiveState.ABSENT_AND_NO_SHOW ||
                              live.state === LiveState.CANCELLATION_THE_DAY
                                ? 'text-primary-8'
                                : ''
                            }
                          >
                            {(live.state === LiveState.CANCELED &&
                              moment(start).isSame(canceledAt)) ||
                            live.state === LiveState.CANCELLATION_THE_DAY
                              ? `${
                                  live.cancelType === CancelType.ADMIN
                                    ? '관리자'
                                    : '학생'
                                } 당일 취소`
                              : live.state === LiveState.AVAILABLE
                              ? '예약가능'
                              : live.state === LiveState.RESERVED
                              ? '예약완료'
                              : live.state === LiveState.CANCELED
                              ? `${
                                  live.cancelType === CancelType.ADMIN
                                    ? '관리자'
                                    : '학생'
                                } 취소`
                              : live.state === LiveState.ATTENDED
                              ? '출석'
                              : live.state === LiveState.ABSENT
                              ? '결석'
                              : live.state === LiveState.NO_SHOW
                              ? '선생님 노쇼'
                              : '학생 결석 / 선생님 노쇼'}
                          </Table.Td>
                          <Table.Td>
                            <div>
                              {utcToLocalFormat(
                                live.start.toLocaleString(),
                                MomentFormat.YYYYMMDDHmm
                              )}
                            </div>
                            <div>{live?.teacher}</div>
                          </Table.Td>
                          <Table.Td>
                            {live?.courseItem?.liveClass &&
                            live?.courseItem?.liveClass?.book?.title === '체험'
                              ? entrySeries
                              : live?.courseItem?.liveClass
                              ? live?.courseItem?.liveClass?.book?.title
                              : live?.firstCourseItem &&
                                live?.firstCourseItem?.liveClass?.book
                                  ?.title === '체험'
                              ? entrySeries
                              : live?.firstCourseItem?.liveClass?.book?.title}
                          </Table.Td>
                          <Table.Td>
                            {!live?.liveFeedbacks ? 'X' : 'O'}
                          </Table.Td>
                          <Table.Td>
                            {live?.courseItem?.liveClass?.book?.lessonResults
                              ? live?.courseItem?.liveClass?.book?.lessonResults
                                  ?.length
                              : live?.firstCourseItem?.liveClass?.book
                                  ?.lessonResults
                              ? live?.firstCourseItem?.liveClass?.book
                                  ?.lessonResults?.length
                              : 0}{' '}
                            /{' '}
                            {live?.courseItem?.liveClass?.book?.lessons
                              ? live?.courseItem?.liveClass?.book?.lessons
                                  .length
                              : live?.firstCourseItem?.liveClass?.book?.lessons
                              ? live?.firstCourseItem?.liveClass?.book?.lessons
                                  .length
                              : 0}
                          </Table.Td>
                          <Table.Td className="text-right flex space-x-3">
                            <Button
                              className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                              to={`/teacher/students/${live.studentId}/lives/${
                                live.id
                              }?courseItemId=${
                                courseItemId ? courseItemId : firstCourseItemId
                              }`}
                            >
                              상세보기
                            </Button>
                            {live.teacherId === me.id && (
                              <Button
                                className={`h-10 font-semibold ${
                                  live.state === LiveState.CANCELED ||
                                  live.state ===
                                    LiveState.CANCELLATION_THE_DAY ||
                                  live.state === LiveState.ABSENT ||
                                  live.state === LiveState.ABSENT_AND_NO_SHOW ||
                                  live.state === LiveState.NO_SHOW
                                    ? 'filled-secondary-8 text-primary-8'
                                    : live.state === LiveState.ATTENDED
                                    ? 'filled-gray-300 text-gray-50'
                                    : live.state === LiveState.RESERVED &&
                                      'filled-primary-7 text-secondary-7'
                                }`}
                                onClick={() => {
                                  const now = new Date();
                                  const checkTime = new Date(live?.start || '');
                                  checkTime.setMinutes(
                                    checkTime.getMinutes() - 5
                                  );
                                  const end = new Date(live?.end || '');
                                  end.setMinutes(end.getMinutes() - 5);
                                  if (live.state === LiveState.RESERVED) {
                                    if (
                                      checkTime > now ||
                                      now > end ||
                                      !live.pageCallRoomId
                                    ) {
                                      popupState.set({
                                        title: '수업 입장 안내',
                                        body: `수업 입장 시간이 아닙니다.수업 시간 5분전에 입장해주세요.`,
                                        primaryText: '확인',
                                      });
                                    } else {
                                      // socket?.emit('join', live?.id);
                                      setLiveId(live?.id);
                                      window.open(
                                        `https://app.pagecall.net/${live.pageCallRoomId}/?access_token=${me?.pageCallAccessToken}`
                                      );
                                    }
                                  }
                                }}
                              >
                                {live.state === LiveState.CANCELED ||
                                live.state === LiveState.CANCELLATION_THE_DAY ||
                                live.state === LiveState.ABSENT ||
                                live.state === LiveState.ABSENT_AND_NO_SHOW ||
                                live.state === LiveState.NO_SHOW
                                  ? 'LIVE 수업취소'
                                  : live.state === LiveState.ATTENDED
                                  ? 'LIVE 수업완료'
                                  : live.state === LiveState.RESERVED &&
                                    'LIVE 시작하기'}
                              </Button>
                            )}
                          </Table.Td>
                        </Table.Row>
                      );
                    });
                  })
                  .value()}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
      <div className="block md:hidden w-full">
        <MobileHelper />
      </div>
    </>
  );
};
