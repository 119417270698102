import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Coupon, CouponTarget, CouponType, Student, User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  target: CouponTarget;
  type: CouponType;
  name: string;
  description: string;
  discount: number;
  downloadStart: string | null;
  downloadEnd: string | null;
  useStart: string | null;
  useEnd: string | null;
  memo: string;
  open: boolean;
  userId?: string;
  studentId?: number;
}

export const CouponDetails = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const queryParams = new URLSearchParams(search);
  const { data: coupon, refetch } = useQuery<Coupon>(
    `/admin/coupons/${id}?join[0]=user&join[1]=student`,
    fetcher,
    { enabled: !addMode }
  );

  const userId = queryParams.get('userId') || coupon?.user?.id.toString();
  const isPrivateCoupon = !!userId;
  const { data: user } = useQuery<User>(`/admin/users/${userId}`, fetcher, {
    enabled: isPrivateCoupon,
    refetchOnWindowFocus: false,
  });
  const { data: students } = useQuery<Student[]>(
    `/admin/students/user/${userId}`,
    fetcher,
    {
      enabled: isPrivateCoupon,
      refetchOnWindowFocus: false,
    }
  );

  const {
    watch,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      target: CouponTarget.ALL,
      downloadStart: null,
      downloadEnd: null,
      useStart: null,
      useEnd: null,
    },
  });

  useEffect(() => {
    if (coupon) {
      setValue('name', coupon.name);
      setValue('description', coupon.description);
      setValue('discount', coupon.discount);
      setValue('downloadStart', coupon.downloadStart);
      setValue('downloadEnd', coupon.downloadEnd);
      setValue('useStart', coupon.useStart);
      setValue('useEnd', coupon.useEnd);
      setValue('memo', coupon.memo);
      setValue('open', coupon.open);
      setValue('type', coupon.type);
      setValue('studentId', coupon?.student?.id);
    }

    if (userId) {
      setValue('target', CouponTarget.ONE);
      setValue('userId', userId);
    }
  }, [setValue, coupon, editMode, userId]);

  return (
    <>
      <AdminH1>쿠폰</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <Select
            label="대상"
            value={watch('target')}
            disabled={(!addMode && !editMode) || isPrivateCoupon}
            onChange={(e) => setValue('target', e.target.value as CouponTarget)}
          >
            <option value={CouponTarget.ALL}>전체</option>
            {isPrivateCoupon && <option value={CouponTarget.ONE}>개인</option>}
          </Select>
          {isPrivateCoupon ? (
            <>
              <TextField label="유저" disabled={true} value={user?.name} />
              <Select
                label="자녀명"
                value={watch('studentId')}
                disabled={!addMode}
                onChange={(e) => setValue('studentId', Number(e.target.value))}
              >
                <option value="" selected>
                  자녀를 선택해주세요.
                </option>
                {students?.map((student) => (
                  <option value={student.id}>{student.name}</option>
                ))}
              </Select>
            </>
          ) : (
            <div />
          )}
          <TextField
            label="쿠폰명"
            disabled={!addMode}
            helper={errors.name?.message}
            {...register('name', { required: '쿠폰명을 입력해주세요' })}
          />
          <Select
            label="쿠폰타입"
            value={watch('type')}
            disabled={!addMode}
            onChange={(e) => setValue('type', e.target.value as CouponType)}
          >
            <option value="" selected>
              쿠폰타입을 선택해주세요.
            </option>
            <option value={CouponType.NORMAL}>일반쿠폰</option>
            <option value={CouponType.REPEAT}>중복쿠폰</option>
          </Select>
          <TextField
            label="설명"
            disabled={!addMode}
            helper={errors.description?.message}
            {...register('description', {
              required: '설명을 입력해주세요',
            })}
          />
          <TextField
            type="number"
            label="할인 금액"
            disabled={!addMode}
            helper={errors.discount?.message}
            {...register('discount', {
              required: '할인 금액을 입력해주세요',
            })}
          />
          <TextField
            label="메모"
            disabled={!addMode}
            helper={errors.memo?.message}
            {...register('memo')}
          />
          <TextField
            type="date"
            max={'9999-12-31'}
            label="다운로드 시작일"
            disabled={!addMode && !editMode}
            helper={errors.downloadStart?.message}
            {...register('downloadStart')}
          />
          <TextField
            type="date"
            max={'9999-12-31'}
            label="다운로드 종료일"
            disabled={!addMode && !editMode}
            helper={errors.downloadEnd?.message}
            {...register('downloadEnd')}
          />
          <TextField
            type="date"
            max={'9999-12-31'}
            label="사용 시작일"
            disabled={!addMode && !editMode}
            helper={errors.useStart?.message}
            {...register('useStart')}
          />
          <TextField
            type="date"
            max={'9999-12-31'}
            label="사용 종료일"
            disabled={!addMode && !editMode}
            helper={errors.useEnd?.message}
            {...register('useEnd')}
          />
          <Checkbox
            label="open"
            disabled={!addMode && !editMode}
            {...register('open')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/coupons/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
            />
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                const res = await api.post<Coupon>('/admin/coupons', {
                  ...data,
                  discount: Number(getValues('discount')),
                  userId: Number(getValues('userId')),
                });
                if (res.data.id) {
                  push(`/admin/coupons/${res.data.id}`);
                } else {
                  push(`/admin/coupons`);
                }
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
