import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Table } from '../../components/Table';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { User } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminPhoneNumber } from '../components/AdminPhoneNumber';

interface FormValues {
  email: string;
  name: string;
  phoneNumber: string;
  memo: string;
  blackList: boolean;
  marketing: boolean;
}

export const UserInfo = () => {
  const { id } = useParams<{ id: string }>();
  const [editMode, setEditMode] = useState(false);
  const [phoneNumber1, setPhoneNumber1] = useState<string>('');
  const [phoneNumber2, setPhoneNumber2] = useState<string>('');
  const [phoneNumber3, setPhoneNumber3] = useState<string>('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: user, refetch } = useQuery<User>(
    `/admin/users/${id}?join[]=invitees`,
    fetcher
  );

  useEffect(() => {
    if (!user) return;
    setValue('name', user.name);
    setValue('email', user.email);
    setValue('phoneNumber', user.phoneNumber);
    setValue('memo', user.memo);
    setValue('blackList', user.blackList);
    setValue('marketing', user.marketing);
    setPhoneNumber1(user.phoneNumber.substring(0, 3));
    setPhoneNumber2(user.phoneNumber.substring(3, 7));
    setPhoneNumber3(user.phoneNumber.substring(7));
  }, [user, setValue, editMode]);

  if (!user) return <></>;
  return (
    <>
      <AdminH1>회원정보</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="학부모 이름*"
            disabled={!editMode}
            placeholder="학부모 이름을 입력해주세요"
            helper={errors.name?.message}
            {...register('name', {
              required: ' 학부모 이름을 입력해주세요',
            })}
          />
          <TextField
            label="이메일*"
            type="email"
            disabled={!editMode}
            placeholder="이메일을 입력해주세요"
            helper={errors.email?.message}
            {...register('email', {
              required: '이메일을 입력해주세요.',
            })}
          />
          <AdminPhoneNumber
            label="핸드폰 번호*"
            disabled={!editMode}
            number1={phoneNumber1}
            number2={phoneNumber2}
            number3={phoneNumber3}
            setNumber1={setPhoneNumber1}
            setNumber2={setPhoneNumber2}
            setNumber3={setPhoneNumber3}
          />
          <TextArea
            label="관리자 메모"
            {...register('memo')}
            disabled={!editMode}
          />
          <Checkbox
            label="마케팅 수신여부"
            {...register('marketing')}
            disabled={!editMode}
          />
          <Checkbox
            label="블랙리스트"
            {...register('blackList')}
            disabled={!editMode}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="저장"
              onClick={handleSubmit(async (data) => {
                if (
                  user?.phoneNumber !==
                  phoneNumber1 + phoneNumber2 + phoneNumber3
                ) {
                  data.phoneNumber = phoneNumber1 + phoneNumber2 + phoneNumber3;
                }
                await api.patch(`/admin/users/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            />
          )}
          {!editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>

      <AdminH1>초대코드 정보</AdminH1>

      <AdminCard>
        <div className="px-4 py-6 sm:px-6 md:px-8">
          <div className="flex">
            <div className="">
              <span className="text-14 pr-3">나의 코드</span>
              <strong className="text-18 pr-4">{user.inviteCode}</strong>
            </div>
            <div className="">
              <span className="text-14 pr-3">내 코드로 가입한 유저</span>
              <strong className="text-18 pr-4">{user.invitees.length}</strong>
            </div>
            <div className="">
              <span className="text-14 pr-3">가입시 입력한 코드</span>
              <strong className="text-18 pr-4">
                {user.invitedCode ?? '-'}
              </strong>
            </div>
          </div>
          <div className="text-14 mt-8">내 코드로 가입한 유저</div>
          <div className="mt-4">
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>가입일</Table.Th>
                  <Table.Th>이름</Table.Th>
                  <Table.Th>이메일</Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {user.invitees.map((invitee, index) => (
                  <Table.Row key={index}>
                    <Table.Td>{invitee.id}</Table.Td>
                    <Table.Td>
                      {moment(invitee.createdAt).format('yyyy-MM-DD HH:mm')}
                    </Table.Td>
                    <Table.Td>{invitee.name}</Table.Td>
                    <Table.Td>{invitee.email}</Table.Td>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </AdminCard>
    </>
  );
};
