import React from 'react';
import { useQuery } from 'react-query';
import { H5 } from '../../components/H5';
import { StudentCard } from '../../components/Parent/StudentCard';
import { Section } from '../../components/Section';
import { fetcher } from '../../plugins/react-query';
import { Student } from '../../types';

export const StudentsPage = () => {
  const { data: students } = useQuery<Student[]>(`/students`, fetcher);

  if (!students) return <>아직 등록된 자녀가 없습니다.</>;
  return (
    <Section className="space-y-4">
      <H5>자녀 학습 현황</H5>

      <div className="space-y-6">
        {students.map((student) => (
          <StudentCard key={student.id} student={student} />
        ))}
      </div>
    </Section>
  );
};
