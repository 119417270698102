import moment from 'moment';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { H5 } from '../../components/H5';
import { H6 } from '../../components/H6';
import { Section } from '../../components/Section';
import { fetcher } from '../../plugins/react-query';
import { studentState } from '../../plugins/ridge';
import { Coupon, CumulativePoint, Point, User } from '../../types';
import { RewardsCouponsPage } from './RewardsCouponsPage';
import { RewardsPointPage } from './RewardsPointPage';

export const RewardsPage = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const student = studentState.useValue();

  const { data: user } = useQuery<User>(`/users/me`, fetcher);
  const { data: coupons } = useQuery<Coupon[]>('/coupons', fetcher);
  const { data: points } = useQuery<Point[]>('/points/my', fetcher, {
    enabled: !!student,
  });
  const { data: cumulativePoint } = useQuery<CumulativePoint>(
    `/cumulative-points/student/${student?.id}`,
    fetcher,
    {
      enabled: !!student,
    }
  );

  return (
    <div className="space-y-6">
      <H5>쿠폰 · 포인트</H5>
      <Section className="rounded-md bg-white shadow-3xl flex justify-between py-6">
        <div className="grid place-content-center text-center flex-1">
          <p>보유 쿠폰</p>
          <H6>
            {coupons?.filter(
              (c) =>
                c.couponItems.length > 0 &&
                !c.couponItems[0].used &&
                ((student && c.studentId === student?.id) || !c.studentId) &&
                c.couponItems[0].useEnd > moment().toISOString()
            ).length ?? 0}
            장
          </H6>
        </div>
        <div className="bg-gray-50 w-0.5 h-auto" />
        <div className="grid place-content-center text-center flex-1">
          <p>학습 포인트</p>
          <H6>
            {cumulativePoint?.currentTotalPoint
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            P
          </H6>
        </div>
      </Section>

      {pathname === '/parent/rewards/coupons' && (
        <Section className="rounded-md bg-white shadow-3xl p-6">
          <div className="flex md:flex-row flex-col space-y-6 md:space-y-0">
            <div className="flex-1">
              <p className="font-nanum text-17 text-primary-1">
                친구가 내 추천 코드 입력해 가입하면 친구도 나도 쿠폰이 쏙!
              </p>
              <p>
                친구가 레벨테스트하면 5천원, 구매하면 1만원 쿠폰이 나에게,
                <br />
                친구는 가입 즉시 1만원 쿠폰이!
              </p>
            </div>

            <div>
              <p className="text-gray-600">내 추천 코드</p>
              <div className="flex items-center space-x-2">
                <H6>{user?.inviteCode}</H6>
                <CopyToClipboard text={user?.inviteCode ?? ''}>
                  <button className="text-13 border border-gray-100 p-2 rounded-md">
                    코드 복사
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </Section>
      )}

      <button
        onClick={() => push('/parent/rewards/coupons')}
        className={`rounded-md text-13 px-3 py-2 ${
          pathname === '/parent/rewards/coupons' ? 'filled-gray-800' : ''
        }`}
      >
        쿠폰
      </button>
      <button
        onClick={() => push('/parent/rewards/point')}
        className={`rounded-md text-13 px-3 py-2 ${
          pathname === '/parent/rewards/point' ? 'filled-gray-800' : ''
        }`}
      >
        포인트
      </button>

      <Switch>
        <Route path="/parent/rewards/coupons" component={RewardsCouponsPage} />
        <Route
          path="/parent/rewards/point"
          render={() => <RewardsPointPage points={points} />}
        />
        <Redirect from="/" to="/parent/rewards/coupons" />
      </Switch>
    </div>
  );
};
