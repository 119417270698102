import { FC, useRef } from 'react';
import { ReactComponent as Signal } from '../../assets/icons/icon-signal.svg';
import { useScroll } from '../../components/CustomScroll';
import { Donut } from './Donut';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';

interface ProcessSectionProps {}

export const ProcessSection: FC<ProcessSectionProps> = () => {
  const inputRef = useRef<any>();
  const top = inputRef.current?.getBoundingClientRect().top;
  useScroll();

  return (
    <>
      <div ref={inputRef} className="md:pt-32 pt-20">
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="flex flex-col items-center justify-center">
            <div className="md:w-16 md:h-16 w-10 h-10 text-white md:text-32 text-16 font-nanum rounded-full bg-primary-1 grid place-content-center pl-0.5 md:pt-1">
              3
            </div>
          </div>
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center md:pt-10 pt-6">
            깐깐한 채용 프로세스로
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center py-2">
            검증된 라이브쌤
          </h2>
          <p className="text-15 md:text-xl text-black sub-txt01 font-medium text-center py-3">
            우수한 인재를 교사로 뽑아,
            <br />더 우수한 라이브 교사로 양성합니다.
          </p>

          <div className="text-lg md:text-xl text-center mt-10 px-7 py-3 border-2 border-primary-1 text-primary-1 rounded-full w-max mx-auto process-txt">
            채용프로세스
          </div>
        </ScrollAnimation>

        {/* PC뷰 */}
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="md:block hidden">
            <div className="max-w-container grid grid-cols-3">
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-1' : 'donut-segment-0'
                }
                strokeDashArray="20 80"
                number={1}
                title="영어전문강사"
                subTitle="이력 심사"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-2' : 'donut-segment-0'
                }
                strokeDashArray="30 70"
                number={2}
                title="기초강의"
                subTitle="시연 평가"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-4' : 'donut-segment-0'
                }
                strokeDashArray="50 50"
                number={3}
                title="면접 진행"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-7' : 'donut-segment-0'
                }
                strokeDashArray="100 0"
                number={6}
                title="채용 확정"
                hasCharacter={true}
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-6' : 'donut-segment-0'
                }
                strokeDashArray="90 10"
                number={5}
                title="최종 모의수업"
                subTitle="면접"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-5' : 'donut-segment-0'
                }
                strokeDashArray="70 30"
                number={4}
                title="라이브강사"
                subTitle="기본교육 진행"
              />
            </div>
          </div>

          {/* 모바일 */}
          <div className="md:hidden inline-block">
            <div className="max-w-container grid grid-cols-2">
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-1' : 'donut-segment-0'
                }
                strokeDashArray="20 80"
                number={1}
                title="영어전문강사"
                subTitle="이력 심사"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-2' : 'donut-segment-0'
                }
                strokeDashArray="30 70"
                number={2}
                title="기초강의"
                subTitle="시연 평가"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-5' : 'donut-segment-0'
                }
                strokeDashArray="70 30"
                number={4}
                title="라이브강사"
                subTitle="기본교육 진행"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-4' : 'donut-segment-0'
                }
                strokeDashArray="50 50"
                number={3}
                title="면접 진행"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-6' : 'donut-segment-0'
                }
                strokeDashArray="90 10"
                number={5}
                title="최종 모의수업"
                subTitle="면접"
              />
              <Donut
                donutClassName={
                  top < 250 ? 'donut-segment-7' : 'donut-segment-0'
                }
                strokeDashArray="100 0"
                number={6}
                title="채용 확정"
                hasCharacter={true}
              />
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="relative pt-16">
            <div className="text-lg md:text-xl text-center mt-10 px-7 py-3 border-2 bg-white border-primary-1 text-primary-1 rounded-full w-max mx-auto absolute top-0 left-1/2 transform -translate-x-1/2 process-txt">
              교육과정
            </div>

            <div className="md:flex md:px-5 px-10 py-16 gap-5 justify-center bg-primary-1 md:space-y-0 space-y-5 md:mx-0 mx-5 md:rounded-none rounded-lg">
              <div className="text-center bg-white border-2 rounded-lg process-b-p">
                <Signal className=" mx-auto" />
                <h2 className="text-xl py-2 ">정기 교육</h2>
                <p className="text-md md:font-normal font-medium">
                  <span className="text-primary-1">
                    최신 영어 교육 트렌드와 <br />
                    노하우
                  </span>
                  를 전달하는 정기교육을 <br />
                  통해 교수 스킬을 형성합니다.
                </p>
              </div>

              <div className="text-center bg-white border-2 rounded-lg process-b-p">
                <Signal className=" mx-auto" />
                <h2 className="text-xl py-2 ">정기 수업 평가</h2>
                <p className="text-md md:font-normal font-medium">
                  교사 전담 관리팀이 프로그램별
                  <br />
                  정기 수업 평가를 진행해
                  <br />
                  <span className="text-primary-1">
                    모든 학습 콘텐츠와 수업 품질을
                    <br />
                    균일하게 유지
                  </span>
                  합니다.
                </p>
              </div>

              <div className="text-center bg-white border-2 rounded-lg process-b-p">
                <Signal className=" mx-auto" />
                <h2 className="text-xl py-2 ">비정기 수업 모니터링</h2>
                <p className="text-md md:font-normal font-medium">
                  회원의 피드백과 실시간 <br />
                  수업 모니터링을 기반으로 <br />
                  <span className="text-primary-1">
                    영어 전문성, 교수 태도 등을 <br />
                    발전
                  </span>
                  시킵니다.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
};
