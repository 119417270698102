import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';
import { UserStudentCourseItemLiveDetail } from './UserStudentCourseItemLiveDetail';
import { UserStudentCourseItems } from './UserStudentCourseItems';
import { UserStudentLiveDetails } from './UserStudentLiveDetails';
import { UserStudentLives } from './UserStudentLives';

export const UserStudentDetails = () => {
  const { pathname } = useLocation();
  const chunks = pathname.split('/');
  const basePath = `/${chunks[1]}/${chunks[2]}/${chunks[3]}/${chunks[4]}/${chunks[5]}/${chunks[6]}/${chunks[7]}`;
  const subPath = chunks[8];
  const otherPath = chunks[9];
  const lastPath = chunks[10];

  return (
    <>
      <AdminTabs>
        <AdminTab
          text="학습정보"
          to={`${basePath}`}
          selected={(!subPath || !!otherPath) && !lastPath}
        />
        <AdminTab
          text="회차정보"
          to={`${basePath}/courseItems`}
          selected={(subPath === 'courseItems' && !otherPath) || !!lastPath}
        />
      </AdminTabs>

      <Switch>
        <Route
          path="/admin/users/:userId/students/:studentId/courses/:courseId/courseItems/:courseItemId/detail"
          component={UserStudentCourseItemLiveDetail}
        />
        <Route
          path="/admin/users/:userId/students/:studentId/courses/:courseId/courseItems/:courseItemId"
          component={UserStudentLiveDetails}
        />
        <Route
          path="/admin/users/:userId/students/:studentId/courses/:courseId/courseItems"
          component={UserStudentCourseItems}
        />
        <Route
          path="/admin/users/:userId/students/:studentId/courses/:courseId"
          component={UserStudentLives}
        />
        <Redirect
          from="/admin/users/:userId/students/:studentId"
          to="/admin/users/:userId/students/:studentId/courses/:courseId"
        />
      </Switch>
    </>
  );
};
