import moment from 'moment';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { apiUrl } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { EventBoard } from '../types';

interface EventsCardProps {
  event: EventBoard;
}

export const EventsCard: FC<EventsCardProps> = ({ event }) => {
  const { push } = useHistory();
  const now = moment().format('YYYY-MM-DD');

  return (
    <div onClick={() => push(`/events/${event.id}`)} className="cursor-pointer">
      <div className="aspect-h-9 aspect-w-16 relative w-full">
        <img
          src={apiUrl(event?.thumbnail)}
          alt=""
          className="w-full h-full absolute object-cover"
        />
      </div>
      <div className="space-y-1 py-3">
        <p className="text-gray-400">{event.category?.title}</p>
        <div>
          <h4 className="text-lg font-semibold">{event.title}</h4>
          <p className="text-gray-400">
            {event && event?.startedAt <= now && event?.endedAt >= now ? (
              <>
                {utcToLocalFormat(
                  event?.startedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}{' '}
                ~{' '}
                {utcToLocalFormat(
                  event?.endedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}
              </>
            ) : event && event?.endedAt <= now ? (
              '종료'
            ) : (
              <>
                {utcToLocalFormat(
                  event?.startedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}{' '}
                ~{' '}
                {utcToLocalFormat(
                  event?.endedAt.toLocaleString() || '',
                  MomentFormat.YYYYMMDD
                )}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
