import React, { useMemo } from 'react';
import StarIcon from './StarIcon';

interface StarsProps {
  width: 'w-5' | 'w-16';
  index: number;
  rating: number;
  hoverRating: number;
  disabled?: boolean;
  onMouseEnter: (index: number) => void;
  onMouseLeave: () => void;
  onSaveRating: (index: number) => void;
}

const Stars: React.FC<StarsProps> = ({
  width,
  index,
  rating,
  hoverRating,
  disabled,
  onMouseEnter,
  onMouseLeave,
  onSaveRating,
}) => {
  const fillColor = useMemo(() => {
    if (hoverRating >= index) {
      return true; // #ffd601 === 노란색
    } else if (!hoverRating && rating >= index) {
      return true; // #ffd601 === 노란색
    }
    return false; // #dcdcdc === 회색
  }, [rating, hoverRating, index]);

  return (
    <div
      onMouseEnter={() => !disabled && onMouseEnter(index)}
      onMouseLeave={() => !disabled && onMouseLeave()}
      onClick={() => !disabled && onSaveRating(index)}
    >
      <StarIcon fill={fillColor} width={width} />
    </div>
  );
};

export default Stars;
