import React, { FC } from 'react';
import mainDescriptionImg2 from '../../../assets/img/main-description-img5.png';
import mainDescriptionImg3 from '../../../assets/img/main-description-img5-1.png';
import mainDescriptionImg4 from '../../../assets/img/main-description-img5-2.png';
import mainDescriptionImg5 from '../../../assets/img/main-description-img5-3.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section } from '../../../components/Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { ReactComponent as Arrow } from '../../../assets/svg/swiper_arrow_right.svg';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const imgData = [
  { id: 1, imgPath: mainDescriptionImg2 },
  { id: 2, imgPath: mainDescriptionImg3 },
  { id: 3, imgPath: mainDescriptionImg4 },
  { id: 4, imgPath: mainDescriptionImg5 },
];

interface FourthSectionProps {}

export const FourthSection: FC<FourthSectionProps> = () => {
  return (
    <>
      <div className="con-pa">
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="max-w-container"
        >
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
            베테랑 라이브쌤과 함께하는
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
            25분의 짜임새 있는 수업!
          </h2>
          <p className="text-15 md:text-xl text-black font-medium sub-txt01 text-center">
            오로지 우리 아이에게만 집중되는 알찬 수업으로 <br />
            영어 자신감이 쑥쑥!
          </p>
          {/* <img
          alt=""
          src={mainDescriptionImg2}
          className="py-10 md:px-52 m-auto object-cover"
        /> */}
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="relative"
        >
          <Section className="relative Fourth-slide-wrap tablet-slide-wrap">
            <Swiper
              loop={true}
              loopedSlides={1}
              touchRatio={1}
              //pagination={{ clickable: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next-1',
                prevEl: '.swiper-button-prev-1',
              }}
              className="Fourth-slide tablet-slide"
            >
              {/* {imgData.map((banner) => (
                <SwiperSlide key={String(banner.id)}>
                  <img alt="" src={banner.imgPath} className="mx-auto pb-10" />
                </SwiperSlide>
              ))} */}
              <SwiperSlide>
                <img alt="" src={imgData[0].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt12.png').default}
                  className="s-key-txt s-key-txt12"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt13.png').default}
                  className="s-key-txt s-key-txt13"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[1].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt14.png').default}
                  className="s-key-txt s-key-txt14"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[2].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt15.png').default}
                  className="s-key-txt s-key-txt15"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[3].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt16.png').default}
                  className="s-key-txt s-key-txt16"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt17.png').default}
                  className="s-key-txt s-key-txt17"
                />
              </SwiperSlide>
            </Swiper>
            <div className="tablet-slide-bg">
              <img
                src={require('../../../assets/img/tablet-frame.png').default}
                className=""
                alt=""
              />
            </div>
            <Arrow className="swiper-button-prev-1 mobile-none cursor-pointer swiper-button" />
            <Arrow className="swiper-button-next-1 mobile-none cursor-pointer swiper-button" />
          </Section>
          <div className="t-noti-box">
            <span>
              <a className="xi-info"></a>알려드려요!
            </span>
            튼튼영어라이브의 디지털 학습, 화상 수업을 포함한 모든 기능들은 PC 크롬
            웹페이지에서 원활한 이용이 가능합니다. 가급적 PC나 노트북을 이용해
            주세요.
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
};
