import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H5 } from '../../components/H5';
import { Pagination } from '../../components/Pagination';
import { Select } from '../../components/Select';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Series, TeacherMaterial } from '../../types';

export const MaterialsPage = () => {
  const { push } = useHistory();
  const [seriesId, setSeriesId] = useState<number>();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const page = Number(params.get('page') ?? '1');
  const limit = Number(params.get('limit') ?? '10');
  const floor = page - 1;

  const { data: serieses } = useQuery<Series[]>('/series/all', fetcher);

  useEffect(() => {
    if (!serieses || serieses.length === 0) return;
    setSeriesId(serieses[0].id);
  }, [setSeriesId, serieses]);

  const teacherMaterialQuery = useQueryString({
    filter: { seriesId: seriesId, isHide: false },
    sort: { createdAt: 'DESC' },
  });
  const { data: teacherMaterials } = useQuery<Paginated<TeacherMaterial>>(
    `/teacher-materials${teacherMaterialQuery}`,
    fetcher,
    { enabled: !!seriesId, keepPreviousData: true }
  );

  return (
    <div className="max-w-container space-y-5 mt-5">
      <H5>수업 자료실</H5>
      <Select
        label="시리즈"
        className="text-sm h-10 md:w-1/4"
        value={seriesId}
        onChange={(e) => {
          setSeriesId(Number(e.target.value));
          push('/teacher/prepare/materials?page=1&limit=10');
        }}
      >
        {serieses?.map((series) => (
          <option key={series.id} label={series.name} value={series.id}>
            {series.name}
          </option>
        ))}
      </Select>
      <div className="w-full max-w-5xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden">
        <Table>
          <Table.Head className="bg-white">
            <Table.Row>
              <Table.Th>번호</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th>등록일자</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {teacherMaterials?.items.map((materials, index) => (
              <Table.Row key={materials.id}>
                <Table.Td>
                  {page > 1
                    ? floor && teacherMaterials.total - floor * limit - index
                    : teacherMaterials.total - index}
                </Table.Td>
                <Table.Td>{materials.title}</Table.Td>
                <Table.Td>
                  {utcToLocalFormat(
                    materials.createdAt.toLocaleString(),
                    MomentFormat.YYYYMMDDHmm
                  )}
                </Table.Td>
                <Table.Td className="text-right">
                  <Button
                    className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                    to={`/teacher/prepare/materials/${materials.id}`}
                  >
                    상세보기
                  </Button>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination className="border-t">
          <Pagination.Label
            count={teacherMaterials?.items.length || 0}
            total={teacherMaterials?.total || 0}
          />
          <Pagination.Nav
            basePath="/teacher/prepare/materials"
            total={teacherMaterials?.total || 0}
          />
        </Pagination>
      </div>
    </div>
  );
};
