import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ReactComponent as FoxSVG } from '../assets/svg/fox.svg';
import { Button } from '../components/Button';
import { H3 } from '../components/H3';
import { H5 } from '../components/H5';
import { H6 } from '../components/H6';
import { Section } from '../components/Section';
import { LevelTest } from '../plugins/leveltest';
import { fetcher } from '../plugins/react-query';
import { studentState, testResultState } from '../plugins/ridge';
import { Entry } from '../types';
import './bargraph.css';
import './gauge.css';

export const LevelTestResultPage = () => {
  const { push } = useHistory();
  const student = studentState.useValue();
  const result = testResultState.useValue();
  const json = JSON.parse(result?.json ? result?.json : '{}');

  const { data: entry } = useQuery<Entry>(
    `/entries?testResultId=${result?.id}`,
    fetcher,
    { enabled: !!result }
  );

  //그래프
  const listenings = json.data?.filter((i: any) => i.info.startsWith('listen'));
  const speakings = json.data?.filter((i: any) => i.info.startsWith('speak'));
  const readings = json.data?.filter((i: any) => i.info.startsWith('read'));
  const writings = json.data?.filter((i: any) => i.info.startsWith('write'));
  const correctListenings = listenings?.filter((l: any) => l.isanswer === true);
  const correctSpeakings = speakings?.filter((l: any) => l.isanswer === true);
  const correctReadings = readings?.filter((l: any) => l.isanswer === true);
  const correctWritings = writings?.filter((l: any) => l.isanswer === true);
  const correctListeningCount = (
    (correctListenings?.length / listenings?.length) *
    100
  ).toFixed();
  const correctSpeakingCount = (
    (correctSpeakings?.length / speakings?.length) *
    100
  ).toFixed();
  const correctReadingCount = (
    (correctReadings?.length / readings?.length) *
    100
  ).toFixed();
  const correctWritingCount = (
    (correctWritings?.length / writings?.length) *
    100
  ).toFixed();

  const levelInAlphabet = entry?.levelCode.split('-')[1];
  let levelInGraph = 1;
  switch (levelInAlphabet) {
    case 'A':
      levelInGraph = 1;
      break;
    case 'B':
      levelInGraph = 2;
      break;
    case 'C':
      levelInGraph = 3;
      break;
    case 'D':
      levelInGraph = 4;
      break;
  }

  if (!result || !student?.isTested) {
    push('/learn/classroom');
    return <></>;
  }
  return (
    <div
      className="min-h-screen-5 pb-20"
      style={{ backgroundColor: '#FBFBFB' }}
    >
      <div className="max-w-container ">
        <H3 className="pt-14 pb-8">레벨테스트 결과</H3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col space-y-8">
            <div className="grid grid-cols-2 gap-8">
              {/* profile */}
              <div className="bg-white rounded-lg p-5 space-y-3">
                <div className="flex justify-between">
                  <span className="text-gray-600">이름 (나이)</span>
                  <span className="font-bold">
                    {json.name ? (
                      <>
                        {json.name} ({json.age})
                      </>
                    ) : (
                      <>
                        {student?.name} (
                        {new Date().getFullYear() -
                          new Date(student?.dob || '').getFullYear() +
                          1}
                        )
                      </>
                    )}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">평가지</span>
                  <span className="font-bold">{result.type}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">응시일</span>
                  <span className="font-bold">
                    {moment(result.createdAt).format('YYYY.MM.DD')}
                  </span>
                </div>
              </div>

              {/* result */}
              <div className="bg-white rounded-lg p-5 space-y-4">
                <H6 className="text-17">진단 결과</H6>
                <div className="flex flex-col items-center space-y-3">
                  <div
                    className="gauge"
                    style={{
                      width: '130px',
                      color: '#2D84F0',
                      backgroundColor: '#2D84F0',
                    }}
                  >
                    <div
                      className="percentage"
                      style={{ transform: `rotate(${levelInGraph * 45}deg)` }}
                    />
                    <div className="mask" />
                  </div>
                  <H5 className="text-center">LV. {entry?.levelCode}</H5>
                </div>
              </div>
            </div>

            {/* chart */}
            <div className="bg-white rounded-lg p-5 flex flex-col space-y-8 pb-24">
              <Section className="flex justify-between items-center">
                <div className="space-y-2">
                  <H6>{result.type}형</H6>
                  <div className="flex justify-between">
                    <div>맞힌 문항</div>
                    <div className="font-bold">
                      {result?.score && result?.score}/
                      {json.data && json.data.length}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="pr-5">100점 환산 점수</div>
                    <div className="font-bold">{result.score100}점</div>
                  </div>
                </div>

                {result.json && (
                  <div className="space-y-2">
                    <Button
                      text="성적표 보기"
                      className="bg-gray-50 h-10 rounded-md"
                      onClick={() => LevelTest.openResult()}
                    />
                    <Button
                      text="채점표 보기"
                      className="bg-gray-50 h-10 rounded-md"
                      onClick={() => LevelTest.openTest(result.type)}
                    />
                  </div>
                )}
              </Section>

              <div className="h-44">
                <div className="graphic bar-chart">
                  <ul className="axisy">
                    <li data-ejeY="100"></li>
                    <li data-ejeY="80"></li>
                    <li data-ejeY="60"></li>
                    <li data-ejeY="40"></li>
                    <li data-ejeY="20"></li>
                    <li data-ejeY="0"></li>
                  </ul>
                  <ul className="axisx -mb-32">
                    <li
                      className="rounded-t-xl z-10"
                      style={{
                        height: `${Number(correctListeningCount)}0%`,
                        width: '17%',
                        background: `rgba(255, 220, 15, ${
                          Number(correctListeningCount) / 100
                        })`,
                        position: 'absolute',
                        bottom: '16px',
                        left: '10%',
                      }}
                    >
                      <i
                        className=""
                        style={{
                          position: 'absolute',
                          left: '16%',
                        }}
                      >
                        Listening
                      </i>
                    </li>
                    <li
                      className="rounded-t-xl bg-white"
                      style={{
                        height: `${Number(correctListeningCount)}0%`,
                        width: '17%',
                        position: 'absolute',
                        bottom: '16px',
                        left: '10%',
                      }}
                    />
                    <li
                      className="rounded-t-xl z-10"
                      style={{
                        height: `${Number(correctSpeakingCount)}0%`,
                        width: '17%',
                        background: `rgba(255, 220, 15, ${
                          Number(correctSpeakingCount) / 100
                        })`,
                        position: 'absolute',
                        bottom: '16px',
                        left: '33%',
                      }}
                    >
                      <i
                        style={{
                          position: 'absolute',
                          left: '16%',
                          opacity: '100%',
                        }}
                      >
                        Speaking
                      </i>
                    </li>
                    <li
                      className="rounded-t-xl bg-white"
                      style={{
                        height: `${Number(correctSpeakingCount)}0%`,
                        width: '17%',
                        position: 'absolute',
                        bottom: '16px',
                        left: '33%',
                      }}
                    />
                    <li
                      className="rounded-t-xl z-10"
                      style={{
                        height: `${correctReadingCount}0%`,
                        width: '17%',
                        left: '56%',
                        position: 'absolute',
                        bottom: '16px',
                        background: `rgba(255, 220, 15, ${
                          Number(correctReadingCount) / 100
                        })`,
                      }}
                    >
                      <i
                        style={{
                          position: 'absolute',
                          left: '16%',
                          opacity: '100%',
                        }}
                      >
                        Reading
                      </i>
                    </li>
                    <li
                      className="rounded-t-xl bg-white"
                      style={{
                        height: `${correctReadingCount}0%`,
                        width: '17%',
                        left: '56%',
                        position: 'absolute',
                        bottom: '16px',
                      }}
                    />
                    <li
                      className="rounded-t-xl z-10"
                      style={{
                        height: `${correctWritingCount}0%`,
                        width: '17%',
                        left: '77%',
                        position: 'absolute',
                        bottom: '16px',
                        background: `rgba(255, 220, 15, ${
                          Number(correctWritingCount) / 100
                        })`,
                      }}
                    >
                      <i
                        style={{
                          position: 'absolute',
                          left: '16%',
                          opacity: '100%',
                        }}
                      >
                        Writing
                      </i>
                    </li>
                    <li
                      className="rounded-t-xl bg-white"
                      style={{
                        height: `${correctWritingCount}0%`,
                        width: '17%',
                        left: '77%',
                        position: 'absolute',
                        bottom: '16px',
                      }}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-5">
            <Section>
              <H6 className="text-17">진단 위치</H6>
              <div className="grid grid-cols-4 gap-8 h-full">
                <div className="h-72 flex flex-col justify-end relative">
                  {result.type === 'A' && (
                    <FoxSVG className="absolute bottom-12 left-4" />
                  )}
                  <div
                    className="rounded-t-xl"
                    style={{ backgroundColor: '#FFDC0F', height: '10%' }}
                  />
                  <span className="grid place-content-center pt-2">1단계</span>
                </div>

                <div className="h-72 flex flex-col justify-end relative">
                  {result.type === 'B' && (
                    <FoxSVG className="absolute bottom-20 left-4" />
                  )}
                  <div
                    className=" rounded-t-xl"
                    style={{ backgroundColor: '#FFA5BE', height: '20%' }}
                  />
                  <span className="grid place-content-center pt-2">2단계</span>
                </div>

                <div className="h-72 flex flex-col justify-end relative">
                  {result.type === 'C' && (
                    <FoxSVG className="absolute bottom-32 left-4" />
                  )}
                  <div
                    className=" rounded-t-xl"
                    style={{ backgroundColor: '#8CD7FF', height: '40%' }}
                  />
                  <span className="grid place-content-center pt-2">3단계</span>
                </div>

                <div className="h-72 flex flex-col justify-end relative">
                  {/* {result.type === 'D' && (
                    <FoxSVG className="absolute bottom-48 left-4" />
                  )} */}
                  <div
                    className=" rounded-t-xl"
                    style={{ backgroundColor: '#FF8C58', height: '60%' }}
                  />
                  <span className="grid place-content-center pt-2">4단계</span>
                </div>
              </div>
            </Section>

            <Section className="space-y-4 mt-16">
              <H6 className="text-17">학습 교재 설명</H6>
              <p className="bg-gray-50 p-3 rounded-md">
                {entry?.levelCode === '1-A'
                  ? 'Hear Me는 1단계 교재 입니다. 영어 학습을 본격적으로 시작하는 아이들이 쉽고 재미있게 영어를 익힐 수 있도록 만들어진 교재입니다. 특히 영어 습득의 기본인 ‘듣기’의 기초가 잘 형성되도록 영어 특유의 소리와 리듬을 잘 익히게끔 구성되어 있습니다.'
                  : entry?.levelCode === '1-B'
                  ? 'I Love Stories는 1단계 교재 입니다. 우리 주변의 이야기에서부터 먼 극지방에 사는 북극곰의 이야기까지 다양한 소재로 이야기가 구성되어 있습니다. 아이들은 자연스럽게 영어 소리를 익히고 다양한 문형을 접하게 되는 교재입니다.'
                  : entry?.levelCode === '1-C'
                  ? 'My Day는 1단계 교재 입니다. 일상생활에 기반한 다양한 의사소통 예문을 습득하면서 영어에 관심과 재미를 붙이게 되고, 쉽고 자연스럽게 말하기의 기초를 쌓을 수 있습니다.'
                  : entry?.levelCode === '1-D'
                  ? 'Reading Rookie는 1단계 교재 입니다. 전래 동화, 외국 동화, 과학 이야기, 생각 키우기 등 4가지 주제로 재미있게 구성된 이야기를 통해 영어에 대한 흥미와 자신감을 갖게 됩니다. 노출량이 극대화되어 듣기와 말하기가 보강되며, 영어를 통해 지식의 폭을 넓히고 사고력을 키울 수 있는 교재입니다.'
                  : entry?.levelCode === '2-A'
                  ? `School's Cool은 2단계 교재 입니다. 만화로 구성된 아이들의 학교 생활 이야기를 접하면서 일상생활의 생생한 영어 표현들을 자연스럽게 습득하고 말하기 능력을 강화할 수 있는 교재 입니다.`
                  : entry?.levelCode === '2-B'
                  ? `What's Up은 2단계 교재 입니다. 만화로 구성된 재미있고 친숙한 교재로 영어 말하기에 재미를 느낍니다. 초등 저학년 일상에서 자주 사용하는 문형을 반복 노출하여 영어 말하기를 충분히 연습 할 수 있습니다.`
                  : entry?.levelCode === '2-C'
                  ? 'Reading Rookie 2는 2단계 교재 입니다. 초등학교 수준에 맞추어 교육적으로 설계된 권당 150~200단어 수준의 리딩 교재로 전래 동화, 외국 동화, 과학 이야기, 생각 키우기 등 4가지 주제로 구성된 이야기입니다. 영어 동화책 읽기뿐만 아니라 듣기와 말하기 실력도 키우고, 지식과 사고력을 키우는 교재입니다.'
                  : entry?.levelCode === '3-A'
                  ? 'What to Say는 3단계 교재 입니다. 일상생활에서 일어나는 다양한 상황에 맞는 144개의 회화 표현을 집중적으로 익힙니다.'
                  : entry?.levelCode === '3-B'
                  ? 'Joy the Tomboy는 3단계 교재 입니다. 재미있는 이야기를 즐기며 96개의 문형을 익히고 쓸 수 있습니다.'
                  : entry?.levelCode === '3-C'
                  ? 'My Reading Rainbow는 3단계 교재 입니다. 논픽션 글을 읽으며 다양한 정보를 습득하고 읽기와 쓰기 실력을 키웁니다.'
                  : ''}
              </p>
              <Button
                to={
                  entry?.levelCode === '1-A'
                    ? '/library/?levelId=1&seriesId=1'
                    : entry?.levelCode === '1-B'
                    ? '/library/?levelId=1&seriesId=3'
                    : entry?.levelCode === '1-C'
                    ? '/library/?levelId=1&seriesId=5'
                    : entry?.levelCode === '1-D'
                    ? '/library/?levelId=1&seriesId=7'
                    : entry?.levelCode === '2-A'
                    ? '/library/?levelId=2&seriesId=8'
                    : entry?.levelCode === '2-B'
                    ? '/library/?levelId=2&seriesId=10'
                    : entry?.levelCode === '2-C'
                    ? '/library/?levelId=2&seriesId=11'
                    : entry?.levelCode === '3-A'
                    ? '/library/?levelId=3&seriesId=14'
                    : entry?.levelCode === '3-B'
                    ? '/library/?levelId=3&seriesId=16'
                    : entry?.levelCode === '3-C'
                    ? '/library/?levelId=3&seriesId=18'
                    : '/library'
                }
                text="학습 교재 미리보기"
                className="bg-gray-70 w-full"
              />
              <Button
                to="/pricing"
                text="수강신청 하러가기"
                className="filled-gray-700 w-full"
              />
            </Section>
          </div>
        </div>
      </div>
    </div>
  );
};
