import React, { FC, useState } from 'react';
import { useId } from 'react-id-generator';
import { ReactComponent as UploadImgIcon } from '../assets/svg/icon-uploadimg.svg';
import { Label } from './Label';

interface UploadFileProps {
  label: string;
  helper?: string;
  fileName?: string;
  disabled?: boolean;
  onChange: (file: File) => void;
}

export const UploadFile: FC<UploadFileProps> = ({
  label,
  helper,
  fileName,
  disabled,
  onChange,
}) => {
  const [id] = useId(1, 'uploadfile');
  const [file, setFile] = useState<File>();

  return (
    <div>
      <Label text={label} />
      <label
        htmlFor={id}
        className="py-8 border-2 mt-1 border-dashed rounded-md flex flex-col justify-center items-center cursor-pointer"
      >
        {file ? file.name : fileName ? fileName : <UploadImgIcon />}
        <p className="mt-4">
          <span className="text-14 text-blue-500">Upload a file</span>
          <span className="text-12 text-gray-500"> up to 10MB</span>
        </p>
        <input
          id={id}
          type="file"
          className="sr-only"
          disabled={disabled}
          onChange={async (e) => {
            const file = e.target.files?.item(0);
            if (!e.target.validity.valid || !file) return;
            setFile(file);
            onChange(file);
          }}
        />
      </label>
      {helper && <p className="text-sm text-error">{helper}</p>}
    </div>
  );
};
