import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Editor, EditorView } from '../../plugins/froala';
import { fetcher } from '../../plugins/react-query';
import { TeacherGuide } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  title: string;
  content: string;
  isHide: boolean;
}

export const TeacherGuideDetails = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);

  const { data: teacherGuide, refetch } = useQuery<TeacherGuide>(
    `/admin/teacher-guides/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!teacherGuide) return;
    setValue('title', teacherGuide.title);
    setValue('content', teacherGuide.content);
    setValue('isHide', teacherGuide.isHide);
  }, [setValue, teacherGuide, editMode]);

  return (
    <>
      <AdminH1>교사교육실</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="제목"
            disabled={!addMode && !editMode}
            helper={errors.title?.message}
            {...register('title', { required: 'Please enter title.' })}
          />
        </div>

        <div className="px-4 py-6 sm:px-6 md:px-8">
          {addMode || editMode ? (
            <Editor
              model={watch('content')}
              onModelChange={(model: any) => setValue('content', model)}
            />
          ) : (
            <EditorView model={watch('content')} />
          )}
        </div>

        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <Checkbox
            label="숨김처리"
            disabled={!addMode && !editMode}
            {...register('isHide')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/teacher-guides/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
            />
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                const res = await api.post<TeacherGuide>(
                  '/admin/teacher-guides',
                  data
                );
                push(`/admin/teacher-guides/${res.data.id}`);
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
