import React from 'react';
import curriculumTopImg from '../../assets/img/curriculum-top-img.png';
import { SecondSection } from '../home/lesson-introduction/SecondSection';
import { ThirdSection } from '../home/lesson-introduction/ThirdSection';
import { SoldSeparatelySectionSection } from './SoldSeparatelySection';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { usePopup } from '../../components/PopUp';
import { SixthSection } from '../home/lesson-introduction/SixthSection';
import { studentState, testResultState } from '../../plugins/ridge';

export const CurriculumPage = () => {
  const { authenticated } = useAuth();
  const student = studentState.get();
  const { push } = useHistory();
  const { requireLoginPopup, requireLevelTestPopup } = usePopup();
  const testResult = testResultState.useValue();

  const handleLevelTestClick = () => {
    if (!authenticated) return requireLoginPopup();
    // if (students?.length === 0) return addStudentPopup();
    if (!testResult || !student?.isTested)
      return requireLevelTestPopup(student?.id);
    push('/level-test/result');
  };
  return (
    <>
      <div className="font-nanum">
        <div className="bg-blue-cur lg:h-529 relative c-sub-main">
          <div className="h-full grid lg:grid-cols-5 grid-cols-1 max-w-7xl mx-auto relative zindex-10 ">
            <div className="grid col-span-3 place-content-center">
              <h2 className="md:text-36 text-20 text-black text-center py-2 lg:mt-0 mt-10 font-light">
                초등영어완성!
              </h2>
              <h2 className="text-30 md:text-48 text-black text-center pb-5 key-txt relative">
                튼튼영어라이브{` `}
                <br className="lg:hidden block" />
                5년 커리큘럼
              </h2>
              <p className=" lg:text-lg text-black text-center py-2 pb-3 font-medium">
                기초부터 탄탄히! 우리 아이 레벨에
                <br className="lg:hidden block" /> 꼭 맞는 교재부터 차근차근,
                <br />
                커리큘럼에 맞춰 차례대로 배정해드려요.
              </p>
              <button
                onClick={handleLevelTestClick}
                className="lg:mt-6 lg:mb-0 my-4 px-4 lg:text-18 text-16 text-blue-500 rounded-full border-blue-500 border-2 p-2 m-auto bg-white flex"
              >
                레벨테스트 보러 가기 {`>`}
              </button>
            </div>

            <div className="col-span-2 w-full h-full relative ">
              <img
                src={curriculumTopImg}
                alt=""
                className="mx-auto w-80 lg:mx-0 lg:w-full lg:absolute lg:bottom-0"
              />
            </div>
          </div>
          <div className="cur-mainline"></div>
        </div>

        <SecondSection
          firstTitle="258회의 수업으로"
          secondTitle="영어의 4대영역을 골고루!"
          firstDescription="영어를 처음 시작하는 아이들을 위한 1단계 프로그램부터,"
          secondDescription="영어 심화학습을 원하는 아이들을 위한 4단계 프로그램까지"
        />
        <ThirdSection
          firstTitle="자기주도학습 습관을 기르는"
          secondTitle="5,000여개의 디지털 예습 콘텐츠"
          firstDescription="튼튼영어라이브와 함께라면 수업 전 예습도 어렵지 않아요!"
          secondDescription=""
        />
        <SixthSection />
        <SoldSeparatelySectionSection />
      </div>
    </>
  );
};
