import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { AdminH1 } from '../components/AdminH1';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';
import { PaymentAll } from './PaymentAll';
import { PaymentCancel } from './PaymentCancel';
import { PaymentInclude } from './PaymentInclude';
import { PaymentNotInclude } from './PaymentNotInclude';
import { PaymentTrail } from './PaymentTrail';
import { PaymentRegular } from './PaymentRegular';
import { PaymentSpecial } from './PaymentSpecial';

export const Payments = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  return (
    <>
      <AdminH1>주문 관리</AdminH1>
      <AdminTabs>
        {[
          {
            id: 1,
            text: '전체',
            url: '/admin/payments/all',
          },
          {
            id: 2,
            text: '체험',
            url: '/admin/payments/trial',
          },
          {
            id: 3,
            text: '정규',
            url: '/admin/payments/regular',
          },
          {
            id: 4,
            text: '특강',
            url: '/admin/payments/special',
          },
          {
            id: 5,
            text: '교재포함 결제',
            url: '/admin/payments/true',
          },
          {
            id: 6,
            text: '교재제외 결제',
            url: '/admin/payments/false',
          },
          {
            id: 7,
            text: '취소/교환/환불',
            url: '/admin/payments/cancel',
          },
        ].map((payment) => (
          <AdminTab
            key={payment.id}
            text={payment.text}
            selected={payment.url === pathname}
            onClick={() => push(payment.url)}
          />
        ))}
      </AdminTabs>

      <Switch>
        <Route path="/admin/payments/cancel" component={PaymentCancel} exact />
        <Route path="/admin/payments/trial" component={PaymentTrail} exact />
        <Route
          path="/admin/payments/regular"
          component={PaymentRegular}
          exact
        />
        <Route
          path="/admin/payments/special"
          component={PaymentSpecial}
          exact
        />
        <Route
          path="/admin/payments/false"
          component={PaymentNotInclude}
          exact
        />
        <Route path="/admin/payments/true" component={PaymentInclude} exact />
        <Route path="/admin/payments/all" component={PaymentAll} exact />
        <Route path="/admin/payments">
          <Redirect to="/admin/payments/all" />
        </Route>
      </Switch>
    </>
  );
};
