import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, TeacherGuide } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const TeacherGuides = () => {
  const queryString = useQueryString();
  const { data } = useQuery<Paginated<TeacherGuide>>(
    `/admin/teacher-guides${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>교사교육실</AdminH1>
        <Button
          text="추가"
          to={'/admin/teacher-guides/add'}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>작성일</Table.Th>
              <Table.Th>타이틀</Table.Th>
              <Table.Th>숨김 여부</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((teacherGuide) => (
              <Table.Row key={teacherGuide.id}>
                <Table.Td>
                  {utcToLocalFormat(
                    teacherGuide.createdAt,
                    MomentFormat.YYYYMMDD
                  )}
                </Table.Td>
                <Table.Td>{teacherGuide.title}</Table.Td>
                <Table.Td>{teacherGuide.isHide ? 'O' : 'X'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/teacher-guides/${teacherGuide.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/teacher-guides" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
