import { api } from './axios';
import { studentState, testResultState } from './ridge';

export class LevelTest {
  static window1: Window | null;
  static window2: Window | null;

  static openTest(type: 'A' | 'B' | 'C') {
    LevelTest.window1 = window.open(
      `${
        window.location.origin
      }/leveltest/leveltest${type}/index.html?t=${Date.now()}`
    );
  }

  static startTest() {
    const student = studentState.get();
    if (student) {
      const result = testResultState.get();
      const message = {
        op: result ? 'start_scoresheet' : 'start_leveltest',
        payload: {
          skip: process.env.REACT_APP_SKIP_LEVEL_TEST_AUDIO,
          name: student.name,
          age:
            new Date().getFullYear() - new Date(student.dob).getFullYear() + 1,
          score_sheet: student.isTested ? result?.json : '',
        },
      };
      LevelTest.window1?.postMessage(message, '*');
    } else {
      const testResult = localStorage.getItem('testResult');
      const isTested = localStorage.getItem('isTested');
      const studentName = localStorage.getItem('studentName');

      const message = {
        op: testResult ? 'start_scoresheet' : 'start_leveltest',
        payload: {
          skip: process.env.REACT_APP_SKIP_LEVEL_TEST_AUDIO,
          name: studentName,
          age: '',
          score_sheet: isTested === 'true' ? testResult : '',
        },
      };
      LevelTest.window1?.postMessage(message, '*');
    }
  }

  static endTest(result: any) {
    api
      .post(`/students/${studentState.get()?.id}/test-results`, {
        json: JSON.stringify(result),
        type: result.type,
        score: result.nCorrect,
        score100: result.score100,
      })
      .catch((err) => {
        alert(`${err.response.data.message}`);
        return;
      });
  }

  static openResult(iframeName?: string) {
    LevelTest.window2 = window.open(
      `${window.location.origin}/leveltest/report/index.html?t=${Date.now()}`,
      iframeName
    );
  }

  static async showResult() {
    const result = testResultState.get();
    if (result) {
      const message = {
        op: 'start_gradecard',
        payload: {
          score_sheet: JSON.parse(result?.json),
        },
      };
      LevelTest.window2?.postMessage(message, '*');
    } else {
      const testResult = localStorage.getItem('testResult');
      const message = {
        op: 'start_gradecard',
        payload: {
          score_sheet: JSON.parse(testResult || ''),
        },
      };
      LevelTest.window2?.postMessage(message, '*');
    }
  }

  static handleMessage({ data }: MessageEvent) {
    switch (data.op) {
      case 'leveltest_ready':
        localStorage.setItem('leveltest_ready', data.op);
        return LevelTest.startTest();
      case 'leveltest_done':
        localStorage.removeItem('leveltest_ready');
        return LevelTest.endTest(data.payload);
      case 'gradecard_ready':
        return LevelTest.showResult();
    }
  }
}

window.addEventListener('message', LevelTest.handleMessage);
