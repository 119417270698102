import React from 'react';
import { useHistory } from 'react-router-dom';
import { H5 } from '../components/H5';
import { PlusIcon } from '../components/icons';
import {
  courseState,
  popupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import { ClassType } from '../types';

export const ReservationPage = () => {
  const { push } = useHistory();
  const student = studentState.useValue();
  const course = courseState.useValue();
  const testResult = testResultState.useValue();

  if (
    course?.startLevel?.classType !== ClassType.SPECIAL &&
    !student?.isTested
  ) {
    push('/learn/classroom');
  }

  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const endedAt = new Date(course?.endedAt || '');
  endedAt.setHours(0, 0, 0, 0);

  if (
    !course ||
    (course?.startLevel?.classType !== ClassType.SPECIAL &&
      (!testResult || !student?.isTested))
  ) {
    return <></>;
  }
  return (
    <div className="flex flex-col space-y-5 flex-1">
      <H5>수업 예약하기</H5>

      <div className="grid md:grid-cols-2 gap-6">
        <div
          className="group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm rounded-lg flex flex-col items-center justify-center py-24"
          onClick={async () => {
            if (course.endedAt < now.toISOString()) {
              popupState.set({
                title: '수강권 수강기한 확인',
                body: '수강기한이 지난 수강권은 수업 예약이 불가합니다.\n수강기한을 확인해주세요.',
                primaryText: '확인',
              });
              return;
            }
            if (course?.isTrial) {
              return push('/reservation/time-first');
            }
            if (!course?.isOpen) {
              popupState.set({
                title: '유효기간 안내',
                body: '수강권 구매 후 아래의 경우 중 하나를 시작한 때부터 유효기간이 기산됩니다.',
                secondBody: `1. 디지털 예습 콘텐츠를 첫 열람시\n2. 첫 수업 예약시\n\n[정규]\n* 12회: 20주\n* 24회: 40주\n\n[스피킹]\n* 4회: 6주\n* 8회: 12주\n* 12회: 18주\n\n* 학습 시작 전 ‘로그인>부모님>공지사항’을 정독하시면\n앞으로의 학습에 많은 참고가 될 거예요😀\n`,
                primaryText: '수강 예약하기',
                primaryClick: () => push('/reservation/time-first'),
              });
            } else {
              push('/reservation/time-first');
            }
          }}
        >
          <div className="group-hover:bg-primary-1 bg-gray-50 rounded-full wh-14 grid place-items-center">
            <PlusIcon className="group-hover:text-white text-gray-400 fill-current" />
          </div>
          <H5 className="mt-4 mb-2">시간 먼저 선택</H5>
          <p className="text-12 text-gray-400">
            원하는 시간을 먼저 선택할 수 있어요!
          </p>
        </div>

        <div
          className="group transition duration-300 ease-in-out hover:shadow-md bg-white shadow-sm rounded-lg flex flex-col items-center justify-center py-24"
          onClick={async () => {
            if (course.endedAt < now.toISOString()) {
              popupState.set({
                title: '수강권 수강기한 확인',
                body: '수강기한이 지난 수강권은 수업 예약이 불가합니다.\n수강기한을 확인해주세요.',
                primaryText: '확인',
              });
              return;
            }
            if (course?.isTrial) {
              return push('/reservation/teacher-first');
            }
            if (!course?.isOpen) {
              popupState.set({
                title: '유효기간 안내',
                body: '수강권 구매 후 아래의 경우 중 하나를 시작한 때부터 유효기간이 기산됩니다.',
                secondBody: `1. 디지털 예습 콘텐츠를 첫 열람시\n2. 첫 수업 예약시\n\n[정규]\n* 12회: 20주\n* 24회: 40주\n\n[스피킹]\n* 4회: 6주\n* 8회: 12주\n* 12회: 18주\n\n* 학습 시작 전 ‘로그인>부모님>공지사항’을 정독하시면\n앞으로의 학습에 많은 참고가 될 거예요😀\n`,
                primaryText: '수강 예약하기',
                primaryClick: () => push('/reservation/teacher-first'),
              });
            } else {
              push('/reservation/teacher-first');
            }
          }}
        >
          <div className="group-hover:bg-primary-1 bg-gray-50 rounded-full wh-14 grid place-items-center">
            <PlusIcon className="group-hover:text-white text-gray-400 fill-current" />
          </div>
          <H5 className="mt-4 mb-2">선생님 먼저 선택</H5>
          <p className="text-12 text-gray-500">
            원하는 선생님을 먼저 선택할 수 있어요!
          </p>
        </div>
      </div>
    </div>
  );
};
