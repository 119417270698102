import React from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Table } from '../../components/Table';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Coupon, CouponType } from '../../types';

enum CouponStatus {
  NONE,
  USED,
  AVAILABLE,
  EXPIRED,
  UNAVAILABLE,
}

enum DownloadStatus {
  DOWNLOADED,
  AVAILABLE,
  UNAVAILABLE,
}

type RewardsCouponListItemProps = {
  coupon: Coupon;
  download: (couponId: number) => void;
};

const RewardsCouponListItem: React.FC<RewardsCouponListItemProps> = ({
  coupon,
  download,
}) => {
  const now = moment();
  const couponDownloadStartDate = moment(coupon.downloadStart).set({
    h: 0,
    m: 0,
    s: 0,
  });
  const couponDownloadEndDate = moment(coupon.downloadEnd).set({
    h: 23,
    m: 59,
    s: 59,
  });
  const isDownloaded = coupon.couponItems.length > 0;
  const isDownloadNotStarted = now < couponDownloadStartDate;
  const isDownloadExpired = now > couponDownloadEndDate;
  const downloadStatus = isDownloaded
    ? DownloadStatus.DOWNLOADED
    : isDownloadExpired || isDownloadNotStarted
    ? DownloadStatus.UNAVAILABLE
    : DownloadStatus.AVAILABLE;
  const isDownloadAvailable = downloadStatus === DownloadStatus.AVAILABLE;
  const formatDownloadStatus = (downloadStatus: DownloadStatus) => {
    switch (downloadStatus) {
      case DownloadStatus.DOWNLOADED: {
        return '다운완료';
      }
      case DownloadStatus.AVAILABLE: {
        return '다운로드';
      }
      case DownloadStatus.UNAVAILABLE: {
        return '다운불가';
      }
    }
  };

  const couponItem = coupon.couponItems[0];
  let isCouponNotStarted: boolean;
  let isCouponExpired: boolean;
  let couponStatus: CouponStatus;

  if (couponItem) {
    const couponUseStartDate = moment(coupon.useStart).set({
      h: 0,
      m: 0,
      s: 0,
    });
    const couponUseEndDate = moment(coupon.useEnd).set({
      h: 23,
      m: 59,
      s: 59,
    });

    isCouponNotStarted = now < couponUseStartDate;
    isCouponExpired = now > couponUseEndDate;
    couponStatus = [
      DownloadStatus.UNAVAILABLE,
      DownloadStatus.AVAILABLE,
    ].includes(downloadStatus)
      ? CouponStatus.NONE
      : couponItem.used
      ? CouponStatus.USED
      : isCouponExpired
      ? CouponStatus.EXPIRED
      : isCouponNotStarted
      ? CouponStatus.UNAVAILABLE
      : couponItem.isEnabled
      ? CouponStatus.AVAILABLE
      : CouponStatus.UNAVAILABLE;
  } else {
    isCouponNotStarted = false;
    isCouponExpired = false;
    couponStatus = CouponStatus.NONE;
  }

  const formatCouponStatus = (couponStatus: CouponStatus) => {
    switch (couponStatus) {
      case CouponStatus.NONE: {
        return '-';
      }
      case CouponStatus.AVAILABLE: {
        return '사용가능';
      }
      case CouponStatus.EXPIRED: {
        return '기간만료';
      }
      case CouponStatus.USED: {
        return '사용완료';
      }
      case CouponStatus.UNAVAILABLE: {
        return '사용불가';
      }
    }
  };

  return (
    <Table.Row key={coupon.id}>
      <Table.Td className="font-normal space-y-1">
        <div className="font-bold">{coupon.name}</div>
        <div className="whitespace-pre-line text-12">{coupon.description}</div>
      </Table.Td>
      <Table.Td className="font-normal space-y-1 text-12">
        {coupon.type === CouponType.NORMAL ? '일반쿠폰' : '중복쿠폰'}
      </Table.Td>
      <Table.Td className="font-normal space-y-1">
        {coupon.student?.name}
      </Table.Td>
      <Table.Td className="font-normal">~ {coupon.downloadEnd}</Table.Td>
      <Table.Td className="font-normal">~ {coupon.useEnd}</Table.Td>
      <Table.Td className="font-normal">
        {formatCouponStatus(couponStatus)}
      </Table.Td>
      <Table.Td>
        <button
          className={`${
            isDownloadAvailable
              ? 'p-2 border border-gray-900 rounded-md font-bold'
              : 'bg-gray-100 p-2 rounded-md text-gray-300 font-bold'
          } text-13`}
          disabled={!isDownloadAvailable}
          onClick={() => download(coupon.id)}
        >
          {formatDownloadStatus(downloadStatus)}
        </button>
      </Table.Td>
    </Table.Row>
  );
};

export const RewardsCouponsPage = () => {
  const { data: coupons, refetch } = useQuery<Coupon[]>('/coupons', fetcher);

  const download = (couponId: number) => {
    api.post(`/coupons/${couponId}/download`).then(() => refetch());
  };

  return (
    <div className="bg-white shadow-3xl rounded-md space-y-6 p-5">
      {/* Mobile Table */}
      <Table className="md:hidden divide-gray-100">
        <Table.Head className="bg-transparent">
          <Table.Row>
            <Table.Th>쿠폰명 • 쿠폰 유효기간</Table.Th>
            <Table.Th>쿠폰상태</Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body className="divide-gray-50">
          {coupons?.map((coupon) => {
            const downloaded = coupon.couponItems.length > 0;
            const used = !!coupon.couponItems[0]?.used;
            return (
              <Table.Row key={coupon.id}>
                <Table.Td className="font-normal flex flex-col">
                  <p>{coupon.name}</p>
                  <p>{coupon.student?.name}</p>
                  <p>{coupon.useEnd}</p>
                </Table.Td>
                <Table.Td className="font-normal">
                  {downloaded && used && '사용완료'}
                </Table.Td>
                <Table.Td>
                  <button
                    className={`${
                      downloaded
                        ? 'bg-gray-100 p-2 rounded-md text-gray-300 font-bold'
                        : 'p-2 border border-gray-900 rounded-md font-bold'
                    } text-13`}
                    disabled={downloaded}
                    onClick={() => download(coupon.id)}
                  >
                    {downloaded ? '다운완료' : '다운로드'}
                  </button>
                </Table.Td>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {/* Desktop Table */}
      <Table className="hidden md:table divide-gray-100">
        <Table.Head className="bg-transparent">
          <Table.Row>
            <Table.Th>쿠폰명</Table.Th>
            <Table.Th>쿠폰타입</Table.Th>
            <Table.Th>자녀명</Table.Th>
            <Table.Th>다운로드 유효기간</Table.Th>
            <Table.Th>사용 유효기간</Table.Th>
            <Table.Th>쿠폰상태</Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body className="divide-gray-50">
          {coupons?.map((coupon, index) => {
            return (
              <RewardsCouponListItem
                key={index}
                coupon={coupon}
                download={download}
              />
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
