import React, { FC, useState } from 'react';
import { Live } from '../types';
import { Button } from './Button';
import { XIcon } from './icons';
// @ts-ignore
import Stars from './Starts';
import { range } from '../utils';
import { useForm } from 'react-hook-form';
import { api } from '../plugins/axios';

export interface StudentReportPopUpProps {
  live?: Live;
  onClose: () => void;
  refetch: () => void;
}

interface FormValues {
  rate: number;
  liveId: number;
  teacherId: number;
  studentId: number;
}

export const StudentReportPopUp: FC<StudentReportPopUpProps> = ({
  live,
  onClose,
  refetch,
}) => {
  const [hoverTotalRating, setHoverTotalRating] = useState(0);

  const { handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      liveId: live?.id,
      teacherId: live?.teacherId,
      studentId: live?.studentId,
    },
  });

  const onMouseEnterRate = (index: number) => setHoverTotalRating(index);
  const onMouseLeaveRate = () => setHoverTotalRating(0);
  const onSaveRating = (index: number) => setValue('rate', index);

  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="card">
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-24 whitespace-pre">
            {`${live?.teacher?.englishName} 선생님과의\n수업은 어땠나요?`}
          </h1>
          <button className="mt-1" onClick={() => onClose()}>
            <XIcon />
          </button>
        </div>

        <div className="flex justify-center my-10">
          {range(5).map((idx) => {
            return (
              <Stars
                key={idx}
                width={'w-16'}
                index={idx}
                rating={watch('rate')}
                hoverRating={hoverTotalRating}
                onMouseEnter={onMouseEnterRate}
                onMouseLeave={onMouseLeaveRate}
                onSaveRating={onSaveRating}
              />
            );
          })}
        </div>

        <div className="mt-5 flex flex-col space-y-3">
          <Button
            text="확인"
            className="filled-primary-1"
            onClick={handleSubmit((data) => {
              data.teacherId = Number(live?.teacherId);
              data.liveId = Number(live?.id);
              data.studentId = Number(live?.studentId);
              api
                .post('/live-reviews', data)
                .then(async () => {
                  await refetch();
                  alert('피드백 작성이 완료되었습니다.');
                  onClose();
                })
                .catch((err) => console.error(err, '선생님 피드백 생성 오류'));
            })}
          />
        </div>
      </div>
    </div>
  );
};
