import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Level, LiveClass, Paginated } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  goal: string;
  detailedGoal: string;
  words: string;
  patterns: string;
  content: string;
  index: number;
  open: boolean;
  levelId: number;
  seriesId: number;
  bookId: number;
}

export const LiveClassDetails = () => {
  const { push } = useHistory();
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';

  const levelQueryString = useQueryString({
    join: ['serieses', 'serieses.books'],
  });
  const { data: levels } = useQuery<Paginated<Level>>(
    `/admin/levels${levelQueryString}`,
    fetcher
  );
  const { data: liveClass, refetch } = useQuery<LiveClass>(
    `/admin/live-classes/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!liveClass) {
      // 기본값 세팅
      setValue('levelId', levels?.items[0].id ?? 1);
      setValue('seriesId', levels?.items[0].serieses[0].id ?? 1);

      return;
    }
    setValue('goal', liveClass.goal);
    setValue('detailedGoal', liveClass.detailedGoal);
    setValue('words', liveClass.words);
    setValue('patterns', liveClass.patterns);
    setValue('content', liveClass.content);
    setValue('index', liveClass.index);
    setValue('open', liveClass.open);
    setValue('levelId', liveClass.levelId);
    setValue('seriesId', liveClass.seriesId);
    setValue('bookId', liveClass.bookId);
  }, [setValue, liveClass, editMode]);

  return (
    <>
      <AdminH1>회차 관리</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <Select
            label="레벨"
            value={watch('levelId')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('levelId', Number(e.target.value))}
          >
            {levels?.items.map((level) => (
              <option key={level.id} label={level.name} value={level.id}>
                {level.name}
              </option>
            ))}
          </Select>
          <Select
            label="시리즈"
            value={watch('seriesId')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('seriesId', Number(e.target.value))}
          >
            {levels?.items
              .find((level) => level.id === watch('levelId'))
              ?.serieses.map((series) => (
                <option key={series.id} label={series.name} value={series.id}>
                  {series.name}
                </option>
              ))}
          </Select>
          <Select
            label="책"
            value={watch('bookId')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('bookId', Number(e.target.value))}
          >
            {levels?.items
              .find((level) => level.id === watch('levelId'))
              ?.serieses.find((series) => series.id === watch('seriesId'))
              ?.books.map((book) => (
                <option key={book.id} label={book.title} value={book.id}>
                  {book.title}
                </option>
              ))}
          </Select>
          <TextField
            type="number"
            label="index"
            disabled={!addMode && !editMode}
            helper={errors.index?.message}
            {...register('index', { required: 'Please enter index.' })}
          />
          <TextField
            label="학습 목표"
            disabled={!addMode && !editMode}
            helper={errors.goal?.message}
            {...register('goal', { required: 'Please enter goal.' })}
          />
          <TextField
            label="세부 학습 목표"
            disabled={!addMode && !editMode}
            helper={errors.detailedGoal?.message}
            {...register('detailedGoal', {
              required: 'Please enter detailedGoal.',
            })}
          />
          <TextField
            label="주요 단어"
            disabled={!addMode && !editMode}
            helper={errors.words?.message}
            {...register('words', { required: 'Please enter words.' })}
          />
          <TextField
            label="주요 패턴"
            disabled={!addMode && !editMode}
            helper={errors.patterns?.message}
            {...register('patterns', { required: 'Please enter patterns.' })}
          />
          <TextField
            label="내용"
            disabled={!addMode && !editMode}
            helper={errors.content?.message}
            {...register('content', { required: 'Please enter content.' })}
          />
          {!addMode && (
            <Checkbox label="open" disabled={!editMode} {...register('open')} />
          )}
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="Save"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/live-classes/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
            />
          )}
          {addMode && (
            <Button
              text="Save"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                const res = await api.post<LiveClass>(
                  '/admin/live-classes',
                  data
                );
                push(`/admin/live-classes/${res.data.id}`);
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
