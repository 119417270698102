import { useEffect } from 'react';
export const NewTermsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="new-terms-wrap">
        <p className="hv-txt">튼튼영어라이브 서비스 이용 약관</p>
        <p className="nl-txt">제1조 (목적)</p>
        본 약관은 튼튼영어(이하 “회사”라 합니다)가 운영하는 웹사이트
        https://www.tuntunlive.com (이하 “웹사이트”라 합니다)에서 제공하는
        디지털 콘텐츠를 포함한 화상 수업 교육서비스(이하 “서비스”라 합니다)를
        이용함에 있어 이용 조건 및 절차, 회사와 이용자의 권리와 의무 및 책임사항
        등을 규정함을 목적으로 합니다.
        <br />
        <br />
        <p className="nl-txt">제2조 (정의)</p>
        1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        1) 이용자: 이용자란 이 약관에 따라 회사가 제공하는 웹사이트에 접속하여
        서비스를 이용하는 회원 및 비회원을 말합니다.
        <br />
        2) 준회원: 준회원이란 회사에 개인정보를 제공하여 회원 가입을 한 자로,
        튼튼영어라이브에서 판매하는 정규 상품 과정을 구입하지 않은 자를
        말합니다.
        <br />
        3) 정회원: 정회원이란 회사에 개인정보를 제공하여 회원가입을 한 자로,
        튼튼영어라이브에서 판매하는 정규 상품 과정을 구입하여 회사가 제공하는
        콘텐츠 및 서비스를 이용할 수 있는 자를 말합니다.
        <br />
        4) 비회원: 비회원이란 회원으로 가입하지 않고, 회사가 제공하는 콘텐츠 및
        서비스를 이용하는 자를 말합니다.
        <br />
        5) 라이브쌤: 라이브쌤이란 튼튼영어라이브 선생님을 의미하며, 회사의
        웹사이트를 통해 회사가 제공하는 콘텐츠 및 서비스를 이용하여 회원에게
        언어 교육 용역을 제공하는 자를 말합니다.
        <br />
        6) 콘텐츠: 콘텐츠란 디지털학습자료를 의미하며, 디지털학습자료에는
        애니메이션, E-book, 예습 및 복습 콘텐츠가 포함되어 있습니다.
        <br />
        7) 수강권: 수강권이란 디지털학습자료를 포함한 디지털콘텐츠와 화상수업이
        결합되어 분리되지 않는 상품을 말합니다.
        <br />
        8) 서비스: 회사가 운영하는 웹사이트와 앱을 통해 수강권을 구매한 자가
        디지털콘텐츠 포함 권당 1~2회의 학습을 할 수 있도록 하는 서비스를
        말합니다.
        <br />
        9) 옵션상품: 수강권 구매 시 추가로 구매할 수 있는 페이퍼북, 스마트토킹펜
        등을 말합니다.
        <br />
        10) 포인트: 예습 콘텐츠 학습 완료, 바른 수업 태도 등의 이유로 제공받을
        수 있으며, 회사가 지정한 특정 서비스를 이용할 수 있는 비환금성
        마일리지를 뜻합니다.
        <br />
        <br />
        2. 이 약관에서 사용하는 용어의 정의는 본조 제1항에서 정하는 것을
        제외하고는, 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에
        정하지 아니한 것은 일반적인 상관례에 따릅니다.
        <br />
        <br />
        <p className="nl-txt">제3조 (약관의 명시와 개정)</p>
        회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의
        불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 이메일 주소,
        사업자등록번호, 통신판매업 신고번호, 개인정보 관리책임자 등을 이용자가
        쉽게 알 수 있도록 웹사이트 또는 앱의 초기 서비스화면(전면)에 게시합니다.
        다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
        있습니다. 회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의
        규제에 관한 법률, 콘텐츠산업진흥법, 기타 관계법령 또는 상거래 관습에
        위배되지 않는 범위에서 본 약관을 개정할 수 있습니다. 회사는 본 약관을
        개정하는 경우에는 적용 일자 및 개정 사유를 명시하여 현행 약관과 함께 그
        적용일의 7영업일 이전부터 웹사이트 또는 앱 내 서비스 화면 및 연결화면
        등을 통해 게시하고, 회원에게 공지합니다. 다만, 변경된 내용이 회원에게
        불리하거나 중대한 사항의 변경일 경우에는 그 적용일 30일 이전까지 본문과
        같은 방법으로 공지하고 이메일 또는 문자메시지 발송 등의 방법으로
        회원에게 통지합니다. 변경된 약관이 공지된 후 7영업일(변경된 내용이
        회원에게 불리하거나 중대한 사항의 변경일 경우에는 그 적용일 30일) 이내에
        회원이 거부 의사를 표시하지 않는 경우에는 변경된 약관에 동의하는 것으로
        간주합니다. 회원이 개정 약관에 동의하지 않는 경우 회사 또는 회원은
        서비스 이용계약을 해지할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제4조 (약관의 해석)</p>본 약관 외 개별 서비스에
        대한 약관 또는 이용 안내에 대한 고지 등이 존재할 경우 해당 약관이 본
        약관보다 우선합니다. 본 약관에서 정하지 아니한 사항과 본 약관의 해석에
        관해서는 정부가 제정한 콘텐츠산업진흥법, 전자상거래 등에서의
        소비자보호에 관한 법률, 약관의 규제에 관한 법률, 콘텐츠 이용자보호지침
        및 관계법령 또는 상관례에 따릅니다.
        <br />
        <br />
        <p className="nl-txt">제5조 (서비스 제공 및 변경)</p>
        회사는 다음과 같은 업무를 수행합니다.
        <br />
        1) 회사는 라이브쌤이 회원에게 회사의 웹사이트 또는 앱을 통해 수업하는
        것을 중개합니다.
        <br />
        2) 회사는 중개서비스에 있어 라이브쌤과 회원 사이에서 수업이 원활히
        이루어질 수 있도록 서비스를 제공합니다.
        <br />
        3) 회사는 위와 관련한 부수적 업무를 지원합니다.
        <br />
        ① 회사는 필요하면 서비스 일부를 회사가 계약한 제휴업체 등 제 삼자에게
        위탁할 수 있으며, 이용자의 개인정보를 위탁할 시 어떤 곳에 어떤 목적으로
        제공되는지에 대한 별도 동의를 받습니다.
        <br />
        ② 제1항의 서비스 제공은 웹사이트와 앱을 통해 고지된 연중 서비스 시간
        동안 제공됩니다. 다만, 회사의 업무상 또는 기술상의 이유로 서비스가 일시
        중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.
        <br />
        ③ 일부 서비스에 대해서는 특정 회원에게만 서비스를 제공할 수 있습니다. 이
        경우 그 내용을 사전에 공개합니다.
        <br />
        <br />
        <p className="nl-txt">제6조 (서비스의 중단)</p>
        1. 회사는 천재지변, 국가비상사태 등 회사가 정상적인 서비스 제공이
        불가능할 경우 일시적으로 서비스를 제한, 정지시킬 수 있으며 사전 또는
        사후에 라이브쌤 및 이용자에게 중지사유 및 기간을 공지합니다.
        <br />
        <br /> 2. 회사는 회사의 전산장비시설의 보수점검, 교체 및 통신상의 문제가
        발생한 경우 서비스 중지 1주일 전에 고지 후 서비스를 중지할 수 있으며, 이
        기간에 회원이 고지 내용을 인지하지 못한 것에 대하여 회사는 책임을 지지
        않습니다. 상당한 이유가 있을 경우 위 사전 고지가 생략되거나 고지 기간이
        감축될 수 있습니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가
        있는 경우 사후에 통지할 수 있습니다.
        <br />
        <br /> 3. 회사는 서비스 내용의 결정, 변경, 유지, 중단에 관한 포괄적인
        권한을 가집니다. 회사는 영업의 폐지, 합병, 분할, 당해 서비스의 수익
        악화, 서비스 유지 등 상당한 이유가 있는 경우 제공하고 있는 전부 또는
        일부 서비스를 변경하거나 중단할 수 있습니다. 이와 같은 경우에는
        이용자에게 통지하고 사용하지 않은 유료 수강권에 한해 회사에서 정한
        조건에 따라 보상합니다.
        <br />
        <br />
        4. 회사는 서비스의 이용에 필요한 경우 정기점검을 할 수 있으며, 정기점검
        시간은 웹사이트와 앱의 서비스 제공화면에 공지한 바에 따릅니다.
        <br />
        <br />
        <p className="nl-txt">제7조 (회원가입)</p>
        1. 회원가입은 이용자가 약관의 내용에 대하여 동의를 하고 회원가입 신청을
        한 후 회사가 이러한 신청에 대하여 승낙함으로써 이루어집니다. 이용자가
        회원가입 신청을 하는 경우 회사의 이용약관에 동의한 것으로 간주합니다.
        <br />
        <br />
        2. 회사는 아래 해당 사항에 해당하는 경우 이용자의 회원가입을 허가하지
        않을 수 있습니다.
        <br />
        1) 등록 내용에 허위 기재, 누락, 오기가 있는 경우
        <br />
        2) 본 약관에서 정한 회원 탈퇴 및 자격 상실이 있던 회원의 경우
        <br />
        3) 타인의 명의를 이용한 경우
        <br />
        4) 이용자의 고의 또는 과실로 인하여 승낙할 수 없거나 회사의 규정을
        위반하여 신청한 경우
        <br />
        5) 기타 법령 위반 또는 회사의 규정을 위반하는 경우
        <br />
        <br />
        3. 회사는 이용자의 회원가입 신청을 승낙하는 것을 원칙으로 합니다. 다만,
        회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는
        경우, 기타 회사가 필요하다고 인정하는 경우에는 가입 신청의 승낙을
        유보하거나 거절할 수 있습니다.
        <br />
        <br />
        4. 회원가입 신청에 대하여 유보하거나 거절한 경우 회사는 이를 신청자에게
        알립니다. 다만, 회사가 고의 또는 과실 없이 가입 신청자에게 통지할 수
        없는 경우에는 그러하지 않습니다.
        <br />
        <br />
        5. 회원가입 계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로
        합니다.
        <br />
        <br />
        <p className="nl-txt">
          제8조 (만 14세 미만 이용자의 회원가입 등에 관한 특칙)
        </p>
        1. 회사는 만 14세 미만 이용자(이하 “아동”이라고 한다)의 회원가입 신청에
        대해 승낙을 하기 전에 부모 등 법정대리인(이하 “법정대리인”이라고 한다)의
        동의를 받습니다. 다만, 법정대리인의 동의를 받을 수 없는 경우 아동의
        회원가입 신청에 대하여 거절할 수 있습니다.
        <br />
        <br />
        2. 회사는 회원가입을 신청하는 아동에게 법정대리인의 동의를 받기 위하여
        필요한 법정대리인의 성명 등 최소한의 정보를 요구할 수 있습니다.
        <br />
        <br />
        3. 아동의 법정대리인은 아동의 개인정보 열람, 정정, 갱신을 요청하거나
        회원가입에 대한 동의를 철회할 수 있으며, 동의를 철회한 경우 회사는
        지체없이 수집한 아동 및 법정대리인의 개인정보를 파기하는 등 필요한
        조치를 취합니다.
        <br />
        <br />
        <p className="nl-txt">제9조 (회원정보의 변경)</p>
        1. 회원은 개인정보 관리화면을 통하여 언제든지 자신의 개인정보를
        열람하고, 수정할 수 있습니다.
        <br />
        <br />
        2. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 직접 수정하거나
        이메일, 문자메시지 또는 기타 방법으로 회사에 해당 변경사항을 알려야
        합니다.
        <br />
        <br />
        3. 회사는 회원이 제2항에 따라 변경 사항을 수정하지 않거나 회사에 알리지
        않아 발생한 불이익에 대하여 책임을 지지 않습니다.
        <br />
        <br />
        <p className="nl-txt">
          제10조 (회원 계정 정보 및 비밀번호에 대한 의무)
        </p>
        1. 회원의 계정정보와 비밀번호에 대한 책임은 회원에게 있습니다.
        <br />
        <br />
        2. 회원은 자신의 계정정보 및 비밀번호를 제삼자가 이용하게 해서는 안
        됩니다.
        <br />
        <br />
        3. 회원은 자신의 계정정보 및 비밀번호를 도용 당하거나 제삼자가 사용하고
        있음을 인지하는 경우 바로 회사에 통보하고 회사의 안내에 따라야 합니다.
        <br />
        <br />
        4. 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
        경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임을
        지지 않습니다.
        <br />
        <br />
        <p className="nl-txt">제11조 (회원에 대한 통지)</p>
        1. 회사가 회원에게 통지하는 경우 회원이 지정한 이메일 주소, 휴대
        전화번호 등으로 합니다.
        <br />
        <br />
        2. 회사는 회원 전체에게 통지하는 경우 7영업일 이상의 기간 동안 회사
        사이트의 초기화면에 게시하거나 팝업화면 등을 제시함으로써 제1항의 통지에
        갈음할 수 있습니다. 다만, 제3조에 따른 약관의 변경 또는 회원 본인과 관련
        중요한 사항에 대하여는 제1항에서 정한 통지에 따릅니다.
        <br />
        <br />
        <p className="nl-txt">제12조 (회원탈퇴 및 자격 상실)</p>
        1. 회원은 회사에 언제라도 회원 탈퇴를 요청할 수 있습니다. 회사는 회원의
        탈퇴 요청이 있으면 신속히 회원탈퇴를 처리합니다.
        <br />
        <br />
        2. 회사는 회원이 다음 각 호의 사유에 해당하는 경우, 회원자격을 제한 및
        정지, 강제 환불, 탈퇴 조치를 취할 수 있습니다.
        <br />
        1) 가입신청 또는 콘텐츠 이용 신청 또는 변경 시 허위 내용을 등록한 경우
        <br />
        2) 회사를 통해 구매한 서비스의 수강권, 콘텐츠 이용 대금 및 기타 회사의
        서비스 이용에 관하여 회원이 부담하는 채무를 기일까지 지급하지 않은 경우
        <br />
        3) 다른 이용자의 정보를 도용하여 질서를 위협하는 경우
        <br />
        4) 회사 또는 제삼자가 게시한 정보의 변경
        <br />
        5) 회사가 금지한 정보(컴퓨터 프로그램 등)의 사용, 송신 또는 게시
        <br />
        6) 회사 또는 제삼자의 저작권 등 지식재산권에 대한 침해
        <br />
        7) 회사 또는 제삼자의 명예를 훼손하거나 업무를 방해하는 행위
        <br />
        8) 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는
        정보를 회사의 사이트에 공개 또는 게시하는 행위
        <br />
        9) 회사의 기술적 보호조치를 회피 혹은 무력화하는 행위
        <br />
        10) 회사의 웹 또는 앱에서 법령이나 본 약관이 금지하는 행위를 하거나
        공서양속에 반하는 행동을 하는 경우
        <br />
        11) 라이브쌤과 수업 진행 도중 외설(성희롱, 음담패설) 또는 폭력적인 말
        등의 회원 귀책이 확실한 행동을 하는 경우
        <br />
        <br />
        3. 회원 자격이 제한 또는 정지되었던 회원이 제2항 각호에서 금지한 행위를
        반복하거나 그 회원에게 시정을 요구하였음에도 불구하고 7영업일 이내에 그
        사유를 시정하지 아니하는 경우, 잔여 수강권에 대한 강제 환불 및
        회원자격을 상실시킬 수 있습니다. 또한, 그 행위에 귀책사유가 명확하고
        중대한 문제라고 판단하는 경우, 위에서 명시한 운영 대응 외에 법적인
        조치를 취할 수 있습니다.
        <br />
        <br />
        4. 회사가 제3항에 따라 회원자격을 상실시킨 경우 당해 회원에게 이 사실과
        사유를 통지합니다. 다만, 당해 회원이 통지를 받은 날부터 15일 이내에
        소명하고, 그 내용이 정당한 경우 회사는 즉시 회원의 자격을 복구합니다.
        <br />
        <br />
        <p className="nl-txt">
          제13조 (이용 계약의 체결 및 수강권의 이용, 가격, 연장, 재구매)
        </p>
        1. 이용 계약의 체결: 회원이 웹사이트와 앱을 통해서 회사가 정한 절차에
        따라 수강권 등을 구매하고 이에 상응하는 대금을 납부하는 것으로 서비스
        이용 계약이 체결됩니다. 서비스 이용 계약의 체결로 회원은 서비스를 이용할
        수 있는 권리를 취득하며, 해당 서비스가 수강권에 해당하는 경우에는
        라이브쌤과 수업할 수 있는 권리를 취득합니다.
        <br />
        <br />
        2. 수강권: 구매자는 구입한 제품에 대한 교육 서비스를 구매일로부터
        수강유효기간까지 회사가 운영하는 웹사이트와 앱에서 권당 1회에 25분의
        학습서비스를 받을 수 있습니다. 단, 세부 스케쥴은 구매자가 직접 선택할 수
        있습니다. 해당 서비스는 튼튼영어라이브 웹사이트나 앱 또는 회사가 지정한
        정식 온라인 사이트를 통해 구입한 정품에 한하며, 중고거래나 양도 등 기타
        다른 경로로 보유한 경우에는 서비스를 제공받을 수 없습니다. 수강권은 학습
        서비스 시작 5분 경과 시 1회가 차감되며, 10분 이내에 참석하지 않은
        경우에도 차감됩니다. 수강권의 유효기간과 콘텐츠 열람기간은 본조 4항과
        같습니다.
        <br />
        <br />
        3. 가격: 수강권 등의 서비스 이용 요금은 회사가 지정한 요금 체계에 의해
        회사의 웹사이트와 앱에 공지한 가격을 기준으로 정합니다. 해외 사용자의
        경우, 환율에 따라 일부 차이가 있을 수 있으며, 회사는 급격한 환율
        변동으로 인한 가격 수정이 필요한 경우 사전 고지 없이 요금제를 일부
        조정할 수 있습니다.
        <br />
        <br />
        4. 이용 기간
        <br />
        1) 정규 수강권 유효기간 : 12회 구매 시 20주, 24회 구매 시 40주, 48회
        구매 시 70주
        <br />
        2) 특강 수강권 유효기간 : 4회 구매 시 6주, 8회 구매 시 12주, 12회 구매
        시 18주
        <br />
        3) 콘텐츠 열람기간: 수강권 유효기간과 동일. 최초 콘텐츠 열람일부터
        열람기간(=유효기간) 적용.
        <br />
        4) 취소, 환불 등 없이 정상적으로 보유한 수강권의 경우 유효기간이 끝나도
        콘텐츠(디지털 학습 콘텐츠, e-Book)는 열람할 수 있습니다.
        <br />
        <br />
        5. 연장: 모든 결제 상품의 이용 기간 연장은 불가합니다. 다만, 예외적인
        경우 연장 여부는 상이할 수 있으며 이는 상품별 특약 조건을 따릅니다. 연장
        기간 동안에는 수강권의 교환/환불이 불가합니다.
        <br />
        <br />
        6. 재구매(추가구매)
        <br />
        1) 기존 결제 수강권의 유효기간이 남은 상태에서 수강권을 추가구매 하는
        경우 ‘선구매 수강권 종료일+1일’부터 후구매 수강권의 유효기간이
        시작됩니다.
        <br />
        2) 기존 결제 수강권의 유효기간이 남은 상태에서 소비자가 추가 구매한
        수강권의 개시 의사를 표시하는 경우, 회사와 상호 협의를 통해 해당
        시점부터 추가 구매 수강권의 이용이 가능합니다.
        <br />
        3) 유효기간은 각 수강권 별로 산정되기 때문에 보유한 수강권의 유효기간을
        합산할 수 없습니다.
        <br />
        (예: 12회, 24회 수강권을 보유하였다고 수강권의 유효기간이 60주가 되지
        않음. 12회 수강권을 3주만에 사용하고 17주의 유효기간이 남은 상황에서
        추가 구매한 24회 수강권을 사용하는 경우, 처음 구매한 12회 수강권의
        유효기간은 유지 후 소멸, 24회 수강권은 콘텐츠 첫 열람 후 유효기간 40주
        유지 후 소멸)
        <br />
        <br />
        <p className="nl-txt">제14조 (대금지급수단)</p>
        1. 수강권은 다음 각 호의 하나로 구매할 수 있습니다.
        <br />
        1) 신용카드 결제
        <br />
        2) 실시간 계좌이체
        <br />
        3) 회사에서 발행한 쿠폰
        <br />
        4) 기타 회사가 정한 방법
        <br />
        <br />
        2. 회사에서 발행한 쿠폰은 이에 명시된 기간 안에만 사용할 수 있으며
        현금으로 교환할 수 없습니다. 쿠폰 다운로드 이후 해당 쿠폰이 발급 중지된
        경우, 이미 다운로드 받은 고객은 명시된 기간 내에 사용할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제15조 (회원의 청약철회)</p>
        1. 회사와 콘텐츠 구매 계약을 체결한 회원은 다음 각 호에서 정한 기간 내에
        청약을 철회할 수 있습니다.
        <br />
        1) 구매 계약 체결일로부터 7일 이내
        <br />
        2) 수강권의 공급이 늦게 이루어진 경우, 수강권을 공급받거나 수강권의
        공급이 시작된 날부터 7일 이내
        <br />
        <br />
        2. 본조 제1항의 청약철회권은 다음 각 호의 어느 하나에 해당할 경우 행사할
        수 없습니다. 다만, 회사는 제2호부터 제4호까지에 해당하는 회원의
        청약철회권을 배제하기 위하여 청약철회를 할 수 없다는 사실을 콘텐츠 또는
        이의 포장 및 기타 이용자 및 회원이 쉽게 알 수 있는 곳에 표시해야 하거나
        사용상품 제공이나 콘텐츠를 한시적 또는 일부 이용 가능하게 하는 등의
        방법으로 청약철회권의 행사가 방해받지 아니하도록 조치를 취합니다.
        <br />
        1) 회원의 책임 있는 사유로 콘텐츠가 멸실 또는 훼손된 경우
        <br />
        2) 회원의 사용 또는 일부 소비에 의하여 콘텐츠의 가치가 현저히 감소한
        경우
        <br />
        3) 시간의 경과에 의하여 재판매가 곤란할 정도로 콘텐츠의 가치가 현저히
        감소한 경우
        <br />
        4) 복제가 가능한 콘텐츠의 포장을 훼손한 경우
        <br />
        5) 회원의 주문에 의하여 콘텐츠가 개별적으로 생산되는 경우 등 청약철회를
        인정하면 회사에 회복할 수 없는 중대한 피해가 예상되는 경우로써 사전에
        당해 거래에 대하여 별도로 그 사실을 고지하고 회원의 서면(전자문서
        포함)에 의한 동의를 얻은 경우
        <br />
        <br />
        3. 제1항 및 제2항에도 불구하고 회원은 다음 각 호의 어느 하나에 해당하는
        사유가 있을 때는 당해 콘텐츠를 이용할 수 있는 날부터 3월 이내 또는 그
        사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 콘텐츠 구매계약에 대한
        청약을 철회할 수 있습니다.
        <br />
        1) 표시ㆍ광고된 내용과 다른 콘텐츠가 제공된 경우
        <br />
        2) 계약내용과 다르게 이행된 경우
        <br />
        <br />
        4. 회원은 제1항 또는 제3항의 규정에 의한 청약의 철회를 전화, 이메일,
        문자메시지, 팩스 및 기타 회사가 정한 방법으로 할 수 있습니다.
        <br />
        <br />
        5. 회원이 제2항 또는 제3항의 규정에 의한 청약철회권을 서면(전자문서
        포함)으로 행사하는 경우 그 의사표시가 기재된 서면을 발송한 날에 그
        효력이 발생합니다.
        <br />
        <br />
        6. 회사는 청약철회의 의사표시를 수신한 후 지체없이 이의 수신 사실을
        회원에게 회신합니다.
        <br />
        <br />
        <p className="nl-txt">제16조 (회원의 계약해제ㆍ해지)</p>
        1. 회원은 다음 각 호의 하나의 사유가 있는 경우 콘텐츠 구매계약을 해제
        또는 해지할 수 있습니다.
        <br />
        1) 콘텐츠가 제공시기까지 제공되지 않은 경우
        <br />
        2) 하자 있는 콘텐츠의 제공으로 인하여 회원이 이용목적을 달성할 수 없는
        경우
        <br />
        3) 1개월 동안 콘텐츠의 이용 중지 및 장애 발생 누적시간이 72시간을 초과한
        경우
        <br />
        4) 회원이 변경 약관을 거절하거나 동의한 것으로 간주되는 경우
        <br />
        5) 회사가 콘텐츠 이용 조건 등의 변경 사유 등을 게시하지 않거나 회원이
        이용 조건 변경 등을 거절한 경우
        <br />
        <br />
        2. 회원이 제1항 제1호 또는 제2호의 사유로 콘텐츠 구매계약을 해제 또는
        해지하기 위해서는 상당한 기간을 정하여 회사에게 콘텐츠의 이용을 가능하게
        하거나 하자 없는 콘텐츠를 제공할 것을 요구해야 합니다. 다만, 회원이 당해
        제공시기에 콘텐츠를 제공받지 아니하면 그 계약의 목적을 달성할 수 없는
        경우 또는 회사가 사전에 콘텐츠를 제공하지 않을 것을 명시한 경우에는
        그러하지 않습니다.
        <br />
        <br />
        <p className="nl-txt">제17조 (회사의 계약 해제ㆍ해지 및 이용제한)</p>
        1. 회사는 회사가 본 약관에서 금지하는 행위를 한 회원에게 시정을
        요구하였음에도 불구하고, 30일 이내에 그 사유가 시정되지 않은 경우 콘텐츠
        구매계약을 해제 또는 해지할 수 있습니다.
        <br />
        <br />
        2. 본조의 해제ㆍ해지는 본 약관에서 정하고 있는 통지 방법에 따라 회원에게
        그 의사표시가 도달한 때에 효력이 발생합니다.
        <br />
        <br />
        3. 회원은 회사의 해제ㆍ해지 및 이용제한에 대하여 이의신청을 할 수
        있습니다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이 콘텐츠를
        이용할 수 있도록 필요한 조치를 취해야 합니다.
        <br />
        <br />
        <p className="nl-txt">
          제18조 (서비스 보류, 정지, 수강권 등록 취소 등)
        </p>
        1. 회원은 수강권이 성공적으로 취소됨과 동시에 서비스에 대한 수강 권한을
        상실합니다.
        <br />
        <br />
        2. 회원이 수업 도중 회사 및 라이브쌤을 비롯한 제삼자에게 피해를 주었을
        경우 수강권이 취소되었더라도 회원의 책임은 면제되지 아니합니다.
        <br />
        <br />
        3. 회사는 다음 각 호의 사항에 따라 해당 회원에 대한 수업을 사전 통지
        없이 중단, 보류, 정지 및 취소시킬 수 있습니다.
        <br />
        1) 본 이용 약관에 명시된 규정을 위반하였을 경우
        <br />
        2) 회사나 라이브쌤의 수업 서비스 지침을 따르지 않는 경우
        <br />
        <br />
        4. 회사는 본조 제3항의 1, 2호에 명시된 사유로 이용자의 수업을 정지 및
        취소하였을 경우, 이미 사용한 수업에 대해 환불을 하지 않습니다.
        <br />
        <br />
        <p className="nl-txt">제19조 (환불에 대한 일반 원칙)</p>
        1. 회사는 회원이 수강권을 결제했던 방법으로 환불을 진행합니다. 예를 들어
        무통장 입금으로 결제한 경우 환불 또한 무통장으로 진행되며, 카드 결제의
        경우 카드의 부분 취소 및 전체 취소로 환불하는 것을 원칙으로 합니다.
        다만, 회사가 사정에 의해 같은 방법으로 환불할 수 없을 때 회원에게 사전
        고지 후 다른 방법으로 변경하여 환불할 수 있습니다.
        <br />
        <br />
        2. 회사는 청약철회, 해제 및 해지의 경우 통지를 받은 날 또는 콘텐츠를
        반환 받은 날 중 늦은 일자로부터 10 영업일 이내에 대금 환불을 완료합니다.
        결제 대행사를 통해 환불이 이루어지는 경우 환불 시일은 해당 결제사의
        사정에 따라 지연될 가능성이 있으며 회사는 결제사의 사정에 의한 환불
        지연에 대하여 책임지지 않고 회원이 동의한 결제사의 약관에 따라
        처리합니다.
        <br />
        <br />
        3. 회원은 구매계약의 청약철회, 해제 및 해지의 경우 반납이 가능한 일체의
        콘텐츠를 반납하여야 할 의무가 있으며, 이를 이행하지 않은 경우 해당
        콘텐츠 등의 비용을 제외한 금액만 환불됩니다.
        <br />
        <br />
        4. 콘텐츠 환불 시 은행, 카드사 등의 결제 수단에 대한 수수료는 제외하고
        환불될 수 있습니다.
        <br />
        <br />
        5. 회사는 다음과 같은 경우 환불을 거절할 수 있습니다.
        <br />
        1) 회원의 실수로 이용 기간 내에 회원이 서비스를 이용하지 못한 경우
        <br />
        2) 환불 관련 제비용이 잔여이용료를 초과하는 경우
        <br />
        3) 이미 서비스에서 탈퇴한 후, 환불을 요구하는 경우
        <br />
        4) 단시간 또는 일회성으로 이용할 수 있는 콘텐츠에 대한 환불을 요구하는
        경우
        <br />
        5) 기타 정당한 환불사유로 판명되지 않은 경우
        <br />
        <br />
        <p className="nl-txt">제20조 (유료 수강권의 환불)</p>
        유료 수강권이란 정기결제 상품, 패키지 수강권 등을 포함한 모든 유료 결제
        상품을 말합니다. 상품별 별도의 규정이 존재하는 경우에도 기본 환불 규정은
        아래 내용을 따릅니다.
        <br />
        <br />
        1. 회원은 수강권을 구매할 당시 상품별로 회사가 고지한 기간 동안 수강권을
        사용하여 라이브쌤과 수업할 수 있는 권리가 있으며 해당 유효기간이 경과한
        경우에는 환불을 요청할 수 없습니다.
        <br />
        <br />
        2. 모든 수강권을 포함한 콘텐츠 등 패키지 수강권의 유효기간(환불
        가능기간)과 사용기간(서비스 이용기간)은 웹사이트와 앱 내에 별도
        표시됩니다.
        <br />
        <br />
        3. 유효기간 내 회원이 임의로 수강권 이용계약을 해지하거나 이용자의 책임
        있는 사유로 인하여 회사가 수강권 이용계약을 해지한 경우에는 각 수강권
        유형별 환불 규정에 따라 환불처리됩니다. 4. 구매한 모든 수강권의 환불은
        각 구매 시점에서 적용된 환불 규정을 우선 적용 받습니다.
        <br />
        <br />
        <p className="nl-txt">제21조 (수강권 및 옵션 상품에 대한 환불)</p>
        1. 수강권의 구매 취소 및 환불은 결제일로부터 7일 이내 미사용 시에만 전액
        환불이 가능합니다.
        <br />
        <br />
        2. 수강권은 디지털 콘텐츠와 화상 수업이 결합된 상품으로써 둘 중 하나라도
        이용을 했다면 둘 다 일부 사용으로 간주되어 환불이 불가합니다.
        <br />
        <br />
        3. 수강권 별 별도로 표시된 환불 가능 유효기간 내 미사용 분에 대한 환불을
        원하는 경우 아래와 같이 적용합니다.
        <br />
        1) 수강권만 구매 시<br />
        ●환불금액 = 최종 결제금액 – 수강권 정가*수업횟수 – 사은품 또는 할인 금액
        <br />
        2) 수강권+옵션상품 구매 시<br />
        ●환불금액 = 최종 결제금액 – 옵션상품 정가 – 수강권 정가*수업횟수 –
        사은품 또는 할인 금액
        <br />
        1) 대상 상품의 최종 결제금액: 결제 시 사용한 포인트 및 할인쿠폰 금액을
        제외한 순수 결제 금액
        <br />
        2) 옵션 상품 정가: 상품 제공사의 공식 소비자 가격을 기준으로 책정됩니다.
        <br />
        3) 정규 커리큘럼 수강권 1개당 정가: 30,000원
        <br />
        각 회차 별 디지털 콘텐츠를 이미 열람한 경우 수업한 것으로 간주합니다.
        <br />
        ●정규 커리큘럼 외 특강 수강권 1개의 정가는 판매 페이지에 고지된 금액을
        기준으로 한다.
        <br />
        4) 회원이 수강료 반환 의사를 표시하지 아니하고 지난 기간은 수강 기간으로
        계산되며, 환불 신청이 접수된 시점을 기준으로 환불을 진행합니다.
        <br />
        <br />
        4. 옵션 상품의 환불
        <br />
        1) 구매자의 단순 변심에 의한 계약 철회의 경우 상품이 개봉되지 않은
        상태여야 하고, 사은품이 포함된 경우 구매 제품과 함께 원상태로 반환해야
        합니다. 사은품을 개봉한 경우 사은품의 소비자 가격을 공제한 후
        환불합니다.
        <br />
        2) 구매자가 판매자의 책임 없는 사유로 제품을 교환 또는 반품하는 경우,
        왕복 배송비를 포함한 물류비용은 구매자가 부담합니다.
        <br />
        [1박스당 가격 기준]
        <br />
        ① 최초 배송비(실비 등 포함): 교환/반품 배송비 5,000원
        <br />
        ② 회수 배송비(실비 등 포함): 교환/반품 배송비 5,000원
        <br />
        ③ 고객부담 왕복 배송비: 교환/반품 배송비 10,000원 (①+②)
        <br />
        *비고: 교환/반품 접수 시에는 판매자가 지정한 택배사를 이용해야 합니다.
        제주 및 도서산간 지역의 경우 추가비용이 발생할 수 있습니다.
        <br />
        3) 계약의 철회는 웹사이트나 앱 내에서 구매취소나 환불을 요청했을 때 그
        효력이 발생합니다.
        <br />
        4) 구매자가 적법한 절차에 따라 계약을 철회하는 경우 처음 인도받은 형태로
        제품을 반환하여야 하고, 판매자는 제품을 반환 받은 후 7영업일 이내에
        제품대금을 환불합니다.
        <br />
        5) 불량 및 A/S의 경우 구매자가 제품 수령 후 7영업일 이내 튼튼영어
        고객센터로 접수하면 교환이 진행됩니다.
        <br />
        6) 옵션 상품의 개별 환불 시, 판매자가 지정한 정가를 차감합니다.
        <br />
        <br />
        5. 제1항에도 불구하고 다음 각 호 중 하나에 해당하는 경우에는 구매 취소
        및 환불이 불가합니다.
        <br />
        1) 이러닝 등의 디지털 교육 콘텐츠의 결제일이 7일이 지난 경우
        <br />
        2) 옵션 상품의 경우, 인도받은 날부터 7일이 지난 경우
        <br />
        3) 구매자에게 책임 있는 사유로 재화 등이 멸실되거나 훼손된 경우
        <br />
        4) 구매자가 제품을 사용하거나 또는 일부 사용하여 재화 등의 가치가 현저히
        감소한 경우
        <br />
        5) 시간이 지남으로써 다시 판매하기 어려울 정도로 재화 등의 가치가 현저히
        감소한 경우
        <br />
        6) 복제할 수 있는 구성품(전자교구재 포함)등의 포장을 훼손한 경우
        <br />
        <br />
        <p className="nl-txt">제22조 (무료 수업)</p>
        1. 이용자 및 회원은 1인 1회에 한하여 회사가 정한 쿠폰 및 이벤트 등의
        별도 규정을 통하여 무료 체험 수업에 참여할 수 있습니다.
        <br />
        <br />
        2. 이용자 및 회원은 무료 수업을 체험하는 동안에도 회사의 이용 약관을
        준수하여야 합니다.
        <br />
        <br />
        3. 회사는 동일한 이용자가 무료 수업을 체험하기 위해 복수의 여러 계정을
        만들거나, 부적절한 계정을 만드는 것으로 판단한 경우 무료 수업 제공을
        제한할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제23조 (포인트 및 쿠폰)</p>
        1. 포인트는 회사가 지정한 방법 내에서 이용 가능한 비환금성 마일리지를
        의미합니다.
        <br />
        <br />
        2. 회원은 예습 콘텐츠 학습, 수업 출석 등을 통해서 포인트를 무상으로
        취득할 수 있습니다.
        <br />
        <br />
        3. 포인트는 이벤트 참여 등 회원의 특정 행동이 완료되었을 때 지급됩니다.
        <br />
        <br />
        4. 포인트는 회사가 지정한 특정 서비스에서 사용할 수 있으며, 사용 조건은
        회사가 정한 기준에 따릅니다.
        <br />
        <br />
        5. 포인트는 지급 기준에 따라 각기 다른 유효기간이 존재하며, 별도 기간이
        정해지지 않은 포인트의 유효 기간은 발급일로부터 1년입니다.
        <br />
        <br />
        6. 포인트 유효기간 만료 전 이용자에게 메일 또는 앱 내 메시지 등을 통해
        만료가 고지됩니다. 유효기간이 지난 포인트는 소멸합니다.
        <br />
        <br />
        7. 포인트는 당사에서 주관하는 비정기성 이벤트에서만 사용할 수 있고,
        튼튼영어라이브 정규 상품 구매에는 사용할 수 없습니다.
        <br />
        <br />
        8. 회원이 부정한 방법(어뷰징)으로 포인트를 취득 혹은 사용하는 행위가
        발견되면, 회사는 사전 통지 없이 해당 포인트를 전액 환수하고 교환 요청을
        취소 처리합니다.
        <br />
        <br />
        9. 회사는 아래 내용 중 하나라도 해당하는 경우 어뷰징 행위로 간주합니다.
        <br />
        1) 불법 프로그램을 통해 아이디를 다수 생성하는 행위(예: 연속된 배열
        아이디, 도메인만 다른 경우)
        <br />
        2) 타인의 개인정보를 도용하여 이용하는 행위(예: 결제 도용 등)
        <br />
        3) 비정상적인 방법으로 포인트를 취득 및 사용하는 모든 행위
        <br />
        4) 포인트를 상품권 혹은 현금으로 사고 파는 행위 및 기타 거래 의도(광고
        포함)가 있는 모든 행위
        <br />
        5) 현금 거래 관련한 채팅 및 블로그 혹은 그에 준하는 사이트를 개설하여
        현금 거래를 유도 및 시도하는 행위
        <br />
        6) 채팅, 블로그, 게시판 혹은 그에 준하는 온/오프라인 채널을 통해 포인트
        관련 현금 거래를 유도 및 시도하는 행위
        <br />
        <br />
        10. 9항에 해당하는 부정한 방법을 통해 이미 회원이 상품을 수령했다면
        회사는 상품 회수 또는 손해배상을 청구합니다. 회사는 사안과 행위의 경중에
        따라 대상 회원의 이용을 제한(접속 차단, 계정 삭제 등)하고 필요 시,
        법적인 조치를 취합니다.
        <br />
        <br />
        11. 포인트는 타인에게 양도할 수 없으며, 회원 탈퇴 시 소멸됩니다.
        <br />
        <br />
        12. 포인트를 상품으로 교환하는 서비스 페이지에서 포인트로 구매한 상품은
        환불 및 교환이 불가합니다. 단, 제품 불량에 의한 교환은 가능합니다.
        <br />
        <br />
        13. 포인트몰에서 포인트와 실물 상품을 교환할 경우, 상품 배송을 위해
        개인정보가 유통사에 제공됩니다. 유통사 정보는 [이용 약관 – 개인 정보
        처리 방침 – 개인정보 취급 위탁 – 배송] 항목에서 확인할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제24조 (수업 및 예약수업)</p>
        1. 회원은 원하는 시간에 가능한 라이브쌤을 선택하거나 회사가 별도로
        규정한 시간 이후 수업에 대해 사전 예약 후 수업을 수강할 수 있습니다.
        <br />
        <br />
        2. 회원은 자신이 보유한 수강권 내에서 수업을 예약할 수 있습니다. 수업
        예약 시점에 해당 수업에 대한 수강권이 선차감되며, 3항에 규정한 수업 예약
        취소 가능 시간 이내에 취소한 경우에만 수강권이 복구됩니다.
        <br />
        <br />
        3. 수업을 예약한 후 회원은 수업 예약 전날 23시 59분 59초 이전에 예약을
        취소할 수 있으며, 수업 당일 24시가 넘은 경우 예약은 취소할 수 있으나
        수강권은 복구되지 않습니다.
        <br />
        (예: 10월 13일 18:00 예약의 경우, 10월 13일 23:59까지 예약 취소 가능.
        10월 14일 24:00 예약 취소 가능하나 수강권 복구 불가능.)
        <br />
        *다만, 회원의 입원, 가족상(喪) 및 이에 준하는 상황으로 수업이 불가능하여
        당일 취소하는 경우 증빙자료제출 및 확인을 통해 수강권을 복구할 수
        있습니다.
        <br />
        <br />
        4. 수업 시작 10분 이후까지 회원이 미출석하여 수업을 진행하지 못한 경우
        회사는 차감된 수강권에 대해 보상이나 보강, 환불을 하지 않습니다.
        <br />
        <br />
        5. 라이브쌤이 회원이 예약한 수업에 출석하지 않아 수업을 진행하지 못한
        경우 회원의 수강권은 당일 24시 내에 복구 처리됩니다.
        <br />
        <br />
        6. 라이브쌤이 회원이 예약한 수업을 취소하는 경우 회원의 수강권은 당일
        1영업일 이내에 복구 처리됩니다.
        <br />
        <br />
        7. 본사 시스템으로 회원의 수업 환경이 원활하지 않아(예: IT오류) 문제가
        생김을 확인한 경우 수강권은 복구, 환불되지 않습니다.
        <br />
        <br />
        8. 위에 규정되지 않은 수업 및 예약 수업의 수강요령은 회사가 별도로
        안내한 페이지에 따르며, 본 약관과 충돌되는 사항이 있는 경우 해당
        페이지의 내용이 우선 적용됩니다.
        <br />
        <br />
        9. 튼튼영어라이브 서비스를 이용하는 회원의 대화 내용 등의 수업 내용은
        수업 품질 관리 및 서비스 향상의 목적으로 녹음/녹취/녹화가 될 수
        있습니다. 녹음/녹취/녹화를 원하지 않는 회원은 사이트 내에서 회사가
        정하는 방법에 따라 거부의사를 밝혀야합니다.
        <br />
        <br />
        10. 회사는 회원의 녹음/녹취/녹화 파일을 성실하고 안전하게 관리할 책임을
        집니다.
        <br />
        <br />
        11. 제7, 8항에 따라 녹음/녹취/녹화된 회원의 대화 내용은 회사의 녹취
        서버에 안전하게 저장되며, 7일이 경과한 후에 자동 폐기됩니다.
        <br />
        <br />
        <p className="nl-txt">제25조 (회사의 의무)</p>
        1. 회사는 법령과 본 약관이 금지하거나 공공의 질서와 공서양속에 반하는
        행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고 안정적으로
        수업을 비롯한 콘텐츠를 제공하는 데 최선을 다해야 합니다.
        <br />
        <br />
        2. 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보
        보호를 위한 보안 시스템을 갖추어야 합니다.
        <br />
        <br />
        3. 회사는 이용자가 원하지 않는 영리 목적의 광고성 이메일을 발송하지
        않습니다. 단, 이용자가 동의할 시 발송할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제26조 (이용자의 의무)</p>
        1. 이용자는 아래 행위를 하여서는 안 됩니다.
        <br />
        1) 회원 가입에 대한 정보 등록 또는 회원 정보 수정 시 허위 내용의 등록
        <br />
        2) 부정 아이디의 생성 및 이용 (타인의 개인정보 도용, 불법 가입
        프로그램의 사용 등)
        <br />
        3) 제3자에 대한 계정정보 및 비밀번호 제공 및 대여
        <br />
        4) 포인트 취득 및 사용과 관련한 어뷰징 행위
        <br />
        5) 회사의 저작권 등 지적재산권에 대한 침해
        <br />
        6) 회사 또는 기타 제3자의 명예를 훼손하거나 업무를 방해하는 행위
        <br />
        7) 기타 본 약관 및 관련 법령을 위반하는 일체의 불법적인 행위
        <br />
        <br />
        2. 회원은 수업 중이거나 기타 서비스를 이용할 때 앱 또는 웹사이트의
        채팅창에서 아래에 명시된 행위을 할 수 없습니다.
        <br />
        1) 수업 운영에 방해가 되는 행위
        <br />
        2) 라이브쌤에게 불법적인 행동을 유도하는 행위
        <br />
        3) 라이브쌤에게 성희롱적인 언행, 이미지, 문자를 전송하는 행위
        <br />
        4) 라이브쌤에게 개인 정보를 물어보거나 개인 이메일, SNS를 요청하여
        별도로 연락하고자 하는 행위
        <br />
        5) 라이브쌤에게 특정 종교, 정치적 성향을 강요하는 행위
        <br />
        6) 라이브쌤에게 경쟁업체로 이직을 유도하기 위한 정보를 묻거나 장려하는
        행위
        <br />
        7) 라이브쌤에게 회사의 고용 조건, 회사 내의 시스템에 대한 정보를 묻는
        행위
        <br />
        8) 라이브쌤에게 욕설을 하거나 개인의 신상을 묻는 행위
        <br />
        9) 라이브쌤에게 부적절한 단어를 사용하거나 성적 표현을 하는 행위
        <br />
        10) 기타 법령 및 약관을 위반하거나 공서양속을 해치는 행위
        <br />
        <br />
        3. 회사는 회원이 위 조항들에 명시된 금지 행위를 하였는지 여부를 검토한
        후, 회원의 서비스 이용 정지 및 탈퇴 여부를 결정할 수 있습니다. 회원의
        귀책 사유로 인해 이용 정지 및 탈퇴가 발생한 경우, 회원이 보유한 수강권은
        강제 정지 처리되며 환불이 불가합니다. 또한 그 행위에 귀책사유가 명확하고
        중대한 문제라고 판단하는 경우, 법적인 조치를 취할 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제27조 (저작권의 귀속 및 이용 제한)</p>
        1. 회사는 웹사이트 또는 앱에 게시된 콘텐츠 등 회사가 창작한 저작물에
        대한 저작권 및 기타 지식재산권을 소유합니다.
        <br />
        <br />
        2. 이용자는 회사의 웹사이트 또는 앱을 이용함으로써 얻은 정보 중 회사
        소유 지식재산권에 관한 정보를 회사의 사전 승낙 없이 복제, 송신, 출판,
        배포, 방송 등 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게
        배포할 수 없습니다.
        <br />
        <br />
        3. 이미지, 사진, 음성녹음, 상표, 로고, 글 및 서비스와 관련한 모든 매체의
        저작권과 소유권은 모두 회사에 귀속됩니다. 이용자들은 회사의 저작권을
        위반할 수 없으며 인쇄물이나 웹사이트, 앱에 업로드하거나 제3자에게 배포할
        수 없습니다.
        <br />
        <br />
        4. 학습 콘텐츠 제휴를 통해 제공되는 콘텐츠의 경우, 튼튼영어라이브
        플랫폼에 맞춰 일부 내용이 수정될 수 있으며 콘텐츠 저작권은 콘텐츠
        제공사에 있습니다. 각 콘텐츠 제휴사는 상품별로 상이하며, 웹사이트와 앱의
        상품 소개 페이지를 통해 고지합니다.
        <br />
        <br />
        <p className="nl-txt">제28조 (면책사유)</p>
        1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
        수 없는 경우에 이용자에게 발생한 손해에 대하여 고의 및 중대한 과실이
        없는 한 책임을 지지 않습니다.
        <br />
        <br />
        2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
        제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
        <br />
        <br />
        3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로
        발생한 손해에 대하여 회사의 고의 또는 과실이 없는 한 책임이 면제됩니다.
        <br />
        <br />
        4. 회사는 이용자 또는 라이브쌤, 제3자가 게시판에 게재한 정보, 자료,
        사실의 신뢰도, 정확성 등의 내용에 대하여 책임을 지지 않습니다.
        <br />
        <br />
        5. 회사는 이용자 상호 간 또는 이용자와 라이브쌤 및 이용자와 제3자 간에
        서비스를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.
        <br />
        <br />
        6. 회사는 이용자가 서비스를 이용하며 기대하는 손익이나 서비스를 통하여
        얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
        <br />
        <br />
        <p className="nl-txt">제29조 (분쟁의 해결)</p>
        1. 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
        보상 처리하기 위하여 관련 법령과 회사 내부 정책에 따라
        피해보상처리기구를 설치 및 운영합니다.
        <br />
        <br />
        2. 회사는 이용자가 제출한 불만 사항 및 의견에 관한 사항을 우선적으로
        처리합니다. 다만, 신속한 처리가 곤란할 경우에는 이용자에게 그 사유와
        처리 일정을 즉시 통보합니다.
        <br />
        <br />
        3. 회사와 이용자 사이에 발생한 분쟁과 관련하여 이용자의 피해구제신청이
        있는 경우 분쟁조정기관의 조정에 따를 수 있습니다.
        <br />
        <br />
        <p className="nl-txt">제30조 (재판권 및 준거법)</p>
        회사와 이용자 사이에 발생한 분쟁에 관한 소송은 서울중앙지방법원을
        관할법원으로 하는 것을 원칙으로 합니다. 회사와 이용자 간에 제기된 분쟁에
        관한 소송에는 한국법을 적용합니다.
        <br />
        <br />
        <br />
        <br />본 약관은 2023년 8월 28일부터 적용됩니다.
      </div>
    </>
  );
};
