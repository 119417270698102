import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Reinforce } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const Reinforces = () => {
  const queryString = useQueryString({
    join: ['student'],
  });
  const { data } = useQuery<Paginated<Reinforce>>(
    `/admin/reinforces${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data) return <></>;
  return (
    <>
      <AdminH1>보강 관리</AdminH1>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>학생명</Table.Th>
              <Table.Th>횟수</Table.Th>
              <Table.Th>금액</Table.Th>
              <Table.Th>결제 여부</Table.Th>
              <Table.Th>메모</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((reinforce) => (
              <Table.Row key={reinforce.id}>
                <Table.Td>{reinforce.student.name}</Table.Td>
                <Table.Td>{reinforce.amount}</Table.Td>
                <Table.Td>{` ${String(reinforce.price).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}</Table.Td>
                <Table.Td>{` ${String(reinforce.price).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}</Table.Td>
                <Table.Td>{` ${String(reinforce.price).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}</Table.Td>
                <Table.Td>{reinforce.isPaid ? '결제 완료' : '미결제'}</Table.Td>
                <Table.Td>{reinforce.memo}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/reinforces/${reinforce.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/reinforces" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
