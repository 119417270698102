import React, { FC } from 'react';
import mainDescriptionImg3 from '../../../assets/img/main-description-img3.png';
import mainDescriptionImg4 from '../../../assets/img/main-description-img3-3.png';
import mainDescriptionImg5 from '../../../assets/img/main-description-img3-4.png';
import mainDescriptionImg6 from '../../../assets/img/main-description-img3-5.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { ReactComponent as Arrow } from '../../../assets/svg/swiper_arrow_bottom.svg';

SwiperCore.use([Pagination, Autoplay, Navigation]);

interface FifthSectionProps {}

const imgData = [
  { id: 1, imgPath: mainDescriptionImg3 },
  { id: 2, imgPath: mainDescriptionImg4 },
  { id: 3, imgPath: mainDescriptionImg5 },
  { id: 4, imgPath: mainDescriptionImg6 },
];

export const FifthSection: FC<FifthSectionProps> = () => {
  return (
    <div className="bg-light-blue con-pa">
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        className="max-w-container"
      >
        <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
          칭찬 뿐인 피드백은 NO!
        </h2>
        <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center">
          체계적이고 솔직한 피드백은 YES!
        </h2>
        <p className="text-15 md:text-xl text-black sub-txt01 text-center font-medium">
          매 수업 촘촘한 피드백으로 학부모와 학습상황 공유
        </p>
        {/* 
          <div className="flex gap-4 justify-center"> */}
        {/* <Slider {...settings} className="relative">
            {imgs.map((img) => {
              <div className="flex flex-col"> 
                <div>
                  <img src={img} className="py-10 m-auto object-cover" />
                  <img
                    src={mainDescriptionImg3_1}
                    className="py-10 m-auto object-cover"
                  />
                </div>
              </div>
            })}
          </Slider> */}
        {/* </div> */}
      </ScrollAnimation>

      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        className="max-w-xl mx-auto relative"
      >
        <div className="space-y-3 absolute top-1/2 hidden md:block left-5 mobile-slide-btn">
          <Arrow className="swiper-button-prev-1 cursor-pointer transform rotate-180" />
          <Arrow className="swiper-button-next-1 cursor-pointer " />
        </div>
        <div className="sam-img-02">
          <img
            src={require('../../../assets/img/sam_img02.png').default}
            className=""
            alt=""
          />
        </div>
        <div className="relative mobile-slide-wrap">
          <Swiper
            // pagination={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            direction={'vertical'}
            loop={true}
            className="mobile-slide"
            navigation={{
              nextEl: '.swiper-button-next-1',
              prevEl: '.swiper-button-prev-1',
            }}
          >
            {imgData.map((banner) => (
              <SwiperSlide key={String(banner.id)}>
                <img alt="" src={banner.imgPath} className="" />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mobile-slide-bg">
            <img
              src={require('../../../assets/img/mobile-frame.png').default}
              className=""
              alt=""
            />
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};
