import { FC } from 'react';
import TextBookImg from '../../assets/img/textbook-img.png';
import TextBookPenImg from '../../assets/img/textbook-pen-img.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { usePopup } from '../../components/PopUp';

interface SoldSeparatelySectionProps {}

export const SoldSeparatelySectionSection: FC<
  SoldSeparatelySectionProps
> = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLoginPopup } = usePopup();
  const handlelibraryClick = () => {
    if (!authenticated) return requireLoginPopup();
    // if (!testResult || !student?.isTested) return requireLevelTestPopup();
    push('/library');
  };
  return (
    <>
      <div className="back-color-b con-pa">
        <div className="max-w-container relative">
          {/* <CurriculumDeco className="absolute right-0 -top-20 md:w-36 w-24" /> */}

          <ScrollAnimation
            animateIn="animate__fadeInUp"
            animateOnce={true}
            className="mx-auto"
          >
            <h2 className="text-2xl md:text-4xl tit-txt01 text-center font-c-w">
              튼튼영어라이브를 즐기는
              <br className="md:hidden block" />
              다양한 방법!
            </h2>
            <p className="text-15 md:text-xl text-center sub-txt01 font-medium font-c-w">
              아이의 추가 학습이 필요하다면,
              <br />
              교재와 스마트펜을 별도로 구매하실 수 있어요!
            </p>
          </ScrollAnimation>

          <div className="back-color-w mt-10">
            <div className="round-spot">별매품</div>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              animateOnce={true}
              className="grid md:grid-cols-2"
            >
              <div className="m-auto">
                <img src={TextBookImg} alt="" className="" />
              </div>

              <div className="text-center md:text-left space-y-3 my-auto">
                <h2 className="text-xl md:text-2xl text-black ">
                  스토리북 / 워크북 패키지
                </h2>
                <p className="md:text-lg text-black font-medium">
                  내 커리큘럼에 꼭 맞는 튼튼영어라이브 스토리북을 직접 연필로
                  써보며 학습해요!
                </p>
                <p className="md:text-lg text-blue-500 font-medium">
                  <p className="text-black">
                    스토리북, 워크북(워크시트) 포함 구성
                  </p>
                  {/* *48회 이상 교재옵션 과정 구매시, 스마트토킹펜 증정 */}
                </p>
              </div>
              <div className="border-b border-gray-70 w-b-l-p"></div>
              <div className="border-b border-gray-70 w-b-l-p mobile-none"></div>
              <div className="m-auto">
                <img src={TextBookPenImg} alt="" className="" />
              </div>

              <div className="md:text-left text-center space-y-3 my-auto">
                <h2 className="text-xl md:text-2xl text-black ">
                  스마트토킹펜
                </h2>
                <p className="md:text-lg text-black font-medium">
                  펜으로 직접 교재를 찍으며, 정확한 발음을 들어요. <br />
                  펜의 녹음 기능을 활용하여 발음 연습도 할 수 있어요!
                </p>
                <p className="md:text-lg text-blue-500 font-medium">
                  *초등과정에서만 적용 가능
                </p>
              </div>
            </ScrollAnimation>
          </div>
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="flex justify-center mt-10">
              <button
                onClick={handlelibraryClick}
                className="bg-white text-blue-500 rounded-full border-blue-500 border-2 px-5 py-2 md:text-20 text-16"
              >
                튼튼영어라이브 스토리북 미리보기 {`>`}
              </button>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </>
  );
};
