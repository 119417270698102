import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { ClassType, Product, ProductLiveType, ProductType } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  type: ProductType;
  name: string;
  liveType: ProductLiveType;
  description: string;
  amount: number;
  originalPrice: number;
  discountRate: number;
  finalPrice: number;
  index: number;
  open: boolean;
  freePen: boolean;
  expiredAt: number;
  classType: ClassType;
}

export const ProductDetails = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);
  const { data: product, refetch } = useQuery<Product>(
    `/admin/products/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    watch,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!product) return;
    clearErrors();
    setValue('type', product.type);
    setValue('name', product.name);
    setValue('liveType', product.liveType);
    setValue('classType', product.classType);
    setValue('description', product.description);
    setValue('amount', product.amount);
    setValue('originalPrice', product.originalPrice);
    setValue('discountRate', product.discountRate);
    setValue('finalPrice', product.finalPrice);
    setValue('index', product.index);
    setValue('open', product.open);
    setValue('freePen', product.freePen);
    setValue('expiredAt', product.expiredAt);
  }, [clearErrors, setValue, product, editMode]);

  return (
    <>
      <AdminH1>상품 정보</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="상품명"
            disabled={!addMode && !editMode}
            helper={errors.name?.message}
            {...register('name', { required: '상품명을 입력해주세요.' })}
          />

          <Select
            label="상품 타입"
            value={watch('type')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('type', e.target.value as ProductType)}
          >
            <option value="" selected disabled>
              상품 타입을 선택해주세요
            </option>
            <option value={ProductType.LIVE}>수강권</option>
            <option value={ProductType.BOOK}>스토리북</option>
            <option value={ProductType.PEN}>토킹펜</option>
          </Select>

          <Select
            label="수업 종류"
            value={watch('classType')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('classType', e.target.value as ClassType)}
          >
            <option value="" selected disabled>
              수업 종류를 선택해주세요.
            </option>
            <option value={ClassType.TRIAL}>체험</option>
            <option value={ClassType.REGULAR}>정규</option>
            <option value={ClassType.SPECIAL}>특강</option>
          </Select>

          <Select
            label="수강권 종류"
            value={watch('liveType')}
            disabled={!addMode && !editMode}
            onChange={(e) =>
              setValue('liveType', e.target.value as ProductLiveType)
            }
          >
            <option value="" selected disabled>
              수강권 종류를 선택해주세요.
            </option>
            <option value={ProductLiveType.TRIAL}>체험 정규</option>
            <option value={ProductLiveType.TRIAL_SPEAKING}>체험 스피킹</option>
            <option value={ProductLiveType.FIRST}>1,2단계</option>
            <option value={ProductLiveType.SECOND}>3,4단계</option>
            <option value={ProductLiveType.SPEAKING}>스피킹</option>
          </Select>

          <TextField
            type="number"
            label="수량"
            disabled={!addMode && !editMode}
            helper={errors.amount?.message}
            {...register('amount', { required: '수량을 입력해주세요.' })}
          />
          <TextField
            type="number"
            label="유효기간 개월 수"
            disabled={!addMode && !editMode}
            {...register('expiredAt')}
          />
          <TextField
            type="number"
            label="정가"
            disabled={!addMode && !editMode}
            helper={errors.originalPrice?.message}
            {...register('originalPrice', { required: '정가를 입력해주세요.' })}
          />
          <TextField
            type="number"
            label="할인율"
            disabled={!addMode && !editMode}
            helper={errors.discountRate?.message}
            {...register('discountRate', {
              required: '할인율을 입력해주세요.',
            })}
          />
          <TextField
            type="number"
            label="최종 금액"
            disabled={!addMode && !editMode}
            helper={errors.finalPrice?.message}
            {...register('finalPrice', {
              required: '최종 금액을 입력해주세요.',
            })}
          />
          <TextArea
            label="상세 설명"
            disabled={!addMode && !editMode}
            helper={errors.description?.message}
            {...register('description')}
          />
          <TextField
            type="number"
            label="index"
            disabled={!addMode && !editMode}
            helper={errors.index?.message}
            {...register('index', {
              required: 'index 를 입력해주세요.',
            })}
          />
          <Checkbox
            label="노출여부"
            disabled={!addMode && !editMode}
            {...register('open')}
          />
          <Checkbox
            label="TLD증정"
            disabled={!addMode && !editMode}
            {...register('freePen')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <>
              <Button
                text="취소"
                className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                onClick={() => setEditMode(false)}
              />
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                onClick={handleSubmit(async (data) => {
                  await api.patch(`/admin/products/${id}`, data);
                  await refetch();
                  setEditMode(false);
                })}
              />
            </>
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={
                !watch('type') ||
                !watch('name') ||
                !watch('amount') ||
                !watch('originalPrice') ||
                !watch('discountRate') ||
                !watch('finalPrice') ||
                !watch('index')
              }
              onClick={handleSubmit(async (data) => {
                await api
                  .post<Product>('/admin/products', data)
                  .then((res) => push(`/admin/products/${res.data.id}`))
                  .catch((err) => console.log(err));
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
