/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import {
  PaymentMethod,
  PaymentParamDto,
  PaymentReinforceParamDto,
  PaymentSpecialParamDto,
  PaymentTrialParamDto,
} from '../../types';
import { createContainer } from '../createContainer';
import {
  postPaymentStart,
  postReinforcePaymentStart,
  postSpecialPaymentStart,
  postTrialPaymentStart,
} from './payment.api';
import { PaymentStartDto } from './payment.api.types';

export const getSiteCode = (paymentMethod?: PaymentMethod) => {
  const mainCode = process.env.REACT_APP_KCP_SITE_CODE_MAIN ?? 'T0000';
  const npayCode = process.env.REACT_APP_KCP_SITE_CODE_NPAY ?? 'A8P9W';
  const kpayCode = process.env.REACT_APP_KCP_SITE_CODE_KPAY ?? 'A8P9W';

  if (!paymentMethod) {
    return mainCode;
  }

  switch (paymentMethod) {
    case PaymentMethod.CARD:
    case PaymentMethod.BANK: {
      return mainCode;
    }
    case PaymentMethod.NPAY: {
      return npayCode;
    }
    case PaymentMethod.KPAY: {
      return kpayCode;
    }
  }
};

const paymentHook = () => {
  // 결제 정보
  const [paymentStartStatus, setPaymentStartStatus] =
    useState<PaymentStartDto>();

  // 복귀 시 처리할 수 있게 기억
  const rememberPaymentStatus = (data: PaymentStartDto) => {
    localStorage.setItem('last_order_data', JSON.stringify(data));
  };

  // 모바일 결제 대응
  const restorePaymentStatus = (): PaymentStartDto => {
    return JSON.parse(localStorage.getItem('last_order_data') || '');
  };

  const rememberPgParams = (data: any) => {
    localStorage.setItem('last_pg_data', JSON.stringify(data));
  };

  const removePaymentStatus = () => {
    localStorage.removeItem('last_order_data');
  };

  const restorePgParams = () => {
    return JSON.parse(localStorage.getItem('last_pg_data') || '');
  };

  // 결제 시작
  const paymentStart = (data: PaymentParamDto) => {
    return postPaymentStart(data).then((result) => {
      setPaymentStartStatus(result.data);
      rememberPaymentStatus(result.data);
      return result.data;
    });
  };

  //보강권 결제 시작
  const reinforcePaymentStart = (data: PaymentReinforceParamDto) => {
    return postReinforcePaymentStart(data).then((result) => {
      setPaymentStartStatus(result.data);
      rememberPaymentStatus(result.data);
      return result.data;
    });
  };

  //체험 결제 시작
  const trialPaymentStart = (data: PaymentTrialParamDto) => {
    return postTrialPaymentStart(data).then((result) => {
      setPaymentStartStatus(result.data);
      rememberPaymentStatus(result.data);
      return result.data;
    });
  };

  //특강 결제 시작
  const specialPaymentStart = (data: PaymentSpecialParamDto) => {
    return postSpecialPaymentStart(data).then((result) => {
      setPaymentStartStatus(result.data);
      rememberPaymentStatus(result.data);
      return result.data;
    });
  };

  return {
    paymentStart,
    reinforcePaymentStart,
    trialPaymentStart,
    restorePaymentStatus,
    rememberPgParams,
    removePaymentStatus,
    paymentStartStatus,
    restorePgParams,
    specialPaymentStart,
  };
};

export const PaymentContainer = createContainer(paymentHook);
