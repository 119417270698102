import React, { FC, useState } from 'react';
import { ReactComponent as TickIcon } from '../assets/icons/icon-tick.svg';
import { apiUrl } from '../plugins/axios';
import { User } from '../types';
import { Avatar } from './Avatar';
import { H6 } from './H6';
import { XIcon } from './icons';
import ReactPlayer from 'react-player';

interface TeacherSelectCardProps {
  teacher: User;
  selected: boolean;
  onClick: () => void;
}

export const TeacherSelectCard: FC<TeacherSelectCardProps> = ({
  teacher,
  selected,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className="flex items-center justify-between cursor-pointer teacher-ma"
        onClick={onClick}
      >
        <TeacherProfilePopup
          onClose={() => setIsOpen(false)}
          open={isOpen}
          teacher={teacher}
        />
        <div className="flex space-x-4">
          <Avatar src={apiUrl(teacher.avatar)} className="wh-16" />
          <div>
            <H6>{teacher.englishName} 선생님</H6>
            <button
              className="bg-gray-50 rounded-md px-3 py-2"
              onClick={() => setIsOpen(true)}
            >
              자세히 보기
            </button>
          </div>
        </div>

        <div
          className={`wh-6 rounded-full grid place-content-center ${
            selected ? 'bg-primary-1' : ' bg-gray-100'
          }`}
        >
          <TickIcon />
        </div>
      </div>
    </>
  );
};

interface TeacherProfilePopupProps {
  teacher: User;
  open: boolean;
  onClose: () => void;
}

const TeacherProfilePopup: FC<TeacherProfilePopupProps> = ({
  teacher,
  open,
  onClose,
}) => {
  if (!open) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="w-full max-w-4xl rounded-2xl shadow-lg flex flex-col bg-white">
        <div className="w-full flex justify-between items-center rounded-t-2xl bg-yellow-300 px-6 py-3">
          <div className="w-full text-center">
            <h1 className="font-normal text-15">선생님 프로필</h1>
          </div>
          <button className="mt-1" onClick={onClose}>
            <XIcon />
          </button>
        </div>

        <div className="md:grid grid-cols-3 gap-5 p-5 w-full text-center my-2 md:my-5 items-center">
          <div className="col-span-1 md:space-x-4 space-y-3 mb-5 md:mb-0">
            <Avatar
              src={apiUrl(teacher.avatar)}
              className="wh-16 text-center"
            />

            <H6>{teacher.englishName} 선생님</H6>
            <p className="font-semibold">{teacher.category}</p>
            <p>선생님 소개 : {teacher.introduction}</p>
          </div>
          <div className="md:col-span-2 aspect-w-16 aspect-h-9">
            <ReactPlayer
              url={teacher.videoUrl}
              className="react-player"
              width="100%"
              height="100%"
              controls
            />
          </div>
        </div>
      </div>
    </div>
  );
};
