import React, { useState } from 'react';
import { Avatar } from '../components/Avatar';
import { H5 } from '../components/H5';
import { Section } from '../components/Section';
import { ReactComponent as TickIcon } from '../assets/icons/icon-tick.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ClassType, LessonResult, Live, LiveClass } from '../types';
import { fetcher } from '../plugins/react-query';
import { apiUrl } from '../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
// @ts-ignore
import ReactStars from 'react-rating-stars-component';
import { studentState } from '../plugins/ridge';
import { StudentReportPopUp } from '../components/StudentReportPopUp';

export const LiveReportPage = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const student = studentState.useValue();

  if (!student?.isTested) {
    push('/learn/classroom');
  }

  const { data: live, refetch } = useQuery<Live>(`/lives/${id}`, fetcher, {
    enabled: !!id,
  });

  const courseItem = live?.course.courseItems.find(
    (courseItem) => courseItem.id === live.courseItemId
  );

  const { data: liveClass } = useQuery<LiveClass>(
    `/live-classes/${courseItem?.liveClassId}`,
    fetcher,
    {
      enabled: !!courseItem,
    }
  );

  const { data: lessonResults } = useQuery<LessonResult[]>(
    `/lesson-results?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student?.id }
  );

  if (!live) {
    return <></>;
  }
  return (
    <div
      style={{ backgroundColor: '#F9FAFB' }}
      className="min-h-screen flex flex-col"
    >
      <div className="max-w-5xl mx-auto flex flex-col md:flex-row pt-8 space-y-6 md:space-y-0 md:space-x-6 w-full px-4">
        {/* Left Section */}
        <Section className="bg-white md:w-1/5 p-5 shadow-sm rounded-md h-full">
          <div className="flex justify-between items-center">
            <h1 className="text-17 text-primary-1 font-bold">{`${live?.attendRound}회차`}</h1>
          </div>
          <h1 className="text-17 font-bold">{liveClass?.book?.title}</h1>

          <div className="border border-gray-50 -mx-5 my-5" />

          <Section>
            <p className="pb-1 text-13 text-gray-500">선생님</p>
            <div className="space-x-2">
              <Avatar className="wh-10" src={apiUrl(live?.teacher.avatar)} />
              <span>{`${live?.teacher.englishName}선생님`}</span>
            </div>

            <div className="pt-8">
              <p className="pb-1 text-13 text-gray-500">수업 일시</p>
              {/* TODO: Time and Date 기능 */}
              <p>
                {utcToLocalFormat(live?.start || '', MomentFormat.YYYYMMDDHmm)}
              </p>
            </div>
          </Section>
        </Section>

        {/* Right Section */}

        <div className="flex-1">
          <div className="bg-secondary-1 py-4 text-primary-1 text-center w-full rounded-t-md">
            <H5>{`${live?.student.name} 학생의 ${live?.attendRound}회차 Live Report`}</H5>
          </div>

          <Section className="bg-white shadow-sm flex-1 grid items-center rounded-md px-4 md:p-6 space-y-12">
            {/* 1 */}
            <div className="rounded-md shadow-md py-4 px-8 flex flex-col md:flex-row md:space-x-7 space-y-4 md:space-y-0 items-center">
              <div className="relative rounded-md overflow-hidden ">
                <img
                  src={apiUrl(liveClass?.book?.cover)}
                  alt=""
                  className=" w-28 object-cover"
                />
              </div>

              <div className="space-y-2">
                <p className="font-bold pb-3 text-center md:text-left">
                  {liveClass?.book?.title}
                </p>
                <div className="flex space-x-3 text-13 text-gray-500">
                  <span className="font-bold">학습 목표</span>
                  <p className="flex-1">{liveClass?.goal}</p>
                </div>
                <div className="flex space-x-3 text-13 text-gray-500">
                  <span className="font-bold">세부 목표</span>
                  <p className="flex-1">{liveClass?.detailedGoal}</p>
                </div>
                <div className="flex space-x-3 text-13 text-gray-500">
                  <span className="font-bold">주요 단어</span>
                  <p className="flex-1">{liveClass?.words}</p>
                </div>
                <div className="flex space-x-3 text-13 text-gray-500">
                  <span className="font-bold">주요 패턴</span>
                  <p className="flex-1">{liveClass?.patterns}</p>
                </div>
                <div className="flex space-x-3 text-13 text-gray-500">
                  <span className="font-bold">내용</span>
                  <p className="flex-1">{liveClass?.content}</p>
                </div>
              </div>
            </div>

            {/* 2 */}
            {liveClass?.level?.classType !== ClassType.SPECIAL ? (
              <Section className="flex flex-col items-center w-full">
                <span className="text-17 font-nanum">
                  이번회차 데일리 스터디 진행상황은!
                </span>

                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 rounded-md shadow-md px-4 py-6 w-full md:place-items-center">
                  {liveClass?.book.lessons.map((lesson) => {
                    const findLessonResult = lessonResults?.find(
                      (lessonResult) => {
                        return lessonResult.lessonId === lesson.id;
                      }
                    );

                    return (
                      <button className="flex space-x-2">
                        <div
                          className={`${
                            findLessonResult?.isCompleted ||
                            findLessonResult?.testDone
                              ? 'bg-primary-3'
                              : 'border-2 border-gray-100'
                          } rounded-full wh-5 grid place-content-center`}
                        >
                          <TickIcon />
                        </div>

                        <span>{lesson.title}</span>
                      </button>
                    );
                  })}
                </div>
              </Section>
            ) : (
              ''
            )}
            {/* 3 */}
            <Section className="flex flex-col items-center space-y-4 w-full">
              <span className="text-17 font-nanum">
                {`이번회차 화상수업에서 ${live?.student.name}학생은!`}
              </span>

              <div className="flex space-x-2">
                {live?.liveFeedbacks[0]?.totalRate && (
                  <ReactStars
                    count={5}
                    size={26}
                    edit={false}
                    value={live?.liveFeedbacks[0]?.totalRate + 1}
                  />
                )}
              </div>

              {live?.liveFeedbacks[0] && (
                <div className="rounded-md shadow-md w-full grid grid-cols-2 md:grid-cols-4 gap-8 py-6">
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-13 text-gray-500">수업 참여도</span>
                    <div className="flex space-x-2">
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={live?.liveFeedbacks[0]?.participationRate + 1}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-13 text-gray-500">어휘 / 문장력</span>
                    <div className="flex space-x-2">
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={live?.liveFeedbacks[0]?.vocabularyRate + 1}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-13 text-gray-500">이해력</span>
                    <div className="flex space-x-2">
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={live?.liveFeedbacks[0]?.understandingRate + 1}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-13 text-gray-500">발음</span>
                    <div className="flex space-x-2">
                      <ReactStars
                        count={5}
                        size={18}
                        edit={false}
                        value={live?.liveFeedbacks[0]?.pronunciationRate + 1}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Section>

            {/* 4 */}
            <Section className="flex flex-col items-center space-y-4 w-full">
              <span className="text-17 font-nanum">
                {`${live?.teacher.englishName}선생님 피드백`}
              </span>

              <p className="rounded-md shadow-md p-5 text-gray-500 text-13 leading-relaxed break-all">
                {live?.liveFeedbacks[0]?.text}
              </p>
            </Section>
          </Section>

          <Section
            className={`${
              !live?.liveReviews[0] ? 'cursor-pointer' : ''
            } bg-white p-5 shadow-md rounded-md flex flex-col items-center space-y-4 mt-8 mb-8`}
            onClick={() => {
              if (live?.liveReviews[0]) {
                return;
              }
              setIsOpen(true);
            }}
          >
            <span className="text-17 font-nanum text-center">
              {`나에게 ${live?.teacher.englishName}`}
              <br />
              선생님과의 수업은 어땠나요?
            </span>

            <div className="flex space-x-2">
              <ReactStars
                count={5}
                size={18}
                edit={false}
                value={live?.liveReviews[0]?.rate + 1 ?? 0}
              />
            </div>
          </Section>
        </div>
      </div>
      {isOpen && (
        <StudentReportPopUp
          live={live}
          refetch={() => refetch()}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};
