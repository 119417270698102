import { api } from './axios';

export class WeeklyTest {
  static window: Window | null;
  static studentId: number | null;
  static lessonId: number | null;

  static openTest(url: string, studentId?: number, lessonId?: number) {
    WeeklyTest.studentId = studentId ?? null;
    WeeklyTest.lessonId = lessonId ?? null;
    WeeklyTest.window = window.open(url);
  }

  static handleMessage({ data }: MessageEvent) {
    const result = data?.param?.result;
    if (result && data.op === 'done') {
      api.post('/lesson-results', {
        studentId: WeeklyTest.studentId,
        lessonId: WeeklyTest.lessonId,
        testDone: true,
        testId: result.id,
        testQuestion: result.num,
        testCorrect: result.answerNum,
        testScore: result.score,
      });
    }
  }
}

window.addEventListener('message', WeeklyTest.handleMessage);
