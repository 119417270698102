import moment from 'moment';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Pay, PayItemType } from '../../types';

interface FormValues {
  manager: string;
  value: string;
  memo: string;
  time: string;
  type: PayItemType;
  teacherId: number;
  payId: number;
  auto: boolean;
}

interface AdminPayItemPopUpProps {
  teacherId: string;
  payId: string;
  type: PayItemType;
  onClose: () => void;
  refetch: () => void;
}

export const AdminPayItemPopUp: FC<AdminPayItemPopUpProps> = ({
  teacherId,
  payId,
  type,
  onClose,
  refetch,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { auto: false } });

  const { data: pay } = useQuery<Pay>(`/admin/pays/${payId}`, fetcher, {
    keepPreviousData: true,
    enabled: !!payId,
  });

  const add = handleSubmit((data) => {
    data.teacherId = Number(teacherId);
    data.payId = Number(payId);
    data.time = pay ? pay?.time : moment().toISOString();
    data.type = type;
    return api
      .post('/admin/pay-items', data)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((err) => console.error('장려금 및 패널티 생성 오류', err));
  });

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">
          {type === PayItemType.INCENTIVE
            ? '장려금 추가하기'
            : '패널티 추가하기'}
        </H3>

        <TextField
          label="담당자명"
          placeholder="담당자명을 입력해주세요."
          helper={errors.manager?.message}
          {...register('manager', {
            required: '담당자명을 입력해주세요.',
          })}
        />

        <TextField
          label={
            type === PayItemType.INCENTIVE ? '장려금 비용*' : '패널티 비용*'
          }
          placeholder={
            type === PayItemType.INCENTIVE
              ? '장려금 비용을 입력해주세요.'
              : '패널티 비용을 입력해주세요.*'
          }
          helper={errors.value?.message}
          {...register('value', {
            required:
              type === PayItemType.INCENTIVE
                ? '장려금 비용을 입력해주세요.'
                : '패널티 비용을 입력해주세요.',
          })}
        />

        <TextField
          label="내용"
          placeholder="내용을 입력해주세요."
          helper={errors.memo?.message}
          {...register('memo', {
            required: '내용을 입력해주세요.',
          })}
        />

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          disabled={!watch('manager') || !watch('value')}
          onClick={add}
        />
      </div>
    </div>
  );
};
