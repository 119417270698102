import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { api } from '../../plugins/axios';
import { range } from '../../utils';
pdfjs.GlobalWorkerOptions.workerSrc =
  'https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js';

interface TeacherContractCreateProps {
  id: number;
  _file: any;
  _fileData: string;
  goBack: () => void;
}

export const TeacherContractCreate: React.FC<TeacherContractCreateProps> = ({
  id,
  _file,
  _fileData,
  goBack,
}) => {
  const [file, setFile] = useState<any>();
  // const [files, setFiles] = useState<any>();
  const [fileData, setFileData] = useState<any>();
  const [disabled, setDisabled] = useState(false);
  const [pageNumber, setNumPages] = useState(0);

  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

  useEffect(() => {
    setFile(_file);
    // setFiles(_file);
    setFileData(_fileData);
  }, [_file, _fileData]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const pdfData = useMemo(() => ({ data: fileData }), [fileData]);

  return (
    <>
      <div className="w-full h-full relative">
        <div
          className="font-bold text-lg mb-5 cursor-pointer"
          onClick={() => goBack()}
        >
          &#60; 목록보기
        </div>
        <Document
          file={pdfData}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
        >
          {range(pageNumber).map((_, i) => (
            <Page pageNumber={i + 1} key={i} className="mb-2" />
          ))}
        </Document>

        {/* {fileData?.map((f: any) => {
              return (
                <Document
                  file={f}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                >
                  {range(pageNumber).map((_, i) => (
                    <Page pageNumber={i + 1} key={i} className="mb-2" />
                  ))}
                </Document>
              );
            })}  */}

        <div className="absolute bottom-4 left-0 right-0">
          <div className="w-min mx-auto flex space-x-4 items-center justify-between p-1">
            <label
              className="rounded-full bg-blue-100 text-blue-700 py-3 px-5 min-w-max cursor-pointer"
              htmlFor="file_upload"
            >
              파일 수정하기
            </label>
            {/* <form className="uploader" encType="multipart/form-data">
                  <input
                    type="file"
                    id="file_upload"
                    className="hidden"
                    multiple
                    accept="application/pdf"
                    onChange={(e) => {
                      if (e.target.files && e.target.files?.length !== 0) {
                        if (e.target.validity.valid) {
                          setFiles(e.target.files);
                          const fileList = Array.from(e.target.files);
                          fileList.map((f) => {
                            let reader = new FileReader();
                            reader.readAsArrayBuffer(f || new Blob());
                            reader.onload = function () {
                              setFileData(fileList);
                            };
                            reader.onerror = function (error) {
                              console.log('Error: ', error);
                            };
                            return f;
                          });
                        }
                      }
                    }}
                  />
                </form> */}
            <input
              type="file"
              id="file_upload"
              className="hidden"
              accept="application/pdf"
              onChange={(e) => {
                if (e.target.validity.valid && e.target.files?.item(0)) {
                  setFile(e.target.files.item(0));
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(
                    e.target.files?.item(0) || new Blob()
                  );
                  reader.onload = function () {
                    setFileData(reader.result);
                  };
                  reader.onerror = function (error) {
                    console.log('Error: ', error);
                  };
                }
              }}
            />
            <button
              className="rounded-full bg-blue-700 text-white py-3 px-5 min-w-max cursor-pointer"
              onClick={async () => {
                setDisabled(true);
                const formData = new FormData();

                formData.append('file', file);

                // for (let i = 0; i < files.length; i++) {
                //   formData.append('pdf', files[i]);
                // }

                await api.post<any>(
                  `/admin/contracts?teacherId=${id}`,
                  formData,
                  {
                    headers: { 'Content-Type': 'multipart/form-data' },
                  }
                );
                await goBack();
              }}
              disabled={disabled}
            >
              ✍🏻 서명 요청하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
