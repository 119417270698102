import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, PayItem, PayItemType } from '../../types';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import { AdminH1 } from '../components/AdminH1';
import { Button } from '../../components/Button';
import { AdminCard } from '../components/AdminCard';
import { Table } from '../../components/Table';
import { Pagination } from '../../components/Pagination';

export const TeacherTransactionPayItemDetails = () => {
  const [getMoment] = useState(moment());
  const today = getMoment;
  const { id, payId } = useParams<{ id: string; payId: string }>();
  const { push } = useHistory();
  const queryString = useQueryString({
    filter: { teacherId: id, payId },
    join: ['live', 'teacher', 'live.student', 'live.course'],
  });

  const { data } = useQuery<Paginated<PayItem>>(
    `/admin/pay-items${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: payItems } = useQuery<PayItem[]>(
    `/admin/pay-items/export${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  const incentives = payItems?.filter(
    (pi) => pi.type === PayItemType.INCENTIVE
  );
  const penalties = payItems?.filter((pi) => pi.type === PayItemType.PENALTY);
  const liveSettlementData = payItems?.filter(
    (payItem) => payItem.type === PayItemType.LIVE_SETTLEMENT
  );

  const liveSettlementTotal =
    liveSettlementData &&
    liveSettlementData?.length > 0 &&
    liveSettlementData
      ?.map((payItem) => payItem.value)
      ?.reduce((a, b) => a + b);
  const incentiveTotal =
    incentives &&
    incentives?.length > 0 &&
    incentives?.map((payItem) => payItem.value).reduce((a, b) => a + b);
  const penaltyTotal =
    penalties &&
    penalties?.length > 0 &&
    penalties?.map((payItem) => payItem.value).reduce((a, b) => a + b);

  const total =
    (liveSettlementTotal ? liveSettlementTotal : 0) +
    (incentiveTotal ? incentiveTotal : 0) -
    (penaltyTotal ? penaltyTotal : 0);

  const exporter = () => {
    const payData = payItems?.map((payItem: any) => {
      return {
        강사명: payItem.teacher.name,
        영어이름: payItem.teacher.englishName,
        계좌정보: payItem.teacher.bankAccountNumber,
        주민등록번호: payItem.teacher.residentRegistrationNumber,
        주소: payItem.teacher.address,
        등급: payItem.teacher.grade,
        수업한학생: payItem.live?.student?.name,
        수업일자: payItem.live?.start
          ? utcToLocalFormat(payItem.live?.start, MomentFormat.YYYYMMDDHmm)
          : '-',
        정산타입:
          payItem.type === PayItemType.LIVE_SETTLEMENT
            ? '수업정산'
            : payItem.type === PayItemType.PENALTY
            ? '패널티'
            : '장려금',
        세부내용: payItem.memo ? payItem.memo : '-',
        총정산비용:
          payItem.type === PayItemType.PENALTY
            ? `-${payItem.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
            : payItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      };
    });

    jsonExport(payData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(
        `${BOM} ${csv}`,
        `${data?.items[0].teacher.name} 월 정산 관리(${today.format(
          'YYYY 년 MM 월'
        )})`
      );
    });
  };

  if (!data) return <></>;
  return (
    <>
      <div className="space-y-28">
        <div className="space-y-4">
          <div className="flex justify-between">
            <AdminH1>정산 내역</AdminH1>
            <div className="flex space-x-3">
              <div className="h-12 bg-gray-100 text-black flex items-center justify-center px-5 rounded-lg">
                총 정산 비용{' '}
                {`${total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}원
              </div>
              <Button
                className="bg-white text-blue-500 ml-auto text-14"
                onClick={exporter}
              >
                파일 내보내기
              </Button>
            </div>
          </div>
          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>수업일자</Table.Th>
                  <Table.Th>강사명</Table.Th>
                  <Table.Th>영어이름</Table.Th>
                  <Table.Th>계좌정보</Table.Th>
                  <Table.Th>수업한 학생</Table.Th>
                  <Table.Th>정산타입</Table.Th>
                  <Table.Th>세부내용</Table.Th>
                  <Table.Th>총 정산 비용</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {data?.items?.map((payItem) => (
                  <Table.Row key={payItem.id}>
                    <Table.Td>
                      {payItem.live?.start
                        ? utcToLocalFormat(
                            payItem.live?.start,
                            MomentFormat.YYYYMMDDHmm
                          )
                        : '-'}
                    </Table.Td>
                    <Table.Td>{payItem.teacher.name}</Table.Td>
                    <Table.Td>{payItem.teacher.englishName}</Table.Td>
                    <Table.Td>{payItem.teacher.bankAccountNumber}</Table.Td>
                    <Table.Td>
                      {payItem.live?.student?.name
                        ? payItem.live?.student?.name
                        : '-'}
                    </Table.Td>
                    <Table.Td>
                      {payItem.type === PayItemType.LIVE_SETTLEMENT
                        ? '수업정산'
                        : payItem.type === PayItemType.PENALTY
                        ? '패널티'
                        : '장려금'}
                    </Table.Td>
                    <Table.Td>{payItem.memo ? payItem.memo : '-'}</Table.Td>
                    <Table.Td>
                      {payItem.type === PayItemType.PENALTY
                        ? `-${payItem.value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                        : payItem.value
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </Table.Td>
                    {payItem.type === PayItemType.LIVE_SETTLEMENT &&
                      payItem.live.courseItemId && (
                        <Table.Td
                          className="text-right text-blue-500"
                          onClick={() =>
                            push(
                              `/admin/users/${payItem.live.course.userId}/students/${payItem.live.studentId}/courses/${payItem.live.course.id}/courseItems/${payItem.live.courseItemId}?liveId=${payItem.liveId}`
                            )
                          }
                        >
                          레슨보기
                        </Table.Td>
                      )}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Pagination>
              <Pagination.Label count={data.items.length} total={data.total} />
              <Pagination.Nav
                basePath={`/admin/teachers/${id}/transactions/${payId}/payItems`}
                total={data.total}
              />
            </Pagination>
          </AdminCard>
        </div>
      </div>
    </>
  );
};
