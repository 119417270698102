import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Category } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  title: string;
  isHide: boolean;
}

export const CategoryDetails = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);
  const { data: category, refetch } = useQuery<Category>(
    `/admin/categories/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!category) return;
    clearErrors();
    setValue('title', category.title);
    setValue('isHide', category.isHide);
  }, [clearErrors, setValue, category, editMode]);

  return (
    <>
      <AdminH1>카테고리</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="타이틀"
            disabled={!addMode && !editMode}
            helper={errors.title?.message}
            {...register('title', { required: '타이틀을 입력해주세요.' })}
          />

          <Checkbox
            label="숨김처리"
            disabled={!addMode && !editMode}
            {...register('isHide')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <>
              <Button
                text="취소"
                className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                onClick={() => setEditMode(false)}
              />
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                disabled={!watch('title')}
                onClick={handleSubmit(async (data) => {
                  await api.patch(`/admin/categories/${id}`, data);
                  await refetch();
                  setEditMode(false);
                })}
              />
            </>
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('title')}
              onClick={handleSubmit(async (data) => {
                await api
                  .post<Category>('/admin/categories', data)
                  .then((res) => push(`/admin/categories/${res.data.id}`))
                  .catch((err) => console.log(err));
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
