import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Payment, PaymentState, classTypeToText } from '../../types';
import { AdminCard } from '../components/AdminCard';

export const PaymentNotInclude = () => {
  const queryString = useQueryString({
    filter: {
      bookIncluded: false,
      penIncluded: false,
    },
    join: ['user', 'student', 'paymentProducts', 'paymentProducts.product'],
  });

  const { data } = useQuery<Paginated<Payment>>(
    `/admin/payments/notInclude/all${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <>
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>주문일자</Table.Th>
              <Table.Th>주문자</Table.Th>
              <Table.Th>상품유형</Table.Th>
              <Table.Th>주문상품</Table.Th>
              <Table.Th>결제금액</Table.Th>
              <Table.Th>할인금액</Table.Th>
              <Table.Th>주문상태</Table.Th>
              <Table.Th>관리자메모</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items.map((payment) => (
              <Table.Row key={payment.id + payment.userId}>
                <Table.Td>
                  {moment(payment.createdAt).format('YYYY.MM.DD')}
                </Table.Td>
                <Table.Td>{payment.student.name}</Table.Td>
                <Table.Td>
                  {payment?.paymentProducts?.length > 0
                    ? classTypeToText(
                        payment?.paymentProducts[0]?.product?.classType
                      )
                    : ''}
                </Table.Td>
                <Table.Td>
                  {payment.isTrialPackage
                    ? `${payment.productAmount}회 체험팩`
                    : payment.productAmount
                    ? `${payment.productAmount}회 수업`
                    : payment?.paidAmount
                    ? '보강'
                    : '체험'}
                </Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.paidAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.discountedAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>
                  {payment.paymentState === PaymentState.PAID
                    ? '결제완료'
                    : payment.paymentState === PaymentState.CANCELED
                    ? '취소/교환/환불'
                    : ''}
                </Table.Td>
                <Table.Td>{payment.memo ? payment.memo : '-'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/payments/${payment.id}/edit`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data?.items.length || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav
            basePath="/admin/payments/false"
            total={data?.total || 0}
          />
        </Pagination>
      </AdminCard>
    </>
  );
};
