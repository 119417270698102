import React from 'react';
import { ReactComponent as SuccessSVG } from '../assets/svg/success.svg';
import { Button } from '../components/Button';
import { H4 } from '../components/H4';

export const SignupSuccessPage = () => {
  return (
    <div className="flex flex-col items-center pt-14 px-4 min-h-screen-10 justify-center">
      <SuccessSVG />

      <H4>회원가입을 축하드려요!</H4>
      <p className="text-gray-600 text-center pt-4 pb-8">
        이제 학습할 자녀를 추가해주신 후,
        <br />
        레벨테스트를 진행해주세요!
      </p>

      <Button
        to="/students"
        text="자녀 추가하기"
        className="filled-primary-1 w-full md:w-1/3"
      />
    </div>
  );
};
