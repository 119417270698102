import React from 'react';
import { downloadPopupState } from '../plugins/ridge';
import { Button } from './Button';
import { XIcon } from './icons';
//@ts-ignore
import FileSaver from 'file-saver';

export interface DownloadPopupProps {
  title: string;
  body: string;
  secondBody: string;
  primaryText: string;
  primaryLink: any;
  secondaryText: string;
  secondaryLink: any;
  teacherName: any;
  thirdText?: string;
  thirdLink?: string;
}

export const DownloadPopup = () => {
  const popup = downloadPopupState.useValue();

  if (!popup.title) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="card">
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-24 whitespace-pre">{popup.title}</h1>
          <button className="mt-1" onClick={downloadPopupState.reset}>
            <XIcon />
          </button>
        </div>

        {popup.body && (
          <div className="mt-4 text-17 text-gray-700 whitespace-pre-line">
            {popup.body}
          </div>
        )}

        {popup.secondBody && (
          <div className="mt-6 text-14 text-gray-400 whitespace-pre-line">
            {popup.secondBody}
          </div>
        )}

        <div className="mt-14 flex flex-col space-y-3">
          {popup.primaryText && (
            <Button
              text={popup.primaryText}
              className="filled-primary-1"
              onClick={() =>
                popup.primaryLink
                  ? FileSaver.saveAs(
                      process.env.REACT_APP_API_URL + popup.primaryLink,
                      `${popup.teacherName}_통장사본`
                    )
                  : alert('통장사본이 존재하지 않습니다.')
              }
            />
          )}
          {popup.secondaryText && (
            <Button
              text={popup.secondaryText}
              className="bg-secondary-1 text-primary-1 w-full"
              onClick={() =>
                popup.secondaryLink
                  ? FileSaver.saveAs(
                      process.env.REACT_APP_API_URL + popup.secondaryLink,
                      `${popup.teacherName}_신분증사본`
                    )
                  : alert('신분증사본이 존재하지 않습니다.')
              }
            />
          )}

          {popup.thirdText && (
            <Button
              text={popup.thirdText}
              className="bg-secondary-2 text-primary-2 w-full"
              onClick={() => {
                popup.thirdLink
                  ? FileSaver.saveAs(
                      process.env.REACT_APP_API_URL + popup.thirdLink,
                      `${popup.teacherName}_이력서`
                    )
                  : alert('이력서가 존재하지 않습니다.');
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
