import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { H4 } from '../../components/H4';
import { MobileBackButton } from '../../components/MobileBackButton';
import { api } from '../../plugins/axios';

export const PasswordFindSentPage = () => {
  const { state } = useLocation<any>();

  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4">
      <MobileBackButton />
      <H4 className="pb-8">
        비밀번호 재설정
        <br />
        링크를 보냈습니다.
      </H4>

      <p className="pb-14">
        고객님의 이메일 계정으로 비밀번호 재설정링크를 전달하였습니다. 5분 이내
        이메일이 오지 않았다면, 스팸메일함을 확인해주시거나 아래 버튼을 눌러
        다시 보내주세요.
      </p>

      <div className="flex flex-col space-y-4">
        <Button
          className="bg-secondary-1 text-primary-1 font-bold"
          onClick={async () => {
            await api
              .post('users/find-password', {
                email: state?.email,
                phoneNumber: state?.phoneNumber,
              })
              .then(() => alert('재발송 되었습니다.'))
              .catch((err) => console.error(err));
          }}
          text="다시 보내기"
        />
        <Button
          to="/"
          className="bg-primary-1 text-secondary-1"
          text="홈으로 가기"
        />
      </div>
    </div>
  );
};
