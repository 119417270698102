import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { TextField } from '../../components/TextField';
import { useQueryString } from '../../hooks';
import { api, apiUrl } from '../../plugins/axios';
import { resizeImage } from '../../plugins/compress';
import { fetcher } from '../../plugins/react-query';
import { Book, BookItem, Lesson, Paginated, Series } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminLessonCard } from '../components/AdminLessonCard';
import { AdminLibraryCard } from '../components/AdminLibraryCard';

interface FormValues {
  title: string;
  cover: string;
  index: number;
  story: string;
  song: string;
  talk: string;
  seriesId: number;
  bookItems: BookItem[];
}

export const BookDetails = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';
  const [editMode, setEditMode] = useState(false);
  const lessonQuery = useQueryString({
    filter: { bookId: id },
    sort: { index: 'ASC' },
  });

  const { data: serieses } = useQuery<Series[]>('/admin/series/all', fetcher);
  const { data: book, refetch } = useQuery<Book>(
    `/admin/books/${id}`,
    fetcher,
    { enabled: !addMode }
  );
  const { data: lessons, refetch: refetchLessons } = useQuery<
    Paginated<Lesson>
  >(`/admin/lessons${lessonQuery}`, fetcher);
  const { data: bookItems, refetch: refetchBookItems } = useQuery<BookItem[]>(
    `/admin/bookItems/${id}`,
    fetcher
  );

  const {
    watch,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!addMode) return;
    if (!serieses || serieses.length === 0) return;
    setValue('seriesId', serieses[0].id);
  }, [setValue, serieses, addMode]);

  useEffect(() => {
    if (!book) return;
    clearErrors();
    setValue('title', book.title);
    setValue('index', book.index);
    setValue('story', book.story);
    setValue('song', book.song);
    setValue('talk', book.talk);
    setValue('seriesId', book.seriesId);
    setValue('bookItems', book.bookItems);
  }, [clearErrors, setValue, book, editMode]);

  return (
    <div className="space-y-16">
      <div className="space-y-6">
        <AdminH1>상세정보</AdminH1>
        <AdminCard>
          <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
            <div className="col-span-2 grid grid-cols-4">
              <label htmlFor="cover" className="cursor-pointer">
                {book?.cover ? (
                  <div className="aspect-w-3 aspect-h-4 rounded-lg overflow-hidden">
                    <img
                      src={apiUrl(book?.cover)}
                      alt={book.title}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                ) : (
                  <div className="wh-40 grid place-items-center border-2 border-dashed rounded">
                    Upload cover image
                  </div>
                )}
                <input
                  id="cover"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  onChange={async (e) => {
                    const file = e.target.files?.item(0);
                    if (!file || !e.target.validity.valid) return;
                    const formData = new FormData();
                    formData.append('cover', await resizeImage(file));
                    await api.post(`/admin/books/${id}/cover`, formData);
                    refetch();
                  }}
                />
              </label>
            </div>
            <Select
              label="시리즈"
              value={watch('seriesId')}
              disabled={!addMode && !editMode}
              onChange={(e) => setValue('seriesId', Number(e.target.value))}
            >
              {serieses?.map((series) => (
                <option key={series.id} label={series.name} value={series.id}>
                  {series.name}
                </option>
              ))}
            </Select>
            <TextField
              label="타이틀"
              disabled={!addMode && !editMode}
              helper={errors.title?.message}
              {...register('title', { required: 'Please enter title.' })}
            />
            <TextField
              type="number"
              label="인덱스"
              disabled={!addMode && !editMode}
              helper={errors.index?.message}
              {...register('index', { required: 'Please enter index.' })}
            />
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            {editMode && (
              <Button
                text="취소"
                className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
                onClick={() => setEditMode(false)}
              />
            )}
            {editMode && (
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                onClick={handleSubmit(async (data) => {
                  await api.patch(`/admin/books/${id}`, data);
                  await refetch();
                  setEditMode(false);
                })}
              />
            )}
            {addMode && (
              <Button
                text="저장"
                className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                onClick={handleSubmit(async (data) => {
                  const res = await api.post<Book>('/admin/books', data);
                  push(`/admin/books/${res.data.id}`);
                })}
              />
            )}
            {!process.env.REACT_APP_DISABLED && (
              <>
                {!addMode && !editMode && (
                  <Button
                    text="수정"
                    className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
                    onClick={() => setEditMode(true)}
                  />
                )}
              </>
            )}
          </div>
        </AdminCard>
      </div>

      <div className="space-y-6">
        <div className="flex justify-between">
          <AdminH1>도서관</AdminH1>

          {!process.env.REACT_APP_DISABLED && (
            <Button
              text="+ 도서관 추가하기"
              className="h-10 text-md outlined-primary-1 hover:bg-primary-1 hover:text-white"
              onClick={() =>
                api
                  .post('/admin/bookItems', {
                    index: (bookItems?.length ?? 0) + 1,
                    name: '',
                    url: '',
                    bookId: id,
                  })
                  .then(() => refetchBookItems())
              }
            />
          )}
        </div>
        {bookItems?.length === 0
          ? '아직 도서관 컨텐츠가 없습니다.'
          : bookItems?.map((bookItem) => (
              <AdminLibraryCard
                key={bookItem.id}
                bookItem={bookItem}
                refetchBookItems={() => refetchBookItems()}
              />
            ))}
      </div>

      <div className="space-y-6">
        <div className="flex justify-between">
          <AdminH1>레슨</AdminH1>

          {!process.env.REACT_APP_DISABLED && (
            <Button
              text="+ 레슨 추가하기"
              className="h-10 text-md outlined-primary-1 hover:bg-primary-1 hover:text-white"
              onClick={() =>
                api
                  .post('/admin/lessons', {
                    index: (lessons?.items.length ?? 0) + 1,
                    bookId: id,
                  })
                  .then(() => refetchLessons())
              }
            />
          )}
        </div>
        {lessons?.items.length === 0
          ? '아직 레슨이 없습니다.'
          : lessons?.items.map((lesson) => (
              <AdminLessonCard
                key={lesson.id}
                lesson={lesson}
                refetchLessons={() => refetchLessons()}
              />
            ))}
      </div>
    </div>
  );
};
