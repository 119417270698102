import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface AdminTabProps {
  text?: string;
  to?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const AdminTab: FC<AdminTabProps> = ({
  children,
  text,
  to,
  selected,
  onClick,
}) => {
  return to ? (
    <Link
      className={`h-9 ${selected ? 'text-blue-500' : 'text-gray-600'}`}
      to={to}
    >
      {text ?? children}
    </Link>
  ) : (
    <button
      className={`h-9 ${selected ? 'text-blue-500' : 'text-gray-600'}`}
      onClick={onClick}
    >
      {text ?? children}
    </button>
  );
};
