import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import {
  CourseItem,
  Entry,
  Live,
  LiveState,
  Paginated,
  TestResult,
} from '../../types';
import { AdminCard } from '../components/AdminCard';

export const UserStudentCourseItems = () => {
  const { userId, studentId, courseId } = useParams<{
    userId: string;
    studentId: string;
    courseId: string;
  }>();

  const queryString = useQueryString({
    filter: { courseId },
    join: [
      'liveClass',
      'liveClass.book',
      'liveClass.book.lessons',
      'liveClass.book.points',
    ],
    sort: { id: 'ASC' },
  });

  const studentIdQueryString = useQueryString({
    filter: { studentId: studentId },
    join: ['series'],
    sort: { createdAt: 'DESC' },
  });

  const { data: courseItems } = useQuery<Paginated<CourseItem>>(
    `/admin/course-items${queryString}`,
    fetcher,
    { enabled: !!courseId, keepPreviousData: true }
  );

  const { data: lives } = useQuery<Paginated<Live>>(
    `/admin/lives?filter[studentId][0]=${studentId}&filter[courseId][1]=${courseId}&filter[state][0]=${LiveState.ATTENDED}&join[0]=student&join[1]=teacher`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: testResults } = useQuery<Paginated<TestResult>>(
    `/admin/test-results/${studentIdQueryString}`,
    fetcher,
    { enabled: !!studentId, keepPreviousData: true }
  );

  const { data: entries } = useQuery<Entry>(
    `/admin/entries/admin-entry/${testResults?.items[0]?.id}`,
    fetcher,
    { enabled: !!testResults?.items[0]?.id }
  );

  const entryLevel =
    entries?.levelCode === '1-A' ||
    entries?.levelCode === '1-B' ||
    entries?.levelCode === '1-C' ||
    entries?.levelCode === '1-D'
      ? 'Level 1'
      : entries?.levelCode === '2-A' ||
        entries?.levelCode === '2-B' ||
        entries?.levelCode === '2-C'
      ? 'Level 2'
      : entries?.levelCode === '3-A' ||
        entries?.levelCode === '3-B' ||
        entries?.levelCode === '3-C'
      ? 'Level 3'
      : '';

  const entrySeries =
    entries?.levelCode === '1-A' || entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C' || entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A' || entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A' || entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  return (
    <AdminCard>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>회차</Table.Th>
            <Table.Th>단계</Table.Th>
            <Table.Th>교재</Table.Th>
            <Table.Th>진도</Table.Th>
            <Table.Th>수업 여부</Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {courseItems?.items.length === 0
            ? '학습정보가 없습니다.'
            : courseItems?.items.map((courseItem, i) => {
                const live = lives?.items?.find(
                  (live) =>
                    live.courseItemId === courseItem.id &&
                    live.state === LiveState.ATTENDED
                );

                return (
                  <Table.Row>
                    <Table.Td>{courseItem.number}</Table.Td>
                    <Table.Td>
                      {courseItem?.liveClass?.level?.name
                        ? courseItem.liveClass.level.name
                        : entryLevel}
                    </Table.Td>
                    <Table.Td>
                      {courseItem?.liveClass?.series?.name
                        ? courseItem.liveClass.series.name
                        : entrySeries}
                    </Table.Td>
                    <Table.Td>
                      {courseItem?.liveClass?.book?.title
                        ? courseItem.liveClass.book.title
                        : entrySeries}
                    </Table.Td>
                    <Table.Td>
                      {live?.state === LiveState.ATTENDED ? '출석' : '-'}
                    </Table.Td>
                    <Table.Td className="text-right">
                      <Link
                        className="font-normal text-indigo-600 hover:text-indigo-900"
                        to={`/admin/users/${userId}/students/${studentId}/courses/${courseId}/courseItems/${courseItem.id}/detail`}
                      >
                        상세보기
                      </Link>
                    </Table.Td>
                  </Table.Row>
                );
              })}
        </Table.Body>
      </Table>
      <Pagination>
        <Pagination.Label
          count={courseItems?.items.length || 0}
          total={courseItems?.total || 0}
        />
        <Pagination.Nav
          basePath={`/admin/users/${userId}/students/${studentId}/courses/${courseId}/courseItems`}
          total={courseItems?.total || 0}
        />
      </Pagination>
    </AdminCard>
  );
};
