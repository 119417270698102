import React from 'react';
import liveTeacherTopImg from '../../assets/img/liveteacher-top-img.png';
import mobileliveTeacherTopImg from '../../assets/img/liveteacher-top-img-m.png';
import liveTeacherTopTeacher from '../../assets/img/liveteacher-top-img-teacher.png';
import { TeacherReviewSection } from './TeacherReviewSection';
import mainDescriptionImg3 from '../../assets/img/main-description-img3.png';
import mainDescriptionImg4 from '../../assets/img/main-description-img3-3.png';
import mainDescriptionImg5 from '../../assets/img/main-description-img3-4.png';
import mainDescriptionImg6 from '../../assets/img/main-description-img3-5.png';
import { ProcessSection } from './ProcessSection';
import { LiveTeacherFirstSection } from './LiveTeacherFirstSection';
import { LiveTeacherSecondSection } from './LiveTeacherSecondSection';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { ReactComponent as Arrow } from '../../assets/svg/swiper_arrow_bottom.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { usePopup } from '../../components/PopUp';
import { isMobile } from 'react-device-detect';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const imgData = [
  { id: 1, imgPath: mainDescriptionImg3 },
  { id: 2, imgPath: mainDescriptionImg4 },
  { id: 3, imgPath: mainDescriptionImg5 },
  { id: 4, imgPath: mainDescriptionImg6 },
];

export const LiveTeacherPage = () => {
  const { requireLoginPopup } = usePopup();

  const handleLevelTestClick = () => {
    return requireLoginPopup();
  };

  return (
    <>
      <div className="font-nanum overflow-hidden">
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <div
            style={{ 
              backgroundImage: `url('${isMobile ? mobileliveTeacherTopImg : liveTeacherTopImg}')`
            }}
            className="w-full bg-right-1 bg-cover bg-no-repeat relative overflow-hidden h-650 md:h-529 t-sub-main"
          >
            <div className="text-left max-w-container leading-tight">
              <div className="lg:p-0 sm:pl-10 ">
                <h1 className="text-white key-txt relative zindex-10 text-30 md:text-48">
                  아이들 눈높이에
                  <br className="md:hidden block" /> 딱 맞는
                  <br />
                  <span className="text-white font-light md:text-36 text-20">
                    초등영어 전문 선생님
                  </span>
                </h1>
                <button
                  onClick={handleLevelTestClick}
                  className="absolute z-40  rounded-full py-2.5 px-6 mt-5 lt-main-btn"
                >
                  라이브쌤 만나러 가기 {`>`}
                </button>
              </div>
              <div className="z-10">
                <img
                  src={liveTeacherTopTeacher}
                  alt=""
                  className=" absolute bottom-0 right-1/2 transform translate-x-1/2 sm:w-max w-164 sm:max-w-full max-w-none lt-t-img"
                />
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <LiveTeacherFirstSection />
        <LiveTeacherSecondSection />
        <ProcessSection />

        <div className="max-w-container">
          <div className="md:py-32 py-20">
            <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
              <div className="flex flex-col items-center justify-center">
                <div className=" md:w-16 md:h-16 w-10 h-10 text-white md:text-32 text-16 font-nanum rounded-full bg-primary-1 grid place-content-center md:pt-1 pl-0.5">
                  4
                </div>
              </div>
              <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center md:pt-10 pt-6">
                관리가 확실한 라이브쌤
              </h2>
              <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center py-2">
                수업 피드백은 솔직하고 꼼꼼하게!
              </h2>
              <p className="text-15 md:text-xl font-medium text-black sub-txt01 text-center py-3">
                칭찬뿐인 피드백은 NO! 라이브쌤의 꼼꼼한 피드백으로
                <br className="md:hidden block" />
                수업 외 시간의
                <br className="md:block hidden" />
                영어 학습법을 체계적으로
                <br className="md:hidden block" />
                관리해드립니다.
              </p>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__fadeInUp"
              animateOnce={true}
              className="max-w-xl mx-auto pt-10 relative"
            >
              <div className="space-y-3 absolute top-1/2 hidden md:block left-5 mobile-slide-btn">
                <Arrow className="swiper-button-prev-1 cursor-pointer transform rotate-180" />
                <Arrow className="swiper-button-next-1 cursor-pointer " />
              </div>
              <div className="sam-img-02">
                <img
                  src={require('../../assets/img/sam_img02.png').default}
                  className=""
                  alt=""
                />
              </div>
              <div className="relative mobile-slide-wrap">
                <Swiper
                  // pagination={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  direction={'vertical'}
                  loop={true}
                  className="mobile-slide"
                  navigation={{
                    nextEl: '.swiper-button-next-1',
                    prevEl: '.swiper-button-prev-1',
                  }}
                >
                  {imgData.map((banner) => (
                    <SwiperSlide key={String(banner.id)}>
                      <img alt="" src={banner.imgPath} className="" />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="mobile-slide-bg">
                  <img
                    src={require('../../assets/img/mobile-frame.png').default}
                    className=""
                    alt=""
                  />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <TeacherReviewSection />
      </div>
    </>
  );
};
