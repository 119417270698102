import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import HeroImg from '../assets/img/pricing-hero.jpg';
import { Button } from '../components/Button';
import { H5 } from '../components/H5';
import { usePopup } from '../components/PopUp';
import { PricingCard } from '../components/PricingCard';
import { Section } from '../components/Section';
import { useAuth } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { studentState, testResultState } from '../plugins/ridge';
import { Reinforce } from '../types';

export const PricingReinforcePage = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const student = studentState.useValue();
  const testResult = testResultState.useValue();
  const { requireLoginPopup, addStudentPopup, requireLevelTestPopup } =
    usePopup();

  const { data: reinforce } = useQuery<Reinforce>(
    `/reinforces/active/myReinforce?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  return (
    <div className="grid md:grid-cols-2 min-h-screen-10 gap-10">
      <img
        src={HeroImg}
        alt="pricing page"
        className="w-full h-56 md:h-full object-top object-cover"
      />
      <div className="mx-auto px-4 pt-20 w-full max-w-5xl">
        <Section className="max-w-sm mx-auto w-full space-y-6 px-4">
          <H5 className="border-b border-gray-50 pb-6">보강수업 결제</H5>

          {reinforce ? (
            <>
              <PricingCard
                key={reinforce?.id}
                selected
                reinforce={reinforce}
                isReinforce
                onClick={() => ''}
              />
              <div className="pt-24">
                <div className="border-t pb-6 border-gray-50" />
                <Button
                  text="보강권 결제하기"
                  className="filled-primary-1 w-full"
                  onClick={() => {
                    if (!authenticated) {
                      return requireLoginPopup();
                    }
                    if (!student) {
                      return addStudentPopup();
                    }
                    if (!testResult || !student.isTested) {
                      return requireLevelTestPopup(student?.id);
                    }
                    push('/reinforce/purchase');
                  }}
                />
              </div>
            </>
          ) : (
            <>결제할 보강권이 없습니다.</>
          )}
        </Section>
      </div>
    </div>
  );
};
