import React, { FC } from 'react';
import mainDescriptionImg1 from '../../../assets/img/main-description-img4.png';
import mainDescriptionImg2 from '../../../assets/img/main-description-img4-1.png';
import mainDescriptionImg3 from '../../../assets/img/main-description-img4-2.png';
import mainDescriptionImg4 from '../../../assets/img/main-description-img4-3.png';
import mainDescriptionImg5 from '../../../assets/img/main-description-img4-4.png';
import mainDescriptionImg6 from '../../../assets/img/main-description-img4-5.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Section } from '../../../components/Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper/core';
import { ReactComponent as Arrow } from '../../../assets/svg/swiper_arrow_right.svg';

SwiperCore.use([Pagination, Autoplay, Navigation]);

const imgData = [
  { id: 1, imgPath: mainDescriptionImg1 },
  { id: 2, imgPath: mainDescriptionImg2 },
  { id: 3, imgPath: mainDescriptionImg3 },
  { id: 4, imgPath: mainDescriptionImg4 },
  { id: 5, imgPath: mainDescriptionImg5 },
  { id: 6, imgPath: mainDescriptionImg6 },
];

interface SixthSectionProps {}

export const SixthSection: FC<SixthSectionProps> = () => {
  return (
    <>
      <div className="con-pa">
        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="max-w-container"
        >
          <h2 className="text-2xl md:text-4xl tit-txt01 text-center">
            그림만 봐도 영어가 술술~
          </h2>
          <h2 className="text-2xl md:text-4xl tit-txt01 text-center">
            튼튼영어라이브 <span className="text-blue-500">연상력 학습법</span>
          </h2>
          <p className="text-15 md:text-xl text-center sub-txt01 font-medium">
            한국어를 영어로 번역하는 과정 없이 <br />
            입에서 자연스럽게 영어가 툭!
          </p>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="relative"
        >
          <Section className="relative Fourth-slide-wrap tablet-slide-wrap">
            <Swiper
              loop={true}
              loopedSlides={1}
              touchRatio={1}
              //pagination={{ clickable: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next-1',
                prevEl: '.swiper-button-prev-1',
              }}
              className="Fourth-slide tablet-slide"
            >
              {/* {imgData.map((banner) => (
                <SwiperSlide key={String(banner.id)}>
                  <img alt="" src={banner.imgPath} className="mx-auto pb-10" />
                </SwiperSlide>
              ))} */}
              <SwiperSlide>
                <img alt="" src={imgData[0].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt18.png').default}
                  className="s-key-txt s-key-txt18"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[1].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt19.png').default}
                  className="s-key-txt s-key-txt19"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[2].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt20.png').default}
                  className="s-key-txt s-key-txt20"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[3].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt21.png').default}
                  className="s-key-txt s-key-txt21"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[4].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt22.png').default}
                  className="s-key-txt s-key-txt22"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[5].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt23.png').default}
                  className="s-key-txt s-key-txt23"
                />
              </SwiperSlide>
            </Swiper>
            <div className="tablet-slide-bg">
              <img
                src={require('../../../assets/img/tablet-frame.png').default}
                className=""
                alt=""
              />
            </div>
            <Arrow className="swiper-button-prev-1 mobile-none cursor-pointer swiper-button" />
            <Arrow className="swiper-button-next-1 mobile-none cursor-pointer swiper-button" />
          </Section>
          <div className="t-noti-box">
            <span>
              <a className="xi-info"></a>알려드려요!
            </span>
            튼튼영어라이브의 디지털 학습, 화상 수업을 포함한 모든 기능들은 PC 크롬
            웹페이지에서 원활한 이용이 가능합니다. 가급적 PC나 노트북을 이용해
            주세요.
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
};
