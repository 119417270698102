import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { LiveClass, Paginated } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const LiveClasses = () => {
  const queryString = useQueryString({
    sort: { index: 'ASC' },
    join: ['level', 'series', 'book'],
  });
  const { data } = useQuery<Paginated<LiveClass>>(
    `/admin/live-classes${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data) return <></>;
  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>회차 관리</AdminH1>
        <Button
          text="추가"
          to={'/admin/live-classes/add'}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>index</Table.Th>
              <Table.Th>level</Table.Th>
              <Table.Th>series</Table.Th>
              <Table.Th>book</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((liveClass) => (
              <Table.Row key={liveClass.id}>
                <Table.Td>{liveClass.index}</Table.Td>
                <Table.Td>{liveClass.level?.name}</Table.Td>
                <Table.Td>{liveClass.series?.name}</Table.Td>
                <Table.Td>{liveClass.book.title}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/live-classes/${liveClass.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/live-classes" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
