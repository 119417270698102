import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Point, PointType, Student } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminPointPopUp } from '../components/AdminPointPopUp';

export const UserRewardsPoint = () => {
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, refetch } = useQuery<Paginated<Point>>(
    `/admin/points?filter[userId]=${id}&limit=10000&join[0]=student`,
    fetcher
  );

  const { data: students } = useQuery<Student[]>(
    `/admin/students/user/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  return (
    <>
      <div>
        <div className="flex justify-end mb-3">
          <Button
            text="포인트 지급/차감하기"
            className="px-3 py-2 text-md outlined-primary-1 hover:bg-primary-1 hover:text-white"
            onClick={() => setIsOpen(true)}
          />
        </div>
        <AdminCard>
          <Table className="hidden md:table divide-gray-100">
            <Table.Head className="bg-transparent">
              <Table.Row>
                <Table.Th>일자</Table.Th>
                <Table.Th>자녀명</Table.Th>
                <Table.Th>포인트 설명</Table.Th>
                <Table.Th>지급 / 차감</Table.Th>
                <Table.Th>관리자 메모</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body className="divide-gray-50">
              {data?.items.map((point) => (
                <Table.Row key={point.id}>
                  <Table.Td className="font-normal">
                    {utcToLocalFormat(
                      point.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDDHmm
                    )}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {point.student.name}
                  </Table.Td>
                  <Table.Td className="font-normal">{point.title}</Table.Td>
                  <Table.Td className="font-normal">
                    {point.pointType === PointType.PROVISION
                      ? `+${point.value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                      : `-${point.value
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                  </Table.Td>
                  <Table.Td className="font-normal">{point.memo}</Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </AdminCard>
      </div>
      {isOpen && (
        <AdminPointPopUp
          userId={Number(id)}
          students={students}
          refetch={() => refetch()}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
