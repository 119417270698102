import { parse, stringify } from 'qs';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { api } from './plugins/axios';
import { fetcher } from './plugins/react-query';
import { searchTextState, tokenState } from './plugins/ridge';
import { CourseItem, Live } from './types';
import { useState } from 'react';

export const useAuth = () => {
  const [token, setToken] = tokenState.use();
  const authenticated = token !== null;
  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token } }) => setToken(token));
  const login = (data: any) =>
    api
      .post('/auth/login', data)
      .then(({ data: { token } }) => setToken(token));
  const logout = () => tokenState.reset();
  return { token, authenticated, signup, login, logout };
};

export function useQueryString(queryObject: any = {}, options: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: '1',
    limit: options.defaultLimit ?? '10',
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}

export function useCourseItemByLive(live?: Live) {
  const { data: lives } = useQuery<Live[]>(
    `/lives?studentId=${live?.studentId}&courseId=${live?.courseId}`,
    fetcher,
    { enabled: !!live }
  );

  const { data: courseItems } = useQuery<CourseItem[]>(
    `/course-items?courseId=${live?.courseId}`,
    fetcher,
    { enabled: !!live }
  );
  const index = lives?.findIndex((l) => l.id === live?.id);

  return index !== undefined ? courseItems?.[index] : undefined;
}

export function useSearch() {
  const [search, setSearch] = searchTextState.use();
  const [q, setQ] = useState(search.q);

  const setSearchText = () => {
    setSearch({ q: q });
  };

  return { q, setQ, setSearchText };
}
