import React from 'react';
import { H4 } from '../components/H4';
import { ReactComponent as PlusLogo } from '../assets/svg/icon-plus.svg';
import { Section } from '../components/Section';
import { useHistory } from 'react-router';
import { Student } from '../types';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { range } from '../utils';
import { Avatar } from '../components/Avatar';
import { apiUrl } from '../plugins/axios';

export const StudentsPage = () => {
  const { push } = useHistory();
  const { data: students } = useQuery<Student[]>(`/students`, fetcher);

  const studentMaxLength = 3;

  if (!students) return <></>;
  return (
    <div className="flex flex-col items-center justify-center pt-14 px-4">
      <H4>학습할 자녀를 추가해주세요.</H4>
      <p className="text-center pt-4">
        학습할 자녀 정보를 등록해주세요.
        <br />
        자녀는 최대 3명까지 등록 가능합니다.
      </p>

      <Section className="grid grid-cols-2 md:grid-cols-3 gap-14 pt-10">
        {students?.map((student) => (
          <div
            key={student.id}
            className="flex flex-col items-center space-y-4"
          >
            <Avatar src={apiUrl(student?.avatar)} className="wh-20" />
            <p className="text-gray-500">{student.name}</p>
          </div>
        ))}

        {range(studentMaxLength - students.length).map((i) => (
          <div key={i} className="group flex flex-col items-center space-y-4">
            <button
              onClick={() => push('/students/add')}
              className={`
               group-hover:bg-secondary-1 bg-gray-50 rounded-full wh-20 grid place-content-center`}
            >
              <PlusLogo
                className={`group-hover:text-primary-1 text-gray-200 fill-current`}
              />
            </button>
            <p className="text-gray-500">자녀 등록하기</p>
          </div>
        ))}
      </Section>
    </div>
  );
};
