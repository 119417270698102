import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/link.min.js';
import React, { useRef } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { apiUrl } from './axios';
import { tokenState } from './ridge';

export const Editor = (props: any) => {
  const editorRef = useRef<FroalaEditor>(null);
  const config = Object.assign(
    {
      attribution: false, // Remove the Powered By Froala message
      fileUploadURL: apiUrl('froala/upload'),
      imageEditButtons: ['imageDisplay', 'imageAlign', 'imageRemove'],
      imageUploadURL: apiUrl('froala/upload'),
      requestHeaders: { Authorization: `Bearer ${tokenState.useValue()}` },
      key: process.env.REACT_APP_FROALA_KEY,
      fileMaxSize: 1024 * 1024 * 9000,
    },
    props.config
  );

  return (
    <FroalaEditor
      ref={editorRef}
      config={config}
      model={props.model}
      onModelChange={props.onModelChange}
    />
  );
};

export { FroalaEditorView as EditorView };
