import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { BookItem } from '../../types';
import { AdminCard } from './AdminCard';

interface FormValues {
  name: string;
  index: number;
  url: string;
}

interface AdminLibraryCardProps {
  bookItem?: BookItem;
  refetchBookItems: () => void;
}

export const AdminLibraryCard: FC<AdminLibraryCardProps> = ({
  bookItem,
  refetchBookItems,
}) => {
  const [bookItemEditMode, setBookItemEditMode] = useState(false);

  const {
    watch,
    setValue,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!bookItem) return;
    clearErrors();
    setValue('name', bookItem.name);
    setValue('index', bookItem.index);
    setValue('url', bookItem.url);
  }, [clearErrors, setValue, bookItem, bookItemEditMode]);

  return (
    <AdminCard>
      <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-4 md:px-8">
        <TextField
          label="이름"
          disabled={!bookItemEditMode}
          helper={errors.name?.message}
          {...register('name', { required: '타이틀명을 입력해주세요.' })}
        />
        <TextField
          type="number"
          label="인덱스"
          disabled={!bookItemEditMode}
          {...register('index')}
        />
        <TextField
          wrapClassName="md:col-span-2"
          label="주소"
          disabled={!bookItemEditMode}
          {...register('url')}
        />
      </div>
      {!process.env.REACT_APP_DISABLED && (
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {bookItemEditMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setBookItemEditMode(false)}
            />
          )}
          {bookItemEditMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              disabled={!watch('name')}
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/bookItems/${bookItem?.id}`, data);
                await refetchBookItems();
                setBookItemEditMode(false);
              })}
            />
          )}
          {!bookItemEditMode && (
            <Button
              text="삭제"
              className="h-10 text-sm filled-red-500 hover:bg-red-600"
              onClick={() =>
                window.confirm('삭제하시겠습니까?') &&
                api
                  .delete(`/admin/bookItems/${bookItem?.id}`)
                  .then(refetchBookItems)
                  .then(() => window.alert('삭제되었습니다.'))
              }
            />
          )}
          {!bookItemEditMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setBookItemEditMode(true)}
            />
          )}
        </div>
      )}
    </AdminCard>
  );
};
