import React from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router';
import { fetcher } from '../../plugins/react-query';
import { Role, User } from '../../types';
import { LessonDetailPage } from './LessonDetailPage';
import { PreparePage } from './PreparePage';
import { MyPage } from './MyPage';
import { ScheduleEditPage } from './ScheduleEditPage';
import { SchedulePage } from './SchedulePage';
import { StudentsDetailPage } from './StudentsDetailPage';
import { StudentsPage } from './StudentsPage';

export const TeacherPage = () => {
  const { data: me, status } = useQuery<User>('/users/me', fetcher);

  if (status === 'loading') return <></>;
  if (me && me.role !== Role.TEACHER) return <Redirect to="/" />;
  return (
    <div className="pb-20">
      <Switch>
        <Route path="/teacher/schedule/edit" component={ScheduleEditPage} />
        <Route path="/teacher/schedule" component={SchedulePage} />
        <Route
          path="/teacher/students/:studentId/lives/:liveId"
          component={LessonDetailPage}
        />
        <Route path="/teacher/students/:id" component={StudentsDetailPage} />
        <Route path="/teacher/students" component={StudentsPage} />
        <Route path="/teacher/prepare" component={PreparePage} />
        <Route path="/teacher/mypage" component={MyPage} />
        {/* <Route path="/teacher/transactions" component={TransactionsPage} /> */}
        <Redirect from="/" to="/teacher/schedule" />
      </Switch>
    </div>
  );
};
