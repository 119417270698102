import { newRidgeState } from 'react-ridge-state';
import { DownloadPopupProps } from '../components/DownloadPopUp';
import { PopUpProps } from '../components/PopUp';
import {
  ClassType,
  Course,
  CourseItem,
  Live,
  Student,
  TestResult,
} from '../types';

export const tokenState = newRidgeState<string | null>(null, {
  onSet: (newState) => {
    if (newState) {
      localStorage.setItem('token', newState);
    } else {
      localStorage.removeItem('token');
    }
  },
});

export const osState = newRidgeState<string>('');

export const studentState = newRidgeState<Student | undefined>(undefined);
export const testResultState = newRidgeState<TestResult | undefined>(undefined);
export const trialCourseState = newRidgeState<Course | undefined>(undefined);
export const courseState = newRidgeState<Course | undefined>(undefined);
export const courseTypeState = newRidgeState<ClassType | undefined>(undefined);
export const courseItemState = newRidgeState<CourseItem | undefined>(undefined);
export const liveState = newRidgeState<Live | undefined>(undefined);

export const latestCourseState = newRidgeState<Course | undefined>(undefined);
export const latestCourseItemState = newRidgeState<CourseItem | undefined>(
  undefined
);
export const latestLiveState = newRidgeState<Live | undefined>(undefined);

export const popupState = newRidgeState<PopUpProps>({
  title: '',
  body: '',
  secondBody: '',
  primaryText: '',
  primaryClick: () => {},
  secondaryText: '',
  secondaryClick: () => {},
  dangerText: '',
  dangerClick: () => {},
  footer: '',
});

export const downloadPopupState = newRidgeState<DownloadPopupProps>({
  title: '',
  body: '',
  secondBody: '',
  primaryText: '',
  primaryLink: '',
  secondaryText: '',
  secondaryLink: '',
  teacherName: '',
});

export const selectLevelPopupState = newRidgeState({ open: false });
export const selectLevelNoTestPopupState = newRidgeState({ open: false });

export const liveFeedbackPopupState = newRidgeState({
  open: false,
  teacherName: '',
  liveId: 0,
  teacherId: 0,
  studentId: 0,
});

export const searchTextState = newRidgeState({
  q: '',
});

function setInitialState() {
  const token = localStorage.getItem('token');
  tokenState.set(token);
}

setInitialState();
