import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router';
import { UserRewardsCoupons } from './UserRewardsCoupons';
import { UserRewardsPoint } from './UserRewardsPoint';

export const UserRewards = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <>
      <div>
        <button
          onClick={() => push(`/admin/users/${id}/rewards/coupons`)}
          className={`rounded-md text-13 px-3 py-2 ${
            pathname.endsWith('coupons') ? 'bg-primary-1 text-white' : ''
          }`}
        >
          쿠폰
        </button>
        <button
          onClick={() => push(`/admin/users/${id}/rewards/point`)}
          className={`rounded-md text-13 px-3 py-2 ${
            pathname.endsWith('point') ? 'bg-primary-1 text-white' : ''
          }`}
        >
          포인트
        </button>
      </div>

      <Switch>
        <Route
          path="/admin/users/:id/rewards/coupons"
          component={UserRewardsCoupons}
        />
        <Route
          path="/admin/users/:id/rewards/point"
          component={UserRewardsPoint}
        />
        <Redirect from="/" to={`/admin/users/${id}/rewards/coupons`} />
      </Switch>
    </>
  );
};
