import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../components/Button';
import { H3 } from '../../components/H3';
import { ArrowLeftIcon, XIcon } from '../../components/icons';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { Course } from '../../types';

interface FormValues {
  endedAt: string;
}

interface AdminCourseValidityPopUpProps {
  course: Course;
  onClose: () => void;
  refetch: () => void;
}

export const AdminCourseValidityPopUp: FC<AdminCourseValidityPopUpProps> = ({
  course,
  onClose,
  refetch,
}) => {
  const {
    register,

    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const update = handleSubmit((data) => {
    const now = new Date();
    const endedAt = new Date(data.endedAt);
    endedAt.setHours(23, 59, 59, 0);

    if (
      course.startedAt > data.endedAt ||
      now.toISOString() > endedAt.toISOString()
    ) {
      alert('수강권 종료일자를 다시 확인해주세요!');
      return;
    }

    return api
      .patch(`/admin/courses/${course.id}/validity`, data)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((err) => console.error('수강권 유효기간 변경 오류', err));
  });

  useEffect(() => {
    if (!course) return;
    setValue('endedAt', course.endedAt);
  }, [course, setValue]);

  return (
    <div className="z-50 fixed inset-0 -top-4 bg-littleblack flex items-center justify-center md:px-4">
      <div className="w-full h-full md:h-auto flex flex-col bg-white rounded-xl overflow-hidden p-10 space-y-6 md:max-w-xl">
        <button onClick={onClose} className="md:self-end">
          <ArrowLeftIcon className="md:hidden" />
          <XIcon className="hidden md:block" />
        </button>

        <H3 className="pb-4">수강권 유효기간 변경</H3>

        <TextField
          label="종료 일자"
          placeholder="종료 일자를 입력해주세요."
          type="date"
          max={'9999-12-31'}
          helper={errors.endedAt?.message}
          {...register('endedAt', {
            required: '종료 일자를 입력해주세요.',
          })}
        />

        <Button
          text="저장"
          className="text-15 font-normal filled-primary-1"
          onClick={update}
        />
      </div>
    </div>
  );
};
