import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSiteCode, PaymentContainer } from '../../plugins/payment/payment';
import { postConfirmPayments } from '../../plugins/payment/payment.api';
import { popupState, studentState } from '../../plugins/ridge';
import { PaymentMethod } from '../../types';

const PaymentProcessingWeb = () => {
  const { paymentStartStatus } = PaymentContainer.useContext();
  const student = studentState.useValue();
  const { push } = useHistory();

  const onStart = () => {
    // @ts-ignore
    jsf__pay(document.order_info);
  };

  useEffect(() => {
    if (paymentStartStatus) {
      onStart();
      // @ts-ignore
      window.m_Completepayment = function (FormOrJson, closeEvent) {
        // @ts-ignore
        const frm = document.order_info;
        // @ts-ignore
        GetField(frm, FormOrJson);
        if (frm.res_cd.value === '0000') {
          const data = new URLSearchParams();
          for (let i = 0; i < frm.elements.length; i++) {
            data.append(frm.elements[i].name, frm.elements[i].value);
          }
          postConfirmPayments(data)
            .then((result) => {
              closeEvent();
              if (result && result.data) {
                //결제 완료 페이지
                push(
                  `/purchase/success?orderNumber=${result.data.pgOrderNumber}`
                );
              }
            })
            .catch(() => {
              // 에러 팝업
              popupState.set({
                title: '결제 오류',
                body: '결제에 실패했습니다.',
              });
            });
        } else {
          //결제 에러 팝업
          popupState.set({
            title: '결제 실패',
            body: `결제에 실패했습니다.[${frm.res_cd.value}]${frm.res_msg.value}`,
          });
          closeEvent();
        }
      };
    }
  }, [paymentStartStatus, push]);

  return (
    <div className="h-full">
      <form name="order_info" method="post">
        <input
          type="hidden"
          name="ordr_idxx"
          value={paymentStartStatus?.pgOrderNumber}
        />
        <input type="hidden" name="good_name" value="튼튼영어_수강권구매" />
        <input
          type="hidden"
          name="good_mny"
          value={paymentStartStatus?.totalAmount}
        />
        <input type="hidden" name="buyr_name" value={student?.name} />
        <input
          type="hidden"
          name="buyr_tel1"
          value={student?.user.phoneNumber}
        />
        <input type="hidden" name="buyr_tel2" value="" />
        <input type="hidden" name="buyr_mail" value={student?.user.email} />
        <input
          type="hidden"
          name="pay_method"
          value={
            paymentStartStatus?.paymentMethod === PaymentMethod.CARD ||
            paymentStartStatus?.paymentMethod === PaymentMethod.NPAY ||
            paymentStartStatus?.paymentMethod === PaymentMethod.KPAY
              ? '100000000000'
              : '010000000000'
          }
        />
        <input type="hidden" name="req_tx" value="pay" />
        <input
          type="hidden"
          name="site_cd"
          value={getSiteCode(paymentStartStatus?.paymentMethod)}
        />
        <input
          type="hidden"
          name="site_name"
          value={
            paymentStartStatus?.paymentMethod === PaymentMethod.CARD ||
            paymentStartStatus?.paymentMethod === PaymentMethod.BANK
              ? '튼튼영어라이브'
              : paymentStartStatus?.paymentMethod === PaymentMethod.NPAY
              ? '튼튼영어라이브_Npay'
              : paymentStartStatus?.paymentMethod === PaymentMethod.KPAY
              ? '튼튼영어라이브_kakaopay'
              : ''
          }
        />
        <input type="hidden" name="quotaopt" value="12" />
        <input type="hidden" name="currency" value="WON" />
        <input type="hidden" name="tablet_size" value="1.0" />
        <input type="hidden" name="module_type" value="01" />
        <input type="hidden" name="res_cd" value="" />
        <input type="hidden" name="res_msg" value="" />
        <input type="hidden" name="enc_info" value="" />
        <input type="hidden" name="enc_data" value="" />
        <input type="hidden" name="ret_pay_method" value="" />
        <input type="hidden" name="tran_cd" value="" />
        <input type="hidden" name="use_pay_method" value="" />
        <input type="hidden" name="ordr_chk" value="" />

        <input
          type="hidden"
          name="kakaopay_direct"
          value={
            paymentStartStatus?.paymentMethod === PaymentMethod.KPAY ? 'Y' : 'N'
          }
        />
        <input
          type="hidden"
          name="naverpay_direct"
          value={
            paymentStartStatus?.paymentMethod === PaymentMethod.NPAY ? 'Y' : 'N'
          }
        />

        <input type="hidden" name="cash_yn" value="" />
        <input type="hidden" name="cash_tr_code" value="" />
        <input type="hidden" name="cash_id_info" value="" />
        <input type="hidden" name="good_expr" value="0" />
        <input type="hidden" name="encoding_trans" value="UTF 8" />
      </form>
    </div>
  );
};

export default PaymentProcessingWeb;
