import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Avatar } from '../../components/Avatar';
import { H6 } from '../../components/H6';
import { Pagination } from '../../components/Pagination';
import { Section } from '../../components/Section';
import { useQueryString } from '../../hooks';
import { apiUrl } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, Payment, PaymentMethod, PaymentState } from '../../types';

export const UserPayments = () => {
  const { id } = useParams<{ id: string }>();
  const queryString = useQueryString();
  const { data: payments } = useQuery<Paginated<Payment>>(
    `admin/payments/${id}/user${queryString}`,
    fetcher
  );

  return (
    <>
      {payments?.items.map((payment) => (
        <div className="bg-white rounded-md shadow-3xl p-6">
          <Section className="flex justify-between items-center">
            <div className="flex space-x-2">
              <div className="text-18">
                {utcToLocalFormat(payment.createdAt, MomentFormat.YYYYMMDDHHmm)}
              </div>
            </div>

            <div className="text-blue-500 text-right md:text-left">
              {payment.paymentState === PaymentState.PAID
                ? '결제완료'
                : payment.paymentState === PaymentState.CANCELED
                ? '취소'
                : payment.paymentState === PaymentState.WAIT
                ? '입금대기'
                : ''}
              <br className="md:hidden" />
              {payment.bookIncluded || payment.penIncluded
                ? '(배송상품 있음)'
                : '(배송상품 없음)'}
            </div>
          </Section>

          <div className="border-b border-gray-50 my-8 -mx-5" />
          <Section className="flex space-x-10">
            <div>
              <div
                className={`border-primary-2 border-2 wh-14 md:wh-20 rounded-full p-1`}
              >
                <Avatar
                  src={apiUrl(payment.student.avatar)}
                  className="wh-11 md:wh-17"
                />
              </div>
            </div>
            <div>
              <div className="flex space-x-2 items-center pb-6">
                <H6>
                  {!payment.totalAmount
                    ? '체험'
                    : payment.productAmount
                    ? `${payment.productAmount}회`
                    : '보강권'}
                </H6>
                <span className="text-gray-400">
                  {`(${payment.bookIncluded ? '교재포함' : '교재미포함'} ∙ ${
                    payment.penIncluded ? '토킹펜 포함' : '토킹펜 미포함'
                  } )`}
                </span>
              </div>

              <div className="space-y-2">
                <InfoItem
                  title="주문번호"
                  description={payment.pgOrderNumber}
                />
                {(payment.bookIncluded || payment.penIncluded) && (
                  <InfoItem
                    title="송장번호"
                    description={payment?.trackingNumber}
                  />
                )}
                <InfoItem
                  title="주문 상태"
                  description={`
                ${
                  payment.paymentState === PaymentState.PAID ||
                  payment.paymentState ===
                    PaymentState.WAITING_FOR_CONSULTATION ||
                  payment.paymentState === PaymentState.IN_TRANSIT ||
                  payment.paymentState === PaymentState.DELIVERED
                    ? '결제완료'
                    : payment.paymentState === PaymentState.CANCELED
                    ? '취소'
                    : payment.paymentState === PaymentState.WAIT
                    ? '입금대기'
                    : payment.paymentState === PaymentState.WAITING_FOR_DELIVERY
                    ? '배송준비'
                    : payment.paymentState === PaymentState.LEAVING
                    ? '출고완료'
                    : ''
                }
                ${
                  payment.bookIncluded || payment.penIncluded
                    ? '(배송상품 있음)'
                    : '(배송상품 없음)'
                }
                `}
                />
                <InfoItem
                  title="자녀 이름"
                  description={payment.student.name}
                />
                <InfoItem
                  title="결제 금액"
                  description={`
                ${String(!payment.paidAmount ? 0 : payment.paidAmount).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                />
                <InfoItem
                  title="쿠폰"
                  description={
                    (payment.couponItems && payment.couponItems.length > 0) ||
                    payment.couponItemId
                      ? `${String(payment.discountedAmount).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )}원`
                      : '-'
                  }
                />
                <InfoItem
                  title="결제수단"
                  description={
                    payment.paymentMethod === PaymentMethod.BANK
                      ? `계좌이체`
                      : payment.paymentMethod === PaymentMethod.CARD
                      ? `카드결제`
                      : // ∙(${payment.cardName})
                        // : payment.paymentMethod === PaymentMethod.KPAY
                        // ? `카카오페이`
                        // : payment.paymentMethod === PaymentMethod.NPAY
                        // ? `네이버페이`
                        '-'
                  }
                />
                {payment.deliveryMessage && (
                  <InfoItem
                    title="배송 메세지"
                    description={payment.deliveryMessage}
                  />
                )}

                {payment.consultingMessage && (
                  <InfoItem
                    title="학습 컨설팅 시 원하는 내용"
                    description={payment.consultingMessage}
                  />
                )}
                {payment.consultingTime && (
                  <InfoItem
                    title="전화 상담 가능 시간"
                    description={payment.consultingTime}
                  />
                )}
              </div>
            </div>
          </Section>
        </div>
      ))}
      <Pagination className="border-t">
        <Pagination.Label
          count={payments?.items.length || 0}
          total={payments?.total || 0}
        />
        <Pagination.Nav
          basePath={`/admin/users/${id}/payments`}
          total={payments?.total || 0}
        />
      </Pagination>
    </>
  );
};

interface InfoItemProps {
  title?: string;
  description?: string;
}

const InfoItem: FC<InfoItemProps> = ({ title, description }) => {
  return (
    <div className="flex text-12">
      <p className="w-20">{title}</p>
      <p className="flex-1">{description}</p>
    </div>
  );
};
