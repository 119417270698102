import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Payment, PaymentState } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

interface FormValues {
  paymentState: any;
  memo: string;
  trackingNumber: string;
  bookIncluded: boolean;
  penIncluded: boolean;
}

export const PaymentDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { data: payment, refetch } = useQuery<Payment>(
    `/admin/payments/${id}`,
    fetcher
  );

  const { watch, setValue, register, handleSubmit } = useForm<FormValues>();

  useEffect(() => {
    if (!payment) return;
    setValue('paymentState', payment.paymentState);
    setValue('memo', payment.memo);
    setValue('trackingNumber', payment.trackingNumber);
    setValue('bookIncluded', payment.bookIncluded);
    setValue('penIncluded', payment.penIncluded);
  }, [payment, setValue]);

  return (
    <>
      <AdminH1>주문 상세</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <TextField
            label="주문일자"
            disabled
            value={moment(payment?.createdAt).format('YYYY.MM.DD')}
          />
          <TextField
            label="주문번호"
            disabled
            value={payment?.pgOrderNumber ? payment?.pgOrderNumber : '-'}
          />
          <TextField
            label="주문상품"
            disabled
            value={
              payment?.isTrialPackage
                ? `${payment?.productAmount}회 체험팩`
                : payment?.productAmount
                ? `${payment?.productAmount}회 수업`
                : payment?.paidAmount
                ? '보강'
                : '체험'
            }
          />
          <TextField label="주문자명" disabled value={payment?.student.name} />
          <TextField
            label="결제금액"
            disabled
            value={
              payment?.paidAmount
                ? `${String(payment?.paidAmount).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                  )}원`
                : '-'
            }
          />
          <TextField
            label="할인금액"
            disabled
            value={
              payment?.discountedAmount
                ? `${String(payment?.discountedAmount).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                  )}원`
                : '-'
            }
          />
          <TextField
            label="주문자 연락처"
            disabled
            value={payment?.user.phoneNumber}
          />
          <TextField
            label="수취인 연락처"
            disabled
            value={payment?.orderPhoneNumber || '-'}
          />
          <TextField
            label="주소"
            disabled
            value={
              payment?.address || payment?.detailAddress
                ? payment?.address + payment?.detailAddress
                : '-'
            }
          />
          <TextField
            label="배송 메세지"
            disabled
            value={payment?.deliveryMessage || '-'}
          />
          <TextField
            label="상담 가능 시간"
            disabled
            value={payment?.consultingTime || '-'}
          />
          <TextField
            label="학습 컨설팅 시 원하는 내용"
            disabled
            value={payment?.consultingMessage || '-'}
          />
          <Select
            label="주문 상태"
            value={watch('paymentState')}
            onChange={(e) => setValue('paymentState', e.target.value)}
          >
            {[
              { id: 1, label: '결제완료', state: PaymentState.PAID },
              { id: 2, label: '취소/교환/환불', state: PaymentState.CANCELED },
              {
                id: 3,
                label: '상담대기',
                state: PaymentState.WAITING_FOR_CONSULTATION,
              },
              {
                id: 4,
                label: '배송 준비',
                state: PaymentState.WAITING_FOR_DELIVERY,
              },
              { id: 5, label: '출고', state: PaymentState.LEAVING },
              { id: 6, label: '배송 중', state: PaymentState.IN_TRANSIT },
              { id: 7, label: '배송완료', state: PaymentState.DELIVERED },
            ].map((s) => (
              <option key={s.id} label={s.label} value={s.state}></option>
            ))}
          </Select>
          <TextField label="송장 번호" {...register('trackingNumber')} />
          <TextArea label="관리자 메모" {...register('memo')} />
          <div className="space-y-2">
            <Checkbox label="스토리북 포함" {...register('bookIncluded')} />
            <Checkbox label="토킹펜 포함" {...register('penIncluded')} />
          </div>
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="저장"
            className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
            onClick={handleSubmit(async (data) => {
              await api.patch(`/admin/payments/${id}`, data);
              await refetch();
              alert('저장되었습니다.');
            })}
          />
        </div>
      </AdminCard>
    </>
  );
};
