import { FC } from 'react';
import curriculum2_1 from '../../assets/img/curriculum2_1.png';
import curriculum2_2 from '../../assets/img/curriculum2_2.png';
import curriculum2_3 from '../../assets/img/curriculum2_3.png';
import liveteacher2_1 from '../../assets/img/liveteacher2_1.jpg';
import liveteacher2_2 from '../../assets/img/liveteacher2_2.jpg';
import liveteacher2_3 from '../../assets/img/liveteacher2_3.jpg';
import liveteacher2_4 from '../../assets/img/liveteacher2_4.jpg';
import liveteacher2_5 from '../../assets/img/liveteacher2_5.jpg';
import liveteacher2_6 from '../../assets/img/liveteacher2_6.jpg';
import liveteacher2_7 from '../../assets/img/liveteacher2_7.jpg';
import liveteacher2_8 from '../../assets/img/liveteacher2_8.jpg';
import liveteacher2_9 from '../../assets/img/liveteacher2_9.jpg';
import liveteacher2_10 from '../../assets/img/liveteacher2_10.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, { Autoplay, Navigation } from 'swiper/core';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import character from '../../assets/img/character1.png';
import { isMobile } from 'react-device-detect';

SwiperCore.use([Autoplay, Navigation]);

interface LiveTeacherSecondSectionProps {}

const reviews = [
  { id: 1, imgPath: liveteacher2_1 },
  { id: 2, imgPath: liveteacher2_2 },
  { id: 3, imgPath: liveteacher2_3 },
  { id: 4, imgPath: liveteacher2_4 },
  { id: 5, imgPath: liveteacher2_5 },
  { id: 6, imgPath: liveteacher2_6 },
  { id: 7, imgPath: liveteacher2_7 },
  { id: 8, imgPath: liveteacher2_8 },
  { id: 9, imgPath: liveteacher2_9 },
  { id: 10, imgPath: liveteacher2_10 },
];

export const LiveTeacherSecondSection: FC<
  LiveTeacherSecondSectionProps
> = () => {
  return (
    <>
      <div className="bg-light-blue flex flex-col items-center justify-center md:py-32 py-20">
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="flex flex-col items-center justify-center">
            <div className="md:w-16 md:h-16 w-10 h-10 text-white md:text-32 text-16 font-nanum rounded-full bg-primary-1 grid place-content-center md:pt-1 md:pl-0.5 ">
              2
            </div>
          </div>
          <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center md:pt-10 pt-6">
            이해가 잘 안되는 부분은
          </h2>
          <h2 className="text-2xl md:text-4xl text-blue-500 tit-txt01 text-center py-2">
            모국어로 쉽게!
          </h2>
          <p className="text-15 md:text-xl font-medium text-black sub-txt01 text-center py-3">
            영어가 아직 어렵다면, 강제적 영어환경은 역효과!
            <br />
            이중언어 선생님이니까 안심할 수 있어요.
          </p>
        </ScrollAnimation>

        <div className="max-w-7xl mx-auto md:px-10 px-5 space-y-10 mt-10">
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="flex md:flex-row flex-col rounded-lg overflow-hidden shadow-lg">
              <div className="bg-primary-5 h-64 md:w-120 w-full relative">
                <img
                  src={curriculum2_1}
                  alt=""
                  className="absolute bottom-0 right-1/2 transform translate-x-1/2"
                />
              </div>
              <div className="bg-white grid place-content-center">
                <div className="md:w-2/3 w-9/12 py-10 mx-auto space-y-2">
                  <h3 className="lg:text-3xl text-2xl ">
                    1. 검증된 영어교육 전문가
                  </h3>
                  <p className="lg:text-xl text-15 md:font-normal font-medium md:text-gray-900 text-gray-700">
                    테솔 자격증 보유, 초등영어교육 경력 2년 이상, 해외 대학
                    졸업, 해외 3년 이상 거주자로 구성된 수준 높은 라이브쌤을
                    만나보세요!
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="flex md:flex-row flex-col-reverse rounded-lg overflow-hidden shadow-lg">
              <div className="bg-white grid place-content-center">
                <div className="md:w-2/3 w-9/12 py-10 mx-auto space-y-2">
                  <h3 className="lg:text-3xl text-2xl">
                    2. 표준화된 초등 영어 수업
                  </h3>
                  <p className="lg:text-xl text-15 md:font-normal font-medium md:text-gray-900 text-gray-700">
                    선생님의 역량에 따라 들쑥날쑥한 수업은 NO! 정교하게 고안된
                    표준 커리큘럼 기반으로 평생영어의 기반이 되는 영어감각을
                    키워 드려요!
                  </p>
                </div>
              </div>
              <div className="bg-primary-1 h-64 md:w-120 w-full relative">
                <img
                  src={curriculum2_2}
                  alt=""
                  className="absolute bottom-0 right-1/2 transform translate-x-1/2"
                />
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="flex md:flex-row flex-col rounded-lg overflow-hidden shadow-lg">
              <div className="bg-primary-4 h-64 md:w-120 w-full relative">
                <img
                  src={curriculum2_3}
                  alt=""
                  className="absolute bottom-0 right-1/2 transform translate-x-1/2"
                />
              </div>
              <div className="bg-white grid place-content-center">
                <div className="md:w-2/3 w-9/12 py-10 mx-auto space-y-2">
                  <h3 className="lg:text-3xl text-2xl ">3. 따뜻한 라이브쌤</h3>
                  <p className="lg:text-xl text-15 md:font-normal font-medium md:text-gray-900 text-gray-700">
                    비대면으로도 충분히 전해지는 선생님의 응원과 격려! 아이의
                    눈높이에 딱 맞는 수업으로 영어에 대한 부담감은 낮춰주고,
                    자신감은 키워줘요!
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="w-full relative mt-20"
        >
          <img
            src={character}
            alt=""
            className="h-24 relative review-ch-01"
          />
          <h2 className="text-lg md:text-xl font-semibold text-center review-spot-01">
            라이브쌤 리얼리뷰
          </h2>
          <div className="relative max-w-full mt-8">
            <Swiper
              centeredSlides={isMobile ? false : true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next-1',
                prevEl: '.swiper-button-prev-1',
              }}
              loop={true}
              className="mySwiper "
              breakpoints={{
                // 0: {
                //   slidesPerView: 1.5,
                //   spaceBetween: 10,
                // },
                340: {
                  slidesPerView: 1.5,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                1536: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
              }}
            >
              {reviews.map((review) => (
                <SwiperSlide key={String(review.id)}>
                  <div className="ml-5 md:mx-0 h-32 md:h-48 relative overflow-hidden rounded-md">
                    <img
                      src={review.imgPath}
                      alt=""
                      className="absolute w-full h-full object-cover"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </ScrollAnimation>
      </div>
    </>
  );
};
