import moment from 'moment';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { ReactComponent as LessonBullet } from '../assets/svg/lesson-bullet.svg';
import { ReactComponent as LessonChevron } from '../assets/svg/lesson-chevron.svg';
import { Button } from '../components/Button';
import { Dropdown } from '../components/Dropdown';
import { H5 } from '../components/H5';
import { ChevronDownIcon } from '../components/icons';
import { Section } from '../components/Section';
import { ReactComponent as CharacterSvg } from '../assets/svg/character-1.svg';
import { ReactComponent as WarningSvg } from '../assets/svg/warning.svg';
import { Avatar } from '../components/Avatar';
import { H6 } from '../components/H6';
import { Menu } from '../components/Menu';
import {
  Book,
  ClassType,
  CourseItem,
  Entry,
  Lesson,
  LessonResult,
  Live,
  LiveFeedback,
  LiveState,
  liveStateToText,
} from '../types';
import {
  courseItemState,
  courseState,
  latestLiveState,
  liveState,
  popupState,
  studentState,
  testResultState,
  tokenState,
} from '../plugins/ridge';
import { fetcher } from '../plugins/react-query';
import { usePopup } from '../components/PopUp';
import { api, apiUrl } from '../plugins/axios';
//@ts-ignore
import ReactStars from 'react-rating-stars-component';
import {
  CM_OP_CORNER_DONE,
  fn_openCornerMap,
  CM_OP_LESSON_DONE,
  CM_OP_ERROR,
} from '../plugins/open_cm';
import {
  convertLessonDoneResult,
  convertLessonResult,
  lessonToCorners,
} from '../plugins/cornermap';
import { WeeklyTest } from '../plugins/weeklytest';
// import { io, Socket } from 'socket.io-client';
// import { LocalLogger } from '../common/local-logger';

export const ClassroomPage = () => {
  const { push } = useHistory();
  const {
    requireLevelTestPopup,
    selectLevelPopup,
    selectLevelNoTestPopup,
    requirePurchasePopup,
    skipLevelTestPopup,
  } = usePopup();

  const student = studentState.useValue();
  const testResult = testResultState.useValue();
  const course = courseState.useValue();
  const courseItem = courseItemState.useValue();
  const live = liveState.useValue();
  const latestLive = latestLiveState.useValue();

  // const [socket, setSocket] = useState<Socket | null>(null);
  // LocalLogger.log({ key: 'test', description: 'before CM_OP_CORNER_DONE' });

  const { data: courseItems } = useQuery<CourseItem[]>(
    `/course-items?courseId=${course?.id}`,
    fetcher,
    { enabled: !!course }
  );

  const { data: book } = useQuery<Book>(
    `/books/${courseItem?.liveClass?.bookId}`,
    fetcher,
    { enabled: !!courseItem }
  );

  const lessons = book?.lessons;

  const { data: trialBook } = useQuery<Book>(
    `/books/trial?studentId=${student?.id}`,
    fetcher,
    {
      enabled: !!course?.isTrial && !!student,
    }
  );

  const { data: liveFeedback } = useQuery<LiveFeedback>(
    `/live-feedbacks/lives/${live?.id}`,
    fetcher,
    { enabled: !!live }
  );

  const { data: lessonResults, refetch: lessonResultsRefetch } = useQuery<
    LessonResult[]
  >(`/lesson-results?studentId=${student?.id}`, fetcher, {
    enabled: !!student?.id,
  });

  const { data: entries } = useQuery<Entry>(
    `/entries?testResultId=${testResult?.id}`,
    fetcher,
    { enabled: !!testResult }
  );

  //잔여 수강 횟수 및 접근 가능한 회차 여부 확인을 위한 수업 내역
  const { data: lives } = useQuery<Live[]>(
    `/lives/reserved/course/${course?.id}`,
    fetcher,
    { enabled: !!course }
  );

  //해당 선생님과 몇 번째 수업인지 확인하는 로직
  const { data: teacherLives } = useQuery<Live[]>(
    `/lives/byTeacherAndLives/attended?studentId=${student?.id}&teacherId=${live?.teacherId}`,
    fetcher,
    { enabled: !!live && !!student }
  );
  const reservedLives = teacherLives?.filter(
    (live) => live.state === LiveState.RESERVED
  );
  const liveIndex = reservedLives?.findIndex((l) => l.id === live?.id);

  const now = new Date();
  const checkTime = new Date(live?.start || '');
  checkTime.setMinutes(checkTime.getMinutes() - 5);
  const end = new Date(live?.end || '');
  end.setMinutes(end.getMinutes() - 5);

  const entrySeries =
    entries?.levelCode === '1-A' || entries?.levelCode === '1-B'
      ? 'I Love Stories 1권'
      : entries?.levelCode === '1-C' || entries?.levelCode === '1-D'
      ? 'Reading Rookie 1-1권'
      : entries?.levelCode === '2-A' || entries?.levelCode === '2-B'
      ? `What's Up 1권`
      : entries?.levelCode === '2-C'
      ? 'Reading Rookie 2-7권'
      : entries?.levelCode === '3-A' || entries?.levelCode === '3-B'
      ? 'Joy the Tomboy 1권'
      : entries?.levelCode === '3-C'
      ? 'My Reading Rainbow 1권'
      : '';

  const enterLive = async () => {
    if (!student || !live) return;
    if (!student.pageCallAccessToken) {
      alert('강의실 입장 권한이 없습니다.');
      return;
    }
    if (!live.pageCallRoomId) {
      alert('강의실 입장이 불가합니다.');
      return;
    }
    if (live.state === LiveState.NO_SHOW) {
      popupState.set({
        title: '수업 종료 안내',
        body: `선생님이 수업시작 10분내로 입장하지않아 노쇼처리 되었습니다.\n 문의사항은 고객센터로 연락주세요`,
      });
    }
    if (live.state === LiveState.ABSENT) {
      popupState.set({
        title: '수업 종료 안내',
        body: `수업시작 10분내로 입장하지않아 결석 처리 되었습니다.\n 다음 회차 수업을 예약해주세요.`,
      });
    }
    if (live.state === LiveState.ABSENT_AND_NO_SHOW) {
      popupState.set({
        title: '수업 종료 안내',
        body: `수업시작 10분내로 입장하지않아 결석 처리 되었습니다.\n 다음 회차 수업을 예약해주세요.`,
      });
    }
    if (
      live.state === LiveState.CANCELED ||
      live.state === LiveState.CANCELLATION_THE_DAY
    ) {
      popupState.set({
        title: '수업 종료 안내',
        body: `취소 처리된 수업입니다..\n 다음 회차 수업을 예약해주세요.`,
      });
    }

    if (live.state === LiveState.RESERVED) {
      const attendFindCourse = course?.lives
        .filter((l) => l.attendRound)
        .sort((a, b) => a.attendRound - b.attendRound);

      const attendFindCourseItem =
        attendFindCourse &&
        attendFindCourse.length > 0 &&
        attendFindCourse[attendFindCourse.length - 1];

      const attendCourseItem = course?.courseItems.find(
        (ci) =>
          attendFindCourseItem &&
          ci.number === attendFindCourseItem?.attendRound
      );

      const nextCourseItem = course?.courseItems.find(
        (ci) => attendCourseItem && ci.number === attendCourseItem?.number + 1
      );

      await api
        .patch(`/lives/courseItem/${live.id}`, {
          courseItemId: nextCourseItem
            ? nextCourseItem?.id
            : course?.courseItems[0].id,
        })
        .then(async () => {
          // socket?.emit('join', { liveId: live?.id });
          const pageCallAccessToken = student.pageCallAccessToken
            ? student.pageCallAccessToken
            : '';
          window.open(
            `https://app.pagecall.net/${live.pageCallRoomId}/?access_token=${pageCallAccessToken}`
          );
        })
        .catch((e) => console.log('라이브 수정 오류', e));
    }
  };

  const testPageCall = async () => {
    popupState.set({
      title: '수업 환경 테스트',
      body: (
        <React.Fragment>
          <br />
          수업 환경 테스트 시,
          <br />① 팝업창 선택사항을 <b>영구적</b>으로 설정 후 <b>허용</b>을
          눌러주세요.
          <br />② 카메라와 마이크가 잘 연결되어 있는지 확인해주세요.
          <br />③ 스피커 샘플 오디오가 잘 재생되는지 확인해주세요.
          <br />④ 이상이 없다면, 접속을 눌러 수업 환경을 테스트해보세요.
          <br />
          <br />
          *Chrome 사용을 권장드립니다.
        </React.Fragment>
      ),
      primaryText: '수업 환경 테스트 하기',
      primaryClick: async () => {
        window.open('https://app.pagecall.com/test', '_blank');
        // await api
        //   .post(`/students/${student?.id}/test-pageCall`)
        //   .then(async (res) => {
        //     window.open(
        //       `https://app.pagecall.net/${
        //         res.data.pageCallTestRoomId || res.data.id
        //       }/?access_token=${student?.pageCallAccessToken}`
        //     );
        //   })
        //   .catch((err) => console.log(err, '수업 환경 테스트 오류'));
      },
    });
  };

  const openBluegaLesson = async (lesson: Lesson) => {
    const lessonResult = lessonResults?.find((r) => r.lessonId === lesson.id);
    // const findLessons = book?.lessons.filter((l) => !l.test);
    // const bookLessonIds = findLessons?.map((l) => l.id);
    // const doneResults = lessonResults?.filter(
    //   (r) => r.isCompleted && bookLessonIds?.includes(r.lessonId)
    // );
    // const onlyWorkBook = findLessons?.filter(
    //   (l) =>
    //     !l.learn &&
    //     !l.phonics &&
    //     !l.story &&
    //     !l.talk &&
    //     !l.test &&
    //     !l.words &&
    //     l.workbook
    // );

    if (lesson.test && !lessonResult?.testDone) {
      // if (
      //   (!findLessons ||
      //     !doneResults ||
      //     findLessons.length !== doneResults?.length) &&
      //   course?.bookIncluded
      // ) {
      //   popupState.set({
      //     title: '위클리 테스트 진행',
      //     body: '회차의 레슨 진도를 모두 완료해주세요.',
      //     primaryText: '확인',
      //   });
      //   return;
      // } else if (
      //   (!findLessons ||
      //     !doneResults ||
      //     findLessons.length - (onlyWorkBook?.length || 0) !==
      //       doneResults?.length) &&
      //   !course?.bookIncluded
      // ) {
      //   popupState.set({
      //     title: '위클리 테스트 진행',
      //     body: '회차의 레슨 진도를 모두 완료해주세요.',
      //     primaryText: '확인',
      //   });
      //   return;
      // } else {
      WeeklyTest.openTest(lesson.test, student?.id, lesson.id);
      return;
      // }
    }

    if (lesson.test && lessonResult?.testDone) {
      const encodedString = Buffer.from(
        lessonResult.testId?.toString() || ''
      ).toString('base64');
      window.open(`${process.env.REACT_APP_WEEKLY_TEST_URL}${encodedString}`);
      return;
    }

    const { data: bluegaVoices } = await api.get(
      `/lesson-voices/bluega?studentId=${student?.id}&lessonId=${lesson.id}`
    );

    fn_openCornerMap(
      '/cornermap/index.html',
      {
        authToken: tokenState.get(),
        studentId: student?.id,
        lessonId: lesson.id,
        uploadUrl: `${apiUrl('lesson-voices')}`,
        title: lesson.title,
        corners: lessonToCorners(lesson, course, lessonResult, bluegaVoices),
      },
      ({ op, data }: any) => {
        if (!data) {
          window.location.replace('/learn/classroom');
        }
        if (op === CM_OP_ERROR) {
          alert('새로고침 후 다시 시도해주세요!');
        }
        if (op === CM_OP_CORNER_DONE) {
          api.post('/lesson-results', convertLessonResult(data));
          // LocalLogger.log({ data, description: 'before CM_OP_CORNER_DONE' });
          // api
          //   .post('/lesson-results', convertLessonResult(data))
          //   .then((res) => {
          //     LocalLogger.log({
          //       data: res.data,
          //       description: 'then CM_OP_CORNER_DONE',
          //     });
          //   })
          //   .catch((err) => {
          //     LocalLogger.log({
          //       data: err.message,
          //       description: 'catch CM_OP_CORNER_DONE',
          //     });
          //   });
        }
        if (op === CM_OP_LESSON_DONE && data.corners.length > 0) {
          api
            .post('/lesson-results', convertLessonDoneResult(data, true))
            .then(() => lessonResultsRefetch());
        }
      }
    );
  };

  const openBluegaTrialLesson = async (bookUrl: string) => {
    fn_openCornerMap(
      '/cornermap/index.html',
      {
        authToken: tokenState.get(),
        studentId: student?.id,
        uploadUrl: bookUrl,
        directMode: trialBook?.talk !== bookUrl ? 'REVIEW' : 'ANSWER',
        corners: [
          {
            ActiveID:
              trialBook?.talk === bookUrl
                ? '9'
                : trialBook?.story === bookUrl
                ? '2'
                : '1',
            url: bookUrl,
          },
        ],
      },
      ({ op, data }: any) => {
        if (!data) {
          window.location.replace('/learn/classroom');
        }
        if (op === CM_OP_ERROR) {
          alert('새로고침 후 다시 시도해주세요!');
        }
      }
    );
  };

  const openBluegaSpecialLesson = async (name: string, bookUrl: string) => {
    if (name === 'Worksheet') {
      window.location.href = bookUrl;
      return;
    }

    fn_openCornerMap(
      '/cornermap/index.html',
      {
        authToken: tokenState.get(),
        studentId: student?.id,
        uploadUrl: bookUrl,
        directMode: trialBook?.talk !== bookUrl ? 'REVIEW' : 'ANSWER',
        corners: [
          {
            ActiveID:
              trialBook?.talk === bookUrl
                ? '9'
                : trialBook?.story === bookUrl
                ? '2'
                : '1',
            url: bookUrl,
          },
        ],
      },
      ({ op, data }: any) => {
        if (!data) {
          window.location.replace('/learn/classroom');
        }
        if (op === CM_OP_ERROR) {
          alert('새로고침 후 다시 시도해주세요!');
        }
      }
    );
  };

  // useEffect(() => {
  //   const socket = io(`https://www.tuntunlive.com/liveSocket`);
  //   socket.on('close', () => {
  //     push('/lives/feedback');
  //   });
  //   setSocket(socket);
  //   return () => {
  //     socket.close();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (
      lives &&
      courseItem &&
      course &&
      !course.isTrial &&
      course.id === courseItem.courseId &&
      lives.length + course.remainingAmount < courseItem.number
    ) {
      popupState.set({
        title: '수강권 잔여 횟수',
        body: '잔여 수업권이 없습니다. 수강 신청을 먼저 해 주세요.',
        primaryText: '확인',
      });
    }
  }, [course, courseItem, lives]);

  return (
    <div className="flex flex-col space-y-5 flex-1">
      {/* header */}
      <div className="flex justify-between items-center">
        <div className="flex space-x-4">
          <H5>나의 강의실</H5>
          {course && (
            // 수강권이 있을 때
            <>
              <div className="bg-gray-100 w-0.5 h-auto rounded-full" />
              {course.isTrial && course.initialAmount !== 1 ? (
                <Dropdown>
                  <button className="text-20 font-bold text-gray-600 items-center flex space-x-2">
                    <span>{courseItem?.number}회차</span>
                    <ChevronDownIcon className="wh-4 text-gray-200" />
                  </button>
                  <Dropdown.View className="max-h-60 overflow-y-scroll">
                    <Menu>
                      {courseItems?.map((ci) => (
                        <Menu.Item
                          key={ci.id}
                          text={`${ci.number}회차`}
                          onClick={() => {
                            courseItemState.set(ci);
                            localStorage.setItem(
                              'courseItemId',
                              ci.id.toString()
                            );
                          }}
                        />
                      ))}
                    </Menu>
                  </Dropdown.View>
                </Dropdown>
              ) : course.isTrial ? (
                <div className="text-20 font-bold text-gray-600 items-center flex space-x-2">
                  <span>체험</span>
                </div>
              ) : (
                <Dropdown>
                  <button className="text-20 font-bold text-gray-600 items-center flex space-x-2">
                    <span>{courseItem?.number}회차</span>
                    <ChevronDownIcon className="wh-4 text-gray-200" />
                  </button>
                  <Dropdown.View className="max-h-60 overflow-y-scroll">
                    <Menu>
                      {courseItems?.map((ci) => (
                        <Menu.Item
                          key={ci.id}
                          text={`${ci.number}회차`}
                          onClick={() => {
                            courseItemState.set(ci);
                            localStorage.setItem(
                              'courseItemId',
                              ci.id.toString()
                            );
                          }}
                        />
                      ))}
                    </Menu>
                  </Dropdown.View>
                </Dropdown>
              )}
            </>
          )}
        </div>
        <Button
          text="수업 환경 테스트"
          className="bg-white rounded-md text-primary-1 font-bold md:px-6 h-10"
          onClick={() => testPageCall()}
        />
      </div>

      {/* main - first row */}
      <Section className="grid md:grid-cols-2 gap-6">
        {/* 교재 카드 */}
        <div className="bg-white rounded-md flex flex-col justify-between p-5">
          {course?.startLevel?.classType !== ClassType.SPECIAL &&
          (!testResult || !student?.isTested) ? (
            // 레벨테스트 하기 전
            <>
              <div className="flex-1 flex flex-col justify-center items-center my-10">
                <CharacterSvg />
                <p className="text-gray-700 text-center">
                  레벨테스트 후<br /> 학습할 교재가 결정됩니다.
                </p>
              </div>
              <div className="space-y-3">
                <Button
                  text="레벨테스트 응시하기"
                  className="filled-primary-1 w-full"
                  onClick={selectLevelPopup}
                />
                <Button
                  text="레벨테스트 없이 바로 체험 수업 예약하기"
                  className="bg-secondary-1 text-primary-1 w-full"
                  onClick={() => {
                    const isTesting = localStorage.getItem('leveltest_ready');

                    if (isTesting) {
                      const isSkip = window.confirm(
                        '진행 중인 레벨테스트를 완료하지 않고 레벨테스트 미진행으로 체험 수업 예약을 진행하시겠습니까?'
                      );
                      if (isSkip) {
                        localStorage.removeItem('leveltest_ready');
                        selectLevelNoTestPopup();
                        // skipLevelTestPopup(student?.id);
                      } else {
                        return;
                      }
                    } else {
                      selectLevelNoTestPopup();
                      // skipLevelTestPopup(student?.id);
                    }
                  }}
                />
              </div>
            </>
          ) : (
            // 레벨테스트 완료 후
            <>
              {course?.isTrial &&
              course.initialAmount === 1 &&
              course?.startLevel?.classType !== ClassType.SPECIAL ? (
                <>
                  <div className="self-center relative my-10 w-24 h-36 rounded-md overflow-hidden shadow-sm">
                    <img
                      src={apiUrl(trialBook?.cover)}
                      alt={trialBook?.title}
                      className="w-full h-full absolute object-fill"
                    />
                  </div>
                  <span className="font-bold text-gray-700 py-3">
                    {entrySeries}
                  </span>
                  <div className="flex flex-col space-y-6">
                    <div
                      className="flex space-x-2 cursor-pointer items-center"
                      onClick={() => {
                        if (trialBook?.story) {
                          openBluegaTrialLesson(trialBook.story);
                        }
                      }}
                    >
                      <p>Story</p>
                      <div className="grid place-items-center">
                        <LessonChevron />
                      </div>
                    </div>
                    {trialBook?.seriesId === 14 ||
                    trialBook?.seriesId === 18 ? (
                      ''
                    ) : (
                      <div
                        className="flex space-x-2 cursor-pointer items-center"
                        onClick={() => {
                          if (trialBook?.song) {
                            openBluegaTrialLesson(trialBook.song);
                          }
                        }}
                      >
                        <p>Song</p>
                        <div className="grid place-items-center">
                          <LessonChevron />
                        </div>
                      </div>
                    )}

                    <div
                      className="flex space-x-2 cursor-pointer items-center"
                      onClick={() => {
                        if (trialBook?.talk) {
                          openBluegaTrialLesson(trialBook.talk);
                        }
                      }}
                    >
                      <p>Talk+</p>
                      <div className="grid place-items-center">
                        <LessonChevron />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {(courseItem?.liveClass?.level?.id === 3 ||
                    courseItem?.liveClass?.level?.id === 4) && (
                    <div className=" bg-secondary-1 text-15 font-semibold text-primary-1 px-4 py-3 rounded-lg text-center">
                      <span>
                        ※ 3,4 단계에서는 한 권의 교재로
                        <br />
                        2번의 화상수업을 진행합니다.
                      </span>
                    </div>
                  )}
                  <div className="w-1/2 mx-auto my-10 rounded-md overflow-hidden shadow-sm">
                    <img
                      src={apiUrl(book?.cover)}
                      alt={book?.title}
                      className="w-full h-full"
                    />
                  </div>
                  <span className="font-bold text-gray-700 py-3">
                    {book?.title}
                  </span>
                  {courseItem?.liveClass?.level?.id === 1 ||
                  courseItem?.liveClass?.level?.id === 2 ||
                  courseItem?.liveClass?.level?.id === 3 ||
                  courseItem?.liveClass?.level?.id === 4 ? (
                    <>
                      <div className="flex flex-col space-y-6">
                        {lessons
                          ?.sort((a, b) => a.index - b.index)
                          ?.filter((lesson) => {
                            if (!course?.bookIncluded) {
                              const onlyWorkBook =
                                !lesson.learn &&
                                !lesson.phonics &&
                                !lesson.story &&
                                !lesson.talk &&
                                !lesson.test &&
                                !lesson.words &&
                                lesson.workbook;
                              return !onlyWorkBook;
                            } else {
                              return lesson;
                            }
                          })
                          ?.map((lesson) => {
                            const lessonResult = lessonResults?.find(
                              (result) => result.lessonId === lesson.id
                            );
                            return (
                              <>
                                <div
                                  key={lesson.id}
                                  className="flex space-x-2 cursor-pointer"
                                  onClick={async () => {
                                    if (!course?.isOpen) {
                                      popupState.set({
                                        title: '유효기간 안내',
                                        body: '수강권 구매 후 아래의 경우 중 하나를 시작한 때부터 유효기간이 기산됩니다.',
                                        secondBody: `1. 디지털 예습 콘텐츠를 첫 열람시\n2. 첫 수업 예약시\n\n[정규]\n* 12회: 20주\n* 24회: 40주\n\n[스피킹]\n* 4회: 6주\n* 8회: 12주\n* 12회: 18주\n\n* 학습 시작 전 ‘로그인>부모님>공지사항’을 정독하시면\n앞으로의 학습에 많은 참고가 될 거예요😀\n`,
                                        primaryText: '수강 시작하기',
                                        primaryClick: () =>
                                          api
                                            .patch(
                                              `/courses/${course?.id}/validity`
                                            )
                                            .then(() => {
                                              return openBluegaLesson(lesson);
                                            }),
                                      });
                                    } else {
                                      return openBluegaLesson(lesson);
                                    }
                                  }}
                                >
                                  <div
                                    className={`wh-6 rounded-full grid place-items-center ${
                                      lessonResult?.isCompleted ||
                                      lessonResult?.testDone
                                        ? 'bg-primary-3'
                                        : 'bg-gray-200'
                                    }`}
                                  >
                                    <LessonBullet />
                                  </div>
                                  <p>{lesson.title}</p>
                                  <div className="grid place-items-center">
                                    <LessonChevron />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  ) : course?.startLevel.classType === ClassType.SPECIAL ? (
                    <>
                      <div className="flex flex-col space-y-6">
                        {book?.bookItems?.map((bookItem) => {
                          return (
                            <>
                              <div
                                className="flex space-x-2 cursor-pointer items-center"
                                onClick={() => {
                                  openBluegaSpecialLesson(
                                    bookItem.name,
                                    bookItem.url
                                  );
                                }}
                              >
                                <p>{bookItem.name}</p>
                                <div className="grid place-items-center">
                                  <LessonChevron />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </>
              )}

              {!course && (
                // 아무 수강권이 없을 때
                <p className="text-center text-gray-700">
                  아직 수강권이 없습니다. <br /> 해당 교재로 체험 수업을
                  예약해보세요.
                </p>
              )}

              {!course && (
                // 아무 수강권이 없을 때
                <div className="flex flex-col space-y-3 mt-10 w-full">
                  <Button
                    text="체험수업 하기"
                    to="/pricing/trial"
                    className="filled-primary-1 font-bold"
                  />
                  <Button
                    text="체험 없이 바로 수강하기"
                    to="/pricing/regular"
                    className="bg-secondary-1 text-primary-1 font-bold"
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* 라이브 카드 */}
        <div className="bg-white rounded-md flex flex-col justify-between p-5">
          {!live ? (
            // 라이브 수업을 예약하지 않았을 때
            <>
              <div className="flex-1 flex flex-col justify-center items-center my-10">
                <WarningSvg />
                <p className="text-gray-700 text-center mt-5">
                  아직 예약된 수업이 없습니다. <br /> 수강신청을 해주세요.
                </p>
              </div>
              <Button
                text="수업 예약하기"
                className="bg-secondary-1 text-primary-1 w-full"
                onClick={() =>
                  course?.startLevel?.classType !== ClassType.SPECIAL &&
                  (!testResult || !student?.isTested)
                    ? requireLevelTestPopup(student?.id)
                    : !course
                    ? requirePurchasePopup()
                    : push('/learn/reservation')
                }
              />
            </>
          ) : (
            // 라이브 수업 예약 후
            <>
              <Section className="flex space-x-4">
                <Avatar
                  src={apiUrl(live.teacher.avatar)}
                  className="wh-16 object-fill"
                />
                <div className="flex flex-col">
                  <div className="text-12 px-2 py-1 bg-secondary-2 text-primary-2 rounded-md w-max">
                    {liveStateToText(live.state)}
                  </div>
                  <span className="text-17 font-bold font-nanum pt-2">
                    {live.teacher.englishName} 선생님
                  </span>
                  {live.state === LiveState.RESERVED && (
                    <span className="text-13 text-gray-500">
                      {live.teacher.englishName} 선생님과의{' '}
                      {teacherLives && teacherLives.length > 0 && liveIndex
                        ? teacherLives.length - liveIndex
                        : teacherLives && teacherLives.length > 0
                        ? teacherLives.length
                        : 1}
                      번째 수업이에요!
                    </span>
                  )}
                </div>
              </Section>
              <div className="border border-gray-50 -mx-5 my-5" />

              <Section className="space-y-4">
                <span className="text-13 text-gray-500">수업 일시</span>
                <H6>{moment(live.start).format('MMM Do (dd) LT')}</H6>

                <div className="flex space-x-2 items-center pb-8 text-12">
                  *수업 시간 10분 후 강의실 입장은 불가능하며,
                  <br /> 입장하지 않은 경우 노쇼로 간주되어 수업권이 차감됩니다.
                </div>
              </Section>

              <div className="flex-1" />
              <Button
                disabled={checkTime > now || now > end || !live.pageCallRoomId}
                text={
                  now > end
                    ? '수업 종료'
                    : latestLive?.id === live.id
                    ? '강의실 입장'
                    : '수업 예정'
                }
                className="filled-primary-1"
                onClick={() =>
                  popupState.set({
                    title: '강의실 입장하기',
                    body: `${live.teacher.englishName} 선생님과의 수업을 시작하시겠습니까?\n입장하기 버튼을 누르면 화상수업 페이지가 열립니다.`,
                    primaryText: '수업 입장하기',
                    primaryClick: () => enterLive(),
                  })
                }
              />
            </>
          )}
        </div>
      </Section>

      {/* main - second row */}
      <Section className="grid md:grid-cols-2 gap-6">
        <div className="hidden md:block" />

        {/* 학습 레포트 카드 */}
        {live && (
          // 예약한 라이브 수업이 있을 때
          <div className="bg-white rounded-md items-center justify-center flex flex-col p-5">
            <span className="text-17 font-bold font-nanum">
              {liveFeedback
                ? `이번주 수업에서 ${student?.name} 학생은!`
                : '학습 레포트'}
            </span>
            {liveFeedback ? (
              <div className="flex space-x-2 pt-4">
                <ReactStars
                  count={5}
                  size={18}
                  edit={false}
                  value={liveFeedback.totalRate + 1}
                />
              </div>
            ) : !liveFeedback && live.state === LiveState.ATTENDED ? (
              <span className="text-15 text-gray-700 pt-4 text-center">
                선생님이 레포트를 작성하고 있습니다.
                <br />
                잠시만 기다려주세요.
              </span>
            ) : (
              <span className="text-15 text-gray-700 pt-4">
                수업을 완료해야 레포트가 생성됩니다.
              </span>
            )}
            <Button
              disabled={!liveFeedback}
              text="레포트 보기"
              className="bg-secondary-1 text-primary-1 disabled:bg-gray-50 disabled:text-gray-600 disabled:opacity-50  font-bold w-full mt-10"
              onClick={() => {
                if (
                  course?.startLevel?.classType !== ClassType.SPECIAL &&
                  (!testResult || !student?.isTested)
                ) {
                  requireLevelTestPopup(student?.id);
                } else {
                  push(`/learn/lives/${live.id}/report`);
                }
              }}
            />
          </div>
        )}
      </Section>
    </div>
  );
};
