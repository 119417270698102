import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Level, Paginated, Series, TeacherMaterial } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { AdminSeriesPopUp } from '../components/AdminSeriesPopUp';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';

export const TeacherMaterials = () => {
  const { push } = useHistory();
  const [getLevels, setGetLevels] = useState<any>();
  const [selectedLevel, setSelectedLevel] = useState<Level>();
  const [selectedSeries, setSelectedSeries] = useState<Series>();
  const [seriesToEdit, setSeriesToEdit] = useState<Series>();

  // levels
  const levelQuery = useQueryString({
    sort: { index: 'ASC' },
    join: ['serieses'],
  });
  const { data: levels, refetch: refetchLevels } = useQuery<Paginated<Level>>(
    `/admin/levels${levelQuery}`,
    fetcher
  );

  useEffect(() => setSelectedLevel(levels?.items[0]), [levels]);
  useEffect(
    () => setSelectedSeries(selectedLevel?.serieses[0]),
    [selectedLevel]
  );

  // teacherMaterial
  const teacherMaterialQuery = useQueryString({
    filter: { seriesId: selectedSeries?.id },
  });
  const { data: teacherMaterials } = useQuery<Paginated<TeacherMaterial>>(
    `/admin/teacher-materials${teacherMaterialQuery}`,
    fetcher,
    { enabled: !!selectedSeries, keepPreviousData: true }
  );

  useEffect(() => {
    const materialLevels = localStorage.getItem('materialLevels');
    if (levels?.items.length === 0 && materialLevels) {
      const parseMaterialLevels = JSON.parse(materialLevels);
      setGetLevels(parseMaterialLevels);
    }
    const materialSelectLevel = localStorage.getItem('materialSelectedLevel');
    if (materialSelectLevel) {
      setSelectedLevel(JSON.parse(materialSelectLevel));
    } else {
      localStorage.setItem('materialLevels', JSON.stringify(levels));
      setSelectedLevel(levels?.items[0]);
    }
  }, [levels]);

  useEffect(() => {
    const materialSelectSeries = localStorage.getItem('materialSelectedSeries');
    if (materialSelectSeries) {
      setSelectedSeries(JSON.parse(materialSelectSeries));
    } else {
      setSelectedSeries(selectedLevel?.serieses[0]);
    }
  }, [selectedLevel]);

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>수업자료실</AdminH1>
        <Button
          text="추가"
          to={'/admin/teacher-materials/add'}
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <div className="flex justify-between items-center">
        <AdminTabs>
          {getLevels
            ? getLevels?.items?.map((level: any) => (
                <AdminTab
                  key={level.id}
                  text={level.name}
                  selected={level.id === selectedLevel?.id}
                  onClick={() => {
                    refetchLevels();
                    localStorage.setItem(
                      'materialSelectedLevel',
                      JSON.stringify(level)
                    );
                    setSelectedLevel(level);
                  }}
                />
              ))
            : levels?.items.map((level) => (
                <AdminTab
                  key={level.id}
                  text={level.name}
                  selected={level.id === selectedLevel?.id}
                  onClick={() => {
                    refetchLevels();
                    localStorage.setItem(
                      'materialLevels',
                      JSON.stringify(levels)
                    );
                    localStorage.setItem(
                      'materialSelectedLevel',
                      JSON.stringify(level)
                    );
                    setSelectedLevel(level);
                  }}
                />
              ))}
        </AdminTabs>
      </div>

      <div className="flex items-start space-x-4">
        <AdminCard className="px-6 py-4">
          <div className="mb-4 flex justify-between items-center space-x-8">
            <h3 className="font-bold text-18">시리즈 관리</h3>
            <button
              className="px-4 py-2 rounded bg-gray-50"
              onClick={() =>
                setSeriesToEdit({ levelId: selectedLevel?.id } as Series)
              }
            >
              추가
            </button>
          </div>

          {selectedLevel?.serieses.map((series) => (
            <div
              key={series.id}
              className="flex justify-between items-center my-1 cursor-pointer"
              onClick={() => {
                localStorage.setItem(
                  'materialSelectedSeries',
                  JSON.stringify(series)
                );
                setSelectedSeries(series);
                push('/admin/teacher-materials?page=1&limit=10');
              }}
            >
              <span
                className={`text-15 ${
                  series.id === selectedSeries?.id
                    ? 'font-bold'
                    : 'text-gray-500'
                }`}
              >
                {series.name}
              </span>
              <button
                className="text-12 text-gray-500 hover:text-gray-800"
                onClick={() => setSeriesToEdit(series)}
              >
                수정
              </button>
            </div>
          ))}
        </AdminCard>

        <AdminCard className="flex-1">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Th>작성일</Table.Th>
                <Table.Th>타이틀</Table.Th>
                <Table.Th>숨김여부</Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {teacherMaterials?.items.map((teacherMaterial) => (
                <Table.Row key={teacherMaterial.id}>
                  <Table.Td>
                    {utcToLocalFormat(
                      teacherMaterial.createdAt,
                      MomentFormat.YYYYMMDD
                    )}
                  </Table.Td>
                  <Table.Td>{teacherMaterial.title}</Table.Td>
                  <Table.Td>{teacherMaterial.isHide ? 'O' : 'X'}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/teacher-materials/${teacherMaterial.id}`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Pagination>
            <Pagination.Label
              count={teacherMaterials?.items.length || 0}
              total={teacherMaterials?.total || 0}
            />
            <Pagination.Nav
              basePath="/admin/teacher-materials"
              total={teacherMaterials?.total || 0}
            />
          </Pagination>
        </AdminCard>
      </div>

      {seriesToEdit && (
        <AdminSeriesPopUp
          key={seriesToEdit.id}
          series={seriesToEdit}
          onClose={() => {
            setSeriesToEdit(undefined);
            refetchLevels();
          }}
        />
      )}
    </>
  );
};
