import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { api } from '../../plugins/axios';
import { fetcher } from '../../plugins/react-query';
import { Series, TeacherMaterial } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';
import { Select } from '../../components/Select';
import { Editor, EditorView } from '../../plugins/froala';
import { Checkbox } from '../../components/Checkbox';

interface FormValues {
  title: string;
  content: string;
  isHide: boolean;
  seriesId: number;
}

export const TeacherMaterialDetails = () => {
  const { push } = useHistory();
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams<{ id: string }>();
  const addMode = id === 'add';

  const { data: serieses } = useQuery<Series[]>('/admin/series/all', fetcher);
  const { data: teacherMaterial, refetch } = useQuery<TeacherMaterial>(
    `/admin/teacher-materials/${id}`,
    fetcher,
    { enabled: !addMode }
  );

  const {
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!addMode) return;
    if (!serieses || serieses.length === 0) return;
    setValue('seriesId', serieses[0].id);
  }, [setValue, serieses, addMode]);

  useEffect(() => {
    if (!teacherMaterial) return;
    setValue('title', teacherMaterial.title);
    setValue('content', teacherMaterial.content);
    setValue('isHide', teacherMaterial.isHide);
    setValue('seriesId', teacherMaterial.seriesId);
  }, [setValue, teacherMaterial, editMode]);

  return (
    <>
      <AdminH1>수업자료실</AdminH1>

      <AdminCard>
        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <Select
            label="시리즈"
            value={watch('seriesId')}
            disabled={!addMode && !editMode}
            onChange={(e) => setValue('seriesId', Number(e.target.value))}
          >
            {serieses?.map((series) => (
              <option key={series.id} label={series.name} value={series.id}>
                {series.name}
              </option>
            ))}
          </Select>
          <TextField
            label="제목"
            disabled={!addMode && !editMode}
            helper={errors.title?.message}
            {...register('title', { required: 'Please enter title.' })}
          />
        </div>

        <div className="px-4 py-6 sm:px-6 md:px-8">
          {addMode || editMode ? (
            <Editor
              model={watch('content')}
              onModelChange={(model: any) => setValue('content', model)}
            />
          ) : (
            <EditorView model={watch('content')} />
          )}
        </div>

        <div className="grid grid-cols-1 gap-6 px-4 py-6 sm:px-6 md:grid-cols-2 md:px-8">
          <Checkbox
            label="숨김처리"
            disabled={!addMode && !editMode}
            {...register('isHide')}
          />
        </div>

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          {editMode && (
            <Button
              text="취소"
              className="h-10 text-sm outlined-gray-600 hover:bg-gray-50"
              onClick={() => setEditMode(false)}
            />
          )}
          {editMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                await api.patch(`/admin/teacher-materials/${id}`, data);
                await refetch();
                setEditMode(false);
              })}
            />
          )}
          {addMode && (
            <Button
              text="저장"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={handleSubmit(async (data) => {
                const res = await api.post<TeacherMaterial>(
                  '/admin/teacher-materials',
                  data
                );
                push(`/admin/teacher-materials/${res.data.id}`);
              })}
            />
          )}
          {!addMode && !editMode && (
            <Button
              text="수정"
              className="h-10 text-sm filled-indigo-500 hover:bg-indigo-600"
              onClick={() => setEditMode(true)}
            />
          )}
        </div>
      </AdminCard>
    </>
  );
};
