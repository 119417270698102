import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Button } from '../../components/Button';
import { H6 } from '../../components/H6';
import { Table } from '../../components/Table';
import { fetcher } from '../../plugins/react-query';
import {
  Coupon,
  CouponItem,
  CouponTarget,
  CouponType,
  Paginated,
} from '../../types';
import { AdminCard } from '../components/AdminCard';

export const UserRewardsCoupons = () => {
  const { id } = useParams<{ id: string }>();

  const { data: coupons } = useQuery<Paginated<Coupon>>(
    `/admin/coupons?filter[userId]=${id}&limit=10000&join[0]=student`,
    fetcher
  );

  const { data: couponItems } = useQuery<Paginated<CouponItem>>(
    `/admin/coupon-items?filter[userId]=${id}&limit=10000&join[0]=coupon&join[1]=student`,
    fetcher
  );

  return (
    <div className="space-y-8">
      <div>
        <div className="flex items-end justify-between mb-3">
          <H6>해당 회원에게 발급 된 쿠폰</H6>
          <Button
            text="쿠폰 발급하기"
            className="px-3 py-2 text-md outlined-primary-1 hover:bg-primary-1 hover:text-white"
            to={`/admin/coupons/add?userId=${id}`}
          />
        </div>
        <AdminCard>
          <Table className="hidden md:table divide-gray-100">
            <Table.Head className="bg-transparent">
              <Table.Row>
                <Table.Th>대상</Table.Th>
                <Table.Th>자녀명</Table.Th>
                <Table.Th>쿠폰명</Table.Th>
                <Table.Th>쿠폰타입</Table.Th>
                <Table.Th>할인 금액</Table.Th>
                <Table.Th>다운로드 시작일</Table.Th>
                <Table.Th>다운로드 종료일</Table.Th>
                <Table.Th>사용 시작일</Table.Th>
                <Table.Th>사용 종료일</Table.Th>
                <Table.Th>활성화 유무</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body className="divide-gray-50">
              {coupons?.items.map((coupon) => (
                <Table.Row key={coupon.id}>
                  <Table.Td className="font-normal">
                    {coupon.target === CouponTarget.ONE ? '개인' : '전체'}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {coupon.student?.name}
                  </Table.Td>
                  <Table.Td className="font-normal">{coupon.name}</Table.Td>
                  <Table.Td className="font-normal">
                    {coupon.type === CouponType.NORMAL ? '일반' : '중복'}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {String(coupon?.discount || 0).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(coupon?.downloadStart).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(coupon?.downloadEnd).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(coupon?.useStart).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(coupon?.useEnd).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {coupon.open ? 'O' : 'X'}
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </AdminCard>
      </div>

      <div className=" space-y-3">
        <H6>회원이 다운로드 한 쿠폰</H6>
        <AdminCard>
          <Table className="hidden md:table divide-gray-100">
            <Table.Head className="bg-transparent">
              <Table.Row>
                <Table.Th>자녀명</Table.Th>
                <Table.Th>쿠폰명</Table.Th>
                <Table.Th>쿠폰타입</Table.Th>
                <Table.Th>할인 금액</Table.Th>
                <Table.Th>시용 시작일</Table.Th>
                <Table.Th>사용 종료일</Table.Th>
                <Table.Th>다운로드 일자</Table.Th>
                <Table.Th>사용 유무</Table.Th>
                <Table.Th>유효 유무</Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body className="divide-gray-50">
              {couponItems?.items.map((couponItem) => (
                <Table.Row key={couponItem.id}>
                  <Table.Td className="font-normal">
                    {couponItem.student?.name}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {couponItem.coupon.name}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {couponItem.coupon.type === CouponType.NORMAL
                      ? '일반'
                      : '중복'}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {String(couponItem?.coupon?.discount || 0).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ','
                    )}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(couponItem?.useStart).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(couponItem?.useEnd).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {moment(couponItem?.downloadedAt).format('YYYY.MM.DD')}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {couponItem.used ? 'O' : 'X'}
                  </Table.Td>
                  <Table.Td className="font-normal">
                    {couponItem.isEnabled ? 'O' : 'X'}
                  </Table.Td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </AdminCard>
      </div>
    </div>
  );
};
