import Calendar from '@toast-ui/react-calendar';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { TZDate } from 'tui-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import { api } from '../plugins/axios';
import { useWeek } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import {
  CancelType,
  ClassType,
  Live,
  LiveState,
  LiveType,
  ProductLiveType,
  Role,
  User,
  productLiveTypeName,
} from '../types';
import { Button } from './Button';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';
import { ScheduleManager } from './ScheduleManager';
import { Select } from './Select';
// import { io, Socket } from 'socket.io-client';

const MONTHLY_CUSTOM_THEME = {
  'month.weekend.backgroundColor': '#fafafa',
};

export interface ScheduleCalendarProps {
  lives?: Live[];
  onChangeMonth?: (from: Date, to: Date) => void;
  onChangeWeek?: (from: any, to: any) => void;
  onClickEnter?: (live: Live) => void;
  setType?: (type: string) => void;
  selected: boolean;
  refetch?: () => void;
}

export const ScheduleCalendar: FC<ScheduleCalendarProps> = ({
  lives,
  selected,
  onChangeMonth,
  onChangeWeek,
  setType,
  onClickEnter,
  refetch,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const calendarRef = useRef<any>(null);
  const { week, setWeek, from, to } = useWeek();
  // const [socket, setSocket] = useState<Socket | null>(null);
  const [liveId, setLiveId] = useState<number>();
  const [calendarView, setCalendarView] = useState('month');
  const [dateOfRange, setDateOfRange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimes, setSelectedTimes] = useState<Map<string, any>>(
    new Map()
  );

  const { data: me } = useQuery<User>('/users/me', fetcher);

  useEffect(() => {
    const f = new Date(dateOfRange.getFullYear(), dateOfRange.getMonth());
    const t = new Date(dateOfRange.getFullYear(), dateOfRange.getMonth() + 1);
    t.setMilliseconds(t.getMilliseconds() - 1);
    onChangeMonth?.(f, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfRange]);

  const moveRange = (action: 'prev' | 'next') => {
    const calendar = calendarRef.current?.getInstance();
    if (calendar) {
      action === 'prev' ? calendar.prev() : calendar.next();
      setDateOfRange(calendar?.getDate().toDate());
    }
  };

  useEffect(() => {
    const dateOfRange = moment().add(week, 'weeks');
    const f = dateOfRange.clone().startOf('isoWeek');
    const t = dateOfRange.clone().endOf('isoWeek').subtract(2, 'days');
    onChangeWeek?.(f, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfRange, week]);

  const selectedLives = lives?.filter(
    (live) => live.studentId && moment(live.start).isSame(selectedDate, 'day')
  );

  useEffect(() => {
    if (!lives) return;
    setSelectedTimes(
      new Map(
        lives
          .filter(
            (l) =>
              l.state === LiveState.ATTENDED ||
              l.state === LiveState.RESERVED ||
              l.state === LiveState.AVAILABLE ||
              l.state === LiveState.ABSENT_AND_NO_SHOW ||
              l.state === LiveState.NO_SHOW ||
              l.state === LiveState.ABSENT
          )
          .map((l) => [l.start, l])
      )
    );
  }, [lives]);

  const findLiveTimes = Array.from(selectedTimes.values()).filter(
    (live) =>
      live.studentId &&
      (live.state === LiveState.RESERVED ||
        live.state === LiveState.ATTENDED ||
        live.state === LiveState.ABSENT_AND_NO_SHOW ||
        live.state === LiveState.NO_SHOW ||
        live.state === LiveState.ABSENT)
  );

  // useEffect(() => {
  //   const socket = io(`https://www.tuntunlive.com/liveSocket`);
  //   socket.on('close', () => push(`/lives/feedback?liveId=${liveId}`));
  //   setSocket(socket);
  //   return () => {
  //     socket.close();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="max-w-5xl mx-auto mb-10 rounded-lg overflow-hidden bg-white">
      <div className="flex justify-between items-center px-6 py-2">
        <div className="flex items-center space-x-2">
          {calendarView === 'month' && (
            <>
              <time className="font-bold text-24">
                {dateOfRange.getFullYear()}년 {dateOfRange.getMonth() + 1}월
              </time>
              <ChevronLeftIcon
                className="p-2 w-12 h-12 rounded-lg cursor-pointer hover:bg-gray-50"
                onClick={() => moveRange('prev')}
              />
              <ChevronRightIcon
                className="p-2 w-12 h-12 rounded-lg cursor-pointer hover:bg-gray-50"
                onClick={() => moveRange('next')}
              />
            </>
          )}
        </div>

        {selected && (
          <div className="flex flex-row-reverse">
            <Select
              className="pr-8 h-12 text-sm"
              onChange={(e) => {
                setCalendarView(e.target.value);
                setType?.(e.target.value);
              }}
              value={calendarView}
            >
              <option value="month">월별</option>
              <option value="week">주간별</option>
            </Select>
            {calendarView === 'week' && me?.role === Role.ADMIN && (
              <Button
                className="h-12 rounded-lg filled-gray-800 mr-5 bg-primary-1"
                onClick={() => {
                  if (id) {
                    api
                      .post('/admin/lives/schedule', {
                        from,
                        to,
                        lives: Array.from(selectedTimes.values()),
                        teacherId: Number(id),
                      })
                      .then(() => {
                        if (refetch) {
                          alert('스케줄이 저장이 완료되었습니다.');
                          refetch();
                          push(`/admin/teachers/${id}/schedule`);
                        }
                      });
                  }
                }}
              >
                스케줄 저장하기
              </Button>
            )}
          </div>
        )}
      </div>

      {calendarView === 'month' && (
        <div className="flex">
          <div className="flex-1 border-r border-gray-100">
            <Calendar
              ref={calendarRef}
              theme={MONTHLY_CUSTOM_THEME}
              height="100%"
              calendars={[
                {
                  id: '1',
                  name: 'Schedule',
                  bgColor: '#AADDFF',
                  borderColor: '#AADDFF',
                },
              ]}
              month={{ daynames: ['일', '월', '화', '수', '목', '금', '토'] }}
              taskView={false}
              scheduleView={['time']}
              view="month"
              schedules={Array.from(
                lives
                  ?.filter((live) => live.state !== LiveState.AVAILABLE)
                  ?.reduce((prev, live) => {
                    const start = new Date(live.start);
                    start.setHours(0, 0, 0, 0);
                    const key =
                      start.toISOString() + live.state + live.liveType;
                    let count = prev.get(key)?.count ?? 0;
                    count++;
                    prev.set(key, {
                      id: key,
                      calendarId: '1',
                      category: 'time',
                      borderColor:
                        (live.state === LiveState.CANCELED ||
                          live.state === LiveState.CANCELLATION_THE_DAY) &&
                        (live.liveType === LiveType.NORMAL ||
                          live.liveType === LiveType.TRIAL)
                          ? '#f00'
                          : (live.state === LiveState.ABSENT ||
                              live.state === LiveState.ABSENT_AND_NO_SHOW ||
                              live.state === LiveState.NO_SHOW) &&
                            (live.liveType === LiveType.NORMAL ||
                              live.liveType === LiveType.TRIAL)
                          ? '#f00'
                          : (live.state === LiveState.RESERVED ||
                              live.state === LiveState.ATTENDED) &&
                            live.liveType === LiveType.NORMAL
                          ? '#2D84F0'
                          : (live.state === LiveState.RESERVED ||
                              live.state === LiveState.ATTENDED) &&
                            live.liveType === LiveType.TRIAL
                          ? '#8CD7FF'
                          : '',
                      title:
                        (live.state === LiveState.CANCELED ||
                          live.state === LiveState.CANCELLATION_THE_DAY) &&
                        live.liveType === LiveType.TRIAL
                          ? `체험 취소(${count})`
                          : (live.state === LiveState.CANCELED ||
                              live.state === LiveState.CANCELLATION_THE_DAY) &&
                            live.liveType === LiveType.NORMAL
                          ? `취소(${count})`
                          : live.state === LiveState.ABSENT &&
                            live.liveType === LiveType.TRIAL
                          ? `체험 결석(${count})`
                          : live.state === LiveState.ABSENT &&
                            live.liveType === LiveType.NORMAL
                          ? `결석(${count})`
                          : live.state === LiveState.ABSENT_AND_NO_SHOW &&
                            live.liveType === LiveType.TRIAL
                          ? `체험 결석 및 선생님노쇼(${count})`
                          : live.state === LiveState.ABSENT_AND_NO_SHOW &&
                            live.liveType === LiveType.NORMAL
                          ? `결석 및 선생님노쇼(${count})`
                          : live.state === LiveState.NO_SHOW &&
                            live.liveType === LiveType.TRIAL
                          ? `체험 선생님노쇼(${count})`
                          : live.state === LiveState.NO_SHOW &&
                            live.liveType === LiveType.NORMAL
                          ? `선생님노쇼(${count})`
                          : live.state === LiveState.ATTENDED &&
                            live.liveType === LiveType.NORMAL
                          ? `수업완료(${count})`
                          : live.state === LiveState.RESERVED &&
                            live.liveType === LiveType.NORMAL
                          ? `수업대기(${count})`
                          : live.state === LiveState.RESERVED &&
                            live.liveType === LiveType.TRIAL
                          ? `체험대기(${count})`
                          : live.state === LiveState.ATTENDED &&
                            live.liveType === LiveType.TRIAL
                          ? `체험완료(${count})`
                          : '',
                      count,
                      start,
                    });
                    return prev;
                  }, new Map<string, any>())
                  .values() ?? ([] as any)
              )}
              onClickSchedule={(s) =>
                setSelectedDate((s.schedule.start as TZDate).toDate())
              }
              onBeforeCreateSchedule={(s) =>
                setSelectedDate((s.start as TZDate).toDate())
              }
            />
          </div>

          <div className="min-w-max flex-shrink flex flex-col relative border-t border-gray-100">
            <div className="flex-none flex flex-col space-y-1 px-5 py-3 border-b border-gray-100">
              <div className="text-lg pr-24">
                {moment(selectedDate).format('L (dd)')}
              </div>
              <div className="flex space-x-2 text-sm text-gray-500">
                <span>총({selectedLives?.length ?? 0})</span>
                <span>
                  취소(
                  {selectedLives?.filter((l) => l.state === LiveState.CANCELED)
                    .length ?? 0}
                  )
                </span>
              </div>
            </div>

            <div className="flex-auto relative">
              <div className="absolute inset-0 overflow-y-scroll">
                {selectedLives
                  ?.sort((a, b) => {
                    if (a.start < b.start) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })
                  .map((live) => {
                    const now = new Date();
                    const checkTime = new Date(live?.start || '');
                    checkTime.setMinutes(checkTime.getMinutes() - 5);
                    const end = new Date(live?.end || '');
                    end.setMinutes(end.getMinutes() - 5);
                    const start = new Date(live.start);
                    start.setHours(0, 0, 0, 0);
                    const canceledAt = new Date(live.canceledAt);
                    canceledAt.setHours(0, 0, 0, 0);

                    // console.log(live?.course);
                    return (
                      <div
                        key={live.id}
                        className="border-b bg-gray-70 border-gray-100 p-4  space-y-1"
                      >
                        <div className="space-y-3">
                          <div className="flex space-x-1">
                            {(live?.course?.isTrial ||
                              live.liveType === LiveType.TRIAL) && (
                              <>
                                <div className="rounded-md border border-primary-1 text-primary-1 px-2 py-1 text-xs max-w-min">
                                  <div className="min-w-max">체험</div>
                                </div>
                                <div className="rounded-md border border-primary-1 text-primary-1 px-2 py-1 text-xs max-w-min">
                                  <div className="min-w-max">
                                    {productLiveTypeName(
                                      live?.course?.productLiveType
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {live?.course?.classType === ClassType.REGULAR && (
                              <div className="rounded-md border border-primary-1 text-primary-1 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">정규</div>
                              </div>
                            )}
                            {live?.course?.classType === ClassType.SPECIAL && (
                              <div className="rounded-md border border-primary-1 text-primary-1 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">특강</div>
                              </div>
                            )}

                            {live.state === LiveState.RESERVED && (
                              <div className="rounded-md border border-primary-1 text-primary-1 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">수업 대기</div>
                              </div>
                            )}
                            {live.state === LiveState.ATTENDED && (
                              <div className="rounded-md border border-gray-200 text-gray-300 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">수업 완료</div>
                              </div>
                            )}
                            {((live.state === LiveState.CANCELED &&
                              moment(start).isSame(canceledAt)) ||
                              live.state ===
                                LiveState.CANCELLATION_THE_DAY) && (
                              <div className="rounded-md border border-red-600 text-red-600 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">
                                  {live.cancelType === CancelType.ADMIN
                                    ? '관리자'
                                    : '학생'}{' '}
                                  당일 수업 취소
                                </div>
                              </div>
                            )}
                            {live.state === LiveState.CANCELED &&
                              !moment(start).isSame(canceledAt) && (
                                <div className="rounded-md border border-red-600 text-red-600 px-2 py-1 text-xs max-w-min">
                                  <div className="min-w-max">
                                    {live.cancelType === CancelType.ADMIN
                                      ? '관리자'
                                      : '학생'}{' '}
                                    수업 취소
                                  </div>
                                </div>
                              )}
                            {live.state === LiveState.ABSENT && (
                              <div className="rounded-md border border-red-600 text-red-600 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">결석</div>
                              </div>
                            )}
                            {live.state === LiveState.NO_SHOW && (
                              <div className="rounded-md border border-red-600 text-red-600 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">선생님 노쇼</div>
                              </div>
                            )}
                            {live.state === LiveState.ABSENT_AND_NO_SHOW && (
                              <div className="rounded-md border border-red-600 text-red-600 px-2 py-1 text-xs max-w-min">
                                <div className="min-w-max">
                                  결석 및 선생님 노쇼
                                </div>
                              </div>
                            )}
                            {live.state === LiveState.ATTENDED &&
                              me?.role !== Role.USER && (
                                <div className="rounded-md border border-yellow-500 text-yellow-500 px-2 py-1 text-xs max-w-min">
                                  <div className="min-w-max">
                                    {live?.liveFeedbacks?.length > 0
                                      ? '피드백작성'
                                      : '피드백미작성'}
                                  </div>
                                </div>
                              )}
                          </div>
                          <div className="space-y-3 flex justify-between items-center">
                            <div className="space-y-3">
                              <div className="text-base">
                                {moment(live.start).format('HH:mm')} -{' '}
                                {moment(live.end).format('HH:mm')}
                              </div>
                              <div className="space-x-1 flex">
                                <div
                                  className={`text-xs text-white mr-1 bg-primary-1 border border-primary-1 rounded-md px-2 py-1 w-max ${
                                    (pathname.startsWith('/admin') ||
                                      pathname.startsWith('/teacher')) &&
                                    ' cursor-pointer'
                                  }`}
                                  onClick={() => {
                                    if (pathname.startsWith('/admin')) {
                                      push(
                                        `/admin/users/${live.student.userId}/students?itemId=${live.studentId}`
                                      );
                                    }
                                    if (pathname.startsWith('/teacher')) {
                                      push(
                                        `/teacher/students/${live.studentId}?courseId=${live.courseId}`
                                      );
                                    }
                                  }}
                                >
                                  {me?.role === Role.USER
                                    ? `${live.teacher.englishName} 선생님`
                                    : `${live.student.name} 학생`}
                                </div>
                                {me?.role === Role.ADMIN ? (
                                  <div
                                    className={`text-xs text-white mr-1 bg-primary-1 border border-primary-1 rounded-md px-2 py-1 w-max cursor-pointer`}
                                    onClick={() => {
                                      push(
                                        `/admin/teachers/${live.teacherId}/lives`
                                      );
                                    }}
                                  >
                                    {live.teacher.englishName}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                              {me?.role === Role.ADMIN ? (
                                <div className="space-x-1 flex">
                                  <Button
                                    className="bg-primary-2 text-white text-xs p-2 rounded h-auto whitespace-pre"
                                    onClick={() => {
                                      if (!live.teacher?.pageCallAccessToken) {
                                        alert(
                                          '선생의 강의실 입장 권한이 없습니다.'
                                        );
                                        return;
                                      }

                                      if (!live.pageCallRoomId) {
                                        alert('아직 방이 생성되지 않았습니다.');
                                        return;
                                      }

                                      window.navigator.clipboard
                                        .writeText(
                                          `https://app.pagecall.net/${live.pageCallRoomId}/?access_token=${live.teacher?.pageCallAccessToken}`
                                        )
                                        .then(() => {
                                          alert(
                                            `url이 복사되었습니다.\nhttps://app.pagecall.net/${live.pageCallRoomId}/?access_token=${live.teacher?.pageCallAccessToken}`
                                          );
                                        });
                                    }}
                                  >
                                    강의실 URL 선생
                                  </Button>
                                  <Button
                                    className="bg-primary-2 text-white text-xs p-2 rounded h-auto whitespace-pre"
                                    onClick={() => {
                                      if (!live.student?.pageCallAccessToken) {
                                        alert(
                                          '회원의 강의실 입장 권한이 없습니다.'
                                        );
                                        return;
                                      }

                                      if (!live.pageCallRoomId) {
                                        alert('아직 방이 생성되지 않았습니다.');
                                        return;
                                      }

                                      window.navigator.clipboard
                                        .writeText(
                                          `https://app.pagecall.net/${live.pageCallRoomId}/?access_token=${live.student?.pageCallAccessToken}`
                                        )
                                        .then(() => {
                                          alert(
                                            `url이 복사되었습니다.\nhttps://app.pagecall.net/${live.pageCallRoomId}/?access_token=${live.student?.pageCallAccessToken}`
                                          );
                                        });
                                    }}
                                  >
                                    강의실 URL 학생
                                  </Button>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            {(me?.role === Role.TEACHER ||
                              me?.role === Role.USER) && (
                              <>
                                {live.state === LiveState.ABSENT_AND_NO_SHOW ||
                                live.state === LiveState.ABSENT ||
                                live.state === LiveState.CANCELED ||
                                live.state === LiveState.NO_SHOW ||
                                me.role === Role.USER ? (
                                  ''
                                ) : (
                                  <Button
                                    className="bg-primary-1 text-white text-sm p-2 rounded h-auto whitespace-pre"
                                    onClick={() => {
                                      // socket?.emit('join', live?.id);
                                      setLiveId(live?.id);
                                      onClickEnter?.(live);
                                    }}
                                    disabled={
                                      !live.pageCallRoomId ||
                                      checkTime > now ||
                                      now > end
                                    }
                                  >
                                    수업 입장
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      )}

      {calendarView === 'week' && (
        <div className="px-10 pb-10">
          <ScheduleManager
            from={from}
            to={to}
            onClickPrev={() => setWeek(week - 1)}
            onClickNext={() => setWeek(week + 1)}
            selectedTime={(time) => {
              return selectedTimes.has(time.toISOString());
            }}
            findLiveTimes={new Map(findLiveTimes.map((l) => [l.start, l]))}
            onClickTime={(time) => {
              if (me?.role !== Role.ADMIN) return;
              const start = time.toISOString();
              const end = time.clone().add(30, 'minutes');

              const newValue = new Map(selectedTimes);
              selectedTimes.has(start) ? (
                <>
                  {findLiveTimes.find((live) => live.start === start)
                    ? ''
                    : newValue.delete(start)}
                </>
              ) : (
                newValue.set(start, { start, end })
              );
              return setSelectedTimes(newValue);
            }}
          />
        </div>
      )}
    </div>
  );
};
