import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { ReactComponent as TimeIcon } from '../assets/svg/icon-time.svg';
import { ReactComponent as UserIcon } from '../assets/icons/icon-user.svg';
import { ReactComponent as CalendarIcon } from '../assets/icons/icon-calendar.svg';
import { Avatar } from '../components/Avatar';
import { H6 } from '../components/H6';
import { ArrowLeftIcon, ChevronRightIcon } from '../components/icons';
import { ScheduleManager } from '../components/ScheduleManager';
import { Section } from '../components/Section';
import { TeacherSelectCard } from '../components/TeacherSelectCard';
import { api, apiUrl } from '../plugins/axios';
import { useWeek } from '../plugins/moment';
import { fetcher } from '../plugins/react-query';
import {
  courseItemState,
  courseState,
  popupState,
  studentState,
  testResultState,
} from '../plugins/ridge';
import { ClassType, Live, LiveState, User } from '../types';

export const ReservationTeacherFirstPage = () => {
  const now = new Date();
  const student = studentState.useValue();
  const course = courseState.useValue();
  const courseItem = courseItemState.useValue();
  const testResult = testResultState.useValue();
  const { goBack, push } = useHistory();
  const { week, setWeek, from, to } = useWeek();
  const [mobileView, setMobileView] = useState('teacher');
  const [selectedTeacher, setSelectedTeacher] = useState<User>();
  const [selectedTime, setSelectedTime] = useState<Moment>();
  const [teacherMap, setTeacherMap] = useState<Map<string, User[]>>(new Map());

  const regularCourseYn =
    !course?.startLevel?.classType ||
    course?.startLevel?.classType === ClassType.REGULAR;
  const specialCourseYn = course?.startLevel?.classType === ClassType.SPECIAL;

  const selectedLive = selectedTeacher?.lives.find(
    (l) => l.start === selectedTime?.toISOString()
  );

  const { data: teachers } = useQuery<User[]>(
    `/users/teachers?from=${from.toISOString()}&to=${to.toISOString()}&regularCourseYn=${regularCourseYn}&specialCourseYn=${specialCourseYn}`,
    fetcher,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: lives } = useQuery<Live[]>(
    `/lives?studentId=${student?.id}`,
    fetcher,
    { enabled: !!student }
  );

  const findLives = lives?.filter(
    (live) =>
      (live.state === LiveState.RESERVED ||
        live.state === LiveState.ATTENDED) &&
      course?.id === live.courseId
  );

  // const lastLive = findLives?.sort(
  //   (a, b) => +new Date(a.start) - +new Date(b.start)
  // )[findLives?.length - 1];

  const findCourseItem = course?.courseItems.find(
    (c) => findLives && findLives?.length + 1 === c.number
  );

  useEffect(() => {
    var regularYn = course?.startLevel?.classType === ClassType.REGULAR;
    var specialYn = course?.startLevel?.classType === ClassType.SPECIAL;

    if (!teachers) return;
    const newTeacherMap = new Map<string, User[]>();
    teachers.forEach((t) => {
      // if (
      //   (specialYn && t.specialYn) ||
      //   (regularYn && t.regularYn)
      //   // course?.isTrial
      // ) {
      t.lives.forEach((l) => {
        newTeacherMap.get(l.start)?.push(t) ?? newTeacherMap.set(l.start, [t]);
      });
      // }
    });
    setTeacherMap(newTeacherMap);
  }, [teachers, course]);

  if (
    !student ||
    !course ||
    (course?.startLevel?.classType !== ClassType.SPECIAL && !testResult) ||
    !courseItem
  ) {
    return <></>;
  }

  return (
    <>
      {/* header */}
      <Section className="bg-blue-50 px-8 py-6 flex">
        <button
          className="wh-10 bg-white rounded-full grid place-content-center"
          onClick={goBack}
        >
          <ArrowLeftIcon text-gray-500 />
        </button>

        <div className="flex-1 self-center">
          <H6 className="text-center">
            {!courseItem ? (
              <>{course?.name} / 수업 예약</>
            ) : (
              <>
                {course?.name} / {findCourseItem?.number}회차 수업 예약
              </>
            )}
          </H6>
          <div className="hidden md:flex mt-4 divide-x items-center justify-center text-14 space-x-4">
            <div className="flex space-x-2">
              <CalendarIcon />
              <span
                className={`${
                  selectedTime ? 'text-gray-900' : 'text-gray-500'
                }`}
              >
                {selectedTime ? moment(selectedTime).format('LL dddd') : '날짜'}
              </span>
            </div>
            <div className="flex space-x-2 pl-4">
              <TimeIcon />
              <span
                className={`${
                  selectedTime ? 'text-gray-900' : 'text-gray-500'
                }`}
              >
                {selectedTime ? moment(selectedTime).format('LT') : '시간'}
              </span>
            </div>
            <div className="flex space-x-2 pl-4">
              <UserIcon />
              <span
                className={`${
                  selectedTeacher ? 'text-gray-900' : 'text-gray-500'
                }`}
              >
                {selectedTeacher?.englishName} 선생님
              </span>
            </div>
          </div>
        </div>

        <button
          className="hidden md:flex filled-primary-1 rounded-md px-4 py-3 self-end"
          disabled={!selectedTeacher || !selectedTime}
          onClick={() => {
            if (moment(selectedLive?.start).isSame(moment(now), 'day')) {
              popupState.set({
                title: '당일 예약은\n취소가 불가합니다.',
                body: '당일 예약의 경우 취소가 불가합니다.신중하게 예약해주세요!\n예약을 진행하시겠습니까?',
                primaryText: '예약 계속하기',
                primaryClick: () => {
                  popupState.set({
                    title: '수업 예약 확인',
                    body: '확인 후 수업 예약 완료를 눌러주세요.',
                    primaryText: '수업 예약 완료',
                    primaryClick: () => {
                      if (!course.isOpen) {
                        api
                          .patch(`/courses/${course?.id}/validity`)
                          .then(() => {
                            api
                              .post(`/lives/${selectedLive?.id}/reserve`, {
                                studentId: studentState.get()?.id,
                                courseId: courseState.get()?.id,
                              })
                              .then(() => {
                                push('/learn/lives');
                              })
                              .catch((err) => {
                                alert(err.response.data.message);
                              });
                          });
                      } else {
                        api
                          .post(`/lives/${selectedLive?.id}/reserve`, {
                            studentId: studentState.get()?.id,
                            courseId: courseState.get()?.id,
                          })
                          .then(() => {
                            push('/learn/lives');
                          })
                          .catch((err) => {
                            alert(err.response.data.message);
                          });
                      }
                    },
                  });
                },
              });
            } else {
              popupState.set({
                title: '수업 예약 확인',
                body: '확인 후 수업 예약 완료를 눌러주세요.',
                primaryText: '수업 예약 완료',
                primaryClick: () => {
                  if (!course.isOpen) {
                    api.patch(`/courses/${course?.id}/validity`).then(() => {
                      api
                        .post(`/lives/${selectedLive?.id}/reserve`, {
                          studentId: studentState.get()?.id,
                          courseId: courseState.get()?.id,
                        })
                        .then(() => {
                          push('/learn/lives');
                        })
                        .catch((err) => {
                          alert(err.response.data.message);
                        });
                    });
                  } else {
                    api
                      .post(`/lives/${selectedLive?.id}/reserve`, {
                        studentId: studentState.get()?.id,
                        courseId: courseState.get()?.id,
                      })
                      .then(() => {
                        push('/learn/lives');
                      })
                      .catch((err) => {
                        alert(err.response.data.message);
                      });
                  }
                },
              });
            }
          }}
        >
          <span className="pr-8">신청 완료</span>
          <ChevronRightIcon />
        </button>
      </Section>

      {/* mobile - tabs */}
      <div className="flex md:hidden">
        <button
          className={`py-4 flex flex-col items-center space-y-1 w-full ${
            mobileView === 'teacher'
              ? 'text-gray-900 font-semibold border-b-2 border-primary-1'
              : 'text-gray-400'
          }`}
          onClick={() => setMobileView('teacher')}
        >
          선생님 선택하기
        </button>
        <button
          className={`py-4 flex flex-col items-center space-y-1 w-full ${
            mobileView === 'time'
              ? 'text-gray-900 font-semibold border-b-2 border-primary-1'
              : 'text-gray-400'
          }`}
          onClick={() => setMobileView('time')}
        >
          시간 선택하기
        </button>
      </div>

      {/* main */}
      <div className="max-w-5xl mx-auto md:pt-8 flex space-x-0 md:space-x-8 w-full pb-24 md:pb-0">
        {/* teacher section */}
        <Section
          className={`md:block md:w-1/4 space-y-4 px-5 py-3 md:p-0 min-w-max ${
            mobileView === 'teacher' ? 'w-full' : 'hidden'
          }`}
        >
          <H6 className="hidden md:inline">선생님 선택하기</H6>
          {(teachers ?? []).slice().map((teacher) => (
            // 특강 선생 구분해야 한다면 여기 세팅
            <TeacherSelectCard
              key={teacher.id}
              teacher={teacher}
              selected={teacher.id === selectedTeacher?.id}
              onClick={() => {
                setSelectedTeacher(teacher);
                setSelectedTime(undefined);
              }}
            />
          ))}
        </Section>

        {/* time section */}
        <Section
          className={`md:block md:flex-1 px-5 py-3 md:p-0 ${
            mobileView === 'time' ? 'w-full' : 'hidden'
          }`}
        >
          {/* mobile */}
          {selectedTeacher && (
            <div className="flex md:hidden items-center justify-between w-full py-4">
              <div className="flex space-x-4">
                <Avatar
                  src={apiUrl(selectedTeacher.avatar)}
                  className="wh-16"
                />
                <H6>{selectedTeacher.englishName} 선생님</H6>
              </div>
              <button
                className="bg-gray-50 rounded-md px-3 py-2"
                onClick={() => setMobileView('teacher')}
              >
                다시 선택
              </button>
            </div>
          )}

          <ScheduleManager
            from={from}
            to={to}
            onClickPrev={() => setWeek(Math.max(week - 1, 0))}
            onClickNext={() => setWeek(week + 1)}
            selectedTime={(time) => time.isSame(selectedTime)}
            disabledTime={(time) => {
              const reservedLive = findLives?.find((live) => {
                return time.isSame(live?.start);
              });
              return (
                teacherMap.get(time.toISOString())?.every(
                  (t) =>
                    t.id !== selectedTeacher?.id ||
                    time.isSame(reservedLive?.start) ||
                    time.isSame(moment(now), 'day') ||
                    // moment(lastLive?.start ?? '') >= time ||
                    time < moment(now) ||
                    (course.isTrial &&
                      course.initialAmount > 1 &&
                      moment(course?.endedAt).add(23, 'hour') < time) ||
                    (!course.isTrial &&
                      moment(course?.endedAt).add(23, 'hour') < time)
                ) ?? true
              );
            }}
            onClickTime={(time) => setSelectedTime(time)}
          />
        </Section>
      </div>

      {/* mobile - bottom fixed button */}
      <div
        className="fixed md:hidden bottom-0 left-0 right-0 bg-primary-1 py-5 cursor-pointer"
        style={{ zIndex: 1000 }}
        onClick={() => {
          const now = new Date().getDate();
          const start = new Date(selectedLive?.start || '').getDate();

          if (mobileView === 'teacher') {
            setMobileView('time');
          } else {
            // go to next
            if (now === start) {
              popupState.set({
                title: '당일 예약은\n취소가 불가합니다. ',
                body: '당일 예약의 경우 취소가 불가합니다.신중하게 예약해주세요!\n예약을 진행하시겠습니까?',
                primaryText: '예약 계속하기',
                primaryClick: () => {
                  popupState.set({
                    title: '수업 예약 확인',
                    body: '확인 후 수업 예약 완료를 눌러주세요.',
                    primaryText: '수업 예약 완료',
                    primaryClick: () => {
                      if (!course.isOpen) {
                        api
                          .patch(`/courses/${course?.id}/validity`)
                          .then(() => {
                            api
                              .post(`/lives/${selectedLive?.id}/reserve`, {
                                studentId: studentState.get()?.id,
                                courseId: courseState.get()?.id,
                              })
                              .then(() => {
                                push('/learn/lives');
                              })
                              .catch((err) => {
                                alert(err.response.data.message);
                              });
                          })
                          .catch((err) => {
                            alert(err.response.data.message);
                          });
                      } else {
                        api
                          .post(`/lives/${selectedLive?.id}/reserve`, {
                            studentId: studentState.get()?.id,
                            courseId: courseState.get()?.id,
                          })
                          .then(() => {
                            push('/learn/lives');
                          })
                          .catch((err) => {
                            alert(err.response.data.message);
                          });
                      }
                    },
                  });
                },
              });
            } else {
              popupState.set({
                title: '수업 예약 확인',
                body: '확인 후 수업 예약 완료를 눌러주세요.',
                primaryText: '수업 예약 완료',
                primaryClick: () => {
                  if (!course.isOpen) {
                    api.patch(`/courses/${course?.id}/validity`).then(() => {
                      api
                        .post(`/lives/${selectedLive?.id}/reserve`, {
                          studentId: studentState.get()?.id,
                          courseId: courseState.get()?.id,
                        })
                        .then(() => {
                          push('/learn/lives');
                        })
                        .catch((err) => {
                          alert(err.response.data.message);
                        });
                    });
                  } else {
                    api
                      .post(`/lives/${selectedLive?.id}/reserve`, {
                        studentId: studentState.get()?.id,
                        courseId: courseState.get()?.id,
                      })
                      .then(() => {
                        push('/learn/lives');
                      })
                      .catch((err) => {
                        alert(err.response.data.message);
                      });
                  }
                },
              });
            }
          }
        }}
      >
        <div className="w-full h-full flex space-x-6 justify-center items-center pb-3">
          <span className="text-white font-bold text-lg">
            {mobileView === 'teacher' ? '다음' : '신청 완료'}
          </span>
          <ChevronRightIcon className="text-white" />
        </div>
      </div>
    </>
  );
};
