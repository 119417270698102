import React, { ButtonHTMLAttributes, FC, useState } from 'react';
import { H4 } from '../components/H4';
import { Label } from '../components/Label';
import { TextField } from '../components/TextField';
import { Button } from '../components/Button';
import { Gender, Student } from '../types';
import { useForm } from 'react-hook-form';
import { DateSelect } from '../components/DateSelect';
import { api } from '../plugins/axios';
import { useHistory } from 'react-router';
import { useQuery } from 'react-query';
import { fetcher } from '../plugins/react-query';
import { MobileBackButton } from '../components/MobileBackButton';
import { Avatar } from '../components/Avatar';
import { PlusIcon } from '../components/icons';
import { resizeImage } from '../plugins/compress';

interface FormValues {
  avatar: File;
  name: string;
  dob: string;
  gender: Gender;
}

export const StudentsAddPage = () => {
  const { push } = useHistory();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ defaultValues: { gender: Gender.MALE } });
  const { data: students } = useQuery<Student[]>(`/students`, fetcher);

  const add = !isCompleted
    ? handleSubmit((data) => {
        const formData = new FormData();
        Object.entries(data).forEach(([name, value]) =>
          formData.append(name, value)
        );
        api
          .post('/students', formData)
          .then(async () => {
            setIsCompleted(true);
            if (students?.length === 2) {
              push('/learn');
            } else {
              window.location.reload();
            }
          })
          .catch((err) => console.error(err));
      })
    : (e: any) => {
        e.preventDefault();
        alert('이미 등록된 자녀입니다.');
      };

  if (students?.length === 3) {
    push('/students');
  }

  return (
    <div className="max-w-sm mx-auto md:pt-14 px-4">
      <MobileBackButton />

      <H4>자녀 등록하기</H4>

      <form
        className="flex flex-col space-y-8 pt-9"
        onSubmit={
          !isCompleted
            ? handleSubmit((data) => {
                const formData = new FormData();
                Object.entries(data).forEach(([name, value]) =>
                  formData.append(name, value)
                );
                api
                  .post('/students', formData)
                  .then(async () => {
                    setIsCompleted(true);
                    if (students?.length === 2) {
                      push('/learn');
                    } else {
                      window.confirm(
                        '다른 자녀를 추가하지 않고 나의 학습페이지로 이동하시겠습니까?'
                      )
                        ? push('/pricing/trial')
                        : window.location.reload();
                    }
                  })
                  .catch((err) => console.error(err));
              })
            : (e) => {
                e.preventDefault();
                alert('이미 등록된 자녀입니다.');
              }
        }
      >
        <label htmlFor="avatar" className="relative w-max cursor-pointer">
          <Avatar
            src={watch('avatar') ? URL.createObjectURL(watch('avatar')) : ''}
            className="wh-20 object-cover"
          />
          <div className="absolute right-0 bottom-0 bg-primary-1 rounded-full wh-7 grid place-content-center">
            <PlusIcon className="text-white fill-current wh-3" />
          </div>
          <input
            id="avatar"
            type="file"
            accept="image/*"
            className="sr-only"
            onChange={async (e) => {
              const file = e.target.files?.item(0);
              if (!file || !e.target.validity.valid) return;
              setValue('avatar', await resizeImage(file));
            }}
          />
        </label>
        <TextField
          label="자녀 이름*"
          placeholder="자녀 이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', {
            required: '자녀 이름을 입력해주세요.',
          })}
        />

        <div className="flex flex-col space-y-2">
          <DateSelect
            label="자녀 생년월일*"
            onChange={(value) => setValue('dob', value.toLocaleDateString())}
          />
        </div>

        <div className="flex flex-col space-y-2">
          <Label text="자녀 성별*" />
          <div className="flex space-x-4">
            <Tab
              selected={watch('gender') === Gender.MALE}
              onClick={() => setValue('gender', Gender.MALE)}
              title="남자"
            />
            <Tab
              selected={watch('gender') === Gender.FEMALE}
              onClick={() => setValue('gender', Gender.FEMALE)}
              title="여자"
            />
          </div>
        </div>

        <Button
          className="filled-primary-1"
          text="등록 완료"
          disabled={!watch('name') || !watch('dob') || !watch('gender')}
        />
      </form>

      {students && students?.length < 2 && (
        <Button
          onClick={(e) => {
            students?.length === 3
              ? alert('자녀 등록은 최대 3명까지만 가능합니다.')
              : add(e);
          }}
          className="bg-secondary-1 text-primary-1 w-full mt-4"
          text="다음 자녀 등록하기"
        />
      )}
    </div>
  );
};

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  title: string;
}

export const Tab: FC<TabProps> = ({ selected, title, ...props }) => {
  return (
    <button
      type="button"
      className={`font-bold px-4 py-3 rounded-lg flex flex-col items-center space-y-1 ${
        selected ? 'filled-primary-1' : 'bg-gray-50'
      } `}
      {...props}
    >
      <p className={`${selected ? 'text-white' : 'text-gray-400'}`}>{title}</p>
    </button>
  );
};
