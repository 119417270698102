import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, PayItem, PayItemType } from '../../types';
import { AdminH1 } from '../components/AdminH1';
import { Button } from '../../components/Button';
import { AdminCard } from '../components/AdminCard';
import { Table } from '../../components/Table';
import { Pagination } from '../../components/Pagination';
import { AdminPayItemPopUp } from '../components/AdminPayItemPopUp';

export const TeacherTransactionIncentiveDetails = () => {
  const { id, payId } = useParams<{ id: string; payId: string }>();
  const queryString = useQueryString({
    filter: { teacherId: id, payId, type: PayItemType.INCENTIVE },
    join: ['live', 'teacher', 'live.student', 'live.course'],
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [type, setType] = useState<PayItemType>(PayItemType.LIVE_SETTLEMENT);

  const { data, refetch } = useQuery<Paginated<PayItem>>(
    `/admin/pay-items${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <>
      <div className="space-y-28">
        <div className="space-y-4">
          <div className="flex justify-between">
            <AdminH1>장려금 내역</AdminH1>
            <Button
              className="bg-primary-1 text-white"
              onClick={() => {
                setIsOpen(true);
                setType(PayItemType.INCENTIVE);
              }}
            >
              장려금 추가하기
            </Button>
          </div>
          <AdminCard>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.Th>생성일자</Table.Th>
                  <Table.Th>장려금 비용</Table.Th>
                  <Table.Th>세부내용</Table.Th>
                  {/* <Table.Th>담당자명</Table.Th> */}
                </Table.Row>
              </Table.Head>
              <Table.Body>
                {data?.items
                  ?.sort((a, b) => b.id - a.id)
                  ?.map((incentive) => (
                    <Table.Row key={incentive?.id}>
                      <Table.Td>
                        {utcToLocalFormat(
                          incentive?.createdAt || '',
                          MomentFormat.YYYYMMDDHmm
                        )}
                      </Table.Td>
                      <Table.Td>{incentive?.value}</Table.Td>
                      <Table.Td>{incentive?.memo}</Table.Td>
                      {/* <Table.Td>{incentive?.manager}</Table.Td> */}
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
            <Pagination>
              <Pagination.Label
                count={data?.items?.length || 0}
                total={data?.total || 0}
              />
              <Pagination.Nav
                basePath={`/admin/teachers/${id}/transactions/${payId}/incentive`}
                total={data?.total || 0}
              />
            </Pagination>
          </AdminCard>
        </div>
      </div>
      {isOpen && (
        <AdminPayItemPopUp
          teacherId={id}
          payId={payId}
          type={type}
          onClose={() => setIsOpen(false)}
          refetch={() => {
            refetch();
          }}
        />
      )}
    </>
  );
};
