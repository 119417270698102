import React, { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helper?: string;
  wrapClassName?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ className = '', label, helper, wrapClassName, ...props }, ref) => {
    const [id] = useId(1, 'textfield');

    return (
      <div className={`label-col ${wrapClassName}`}>
        {label && <Label htmlFor={id} text={label} />}
        <input
          ref={ref}
          id={id}
          className={`textfield ${helper ? 'border-error' : ''} ${className}`}
          {...props}
        />
        {helper && <p className="text-sm text-error">{helper}</p>}
      </div>
    );
  }
);
