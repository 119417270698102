import React, { FC, HTMLAttributes } from 'react';
import { Level, Product, Reinforce } from '../types';
import { H6 } from './H6';

interface PricingCardProps extends HTMLAttributes<HTMLDivElement> {
  product?: Product;
  reinforce?: Reinforce;
  nextLevel?: Level;
  selected: boolean;
  isReinforce?: boolean;
}

export const PricingCard: FC<PricingCardProps> = ({
  product,
  selected,
  reinforce,
  nextLevel,
  isReinforce,
  ...props
}) => {
  // if (nextLevel?.index !== 2 && product?.amount === 6) return <></>;
  return (
    <div
      className={`border-2 rounded-lg p-5 cursor-pointer ${
        selected ? 'border-primary-1' : 'border-gray-50'
      }`}
      {...props}
    >
      {isReinforce ? (
        <div className="flex justify-between items-center">
          <H6>{`보강권 ${reinforce?.amount}회`} </H6>
          <p className="font-nanum text-17">
            {(reinforce?.price ?? '')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            원
          </p>
        </div>
      ) : (
        <>
          {product?.discountRate !== undefined && product.discountRate > 0 && (
            <div className="flex justify-between">
              <p className="text-12 text-primary-1 font-bold">
                {product.discountRate}% 할인
              </p>

              <p className="text-12 text-gray-400 line-through text-right">
                {product.originalPrice
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </p>
            </div>
          )}

          <div className="flex justify-between items-center">
            <H6>{product?.name ?? '선택 안함'}</H6>
            <p className="font-nanum text-17">
              {(product?.finalPrice ?? 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              원
            </p>
          </div>

          {product?.description && (
            <div className="mt-2">
              {product.description.split('\n').map((p) => (
                <div
                  key={p}
                  className="text-13 text-gray-500 flex items-center space-x-2"
                >
                  <span>•</span>
                  <p>{p}</p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
