import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import character from '../../assets/img/button_character.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { ChevronDownIcon } from '../../components/icons';

const data = [
  {
    id: 1,
    animateIn: 'animate__fadeInRight',
    src: 'https://www.youtube.com/embed/VvjknydTej0',
    teacherName: 'Sarah',
    titleEN: 'Learning English can be Fun!',
    titleKR: `Hello, dear students! 
    영어 실력이 쑥쑥! 자신감도 뿜뿜! 
    즐거움이 팡팡! Sarah쌤과 함께라면 가능하답니다.`,
    hashtags: [
      { title: '#유초등전문' },
      { title: '#영어동화번역가' },
      { title: '#리액션최고' },
      { title: '#방과후영어교육지도사' },
      { title: '#칭찬왕' },
    ],
  },
  {
    id: 2,
    animateIn: 'animate__fadeInLeft',
    src: 'https://www.youtube.com/embed/FqdtgsMzA78',
    teacherName: 'Lisa',
    titleEN: `I am here to help you! 
    You can have fun with me!`,
    titleKR: `Hello, everyone! I'm teacher Lisa! 
    함께 재미있고 신나게 영어를 배워봐요~`,
    hashtags: [
      { title: '#튼튼영어전문교사' },
      { title: '#대치동교육15년' },
      { title: '#재미있는' },
      { title: '#열정적인' },
      { title: '#영어실력쑥쑥' },
    ],
  },
  // {
  //   id: 3,
  //   animateIn: 'animate__fadeInRight',
  //   src: 'https://www.youtube.com/embed/ZiZaNX_-Bnc',
  //   teacherName: 'Ellie Kim',
  //   titleEN: 'Hey Beautiful! I look forward to seeing you SOON :)',
  //   titleKR: `Ellie쌤과 만들어가는 25분의 기적!
  //   진정한 미국의 맛을 경험해보세요!`,
  //   hashtags: [
  //     { title: '#미국거주10년' },
  //     { title: '#100%영어수업가능' },
  //     { title: '#유초등전문' },
  //     { title: '#칭찬왕' },
  //     { title: '#리액션최고' },
  //   ],
  // },
  // {
  //   id: 4,
  //   animateIn: 'animate__fadeInLeft',
  //   src: 'https://www.youtube.com/embed/2bL7ZYA8znA',
  //   teacherName: 'Rose',
  //   titleEN: 'You are gonna enjoy English if you study with me.',
  //   titleKR: `저 Rose쌤과 함께 영어로 신나게 놀 준비 됐나요?
  //   Trust me, and follow me!`,
  //   hashtags: [
  //     { title: '#경력10년이상' },
  //     { title: '#리더십있는' },
  //     { title: '#초등전문' },
  //     { title: '#열정적인' },
  //     { title: '#재미있는' },
  //   ],
  // },
  // {
  //   id: 5,
  //   animateIn: 'animate__fadeInRight',
  //   src: 'https://www.youtube.com/embed/IToAE9zBpOM',
  //   teacherName: 'Sophie',
  //   titleEN: `I'm calm and kind, but charismatic!`,
  //   titleKR: `네덜란드와 영국, 다양한 국가에 거주하며 경험을 쌓았어요!
  //   Sophie와 재밌게 영어로 말해봐요!`,
  //   hashtags: [
  //     { title: '#국제학교졸업' },
  //     { title: '#스피킹위주' },
  //     { title: '#초등전문' },
  //     { title: '#해외거주' },
  //     { title: '#리더십있는' },
  //   ],
  // },
];
interface LiveTeacherFirstSectionProps {}

export const LiveTeacherFirstSection: FC<LiveTeacherFirstSectionProps> = () => {
  const [show, setShow] = useState(3);
  const { push } = useHistory();
  return (
    <>
      <div>
        <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
          <div className="flex flex-col items-center justify-center">
            <div className="mt-32 md:w-16 md:h-16 w-10 h-10 text-white md:text-32 text-16 font-nanum rounded-full bg-primary-1 grid place-content-center md:pt-1">
              1
            </div>
          </div>
          <h2 className="text-2xl md:text-4xl text-blue-500 text-center tit-txt01 md:pt-10 pt-6">
            어떤 라이브 쌤을 만나더라도
          </h2>
          <h2 className="text-2xl md:text-4xl text-black text-center tit-txt01 py-2">
            수업은 튼튼하게!
          </h2>
          <p className="md:text-xl font-medium text-black sub-txt01 text-15 text-center py-3 mb-6">
            탄탄하게 짜여진 라이브 커리큘럼을
            <br />
            선생님들의 개성 넘치는 수업으로 만나보세요!
          </p>

          <div className="md:w-782 w-96 mx-auto px-8 mt-10 mb-20">
            <div className="relative aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
              <iframe
                // width="560"
                // height="315"
                className="w-full h-full object-cover absolute"
                src={`https://www.youtube.com/embed/7mSbJVUCHYg`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </ScrollAnimation>

        <div className="space-y-20">
          {data.slice(0, show).map((item) => (
            <ScrollAnimation animateIn={item.animateIn} animateOnce={true}>
              <div
                className={`max-w-7xl mx-auto relative xl:pt-24 lg:pt-32 pt-44`}
              >
                <div
                  className={`${
                    item.animateIn === 'animate__fadeInRight'
                      ? ' left-10'
                      : 'right-10'
                  } absolute top-0`}
                >
                  <div className="relative xl:w-120 xl:h-72 lg:w-96 lg:h-64 w-80 h-52 rounded-xl overflow-hidden">
                    <iframe
                      // width="560"
                      // height="315"
                      className="w-full h-full object-cover absolute"
                      src={item.src}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
                <div
                  className={`${
                    item.animateIn === 'animate__fadeInRight'
                      ? 'bg-light-red-2 rounded-l-full ml-auto grid place-content-end'
                      : 'bg-light-blue-2 rounded-r-full mr-auto pl-10'
                  } lg:py-10 pb-10 pt-16 lg:w-4/5 w-full`}
                >
                  <div className="flex lg:pr-10 lg:pl-0 px-5">
                    <p
                      className={`${
                        item.animateIn === 'animate__fadeInRight'
                          ? 'text-light-red-1'
                          : 'text-light-blue-1'
                      } lg:text-128 text-96 mb-auto `}
                    >
                      “
                    </p>
                    <div className="text-center">
                      <h1 className="lg:text-4xl text-3xl pb-2">
                        {item.teacherName}
                      </h1>
                      <div className="lg:text-xl md:font-medium font-medium text-14 leading-relaxed pb-6">
                        <p>{item.titleEN}</p>
                        <p className="whitespace-pre">{item.titleKR}</p>
                      </div>
                      <div className="flex flex-wrap space-x-2 lg:text-base text-sm justify-center">
                        {item.hashtags.map((tag) => (
                          <p className="px-3 py-1 mb-2 border-blue-500 bg-white border-2 rounded-full text-blue-500 font-semibold">
                            {tag.title}
                          </p>
                        ))}
                      </div>
                    </div>
                    <p
                      className={`${
                        item.animateIn === 'animate__fadeInRight'
                          ? 'text-light-red-1'
                          : 'text-light-blue-1'
                      } lg:text-128 text-96 mt-auto align-text-bottom`}
                    >
                      ”
                    </p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          ))}
        </div>

        {show === 3 && (
          <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true}>
            <div className="flex justify-center pt-24">
              {/* <button
                onClick={() => setShow(5)}
                type="button"
                className="group flex flex-cols justify-center"
              >
                <div className=" text-gray-600 font-medium lg:text-xl text-lg pr-1  ">
                  더 많은 쌤 보러가기
                </div>
                <ChevronDownIcon className="text-gray-600" />
              </button> */}
            </div>
          </ScrollAnimation>
        )}
        {/* <div className="flex justify-center pt-20 pb-36">
          <button
            onClick={() => push('pricing/trial')}
            type="button"
            className="relative pt-9 group"
          >
            <img
              src={character}
              alt=""
              className="wh-20 absolute bottom-0.5 left-3"
            />
            <div className="border-2 border-primary-1 text-primary-1 lg:text-2xl text-lg pl-24 group-hover:bg-primary-1 group-hover:text-white pr-6 py-2.5 rounded-full ">
              체험 수업 신청페이지
              {`>`}
            </div>
          </button>
        </div> */}
      </div>
    </>
  );
};
