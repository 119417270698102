import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { Banner, Paginated } from '../../types';
import { AdminCard } from '../components/AdminCard';
import { AdminH1 } from '../components/AdminH1';

export const Banners = () => {
  const queryString = useQueryString();
  const { data } = useQuery<Paginated<Banner>>(
    `/admin/banners${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  return (
    <>
      <div className="flex justify-between items-center">
        <AdminH1>배너 관리</AdminH1>
        <Button
          text="배너 추가"
          to="/admin/banners/add"
          className="h-8 font-normal text-indigo-600 hover:text-indigo-900"
        />
      </div>

      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>타이틀</Table.Th>
              <Table.Th>설명</Table.Th>
              <Table.Th>숨김여부</Table.Th>
              <Table.Th>순서</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data?.items.map((banner) => (
              <Table.Row key={banner.id}>
                <Table.Td>{banner.title}</Table.Td>
                <Table.Td>{banner.description}</Table.Td>
                <Table.Td>{banner.isHide ? 'O' : 'X'}</Table.Td>
                <Table.Td>{banner.rank}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/banners/${banner.id}`}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data?.items.length || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav basePath="/admin/banners" total={data?.total || 0} />
        </Pagination>
      </AdminCard>
    </>
  );
};
