import moment, { Moment } from 'moment';
import React, { FC } from 'react';
import { range } from '../utils';
import { H5 } from './H5';
import { H6 } from './H6';
import { ChevronLeftIcon, ChevronRightIcon } from './icons';
import { Section } from './Section';

export interface ScheduleManagerProps {
  from?: Moment;
  to?: Moment;
  findLiveTimes?: Map<string, any>;
  onClickPrev?: () => void;
  onClickNext?: () => void;
  selectedTime?: (time: Moment) => boolean;
  disabledTime?: (time: Moment) => boolean;
  onClickTime?: (time: Moment) => void;
}

export const ScheduleManager: FC<ScheduleManagerProps> = ({
  from,
  to,
  findLiveTimes,
  onClickPrev,
  onClickNext,
  selectedTime,
  disabledTime,
  onClickTime,
}) => {
  return (
    <>
      <div className="flex items-center space-x-4 justify-center">
        <button onClick={onClickPrev}>
          <ChevronLeftIcon />
        </button>
        <H5>
          {from?.format('MM.DD')} - {to?.format('MM.DD')}
        </H5>
        <button onClick={onClickNext}>
          <ChevronRightIcon />
        </button>
      </div>

      <div className="md:grid md:grid-cols-5 md:gap-5">
        {range(5)
          .map((i) => moment(from).add(i, 'days'))
          .map((date) => (
            <Section key={date.unix()} className="md:space-y-5 pt-8 md:py-0">
              <div className="rounded-lg shadow-sm py-4 px-6 md:items-center md:text-center space-y-1">
                <H6 className="hidden md:block">{date.format('dd')}</H6>
                <p className="text-lg font-bold md:text-base md:font-normal">
                  {date.format('MM월 DD일')}
                  <span className="text-lg font-bold md:hidden ml-1">
                    ({date.format('dd')})
                  </span>
                </p>
              </div>

              <div className="space-y-2">
                {range(28)
                  .map((i) => date.clone().add(570 + i * 30, 'minutes'))
                  .map((time) => (
                    <button
                      key={time.unix()}
                      className={`rounded-full md:rounded-lg shadow-sm px-5 py-2 w-min md:w-full md:px-0 md:py-3 m-2 md:m-0 disabled:text-gray-200 inline-block md:block ${
                        findLiveTimes && findLiveTimes?.has(time.toISOString())
                          ? 'border-2 text-white border-primary-1 bg-blue-100 md:bg-blue-100 md:text-gray-800'
                          : selectedTime?.(time)
                          ? 'border-primary-1 border-2 bg-primary-1 text-white md:bg-white md:text-gray-800'
                          : 'border-2 border-gray-800 md:border-white'
                      }`}
                      disabled={disabledTime?.(time)}
                      onClick={() => onClickTime?.(time)}
                    >
                      {time.format('HH:mm')}
                    </button>
                  ))}
              </div>
            </Section>
          ))}
      </div>
    </>
  );
};
