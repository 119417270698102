import { ClassType, ProductLiveType } from './types';

export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export function typeToName(
  productLiveType: ProductLiveType,
  classType: ClassType
) {
  switch (classType) {
    case ClassType.REGULAR:
      return '정규';
    case ClassType.TRIAL:
      switch (productLiveType) {
        case ProductLiveType.TRIAL:
          return '정규 체험';
        case ProductLiveType.TRIAL_SPEAKING:
          return '스피킹 체험';
        default:
          break;
      }
      return '체험';
    case ClassType.SPECIAL:
      switch (productLiveType) {
        case ProductLiveType.SPEAKING:
          return '스피킹';
        default:
          break;
      }

      return '특강';
    default:
      return '';
  }
}
