import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString, useSearch } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import {
  ClassType,
  Paginated,
  Payment,
  PaymentState,
  ProductLiveType,
  classTypeToText,
} from '../../types';
import { AdminCard } from '../components/AdminCard';
import { Button } from '../../components/Button';
//@ts-ignore
import { downloadFile } from 'download-csv';
//@ts-ignore
import jsonExport from 'jsonexport/dist';
import { AdminSearch } from '../components/AdminSearch';
import { TextField } from '../../components/TextField';

export const PaymentAll = () => {
  const { q, setQ, setSearchText } = useSearch();
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const queryString = useQueryString({});
  const { data } = useQuery<Paginated<Payment>>(
    `/admin/payments/all${queryString}&q=${q}`,
    fetcher,
    { keepPreviousData: true }
  );

  const { data: payments, refetch: exportPayments } = useQuery<Payment[]>(
    `/admin/payments/export?from=${from}&to=${to}`,
    fetcher,
    { keepPreviousData: true, enabled: false }
  );

  const exporter = () => {
    exportPayments();
    const paymentData = payments?.map((payment: any) => {
      return {
        주문일자: moment(payment.createdAt).format('YYYY.MM.DD'),
        주문자명: payment.student.name,
        상품유형: classTypeToText(
          payment?.paymentProducts[0]?.product?.classType
        ),
        주문상품: payment.isTrialPackage
          ? `${payment.productAmount}회 체험팩`
          : payment.productAmount
          ? `${payment.productAmount}회 수업`
          : payment?.paidAmount
          ? '보강'
          : '체험',
        결제금액: payment?.paidAmount,
        교재: payment.bookIncluded ? '포함' : '-',
        TLD: payment.penIncluded ? '포함' : '-',
        할인금액: payment?.discountedAmount,
        상담가능시간: payment.consultingTime,
        배송정보:
          '수취인' +
          '-' +
          payment.user.name +
          ',' +
          ' ' +
          '전화번호' +
          '-' +
          payment.orderPhoneNumber +
          ',' +
          ' ' +
          '주소' +
          '-' +
          payment.address +
          payment.detailAddress,
        송장번호: payment.trackingNumber,
        주문상태:
          payment.paymentState === PaymentState.WAITING_FOR_CONSULTATION
            ? '상담 대기'
            : payment.paymentState === PaymentState.WAITING_FOR_DELIVERY
            ? '배송 준비'
            : payment.paymentState === PaymentState.LEAVING
            ? '출고'
            : payment.paymentState === PaymentState.IN_TRANSIT
            ? '배송 중'
            : payment.paymentState === PaymentState.DELIVERED
            ? '배송완료'
            : payment.paymentState === PaymentState.PAID
            ? '결제완료'
            : payment.paymentState === PaymentState.CANCELED
            ? '취소/교환/환불'
            : payment.paymentState === PaymentState.WAIT
            ? '입금 대기'
            : '',
        관리자메모: payment.memo,
      };
    });

    jsonExport(paymentData, function (err: any, csv: any) {
      if (err) return console.error(err);
      const BOM = '\uFEFF';
      downloadFile(`${BOM} ${csv}`, '주문 관리(전체)');
    });
  };

  if (!data) return <></>;
  return (
    <>
      <div className="flex ml-auto space-x-4 bg-white border-1 p-1.5 rounded-lg">
        <div className="flex space-x-2 items-center">
          <TextField
            type="date"
            value={from}
            className="h-12"
            max="9999-12-31"
            onChange={(e) => setFrom(e.target.value)}
          />
          <div>~</div>
          <TextField
            type="date"
            value={to}
            className="h-12"
            max="9999-12-31"
            onChange={(e) => setTo(e.target.value)}
          />
        </div>

        <Button
          className="bg-primary-1 h-12 text-white text-14"
          onClick={exporter}
        >
          파일 내보내기
        </Button>
      </div>
      <AdminSearch
        placeholder="검색"
        value={q}
        onChange={(e) => setQ(e.target.value)}
      />
      <AdminCard>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>주문일자</Table.Th>
              <Table.Th>주문자</Table.Th>
              <Table.Th>상품유형</Table.Th>
              <Table.Th>주문상품</Table.Th>
              <Table.Th>결제금액</Table.Th>
              <Table.Th>할인금액</Table.Th>
              <Table.Th>교재</Table.Th>
              <Table.Th>TLD</Table.Th>
              <Table.Th>주문상태</Table.Th>
              <Table.Th>관리자메모</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.items.map((payment) => (
              <Table.Row key={payment.id}>
                <Table.Td>
                  {moment(payment.createdAt).format('YYYY.MM.DD')}
                </Table.Td>
                <Table.Td>{payment.student?.name}</Table.Td>
                <Table.Td>
                  {classTypeToText(
                    payment?.paymentProducts[0]?.product?.classType
                  )}
                </Table.Td>
                <Table.Td>
                  {payment?.paymentProducts[0]?.product?.classType ===
                  ClassType.TRIAL
                    ? payment.paymentProducts[0]?.product?.liveType ===
                      ProductLiveType.TRIAL
                      ? '정규'
                      : payment.paymentProducts[0]?.product?.liveType ===
                        ProductLiveType.TRIAL_SPEAKING
                      ? '스피킹'
                      : ''
                    : payment.isTrialPackage
                    ? `${payment.productAmount}회 체험팩`
                    : payment.productAmount
                    ? `${payment.productAmount}회 수업`
                    : payment?.paidAmount
                    ? '보강'
                    : '체험'}
                </Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.paidAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>
                  {`
                ${String(payment?.discountedAmount || 0).replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}원`}
                </Table.Td>
                <Table.Td>{payment?.bookIncluded ? '포함' : '-'}</Table.Td>
                <Table.Td>{payment?.penIncluded ? '포함' : '-'}</Table.Td>
                <Table.Td>
                  {payment.paymentState ===
                  PaymentState.WAITING_FOR_CONSULTATION
                    ? '상담 대기'
                    : payment.paymentState === PaymentState.WAITING_FOR_DELIVERY
                    ? '배송 준비'
                    : payment.paymentState === PaymentState.LEAVING
                    ? '출고'
                    : payment.paymentState === PaymentState.IN_TRANSIT
                    ? '배송 중'
                    : payment.paymentState === PaymentState.DELIVERED
                    ? '배송완료'
                    : payment.paymentState === PaymentState.PAID
                    ? '결제완료'
                    : payment.paymentState === PaymentState.CANCELED
                    ? '취소/교환/환불'
                    : ''}
                </Table.Td>
                <Table.Td>{payment.memo ? payment.memo : '-'}</Table.Td>
                <Table.Td className="text-right">
                  <Link
                    className="font-normal text-indigo-600 hover:text-indigo-900"
                    to={`/admin/payments/${payment.id}/edit`}
                    onClick={setSearchText}
                  >
                    상세보기
                  </Link>
                </Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label count={data.items.length} total={data.total} />
          <Pagination.Nav basePath="/admin/payments/all" total={data.total} />
        </Pagination>
      </AdminCard>
    </>
  );
};
