import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { Table } from '../../components/Table';
import { useQueryString } from '../../hooks';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { Paginated, PayItem, PayItemType } from '../../types';

export const TransactionsMonthPayItemPage = () => {
  const { id } = useParams<{ id: string }>();
  const queryString = useQueryString({
    filter: { payId: id },
  });

  const { data: pay } = useQuery<PayItem>(`/pays/${id}`, fetcher, {
    keepPreviousData: true,
  });

  const { data } = useQuery<Paginated<PayItem>>(
    `/pay-items/monthly${queryString}`,
    fetcher,
    { keepPreviousData: true }
  );

  if (!data || !pay) return <></>;
  return (
    <div className="border rounded-md overflow-hidden border-gray-100">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Th>수업일자</Table.Th>
            <Table.Th>강사명</Table.Th>
            <Table.Th>계좌정보</Table.Th>
            <Table.Th>수업한 학생</Table.Th>
            <Table.Th>정산타입</Table.Th>
            <Table.Th>세부내용</Table.Th>
            <Table.Th>총 정산 금액</Table.Th>
            <Table.Th></Table.Th>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.items
            ?.sort((a, b) => b.id - a.id)
            ?.map((payItem) => (
              <Table.Row key={payItem.id}>
                <Table.Td>
                  {payItem.live?.start
                    ? utcToLocalFormat(
                        payItem.live.start.toLocaleString(),
                        MomentFormat.YYYYMMDDHmm
                      )
                    : '-'}
                </Table.Td>
                <Table.Td>{payItem.teacher.englishName}</Table.Td>
                <Table.Td>{payItem.teacher.bankAccountNumber}</Table.Td>
                <Table.Td>{payItem.live?.student?.name}</Table.Td>
                <Table.Td>
                  {payItem.type === PayItemType.LIVE_SETTLEMENT
                    ? '수업정산'
                    : payItem.type === PayItemType.PENALTY
                    ? '패널티'
                    : '장려금'}
                </Table.Td>
                <Table.Td>{payItem.memo ? payItem.memo : '-'}</Table.Td>
                <Table.Td>
                  {payItem.type === PayItemType.PENALTY
                    ? `-${payItem.value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                    : payItem.value
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Table.Td>
                {payItem.type === PayItemType.LIVE_SETTLEMENT &&
                  payItem.live.courseItemId && (
                    <Table.Td className="text-right">
                      <Button
                        className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                        to={`/teacher/students/${payItem?.live?.student?.id}/lives/${payItem.liveId}`}
                      >
                        레슨보기
                      </Button>
                    </Table.Td>
                  )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <Pagination>
        <Pagination.Label count={data.items.length} total={data.total} />
        <Pagination.Nav
          basePath={`/teacher/mypage/transactions/${id}/payItem`}
          total={data.total}
        />
      </Pagination>
    </div>
  );
};
