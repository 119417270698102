export interface Paginated<T> {
  items: T[];
  total: number;
}

export interface Banner {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  rank: number;
  image: string;
  mobileImage: string;
  isHide: boolean;
}

export interface Book {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  cover: string;
  index: number;
  story: string;
  song: string;
  talk: string;
  lecture: string;
  code: string;
  seriesId: number;
  lessons: Lesson[];
  points: Point[];
  bookItems: BookItem[];
}

export interface BookItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  index: number;
  activeId: ActiveId;
  name: string;
  url: string;
  bookId: number;
}

export interface Category {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  isHide: boolean;
}

export interface Coupon {
  id: number;
  createdAt: string;
  updatedAt: string;
  target: CouponTarget;
  type: CouponType;
  name: string;
  description: string;
  discount: number;
  downloadStart: string;
  downloadEnd: string;
  useStart: string;
  useEnd: string;
  memo: string;
  open: boolean;
  studentId?: number;
  isTargetParent: boolean;
  user?: User;
  student?: Student;
  couponItems: CouponItem[];
}

export enum CouponTarget {
  ALL = 'ALL',
  ONE = 'ONE',
}

export enum CouponType {
  NORMAL = 'NORMAL',
  REPEAT = 'REPEAT',
}

export interface CouponItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  isEnabled: boolean;
  downloadedAt: string;
  useStart: string;
  useEnd: string;
  used: boolean;
  userId: number;
  studentId?: number;
  couponId: number;
  coupon: Coupon;
  student?: Student;
}

export interface Course {
  id: number;
  createdAt: string;
  updatedAt: string;
  isTrial: boolean;
  name: string;
  memo: string;
  initialAmount: number;
  remainingAmount: number;
  bookIncluded: boolean;
  penIncluded: boolean;
  isOpen: boolean;
  isHide: boolean;
  startedAt: string;
  endedAt: string;
  expiredAt: number;
  userId: number;
  studentId: number;
  startLevelId: number;
  startSeriesId: number;
  startBookId: number;
  courseItems: CourseItem[];
  startSeries: Series;
  student: Student;
  startLevel: Level;
  startBook: Book;
  classType: ClassType;
  productLiveType: ProductLiveType;
  lives: Live[];
}

export interface CourseItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  number: number;
  courseId: number;
  liveClassId: number;
  liveClass: LiveClass;
}

export interface CumulativePoint {
  id: number;
  createdAt: string;
  updatedAt: string;
  currentTotalPoint: number;
  totalCumulativePoints: number;
  initializationTime: Date;
  studentId: number;
}

export interface Entry {
  id: number;
  createdAt: string;
  updatedAt: string;
  testType: string;
  minScore100: number;
  levelCode: string;
  bookDescription: string;
  levelId: number;
  seriesId: number;
  bookId: number;
}

export interface EventBoard {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  thumbnail: string;
  description: string;
  startedAt: string;
  endedAt: string;
  isHide: boolean;
  categoryId: number;
  category: Category;
}

export enum ActiveId {
  STORY = 2,
  STORY_QUIZ = 3,
  LEARN = 4,
  LEARN_QUIZ = 5,
  WORDS = 6,
  WORD_QUIZ = 7,
  WORKBOOK = 8,
  TALK = 9,
  WEEKLYTEST = 10,
  SPEAKINGLESSON1 = 11,
  SPEAKINGLESSON2 = 12,
  SPEAKINGLESSON3 = 13,
}

export interface Lesson {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  index: number;
  story: string;
  storyQuiz: string;
  learn: string;
  learnQuiz: string;
  words: string;
  wordQuiz: string;
  workbook: string;
  talk: string;
  test: string;
  phonics: string;
  phonicsQuiz: string;
  bookId: number;
  lessonResults: LessonResult[];
  lessonItems: LessonItem[];
}

export interface LessonItem {
  activeId: ActiveId;
  name: string;
  url: string;
  lessonId: number;
}

export interface LessonResult {
  id: number;
  createdAt: string;
  updatedAt: string;
  storyDone: boolean;
  storyQuizDone: boolean;
  storyQuizQuestion: number;
  storyQuizCorrect: number;
  storyQuizResult: string;
  storyQuizScore: number;
  learnDone: boolean;
  learnQuizDone: boolean;
  learnQuizQuestion: number;
  learnQuizCorrect: number;
  learnQuizResult: string;
  learnQuizScore: number;
  wordsDone: boolean;
  wordQuizDone: boolean;
  wordQuizQuestion: number;
  wordQuizCorrect: number;
  wordQuizResult: string;
  wordQuizScore: number;
  workbookDone: boolean;
  talkDone: boolean;
  testDone: boolean;
  testId: string;
  testQuestion: number;
  testCorrect: number;
  testScore: number;
  isCompleted: boolean;
  studentId: number;
  lessonId: number;
  lesson: Lesson;
}

export interface LessonVoice {
  id: number;
  createdAt: string;
  updatedAt: string;
  file: string;
  recordingTime: string;
  recordingType: string;
  lesson: Lesson;
  studentId: number;
  lessonId: number;
}

export interface Level {
  id: number;
  createdAt: string;
  updatedAt: string;
  productLiveType: ProductLiveType;
  name: string;
  index: number;
  open: boolean;
  classType: ClassType;
  serieses: Series[];
}

export interface Live {
  id: number;
  createdAt: string;
  updatedAt: string;
  state: LiveState;
  liveType: LiveType;
  start: string;
  end: string;
  text: string;
  canceledAt: string;
  reservedAt: string;
  cancelType: CancelType;
  cancelRound: number;
  attendRound: number;
  pageCallRoomId: string;
  courseItemId: number;
  teacherId: number;
  studentId: number;
  courseId: number;
  isManualChange: boolean;
  teacher: User;
  student: Student;
  course: Course;
  liveFeedbacks: LiveFeedback[];
  liveReviews: LiveReview[];
  index?: number;
  courseItem: CourseItem;
  firstCourseItem: CourseItem;
  points: Point[];
}

export enum LiveState {
  AVAILABLE = 'AVAILABLE', // 예약가능
  RESERVED = 'RESERVED', // 예약완료
  CANCELED = 'CANCELED', // 취소
  ATTENDED = 'ATTENDED', // 출석
  ABSENT = 'ABSENT', // 결석
  NO_SHOW = 'NO_SHOW', // 선생님 노쇼
  ABSENT_AND_NO_SHOW = 'ABSENT_AND_NO_SHOW', // 결석, 노쇼
  CANCELLATION_THE_DAY = 'CANCELLATION_THE_DAY', // 당일 취소
}

export enum LiveType {
  NORMAL = 'NORMAL', // 일반 수업
  TRIAL = 'TRIAL', // 체험 수업
}

export enum CancelType {
  ADMIN = 'ADMIN', // 관리자
  TEACHER = 'TEACHER', // 선생님
  STUDENT = 'STUDENT', // 학생
}

export function liveStateToText(liveState: LiveState) {
  switch (liveState) {
    case LiveState.AVAILABLE:
      return '예약 가능';
    case LiveState.RESERVED:
      return '예약 완료';
    case LiveState.CANCELED:
      return '취소';
    case LiveState.ATTENDED:
      return '출석';
    case LiveState.ABSENT:
      return '결석';
    case LiveState.NO_SHOW:
      return '선생님 노쇼';
    case LiveState.ABSENT_AND_NO_SHOW:
      return '학생 결석/선생님 노쇼';
    default:
      throw new Error('Invalid LiveState');
  }
}

export function liveStateToTextMin(liveState: LiveState) {
  switch (liveState) {
    case LiveState.AVAILABLE:
      return '가능';
    case LiveState.RESERVED:
      return '예약';
    case LiveState.CANCELED:
      return '취소';
    case LiveState.ATTENDED:
      return '출석';
    case LiveState.ABSENT:
      return '결석';
    case LiveState.NO_SHOW:
      return '노쇼';
    case LiveState.ABSENT_AND_NO_SHOW:
      return '결석';
    case LiveState.CANCELLATION_THE_DAY:
      return '결석';
    default:
      throw new Error('Invalid LiveState');
  }
}

export interface LiveClass {
  id: number;
  createdAt: string;
  updatedAt: string;
  productLiveType: ProductLiveType;
  goal: string;
  detailedGoal: string;
  words: string;
  patterns: string;
  content: string;
  index: number;
  open: boolean;
  levelId: number;
  seriesId: number;
  bookId: number;
  level: Level;
  series: Series;
  book: Book;
}

export interface LiveFeedback {
  id: number;
  createdAt: string;
  updatedAt: string;
  text: string;
  totalRate: number;
  participationRate: number;
  vocabularyRate: number;
  understandingRate: number;
  pronunciationRate: number;
  liveId: number;
  studentId: number;
  teacherId: number;
}

export interface LiveReview {
  id: number;
  createdAt: string;
  updatedAt: string;
  rate: number;
  liveId: number;
  studentId: number;
  teacherId: number;
}

export interface Notice {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  isHide: boolean;
}

export interface Pay {
  id: number;
  createdAt: string;
  updatedAt: string;
  total: number;
  liveSettlement: number;
  liveSettlementCount: number;
  incentive: number;
  penalty: number;
  time: string;
  teacherId: number;
  teacher: User;
  payItems: PayItem[];
}

export enum PayItemType {
  LIVE_SETTLEMENT = 'LIVE_SETTLEMENT', // 수업정산
  PENALTY = 'PENALTY', // 패널티
  INCENTIVE = 'INCENTIVE', // 장려금
}
export interface PayItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: PayItemType;
  memo: string;
  auto: boolean;
  value: number;
  time: string;
  manager: string;
  teacherId: number;
  liveId: number;
  payId: number;
  teacher: User;
  live: Live;
}

export enum PaymentState {
  READY = 'READY', // 결제 대기
  WAIT = 'WAIT', // 입금 대기
  PAID = 'PAID', // 결제 완료
  CANCELED = 'CANCELED', // 취소/환불/교환
  WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY', // 배송준비
  WAITING_FOR_CONSULTATION = 'WAITING_FOR_CONSULTATION', // 상담대기
  LEAVING = 'LEAVING', // 출고
  IN_TRANSIT = 'IN_TRANSIT', // 배송 중
  DELIVERED = 'DELIVERED', // 배송완료
}

export enum PaymentMethod {
  CARD = 'CARD', //신용카드
  BANK = 'BANK', //계좌이체
  NPAY = 'NPAY', //네이버페이
  KPAY = 'KPAY', //카카오페이
}

export interface PaymentParamDto {
  paymentMethod: PaymentMethod;
  email: string;
  paymentPhoneNumber: string;
  orderPhoneNumber: string;
  address?: string;
  detailAddress?: string;
  deliveryMessage?: string;
  consultingTime?: string;
  consultingMessage?: string;
  studentId: number;
  productIds: number[];
  productAmount?: number;
  bookIncluded: boolean;
  penIncluded: boolean;
  levelId?: number;
  seriesId?: number;
  bookId?: number;
  couponItem?: string;
  couponItemIds?: number[];
  mobileReturnUrl?: string;
  // 미결제 건에 대해 재요청 시 사용
  pgOrderNumber?: string;
}

export interface PaymentReinforceParamDto {
  email: string;
  paymentPhoneNumber: string;
  studentId: number;
  reinforceId: number;
  price: number;
  mobileReturnUrl?: string;
  couponItem?: string;
  couponItemIds?: number[];
  paymentMethod: PaymentMethod;
  // 미결제 건에 대해 재요청 시 사용
  pgOrderNumber?: string;
}

export interface PaymentTrialParamDto {
  email: string;
  paymentMethod: PaymentMethod;
  paymentPhoneNumber: string;
  studentId: number;
  productIds: number[];
  productAmount?: number;
  couponItem?: string;
  couponItemIds?: number[];
  mobileReturnUrl?: string;
  // 미결제 건에 대해 재요청 시 사용
  pgOrderNumber?: string;
}

export interface PaymentSpecialParamDto {
  email: string;
  paymentMethod: PaymentMethod;
  paymentPhoneNumber: string;
  studentId: number;
  productIds: number[];
  productAmount?: number;
  couponItem?: string;
  couponItemIds?: number[];
  mobileReturnUrl?: string;
  // 미결제 건에 대해 재요청 시 사용
  pgOrderNumber?: string;
}

export interface Payment {
  id: number;
  createdAt: string;
  updatedAt: string;
  paymentState: PaymentState;
  paymentMethod: PaymentMethod;
  consultingTime: string;
  pgOrderNumber: string;
  totalAmount: number;
  cardName: string;
  cardCode: string;
  bankName: string;
  bankAccount: string;
  orderPhoneNumber: string;
  memo: string;
  address: string;
  detailAddress: string;
  trackingNumber: string;
  productAmount: number;
  bookIncluded: boolean;
  discountedAmount: number;
  paidAmount: number;
  consultingMessage: string;
  deliveryMessage: string;
  penIncluded: boolean;
  isTrialPackage: boolean;
  levelId: number;
  seriesId: number;
  bookId: number;
  userId: number;
  reinforceId: number;
  couponItemId?: number;
  user: User;
  studentId: number;
  student: Student;
  couponItems: CouponItem[];
  paymentProducts: PaymentProduct[];
}

export interface PaymentProduct {
  id: number;
  createdAt: string;
  updatedAt: string;
  paymentId: number;
  productId: number;
  product: Product;
}

export interface Phone {
  id: number;
  createdAt: string;
  updatedAt: string;
  verified: boolean;
}

export enum PointType {
  PROVISION = 'PROVISION', // 지급
  DEDUCTIBLE = 'DEDUCTIBLE', // 차감
}

export interface Point {
  id: number;
  createdAt: string;
  updatedAt: string;
  pointType: PointType;
  title: string;
  memo: string;
  value: number;
  liveId: number;
  bookId: number;
  studentId: number;
  userId: number;
  student: Student;
}

export interface Product {
  id: number;
  createdAt: string;
  updatedAt: string;
  type: ProductType;
  liveType: ProductLiveType;
  name: string;
  description: string;
  amount: number;
  originalPrice: number;
  discountRate: number;
  finalPrice: number;
  expiredAt: number;
  index: number;
  open: boolean;
  freePen: true;
  classType: ClassType;
}

export interface LiveProduct {
  id: number;
  createdAt: string;
  updatedAt: string;
  index: number;
  classType: ClassType;
  name: string;
  open: boolean;
}

export enum ClassType {
  TRIAL = 'TRIAL',
  REGULAR = 'REGULAR',
  SPECIAL = 'SPECIAL',
}

export function classTypeToText(classType: ClassType) {
  switch (classType) {
    case ClassType.TRIAL:
      return '체험';
    case ClassType.REGULAR:
      return '정규';
    case ClassType.SPECIAL:
      return '특강';
    default:
    // throw new Error('Invalid LiveState');
  }
}

export enum ProductType {
  LIVE = 'LIVE',
  BOOK = 'BOOK',
  PEN = 'PEN',
}

export enum ProductLiveType {
  TRIAL = 'TRIAL',
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  SPEAKING = 'SPEAKING',
  GRAMMAR = 'GRAMMAR',
  TRIAL_SPEAKING = 'TRIAL_SPEAKING',
}

export function productLiveTypeName(productLiveType: ProductLiveType) {
  switch (productLiveType) {
    case ProductLiveType.TRIAL:
      return '정규';
    case ProductLiveType.FIRST:
      return '정규';
    case ProductLiveType.SECOND:
      return '정규';
    case ProductLiveType.SPEAKING:
      return '스피킹';
    case ProductLiveType.GRAMMAR:
      return '';
    case ProductLiveType.TRIAL_SPEAKING:
      return '스피킹';
    default:
      return '';
  }
}

export interface Reinforce {
  id: number;
  createdAt: string;
  updatedAt: string;
  memo: string;
  amount: number;
  price: number;
  isPaid: boolean;
  studentId: number;
  userId: number;
  student: Student;
}

export interface Series {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  thumbnail: string;
  index: number;
  open: boolean;
  levelId: number;
  books: Book[];
}

export interface Student {
  id: number;
  createdAt: string;
  updatedAt: string;
  avatar: string;
  name: string;
  dob: string; // Date of Birth
  gender: Gender;
  isTested: boolean;
  inflowPath: string;
  memo: string;
  userId: number;
  nextLevelId: number;
  nextSeriesId: number;
  nextBookId: number;
  pageCallId: string;
  pageCallAccessToken: string;
  pageCallTestRoomId: string;
  user: User;
  lessonResults: LessonResult[];
  testResults: TestResult[];
  lives: Live[];
  nextLevel: Level;
  nextSeries: Series;
  nextBook: Book;
  studentAggregation: StudentAggregation;
  cumulativePoint: CumulativePoint;
}

export interface StudentAggregation {
  id: number;
  createdAt: string;
  updatedAt: string;
  testResultedAt: string;
  liveTrailedAt: string;
  liveTrailTeacherName: string;
  courseCreatedAt: string;
  coursePaymentCount: number;
  studentNowLevel: string;
  accessedAt: string;
  courseRemainingCount: number;
  courseExpiredAt: string;
  paymentTotalPrice: number;
  studentId: number;
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export interface TeacherGuide {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  isHide: boolean;
}

export interface TeacherMaterial {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  isHide: boolean;
  seriesId: number;
}

export interface TeacherNotice {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  content: string;
  isHide: boolean;
}

export interface TestResult {
  id: number;
  createdAt: string;
  updatedAt: string;
  json: string;
  acquisition: string;
  type: 'A' | 'B' | 'C';
  score: number; // 정답 갯수
  score100: number; // 100점 환산 점수
  isSkip: boolean;
  studentId: number;
  activeStudentId: number;
  levelId: number;
  seriesId: number;
  bookId: number;
  level: Level;
  series: Series;
  book: Book;
  student: Student;
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  role: Role;
  name: string;
  memo: string;
  phoneNumber: string;
  blackList: boolean;
  marketing: boolean;
  marketingDate: string;
  inviteCode: string;
  invitedCode: string;
  inviterId: number;
  invitees: Omit<User, 'invitees'>[];
  privacyPolicy: boolean;
  purchaseCompleted: boolean;
  //teacher Only
  avatar: string;
  category: string;
  englishName: string;
  exposureRank: number;
  videoUrl: string;
  introduction: string;
  emergencyContact: string;
  address: string;
  residentRegistrationNumber: string;
  bank: string;
  accountHolder: string;
  bankAccountNumber: string;
  passBook: string;
  idCard: string;
  resume: string;
  teacherTerms: boolean;
  firstDayOfWork: string;
  resignationDay: string;
  isDisabled: boolean;
  isHide: boolean;
  isInActive?: boolean;
  disabledTypes: string;
  disabledAt: string;
  pageCallId: string;
  pageCallAccessToken: string;
  grade: Grade;
  regularYn: boolean;
  specialYn: boolean;
  students: Student[];
  lives: Live[];
}

export enum Role {
  ADMIN = 'ADMIN',
  TEACHER = 'TEACHER',
  USER = 'USER',
}

export enum Grade {
  TF = 'TF', //프리랜서
  T1 = 'T1', //튼튼 교사
  T2 = 'T2', //튼튼 우수 교사
}
