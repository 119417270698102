import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '../hooks';
import { fetcher } from '../plugins/react-query';
import {
  courseItemState,
  courseState,
  latestCourseItemState,
  latestCourseState,
  latestLiveState,
  liveState,
  studentState,
  testResultState,
  trialCourseState,
} from '../plugins/ridge';
import { Course, Live, LiveState, Student, TestResult } from '../types';

export const GlobalState = () => {
  const { authenticated } = useAuth();
  const student = studentState.useValue();
  const course = courseState.useValue();
  const courseItem = courseItemState.useValue();
  const latestCourse = latestCourseState.useValue();
  const latestCourseItem = latestCourseItemState.useValue();

  const { data: students } = useQuery<Student[]>('/students', fetcher, {
    enabled: authenticated,
  });

  const { data: testResult } = useQuery<TestResult>(
    `/test-results/active?studentId=${student?.id}`,
    fetcher,
    { enabled: authenticated && !!student }
  );

  const { data: courses } = useQuery<Course[]>(
    `/courses?studentId=${student?.id}`,
    fetcher,
    { enabled: authenticated && !!student }
  );

  const validCourses = courses
    // ?.filter((course) => {
    //   const now = new Date();
    //   now.setHours(0, 0, 0, 0);
    //   const endedAt = new Date(course.endedAt);
    //   endedAt.setHours(0, 0, 0, 0);

    //   return endedAt.toISOString() >= now.toISOString() || !course.endedAt;
    // })
    ?.sort((a: Course, b: Course) => {
      const aLives = a.lives
        ?.filter((live) => live.state === LiveState.RESERVED)
        .sort((a: Live, b: Live) => {
          if (a.start < b.start) {
            return -1;
          } else {
            return 1;
          }
        });

      const bLives = b.lives
        ?.filter((live) => live.state === LiveState.RESERVED)
        .sort((a: Live, b: Live) => {
          if (a.start < b.start) {
            return -1;
          } else {
            return 1;
          }
        });

      if (
        (!aLives || aLives.length === 0) &&
        (!bLives || bLives.length === 0)
      ) {
        return 1;
      } else if ((!bLives || bLives.length === 0) && aLives) {
        return -1;
      } else if (
        aLives[0]?.courseId === a.id &&
        bLives[0]?.courseId === b.id &&
        aLives[0]?.start < bLives[0]?.start
      ) {
        return -1;
      } else {
        return 1;
      }
    });

  const { data: lives } = useQuery<Live[]>(
    `/lives?studentId=${student?.id}&courseId=${course?.id}`,
    fetcher,
    { enabled: authenticated && !!student && !!course }
  );

  const { data: latestLives } = useQuery<Live[]>(
    `/lives?studentId=${student?.id}&courseId=${latestCourse?.id}`,
    fetcher,
    { enabled: authenticated && !!student && !!latestCourse }
  );

  const studentId = localStorage.getItem('studentId');
  useEffect(() => {
    authenticated && studentId
      ? studentState.set(students?.find((s) => s.id === Number(studentId)))
      : authenticated
      ? studentState.set(students?.[0])
      : studentState.reset();
  }, [authenticated, studentId, students]);

  useEffect(() => {
    authenticated ? testResultState.set(testResult) : testResultState.reset();
  }, [authenticated, testResult]);

  const courseId = localStorage.getItem('courseId');
  const selectCourse = validCourses?.find((c) => c.id === Number(courseId));

  useEffect(() => {
    authenticated
      ? selectCourse
        ? courseState.set(selectCourse)
        : courseState.set(validCourses?.[0])
      : courseState.reset();
    authenticated
      ? trialCourseState.set(courses?.find((c) => !c.startSeriesId))
      : trialCourseState.reset();
  }, [authenticated, courses, selectCourse, validCourses]);

  // 나의 강의실
  useEffect(() => {
    authenticated
      ? latestCourseState.set(validCourses?.[0])
      : latestCourseState.reset();
  }, [authenticated, courses, validCourses]);

  const courseItemId = localStorage.getItem('courseItemId');
  const selectCourseItem = course?.courseItems?.find(
    (ci) => ci.id === Number(courseItemId)
  );

  const attendFindCourse = course?.lives
    .filter((l) => l.attendRound)
    .sort((a, b) => a.attendRound - b.attendRound);

  const latestAttendFindCourse = latestCourse?.lives
    .filter((l) => l.attendRound)
    .sort((a, b) => a.attendRound - b.attendRound);

  const attendFindCourseItem =
    attendFindCourse &&
    attendFindCourse.length > 0 &&
    attendFindCourse[attendFindCourse.length - 1];

  const latesAttendFindCourseItem =
    latestAttendFindCourse &&
    latestAttendFindCourse.length > 0 &&
    latestAttendFindCourse[latestAttendFindCourse.length - 1];

  const attendCourseItem = course?.courseItems.find(
    (ci) =>
      attendFindCourseItem && ci.number === attendFindCourseItem?.attendRound
  );

  const latestAttendCourseItem = latestCourse?.courseItems.find(
    (ci) =>
      latesAttendFindCourseItem &&
      ci.number === latesAttendFindCourseItem?.attendRound
  );

  const nextCourseItem = course?.courseItems.find(
    (ci) => attendCourseItem && ci.number === attendCourseItem?.number + 1
  );

  const nextLatestCourseItem = latestCourse?.courseItems.find(
    (ci) =>
      latestAttendCourseItem && ci.number === latestAttendCourseItem?.number + 1
  );

  useEffect(() => {
    authenticated
      ? selectCourseItem
        ? courseItemState.set(selectCourseItem)
        : courseItemState.set(
            nextCourseItem ? nextCourseItem : course?.courseItems[0]
          )
      : courseItemState.reset();
  }, [authenticated, course, nextCourseItem, selectCourseItem]);

  // 나의 강의실
  useEffect(() => {
    authenticated
      ? latestCourseItemState.set(
          nextLatestCourseItem
            ? nextLatestCourseItem
            : latestCourse?.courseItems[0]
        )
      : latestCourseItemState.reset();
  }, [authenticated, course, latestCourse, nextLatestCourseItem]);

  useEffect(() => {
    const index = course?.courseItems
      .sort((a, b) => a.number - b.number)
      .findIndex((ci, i) => ci.id === courseItem?.id);

    const findValidityLives = lives?.filter(
      (l) => l.state === LiveState.RESERVED || l.state === LiveState.ATTENDED
    );

    authenticated && course?.isTrial && course.initialAmount === 1
      ? liveState.set(findValidityLives?.[0])
      : authenticated &&
        course?.isTrial &&
        course.initialAmount > 1 &&
        index !== undefined
      ? liveState.set(findValidityLives?.[index])
      : authenticated && index !== undefined
      ? liveState.set(findValidityLives?.[index])
      : liveState.reset();
  }, [authenticated, course, courseItem, lives]);

  // 나의 강의실
  useEffect(() => {
    const index = latestCourse?.courseItems
      .sort((a, b) => a.number - b.number)
      .findIndex((ci, i) => ci.id === latestCourseItem?.id);

    const findValidityLives = latestLives?.filter(
      (l) => l.state === LiveState.RESERVED || l.state === LiveState.ATTENDED
    );

    authenticated && latestCourse?.isTrial && latestCourse.initialAmount === 1
      ? latestLiveState.set(findValidityLives?.[0])
      : authenticated &&
        latestCourse?.isTrial &&
        latestCourse.initialAmount > 1 &&
        index !== undefined
      ? latestLiveState.set(findValidityLives?.[index])
      : authenticated && index !== undefined
      ? latestLiveState.set(findValidityLives?.[index])
      : latestLiveState.reset();
  }, [authenticated, latestCourse, latestCourseItem, latestLives]);

  return <></>;
};
