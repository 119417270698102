import React, { FC, useState } from 'react';
import mainDescriptionImg1 from '../../../assets/img/main-description-img1.png';
import mainDescriptionImg2 from '../../../assets/img/main-description-img1-1.png';
import mainDescriptionImg3 from '../../../assets/img/main-description-img1-2.png';
import mainDescriptionImg4 from '../../../assets/img/main-description-img1-3.png';
import mainDescriptionImg5 from '../../../assets/img/main-description-img1-4.png';
import mainDescriptionImg6 from '../../../assets/img/main-description-img1-5.png';
import 'animate.css/animate.min.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import { ReactComponent as Arrow } from '../../../assets/svg/swiper_arrow_right.svg';
import { Section } from '../../../components/Section';
import { range } from '../../../utils';
import { isMobile } from 'react-device-detect';

SwiperCore.use([Pagination, Autoplay]);

interface ThirdSectionProps {
  firstTitle?: string;
  secondTitle?: string;
  secondMobileTitle1?: string;
  secondMobileTitle2?: string;
  firstDescription?: string;
  secondDescription?: string;
}

const tabData = [
  { id: 1, name: '디지털 교재', activeSlide: 0, containSlides: [1] },
  { id: 2, name: '단어학습', activeSlide: 1, containSlides: [2, 3, 4] },
  { id: 3, name: '퀴즈', activeSlide: 4, containSlides: [5] },
  { id: 4, name: '토크플러스', activeSlide: 5, containSlides: [6] },
];

const imgData = [
  { id: 1, imgPath: mainDescriptionImg1 },
  { id: 2, imgPath: mainDescriptionImg2 },
  { id: 3, imgPath: mainDescriptionImg3 },
  { id: 4, imgPath: mainDescriptionImg4 },
  { id: 5, imgPath: mainDescriptionImg5 },
  { id: 6, imgPath: mainDescriptionImg6 },
];

export const ThirdSection: FC<ThirdSectionProps> = ({
  firstTitle = '자연스러운 예습을 돕는',
  secondTitle = '튼튼영어라이브 회원 전용 디지털학습',
  secondMobileTitle1 = '튼튼영어라이브 회원 전용 ',
  secondMobileTitle2 = '디지털학습',
  firstDescription = '책의 모든 것이 머리에 쏙!',
  secondDescription = '매일 매일 체계적인 예습 시스템',
}) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState(1);
  const slideTo = (index: number) => {
    if (swiper) swiper.slideTo(index);
    setActiveIndex(index);
  };

  return (
    <div className="bg-light-blue con-pa">
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        className="mb-6"
      >
        <h2 className="text-2xl md:text-4xl text-black tit-txt01 text-center">
          {firstTitle}
        </h2>
        <h2 className=" text-blue-500 text-center py-2">
          <p className="text-4xl hidden tit-txt01 md:block">{secondTitle}</p>
          <p className="text-2xl inline-block md:hidden ">
            {secondMobileTitle1}
            <br />
            {secondMobileTitle2}
          </p>
        </h2>
        <p className=" text-15 md:text-xl font-medium text-black sub-txt01 text-center">
          {firstDescription}
          {secondDescription && (
            <>
              <br />
              {secondDescription}
            </>
          )}
        </p>

        <div className="flex  justify-center my-8">
          {tabData.map((t, i) => (
            <div className="flex items-center space-x-2">
              <button
                //onClick={() => slideTo(t.activeSlide)}
                className={` flex flex-col items-center`}
              >
                <div
                  className={`${
                    t.containSlides.includes(activeIndex)
                      ? 'filled-primary-1'
                      : 'filled-gray-200'
                  }  wh-12 grid place-content-center rounded-full text-2xl mb-2 text-white`}
                >
                  {t.id}
                </div>
                <span className="font-semibold">{t.name}</span>
              </button>

              {tabData.length - i > 1 && (
                <div className="flex space-x-2 mb-6 pr-2 md:pl-2 md:pr-4">
                  {isMobile ? (
                    <>
                      {range(2).map((dot) => (
                        <div className="w-1.5 h-1.5 rounded-full bg-gray-100" />
                      ))}
                    </>
                  ) : (
                    <>
                      {range(5).map((dot) => (
                        <div className="w-1.5 h-1.5 rounded-full bg-gray-100" />
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <ScrollAnimation
          animateIn="animate__fadeInUp"
          animateOnce={true}
          className="relative"
        >
          <Section className="relative Thirs-slide-wrap tablet-slide-wrap">
            <Swiper
              onTransitionEnd={(swiper) => {
                // console.log(swiper.realIndex + 1);
                setActiveIndex(swiper.realIndex + 1);
              }}
              onSwiper={setSwiper}
              loop={true}
              loopedSlides={1}
              //touchRatio={1}
              //pagination={{ clickable: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              navigation={{
                nextEl: '.swiper-button-next-1',
                prevEl: '.swiper-button-prev-1',
              }}
              className="Thirs-slide tablet-slide"
            >
              {/* {imgData.map((banner) => (
                <SwiperSlide key={String(banner.id)}>
                  <img alt="" src={banner.imgPath} className="mx-auto pb-10" />
                </SwiperSlide>
              ))} */}
              <SwiperSlide>
                <img alt="" src={imgData[0].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt01.png').default}
                  className="s-key-txt s-key-txt01"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt02.png').default}
                  className="s-key-txt s-key-txt02"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt03.png').default}
                  className="s-key-txt s-key-txt03"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[1].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt04.png').default}
                  className="s-key-txt s-key-txt04"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[2].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt05.png').default}
                  className="s-key-txt s-key-txt05"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[3].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt06.png').default}
                  className="s-key-txt s-key-txt06"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[4].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt07.png').default}
                  className="s-key-txt s-key-txt07"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt08.png').default}
                  className="s-key-txt s-key-txt08"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img alt="" src={imgData[5].imgPath} className="bg-slide" />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt09.png').default}
                  className="s-key-txt s-key-txt09"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt10.png').default}
                  className="s-key-txt s-key-txt10"
                />
                <img
                  alt=""
                  src={require('../../../assets/img/s-key-txt11.png').default}
                  className="s-key-txt s-key-txt11"
                />
              </SwiperSlide>
            </Swiper>
            <div className="tablet-slide-bg">
              <img
                src={require('../../../assets/img/tablet-frame.png').default}
                className=""
                alt=""
              />
            </div>
            <Arrow className="swiper-button-prev-1 mobile-none cursor-pointer swiper-button" />
            <Arrow className="swiper-button-next-1 mobile-none cursor-pointer swiper-button" />
          </Section>
          <div className="gury_img01">
            <img
              src={require('../../../assets/img/character1.png').default}
              className=""
              alt=""
            />
          </div>
        </ScrollAnimation>
        {/* <div className="t-noti-box">
              <span><a className="xi-info"></a>알려드려요!</span>
              튼튼영어라이브의 디지털 학습, 화상 수업을 포함한 모든 기능들은 PC 크롬 웹페이지에서 원활한 이용이 가능합니다. 가급적 PC나 노트북을 이용해 주세요.  
        </div> */}
      </ScrollAnimation>
    </div>
  );
};
