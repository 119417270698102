import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AdminTab } from '../components/AdminTab';
import { AdminTabs } from '../components/AdminTabs';
import { UserInfo } from './UserInfo';
import { UserPayments } from './UserPayments';
import { UserRewards } from './UserRewards';
import { UserStudentDetails } from './UserStudentDetails';
import { UserStudents } from './UserStudents';

export const UserDetails = () => {
  const { pathname } = useLocation();
  const chunks = pathname.split('/');
  const basePath = `/${chunks[1]}/${chunks[2]}/${chunks[3]}`;
  const subPath = chunks[4];

  return (
    <>
      <AdminTabs>
        <AdminTab
          text="자녀정보"
          to={`${basePath}/students`}
          selected={subPath === 'students'}
        />
        <AdminTab
          text="회원정보"
          to={`${basePath}/info`}
          selected={subPath === 'info'}
        />
        <AdminTab
          text="배송·결제 내역"
          to={`${basePath}/payments`}
          selected={subPath === 'payments'}
        />
        <AdminTab
          text="쿠폰·포인트"
          to={`${basePath}/rewards`}
          selected={subPath === 'rewards'}
        />
      </AdminTabs>

      <Switch>
        <Route
          path="/admin/users/:userId/students/:studentId"
          component={UserStudentDetails}
        />
        <Route path="/admin/users/:id/students" component={UserStudents} />
        <Route path="/admin/users/:id/info" component={UserInfo} />
        <Route path="/admin/users/:id/payments" component={UserPayments} />
        <Route path="/admin/users/:id/rewards" component={UserRewards} />
        <Redirect from="/admin/users/:id" to="/admin/users/:id/students" />
      </Switch>
    </>
  );
};
