import React from 'react';
import { useQuery } from 'react-query';
import { Button } from '../../components/Button';
import { Table } from '../../components/Table';
import { fetcher } from '../../plugins/react-query';
import { Live, LiveState } from '../../types';
import { MobileHelper } from './MobileHelper';
import { chain, find } from 'lodash';

export const StudentsPage = () => {
  const { data: students } = useQuery<any>(`/lives/byTeacher`, fetcher);

  return (
    <div>
      <div className="p-4 w-full md:block hidden">
        <div className="w-full max-w-6xl mx-auto rounded-lg bg-white shadow-lg overflow-hidden">
          <Table>
            <Table.Head className="bg-white">
              <Table.Row>
                <Table.Th>학생 이름</Table.Th>
                <Table.Th>코스</Table.Th>
                <Table.Th className="whitespace-pre">현재 레벨</Table.Th>
                <Table.Th className="whitespace-pre">현재 교재</Table.Th>
                <Table.Th className="whitespace-pre">현재 진도</Table.Th>
                <Table.Th className="whitespace-pre">현재 회차</Table.Th>
                <Table.Th className="whitespace-pre">나와의 수업 횟수</Table.Th>
                <Table.Th></Table.Th>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {chain(students)
                .map((student) => Object.values(student))
                .flatten()
                .map((lives: Live[], index: any) => {
                  const liveCount = lives.filter(
                    (l) => l.state === LiveState.ATTENDED
                  ).length;
                  const currentStep = chain(lives)
                    .map('attendRound')
                    .max()
                    .value();
                  const currentLive = find(
                    lives,
                    (live) => live.attendRound === currentStep
                  );
                  if (lives.length === 0) {
                    return null;
                  }
                  const {
                    student: { name: studentName },
                    course: {
                      name: courseName,
                      studentId,
                      id: courseId,
                      isTrial,
                    },
                  } = lives[0];

                  const entryLevel = lives[0].student.testResults
                    ? lives[0].student.testResults[
                        lives[0].student.testResults.length - 1
                      ].type === 'A'
                      ? 'Level 1'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'B'
                      ? 'Level 2'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'C'
                      ? 'Level 3'
                      : ''
                    : '';

                  const entrySeries = lives[0].student.testResults
                    ? lives[0].student.testResults[
                        lives[0].student.testResults.length - 1
                      ].type === 'A' &&
                      lives[0].student.testResults[
                        lives[0].student.testResults.length - 1
                      ].score100 <= 60
                      ? 'I Love Stories 1권'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'A' &&
                        lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].score100 <= 100
                      ? 'Reading Rookie 1-1권'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'B' &&
                        lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].score100 <= 70
                      ? `What's Up 1권`
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'B' &&
                        lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].score100 <= 100
                      ? 'Reading Rookie 2-7권'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'C' &&
                        lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].score100 <= 70
                      ? 'Joy the Tomboy 1권'
                      : lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].type === 'C' &&
                        lives[0].student.testResults[
                          lives[0].student.testResults.length - 1
                        ].score100 <= 100
                      ? 'My Reading Rainbow 1권'
                      : ''
                    : '';

                  return (
                    <Table.Row key={index}>
                      <Table.Td>{studentName}</Table.Td>
                      <Table.Td>{courseName}</Table.Td>
                      <Table.Td>
                        {
                          // isTrial && lives[0].course.initialAmount === 1
                          //   ? entryLevel
                          //   :
                          currentLive?.courseItem?.liveClass?.level?.name
                            ? `${currentLive?.courseItem?.liveClass?.level?.name}`
                            : lives[0]?.courseItem?.liveClass?.level?.name
                            ? lives[0]?.courseItem?.liveClass?.level?.name
                            : lives[0]?.firstCourseItem?.liveClass?.level?.name
                            ? lives[0]?.firstCourseItem?.liveClass?.level?.name
                            : entryLevel
                        }
                      </Table.Td>
                      <Table.Td>
                        {
                          // isTrial && lives[0].course.initialAmount === 1
                          //   ? entrySeries
                          //   :
                          currentLive?.courseItem?.liveClass?.series?.name
                            ? `${currentLive?.courseItem?.liveClass?.series?.name}`
                            : lives[0]?.courseItem?.liveClass?.series?.name
                            ? lives[0]?.courseItem?.liveClass?.series?.name
                            : lives[0]?.firstCourseItem?.liveClass?.series?.name
                            ? lives[0]?.firstCourseItem?.liveClass?.series?.name
                            : entrySeries
                        }
                      </Table.Td>
                      <Table.Td>
                        {
                          isTrial &&
                          lives[0].course.initialAmount === 1 &&
                          // 정규는 startlevelid가 세팅되어 있지 않음 임시로 사용해야 될 듯..
                          !lives[0].course.startLevelId
                            ? '-'
                            : currentLive?.courseItem?.liveClass?.book?.title
                            ? `${currentLive?.courseItem?.liveClass?.book?.title}`
                            : lives[0]?.courseItem?.liveClass?.book?.title
                            ? lives[0]?.courseItem?.liveClass?.book?.title
                            : lives[0]?.firstCourseItem?.liveClass?.book?.title
                          // ? lives[0]?.firstCourseItem?.liveClass?.book?.title
                          // : '-'
                        }
                      </Table.Td>
                      <Table.Td>
                        {currentStep ? `${currentStep}회차` : '-'}
                      </Table.Td>
                      <Table.Td>{liveCount}회</Table.Td>
                      <Table.Td className="text-right">
                        <Button
                          className="filled-secondary-7 text-primary-7 h-10 font-semibold"
                          to={`/teacher/students/${studentId}?courseId=${courseId}`}
                        >
                          상세보기
                        </Button>
                      </Table.Td>
                    </Table.Row>
                  );
                })
                .value()}
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className="block md:hidden w-full">
        <MobileHelper />
      </div>
    </div>
  );
};
