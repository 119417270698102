/*
- 인수
  * cornerMapUrl : 코너맵 html 위치
  * lessonInfo : 레슨 정보. 아래 포맷
  {
    authToken : 'adf' //인증 식별자. 
    ,studentId: 777  //학습 진행 학생ID. 
    ,uploadUrl: 'https://' //녹음파일 업로드 위치. url 형태여야 함.
    ,lessonId: 'MDY_B1_L1' //레슨 식별자
    ,title : 'Lesson 1' //레슨 명. 코너맵 UI상에 표시 용도.
    ,directMode : ''//CM_DIRECT_STUDY|| CM_DIRECT_REVIEW||CM_DIRECT_ANSWER||undefined 
    ,corners: [//코너별 정보 목록. 필수.
      {
        ActiveID: "1" //코너 유형 식별자. 숫자형 문자열임. 필수 
        ,url: "http://server.com/Contents/... /index.html" //코너 콘텐츠 위치
        ,done: true //boolean. 최초 학습 완료 여부. 필수. 코너맵에서는 이 항목으로 최초 학습 | 복습 여부 판단
        //아래 세값은 done:true일 때만 있음
        ,TalkPlusResult : '' //토크플러스 만 있음. 
        ,QuizResult : '' //이전에 보고한 채점표. 퀴즈류 코너만 있음. json을 stringify한 값
        ,QuizScore : '' //이전에 보고한 점수. 퀴즈류 코너만 있음. 0~100 정수
      },
      ...
      {
      ActiveID: "9", 	url: "..."...
      }
		]
    * cbFn : 코너맵 결과 콜백. 
    - 시그너쳐 cbFn(ret)
      ret : 코너맵이 리턴하는 객체. 아래 포맷.
    {
      op: //코너맵이 보고하는 신호 종류. 항상 값을 가짐. 위 CM_OP_CORNER_DONE 등
      data : //신호에 따른 데이터. 
      err : 오류 발생시 코드. undefined || 0 < 인 정수(e.g. CM_ERR_CLOSED). 
    }

    //op항목이 CM_OP_CORNER_DONE일 때 data 항목의 코너 완료 정보 포맷
    {
      authToken: '' //사이트->코너맵 호출시 넘겨주는 authToken인수
      ,studentId : '' //사이트->코너맵 호출시 넘겨주는 계정 식별자.
      ,lessonId : '' //사이트->코너맵 호출시 넘겨주는 레슨 식별자.
      ,ActiveID: ''   //코너 유형.
      ,TalkPlusResult : '' //토크플러스 만 있음. 
      ,QuizResult : '' //퀴즈류 코너만 있음. weekly test일 때는 없음 
      ,QuizScore : '' //퀴즈류 코너만 있음. weekly test 포함
    }    
    }
*/
//오류 코드 목록
const CM_ERR_CLOSED = -1; //코너맵 창이 열려있지 않음
const CM_ERR_EXCEPTIOIN = -2; //메시지 처리중 exception발생.

//코너 곧바로 실행시(cornere.length == 1) 코너 동작 모드
//코너가 weekly test 일때는 URL로 모드가 결정되므로 불필요. 현 기획하에서 CM_DIRECT_ANSWER 또는 undefined로 호출
export const CM_DIRECT_STUDY = 'STUDY'; //최초 학습
export const CM_DIRECT_REVIEW = 'REVIEW'; //복습
export const CM_DIRECT_ANSWER = 'ANSWER'; //풀이 보기 or 도서관용

//코너맵이 보고하는 신호 종류
export const CM_OP_REDAY = 'cm_ready'; //코너맵 신호 수신 준비 완료
export const CM_OP_ERROR = 'cm_error'; //오류 발생시 신호
export const CM_OP_EXIT = 'cm_exit'; //코너맵 닫힘.
export const CM_OP_CORNER_DONE = 'cm_corner_done'; //코너 완료 신호
export const CM_OP_LESSON_DONE = 'cm_lesson_done'; //코너 완료 신호

export function fn_openCornerMap(cornerMapUrl, lessonInfo, cbFn) {
  const wndWidth = 1280,
    wndHeight = 805;

  if (!window._cmWndInfo) {
    window._cmWndInfo = {};
    window.addEventListener('message', fn_handleMsg);
  }

  function fn_handleMsg(ev) {
    let msg = ev.data || ev.detail;

    try {
      switch (msg.op) {
        case CM_OP_REDAY:
          if (_cmWndInfo.wnd && !_cmWndInfo.wnd.closed)
            _cmWndInfo.wnd.postMessage(
              { op: 'list_corner_cm', lesson: _cmWndInfo.lesson },
              '*'
            );
          else cbFn({ op: CM_OP_ERROR, data: CM_ERR_CLOSED });
          break;
        case CM_OP_CORNER_DONE:
          cbFn(msg);
          break;
        case CM_OP_EXIT:
          cbFn(msg);
          break;
        case CM_OP_ERROR:
          cbFn(msg);
          break;
        case CM_OP_LESSON_DONE:
          cbFn(msg);
          break;
        default:
          break;
      }
    } catch (ex) {
      console.error(ex);
      alert('코너맵 처리 오류:' + ex.message);
      cbFn({ op: CM_OP_ERROR, data: ex, err: CM_ERR_EXCEPTIOIN });
    }
  }

  //창이 열린 상태에서 동일 세션/레슨/계정이면 새로 창을 열지 않는다
  // if((_cmWndInfo.wnd && !_cmWndInfo.wnd.closed) &&
  // _cmWndInfo.lesson.userAuth == lessonInfo.userAuth && _cmWndInfo.lesson.lessonId == lessonInfo.lessonId && _cmWndInfo.lesson.studentId == lessonInfo.studentId)
  //{
  //   if(_cmWndInfo.wnd && !_cmWndInfo.wnd.closed) {
  //     _cmWndInfo.wnd.focus();
  //   }
  //     _cmWndInfo.wnd.focus();
  //   return console.error(`Same lesson and ID`)
  // }

  var _cmWndInfo = {};
  _cmWndInfo.wnd = window.open(
    cornerMapUrl,
    'Corner Map',
    _getWindowOption(wndWidth, wndHeight)
  );
  _cmWndInfo.lesson = lessonInfo;

  //사이트 창이 닫히면 코너맵도 닫음.
  window.onbeforeunload = () => _cmWndInfo.wnd.close();

  function _getWindowOption(width, height) {
    var opts = `menubar=no,status=no,toolbar=no,location=no,width=${width},height=${height}`;
    var offsetLeft = window.screen.availLeft ? window.screen.availLeft : 0;
    var offsetTop = window.screen.availTop ? window.screen.availTop : 0;
    offsetLeft = offsetLeft + (window.screen.availWidth - parseInt(width)) / 2;
    offsetTop -= 22; //magic number
    if (window.screenX) {
      if (window.screenX > window.screen.width) {
        opts +=
          ',left=' +
          (window.screen.availWidth +
            parseInt((window.screen.availWidth - width) / 2)) +
          ',top=' +
          parseInt((window.screen.availHeight - height) / 2 - 22);
      } else {
        opts +=
          ',left=' +
          parseInt((window.screen.availWidth - width) / 2) +
          ',top=' +
          parseInt((window.screen.availHeight - height) / 2 - 22);
      }
    } else {
      opts += ',left=' + offsetLeft + ',top=' + offsetTop;
    }
    return opts;
  }
}
