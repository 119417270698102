import axios from 'axios';
import { tokenState } from './ridge';

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const token = tokenState.get();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization;
  }
  // config.headers['Content-type'] = 'application/json; charset=utf-8';
  return config;
});

api.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) {
    tokenState.reset();
  }
  return Promise.reject(error);
});

function apiUrl(path?: string) {
  if (!baseURL || !path) return undefined;
  return baseURL[baseURL.length - 1] === '/'
    ? `${baseURL}${path}`
    : `${baseURL}/${path}`;
}

export { api, baseURL, apiUrl };
